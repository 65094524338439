import { CodeResponse, GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import React, { useState } from "react";
import APIManger from "../../../APIManger";
import jwt_decode from "jwt-decode";
import MDIcons from "../../Common/MDIcons";
// 800279025061-nliijtdpur2607u1v8nbqdqc6p40ncfa.apps.googleusercontent.com
// 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

export default function MyGoogleLogin({ GoogleLogin }) {
  const validateTokenAndObtainSession = async (data, idToken) => {
    const token = await APIManger.post("accounts/google-login/", { data, idToken });
    return token;
  };

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      // discuss with Mihail: chain this request to another request to the backend so we can find the ...
      // user based on the email, issue a token, and return the token and set it in the local storage
      try {
        let data = null;
        const res = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: {
            Authorization: `Bearer ${response.access_token}`,
          },
        });
        data = res.data;
        const token = await validateTokenAndObtainSession(data, response.access_token);
        GoogleLogin(token);
      } catch (err) {
        console.log(err);
      }
    },
  });

  return (
    <>
      <button onClick={() => login()}>
        <MDIcons title="google_button_icon" />
        Login with Google
      </button>
    </>
  );
}
