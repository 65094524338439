import { TextareaAutosize } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { MilestoneContext } from "../../../../contexts/TicketingSystem/MilestoneContext";
import { MilestoneEmailContext } from "../../../../contexts/TicketingSystem/MilestoneEmailContext";
import { getMonthEndTaskType } from "../../../Tasks/utils";
import EmailReadyToSendContainer from "../../MilestoneEmail/EmailReadyToSendContainer";
import EmailBuilder from "../../MilestoneEmail";
import StageApproveRejectMonthEndClose from "./MonthEndClose";
import StageApproveRejectPayroll from "./Payroll";

const StageApproveReject = ({ onSubmitRequestRevision }) => {
  const { subTasksAcceptanceArray, milestoneTasksCompletionArray, milestoneType } =
    useContext(MilestoneContext);
  const { emailReadyToSendFlag } = useContext(MilestoneEmailContext);

  const revisionSelected = () => {
    const rejection = subTasksAcceptanceArray.find((x) => x.state === "Rejected");
    if (rejection) {
      return true;
    } else {
      return false;
    }
  };

  const allCompleted = () => {
    const notCompleted = milestoneTasksCompletionArray.filter((x) => x.completed !== true);
    if (notCompleted.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const allAccepted = () => {
    const notAccepted = subTasksAcceptanceArray.filter((x) => x.state !== "Accepted");
    if (notAccepted.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const allReviewed = () => {
    const notReviewed = subTasksAcceptanceArray.filter((x) => x.state === "");
    if (notReviewed.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  // useEffect(() => {
  //   const IsUserAssignedCurrentStage = getMonthEndTaskType(userView) === getMonthEndTaskType(currentStage?.name);
  //   setIsUserAssignedCurrentStage(IsUserAssignedCurrentStage);
  // }, [currentStage, userView, prevStage]);

  return (
    <div>
      {milestoneType === 0 ? (
        <StageApproveRejectPayroll
          allCompleted={allCompleted}
          allAccepted={allAccepted}
          allReviewed={allReviewed}
          revisionSelected={revisionSelected}
          onSubmitRequestRevision={onSubmitRequestRevision}
        />
      ) : (
        <StageApproveRejectMonthEndClose
          allCompleted={allCompleted}
          allAccepted={allAccepted}
          allReviewed={allReviewed}
          revisionSelected={revisionSelected}
          onSubmitRequestRevision={onSubmitRequestRevision}
        />
      )}
      {emailReadyToSendFlag && (
        <EmailReadyToSendContainer >
          <EmailBuilder />
        </EmailReadyToSendContainer>
      )}
    </div>
  );
};

export default StageApproveReject;
