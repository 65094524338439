import React from "react";
import CustomTooltip from "../Tooltip";
import { getInitials } from "../../../utils/helpers";

const CreatedByImage = ({ task, size }) => {
    // options for size are: 21, 27, 36, 60, 128, 1024
  return (
    <>
      <CustomTooltip title={task?.created_by?.name}>
        {task?.created_by?.photo ? (
          task?.created_by?.photo?.[`image_${size}x${size}`] ? (
            <img className="AsanaPhoto" src={task?.created_by?.photo?.[`image_${size}x${size}`]} alt="" style={{ height: size, width: size }} />
          ) : (
            <div className="user__circle" style={{ height: size, width: size }}>
              <div className="user__circle_inner">{getInitials(task?.created_by?.name)}</div>
            </div>
          )
        ) : (
          <div className="user__circle" style={{ height: size, width: size }}>
            <div className="user__circle_inner">{getInitials(task?.created_by?.name)}</div>
          </div>
        )}
      </CustomTooltip>
    </>
  );
};

export default CreatedByImage;
