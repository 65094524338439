import { useContext } from "react";
import { OnboardingContext } from "../../contexts/Onboarding/OnboardingContext";

const ImageUploader = ({ setImage, setOriginalImage,setIsCropDialogOpen, setImageFile }) => {

    

    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    const onSelect = async (e) => {
        // get the first file only
        const file = e.target.files[0];
        // seize validation
        if (file.size > 2 * 1024 * 1024) {
            // 2 MB
            console.error("File size is too big");
            return;
        }
        
        const base64 = await toBase64(file);
        setImageFile(file)
        setImage(base64);
        setOriginalImage(base64)
        setIsCropDialogOpen(true)
    };
    return (
        <label className="custom_file_upload">
            <input className="LogoFileInput" type="file" id="img" accept=".jpg,.png" onChange={onSelect} />
            Click to upload logo
        </label>
    );
};

export default ImageUploader