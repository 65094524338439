import { Fragment, MouseEvent, useContext, useEffect, useState } from "react";
// import ReactTooltip from "react-tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Chip from "@mui/material/Chip";
import { AuthContext } from "../../contexts/AuthContext";
import defaultCompany from "../../static/img/default_company.png";
import "./mainHeader.css";
import SectionTitleIcons from "../Common/SectionTitleIcons";
import { Company } from "../../types/Company";

export interface AccountMenuProps {
  company?: Company;
  setCompany?: (company: Company) => void;
}

export default function AccountMenu({ company, setCompany }: AccountMenuProps) {
  const { userProfile, setUserSelectedCompany, selectedCompany } = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = useState<{ [x: string]: null | HTMLElement }>({
    company: null,
    account: null,
  });

  // useEffect(() => {
  //   ReactTooltip.rebuild();
  // }, [userProfile]);

  const handleClick = (event: MouseEvent<HTMLElement>, itemKey: string) => {
    setAnchorEl({ [itemKey]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl({ company: null });
  };
  return (
    <Fragment>
      <Chip
        // data-tip="Companies"
        id="sidebar-companies"
        label={company ? company.companyName : selectedCompany?.companyName || "No company"}
        onClick={(e) => handleClick(e, "company")}
        sx={{
          width: "100%",
          backgroundColor: "#FFFFFF0D",
          justifyContent: "center",
          "& .MuiChip-label": {
            color: "#FFFFFF",
            fontSize: 14,
            lineHeight: "24px",
          },
        }}
        icon={
          <img
            className="company-icon-header"
            src={
              company
                ? company.companyAvatarURL
                  ? company.companyAvatarURL
                  : defaultCompany
                : selectedCompany?.companyAvatarURL
                ? selectedCompany.companyAvatarURL
                : defaultCompany
            }
            alt=""
          />
        }
      />
      <Menu
        anchorEl={anchorEl["company"]}
        open={Boolean(anchorEl["company"])}
        onClose={handleClose}
        onClick={handleClose}
        keepMounted
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        PopoverClasses={{
          paper: "companyMenuPaper",
        }}
      >
        {company
          ? userProfile.companies
              .filter((x) => x.is_connected === true)
              .map((companyListItem) => (
                <MenuItem
                  key={companyListItem.companyName}
                  disabled={false}
                  sx={{
                    height: 50,
                    justifyContent: "left",
                    "&.Mui-selected": {
                      backgroundColor: "#85b4404D",
                      ":hover": {
                        backgroundColor: "#85b4404D",
                      },
                    },
                    ":hover": {
                      backgroundColor: "#FAFAFA",
                    },
                  }}
                  selected={company ? companyListItem.id === company?.id : companyListItem.id === selectedCompany?.id}
                  onClick={!setCompany ? () => setUserSelectedCompany(companyListItem) : () => setCompany(companyListItem)}
                >
                  <ListItemIcon sx={{ marginRight: 1 }}>
                    <img className="company-icon" src={companyListItem.companyAvatarURL ? companyListItem.companyAvatarURL : defaultCompany} alt="" />
                  </ListItemIcon>
                  {companyListItem?.companyName || "No Name_" + companyListItem.id}
                  {companyListItem?.is_Onboarding && (
                    <span>
                      &nbsp;&nbsp;
                      <SectionTitleIcons title="Onboarding new company" />
                    </span>
                  )}
                </MenuItem>
              ))
          : userProfile.companies.map((companyListItem) => (
              <MenuItem
                key={companyListItem.companyName}
                disabled={false}
                sx={{
                  height: 50,
                  justifyContent: "left",
                  "&.Mui-selected": {
                    backgroundColor: "#85b4404D",
                    ":hover": {
                      backgroundColor: "#85b4404D",
                    },
                  },
                  ":hover": {
                    backgroundColor: "#FAFAFA",
                  },
                }}
                selected={companyListItem.id === selectedCompany?.id}
                onClick={!setCompany ? () => setUserSelectedCompany(companyListItem) : () => setCompany(companyListItem)}
              >
                <ListItemIcon sx={{ marginRight: 1 }}>
                  <img className="company-icon" src={companyListItem.companyAvatarURL ? companyListItem.companyAvatarURL : defaultCompany} alt="" />
                </ListItemIcon>
                {companyListItem?.companyName || "No Name_" + companyListItem.id}
                {companyListItem?.is_Onboarding && (
                  <span>
                    &nbsp;&nbsp;
                    <SectionTitleIcons title="Onboarding new company" />
                  </span>
                )}
              </MenuItem>
            ))}
      </Menu>
    </Fragment>
  );
}
