import { HeadCountInformation } from "../types/HeadCount"
import APIManger from "../APIManger"
import {
    ADD_EMPLOYEE, EDIT_DELETE_EMPLOYEE, DELETE_EMPLOYEE_LIST, EXPORT_HEAD_COUNT_EMPLOYEE_LIST,
    GET_HEAD_COUNT_EMPLOYEE_LIST, GET_HEAD_COUNT_LIST_FILTERS, UPDATE_EMPLOYEE_LIST, UPLOAD_HEAD_COUNT_LIST, DOWNLOAD_CSV_SAMPLE, DUPLICATE_EMPLOYEE_LIST, PRINT_EMBEDED_PDF, Get_PDF_DOWNLOAD_LINK
} from "./apiConstants"
import { CompanyFilters } from "../types/CompanyFilters"
import { BulkAdd__ResponseAPI, DownloadPDFLink__ResponseAPI, UpdateHeadCountList__ResponseAPI } from "../types/API/headCountsData"
import { Company } from "../types/Company"
import { Division } from "../types/Division"

export const getHeadCountEmployeeList = async (company: Company, searchText?: string): Promise<HeadCountInformation[]> => {
    let url = GET_HEAD_COUNT_EMPLOYEE_LIST.replace("%COMPANY_ID%", company?.id?.toString())
    url += searchText ? "?search=" + searchText : ""
    const apiResult: any = await APIManger.get(url)
    var result: HeadCountInformation[] = []
    if (apiResult.message && apiResult.message === "Network Error")
        result = []
    else
        result = apiResult
    return result;
}

export const exportHeadCountEmployeeList = async (company: Company) => {
    let url = EXPORT_HEAD_COUNT_EMPLOYEE_LIST.replace("%COMPANY_ID%", company?.id?.toString())
    await APIManger.download(url, "export.csv")
}




export const downloadCSVSample = async () => {
    let url = DOWNLOAD_CSV_SAMPLE
    await APIManger.download(url, "export.csv")
}

export const getHeadCountListFilters = async (company: Company): Promise<CompanyFilters> => {
    let url = GET_HEAD_COUNT_LIST_FILTERS.replace("%COMPANY_ID%", company?.id?.toString())
    let apiResult: any = await APIManger.get(url)
    return new CompanyFilters(apiResult.data)
}

export const deleteSelectedEmployeesList = async (ids: readonly string[], company: Company): Promise<boolean> => {
    let url = DELETE_EMPLOYEE_LIST.replace("%COMPANY_ID%", company?.id?.toString())
    let apiResult: string = await APIManger.delete(url, { ids: ids.toString() })
    return apiResult === '';
}

export const updateSelectedEmployeesList = async (ids: readonly string[], company: Company, updates: Object): Promise<UpdateHeadCountList__ResponseAPI> => {
    let url = UPDATE_EMPLOYEE_LIST.replace("%COMPANY_ID%", company?.id?.toString())
    let apiResult: UpdateHeadCountList__ResponseAPI = await APIManger.post(url, { ids: ids.toString(), ...updates })
    return apiResult;
}

export const uploadEmployeesList = async (company: Company, file: any): Promise<BulkAdd__ResponseAPI> => {
    let url = UPLOAD_HEAD_COUNT_LIST.replace("%COMPANY_ID%", company?.id?.toString())
    let apiResult: BulkAdd__ResponseAPI = await APIManger.upload(url, file)
    return apiResult;
}

export const deleteEmployee = async (id: string, company: Company): Promise<boolean> => {
    let url = EDIT_DELETE_EMPLOYEE.replace("%ID%", id).replace("%COMPANY_ID%", company.id?.toString())
    let apiResult: string = await APIManger.delete(url, {})
    return apiResult === '';
}

export const addEmployee = async (data: any, company: Company): Promise<HeadCountInformation> => {
    let url = ADD_EMPLOYEE.replace("%COMPANY_ID%", company?.id?.toString())
    let apiResult: HeadCountInformation = await APIManger.post(url, data)
    return apiResult;
}

export const editEmployee = async (data: HeadCountInformation, company: Company): Promise<HeadCountInformation> => {
    let url = EDIT_DELETE_EMPLOYEE.replace("%ID%", data.id).replace("%COMPANY_ID%", company?.id?.toString())
    let apiResult: HeadCountInformation = await APIManger.put(url, data)
    return apiResult;
}

export const printEmbededPDF = async (company: Company, selectedDivision: Division): Promise<boolean> => {
    let url = PRINT_EMBEDED_PDF.replace("%COMPANY_ID%", company?.id?.toString()).replace("%DIVISION_ID%", selectedDivision.id.toString())
    const apiResult: any = await APIManger.post(url)
    if (apiResult.detail === "Success")
        return true;
    else
        return false;
}
export const getPDFDownloadLink = async (company: Company): Promise<DownloadPDFLink__ResponseAPI> => {
    let url = Get_PDF_DOWNLOAD_LINK.replace("%COMPANY_ID%", company?.id?.toString())
    const apiResult: DownloadPDFLink__ResponseAPI = await APIManger.post(url)
    return apiResult;
}
export const duplicateEmployee = async (ids: readonly string[], count: number, company: Company): Promise<HeadCountInformation> => {
    let url = DUPLICATE_EMPLOYEE_LIST.replace("%COMPANY_ID%", company?.id?.toString())
    let apiResult: HeadCountInformation = await APIManger.post(url, { ids: ids.toString(), count })
    return apiResult;
}