import { TextareaAutosize } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { createAsanaTaskComment, retrieveAsanaTaskStories } from "../../../../APIFunctions/Asana";
import { MilestoneContext } from "../../../../contexts/TicketingSystem/MilestoneContext";
import Comment from "../../../Tasks/Components/Comment";
import QA_Image from '../../img/QA__Image.svg'
import RichTextEditor, { convertQuillHTMLToAsana, convertQuillMentionsToAsana } from "../../../Common/RichTextEditor";

const Comments = ({ task_gid, people }) => {
  const { setPayrollLoading } = useContext(MilestoneContext);
  const [comments, setComments] = useState<any[]>([]);
  const [newComment, setNewComment] = useState<string>("");

  const getComments = async (task_gid) => {
    if (task_gid) {
      setPayrollLoading(true);
      const response = await retrieveAsanaTaskStories(task_gid);
      if (response.data) {
        const stories = response.data;
        const storiesArray = stories.filter((story) => story.type === "comment");
        setComments(storiesArray);
      } else if (response.response?.status === 401) {
        setTimeout(() => {
          getComments(task_gid);
        }, 1000);
      }
      setPayrollLoading(false);
    }
    
  };

  const addComment = async () => {
    
    setPayrollLoading(true);
    // const newComment = "<a data-asana-gid=\"1195963963243747\"/> good morning"
    const cleaned_notes = convertQuillHTMLToAsana(convertQuillMentionsToAsana(newComment))
    const newCommentObj = {
      data: {
        html_text: cleaned_notes,
      },
    };

    // todo: setTaskCardLoading(true);
    const response = await createAsanaTaskComment(task_gid, newCommentObj);

    if (response.data) {
      setNewComment(" ");
      await getComments(task_gid);
      // todo:  setTaskCardLoading(false);
    } else if (response.response?.status === 401) {
      setTimeout(() => {
        addComment();
      }, 1000);
    }
    setPayrollLoading(false);
  };

  useEffect(() => {
    getComments(task_gid);
  }, [task_gid]);

  return (
    <>
      <div className="Comments__Container" style={{ padding: comments.length > 0 ? "1rem" : "0rem", overflow: "auto" }}>
        {comments.length === 0 &&
        <div className="Comments__Container__NoComments">
            <img src={QA_Image} alt="" />
            <div>No Questions!</div>
            <div>Add your first question</div>
        </div>
        
        }
        {comments.map((comment) => {
          return <Comment key={comment.gid} story={comment} taskStories={comments} setTaskStories={setComments} />;
        })}
      </div>

      <div className="NewComment__Container">
        {/* <TextareaAutosize
          aria-label="empty textarea"
          placeholder="Ask a question or post an update..."
          style={{ width: "100%", padding: 10, maxHeight:"5rem" }}
          className="TaskCard__Description"
          value={newComment}
          name="notes"
          onChange={(e) => setNewComment(e.target.value)}
        /> */}
        <RichTextEditor htmlValue={newComment} setHTMLValue={setNewComment} onChange={(value) => setNewComment(value)} people={people} placeholder={"Add comment here..."}/>
        <button className="btn--primary btn--S flex-row--S addComment__Button " onClick={addComment}>
          <span>Comment</span>
        </button>
      </div>
    </>
  );
};

export default Comments;
