import React, { useContext, useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs'
// import TimeAgo from 'javascript-time-ago'
// import en from 'javascript-time-ago/locale/en.json'
// import ReactTimeAgo from 'react-time-ago';
import BasicMenu from '../../Common/DropDownComponent/TaskMoreActions';
import { TaskAuthContext } from '../../../contexts/Task/TaskAuthContext';
import { deleteAsanaTaskComment, updateAsanaTaskStory } from '../../../APIFunctions/Asana';
import CustomTooltip from '../../Common/Tooltip';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import { TextareaAutosize } from '@mui/material';
import { TaskTableContext } from '../../../contexts/Task/TaskTableContext';
import { getInitials } from '../../../utils/helpers';
import relativeTime from 'dayjs/plugin/relativeTime';
import { AuthContext } from '../../../contexts/AuthContext';
import RichTextEditor, { convertQuillHTMLToAsana, convertQuillMentionsToAsana } from '../../Common/RichTextEditor';
import { TaskServicesContext } from '../../../contexts/Task/TaskServicesContext';
dayjs.extend(relativeTime)


const Comment = ({story, taskStories, setTaskStories}) => {
    const { userProfile } = useContext(AuthContext)
    const { team } = useContext(TaskServicesContext)
    const [edit, setEdit] = useState<boolean>(false)
    const [commentText, setCommentText] = useState<string>("")

    const removeReadOnlyFields = (data) => {
        delete data.created_at
        delete data.likes
        delete data.type
        delete data.resource_subtype
        return data
      }
    
    const editComment = () => {
        setEdit(true)
        const html_string = story.text.html_text.replace("</body>","").replace("<body>","").replaceAll("\n","<br/>")
        setCommentText(html_string)
    }

    const onChangeCommentText = (e) => {
        setCommentText(e)
    }

    const updateCommentText = () => {
        
        const cleaned_description = convertQuillHTMLToAsana(convertQuillMentionsToAsana(commentText))
        const updatedComment = {...story, html_text:cleaned_description} 
        updateAsanaTaskStory(removeReadOnlyFields(updatedComment))
        updatedComment["type"] = "comment"
        updatedComment["likes"] = story.likes
        
        // const updatedStoriesArray = taskStories.map(x => x.gid === story.gid ? updatedComment : x)
        // setTaskStories(updatedStoriesArray)
        setEdit(false)
    }

    const deleteComment = async() => {
        deleteAsanaTaskComment(story.gid)
        const updatedSubStoriesArray = taskStories.slice().filter(x => x.gid !== story.gid)
        setTaskStories(updatedSubStoriesArray)
      }

    const onLikeComment = async() => {
        const updatedComment = {...story}
        updatedComment["liked"] = !story.liked
        updateAsanaTaskStory(removeReadOnlyFields(updatedComment))
        updatedComment["likes"] = story.likes
        updatedComment["type"] = "comment"
        const updatedStoriesArray = taskStories.map(x => x.gid === story.gid ? updatedComment : x)
        setTaskStories(updatedStoriesArray)
      }

    useEffect(() => {
        var relativeTime = require('dayjs/plugin/relativeTime')
        dayjs.extend(relativeTime)
    },[])

    useEffect(() => {
        const html_string = story?.html_text.replace("</body>","").replace("<body>","").replaceAll("\n","<br/>")
        setCommentText(html_string)
    },[story])

    

  return (
    story?.type === 'comment' ? 
        <div className={'TaskCard__StoriesContainer__Comment__Container'}>
            {story?.created_by.photo ? 
            <img style={{marginTop:"0.3rem"}} className='AsanaPhoto' src={story?.created_by.photo?.["image_27x27"]} alt="" />
            :
            
            <div style={{marginTop:"0.3rem"}} className="user__circle">
                  <div className="user__circle_inner">{getInitials(story?.created_by?.name)}</div>
                </div>
        }
            
            <div className='TaskCard__StoriesContainer__Comment__Container__Main'>
                <div className='TaskCard__StoriesContainer__Comment__Container__Header'>
                {story?.created_by?.name} <span className='TaskCard__StoriesContainer__storyTimeStamp'>{dayjs(story?.created_at).fromNow()}</span>
                 
                    <CustomTooltip title={
                    <React.Fragment>
                    {story.likes.length > 0 ? story.likes.map(x => <div>{x.user?.name}</div>) : <div>be the first to like!</div>}
                    </React.Fragment>}>
                    {story.liked?
                    <div id="" onClick={onLikeComment} className='btn--secondary--completed btn--M--centered no-borders-no-background success'>
                        { story.likes.length > 1 && 
                        <span className='Likes'>{story.likes.length}</span>
                        }
                        <ThumbUpIcon />
                    </div>
                    :
                    <div id="" onClick={onLikeComment} className='btn--tertiary btn--M--centered no-borders-no-background success'>
                        { story.likes.length > 1 && 
                        <span className='Likes'>{story.likes.length}</span>
                        }
                        <ThumbUpOutlinedIcon/>
                    </div>
                    }
                    </CustomTooltip>
                
                {story?.created_by?.gid === userProfile?.asana_user_id ?  <BasicMenu deleteItem={deleteComment} editItem={editComment} type="comment"/> : <></>}
                 
                </div>
                {!edit ? 
                <div dangerouslySetInnerHTML={{__html: commentText}} className='TaskCard__StoriesContainer__Comment__Container__Body' />
                :
                <div className='NewComment__Container TaskCard__StoriesContainer__Comment__Container__Body'>
                {/* <TextareaAutosize
                aria-label="empty textarea"
                placeholder="Insert a new comment ..."
                style={{ width: '100%', padding: 10 }}
                className='TaskCard__Description' value={commentText} onChange={(e) => onChangeCommentText(e)}
              /> */}
              <RichTextEditor htmlValue={commentText} setHTMLValue={setCommentText} onChange={(value) => setCommentText(value)} people={team} placeholder={""}/>
              <button className='btn--primary btn--S flex-row--S addComment__Button ' onClick={updateCommentText}>
                <span>Update Comment</span>
              </button>
              </div>
            }
            </div>
        </div>
        :
        <div key={story.gid} className={'TaskCard__StoriesContainer__updateLine'} >
            {story?.text}
            <span className='TaskCard__StoriesContainer__storyTimeStamp'>{dayjs(story?.created_at).fromNow()}</span>
        </div>
    )
}

export default Comment