import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { IFileResource } from "../../../types/API/fileResource";
import ImageCropper from "../../Common/ImageCropper";
import defaultCompany from "../../../static/img/default_company.png";
import ImageUploader from "../../Common/ImageUploader";
import MDIcons from "../../Common/MDIcons";
import { uploadFile } from "../../../APIFunctions/file";
import { changeCompanySettings } from "../../../APIFunctions/auth";
import "./style.css";

const CompanySettings = () => {
  // todo: a save button for saving the company settings changes

  const { selectedCompany, fetchUserProfile } = useContext(AuthContext);

  const [companySettings, setCompanySettings] = useState<any>(selectedCompany);
  const [isCropDialogOpen, setIsCropDialogOpen] = useState<boolean>(false);
  const [originalImage, setOriginalImage] = useState<string>("");
  const [image, setImage] = useState<string>("");
  const [imageFile, setImageFile] = useState<IFileResource | undefined>();

  function dataURLtoFile(dataurl, filename) {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
}

  const onSave = async () => {
    if (selectedCompany) {
      if (imageFile) {
        const upload = async () => {
          
          var uploadFileObj = dataURLtoFile(image,selectedCompany?.companyName + " logo")
          var result = await uploadFile(uploadFileObj, "avatar", imageFile.name);
          const updatedCompany = { ...selectedCompany,  };
          setCompanySettings(updatedCompany);
          const response = await changeCompanySettings(selectedCompany?.id, {...companySettings,companyAvatarImage: result.file.id});
      if (response["detail"] === "success") {
        fetchUserProfile(true);
      }
        };
        upload();
        
      }
      
    }
  };

  useEffect(() => {
    
  }, [image]);

  return (
    <div className="CompanySettings__Container">
      <div className="bodyContainer__InputSection">
        <div className="onboarding__block--y">
          <h2>Logo</h2>
          <div className="companyLogoEdit__Container">
            {isCropDialogOpen && (
              <ImageCropper image={image} setImage={setImage} originalImage={originalImage} isCropDialogOpen={isCropDialogOpen} setIsCropDialogOpen={setIsCropDialogOpen} />
            )}

            {image ? <img src={image} alt="" /> : <img src={defaultCompany} alt="" />}
            <div className="companyLogoEdit__Container__ButtonContainer">
              <ImageUploader setImage={setImage} setOriginalImage={setOriginalImage} setIsCropDialogOpen={setIsCropDialogOpen} setImageFile={setImageFile} />
              <p>Support Up to 2 MB, PNG, JPG</p>
              {image && (
                <button className={"btn--secondary btn--S"} onClick={() => setIsCropDialogOpen(true)}>
                  <MDIcons title="crop" />
                  Re-crop
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <button className="btn--primary btn--S" onClick={onSave}>
        save
      </button>
    </div>
  );
};

export default CompanySettings;
