import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { createTheme, ThemeProvider } from "@mui/material";

export default function LinkOptionsMenu({ deleteItem, editItem, pinItem,pinned, type }) {
  const theme = createTheme({
    components: {
      // Name of the component
      MuiTouchRipple: {
        styleOverrides: {
          root: {
            width: "2rem !important",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            border: type === "comment" || type === "link" ? "none !important" : "1px solid var(--Neutral200) !important",
            padding: "0rem",
            width: "auto",
            minWidth: "0rem",
            "&:hover": {
              backgroundColor: "transparent !important",
              svg: {
                path: {
                  fill: "var(--Success500)",
                },
              },
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: "#b72b1a !important",
            ".normal-action": {
              color: "var(--Neutral900)",
            },
          },
        },
      },
    },
  });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <Button
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        id={type === "comment" ? "" : "success"}
        className="btn--tertiary btn--M--centered selectTaskActions"
        data-test={`${type}-menu-button`}
      >
        <MoreHorizIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {(type === "comment" || type === "link") && (
          <MenuItem
            onClick={() => {
              handleClose();
              editItem();
            }}
          >
            <span className="normal-action">{type === "comment" ? "Edit Comment" : "Edit Link"}</span>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleClose();
            pinItem()
          }}
        >
          <span className="normal-action">{pinned ? "Unpin Link" : "Pin Link"}</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            deleteItem();
          }}
        >
          {type === "task" ? "Delete Task" : type === "comment" ? "Delete Comment" : "Delete Link"}
        </MenuItem>
        
      </Menu>
    </ThemeProvider>
  );
}
