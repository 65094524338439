import APIManger from '../APIManger';
import { CompanyCreateProp } from '../types/API/filtersData';
import { Company } from '../types/Company';
import { FilterItem } from '../types/CompanyFilters';
import { COMPANY_PROP_CREATE } from './apiConstants';

export const createCompanyProp = async (company: Company,data: CompanyCreateProp): Promise<FilterItem | null> => {
    let url = COMPANY_PROP_CREATE.replace("%COMPANY_ID%", company.id.toString())
    let apiResult: FilterItem = await APIManger.post(url, data)
    return apiResult;
}