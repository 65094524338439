import React from 'react'
import No_Payrolls_SVG from '../img/no-payrolls.svg'

const NoPayrollsView = ({milestoneType}) => {
  return (
    <div className='NoPayrolls__Container'>
        <img src={No_Payrolls_SVG} alt="" />
        <div className='NoPayrolls__Header'>No {milestoneType}s Are Available</div>
        <div className='NoPayrolls__Message'>Please add {milestoneType.toLowerCase()} milestones to the {milestoneType} section of the asana project</div>
        <a href='https://app.asana.com/' target="_blank" ><button className='btn--primary btn--L' style={{width:"260px"}}>Go to Asana</button></a>
    </div>
  )
}

export default NoPayrollsView