import { useEffect } from 'react';

export default function HideAllButton() {
    useEffect(() => {
        var x = document.getElementById('hide-all-columns-button')
        x?.parentElement?.remove();
    }, []);
    return (
        <span id="hide-all-columns-button" />
    )
}