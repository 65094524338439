import React from "react";
import { useCountdown } from "../../../../../hooks/useCountdown";

export type CountDownProps = {
  DueDateWithTime: Date;
};

const CountDown: React.FC<CountDownProps> = ({ DueDateWithTime }) => {
  const [days, hours, minutes, seconds] = useCountdown(DueDateWithTime);
  return (
    <>
      {days || hours || minutes || seconds ? (
        <div className="countDown__container">
          <div className="countDown__container__unit">
            <span className="countDown__container__unit__value">{days}</span>
            <span className="countDown__container__unit__label">Days</span>
          </div>
          <div className="countDown__container__unit">
            <span>:</span>
          </div>
          <div className="countDown__container__unit">
            <span className="countDown__container__unit__value">{hours}</span>
            <span className="countDown__container__unit__label">Hours</span>
          </div>
          <div className="countDown__container__unit">
            <span>:</span>
          </div>
          <div className="countDown__container__unit">
            <span className="countDown__container__unit__value">{minutes}</span>
            <span className="countDown__container__unit__label">Minutes</span>
          </div>
          <div className="countDown__container__unit">
            <span>:</span>
          </div>
          <div className="countDown__container__unit">
            <span className="countDown__container__unit__value">{seconds}</span>
            <span className="countDown__container__unit__label">Seconds</span>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default CountDown;
