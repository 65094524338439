import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { createTheme, ThemeProvider } from '@mui/material';
import { deleteAsanaTask } from '../../../APIFunctions/Asana';


  
export interface EmailChipMenuProps {
  addAccess: (accessPlatform:string) => void;
  appAccessFlag: boolean;
  sharepointAccessFlag: boolean;
  children: React.ReactNode;
}

export default function EmailChipMenu(props: EmailChipMenuProps) {

  const theme = createTheme (
    {
      components: {
        // Name of the component
        MuiButton: {
          styleOverrides: {
            // Name of the slot
            root: {
              // Some CSS
              border:"none !important",
              width:'3rem',
              minWidth:'0rem',
              "&:hover": {
                backgroundColor:'transparent !important',
                "svg": {
                  path: {
                    fill:"var(--Success500)"
                  }
                }
              },
            },
          },
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
                color:'#b72b1a !important',
                ".normal-action" : {
                  color:"var(--Neutral900)"
                },
            },
          },
        },
      },
      
    }
    
    ) 


  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <button
        data-test="email-chip-menu-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        // className='btn--tertiary btn--M--centered selectTaskActions'
      >
      {props.children}
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {/* {props.appAccessFlag && <MenuItem onClick={() => {handleClose(); props.addAccess("app")}}><span className='normal-action'>Invite to App</span></MenuItem>} */}
        {props.sharepointAccessFlag && <MenuItem onClick={() => {handleClose(); props.addAccess("sharepoint")}}><span className='normal-action'>Invite to Sharepoint</span></MenuItem>}
      </Menu>
    </ThemeProvider>
  );
}
