import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import React, { useContext } from "react";
import { reportTabIndexEnum } from "../../../MilestoneTaskComponent/Financials/types";
import { AuthContext } from "../../../../../../contexts/AuthContext";
import { appDateStringToDate, getMonthShort } from "../../../../../../utils/DateFunctions";
import { reportDict } from "../../../MilestoneTaskComponent/Financials/helpers/dictionary";
import { useSearchParams } from "react-router-dom";
import { DataContext } from "../../Context/DataContext";
import { DataGridContext } from "../../Context/DataGridContext";
import { QBOFilterContext } from "../../Context/FilterContext";
import { reportTabsDict } from "../../Types/Report";
import { CommentContext } from "../../Context/CommentContext";

const Presentation = ({ onExitTransactionsDrilling }) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const { selectedCompany } = useContext(AuthContext);
  const { reportTabIndex } = useContext(DataContext);
  const { drillFlag } = useContext(DataGridContext);
  const { startDate, endDate } = useContext(QBOFilterContext);
  const { reportPage } = useContext(CommentContext);
  return (
    <>
      <div className="FinancialReport__Container_ReportHeader">
        <div className="FinancialReport__Container_ReportHeader__Title">
          {drillFlag && (reportTabIndex === reportTabIndexEnum.INCOMESTATEMENTSUMMARY || reportTabIndex === reportTabIndexEnum.BALANCESHEETSUMMARY) && (
            <button onClick={onExitTransactionsDrilling}>
              <ArrowBackIosNewIcon />
              {"     "} <span>Back to summary report</span>
            </button>
          )}
          <h2>
            {selectedCompany?.companyName} {!drillFlag ? reportTabsDict[reportPage][reportTabIndex.toString()] : "Transactions Details"}
          </h2>
        </div>
        <div>
          {startDate &&
            endDate &&
            (!drillFlag ? getMonthShort(startDate) !== getMonthShort(endDate) ? `${getMonthShort(startDate)} - ${getMonthShort(endDate)}` : `${getMonthShort(startDate)}` : <></>)}
        </div>
        <div>
          {drillFlag ? (
            getMonthShort(appDateStringToDate(searchParams.get("drill_start_date") || searchParams.get("start_date") || "")) !==
            getMonthShort(appDateStringToDate(searchParams.get("drill_end_date") || searchParams.get("end_date") || "")) ? (
              `${getMonthShort(appDateStringToDate(searchParams.get("drill_start_date") || searchParams.get("start_date") || ""))} 
              - ${getMonthShort(appDateStringToDate(searchParams.get("drill_end_date") || searchParams.get("end_date") || ""))}`
            ) : (
              `${getMonthShort(appDateStringToDate(searchParams.get("drill_start_date") || searchParams.get("start_date") || ""))}`
            )
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default Presentation;
