import { createContext, useContext, useEffect, useState } from "react";
import { retrieveAsanaCustomFields, retrieveAsanaSections, retrieveAsanaTeam } from "../../APIFunctions/Asana";
import { section } from "../../types/AsanaTask";
import { AuthContext } from "../AuthContext";
import { TaskAuthContext } from "./TaskAuthContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export interface TaskServicesContextProps {
  userInTeam: boolean;
  team: any[];
  sectionsQuery:any
  customFields: any[];
  setCustomFields: (customFields: any[]) => void;
  projectID: string;
  tasksCategory: string;
  setTasksCategory: (tasksCategory: string) => void;
}

export const TaskServicesContext = createContext<TaskServicesContextProps>(null as any);

export const TaskServicesContextProvider = (props) => {
  const queryClient = useQueryClient()
  const { selectedCompany, userProfile, fetchUserProfile } = useContext(AuthContext);
  const { accessToken } = useContext(TaskAuthContext)
  const projectID = props.projectID ? props.projectID : selectedCompany?.asana_project_id;
  const [team, setTeam] = useState<any[]>([]);
  const [userInTeam, setUserInTeam] = useState<boolean>(false);
  const [sections, setSections] = useState<section[]>([]);
  const [customFields, setCustomFields] = useState<any[]>([]);
  const [tasksCategory, setTasksCategory] = useState<string>(props.tasks_category);
  const getTeam = async () => {
    const response = await retrieveAsanaTeam(projectID);
    if (response.response?.status === 401) {
      setTimeout(() => {
        getTeam();
      }, 1000);
    } else if (response.response?.status === 403) {
      // if the response comes back as not allowed then it means we do have a user token but the user is not allowed in.
      setUserInTeam(false);
    } else {
      const team = response?.data?.map((x) => ({
        value: x.user.gid,
        label: x.user.name,
        email:x.user.email,
        photo: { image_36x36: x.user.photo?.["image_36x36"], image_27x27: x.user.photo?.["image_27x27"],image_21x21: x.user.photo?.["image_21x21"]  },
      }));

      setTeam(team);

      if (userProfile?.asana_user_id !== "") {
        const userInTeam = team?.map((x) => x.value).includes(userProfile?.asana_user_id);
        if (userInTeam) {
          setUserInTeam(true);
        } else {
          setUserInTeam(false);
        }
      } else {
        fetchUserProfile(true);
      }
    }
  };

  const sectionsQuery =  useQuery({
    queryKey: ["month-end", "selected-milestone", "sections",projectID],
    queryFn: () => retrieveAsanaSections(projectID),
    staleTime: Infinity,
    enabled: !!projectID,
  });

  const getProjectCustomFields = async () => {
    if (projectID) {
      const response = await retrieveAsanaCustomFields(projectID);
      if (response.data) {
        const newCustomFields: any[] = [];
        const data = response.data;

        for (var i = 0; i < data.length; i++) {
          newCustomFields.push({
            name: data[i].custom_field.name,
            enum_options: data[i].custom_field.name === "Status" ? data[i].custom_field.enum_options.filter((x) => x.name !== "Blocked") : data[i].custom_field.enum_options,
            type: data[i].custom_field.type,
            gid: data[i].custom_field.gid,
          });
        }
        setCustomFields(newCustomFields);
      } else if (response.response?.status === 401) {
        setTimeout(() => {
          getProjectCustomFields();
        }, 1000);
      }
    }
  };

  useEffect(() => {
    queryClient.invalidateQueries(["month-end", "selected-milestone", "sections",projectID])
    getTeam();
    getProjectCustomFields();
    setTasksCategory(props.tasks_category);
  }, [selectedCompany, projectID, tasksCategory, accessToken]);

  return (
    <TaskServicesContext.Provider
      value={{
        userInTeam,
        team,
        sectionsQuery,
        customFields,
        setCustomFields,
        projectID,
        tasksCategory,
        setTasksCategory,
      }}
    >
      {props.children}
    </TaskServicesContext.Provider>
  );
};
