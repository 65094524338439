import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { ReactComponent as QBLogo } from "../../static/img/Onboarding/QuickBooks-Online-Logo.svg";
import MDIcons from "../Common/MDIcons";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { months } from "../../utils/DateFunctions";
import { OnboardingContext } from "../../contexts/Onboarding/OnboardingContext";
import SingleFileUploadDialog from "../Common/UploadFileDialog";
import { IFileResource } from "../../types/API/fileResource";
import { QuickBookConnectingStatus } from "../../types/Company";
import { SocketContext } from "../../contexts/SocketContext";
import { uppercaseFirstLetter } from "../../utils/helpers";
import { Onboarding } from "../../types/Onboarding";
import FinancialReportUploadDialog from "../Common/UploadFileDialog/FinancialReportUploadDialog";

export enum RadioSelection {
  QBO = 0,
  FileUpload = 1,
  NoData = 2,
}

const FormPage5 = () => {
  const { qb_ConnectingStatus } = useContext(SocketContext);
  const { onBack, onSubmit, done, onboardingInputs, setOnboardingInputs, onConnection, PLFile, setPLFile, BSFile, setBSFile } = useContext(OnboardingContext);
  const [fileUploadOpen, setFileUploadOpen] = useState(false);
  const [fileType, setFileType] = useState("");
  const [radioSelection, setRadioSelection] = useState<number>(onboardingInputs['data_type']);

  const submitDisabled = () =>{
    if (radioSelection === RadioSelection.FileUpload && !PLFile) return true
    if (radioSelection === RadioSelection.FileUpload && !BSFile) return true
    if (radioSelection === RadioSelection.QBO && (qb_ConnectingStatus !== QuickBookConnectingStatus.SUCCESSED)) return true
    return false
  }

  const onSuccess = (file: IFileResource) => {
    if (file.meta === "PLFile") setPLFile(file);
    if (file.meta === "BSFile") setBSFile(file);
  };

  const clearFile = (fileType: string) => {
    if (fileType === "BS") {
      setBSFile(undefined);
    }
    if (fileType === "PL") {
      setPLFile(undefined);
    }
  };

  const start_date_month = new Date(onboardingInputs["start_date"]).getMonth();
  var start_date_month_balancesheet: number = 0;
  var start_date_year_balancesheet: number = 0;
  if (start_date_month === 0) {
    start_date_month_balancesheet = new Date(onboardingInputs["start_date"]).getMonth() + 11;
    start_date_year_balancesheet = new Date(onboardingInputs["start_date"]).getFullYear() - 1;
  } else {
    start_date_month_balancesheet = new Date(onboardingInputs["start_date"]).getMonth() - 1;
    start_date_year_balancesheet = new Date(onboardingInputs["start_date"]).getFullYear();
  }
  var start_date_year: number = new Date(onboardingInputs["start_date"]).getFullYear();
  const actuals_date_month = new Date(onboardingInputs["last_actuals_date"]).getMonth();
  const actuals_date_year = new Date(onboardingInputs["last_actuals_date"]).getFullYear();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("Radio selection", event.target.value);
    setRadioSelection(Number(event.target.value));
    const newItem = { ...onboardingInputs };
    newItem["data_type"] = Number(event.target.value);
    setOnboardingInputs(newItem as Onboarding);
  };

  return (
    <>
      <h1>
        Connect to Accounting Software
        <br />
        <span>
          Your data is secure and confidential. Learn more at our{" "}
          <a href="https://www.mightydigits.com/terms-of-service" target="_blank" rel="noreferrer">
            <u>Terms of Use</u>
          </a>
        </span>
      </h1>

      <></>
      <div className="bodyContainer">
        <RadioGroup defaultValue={RadioSelection.QBO} name="controlled-radio-buttons-group" value={radioSelection} onChange={handleChange} sx={{ my: 1 }}>
          <div className="flex_col border_bottom vertical_padding">
            <div className={radioSelection === RadioSelection.QBO ? "bodyContainer__QBORow" : "bodyContainer__QBORow__disabled"}>
            <div className={`flex_row`}>
              <Radio value={RadioSelection.QBO} />
              <QBLogo />
            </div>
              {qb_ConnectingStatus === QuickBookConnectingStatus.NOT_CONNECTED || qb_ConnectingStatus === QuickBookConnectingStatus.CONSENTING ? (
                <button className="btn--primary btn--S" onClick={() => onConnection(true)}>
                  Connect
                </button>
              ) : (
                <>
                  {qb_ConnectingStatus === QuickBookConnectingStatus.SUCCESSED ? (
                    <>
                      <div className="QBO__Container__Success">
                        <div>
                          <MDIcons title={"check_mark"} /> Your account is connected!
                        </div>
                        <div className="QBO__Container__Disconnect" onClick={() => onConnection(false)}>
                          Disconnect
                        </div>
                      </div>
                    </>
                  ) : qb_ConnectingStatus === QuickBookConnectingStatus.FAILED ? (
                    <>
                      <div className="QBO__Container__Failure">
                        <MDIcons title={"QBFailure"} /> Something went wrong!
                      </div>
                      <div className="btn--secondary btn--S" onClick={() => onConnection(true)}>
                        Try Again
                      </div>
                    </>
                  ) : (
                    <>
                      {/* <div className='QBO__Container__Failure'>
                  </div> */}
                      <div className="btn--secondary btn--S" style={{ cursor: "not-allowed" }} onClick={() => onConnection(true)}>
                        Connect
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
            <div className={`${radioSelection === RadioSelection.QBO ? "bodyContainer__QBORow" : "bodyContainer__QBORow__disabled"} margin-left`}>
              {qb_ConnectingStatus === QuickBookConnectingStatus.SUCCESSED && (
                <>
                  <div className="bodyContainer__InputSection">
                    <FormControlLabel
                      label="Pull my P&L data by class"
                      control={
                        <Checkbox
                          inputProps={{ "aria-label": "qbo_classes_enabled" }}
                          checked={onboardingInputs["qbo_classes_enabled"]}
                          onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                            const newItem = { ...onboardingInputs };
                            newItem["qbo_classes_enabled"] = checked;
                            setOnboardingInputs(newItem as any);
                          }}
                          sx={{
                            padding: 0,
                            color: "#C0C0CF",
                            "&.Mui-checked": {
                              color: "#85B440",
                            },
                            "& .MuiSvgIcon-root": {
                              fontSize: 24,
                            },
                          }}
                        />
                      }
                      sx={{
                        mt: 1.25,
                        color: "#32324D",
                        "& .MuiFormControlLabel-label": {
                          ml: 1,
                          fontSize: 12,
                        },
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex_col vertical_padding bottom_border">
          <div className={`flex_row ${radioSelection === RadioSelection.FileUpload ? "" : "disabled"}`}>
            <Radio value={RadioSelection.FileUpload} />
            <span>Upload Financial Statements manually.</span>
          </div>

          {radioSelection === RadioSelection.FileUpload && (
            <>
              <div
                className={
                  radioSelection !== RadioSelection.FileUpload
                    ? "flex_row__spaceBetween bodyContainer__NOQBORow__UploadRow__disabled"
                    : "flex_row__spaceBetween bodyContainer__NOQBORow__UploadRow"
                }
              >
                <div className="flex_col">
                  <label>Monthly Profit and Loss</label>
                  <span>
                    The report should start from {uppercaseFirstLetter(months[start_date_month]) + " " + start_date_year} (Start Date) to{" "}
                    {uppercaseFirstLetter(months[actuals_date_month]) + " " + actuals_date_year} (Last month of Actuals)
                  </span>
                </div>
                {PLFile ? (
                  <div className="flex_row">
                    <MDIcons title={"check_mark"} />
                    <div>{PLFile.name}</div>
                    <label onClick={() => clearFile("PL")}>
                      <MDIcons title={"close"} />
                    </label>
                  </div>
                ) : (
                  <div>
                    <button
                      onClick={() => {
                        setFileType("PLFile");
                        setFileUploadOpen(true);
                      }}
                      className="btn--primary btn--S"
                    >
                      <MDIcons title={"upload"} />
                      Upload
                    </button>
                  </div>
                )}
              </div>
              <div
                className={
                  radioSelection !== RadioSelection.FileUpload
                    ? "flex_row__spaceBetween bodyContainer__NOQBORow__UploadRow__disabled"
                    : "flex_row__spaceBetween bodyContainer__NOQBORow__UploadRow"
                }
              >
                <div className="flex_col">
                  <label>Monthly Balance Sheet</label>
                  <span>
                    The report should start from {uppercaseFirstLetter(months[start_date_month_balancesheet]) + " " + start_date_year_balancesheet} (Start Date) to{" "}
                    {uppercaseFirstLetter(months[actuals_date_month]) + " " + actuals_date_year} (Last month of Actuals)
                  </span>
                </div>
                {BSFile ? (
                  <div className="flex_row">
                    <MDIcons title={"check_mark"} />
                    <div>{BSFile.name}</div>
                    <label onClick={() => clearFile("BS")}>
                      <MDIcons title={"close"} />
                    </label>
                  </div>
                ) : (
                  <div>
                    <button
                      onClick={() => {
                        setFileType("BSFile");
                        setFileUploadOpen(true);
                      }}
                      className="btn--primary btn--S"
                    >
                      <MDIcons title={"upload"} />
                      Upload
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
          </div>
          <div
            className={
              `${radioSelection !== RadioSelection.NoData
                ? "flex_row disabled"
                : "flex_row"} vertical_padding`
            }
          >
            <Radio value={RadioSelection.NoData} />
            <span>I do not have data to provide.</span>
          </div>
        </RadioGroup>
        <div className="footer--btns">
          <button className="btn--M btn--tertiary" onClick={onBack}>
            Previous
          </button>
          <input className="btn--primary btn--M " type="submit" value={"Submit All Answers"} onClick={onSubmit} disabled={submitDisabled()}/>
        </div>
      </div>

      <FinancialReportUploadDialog
        open={fileUploadOpen}
        meta={fileType}
        onClose={() => {
          setFileUploadOpen(false);
          setFileType("");
        }}
        onSuccess={onSuccess}
      />
    </>
  );
};

export default FormPage5;
