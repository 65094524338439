import React from 'react'
import TransactionLineTable from './TransactionLineTable'

const JournalEntryTransaction = ({transaction}) => {
  return (
    <div>
      <div className="TransactionDetail__GroupContainer">
        <div className="TransactionDetail__ParameterContainer">
          <span>Journal Entry date: </span>
          <div>{transaction?.["detail"]?.["JournalEntry"]?.["TxnDate"]}</div>
        </div>
        </div>
        <TransactionLineTable lines={transaction?.["detail"]?.["JournalEntry"]?.["Line"]} transactionID={transaction?.["detail"]?.["JournalEntry"]?.["Id"]} transaction_type="Journal Entry"/>
        <div className="TransactionDetail__Subtotal">
          <span>Total Amount</span>
          <div>${transaction?.["detail"]?.["JournalEntry"]?.["TotalAmt"]}</div>
        </div>
    </div>
  )
}

export default JournalEntryTransaction