
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { OptionTypeBase } from "react-select";
import { loadReportData } from "../../APIFunctions/data_modeling";
import { useInterval } from "../../hooks";
import { AccountList, BalanceSheet, ProfiAndLoss } from "../../types/API/FinancialReport";
import { FinancialStatements } from "../../types/FinancialStatements.ts/FinancialStatements";
import { Account } from "../../types/FinancialStatements.ts/interfaces";
import { LoaderStatus } from "../../types/Loaders";
import {
  appDateStringToDate,
  convertMonthLongYearStringToDate,
  convertMonthYearStringToDate,
  createDateWithOffset,
  createDateWithOffsetFromDate,
  dateObjToAppString,
  dateObjToAsanaString,
  endOfMonth,
  FirstDayCurrentYear,
  QuarterStartEnd,
  startOfMonth,
} from "../../utils/DateFunctions";
import { showToast } from "../../utils/toastHelpers";
import { AuthContext } from "../AuthContext";
import { SocketContext } from "../SocketContext";
import { FinancialStatementFilterContext } from "./FilterContext";
import { checkSyncStatus, syncQbData } from "../../APIFunctions/financial_statement";
import { MonthEndCloseContext } from "../TicketingSystem/MonthEndCloseContext";
import { MilestoneContext } from "../TicketingSystem/MilestoneContext";
import { useSearchParams } from "react-router-dom";
import { GridCellParams } from "@mui/x-data-grid-pro";
import {  useQuery, useQueryClient } from "@tanstack/react-query";
import { useSelectedMonthEndCloseMilestone } from "../../components/TicketingSystem/hooks/useSelectedMilestone";
import moment from "moment";


export const PeriodDisplayOptions = [
  { value: "Month", label: "Month" },
  { value: "Quarter", label: "Quarter" },
  { value: "Year", label: "Year" },
];

export interface DataConextProps {
  financialReport_LoaderStatus: LoaderStatus;
  setLoaderStatus: (status: LoaderStatus) => void;
  SyncQBData: () => void;
  financialReport_LoaderValue: number;
  setLoaderValue: (status: number) => void;
  financialData: Account[];
  setStartDate: (startDate: Date) => void;
  setEndDate: (endDate: Date) => void;
  startDate: Date;
  endDate: Date;
  reportDataModel: FinancialStatements;
  selectedPeriodName: OptionTypeBase;
  columnsHeaders: string[];
  setColumnsHeaders: (columnsHeaders: string[]) => void;
  setSelectedPeriodName: (option: OptionTypeBase) => void;
  loadEntireData: () => Promise<void>;
  profitAndLoss: ProfiAndLoss;
  balanceSheet: BalanceSheet;
  onSetDrilledCellParams: (params) => void;
  drilledTransactionsData: any;
  drillViewFlag: boolean;
  setDrillViewFlag: (drillViewFlag: boolean) => void;

  setDrilledCellParams: (drilledCellParams: any) => void;
  currentSyncInformation: any;
}

export const DataContext = createContext<DataConextProps>(null as any);

export const DataContextProvider = (props) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const accountID = searchParams.get("account_id");
  const { selectedCompany } = useContext(AuthContext);
  const { selectedFilters } = useContext(FinancialStatementFilterContext);
  const { updatelogicAppTracking, setLoadFinancialRepoortData } = useContext(SocketContext);
  const [selectedPeriodName, setSelectedPeriodName] = useState<OptionTypeBase>(PeriodDisplayOptions[0]);
  const [accountList, setAccount_list] = useState<AccountList>({});
  const [profitAndLoss, setProfitAndLoss] = useState<ProfiAndLoss>({});
  const [balanceSheet, setBalanceSheet] = useState<BalanceSheet>({});
  const [financialReport_LoaderStatus, setLoaderStatus] = useState<LoaderStatus>(LoaderStatus.Loading);
  const [financialReport_LoaderValue, setLoaderValue] = useState<number>(1);
  const [startDate, setStartDate] = useState<Date>(FirstDayCurrentYear());
  const [endDate, setEndDate] = useState<Date>(new Date(new Date().getFullYear(), 11, 31));
  const [columnsHeaders, setColumnsHeaders] = useState<string[]>([]);
  const [reportDataModel, setReportDataModel] = useState<FinancialStatements>(new FinancialStatements(selectedCompany));
  const { monthEndPeriod, getTransactionsData, setReportTabIndex, reportTabIndex, setPLTransactionsReport, setBSTransactionsReport } = useContext(MonthEndCloseContext);
  const { setPayrollLoading } = useContext(MilestoneContext);
  const [drilledCellParams, setDrilledCellParams] = useState<any>(null);
  const [drillViewFlag, setDrillViewFlag] = useState<boolean>(false);
  const [checkSyncStatusInterval, setCheckSyncStatusInterval] = useState<number>(1000 * 60 * 60 * 24);
  const [syncStartTime, setSyncStartTime] = useState<Date>(new Date());

  const queryClient = useQueryClient();
  const selectedMilestone = useSelectedMonthEndCloseMilestone();

  useEffect(() => {
    if (drilledCellParams) {
      queryClient.invalidateQueries(["drilled-transactions", drilledCellParams?.row?.id, drilledCellParams?.field]);
    }
  }, [drilledCellParams]);

  // useQuery
  const onDrillTransactions = async () => {
    let accountID;
    let sectionName;
    let startDate;
    let endDate;
    if (drilledCellParams?.field.includes("Q")) {
      const obj = QuarterStartEnd(drilledCellParams?.field);
      startDate = dateObjToAsanaString(obj["startDate"]);
      endDate = dateObjToAsanaString(obj["endDate"]);
    } else if (drilledCellParams?.field.includes("Total")) {
      startDate = dateObjToAsanaString(startOfMonth(appDateStringToDate(searchParams.get("start_date") || startDate)));
      endDate = dateObjToAsanaString(endOfMonth(appDateStringToDate(searchParams.get("end_date") || endDate)));
    } else if (drilledCellParams?.field.startsWith("20")) {
      startDate = dateObjToAsanaString(startOfMonth(new Date(drilledCellParams?.field, 0, 1)));
      endDate = dateObjToAsanaString(endOfMonth(new Date(drilledCellParams?.field, 11, 1)));
    } else {
      
      startDate = dateObjToAsanaString(startOfMonth(convertMonthYearStringToDate(drilledCellParams?.field)));
      endDate = dateObjToAsanaString(endOfMonth(convertMonthYearStringToDate(drilledCellParams?.field)));
    }

    const metaType = drilledCellParams?.row.metaType;
    if (metaType === "RetainedEarnings") {
      accountID = drilledCellParams?.row.id;
    }
    if (metaType === "Total Account") {
      accountID = drilledCellParams?.row.id.split("(")[1].split(")")[0];
    }
    if (metaType === "Total Section") {
      sectionName = drilledCellParams?.row.id.split("(")[1].split(")")[0];
    }

    if (metaType === "Formula") {
      sectionName = drilledCellParams?.row.id;
    }
    setSearchParams((prevParams) => {
      return new URLSearchParams({
        ...Object.fromEntries(prevParams.entries()),
        ...{
          drill_transactions: "true",
          row_id: drilledCellParams?.row.id,
          account_id: accountID,
          section_name: sectionName,
          drill_start_date: dateObjToAppString(moment(startDate), "-"),
          drill_end_date: dateObjToAppString(moment(endDate), "-"),
        },
      });
    });

    if (drilledCellParams && (accountID || sectionName)) {
      const response = await getTransactionsData({ startDate: startDate, endDate: endDate, accountID: accountID, sectionName: sectionName });

      return response;
    } else {
      return [];
    }
  };

  const drilledTransactionsData = useQuery({
    queryKey: ["drilled-transactions", drilledCellParams?.row?.id, drilledCellParams?.field],
    queryFn: onDrillTransactions,
    staleTime: 60 * 60 * 1000,
    enabled: !!(drilledCellParams && drilledCellParams?.row?.id && drilledCellParams?.field),
  });
  // checkSyncStatus(company)
  const currentSyncInformation = useQuery({
    queryKey: ["current-sync-info"],
    queryFn: () => checkSyncStatus(selectedCompany, selectedMilestone.data.data.gid),
    refetchInterval: checkSyncStatusInterval,
    enabled: !!(selectedCompany && selectedMilestone?.data?.data),
    staleTime:60*60*1000
  });

  const refreshRelatedSyncData = async () => {
    await queryClient.invalidateQueries({ queryKey: ["PLTransactionsReportData", startDate.toString(), endDate.toString()] });
    await queryClient.invalidateQueries({ queryKey: ["BSTransactionsReportData", startDate.toString(), endDate.toString()] });
    await queryClient.invalidateQueries({ queryKey: ["cellComments", "resolved"] });
    await queryClient.invalidateQueries({ queryKey: ["cellComments", "unresolved"] });
    await loadEntireData();
  };


  useEffect(() => {
    if (currentSyncInformation.data?.status === "Running" && checkSyncStatusInterval !== 1000) {
      setCheckSyncStatusInterval(1000);
    }

    if (currentSyncInformation.data?.status === "Idle") {
      setCheckSyncStatusInterval(1000 * 60 * 60 * 24);
      refreshRelatedSyncData();
    }
  }, [currentSyncInformation.fetchStatus]);



  // const getTransactionsReportData = async () => {
  //   setPayrollLoading(true);
  //   const startDateString = dateObjToAsanaString(startOfMonth(startDate));
  //   const endDateString = dateObjToAsanaString(endOfMonth(endDate));
  //   const reponseBSTransactionReport = await getTransactionsData({ startDate: startDateString, endDate: endDateString, sectionName: "Balance Sheet" });
  //   setBSTransactionsReport(reponseBSTransactionReport);
  //   setPayrollLoading(false);
  // };

  const onSetDrilledCellParams = async (params: GridCellParams) => {
    setDrillViewFlag(true);
    setDrilledCellParams(params);
  };

  const filterByParams = () => {
    const selectedPeriod = searchParams.get("view_by") ? PeriodDisplayOptions.find((x) => x.value === searchParams.get("view_by")) : PeriodDisplayOptions[0];
    if (selectedPeriod) setSelectedPeriodName(selectedPeriod);
    const selectedStartDate = searchParams.get("start_date");

    if (selectedStartDate) {
      const newDate = new Date(selectedStartDate);
      setStartDate(newDate);
    }
    const selectedEndDate = searchParams.get("end_date");
    if (selectedEndDate) {
      const newDate = new Date(selectedEndDate);
      setEndDate(newDate);
    }
  };

  useEffect(() => {
    if (selectedCompany) {
      // setLoaderValue(0);
      setLoaderStatus(LoaderStatus.Loading);
    }
    setReportTabIndex(searchParams.get("report_type") ? Number(searchParams.get("report_type")) : 0);
  }, []);

  const loadEntireData = useCallback(async () => {
    if (selectedCompany) {
      // setLoaderValue(0);
      setLoaderStatus(LoaderStatus.Loading);
      const report_data = await loadReportData(selectedCompany);
      setLoaderStatus(LoaderStatus.Loaded);
      setAccount_list(report_data.account_list);
      setProfitAndLoss(report_data.profit_and_loss);
      setBalanceSheet(report_data.balance_sheet);
      setLoaderStatus(LoaderStatus.GeneratingReports);
    }
  }, [selectedCompany]);

  useEffect(() => {
    setLoadFinancialRepoortData(() => () => loadEntireData());
  }, [loadEntireData]);

  useEffect(() => {
    if (financialReport_LoaderStatus !== LoaderStatus.GeneratingReports) return;
    setTimeout(async () => {
      if (profitAndLoss && balanceSheet && accountList) {
        setReportDataModel(new FinancialStatements(selectedCompany, profitAndLoss, balanceSheet, accountList, startDate, endDate));
        if (financialReport_LoaderStatus === LoaderStatus.GeneratingReports) setLoaderStatus(LoaderStatus.Rendered);
      }
    });
  }, [selectedCompany, setLoaderStatus, financialReport_LoaderStatus, profitAndLoss, balanceSheet, accountList]);

  useEffect(() => {
    setColumnsHeaders(reportDataModel.getReportHeaders(selectedPeriodName.value, startDate, endDate, searchParams.get("report_type") ? Number(searchParams.get("report_type")) : 0));
    // setURLParams()
  }, [selectedPeriodName.value, startDate, endDate, reportTabIndex]);

  useEffect(() => {
    loadEntireData();
  }, [selectedCompany]);

  useEffect(() => {
    filterByParams();
  }, []);

  // const setURLParams = useCallback(() =>{
  // window.history.pushState({},"",`/${selectedCompany?.getURL()}/financialreport?view_by=${selectedPeriodName.value}&start_date=${dateObjToAsanaString(startDate)}&end_date=${dateObjToAsanaString(endDate)}`)
  // console.log(`/${selectedCompany?.getURL()}/financialreport?view_by=${selectedPeriodName.value}&start_date=${dateObjToString(startDate)}&end_date=${dateObjToString(endDate)}`)
  // },[startDate,endDate,selectedPeriodName])

  const financialData = [];

  // const SyncQBData = useCallback(async () => {
  //     if (selectedCompany) {
  //         const response = await syncQbData(selectedCompany);
  //         if (response)
  //             updatelogicAppTracking("Syncing QB Data", "info", "The process is initiated.")

  //     }
  //     else {
  //         showToast('error', 'There is no selected division.', "Syncing QB Data")
  //     }
  // }, [selectedCompany, updatelogicAppTracking])
  // const repetitiveCheckSync = async (company) => {
  //   let status = "Running";
  //   const check = async () => {
  //     const response = await checkSyncStatus(company);
  //     if (response) {
  //       status = response.status;
  //     }
  //   };
  //   await check();
  //   if (status === "Running") {
  //     setTimeout(() => repetitiveCheckSync(company), 5000);
  //   } else if (status === "Completed") {
  //     loadEntireData();
  //     setSyncingInProgress(false);
  //   } else {
  //     console.log("sync status", status);
  //   }
  // };

  const SyncQBData = useCallback(async () => {
    if (selectedCompany && selectedMilestone.data) {
      // todo: set the start and end date dynamically
      // setPayrollLoading(true);
      setCheckSyncStatusInterval(1000);
      // setSyncStartTime(new Date())
      console.log("filter start date",startDate,"filter end date",endDate)
      const response = syncQbData(selectedCompany, dateObjToAsanaString(startDate), dateObjToAsanaString(endDate), selectedMilestone.data.data.gid);

      // if (response)
      //     updatelogicAppTracking("Syncing QB Data", "info", "The process is initiated.")

      // }
      // else {
      //     showToast('error', 'There is no selected division.', "Syncing QB Data")
    }
  }, [selectedCompany, selectedMilestone.data,startDate,endDate ]);

  useEffect(() => {
    const startDateURL = searchParams.get("start_date");
    if (monthEndPeriod && !startDateURL) {
      const endDateInitial = endOfMonth(convertMonthLongYearStringToDate(monthEndPeriod));
      const startDateInitial = startOfMonth(createDateWithOffsetFromDate(endDateInitial, 0, -5, 0));

      setStartDate(startDateInitial);
      setEndDate(endDateInitial);

      if (!startDateURL) {
        setSearchParams((prevParams) => {
          return new URLSearchParams({
            ...Object.fromEntries(prevParams.entries()),
            ...{ start_date: dateObjToAppString(startDateInitial, "-"), end_date: dateObjToAppString(endDateInitial, "-") },
          });
        });
      }
    }
  }, [monthEndPeriod]);

  // useEffect(() => {
  // getTransactionsReportData();
  // }, [startDate, endDate]);

  useInterval(
    () => {
      const currentLoaderValue = financialReport_LoaderValue + Math.floor(Math.random() * 2 + 1);
      switch (financialReport_LoaderStatus) {
        case LoaderStatus.Loading:
          if (currentLoaderValue < 20) setLoaderValue(20);
          else if (currentLoaderValue > 90) setLoaderValue(90);
          else setLoaderValue(currentLoaderValue);
          break;
        case LoaderStatus.Loaded:
          if (currentLoaderValue < 40) setLoaderValue(40);
          else if (currentLoaderValue > 80) setLoaderValue(80);
          else setLoaderValue(currentLoaderValue);
          break;
        case LoaderStatus.GeneratingReports:
          if (currentLoaderValue < 80) setLoaderValue(80);
          else if (currentLoaderValue > 100) setLoaderValue(100);
          else setLoaderValue(currentLoaderValue);
          break;
        default:
          break;
      }
    },
    250,
    financialReport_LoaderStatus !== LoaderStatus.Rendered
  );

  return (
    <DataContext.Provider
      value={{
        financialReport_LoaderStatus,
        loadEntireData,
        SyncQBData,
        setLoaderStatus,
        financialReport_LoaderValue,
        setLoaderValue,
        financialData,
        setStartDate,
        setEndDate,
        startDate,
        endDate,
        reportDataModel,
        profitAndLoss,
        balanceSheet,
        setSelectedPeriodName,
        selectedPeriodName,
        columnsHeaders,
        setColumnsHeaders,
        onSetDrilledCellParams,
        drilledTransactionsData,
        drillViewFlag,
        setDrillViewFlag,
        setDrilledCellParams,
        currentSyncInformation,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};
