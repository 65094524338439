import React, { useContext, useEffect, useState } from "react";
import DataGridWithCommentContextMenu from "../../../../../Common/MUIDataGrid/ContextMenuDataGrid";
import { MonthEndCloseContext } from "../../../../../../contexts/TicketingSystem/MonthEndCloseContext";
import { GridColDef } from "@mui/x-data-grid-pro";
import { transactionsDataGridRow } from "./helpers";
import { CommentContext } from "../../Context/CommentContext";
import { useCellComments } from "../../Hooks/useComments";
import { DataContext } from "../../Context/DataContext";

const TransactionLineTable = ({ lines, transactionID, transaction_type }) => {
  const { selectedCell, setSelectedCell, createCommentMutation, commentsTabIndex, commentCellCoordinates,showCommentsPan, reportPage } = useContext(CommentContext)

  // comments
  const cellComments = useCellComments(reportPage);

  const [rows, setRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);

  const createDataGridRows = () => {
    const dataGridRows = lines
      ?.map((line) => {
        return transactionsDataGridRow(line, transaction_type, cellComments, transactionID, commentsTabIndex);
      })
      .filter((x) => x && x);
    
    setRows(dataGridRows);
  };

  const salesReceiptColumns: GridColDef[] = [
    { field: "id", headerName: "Line #", flex: 1 },
    {
      field: "product_service",
      headerName: "Product/Service",
      flex: 2,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
    },
    {
      field: "qty",
      headerName: "Qty",
      type: "number",
      flex: 1,
    },
    {
      field: "rate",
      headerName: "Rate",
      type: "number",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      flex: 1,
    },
  ];

  const journalEntryColumns: GridColDef[] = [
    { field: "id", headerName: "#", flex: 1 },
    {
      field: "account",
      headerName: "ACCOUNT",
      flex: 2,
    },
    {
      field: "debits",
      headerName: "DEBITS",
      flex: 2,
    },
    {
      field: "credits",
      headerName: "CREDITS",
      type: "number",
      flex: 1,
    },

    {
      field: "name",
      headerName: "NAME",
      type: "number",
      flex: 1,
    },
  ];

  const depositColumns: GridColDef[] = [
    { field: "id", headerName: "#", flex: 1 },
    {
      field: "received_from",
      headerName: "RECEIVED FROM",
      flex: 2,
    },
    {
      field: "account",
      headerName: "ACCOUNT",
      flex: 2,
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      flex: 1,
    },

    {
      field: "amount",
      headerName: "AMOUNT",
      type: "number",
      flex: 1,
    },
    {
      field: "customer",
      headerName: "CUSTOMER",
      flex: 1,
    },
  ];

  const expenseColumns: GridColDef[] = [
    { field: "id", headerName: "#", flex: 1 },
    {
      field: "category",
      headerName: "CATEGORY",
      flex: 2,
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      flex: 2,
    },
    {
      field: "amount",
      headerName: "AMOUNT",
      type: "number",
      flex: 1,
    },
    {
      field: "customer",
      headerName: "CUSTOMER",
      flex: 1,
    },
  ];

  useEffect(() => {
    createDataGridRows();
    // transaction_type !== "Journal Entry" ? setColumns(salesReceiptColumns) : setColumns(journalEntryColumns);
    if (transaction_type === "Deposit") {
      setColumns(depositColumns);
    }
    if (transaction_type === "Journal Entry") {
      setColumns(journalEntryColumns);
    }
    if (transaction_type === "Sales Receipt" || transaction_type === "Credit Memo") {
      setColumns(salesReceiptColumns);
    }
    if (transaction_type === "Expense" || transaction_type === "Bill" || transaction_type === "Invoice" ||  transaction_type === "Check") {
      setColumns(expenseColumns);
    }
  }, [lines, cellComments.data, commentsTabIndex]);

  

  return lines ? (
    <DataGridWithCommentContextMenu
      rows={rows}
      columns={columns}
      selectedCell={selectedCell}
      setSelectedCell={setSelectedCell}
      createCommentMutation={createCommentMutation}
      coordinates={commentCellCoordinates}
      transactionType={transaction_type}
      showCommentsPan={showCommentsPan}
    />
  ) : (
    <></>
  );
};

export default TransactionLineTable;
