import React from "react";
import { reportTabIndexEnum } from "../../../MilestoneTaskComponent/Financials/types";
import { useSearchParams } from "react-router-dom";
import Presentation from "./Presentation";

const Container = ({ setDrillFlag, setDrilledCellParams, setDetailRowsDisplay, setDetailHeadersDisplay, setReportDetailLevel}) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const onExitTransactionsDrilling = () => {
    setReportDetailLevel("Summary")
    setDrillFlag(false);
    setDetailRowsDisplay([]);
    setDetailHeadersDisplay([]);
    setDrilledCellParams(null);
    setSearchParams((prevParams) => {
      return new URLSearchParams({
        ...Object.fromEntries(prevParams.entries()),
        ...{
          drill_transactions: "false",
        },
      });
    });
  };

  return <Presentation onExitTransactionsDrilling={onExitTransactionsDrilling} />;
};

export default Container;
