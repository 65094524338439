import React from "react";
import TransactionLineTable from "./TransactionLineTable";

const CreditMemoTransaction = ({ transaction }) => {
  return (
    <div>
      <div className="TransactionDetail__GroupContainer">
        <div className="TransactionDetail__ParameterContainer">
          <span>Customer: </span>
          <div>{transaction?.["detail"]?.["CreditMemo"]?.["CustomerRef"]?.["name"]}</div>
        </div>
        <div className="TransactionDetail__ParameterContainer">
          <span>Email: </span>
          <div>{transaction?.["detail"]?.["CreditMemo"]?.["BillEmail"]?.["Address"]}</div>
        </div>
        <div className="TransactionDetail__ParameterContainer">
          <span>Billing address</span>
          <div className="TransactionDetail__ParameterContainer__MultiLine">
          <span>{transaction?.["detail"]?.["CreditMemo"]?.["BillAddr"]?.["Line1"]}, </span>
          <span>{transaction?.["detail"]?.["CreditMemo"]?.["BillAddr"]?.["Line2"]}, </span>
          <span>{transaction?.["detail"]?.["CreditMemo"]?.["BillAddr"]?.["Line3"]}</span>
          </div>
        </div>
        <div className="TransactionDetail__ParameterContainer">
          <span>Credit Memo date: </span>
          <div>{transaction?.["detail"]?.["CreditMemo"]?.["TxnDate"]}</div>
        </div>
        <div className="TransactionDetail__ParameterContainer">
          <span>Deposit date: </span>
          <div>{transaction?.["detail"]?.["CreditMemo"]?.["TxnDate"]}</div>
        </div>
      </div>
      <TransactionLineTable lines={transaction?.["detail"]?.["CreditMemo"]?.["Line"]} transactionID={transaction?.["detail"]?.["CreditMemo"]?.["Id"]} transaction_type="Credit Memo" />
      <div className="TransactionDetail__Subtotal">
        <span>Total Amount</span>
        <div>${transaction?.["detail"]?.["CreditMemo"]?.["TotalAmt"]}</div>
      </div>
    </div>
  );
};

export default CreditMemoTransaction;
