import React, { useContext, useEffect, useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { createAsanaSubTask, retrieveAsanaSubTask, updateAsanaTask } from "../../../APIFunctions/Asana";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AssigneeMenu from "../../Common/DropDownComponent/Assignee";
import { MilestoneContext } from "../../../contexts/TicketingSystem/MilestoneContext";
import CustomDatePicker from "../../Common/CustomDatePicker";
import { dateFromString } from "../../../utils/helpers";
import { dateObjToAsanaString } from "../../../utils/DateFunctions";

const SubTaskLine = ({ subtask, subtaskName, setSubTask, openSubTask, changeSubTaskInList, sectionName }) => {
  const { setPayrollLoading, payrollLoading } = useContext(MilestoneContext);
  const [completed, setCompleted] = useState<boolean>(subtask?.completed);
  const [editFlag, setEditFlag] = useState<boolean>(false);
  const [subTaskName, setSubTaskName] = useState<string>(subtask.name);
  const [assignee, setAssignee] = useState<any>(subtask?.assignee);
  const [dueDate, setDueDate] = useState<string | null>(subtask?.due_on);

  const updateSubTaskInAsana = async (updatedSubtask) => {
    setPayrollLoading(true);

    const response = await updateAsanaTask({ gid: updatedSubtask.gid, name: updatedSubtask.name, assignee: updatedSubtask.assignee?.gid, notes: updatedSubtask.notes });
    if (!response["data"]) {
      setInterval(() => {
        updateAsanaTask(updatedSubtask);
      }, 2000);
    }
    setPayrollLoading(false);
  };

  const onDateChange = (date: Date, task, updateTaskState) => {
    const newItem = {};
    newItem["due_on"] = dateObjToAsanaString(date);
    newItem["gid"] = task.gid;
    const updatedTask = { ...task };
    updatedTask["due_on"] = dateObjToAsanaString(date);
    updateTaskState(updatedTask);
    // setSubTask(updatedTask)
    updateAsanaTask(newItem);
    setDueDate(dateObjToAsanaString(date));
  };

  const onChangeAssignee = (subTask) => {
    setAssignee(subTask.assignee);
    setSubTask(subTask);
    changeSubTaskInList(subTask);
    updateSubTaskInAsana(subTask);
  };

  // const onClearAssignee = () => {
  //   setAssignee(null);
  //   setSubTask({ ...subtask, assignee: { gid: null } })
  //   updateSubTaskInAsana({ ...subtask, assignee: { gid: null } });
  //   changeSubTaskInList({ ...subtask, assignee: { gid: null } });
  // };



  const onComplete = (e) => {
    e.stopPropagation()
    const newItem = { ...subtask };
    newItem.completed = !subtask.completed;
    subtask.completed = newItem.completed;
    updateAsanaTask({
      gid: subtask.gid,
      completed: newItem.completed,
    });
    setCompleted(newItem.completed);
  };

  const onOpen = () => {
    setEditFlag(false);
    editAsanaSubTask();

    openSubTask({ ...subtask, name: subTaskName });
  };

  const onEdit = (e) => {
    setSubTaskName(e.target.value);
  };

  const editAsanaSubTask = async () => {
    if (subtask) {
      const response = await updateAsanaTask({ gid: subtask.gid, name: subTaskName });
      if (response["response"]?.status === 401) {
        setTimeout(() => {
          editAsanaSubTask();
        }, 1000);
      } else {
        setSubTask({ ...subtask, name: subTaskName });
        changeSubTaskInList({ ...subtask, name: subTaskName });
      }
    }
  };

  const onBlur = () => {
    setEditFlag(false);
    editAsanaSubTask();
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      setEditFlag(false);
      editAsanaSubTask();
    }
  };

  const turnEditModeOn = (e) => {
    e.stopPropagation();
    setEditFlag(true);
  };

  useEffect(() => {
    return function cleanup() {
      if (editFlag) {
        setEditFlag(false);
        editAsanaSubTask();
      }
    };
  }, []);

  return (
    <div className={sectionName ? "MilestoneCard__SubTasksList__SubTaskLine_Container": "MilestoneCard__SubTasksList__SubTaskLine_Container--no-grid"}>
    <div className={sectionName ? "MilestoneCard__SubTasksList__SubTaskLine_SubTaskParams" : "MilestoneCard__SubTasksList__SubTaskLine_SubTaskParams--no-grid"}>
      <div className="MilestoneCard__SubTasks__SubTaskContainer__subTaskName" data-test="MilestoneCard__SubTasks__SubTaskContainer__open" onClick={onOpen}>
        {!completed ? (
          <CheckCircleOutlineIcon style={{ color: "#A5A5BA", marginRight: "0.7rem" }} onClick={(e) => onComplete(e)} />
        ) : (
          <CheckCircleRoundedIcon style={{ color: "#5CB176", marginRight: "0.7rem" }} onClick={(e) => onComplete(e)} />
        )}
        {!editFlag && subTaskName !== "" ? (
          <div onClick={(e) => turnEditModeOn(e)}>{subTaskName}</div>
        ) : (
          <input
            onClick={(e) => turnEditModeOn(e)}
            id="subtask-name-input"
            onBlur={onBlur}
            onKeyDown={(e) => onKeyDown(e)}
            value={subTaskName}
            onChange={(e) => onEdit(e)}
            placeholder="Insert subtask name..."
          />
        )}
      </div>
      
      <div className="MilestoneCard__SubTasks__SubTaskContainer__DatePicker--inline">
        <CustomDatePicker
          selected={dueDate ? dateFromString(dueDate) : null}
          onChange={(date: Date) => onDateChange(date, subtask, changeSubTaskInList)}
          dateFormat="yyyy-MM-dd"
          showYearDropdown
          showMonthYearPicker={false}
        />
      </div>
      <div>
        <AssigneeMenu task={subtask} setTask={onChangeAssignee} size={27} optionsSize={27} />
      </div>
    </div>
    {sectionName && <div className="MilestoneCard__SubTasksList__SubTaskLine__SectionName">{sectionName}</div>}
    </div>
  );
};

export default SubTaskLine;
