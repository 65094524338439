import { createContext, useContext, useEffect, useState } from "react";
import { updateAsanaTask } from "../../APIFunctions/Asana";
import { createMonthEndCellComment } from "../../APIFunctions/ticketing_system";
import { asanaTaskDateStringToDate, getMonth } from "../../utils/DateFunctions";
import { AuthContext } from "../AuthContext";
import { MilestoneContext } from "./MilestoneContext";
import { MilestoneEmailContext } from "./MilestoneEmailContext";
import { dateObjToAsanaString } from "../../utils/DateFunctions";
import { getTransactions } from "../../APIFunctions/financial_statement";
import { useSearchParams } from "react-router-dom";
import { showToast } from "../../utils/toastHelpers";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelectedMonthEndCloseMilestone } from "../../components/TicketingSystem/hooks/useSelectedMilestone";
import { useCellComments } from "../../components/TicketingSystem/hooks/useComments";
import { reportDict } from "../../components/TicketingSystem/Components/MilestoneTaskComponent/Financials/helpers/dictionary";
import { reportTabIndexEnum } from "../../components/TicketingSystem/Components/MilestoneTaskComponent/Financials/types";
import { decodedID } from "../../components/TicketingSystem/Components/QBOReport/Components/DataGrid/utils";

export interface MonthEndCloseContextProps {
  completedMilestones: any[];
  upcomingMilestones: any[];
  monthEndPeriod: string;
  editComment: (commentID: number, asanaTaskID: string, updateObj: any) => any;
  selectedCommentID: any;
  setSelectedCommentID: (selectedCommentID: any) => void;
  drilledTransactionsData: any[];
  setDrilledTransactionsData: (transactionsData: any[]) => void;
  getTransactionsData: (reqObj: any) => Promise<any[]>;
  commentsTabIndex: number;
  setCommentsTabIndex: (commentsTabIndex: number) => void;
  showCommentsPan: boolean;
  setShowCommentsPan: (showCommentsPan: boolean) => void;
  onSelectComment: (comment: any) => void;
  summaryCoordinates: {
    rowIndex: any;
    colIndex: any;
  };
  transactionsCoordinates: {
    rowIndex: any;
    colIndex: any;
  };
  setSummaryCoordinates: (summaryCoordinates: { rowIndex: any; colIndex: any }) => void;
  setTransactionsCoordinates: (transactionsCoordinates: { rowIndex: any; colIndex: any }) => void;
  reportTabIndex: number;
  setReportTabIndex: (reportTabIndex: number) => void;
  PLTransactionsReport: any[];
  BSTransactionsReport: any[];
  setPLTransactionsReport: (PLTransactionsReport: any[]) => void;
  setBSTransactionsReport: (BSTransactionsReport: any[]) => void;
  selectedCell: any;
  setSelectedCell: (selectedCell: any) => void;
  createCommentMutation: any;
  commentEditID: number | null;
  setCommentEditID: (commentEditID: number | null) => void;
  goToCommentCell: (comment) => void;
  transactionDetailFlag: boolean;
  setTransactionDetailFlag: (transactionDetailFlag: boolean) => void;
  selectedTransactionDetail: any;
  setSelectedTransactionDetail: (selectedTransactionDetail: any) => void;
}

export const MonthEndCloseContext = createContext<MonthEndCloseContextProps>(null as any);

export const MonthEndCloseContextProvider = (props) => {
  const queryClient = useQueryClient();
  let [searchParams, setSearchParams] = useSearchParams();

  const selectedMilestone = useSelectedMonthEndCloseMilestone();

  const { selectedCompany } = useContext(AuthContext);
  const { getEmailParams } = useContext(MilestoneEmailContext);
  const { setMilestoneType, prevStage, preparationStageTasks, staffAssignee } = useContext(MilestoneContext);

  const [tasks] = useState<any[]>([]);
  const [completedMilestones, setCompletedMilestones] = useState<any[]>([]);
  const [upcomingMilestones] = useState<any[]>([]);
  const [monthEndPeriod, setMonthEndPeriod] = useState<string>("");
  const [selectedCommentID, setSelectedCommentID] = useState<any>();
  const [drilledTransactionsData, setDrilledTransactionsData] = useState<any[]>([]);
  const [PLTransactionsReport, setPLTransactionsReport] = useState<any[]>([]);
  const [BSTransactionsReport, setBSTransactionsReport] = useState<any[]>([]);

  const [commentsTabIndex, setCommentsTabIndex] = useState<number>(0);
  const [showCommentsPan, setShowCommentsPan] = useState<boolean>(false);
  const [summaryCoordinates, setSummaryCoordinates] = useState({ rowIndex: 0, colIndex: "" });
  const [transactionsCoordinates, setTransactionsCoordinates] = useState({ rowIndex: 0, colIndex: "" });
  const [reportTabIndex, setReportTabIndex] = useState<number>(reportTabIndexEnum.INCOMESTATEMENTSUMMARY);
  const [selectedCell, setSelectedCell] = useState<any>();
  const [commentEditID, setCommentEditID] = useState<number | null>(null);

  const [transactionDetailFlag, setTransactionDetailFlag] = useState<boolean>(false);
  const [selectedTransactionDetail, setSelectedTransactionDetail] = useState<any>(null);

  const CommentsQuery = useCellComments();

  const onCreateComment = async () => {
    setShowCommentsPan(true);
    setCommentsTabIndex(0);
    // Add comment to an account
    const objType = "transaction_id" in selectedCell?.row === true ? "transaction" : "account";
    const period__string = selectedCell?.["field"];
    const financialsTask = preparationStageTasks.find((x) => x.name === "Financials");
    if (financialsTask) {
      const name =
        objType === "account"
          ? `Comment on ${selectedCell?.row["name"]} in period ${period__string} in ${reportDict[reportTabIndex]} report.`
          : `Comment on transaction # ${selectedCell.row["transaction_id"]}, field: "${selectedCell["field"]}", line # ${selectedCell.row["id"]}.`;
      let newCommentLink = window.location.href;
      if (newCommentLink.includes("component?") === false) {
        newCommentLink = newCommentLink.replace("component", "component?");
      }
      if (newCommentLink.includes("comment=") === false) {
        newCommentLink = newCommentLink.replace("component?", "component?comment=123");
      }

      const taskDescriptionHTML = `<body>Comment link → <a href="${newCommentLink}">Go to comment</a>\n</body>`;
      // add a reference to the comment's link in the description.

      const newCommentData = {
        company_id: selectedCompany?.id,
        account_id: objType === "account" ? decodedID(selectedCell?.row["id"]) : null,
        column_id: period__string,
        report_type:
          objType === "transaction" && reportTabIndex === reportTabIndexEnum.INCOMESTATEMENTSUMMARY
            ? reportTabIndexEnum.INCOMESTATEMENTDETAIL
            : objType === "transaction" && reportTabIndex === reportTabIndexEnum.BALANCESHEETSUMMARY
            ? reportTabIndexEnum.BALANCESHEETDETAIL
            : reportTabIndex,
        asana_milestone_id: selectedMilestone.data?.data?.gid,
        asana_task_id: financialsTask.gid,
        name: name,
        asana_task_assignee: prevStage?.assignee ? prevStage?.assignee?.gid : staffAssignee ? staffAssignee.gid : "me",
        taskDescriptionHTML: taskDescriptionHTML,
        transaction_id: objType === "transaction" ? selectedCell?.row["transaction_id"] : null,
        transaction_type: objType === "transaction" ? selectedCell?.row["transaction_type"] : null,
        transaction_line_id: objType === "transaction" ? selectedCell?.row["id"] : null,
      };
      const newComment = await createMonthEndCellComment(newCommentData);
      return newComment;
    }
  };

  const createCommentMutation = useMutation({
    mutationFn: onCreateComment,
    onSuccess: (newComment) => {
      queryClient.invalidateQueries(["cellComments"]);
      setCommentEditID(newComment.id);
    },
  });

  const onSelectComment = (comment) => {
    if (selectedCommentID !== comment.id) {
      setSelectedCommentID(comment.id);
      setSearchParams((prevParams) => {
        return new URLSearchParams({
          ...Object.fromEntries(prevParams.entries()),
          ...{
            comment: comment.id?.toString(),
            // report_type: comment.report_type,
          },
        });
      });
    }
  };

  const addHighlightingAttribute = (dataPoint, comments) => {
    let newDataPoint = { ...dataPoint };
    const matchingComments = comments.filter((x) => x?.transaction_id?.toString() === decodedID(dataPoint.id.toString()));
    if (matchingComments?.length > 0) {
      let highlightFieldsArray: any = [];
      for (let i = 0; i < matchingComments.length; i++) {
        const comment = matchingComments[i];
        highlightFieldsArray.push(comment.column_id);
      }
      newDataPoint["highlight_field"] = highlightFieldsArray;
      return newDataPoint;
    }
    newDataPoint["highlight_field"] = [];
    return dataPoint;
  };

  const setPeriod = () => {
    if (selectedMilestone.isSuccess) {
      const period = selectedMilestone.data?.data?.custom_fields?.find((x) => x.name === "Period")?.date_value?.date;

      if (period) {
        const periodDate = asanaTaskDateStringToDate(period);
        setMonthEndPeriod(getMonth(periodDate));
      } else {
        const altPeriod = selectedMilestone.data?.data?.name.split(":")[1];
        setMonthEndPeriod(altPeriod);
      }
    }
  };

  const getTransactionsData = async (reqParams) => {
    let data;
    if (reqParams.accountID) {
      data = {
        company_id: selectedCompany?.id,
        start_date: reqParams.startDate,
        end_date: reqParams.endDate,
        account_id: reqParams.accountID,
      };
    } else if (reqParams.sectionName) {
      data = {
        company_id: selectedCompany?.id,
        start_date: reqParams.startDate,
        end_date: reqParams.endDate,
        section_name: reqParams.sectionName,
      };
    }

    let comments: any[] = [];
    if (commentsTabIndex === 0) {
      if (CommentsQuery.data?.length > 0) {
        comments = CommentsQuery.data.filter((x) => x.asana_task.completed === false);
      }
    } else {
      if (CommentsQuery.data?.length > 0) {
        comments = CommentsQuery.data.filter((x) => x.asana_task.completed === true);
      }
    }

    const transactionsData = await getTransactions(data);

    if (transactionsData?.["data"]?.length === 0 || transactionsData?.["data"] === undefined) {
      if (reportTabIndex === reportTabIndexEnum.INCOMESTATEMENTDETAIL || reportTabIndex === reportTabIndexEnum.BALANCESHEETDETAIL) showToast("Info", "No transactions to show");
      return [];
    }
    let dataWithTreePath: any[] = [];
    for (let i = 0; i < transactionsData?.["data"].length; i++) {
      const obj = addHighlightingAttribute(transactionsData["data"][i], comments);
      const encodedID = obj["id"].toString() + "-" + i.toString()
      if (i === 0) {
        dataWithTreePath.push({ ...obj, id: encodedID.toString(), hierarchy: [encodedID.toString()] });
      } else {
        if (obj["depth"] > dataWithTreePath[i - 1]["depth"]) {
          dataWithTreePath.push({ ...obj, id: encodedID.toString(), hierarchy: [...dataWithTreePath[i - 1]["hierarchy"], encodedID.toString()] });
        } else if (obj["depth"] === 0) {
          dataWithTreePath.push({ ...obj, id: encodedID.toString(), hierarchy: [encodedID.toString()] });
        } else if (obj["depth"] < dataWithTreePath[i - 1]["depth"]) {
          for (let j = i - 1; j > -1; j--) {
            if (obj["depth"] > dataWithTreePath[j]["depth"]) {
              dataWithTreePath.push({ ...obj, id: encodedID.toString(), hierarchy: [...dataWithTreePath[j]["hierarchy"], encodedID.toString()] });
              break;
            }
            if (j === 0) {
              const parentHierarchy = dataWithTreePath[j]["hierarchy"].slice(0, -1);
              dataWithTreePath.push({ ...obj, id: encodedID.toString(), hierarchy: [...parentHierarchy, encodedID.toString()] });
            }
          }
        } else {
          // remove the last element and add this object's id instead
          const parentHierarchy = dataWithTreePath[i - 1]["hierarchy"].slice(0, -1);
          dataWithTreePath.push({ ...obj, id: encodedID.toString(), hierarchy: [...parentHierarchy, encodedID.toString()] });
        }
      }
    }
    return dataWithTreePath;
  };

  const onShowTransactionsByUrl = async () => {
    const startDate = searchParams.get("drill_start_date");
    const endDate = searchParams.get("drill_end_date");
    const accountID = searchParams.get("account_id");
    const sectionName = searchParams.get("section_name");
    if (accountID) {
      if (startDate && endDate) {
        await getTransactionsData({
          startDate: dateObjToAsanaString(new Date(startDate)),
          endDate: dateObjToAsanaString(new Date(endDate)),
          accountID: accountID,
        });
      }
    } else if (sectionName) {
      if (startDate && endDate) {
        await getTransactionsData({
          startDate: dateObjToAsanaString(new Date(startDate)),
          endDate: dateObjToAsanaString(new Date(endDate)),
          sectionName: sectionName,
        });
      }
    }
  };

  const editComment = async (commentID, asanaTaskID, updateObj) => {
    const data = { gid: asanaTaskID, ...updateObj };
    await updateAsanaTask(data);
    // todo: implement an api call to the backend that would create the asana task ..
    //  ..and place its id in the comment object on the backend.
  };

  const getMilestoneHistory = () => {
    if (tasks.length > 0) {
      const completedTasks = tasks?.filter((x) => x.completed === true);
      setCompletedMilestones(completedTasks);
    }
  };

  const goToCommentCell = (comment) => {
    setReportTabIndex(comment.report_type);
    
    setTimeout(() => {
      if (comment.account_id) {
        setSearchParams((prevParams) => {
          return new URLSearchParams({
            ...Object.fromEntries(prevParams.entries()),
            ...{ report_type: comment.report_type.toString(), drill_transactions: "false" },
          });
        });
        setSummaryCoordinates({ rowIndex: comment.account_id, colIndex: comment.column_id });
        
      } else {
        // for transactions

        setTransactionDetailFlag(true);
        setSelectedTransactionDetail({ transaction_type: comment.transaction_type, transaction_id: comment.transaction_id });
        setTransactionsCoordinates({ rowIndex: comment.transaction_line_id, colIndex: comment.column_id });
      }
    }, 100);
  };

  const onSelectCommentById = (commentId: Number) => {
    let targetComment;

    targetComment = CommentsQuery.data?.find((x) => x.id === commentId);
    if (targetComment?.asana_task?.completed === true) {
      setCommentsTabIndex(1);
    } else {
      setCommentsTabIndex(0);
    }
    if (targetComment) {
      setShowCommentsPan(true);
      setSelectedCommentID(commentId);
      // setTimeout(() => {
      onSelectComment(targetComment);
      goToCommentCell(targetComment);
      // }, 2000);
    }
  };

  // useEffect(() => {
  //   getMilestoneTasks();
  // }, [sections, refreshMilestonesFlag]);

  useEffect(() => {
    getMilestoneHistory();
  }, [tasks]);

  useEffect(() => {
    setMilestoneType(1);
    getEmailParams(1);
    setPeriod();
    // getCellComments();
  }, [selectedMilestone.data]);

  

  useEffect(() => {
    if (searchParams.get("comment")) {
      setShowCommentsPan(true);
      onSelectCommentById(Number(searchParams.get("comment")));
    }
  }, [CommentsQuery.data]);

  useEffect(() => {
    const drill_transactions = searchParams.get("drill_transactions");
    if (drill_transactions === "true") {
      onShowTransactionsByUrl();
    }
  }, []);

  return (
    <MonthEndCloseContext.Provider
      value={{
        completedMilestones,
        upcomingMilestones,
        monthEndPeriod,
        editComment,
        selectedCommentID,
        setSelectedCommentID,
        drilledTransactionsData,
        setDrilledTransactionsData,
        setReportTabIndex,
        reportTabIndex,
        getTransactionsData,
        commentsTabIndex,
        setCommentsTabIndex,
        showCommentsPan,
        setShowCommentsPan,
        onSelectComment,
        summaryCoordinates,
        setSummaryCoordinates,
        transactionsCoordinates,
        setTransactionsCoordinates,
        PLTransactionsReport,
        BSTransactionsReport,
        setPLTransactionsReport,
        setBSTransactionsReport,
        selectedCell,
        setSelectedCell,
        createCommentMutation,
        commentEditID,
        setCommentEditID,
        goToCommentCell,
        transactionDetailFlag,
        setTransactionDetailFlag,
        selectedTransactionDetail,
        setSelectedTransactionDetail,
      }}
    >
      {props.children}
    </MonthEndCloseContext.Provider>
  );
};
