import { Button } from '@mui/material';
import AlertDialog from '../../../Common/AlertDialog';
import DuplicateIcon from '@mui/icons-material/ContentCopyTwoTone';
import { useContext, useEffect, useState } from 'react';
import { EmployeeListConext } from '../../../../contexts/HeadCount/EmployeeListConext';

interface DuplicateEmployeeDialogProps {
    open: boolean;
    onCancel: () => void;
    onYes: (duplicateNumber: number) => void;
}
export default function DuplicateEmployeeDialog({ open, onCancel, onYes }: DuplicateEmployeeDialogProps) {
    const { duplicateDialogeOpen } = useContext(EmployeeListConext);
    const [count, setCount] = useState<number>(0)

    useEffect(() => {
        if (duplicateDialogeOpen)
            setCount(0)
    }, [duplicateDialogeOpen])

    const preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };

    return (
        <AlertDialog
            dialogType='duplicate-form'
            open={open}
            onClose={onCancel}
            title="How many copies do you want to duplicate?"
            dialogActions={
                <>
                    <Button onClick={() => onCancel()} className='btn--tertiary btn--M btn--fullWidth'>
                        Cancel
                    </Button>
                    <Button onClick={() => onYes(count)} className="btn--secondary btn--M btn--fullWidth" autoFocus>
                        <DuplicateIcon sx={{ marginRight: "9px" }} />
                        Duplicate
                    </Button>
                </>
            }
        >
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    padding: '1.5rem',
                }}
            >
                <input
                    style={{
                        width: '100%',
                        height: '50px',
                        padding: '1.5rem',
                        border: '1px solid #DCDCE4',
                        borderRadius: 4,
                        fontSize: 14,
                        lineHeight: '20px',
                        color: '#32324D',
                        fontWeight: 400
                    }}
                    onKeyPress={preventMinus}
                    placeholder="Ex.2"
                    value={count}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        var value: string = e.target.value
                        if (value === "") {
                            setCount(0)
                            return
                        }
                        if (value.length >= 2) {
                            if (value[0] === "0")
                                value = value.substring(1);
                        }
                        if (!isNaN(parseInt(value)))
                            setCount(parseInt(value))
                    }}
                />
            </div>

        </AlertDialog>
    )
}