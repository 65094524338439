import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import TableControls from '../TableControls';
import DataGridComponent from '../TableComponent/DataGridComponent';

const theme = createTheme({ typography: { fontFamily: "SF Pro Text" } });

export default function UsersList() {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <TableControls />
                <DataGridComponent />
            </ThemeProvider>
        </StyledEngineProvider>
    )
}