import { useContext, useEffect, useState } from "react";
import "./headCountHeader.css";
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import { EmployeeListConext } from "../../../contexts/HeadCount/EmployeeListConext";
import { DataConext } from "../../../contexts/HeadCount/DataConext";
import { AuthContext } from "../../../contexts/AuthContext";
import { ReportFilterContext } from "../../../contexts/HeadCount/ReportFilterContext";
import SectionTitle from "../../Common/SectionTitle";
import SectionTitleUpdate from "../../Common/SectionTitleUpdate";
import SelectComponent from "../../Common/SelectComponent";
import { EmployeeDialogType } from "../../../types/HeadCount";
import SyncExcelFile from "../../Common/SyncExcelFile";
import { exportHeadCountEmployeeList } from "../../../APIFunctions/headcouts";
import { IconButton } from "@mui/material";
import { ReactComponent as BarGraphs } from '../../../static/img/bar_graphs.svg';
import { ReactComponent as ArrowRoundedUpIcon } from '../../../static/img/arrow_rounded_up_icon.svg';
import ClearButton from "../../Common/ClearButton";
import { PeriodDisplayOptions, ProjectionDisplayOptions, ReportDataContext } from "../../../contexts/HeadCount/ReportDataContext";
import { LoaderStatus } from "../../../types/Loaders";
import ReactTooltip from "react-tooltip";

export default function HeadCountHeader() {
  const { hc_LoaderStatus } = useContext(DataConext);
  const { openEmployeeDialog, isModalOpen, toggleModal, tabIndex } = useContext(EmployeeListConext);
  const { removeAllFilters, selectedFilters } = useContext(ReportFilterContext);
  const { selectedPeriodName, selectedProjectionName, setSelectedPeriodName, setSelectedProjectionName } = useContext(ReportDataContext);
  const [selectedFiltersNumber, setSelectedFiltersNumber] = useState(selectedFilters.filterCount())
  const { selectedCompany } = useContext(AuthContext);

  useEffect(() => {
    setSelectedFiltersNumber(selectedFilters.filterCount())
  }, [selectedFilters])

  function resetAllFilters() {
    removeAllFilters()
  }

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [tabIndex]);

  return (
    <>
      <header className="header-bi">
        <div className="Title__Container">
          <SectionTitle
            title="Headcount"
            updateComponent={<SectionTitleUpdate />}
          />
        </div>
        <div className="header-controllers__container">
          <div className="bi-header__select">
            {tabIndex > 0 && (
              <>
                <SelectComponent
                  name={PeriodDisplayOptions[0].label}
                  options={PeriodDisplayOptions}
                  onChange={(value) => setSelectedPeriodName(value)}
                  isLoading={false}
                  internalLabel={true}
                  isSearchable={false}
                  value={selectedPeriodName}
                />
                <div style={{ marginLeft: 10, marginRight: 10 }}>
                  <SelectComponent
                    name={ProjectionDisplayOptions[0].label}
                    options={ProjectionDisplayOptions}
                    onChange={(value) => setSelectedProjectionName(value)}
                    internalLabel={true}
                    isLoading={false}
                    isSearchable={false}
                    value={selectedProjectionName}
                  />
                </div>
                <span className={`btn--tertiary btn--S flex-row--M ${isModalOpen ? 'section_opened' : ''}`}>
                  <IconButton
                    className='flex-row--S'
                    color="inherit"
                    aria-label="open filters"
                    size="large"
                    sx={{ p: 0, fontWeight: 700, fontSize: 12, color: '#32324D !important' }}
                    disabled={hc_LoaderStatus !== LoaderStatus.Rendered}
                    onClick={toggleModal}
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                  >
                    <BarGraphs style={{ marginLeft: 10 }} />
                    Filters
                    <ArrowRoundedUpIcon style={{ transform: isModalOpen ? 'rotate(180deg)' : '' }} />
                  </IconButton>
                  {selectedFiltersNumber > 0 && (
                    <>
                      <span className='vertical-divider'>| </span>
                      <ClearButton resetData={resetAllFilters} isSectionOpen={isModalOpen} />
                    </>
                  )}
                </span>
              </>
            )}
          </div>


          {tabIndex === 0 && (
            <>
              <button
                data-tip="Add a new hire"
                className="btn--secondary btn--M--centered"
                disabled={hc_LoaderStatus !== LoaderStatus.Rendered}
                onClick={() => openEmployeeDialog(EmployeeDialogType.ADD)}
              >
                <AddIcon />
              </button>
              <button
                data-tip="Download Excel Template"
                className="btn--secondary btn--M--centered"
                disabled={hc_LoaderStatus !== LoaderStatus.Rendered}
                onClick={() => {
                  if (selectedCompany) exportHeadCountEmployeeList(selectedCompany);
                }}
              >
                <DownloadIcon />
              </button>
              <button className="btn--secondary btn--M--centered" data-tip="Import Headcount" disabled={hc_LoaderStatus !== LoaderStatus.Rendered} onClick={() => openEmployeeDialog(EmployeeDialogType.BULK_ADD)}>
                <FileUploadIcon />
              </button>
            </>
          )}
          <SyncExcelFile />
        </div>
      </header>
    </>
  );
}
