import React, { useContext, useEffect, useState } from "react";
import { SubTasksContext } from "../../../../../contexts/TicketingSystem/SubTasksContext";
import { Outlet, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { AdminViewOutletContextType } from "../../AdminView/AdminViewComponent";
import MilestoneTasksCard from "../../../Components/MilestoneTasksCard";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import MilestoneFeedbackList from "../../../Components/FeedbackList/Container";
import SelectComponent from "../../../../Common/SelectComponent";
import { selectedFeedbackStatusFilterOptions } from "../../../types/feedback_tasks";
import { OptionTypeBase } from "react-select";
import FeedbackList from "../../../Components/FeedbackList";

export type MilestoneCardOutletContextType = {
  milestoneTask: any;
  setMilestoneTask: (milestoneTask: any) => void;
  setIsMilestoneTaskOpen: (isMilestoneTaskOpen: boolean) => void;
  displayComponentFlag: boolean;
  setDisplayComponentFlag: (displayComponentFlag: boolean) => void;
};

const MilestoneCard = () => {
  const navigate = useNavigate();
  let { milestoneTaskParam } = useParams();
  const [selectedFeedbackStatusFilter, setSelectedFeedbackStatusFilter] = useState<OptionTypeBase>(selectedFeedbackStatusFilterOptions[0]);
  const { setIsMilestoneTaskOpen } = useOutletContext<AdminViewOutletContextType>();
  const [displayComponentFlag, setDisplayComponentFlag] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const { preparationStageTasks } = useContext(SubTasksContext);
  const [milestoneTask, setMilestoneTask] = useState<any>();

  const openMilestoneTask = (milestoneTaskGID) => {
    setIsMilestoneTaskOpen(true);
    const target = preparationStageTasks.find((x) => x.gid === milestoneTaskGID);
    if (!milestoneTaskParam) {
      navigate(target.gid);
    }
    if (target) {
      setMilestoneTask(target);
    }
  };

  useEffect(() => {
    if (milestoneTaskParam) {
      openMilestoneTask(milestoneTaskParam);
    }
    return () => setIsMilestoneTaskOpen(false);
  }, [milestoneTaskParam, preparationStageTasks]);

  return (
    <>
      {milestoneTask ? (
        // MilestoneTaskContainer
        <Outlet context={{ milestoneTask, setMilestoneTask, setIsMilestoneTaskOpen, displayComponentFlag, setDisplayComponentFlag }} />
      ) : (
        <div className="MilestoneCard__Container">
          <Tabs className="tabs-bi" selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
            <TabList>
              <Tab className="tabs-bi__link settings_tab_link" selectedClassName="tabs-bi__link--selected">
                TASKS
              </Tab>
              <Tab className="tabs-bi__link settings_tab_link" selectedClassName="tabs-bi__link--selected">
                FEEDBACK
              </Tab>
            </TabList>
            <TabPanel />
            <TabPanel />
          </Tabs>
          {tabIndex === 0 ? <MilestoneTasksCard openMilestoneTask={openMilestoneTask} /> : <FeedbackList tasksFilter={selectedFeedbackStatusFilter}/>}
          {tabIndex === 1 && <div className="MilestoneCard__Container__Feedback__Filter" data-test="MilestoneCard__Container__Feedback__Filter">
            <SelectComponent
              name={selectedFeedbackStatusFilter.label}
              options={selectedFeedbackStatusFilterOptions}
              onChange={(value) => setSelectedFeedbackStatusFilter(value)}
              isLoading={false}
              internalLabel={true}
              isSearchable={false}
              value={selectedFeedbackStatusFilter}
            />
          </div>}
        </div>
      )}
    </>
  );
};

export default MilestoneCard;
