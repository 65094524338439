import APIManger from "../APIManger"
import {
    COMPANY_USER_CREATE_LIST, COMPANY_USER_DELETE_LIST, COMPANY_USER_RETRIVE_UPDATE_DELETE, EXPORT_PAYMENT_PLAN
} from "./apiConstants"
import { Company } from "../types/Company"
import { ManageUsers } from "../types/ManageUsers"


export const getAccountSettingsUsers = async (company: Company): Promise<ManageUsers[]> => {
    let url = COMPANY_USER_CREATE_LIST.replace("%COMPANY_ID%", company?.id?.toString())
    const apiResult: any = await APIManger.get(url)
    var result: ManageUsers[] = []
    if (apiResult.message && apiResult.message === "Network Error")
        result = []
    else
        result = apiResult
    return result;
}

export const addUser = async (data: any, company: Company): Promise<ManageUsers> => {
    let url = COMPANY_USER_CREATE_LIST.replace("%COMPANY_ID%", company?.id?.toString())
    let apiResult: ManageUsers = await APIManger.post(url, data)
    return apiResult;
}
export const updateUser = async (data: any, company: Company, companyUserId: number): Promise<ManageUsers> => {
    let url = COMPANY_USER_RETRIVE_UPDATE_DELETE.replace("%COMPANY_ID%", company?.id?.toString()).replace("%COMPANY_USER_ID%", companyUserId.toString())
    let apiResult: ManageUsers = await APIManger.put(url, data)
    return apiResult;
}

export const deleteUser = async (company: Company, companyUserId: number): Promise<boolean> => {
    let url = COMPANY_USER_RETRIVE_UPDATE_DELETE.replace("%COMPANY_ID%", company?.id?.toString()).replace("%COMPANY_USER_ID%", companyUserId.toString())
    let apiResult: string = await APIManger.delete(url, {})
    return apiResult === '';
}

export const deleteUserList = async (company: Company, ids: readonly string[]): Promise<boolean> => {
    let url = COMPANY_USER_DELETE_LIST.replace("%COMPANY_ID%", company?.id?.toString())
    let apiResult: string = await APIManger.delete(url, { ids: ids.toString() })
    return apiResult === '';
}

export const exportPaymentPlan = async (company: Company) => {
    let url = EXPORT_PAYMENT_PLAN.replace("%COMPANY_ID%", company?.id?.toString())
    APIManger.downloadPDF(url)
}