import APIManger from "../APIManger";
import { PROJECT_MILESTONES_CREATE } from "./apiConstants";




export const createProjectMilestones = async (project_name: any): Promise<any> => {
    let url = PROJECT_MILESTONES_CREATE.replace("%PROJECT_NAME%", project_name.toString());
    const apiResult: any = await APIManger.get(url);
    return apiResult;
  };