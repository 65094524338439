import APIManger from "../APIManger"
import { ONBOARDING_CONNECT_QBO,ONBOARDING_DISCONNECT_QBO, ONBOARDING_RETRIEVE_UPDATE } from "./apiConstants"
import { Onboarding } from "../types/Onboarding"

export const updateOnboarding = async (data: any): Promise<Onboarding> => {
    let url = ONBOARDING_RETRIEVE_UPDATE.replace("%ONBOARDING_ID%", data?.id?.toString())
    let apiResult: Onboarding = await APIManger.put(url, data)
    return apiResult;
}

export const retrieveOnboarding = async (data: any): Promise<any> => {
    let url = ONBOARDING_RETRIEVE_UPDATE.replace("%ONBOARDING_ID%", data?.id?.toString())
    let apiResult: any = await APIManger.get(url)
    return apiResult
}

export const connectQBO = async (id: number): Promise<any> => {
    let url = ONBOARDING_CONNECT_QBO.replace("%ONBOARDING_ID%", id.toString())
    let apiResult: any = await APIManger.post(url)
    return apiResult
}

export const disconnectQBO = async (id: number): Promise<any> => {
    let url = ONBOARDING_DISCONNECT_QBO.replace("%ONBOARDING_ID%", id.toString())
    let apiResult: any = await APIManger.post(url)
    return apiResult
}
