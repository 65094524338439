import { replaceDashesWithSpacesWithUppercaseWords } from "../utils/helpers";

export declare type AppModuleName = 'Dashboard' | 'Headcount' | 'Summary' | 'Financial Statements' | 'Headcount' | 'Budget To Actuals' | 'Pitch Deck' | 'Revenue' | 'Tasks' | 'Payroll' |
 'Month End Close' | 'Payroll Admin' | 'Month End Close Admin' | 'Accounts Payable Admin' | 'Export Financials';
export declare type AppModuleUrl = 'dashboard' | 'summary' | 'financial-statements' | 'headcount' | 'budget-to-actuals' | 'pitch-deck' | 'revenue' | 'tasks/project/user' | 'tasks/payroll' |
'tasks/month-end-close' | 'tasks/project/payroll' | 
'tasks/project/month-end-close' | 'tasks/project/accounts-payable' | 'export-financials-to-excel' | 'not-found';
export declare type ModuleTypeName = 'Power Bi' | 'Embed Excel';

export enum AppModules {
    DASHBOARD,
    SUMMARY,
    FINANCIAL_STATEMENT,
    HEADCOUNT,
    BUDGET_TO_ACTUALS,
    PITCH_DECK,
    REVENUE,
    TASKS,
    PAYROLL, MONTH_END_CLOSE,
    PAYROLL_ADMIN,MONTH_END_CLOSE_ADMIN,ACCOUNTS_PAYABLE_ADMIN,EXPORT_FINANCIALS,
    DEFAULT,
}
export enum ModuleType {
    CUSTOM,
    EMBED_EXCEL,
    DEFAULT,
}
export interface CompanyModule {
    name: AppModules;
    type: ModuleType;
    name_str: AppModuleName;
    type_str: ModuleTypeName;
    url: string;
}
export class ModuleConverter {

    public static NameToUrl(moduleName: AppModuleName): any {
        return moduleName.replace(/\s+/g, '-').toLowerCase() as AppModuleUrl;
    }

    public static UrlToName(moduleUrl: any): AppModuleName {

        return replaceDashesWithSpacesWithUppercaseWords(replaceDashesWithSpacesWithUppercaseWords(moduleUrl).replace("Tasks/project/","").replace("Tasks/","")) as AppModuleName
    }

    public static UrlToId(moduleUrl: any): AppModules {
        switch (moduleUrl) {
            case "budget-to-actuals":
                return AppModules.BUDGET_TO_ACTUALS
            case "dashboard":
                return AppModules.DASHBOARD
            case "financial-statements":
                return AppModules.FINANCIAL_STATEMENT
            case "headcount":
                return AppModules.HEADCOUNT
            case "pitch-deck":
                return AppModules.PITCH_DECK
            case "summary":
                return AppModules.SUMMARY
            case "revenue":
                return AppModules.REVENUE
            case "tasks":
                return AppModules.TASKS
            case "tasks/payroll":
                    return AppModules.PAYROLL_ADMIN
            case "tasks/month-end-close":
                return AppModules.MONTH_END_CLOSE_ADMIN
            case "month-end-close":
                return AppModules.MONTH_END_CLOSE
            case "tasks/accounts-payable":
                return AppModules.ACCOUNTS_PAYABLE_ADMIN
            case "payroll":
                return AppModules.PAYROLL
            case "export-financials-to-excel":
                return AppModules.EXPORT_FINANCIALS
            case 'not-found':
                return AppModules.DEFAULT
            default:
                return AppModules.DEFAULT
        }
    }

    public static NameToId(moduleName: AppModuleName): AppModules {
        return ModuleConverter.UrlToId(ModuleConverter.NameToUrl(moduleName))
    }



    public static IdToUrl(moduleUrl: AppModules): AppModuleUrl {
        switch (moduleUrl) {
            case AppModules.BUDGET_TO_ACTUALS:
                return "budget-to-actuals"
            case AppModules.DASHBOARD:
                return "dashboard"
            case AppModules.FINANCIAL_STATEMENT:
                return "financial-statements"
            case AppModules.HEADCOUNT:
                return "headcount"
            case AppModules.PITCH_DECK:
                return "pitch-deck"
            case AppModules.SUMMARY:
                return "summary"
            case AppModules.REVENUE:
                return "revenue"
            case AppModules.MONTH_END_CLOSE:
                return 'tasks/month-end-close'
            case AppModules.TASKS:
                return "tasks/project/user"
            case AppModules.PAYROLL_ADMIN:
                return "tasks/project/payroll"
            case AppModules.MONTH_END_CLOSE_ADMIN:
                return "tasks/project/month-end-close"
            case AppModules.ACCOUNTS_PAYABLE_ADMIN:
                return "tasks/project/accounts-payable"
            case AppModules.PAYROLL:
                return "tasks/payroll"
            case AppModules.EXPORT_FINANCIALS:
                return "export-financials-to-excel"
            case AppModules.DEFAULT:
                return "not-found"
        }
    }

    public static IdToName(moduleId: AppModules): AppModuleName {
        return ModuleConverter.UrlToName(ModuleConverter.IdToUrl(moduleId))
    }


}