import React, { useContext, useEffect, useState } from "react";
import { dateFromString, getInitials } from "../../../utils/helpers";
import CloseIcon from "@mui/icons-material/Close";
import { updateAsanaTask } from "../../../APIFunctions/Asana";
import MDIcons from "../../Common/MDIcons";
import CustomTooltip from "../../Common/Tooltip";
import CustomDatePicker from "../../Common/CustomDatePicker";
import { getPayrollTaskType, PayrollTaskType } from "../../Tasks/utils";
import { MilestoneContext } from "../../../contexts/TicketingSystem/MilestoneContext";
import AssigneeMenu from "../../Common/DropDownComponent/Assignee";

const MilestoneStagesHeader = () => {
  const { subTasks, setSubTasks, currentStage, setCurrentStage } = useContext(MilestoneContext);
  const [assignees, setAssignees] = useState<any[]>([]);
  const [stageAssignee, setStageAssignee] = useState<any>(currentStage?.assignee);
  const onClearDueDate = (task, updateTaskState) => {
    const newItem = { ...task };
    newItem["due_on"] = null;
    updateTaskState(newItem);
    updateAsanaTask(newItem);
  };

  const onDateChange = (date: Date, task, updateTaskState) => {
    const newItem = {};
    date.setTime(date.getTime() - new Date().getTimezoneOffset() * 60 * 1000);
    newItem["due_on"] = date.toISOString().substring(0, 10);
    newItem["gid"] = task.gid;
    updateTaskState(newItem);
    updateAsanaTask(newItem);
  };

  const onChangeStageAssignee = (stageTask) => {
    const newItem = {};
    newItem["gid"] = stageTask.gid;
    newItem["assignee"] = stageTask.assignee.gid;

    // discuss with Mihail
    setCurrentStage(stageTask);
    updateAsanaTask(newItem);
  };

  const onClearAssignee = () => {
    setStageAssignee(null);
    setCurrentStage({ ...currentStage, assignee: { gid: null } });
    updateAsanaTask({ ...currentStage, assignee: { gid: null } });
  };

  const getMilestonesAssignees = () => {
    if (subTasks) {
      let pendingFlag = false;
      let assignees: any = [];

      for (let i = 0; i < subTasks.length; i++) {
        if (subTasks[i].assignee?.name) {
          if ((i === 0 && subTasks[i].completed === false) || (subTasks[i - 1]?.completed === true && subTasks[i].completed === false)) {
            pendingFlag = true;
          } else {
            pendingFlag = false;
          }
          assignees.push({
            gid: subTasks[i].assignee?.gid,
            assignee: subTasks[i].assignee?.name,
            photo: subTasks[i].assignee?.photo?.["image_36x36"],
            status: subTasks[i].completed ? "done" : pendingFlag ? "pending" : "scheduled",
          });
        }
      }
      setAssignees(assignees);
    }
  };

  useEffect(() => {
    getMilestonesAssignees();
  }, [subTasks, currentStage, stageAssignee]);

  return (
    <div className="MilestoneStagesHeader__Container">
      <div className="MilestoneStagesHeader__StageButtons">
        <CustomDatePicker
          selected={currentStage?.due_on ? dateFromString(currentStage.due_on) : null}
          onChange={(date: Date) => onDateChange(date, currentStage, setCurrentStage)}
          dateFormat="MM-dd-yyyy"
          showYearDropdown
          showMonthYearPicker={false}
        />
        {currentStage?.due_on && <CloseIcon onClick={() => onClearDueDate(currentStage, setCurrentStage)} />}

        {/* discuss with Mihail: making this take effect in the 'assignees' array as well as if user is assigned current stage. */}
        {/* <AssigneeMenu variant="task" task={currentStage} setTask={onChangeStageAssignee} />
        {currentStage.assignee?.name === "" || stageAssignee === null || stageAssignee?.name === undefined ? (
          <span className="placeholder">Assign {currentStage.name} stage</span>
        ) : (
          <span className="SelectedAssigneeName">
            {stageAssignee?.name}
            <CloseIcon onClick={() => onClearAssignee()} />
          </span>
        )} */}
      </div>

      <div className="MilestoneStagesHeader__StagesContainer">
        <div className="MilestoneStagesHeader__StagesContainer__StageName__Container">
          <div className={getPayrollTaskType(currentStage?.name) === PayrollTaskType.CLIENT_REVIEW ? "dot__blue" : "dot"}></div>
          <div className="MilestoneStagesHeader__StagesContainer__StageName__Name">{currentStage?.name}</div>
        </div>

        {assignees.map((x, index) => {
          return (
            <div key={index} className="MilestoneStagesHeader__StagesContainer__Assignee">
              <div style={{ opacity: x.status !== "pending" ? 0.5 : 1 }}>
                <CustomTooltip title={<>{x.assignee}</>}>
                  {x.photo ? (
                    <img className={x.status === "pending" ? "AsanaPhoto CircleBorder__Green" : "AsanaPhoto"} src={x.photo} alt="" />
                  ) : (
                    <div className="user__circle">
                      <div className="user__circle_inner">{getInitials(x.assignee)}</div>
                    </div>
                  )}
                </CustomTooltip>
              </div>

              {x.status === "done" && <MDIcons title="checkbox_assignee" />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MilestoneStagesHeader;
