import React, { useState } from "react";
import AsanaLikeButton from "../../AsanaComponents/AsanaLikeButton";
import CustomTooltip from "../Tooltip";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { showToast } from "../../../utils/toastHelpers";
import MDIcons from "../MDIcons";
import DeleteTaskDialog from "../../Tasks/DeleteTaskDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import { uploadAsanaAttachment } from "../../../APIFunctions/Asana";
import './style.css'
import GoToAsanaButton from "../../AsanaComponents/GoToAsanaButton";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface uploadAsanaAttachmentVariables {
  taskgid:string;
  selectedFile: any
}

const TaskHeaderButtons = ({ task, setTask, setLoading, onDelete, loading }) => {
  const [deleteDialogeOpenFlag, setDeleteDialogeOpenFlag] = useState<boolean>(false);

  const queryClient = useQueryClient()

  const uploadAttachment = useMutation({
    mutationFn: ({taskgid, selectedFile}:uploadAsanaAttachmentVariables) => uploadAsanaAttachment(taskgid, selectedFile),
  })

  const getTaskLink = () => {
    showToast("success", "Task link copied!", "Success");
    return window.location.href;
  };

  const openDeleteDialoge = () => {
    setDeleteDialogeOpenFlag(true);
  };

  const closeDeleteDialoge = () => {
    setDeleteDialogeOpenFlag(false);
    onDelete(task)

  };

  const handleFileSelect = async (event) => {
    const selectedFile = event.target.files[0];
    setLoading(true);
    if (selectedFile && selectedFile.size <= 9000000) {
      // const response = await uploadAsanaAttachment(task.gid, selectedFile);
      
      uploadAttachment.mutate({taskgid:task.gid,selectedFile: selectedFile},
        {onSuccess: () => {
          queryClient.refetchQueries({ queryKey:  ["asana-task", "attachments", task.gid] })
          setLoading(false);
        }}
        )
    }
  };

  return (
    <div className="buttonsContainer">
      <AsanaLikeButton task={task} setTask={setTask} />
      <GoToAsanaButton taskGID={task?.gid} />
      <CustomTooltip title={<React.Fragment>Upload an attachment!</React.Fragment>}>
        <label id="" className="btn--tertiary btn--M--centered success">
          <AttachmentIcon />

          <input id="uploadFile" type="file" onChange={handleFileSelect} />
        </label>
      </CustomTooltip>
      <CustomTooltip title="copy task link">
        <div
          id=""
          className="btn--tertiary btn--M--centered success"
          onClick={() => {
            navigator.clipboard.writeText(getTaskLink());
          }}
        >
          <MDIcons title="Link_svg" />
        </div>
      </CustomTooltip>
      <CustomTooltip
        title={
          <React.Fragment>
            <div>Delete this task!</div>
          </React.Fragment>
        }
      >
        <button id="danger" data-test="delete-btn" className="btn--tertiary btn--M--centered" onClick={openDeleteDialoge} disabled={loading}>
          <DeleteIcon />
        </button>
      </CustomTooltip>
      <DeleteTaskDialog open={deleteDialogeOpenFlag} onCancel={closeDeleteDialoge} onYes={closeDeleteDialoge} />
    </div>
  );
};

export default TaskHeaderButtons;
