
import React, { forwardRef} from 'react'
import ReactDatePicker from 'react-datepicker';
import EventIcon from '@mui/icons-material/Event';

const CustomDatePicker = ({selected, onChange, dateFormat, showYearDropdown, showMonthYearPicker, disabled=false}) => {
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="calendar-custom-input" onClick={onClick} ref={ref}>
      <EventIcon sx={{cursor:"pointer"}}/>
      {value !== "" ? 
      <span className="calendar-custom-date-display">
      {value}
      </span>
      :
      <span className="calendar-custom-date-display" style={value === "" && {color:"var(--Neutral300)"}}>
      Add Due Date
      </span>
      }
    </div>
  ));
  return (
    <ReactDatePicker
      selected={selected}
      onChange={onChange}
      customInput={<CustomInput />}
      dateFormat={dateFormat}
      showYearDropdown={showYearDropdown}
      showMonthYearPicker={showMonthYearPicker}
      showFullMonthYearPicker
      disabled={disabled}
    />
  )
}

export default CustomDatePicker