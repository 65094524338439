import { CircularProgress } from "@mui/material";
import React from "react";

const MainLoadingView = ({ milestoneType }) => {
  return (
    <div className="verifyingAccess__Container">
      <CircularProgress size={150} sx={{ color: "#85b440" }} />
      <span>Fetching the {milestoneType} milestone from Asana ...</span>
    </div>
  );
};

export default MainLoadingView;
