import React, { useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { TaskAuthContextProvider } from "../../../contexts/Task/TaskAuthContext";
import { TaskServicesContextProvider } from "../../../contexts/Task/TaskServicesContext";
import { MilestoneContextProvider } from "../../../contexts/TicketingSystem/MilestoneContext";
import { MilestoneEmailContextProvider } from "../../../contexts/TicketingSystem/MilestoneEmailContext";
import { MilestoneLinksContextProvider } from "../../../contexts/TicketingSystem/MilestoneLinksContext";
import { MonthEndCloseContextProvider } from "../../../contexts/TicketingSystem/MonthEndCloseContext";
import { PayrollContextProvider } from "../../../contexts/TicketingSystem/PayrollContext";
import { UserRole } from "../../../types/ManageUsers";
import AdminViewComponent from "./AdminView/AdminViewComponent";
import { DataContextProvider } from "../../../contexts/FinancialReport/DataContext";
import { ReportFilterContextProvider } from "../../../contexts/FinancialReport/FilterContext";


const MonthEndCloseViews = () => {
  const { selectedCompany, userProfile } = useContext(AuthContext);

  return (
    <>
      <TaskAuthContextProvider>
        <TaskServicesContextProvider>
          <MilestoneContextProvider>
            <MilestoneEmailContextProvider>
              <MonthEndCloseContextProvider>
                <MilestoneLinksContextProvider>
                <ReportFilterContextProvider>
                  <DataContextProvider>
                  <AdminViewComponent />
                  </DataContextProvider>
                  </ReportFilterContextProvider>
                </MilestoneLinksContextProvider>
              </MonthEndCloseContextProvider>
            </MilestoneEmailContextProvider>
          </MilestoneContextProvider>
        </TaskServicesContextProvider>
      </TaskAuthContextProvider>
    </>
  );
};

export default MonthEndCloseViews;
