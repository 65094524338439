import { createContext, useContext, useCallback, useEffect, useState } from "react";
import { syncExcelFileAPI } from "../APIFunctions/graph_api";
import { getPDFDownloadLink, printEmbededPDF } from "../APIFunctions/headcouts";
import { AppModules, ModuleType } from "../types/CompanyModules";
import { Division } from "../types/Division";
import { CreatingNewPDFToast, showToast } from "../utils/toastHelpers";
import { AuthContext } from "./AuthContext";
import { SocketContext } from "./SocketContext";

export interface ModuleContextProps {
    appModule: AppModules;
    setAppModule: (appModule: AppModules) => void;

    appModuleType: ModuleType;
    setAppModuleType: (appModuleType: ModuleType) => void;

    selectedDivision?: Division | null;
    setSelectedDivision: (appModule: Division) => void;

    SyncSelectedDivisionExcelFile: () => void;
    CreateNewPDF: () => void;
    DownloadPDF: () => void;
}

export const ModuleContext = createContext<ModuleContextProps>(null as any);

export const ModuleContextProvider = (props) => {
    const [appModule, setAppModule] = useState<AppModules>(AppModules.DEFAULT)
    const [appModuleType, setAppModuleType] = useState<ModuleType>(ModuleType.DEFAULT)
    const [selectedDivision, setSelectedDivision] = useState<Division | null>(null);
    const { selectedCompany, companyFilter } = useContext(AuthContext);
    const { updatelogicAppTracking, setPrintPDFRunning } = useContext(SocketContext)


    useEffect(() => {
        if (selectedCompany)
            setSelectedDivision(companyFilter?.division[0])
    }, [selectedCompany]);


    const CreateNewPDF = useCallback(async () => {
        if (selectedDivision && selectedCompany) {
            const response = await printEmbededPDF(selectedCompany, selectedDivision);
            if (response) {
                setPrintPDFRunning(true)
                CreatingNewPDFToast()
            }

        }
    }, [selectedCompany, selectedDivision, setPrintPDFRunning])

    const DownloadPDF = useCallback(async () => {
        if (selectedCompany) {
            showToast('success', 'Your PDF download started.')
            const response = await getPDFDownloadLink(selectedCompany)
            window.open(response.download_url, "_blank");
        }
    }, [selectedCompany])

    const SyncSelectedDivisionExcelFile = useCallback(async () => {
        if (selectedDivision && selectedCompany) {
            const response = await syncExcelFileAPI(selectedCompany);
            if (response)
                updatelogicAppTracking("Syncing Excel File", "info", "The process is initiated.")

        }
        else {
            showToast('error', 'There is no selected division.', "Syncing Excel File")
        }
    }, [selectedCompany, selectedDivision, updatelogicAppTracking])

    return (
        <ModuleContext.Provider value={{
            appModule, setAppModule,
            appModuleType, setAppModuleType,
            selectedDivision, setSelectedDivision,
            SyncSelectedDivisionExcelFile,
            CreateNewPDF,
            DownloadPDF,
        }}>
            {props.children}
        </ModuleContext.Provider>
    )
}