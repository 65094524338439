import React, { useContext } from 'react'
import GeneralHeader from '../../../../../Common/GeneralHeader'
import { reportDict } from '../../../MilestoneTaskComponent/Financials/helpers/dictionary'
import { IconButton } from '@mui/material'
import { ReactComponent as BarGraphs } from "../../../../../../static/img/bar_graphs.svg";
import { ReactComponent as ArrowRoundedUpIcon } from "../../../../../../static/img/arrow_rounded_up_icon.svg"
import { AuthContext } from '../../../../../../contexts/AuthContext';
import SyncQBData from '../../../../../Common/SyncQBData';
import { reportTabsDict } from '../../Types/Report';
import { CommentContext } from '../../Context/CommentContext';

const QBOGeneralHeader = ({reportTabIndex,isModalOpen, toggleModal }) => {
  const { reportPage } = useContext(CommentContext)
    const { selectedCompany } = useContext(AuthContext)

  return (
    <GeneralHeader title={reportTabsDict[reportPage][reportTabIndex.toString()] } renderDivisions={false}>
        <span className={`btn--tertiary btn--S flex-row--M ${isModalOpen ? "section_opened" : ""}`}>
          <IconButton
            className="flex-row--S"
            color="inherit"
            aria-label="open filters"
            size="large"
            sx={{ p: 0, fontWeight: 700, fontSize: 12, color: "#32324D !important" }}
            // disabled={hc_LoaderStatus !== LoaderStatus.Rendered}
            onClick={toggleModal}
            disableRipple
            disableFocusRipple
            disableTouchRipple
          >
            <BarGraphs style={{ marginLeft: 0 }} />
            Filters
            <ArrowRoundedUpIcon style={{ transform: isModalOpen ? "rotate(180deg)" : "" }} />
          </IconButton>
          {/* {selectedFiltersNumber > 0 && (
                    <>
                      <span className='vertical-divider'>| </span>
                      <ClearButton resetData={resetAllFilters} isSectionOpen={isModalOpen} />
                    </>
                  )} */}
        </span>
        {selectedCompany?.is_connected === true && <SyncQBData />}
        {/* todo: add filters here */}
      </GeneralHeader>
  )
}

export default QBOGeneralHeader