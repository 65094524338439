import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AlertDialog from '../../Common/AlertDialog';

interface DeleteTaskDialogProps {
    open: boolean;
    onCancel: () => void;
    onYes: () => void;
}
export default function DeleteTaskDialog({ open, onCancel, onYes }: DeleteTaskDialogProps) {

    return (
        <AlertDialog
            dialogType="yes_or_no"
            open={open}
            onClose={onCancel}
            title="Are you sure you want to delete this task permanently?"
            dialogActions={
                <>
                    <Button onClick={() => onCancel()} className='btn--tertiary btn--XS btn--80Width'>
                        Cancel
                    </Button>
                    <Button data-test="confirm-delete-button" onClick={() => onYes()} className="btn--secondary-danger btn--XS btn--80Width" autoFocus style={{marginLeft:"0rem"}}>
                    <DeleteIcon />
                        Delete
                    </Button>
                </>
            }
        />
    )
}