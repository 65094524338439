import React, { useContext, useEffect, useState } from "react";
import InputContainer from "./InputContainer";
import { AuthContext } from "../../contexts/AuthContext";
import { convertMonthLongYearStringToDate, createDateWithOffsetFromDate, dateObjToAsanaString, endOfMonth, startOfMonth } from "../../utils/DateFunctions";
import "./style.css";
import SharepointExplorer from "../Common/SharepointExplorer/SharepointExplorer";
import { exportReportToExcel, getLastQBOExportTime } from "../../APIFunctions/data_modeling";
import QBOFooter from "./Footer";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { allChecked, indeterminate, reportTypeNameDict } from "./utils";

export type checked = {
  PL: boolean;
  PLDetail: boolean;
  BS: boolean;
  BSDetail: boolean;
  CF: boolean;
};

const initialCheckedState: checked = {
  PL: true,
  PLDetail: true,
  BS: true,
  BSDetail: true,
  CF: true,
};

const MonthEndQBOContainer = ({
  monthEndPeriod,
  company,
  setDisplaySharepointBrowser,
  displaySharepointBrowser,
  setSaveFolder,
  saveFolder,
  byCompany,
  setCompany,
  setLoading,
  setLastExportTime,
  outputFormat,
}) => {
  const { selectedCompany } = useContext(AuthContext);

  const [download, setDownload] = useState<boolean>(false);
  const [qboClasses, setQBOClasses] = useState<any[]>([]);

  const endDateInitial = endOfMonth(convertMonthLongYearStringToDate(monthEndPeriod));
  const startDateInitial = startOfMonth(createDateWithOffsetFromDate(endDateInitial, 0, -5, 0));
  const detailStartDate = startOfMonth(convertMonthLongYearStringToDate(monthEndPeriod));

  const [incomeStatementStartDate, setIncomeStatementStartDate] = useState<string>(`${dateObjToAsanaString(startDateInitial)}`);
  const [incomeStatementEndDate, setIncomeStatementEndDate] = useState<string>(dateObjToAsanaString(endDateInitial));

  const [incomeStatementDetailStartDate, setIncomeStatementDetailStartDate] = useState<string>(`${dateObjToAsanaString(detailStartDate)}`);
  const [incomeStatementDetailEndDate, setIncomeStatementDetailEndDate] = useState<string>(dateObjToAsanaString(endDateInitial));

  const [balanceSheetDetailStartDate, setBalanceSheetDetailStartDate] = useState<string>(`${dateObjToAsanaString(detailStartDate)}`);
  const [balanceSheetDetailEndDate, setBalanceSheetDetailEndDate] = useState<string>(dateObjToAsanaString(endDateInitial));

  const [balanceSheetStartDate, setBalanceSheetStartDate] = useState<string>(`${dateObjToAsanaString(startDateInitial)}`);
  const [balanceSheetEndDate, setBalanceSheetEndDate] = useState<string>(dateObjToAsanaString(endDateInitial));

  const [cashFlowStartDate, setCashFlowStartDate] = useState<string>(`${dateObjToAsanaString(startDateInitial)}`);
  const [cashFlowEndDate, setCashFlowEndDate] = useState<string>(dateObjToAsanaString(endDateInitial));

  const onCloseBrowser = () => {
    setDisplaySharepointBrowser(false);
  };

  const [checked, setChecked] = useState<checked>(initialCheckedState);


  const setAllChecked = (checked) => {
    setChecked({
      PL: checked,
      PLDetail: checked,
      BS: checked,
      BSDetail: checked,
      CF: checked,
    });
  };

  const handleChangeALL = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllChecked(event.target.checked);
  };

  const handleChangeChecked = (event: React.ChangeEvent<HTMLInputElement>, checkedReportName) => {
    setChecked({ ...checked, [reportTypeNameDict[checkedReportName]]: event.target.checked });
  };

  const exportToExcel = async (saveFolder: any, newFileName: any) => {
    setLoading(true);
    if (company) {
      const response = await exportReportToExcel(
        company,
        outputFormat,
        qboClasses,
        newFileName,
        saveFolder.id,
        incomeStatementStartDate,
        incomeStatementEndDate,
        balanceSheetStartDate,
        balanceSheetEndDate,
        incomeStatementDetailStartDate,
        incomeStatementDetailEndDate,
        balanceSheetDetailStartDate,
        balanceSheetDetailEndDate,
        cashFlowStartDate,
        cashFlowEndDate,
        download,
        checked,
        false
      );
      setLoading(false);
      getLastExportTime();
      return response;
    }
  };

  const getLastExportTime = async () => {
    if (selectedCompany) {
      const response = await getLastQBOExportTime(selectedCompany);

      if (response["last_export_time"]) {
        setLastExportTime(response["last_export_time"]);
      }
    }
  };

  useEffect(() => {
    getLastExportTime();
  }, []);

  

  return (
    <>
      <div>
        <div className="ExportQBOToExcel__Container">
          <div className="ExportQBOToExcel__Container__Grid">
            <div className="ExportQBOToExcel__Container__Header">
              <div className="ExportQBOToExcel__Container__Header__CheckAll">
                <FormControlLabel
                  label=""
                  control={<Checkbox checked={allChecked(checked)} indeterminate={indeterminate(checked)} onChange={handleChangeALL} />}
                  sx={{
                    padding: 0,
                    color: "#C0C0CF",
                    "&.Mui-checked": {
                      color: "#85B440",
                    },
                    "&.MuiCheckbox-indeterminate": {
                      color: "#85B440",
                    },
                  }}
                />
              </div>

              <div className="ExportQBOToExcel__Container__Header__Label">Report Name</div>
              <div className="ExportQBOToExcel__Container__Header__Label">Start Date</div>
              <div className="ExportQBOToExcel__Container__Header__Label">End Date</div>
            </div>

            <InputContainer
              title="Income Statement"
              startDate={incomeStatementStartDate}
              setStartDate={setIncomeStatementStartDate}
              endDate={incomeStatementEndDate}
              setEndDate={setIncomeStatementEndDate}
              checked={checked.PL}
              handleChangeChecked={handleChangeChecked}
            />
            <InputContainer
              title="Income Statement Detail"
              startDate={incomeStatementDetailStartDate}
              setStartDate={setIncomeStatementDetailStartDate}
              endDate={incomeStatementDetailEndDate}
              setEndDate={setIncomeStatementDetailEndDate}
              checked={checked["PLDetail"]}
              handleChangeChecked={handleChangeChecked}
            />
            <InputContainer
              title="Balance Sheet"
              startDate={balanceSheetStartDate}
              setStartDate={setBalanceSheetStartDate}
              endDate={balanceSheetEndDate}
              setEndDate={setBalanceSheetEndDate}
              checked={checked.BS}
              handleChangeChecked={handleChangeChecked}
            />
            <InputContainer
              title="Balance Sheet Detail"
              startDate={balanceSheetDetailStartDate}
              setStartDate={setBalanceSheetDetailStartDate}
              endDate={balanceSheetDetailEndDate}
              setEndDate={setBalanceSheetDetailEndDate}
              checked={checked.BSDetail}
              handleChangeChecked={handleChangeChecked}
            />
            <InputContainer
              title="Cash Flow"
              startDate={cashFlowStartDate}
              setStartDate={setCashFlowStartDate}
              endDate={cashFlowEndDate}
              setEndDate={setCashFlowEndDate}
              checked={checked.CF}
              handleChangeChecked={handleChangeChecked}
            />
          </div>
        </div>

        {displaySharepointBrowser && (
          <SharepointExplorer
            onCloseBrowser={onCloseBrowser}
            displaySharepointBrowser={displaySharepointBrowser}
            setSaveFolder={setSaveFolder}
            setDisplaySharepointBrowser={setDisplaySharepointBrowser}
            saveFolder={saveFolder}
            exportToExcel={exportToExcel}
          />
        )}
      </div>
    </>
  );
};

export default MonthEndQBOContainer;
