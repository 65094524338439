import React, { useContext } from 'react'
import { AuthContext } from '../../../../../contexts/AuthContext';
import Excel_logo from "../static/excel-logo.svg";
import { MilestoneEmailContext } from "../../../../../contexts/TicketingSystem/MilestoneEmailContext";
import { PayrollContext } from '../../../../../contexts/TicketingSystem/PayrollContext';
import { asanaTaskDateStringToDate, dateObjToAppString } from '../../../../../utils/DateFunctions';

const PayrollPreviewMainTitleView = () => {
    const { selectedCompany } = useContext(AuthContext);
    const { payrollFileLink,sharepointLinkInEmail } = useContext(MilestoneEmailContext)
    const {location, platform,cycleEndDate,cycleStartDate, payroll_type} = useContext(PayrollContext)

  return (
    <>
    <div className="SubTask__EmailPreview__Body__MainTitle__MainContent">
                <div className="SubTask__EmailPreview__Body__MainTitle__MainContent__Title">
                  <span>{selectedCompany?.companyName}</span>
                  <button>
                    <img src={Excel_logo} alt="Excel" />
                    <span>
                      <a href={sharepointLinkInEmail?.sharepoint_file_info?.web_url} rel="noreferrer" target="_blank" style={{ color: "#fff" }}>
                        {sharepointLinkInEmail?.sharepoint_file_info?.web_url ? "View Payroll Summary" : "Missing Link!"}
                      </a>{" "}
                    </span>
                  </button>
                </div>
                <div className="SubTask__EmailPreview__Body__MainTitle__MainContent__customFields">
                  {location} - {platform} - {payroll_type}
                </div>
                <div className="SubTask__EmailPreview__Body__MainTitle__MainContent__cycledates">
                  {dateObjToAppString(asanaTaskDateStringToDate(cycleStartDate),"/")} - {dateObjToAppString(asanaTaskDateStringToDate(cycleEndDate),"/")}
                </div>
              </div>
    </>
  )
}

export default PayrollPreviewMainTitleView