import { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { DataContext } from "../../Context/DataContext";
import { CommentContext } from "../../Context/CommentContext";
import { reportTabsDict } from "../../Types/Report";

const QBOReportsTabs = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const { reportTabIndex, setReportTabIndex } = useContext(DataContext);
  const { reportPage} = useContext(CommentContext)

  const onChangeTabIndex = (index) => {
    setReportTabIndex(index);
    setSearchParams((prevParams) => {
      return new URLSearchParams({
        ...Object.fromEntries(prevParams.entries()),
        ...{ report_type: index.toString(), drill_transactions: "false" },
      });
    });
  };

  return (
    <>
      <Tabs className="tabs-bi" id="financial-reports-tabs" selectedIndex={reportTabIndex} onSelect={onChangeTabIndex}>
        <TabList>
          <Tab className="tabs-bi__link settings_tab_link">{reportTabsDict[reportPage]['0']}</Tab>
          <Tab className="tabs-bi__link settings_tab_link">{reportTabsDict[reportPage]['1']}</Tab>
          {/* <Tab className="tabs-bi__link settings_tab_link">Cash Flows</Tab> */}
          <Tab className="tabs-bi__link settings_tab_link">{reportTabsDict[reportPage]['2']}</Tab>
          <Tab className="tabs-bi__link settings_tab_link">{reportTabsDict[reportPage]['3']}</Tab>
        </TabList>
        <TabPanel />
        <TabPanel />
        {/* <TabPanel /> */}
        <TabPanel />
        <TabPanel />
      </Tabs>
    </>
  );
};

export default QBOReportsTabs;
