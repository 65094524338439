import React, { useContext, useEffect, useState } from "react";
import { createAsanaTaskComment, deleteAsanaTaskComment, retrieveAsanaTask, retrieveAsanaTaskStories } from "../../../../../../APIFunctions/Asana";
import { MonthEndCloseContext } from "../../../../../../contexts/TicketingSystem/MonthEndCloseContext";
import RichTextEditor, { convertQuillHTMLToAsana, convertQuillMentionsToAsana } from "../../../../../Common/RichTextEditor";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useDeleteComment, useEditComment, useResolveComment } from "../../../../hooks/useComments";
import CommentSkeleton from "./CommentSkeleton";
import { TaskServicesContext } from "../../../../../../contexts/Task/TaskServicesContext";
import AssigneeSkeleton from "../../../../../Common/Skeletons/AssigneeSkeleton";
import CommentNormalView from "./CommentNormalView";
import CommentEditView from "./CommentEditView";
import CommentEditFooter from "./CommentEditFooter";
import { useSelectedMonthEndCloseMilestone } from "../../../../hooks/useSelectedMilestone";

const MonthEndComment = ({ comment }) => {
  

  const { team } = useContext(TaskServicesContext);
  const { editComment, selectedCommentID, onSelectComment, commentEditID, setCommentEditID } = useContext(MonthEndCloseContext);
  const [ setCommentDescriptionFlag] = useState<boolean>(false);
  const [description, setDescription] = useState<string>("");
  const [ setReplies] = useState<any[]>([]);
  const [reply, setReply] = useState<string>("");
  const [goToCommentString, setGoToCommentString] = useState<string>("");

  // custom hooks
  const [deleteMonthEndCellCommentMutation, deleteAsanaTaskMutation] = useDeleteComment(comment);
  const resolveComment = useResolveComment(comment);
  const editCommentMutation = useEditComment(comment?.id,editComment)
  const selectedMilestone = useSelectedMonthEndCloseMilestone();

  const queryClient = useQueryClient();

  const repliesQuery = useQuery({
    queryKey: ["month-end-comment-replies", comment.id],
    queryFn: () => retrieveAsanaTaskStories(comment.asana_task_id),
    select: (data) => data?.data?.filter((x) => x.type === "comment"),
    staleTime: 60 * 1000,
    refetchInterval:60 * 1000
  });
  const asanaTaskQuery = useQuery({
    queryKey: ["month-end", "comment-task",comment.asana_task_id],
    queryFn: () => retrieveAsanaTask(comment.asana_task_id),
    staleTime: 60 * 1000,
    refetchInterval:60 * 1000,
    enabled: !!comment.asana_task_id,
  });

  

  const onReply = async () => {
    const cleaned_reply = convertQuillHTMLToAsana(convertQuillMentionsToAsana(reply));
    const newCommentObj = {
      data: {
        html_text: cleaned_reply,
      },
    };
    const response = await createAsanaTaskComment(asanaTaskQuery.data?.data.gid, newCommentObj);
    setReply("");
    return response;
  };

  // todo: chain the below mutation to another mutation that would
  const replyMutation = useMutation({
    mutationFn: () => onReply(),
    onSuccess: () => {
      queryClient.refetchQueries(["month-end-comment-replies", comment.id]);
    },
  });

  const replyDeleteMutation = useMutation({
    mutationFn: (replyGID) => deleteAsanaTaskComment(replyGID),
    onSuccess: () => {
      queryClient.refetchQueries(["month-end-comment-replies", comment.id]);
    },
  });

  const getAsanaTaskDescription = async () => {
    // const response = await retrieveAsanaTask(comment.asana_task_id);
    // if (asanaTaskQuery?.data?.data) {
      // setAsanaTask(response["data"]);
      const html_string = comment.asana_task?.html_notes.replace("</body>", "").replace("<body>", "").replaceAll("\n", "<br/>");
      const go_to_comment_string =
        html_string.indexOf("Go to comment</a>") > -1
          ? html_string.substring(html_string.indexOf("Comment link →"), html_string.indexOf("Go to comment</a>") + "Go to comment</a>".length)
          : "";
      setGoToCommentString(go_to_comment_string);
      const removedCommentLink = html_string.replace(go_to_comment_string, "").replace("<br/>", "");
      setDescription(removedCommentLink);
    // }
  };

  const onEditCommentDescription = async() => {
    const cleaned_description = convertQuillHTMLToAsana(convertQuillMentionsToAsana(goToCommentString + "\n" + description));
    editCommentMutation.mutate({ asanaTaskID: asanaTaskQuery.data?.data?.gid, updateObj: { html_notes: cleaned_description } });
    setCommentEditID(null);
  };

  const onEnterReply = (e) => {
    if (e.keyCode === 13) {
      onReply();
    }
  };

  const onDelete = () => {
    deleteMonthEndCellCommentMutation.mutate();
    deleteAsanaTaskMutation.mutate();
  };

  // const onDeleteReply = (replyGID) => {
  //   deleteAsanaTaskComment(replyGID);
  //   const updatedArray = replies.filter((x) => x.gid !== replyGID);
  //   setReplies(updatedArray);
  // };

  const updateCommentResolution = () => {
    
    if (comment.asana_task?.completed === true) {
      resolveComment.mutate(false);
    } else {
      resolveComment.mutate(true);
    }
  };


  // const classBased = new  RichEditorClass()

  useEffect(() => {
      getAsanaTaskDescription();
  }, [comment.asana_task?.html_notes]);

  

  return asanaTaskQuery.isFetched && (deleteMonthEndCellCommentMutation.isIdle || deleteMonthEndCellCommentMutation.isSuccess) && !resolveComment.isLoading ? (
    <>
      <div
        id="comment-container"
        onClick={() => onSelectComment(comment)}
        className="MonthEndComments__Comment"
      >
        {commentEditID !== comment.id ? (
          <CommentNormalView
            setCommentEditID={setCommentEditID}
            asanaTaskQuery={asanaTaskQuery}
            comment={comment}
            updateCommentResolution={updateCommentResolution}
            onDelete={onDelete}
            setCommentDescriptionFlag={setCommentDescriptionFlag}
            editCommentMutation={editCommentMutation}
            description={description}
            selectedCommentID={selectedCommentID}
            reply={reply}
            setReply={setReply}
            replyMutation={replyMutation}
            onEnterReply={onEnterReply}
            repliesQuery={repliesQuery}
            replyDeleteMutation={replyDeleteMutation}
            team={team}
          />
        ) : (
          
          <CommentEditView
            description={description}
            setDescription={setDescription}
            team={team}
            editCommentMutation={editCommentMutation}
            comment={comment}
          />
        )}
      </div>
      {
        commentEditID === comment.id ?  <CommentEditFooter onEditCommentDescription={onEditCommentDescription} setCommentEditID={setCommentEditID}/> : <></>
      }

      
    </>
  ) : (
    <CommentSkeleton />
  )
};

export default MonthEndComment;
