import { createContext, useRef, useState, useEffect, useContext, useCallback } from "react";
import { TypeOptions } from "react-toastify";
import { loadingToast, updateToast, dismissToast, isExistToast, showUpdateToast, showToast, NewPDFToastSuccess, NewQBOExportToastSuccess, NewQBOExportToastFailure } from "../utils/toastHelpers";
import { AuthContext } from "./AuthContext";
import { CURRENT_WS_BASE_URL } from "../APIFunctions/apiConstants";
import { LogicAppStatus, Notification, NotificationTypes, UpdateQBStatus, LogicAppRunUpdateMessage, LogicAppTypes } from "../types/Notifications/Notifications";
import { Company, QuickBookConnectingStatus } from "../types/Company";
import { UploadedSharepointFileInfo } from "../types/QBOExport";

export interface SocketContextProps {
  hasHoldUpdates: boolean;
  setHasHoldUpdate: (value: boolean) => void;

  printPDFRunning: boolean;
  setPrintPDFRunning: (value: boolean) => void;

  // This setter will be used in the headcount data context to intialize the headcount loader function
  refreshHeadCountFunction: (value: string) => void;
  setRefreshHeadCountFunction: (value: (value: string) => void) => void;

  // This setter will be used in the draft financial package component to store information of the uploaded file.

  handleUploadedMECFinancialPackageFunction: (value: UploadedSharepointFileInfo) => void;
  setHandleUploadedMECFinancialPackageFunction: (value: (value: UploadedSharepointFileInfo) => void) => void;

  setAgedReportsDataImportStatusHandle: (value: (message, status?, data?) => void) => void;
  setAgedReportsDetailDataImportStatusHandle: (value: (message, status?, data?) => void) => void;

  // This setter will be used in the FinancialStatment data context to reload the data
  setLoadFinancialRepoortData: (value: () => void) => void;

  qb_ConnectingStatus: QuickBookConnectingStatus;
  setQB_ConnectingStatus: (qb_ConnectingStatus: QuickBookConnectingStatus) => void;
  isTrackingLogicAppStatus: boolean;
  setIsTrackingLogicAppStatus: (value: boolean) => void;
  dismissSyncProcessTracking: () => void;
  updatelogicAppTracking: (title: string, type: TypeOptions, message: string, progress?: number) => void;
}

export const SocketContext = createContext<SocketContextProps>(null as any);

export const SocketContextProvider = (props) => {
  const { token, selectedCompany, setUserSelectedCompany, userProfile } = useContext(AuthContext);
  const [hasHoldUpdates, setHasHoldUpdate] = useState(false);
  const [printPDFRunning, setPrintPDFRunning] = useState(false);
  const [refreshHeadCountFunction, setRefreshHeadCountFunction] = useState<(value: string) => void>(() => {});

  const [handleUploadedMECFinancialPackageFunction, setHandleUploadedMECFinancialPackageFunction] = useState<(value: UploadedSharepointFileInfo) => void>(() => {});
  const [loadFinancialRepoortData, setLoadFinancialRepoortData] = useState<() => void>(() => {});
  const [isTrackingLogicAppStatus, setIsTrackingLogicAppStatus] = useState(false);
  const [qb_ConnectingStatus, setQB_ConnectingStatus] = useState(QuickBookConnectingStatus.NOT_CONNECTED);
  const [qboAgedReportsImportStatusHandle, setAgedReportsDataImportStatusHandle] = useState<(message, status?, data?) => void>(() => {});
  const [qboAgedReportsDetailImportStatusHandle, setAgedReportsDetailDataImportStatusHandle] = useState<(message, status?, data?) => void>(() => {});

  const LogicAppLoadingToastId = useRef("");
  const AskForUpdatesToastId = useRef("");

  const ws = useRef<WebSocket | null>(null);
  const [filpper, setFilpper] = useState(false);

  const handle_sync_process_run_update_message = useCallback(
    (runType: LogicAppTypes, notification: LogicAppRunUpdateMessage): void => {
      var processName = "Runninng";
      switch (runType) {
        case LogicAppTypes.SYNC_QB_COMPANY:
          processName = "Syncing QB Data";
          break;
        case LogicAppTypes.SYNC_HEADCOUNT:
          processName = "Syncing Excel File";
          break;
        case LogicAppTypes.EXPORT_QBO_TO_EXCEL:
          processName = "Exporting QBO to Excel";
          break;
      }
      switch (notification.status) {
        case LogicAppStatus.INITIATED:
          updatelogicAppTracking(processName, "info", "The process is initiated.");
          break;
        case LogicAppStatus.STARTED:
          updatelogicAppTracking(processName, "info", "The process is started.");
          break;
        case LogicAppStatus.PENDING:
          updatelogicAppTracking(processName, "info", "The process is running.", notification.progress);
          break;
        case LogicAppStatus.FAILED:
          dismissSyncProcessTracking();
          NewQBOExportToastFailure(notification.fail_reason)
          break;
        case LogicAppStatus.TIMEOUT:
          updatelogicAppTracking(processName, "error", "The process TIMEOUT reached, please try again or contact adminstrator");
          break;
        case LogicAppStatus.SUCCESSED:
          
          if (runType === LogicAppTypes.EXPORT_QBO_TO_EXCEL) {
            dismissSyncProcessTracking();
            if ("data" in notification) {
              if ("uploaded_file" in notification.data) {
              
                NewQBOExportToastSuccess(notification.data["uploaded_file"]["webUrl"], false, "");
  
                handleUploadedMECFinancialPackageFunction(notification.data["uploaded_file"]);
              }
              if ("process_tracker_id" in notification.data) {
                
                NewQBOExportToastSuccess("", true, notification.data["process_tracker_id"]);
              }
            }
            
          } else {
            updatelogicAppTracking(processName, "success", "Run status successed");
          }

          if (runType === LogicAppTypes.SYNC_QB_COMPANY) {
            loadFinancialRepoortData();
          }
          break;
        default:
          break;
      }
    },
    [loadFinancialRepoortData, handleUploadedMECFinancialPackageFunction]
  );

  const handle_import_qbo_report_data = useCallback(
    (runType: LogicAppTypes, notification: LogicAppRunUpdateMessage): void => {
      // by default the handle is set for the aged reports use case
      let handle = qboAgedReportsImportStatusHandle;
      switch (runType) {
        case LogicAppTypes.AGED_REPORTS:
          handle = qboAgedReportsImportStatusHandle;
          break;
        case LogicAppTypes.AGED_REPORTS_DETAIL:
          handle = qboAgedReportsDetailImportStatusHandle;
          break;
        default:
          break;
      }
      switch (notification.status) {
        
        case LogicAppStatus.INITIATED:
          handle("Initiating Import data...", notification.progress);
          break;
        case LogicAppStatus.STARTED:
          handle("Starting Importing data...", notification.progress);
          break;
        case LogicAppStatus.PENDING:
          handle("Importing data...", notification.progress);
          break;
        case LogicAppStatus.FAILED:
          handle("Error", notification.fail_reason ?? "The process is FAILD, please try again or contact adminstrator");
          break;
        case LogicAppStatus.TIMEOUT:
          handle("Error", "The process TIMEOUT reached, please try again or contact adminstrator");
          break;
        case LogicAppStatus.SUCCESSED:
          if (runType === LogicAppTypes.AGED_REPORTS) {
            handle("Loading Aged Reports Data...", notification.progress, notification.data);
          }
          if (runType === LogicAppTypes.AGED_REPORTS_DETAIL) {
            handle("Loading Details Data...", notification.progress, notification.data);
          }
          break;
        default:
          break;
      }
    },
    [qboAgedReportsImportStatusHandle]
  );

  const handle_printing_pdf_update_message = useCallback(
    (notification: LogicAppRunUpdateMessage): void => {
      switch (notification.status) {
        case LogicAppStatus.FAILED:
          setPrintPDFRunning(false);
          showToast("error", notification.fail_reason ?? "The process is FAILD, please try again or contact adminstrator.", "Printing PDF");
          break;
        case LogicAppStatus.TIMEOUT:
          setPrintPDFRunning(false);
          showToast("error", "The process TIMEOUT reached, please try again or contact adminstrator.", "Printing PDF");
          break;
        case LogicAppStatus.SUCCESSED:
          var company: Company = new Company(selectedCompany);
          company.printing_documentId = notification.documentId;
          setUserSelectedCompany(company);
          setPrintPDFRunning(false);
          NewPDFToastSuccess();
          break;
        case LogicAppStatus.INITIATED:
        case LogicAppStatus.STARTED:
        case LogicAppStatus.PENDING:
          if (!printPDFRunning) setPrintPDFRunning(true);
          break;
        default:
          break;
      }
    },
    [printPDFRunning, selectedCompany, setUserSelectedCompany]
  );

  const handle_headcount_Updated = useCallback((): void => {
    // show toast only if there are no toast running to avoid duplicates
    if (AskForUpdatesToastId.current === "" || !isExistToast(AskForUpdatesToastId.current)) {
      dismissToast(AskForUpdatesToastId.current ?? "");
      const onUpdate = () => {
        setHasHoldUpdate(false);
        refreshHeadCountFunction("Update done!");
      };
      const onCancel = () => {
        setHasHoldUpdate(true);
      };
      AskForUpdatesToastId.current = showUpdateToast(onUpdate, onCancel);
    }
  }, [refreshHeadCountFunction]);

  useEffect(() => {
    if (ws.current) {
      ws.current?.close();
    }
  }, [selectedCompany]);

  useEffect(() => {
    ws.current = new WebSocket(`${CURRENT_WS_BASE_URL}company_updates/${selectedCompany?.state_name}/${selectedCompany?.id}/?token=${token}`);
    ws.current.onopen = () => console.log(`ws opened [${selectedCompany?.companyName}]`);
    ws.current.onclose = (e) => {
      console.log(`ws closed [${selectedCompany?.companyName}] and reconnect after 1s`, e);
      setTimeout(() => setFilpper(!filpper), 1000);
    };
    return () => ws.current?.close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filpper, token]);

  useEffect(() => {
    console.log(ws);
    if (!ws.current) return;
    ws.current.onmessage = function (e) {
      let notification = new Notification(JSON.parse(e.data).message);
      let logicAppRunUpdateMessage: LogicAppRunUpdateMessage = notification.data as LogicAppRunUpdateMessage;
      console.log("ws notification", notification);
      if (notification.data.sender.toString() === userProfile.id.toString()) return;
      switch (notification.type) {
        case NotificationTypes.LOGIC_APP_RUN_UPDATE:
          switch (logicAppRunUpdateMessage.type) {
            case LogicAppTypes.SYNC_QB_COMPANY:
            case LogicAppTypes.SYNC_HEADCOUNT:
              handle_sync_process_run_update_message(logicAppRunUpdateMessage.type, logicAppRunUpdateMessage);
              break;
            case LogicAppTypes.PRINTING_PDF:
              handle_printing_pdf_update_message(logicAppRunUpdateMessage);
              break;
            default:
              break;
          }
          break;
        case NotificationTypes.EXPORT_QBO_FILE:
          switch (logicAppRunUpdateMessage.type) {
            case LogicAppTypes.EXPORT_QBO_TO_EXCEL:
              handle_sync_process_run_update_message(logicAppRunUpdateMessage.type, logicAppRunUpdateMessage);
              break;
            default:
              break;
          }
          break;
        case NotificationTypes.IMPORT_QBO_DATA:
          logicAppRunUpdateMessage = notification.data as LogicAppRunUpdateMessage;
          handle_import_qbo_report_data(logicAppRunUpdateMessage.type, logicAppRunUpdateMessage);

          break;
        case NotificationTypes.COMPANY_HEADCOUNT_UPDATED:
          handle_headcount_Updated();
          break;
        case NotificationTypes.CONNECTING_QB_STATUS_UPDATED:
          let updateQBStatus: UpdateQBStatus = notification.data as UpdateQBStatus;
          console.log("qbo status:", updateQBStatus.status);
          setQB_ConnectingStatus(updateQBStatus.status);
          break;

        default:
          break;
      }
      console.log("Message:", e.data);
    };
  }, [handle_headcount_Updated, handle_sync_process_run_update_message, handle_import_qbo_report_data, handle_printing_pdf_update_message, userProfile, ws.current]);

  const dismissSyncProcessTracking = () => {
    dismissToast(LogicAppLoadingToastId.current ?? "");
    LogicAppLoadingToastId.current = "";
  };
  const updatelogicAppTracking = (title: string, type: TypeOptions, message: any, progress?: number) => {
    // update progress in message
    if (progress && progress > 0) message += `...    (${Math.floor(progress * 100)}%)`;

    // if there are no toast running, then create new one
    if (LogicAppLoadingToastId.current === "") {
      setIsTrackingLogicAppStatus(true);
      dismissToast(LogicAppLoadingToastId.current ?? "");
      
      if (type !== 'error') {
        LogicAppLoadingToastId.current = loadingToast(title, message, type, progress);
      }
      
      return;
    }

    // update the current running toast
    updateToast(LogicAppLoadingToastId.current, title, type, message, progress ?? 0);

    // if the type 'error' or success deallocate the toast refrence
    if (type !== "info") {
      setIsTrackingLogicAppStatus(false);
      LogicAppLoadingToastId.current = "";
    }
  };
  return (
    <SocketContext.Provider
      value={{
        hasHoldUpdates,
        setHasHoldUpdate,

        printPDFRunning,
        setPrintPDFRunning,

        refreshHeadCountFunction,
        setRefreshHeadCountFunction,

        qb_ConnectingStatus,
        setQB_ConnectingStatus,

        isTrackingLogicAppStatus,
        setIsTrackingLogicAppStatus,

        dismissSyncProcessTracking,
        updatelogicAppTracking,

        setLoadFinancialRepoortData,
        handleUploadedMECFinancialPackageFunction,
        setHandleUploadedMECFinancialPackageFunction,
        setAgedReportsDataImportStatusHandle,
        setAgedReportsDetailDataImportStatusHandle,
      }}
    >
      {props.children}
    </SocketContext.Provider>
  );
};
