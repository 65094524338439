import { createContext, useContext, useEffect, useState } from "react";
import { addUser, deleteUser, deleteUserList, updateUser } from "../../APIFunctions/accountSettings";
import { LoaderStatus } from "../../types/Loaders";
import { ManageUsers, ManageUsersDialogType, UserRole } from "../../types/ManageUsers";
import { AuthContext } from "../AuthContext";
import { DataConext } from "./DataConext";


export interface UserListConextProps {
    selectedFlatRows: readonly string[];
    setSelectedFlatRows: (selectedFlatRows: readonly string[]) => void;

    onClickSelectedItem: ManageUsers | null;
    setOnClickSelectedItem: (item: ManageUsers | null) => void;

    deleteDialogeOpen: boolean;
    closeDeleteDialoge: (apply: boolean) => void;
    openDeleteDialoge: () => void;

    openEditDialoge: (id: string) => void;

    showToastThenRefresh: (message: string, markedCount?: number) => void;
    manageUsersDialogType: ManageUsersDialogType;
    userDialogIsOpenByType: { [x: number]: boolean; };
    openUserDialog: (manageUsersDialogType: ManageUsersDialogType) => void;
    closeUserDialog: () => void;
    applyUpdateUserItem: (updatedUserItem: ManageUsers) => Promise<void>;
    applyAddUserItem: (newUserItem: ManageUsers) => Promise<void>;


    tabIndex: number;
    setTabIndex: (tabIndex: number) => void;

    isModalOpen: boolean;
    toggleModal: () => void;
}

const initialUserDialogIsOpenByType = {
    [ManageUsersDialogType.DEFAULT]: false,
    [ManageUsersDialogType.INVITE]: false,
    [ManageUsersDialogType.EDIT]: false,
}

export const UserListConext = createContext<UserListConextProps>(null as any);

export const UserListConextProvider = (props) => {
    const { selectedCompany } = useContext(AuthContext)
    const { userListDisplay, settingLoaderStatus, showToastThenRefresh } = useContext(DataConext);

    const [selectedFlatRows, setSelectedFlatRows] = useState<readonly string[]>([]);

    const [deleteDialogeOpen, setDeleteDialogeOpen] = useState(false);

    const [manageUsersDialogType, setManageUsersDialogType] = useState<ManageUsersDialogType>(ManageUsersDialogType.DEFAULT);
    const [userDialogIsOpenByType, setUserDialogIsOpenByType] = useState<{ [x: number]: boolean; }>(initialUserDialogIsOpenByType);


    const [onClickSelectedItem, setOnClickSelectedItem] = useState<ManageUsers | null>(null)
    const [tabIndex, setTabIndex] = useState(0);
    const [isModalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        if (tabIndex === 0) {
            setModalOpen(false);
        }
    }, [tabIndex]);

    const toggleModal = () => {
        setModalOpen(!isModalOpen)
    }   

    const openEditDialoge = (id: string) => {
        // if target user is owner
        if (id.toString() === '-1') return
        const new_item = userListDisplay.find(headcount => id.toString() === headcount.id.toString())
        const currentUserManager = userListDisplay.find(user => user.current_user)
        if(currentUserManager && currentUserManager.role === UserRole.Admin) {
            // return if the target user is admin
            if(new_item && (new_item.role === UserRole.Admin || new_item.role === UserRole.MDADMIN)) return
        } 
        
        
        if (new_item) {
            setOnClickSelectedItem(new_item);
            setManageUsersDialogType(ManageUsersDialogType.EDIT);
            setUserDialogIsOpenByType({ ...initialUserDialogIsOpenByType, [ManageUsersDialogType.EDIT]: true })
        }
    }

    const closeDeleteDialoge = (apply: boolean) => {
        closeUserDialog();
        setDeleteDialogeOpen(false)
        if (apply)
            applyDeleteUser()
    }
    const openDeleteDialoge = () => {
        setDeleteDialogeOpen(true)
    }

    const openUserDialog = (manageUsersDialogType: ManageUsersDialogType) => {
        setManageUsersDialogType(manageUsersDialogType);
        setUserDialogIsOpenByType({ ...initialUserDialogIsOpenByType, [manageUsersDialogType]: true });

    }

    const closeUserDialog = () => {
        setManageUsersDialogType(ManageUsersDialogType.DEFAULT);
        setUserDialogIsOpenByType(initialUserDialogIsOpenByType);
        setOnClickSelectedItem(null);
    }

    const applyAddUserItem = async (newUserItem: ManageUsers) => {
        if (selectedCompany) {
            if (newUserItem) {
                setSelectedFlatRows([]);
                const result = await addUser(ManageUsers.toAPIRepresentation(newUserItem), selectedCompany)
                if (result.id) {
                    newUserItem = new ManageUsers(newUserItem)
                    const message = ` ${newUserItem.user_name} Added Successfully.`
                    showToastThenRefresh(message, result.detail)
                }
            }
        }
    }

    const applyDeleteUser = async () => {
        if (selectedCompany) {
            if (onClickSelectedItem) {
                const result = await deleteUser(selectedCompany, onClickSelectedItem.id)
                if (result) {
                    setOnClickSelectedItem(null);
                    const message = `${onClickSelectedItem.user_name ?? ""} Deleted Successfully.`
                    showToastThenRefresh(message)
                }
            }
            else {
                if (selectedFlatRows.length > 0 && settingLoaderStatus === LoaderStatus.Rendered) {
                    const result = await deleteUserList(selectedCompany, selectedFlatRows)
                    if (result) {
                        setSelectedFlatRows([]);
                        const message = `${selectedFlatRows.length} User${selectedFlatRows.length > 1 ? 's' : ''} Deleted Successfully.`
                        showToastThenRefresh(message)
                    }
                }
            }
        }

    };

    const applyUpdateUserItem = async (updatedUserItem: ManageUsers) => {
        if (selectedCompany) {
            if (updatedUserItem) {
                closeUserDialog();
                if (updatedUserItem !== onClickSelectedItem) {
                    var result = await updateUser(ManageUsers.toAPIRepresentation(updatedUserItem), selectedCompany, updatedUserItem.id);
                    if (result.id) {
                        result = new ManageUsers(result)
                        const message = `${result.user_name} Updated Successfully.`
                        showToastThenRefresh(message, 1);
                    }
                }
            }
            setOnClickSelectedItem(null);
        }
    };


    return (
        <UserListConext.Provider value={{
            selectedFlatRows,
            setSelectedFlatRows,
            onClickSelectedItem,
            setOnClickSelectedItem,

            deleteDialogeOpen,
            closeDeleteDialoge,
            openDeleteDialoge,
            openEditDialoge,
            showToastThenRefresh,
            manageUsersDialogType,
            userDialogIsOpenByType,
            openUserDialog,
            closeUserDialog,
            applyUpdateUserItem,
            applyAddUserItem,

            tabIndex, setTabIndex,
            isModalOpen, toggleModal,
        }}>
            {props.children}
        </UserListConext.Provider>
    )
}

