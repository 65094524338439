import parse from 'html-react-parser';
import './ExcelEmbedViewer.css';
interface ExcelEmbedViewerProps {
    visible: boolean;
    excel_embed_iframe: string;
    isShort?: boolean;
}
export default function ExcelEmbedViewer(props: ExcelEmbedViewerProps) {
    const { excel_embed_iframe, visible, isShort } = props;
    return (
        <div
            style={{ position: visible ? "static" : "absolute", left: visible ? "0px" : "-100000px" }}
            className={`ExcelEmbeded_container ${isShort ? "short":""}`}>
                {parse(excel_embed_iframe)}
        </div>
    )
}