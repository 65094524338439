import React, { useContext, useEffect, useState } from "react";
import EmailBuilder from "../../MilestoneEmail";
import DraftFinancialPackage from "./DraftFinancialPackage";
import { MilestoneLinksContext } from "../../../../contexts/TicketingSystem/MilestoneLinksContext";
import Financials from "./Financials";
import { useParams } from "react-router-dom";
import { SubTasksContext } from "../../../../contexts/TicketingSystem/SubTasksContext";
import AgedReportsRoot from "./AgedReports";
import { MonthEndCloseContext } from "../../../../contexts/TicketingSystem/MonthEndCloseContext";

export const milestoneTaskComponentsList = ["Email Draft","Draft Financial Package","Financials"]

const MilestoneTaskComponent = () => {
  const {milestoneTaskParam} = useParams()
  const { preparationStageTasks } =useContext(SubTasksContext)

  const component = () => {
    let milestoneTaskName;
    if (milestoneTaskParam && preparationStageTasks.length > 0) {
      const target = preparationStageTasks?.find((x) => x.gid === milestoneTaskParam)
      milestoneTaskName = target.name
    }

    switch (milestoneTaskName) {
      case "Email Draft":
        return <EmailBuilder />;
      case "Draft Financial Package":
        return <DraftFinancialPackage />;
      // case "Workpapers":
      //   return <MilestoneLinkComponent link={monthEndBackUpFileLink} areActionsAllowed={true} showAddButton={true} />;
        case "Financials":
          return <Financials />;

      default:
        <div className="SubTask__Body__Container__MilestoneTaskComponent__NoComponents">No components or automations available for this task yet!</div>;
    }
  };

  return <>{component()}</>;
};

export default MilestoneTaskComponent;
