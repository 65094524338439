import React, { useContext } from "react";
import { MilestoneContext } from "../../../../../contexts/TicketingSystem/MilestoneContext";
import { MonthEndCloseContext } from "../../../../../contexts/TicketingSystem/MonthEndCloseContext";
import { asanaTaskDateStringToDate, formatDateStandard, getMonth, getYear } from "../../../../../utils/DateFunctions";
import { useSelectedMonthEndCloseMilestone } from "../../../hooks/useSelectedMilestone";

const Table = () => {

  const { monthEndPeriod} = useContext(MonthEndCloseContext)

  const selectedMilestone = useSelectedMonthEndCloseMilestone()

  const period = selectedMilestone.data?.data?.custom_fields?.find(x => x.name === "Period")?.date_value?.date
  const periodDate = asanaTaskDateStringToDate(period)
  
  const dueDate = asanaTaskDateStringToDate(selectedMilestone.data?.data?.due_on)

  return (
    <div className="ClientPayrollCard__Container__Table__Container">
      <table>
        <tbody>
          <tr>
            <td className="left">
              <div className="table__cell">
                <div className="table__cell__label">Month</div>
                <div className="table__cell__value">{periodDate ? getMonth(periodDate) : "--"}</div>
              </div>
            </td>
            <td>
              <div className="table__cell">
                <div className="table__cell__label">Milestone Due Date</div>
                <div className="table__cell__value">{dueDate ? formatDateStandard(dueDate) : "--"}</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Table;
