import { Button, ButtonProps } from "@mui/material";
import { GridRenderCellParams, gridFilteredDescendantCountLookupSelector, useGridApiContext, useGridSelector } from "@mui/x-data-grid-pro";
import { Box } from "@mui/system";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { uppercaseAllWordLetters } from "../../../../../../utils/helpers";

export const isNavigationKey = (key: string) => key === "Home" || key === "End" || key.indexOf("Arrow") === 0 || key.indexOf("Page") === 0 || key === " ";
export const CustomGridTreeDataGroupingCell = (props: GridRenderCellParams) => {
    const { id, field, row, rowNode } = props;
    const apiRef = useGridApiContext();
    const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector);
    const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;
  
    const handleKeyDown: ButtonProps["onKeyDown"] = (event) => {
      if (event.key === " ") {
        event.stopPropagation();
      }
      if (isNavigationKey(event.key) && !event.shiftKey) {
        apiRef.current.publishEvent("cellNavigationKeyDown", props, event);
      }
    };
  
    const handleClick: ButtonProps["onClick"] = (event) => {
      apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
      apiRef.current.setCellFocus(id, field);
      event.stopPropagation();
    };

    const value = () => {
      if ('Group Column' in row) {
        return row['Group Column']
      } else {
        return row['Date']
      }
      return ""
    }

    return (
      <Box sx={{ ml: rowNode.depth * 1 }}>
        <div>
          {filteredDescendantCount > 0 && rowNode.children !== null ? (
            <Button
              onClick={handleClick}
              onKeyDown={handleKeyDown}
              tabIndex={-1}
              sx={{
                width: "50rem",
                height: "3rem",
                color: "#666687",
                justifyContent: "left !important",
                fontWeight: 700,
                marginLeft: "-2rem",
                "&:hover": {
                  background: "rgba(133, 180, 64, 0.1)",
                },
              }}
            >
              <span>
                {!rowNode.childrenExpanded ? <ArrowRightIcon /> : <ArrowDropDownIcon />} {value()}
              </span>
            </Button>
          ) : row['bold'] === 'bold' ? (
            <Box sx={{ fontWeight: 700 }}>{uppercaseAllWordLetters(value())}</Box>
          ) : (
            <Box sx={{ fontWeight: 400 }}>{value()}</Box>
          )}
        </div>
      </Box>
    );
  };