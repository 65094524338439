import { IaccountBalanceArray } from "./interfaces";

export enum FormulaNameEnum {
    Net_Income = "Net Income",
    Net_Other_Income = 'Net Other Income',
    Net_Operating_Income = 'Net Operating Income',
    Gross_Profit = 'Gross Profit',
    Cash_Burn = "Cash Burn",
    Beginning_Ending_Cash = "Beginning and Ending Cash",
    Beginning_Cash = 'Beginning Cash',
    Ending_Cash = "Ending Cash",
    Total_Cash_Flows = "Total Cash Flows"
}
export enum SectionNameEnum {
    NO_SECTION = "",

    Revenue = "Revenue",
    Cost_Of_Goods_Sold = "Cost of Goods Sold",
    Expenses = "Expenses",
    Other_Income = "Other Income",
    Other_Expenses = "Other Expenses",

    Other_Current_Assets = "Other Current Assets",
    Fixed_Assets = "Fixed Assets",
    Other_Assets = "Other Assets",
    Credit_Cards = "Credit Cards",
    Bank_Accounts = "Bank Accounts",
    Other_Current_Liabilities = "Other Current Liabilities",
    Long_Term_Liabilities = "Long-Term Liabilities",
    Equity = "Equity",
    Accounts_Payable = "Accounts Payable",
    Accounts_Receivable = "Accounts Receivable",

    Current_Assets = "Current Assets",
    Current_Liabilities = "Current Liabilities",

    // Exclusive Sections for the BalanceSheet Report
    Assets= "Assets",
    Liabilities_and_Equity= "Liabilities and Equity",
    Liabilities= "Liabilities",

    // Exclusive Sections for CachFlows
    Δ_in_Current_Assets = "Δ in Current Assets",
    Δ_in_Current_Liabilities = "Δ in Current Liabilities",
    Operating_Activities = "Operating Activities",
    Financing_Activities = "Financing Activities",
    Investing_Activities = "Investing Activities",
}

export enum AccountTypeEnum {
    Other_Current_Asset = "Other Current Asset",
    Bank = "Bank",
    Fixed_Asset = "Fixed Asset",
    Other_Asset = "Other Asset",
    Credit_Card = "Credit Card",
    Other_Current_Liability = "Other Current Liability",
    Long_Term_Liability = "Long Term Liability",
    Equity = "Equity",
    Income = "Income",
    Other_Income = "Other Income",
    Cost_Of_Goods_Sold = "Cost of Goods Sold",
    Expense = "Expense",
    Other_Expense = "Other Expense",
    Accounts_Payable = "Accounts Payable",
    Accounts_Receivable = "Accounts Receivable",
}

export enum AccountSubTypeEnum {
    No_SubType = "",
    Retained_Earnings = "RetainedEarnings"
}

export const sectionNameToAccountType = {
    [SectionNameEnum.Revenue]: AccountTypeEnum.Income,
    [SectionNameEnum.Cost_Of_Goods_Sold]: AccountTypeEnum.Cost_Of_Goods_Sold,
    [SectionNameEnum.Expenses]: AccountTypeEnum.Expense,
    [SectionNameEnum.Other_Income]: AccountTypeEnum.Other_Income,
    [SectionNameEnum.Other_Expenses]: AccountTypeEnum.Other_Expense,
    [SectionNameEnum.Other_Current_Assets]: AccountTypeEnum.Other_Current_Asset,
    [SectionNameEnum.Fixed_Assets]: AccountTypeEnum.Fixed_Asset,
    [SectionNameEnum.Other_Assets]: AccountTypeEnum.Other_Asset,
    [SectionNameEnum.Credit_Cards]: AccountTypeEnum.Credit_Card,
    [SectionNameEnum.Bank_Accounts]: AccountTypeEnum.Bank,
    [SectionNameEnum.Other_Current_Liabilities]: AccountTypeEnum.Other_Current_Liability,
    [SectionNameEnum.Long_Term_Liabilities]: AccountTypeEnum.Long_Term_Liability,
    [SectionNameEnum.Equity]: AccountTypeEnum.Equity,
    [SectionNameEnum.Accounts_Payable]: AccountTypeEnum.Accounts_Payable,
    [SectionNameEnum.Accounts_Receivable]: AccountTypeEnum.Accounts_Receivable
}

export interface SectionItemData {
    children: string[];
    total: IaccountBalanceArray
}