import { DataGridPro, GRID_TREE_DATA_GROUPING_FIELD } from "@mui/x-data-grid-pro";
import React from "react";
import { getRowHeight } from "./utils";
import NoTableData from "../../../../../Common/NoTableData";
import ContextMenuMUIDataGrid from "../../../../../Common/ContextMenuMUIDataGrid";
import { CircularProgress } from "@mui/material";
import ReportLoading from "../../../../../Common/ReportLoading";
import TransactionDetail from "../TransactionDetail/TransactionDetail";

const Presentation = ({
  isModalOpen,
  selectedTransaction,
  classes,
  getTreeDataPath,
  groupingColDef,
  headCells,
  dataRows,
  zoomLevel,
  selectCellForComment,
  apiRef,
  onSetDrilledCellParams,
  handleContextMenu,
  contextMenu,
  setContextMenu,
  createCommentMutation,
  dataStatus,
  loadingMessage,
}) => {

  return (
    <div className={isModalOpen ? "tableContainer__ReportModule": "tableContainer__ReportModule__Extend"}>
      <div style={{ display: "flex", height: isModalOpen ? "calc(100vh - 340px)" : "100%" }}>
        <div style={{ flexGrow: 1, height: isModalOpen ? "calc(100vh - 570px)" : "100%" }} className={classes.root}>
          {selectedTransaction ? (
            <TransactionDetail transaction_type={selectedTransaction?.["transaction_type"]} transaction_id={selectedTransaction?.["transaction_id"]} />
          ) : dataRows === undefined ? (
            <ReportLoading loaderValue={dataStatus ? Number((Number(dataStatus) * 100).toFixed(0)) : 0} reportStatus={loadingMessage} />
          ) : (
            <>
              <DataGridPro
                treeData
                getTreeDataPath={getTreeDataPath}
                groupingColDef={groupingColDef}
                columns={headCells}
                rows={dataRows}
                rowHeight={getRowHeight(zoomLevel)}
                pinnedColumns={{
                  left: [GRID_TREE_DATA_GROUPING_FIELD],
                }}
                // getRowId={params => params.row?.gid}
                getRowClassName={(params) => {
                  return `DataGrid-marked-row--${params.row.metaType}`;
                }}
                // disableSelectionOnClick
                disableColumnFilter
                disableChildrenSorting
                showColumnRightBorder
                defaultGroupingExpansionDepth={-1}
                components={{
                  NoRowsOverlay: NoTableData,
                }}
                onCellClick={(params, event, details) => selectCellForComment(params, event, details)}
                onCellDoubleClick={(params) => onSetDrilledCellParams(params)}
                apiRef={apiRef}
                componentsProps={{
                  row: {
                    onContextMenu: handleContextMenu,
                    style: { cursor: "context-menu" },
                  },
                }}
              />
              <ContextMenuMUIDataGrid contextMenu={contextMenu} setContextMenu={setContextMenu} handleCreateComment={createCommentMutation} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Presentation;
