import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { memo } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import MDIcons from "../MDIcons";
import { asanaTaskDateStringToDate, dateObjToAppString } from "../../../utils/DateFunctions";
import TableSortLabel from "@mui/material/TableSortLabel";
import { DataGrid, GridColDef, GridValueGetterParams, GridRowParams } from "@mui/x-data-grid";
import { DataGridPro, GRID_DATE_COL_DEF, MuiEvent } from "@mui/x-data-grid-pro";
import HideAllButton from "../../HeadCountModule/TableComponent/HideAllButton";
import "./style.css";
import NoTableData from "../NoTableData";
import SharepointLoadingOverlay from "./SharepointLoadingOverlay";

const ItemsList = ({ list, drillDown, selected, setSelected, openFile, renameValue, setRenameValue, renameItemOnEnter, renameItemOnBlur }) => {

  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        "& .MuiDataGrid-columnHeaderTitle": {
          "& ::first-letter": {
            textTransform: "capitalize",
          },
          border: "none",
        },
        "& .MuiDataGrid-root": {
          border: "none",
          "& .MuiDataGrid-footerContainer": {
            display: "none",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            // fontWeight: "bold",
            fontSize: "12px",
            lineHeight: "16px",
            color: "#666687",
          },
          "& .MuiDataGrid-iconSeparator": {
            height: "none",
            borderLeft: "none",
            display: "none",
          },
          "& .MuiDataGrid-columnHeader--sortable": {
            "& .MuiDataGrid-iconButtonContainer": {
              visibility: "visible",
              width: "auto",
            },
          },
          "& .MuiDataGrid-columnHeaders": {
            height: "37px",
            minHeight: "37px !important",
            background: "#fff",
            borderBottom: "1px solid #EAEAEF",
          },
          "& .MuiDataGrid-virtualScroller": {
            marginTop: "37px !important",
          },
          "& .MuiDataGrid-columnHeader": {
            height: "37px",
            borderRight: "none",
            "& .MuiDataGrid-columnHeaderTitleContainer": {
              flexDirection: "row",
            },
            "& .MuiDataGrid-columnHeaderDraggableContainer": {
              flexDirection: "row",
              "& .MuiDataGrid-menuIcon": {
                visibility: "visible",
                marginRight: "2px",
                width: "auto !important",
              },
            },
          },
          "& .MuiDataGrid-cell": {
            "& :focus": {
              outline: "none",
            },
            "& :focus-wthin": {
              outline: "none",
            },
            "& span": {
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
          },
        },
        "& .MuiDataGrid-row": {
          backgroundColor: "#fff",
          "& :hover": {
            cursor: "pointer",
          },
          "&.Mui-selected": {
            backgroundColor: "#85b4401A !important",
          },
        },
        "& .MuiDataGrid-cell": {
          color: "#212134",
          fontSize: 14,
          borderBottom: "1px solid #EAEAEF",
          borderRight: "none",
          justifyContent: "flex-start",
          "& :focus": {
            outline: "solid #85b4401A 1px",
          },
          "& :focus-wthin": {
            outline: "#85b4401A",
          },
          "& span": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          },
        },
        "& .MuiCheckbox-root": {
          color: "#C0C0CF",
        },
        "& .MuiCheckbox-root >svg": {
          border: "0px solid #FFFFFF",
          height: "20px",
          width: "20px",
        },
        "& .MuiCheckbox-root.Mui-checked": {
          color: "#85b440 !important",
        },
        "& .DataGrid-marked-row--header": {
          height: "41px !important",
          maxHeight: "41px !important",
          "& .MuiDataGrid-cell": {
            height: "41px !important",
            maxHeight: "41px !important",
            borderRight: "none",
          },
        },
      },
    })
  );

  const classes = useStyles();

  //   {folderChildren.map((folderItem) => {
  //     return <Item item={folderItem} drillDown={drillDown} setSelected={setSelected} selectedID={selected?.id} openFile={openFile} />;
  //   })}
  const columns: GridColDef[] = [
    {
      field: "Name",
      headerName: "NAME",
      width: 600,
      flex: 2,
      valueGetter: (params: GridValueGetterParams) => params.row["name"],
      renderCell(params) {
        return (
          <div className="SharepointExplorer__Item"  onKeyDown={e => e.stopPropagation()}
          onFocus={e => e.stopPropagation()}
          onMouseOver={e => e.stopPropagation()}>
            {params.row.folder ? <MDIcons title="Explorer_folder_small" /> : <MDIcons title="Explorer_file_small" />}
            {(renameValue && selected["id"] === params.row["id"]) || params.row.new ? (
              <input
              autoFocus
                placeholder="Insert folder name ..."
                value={renameValue}
                onChange={(e) => setRenameValue(e.target.value)}
                onKeyDown={(e) => renameItemOnEnter(params.row.id, e)}
                onBlur={(e) => renameItemOnBlur(params.row.id, e)}
              />
            ) : (
              params.row["name"]
            )}
          </div>
        );
      },
    },
    {
      ...GRID_DATE_COL_DEF,
      field: "LAST_MODIFIED",
      headerName: "LAST MODIFIED",
      width: 110,
      editable: false,
      // flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        
        return (dateObjToAppString(asanaTaskDateStringToDate(params.row["lastModifiedDateTime"].split("T")[0]), "/"))},
    },
    {
      field: "MODIFIED_BY",
      headerName: "MODIFIED BY",
      width: 100,
      editable: false,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row["lastModifiedBy"]["user"]["displayName"],
    },
  ];

  return (
    <Box sx={{ height: 400, width: "100%" }} className={classes.root}>
      <DataGridPro
        rowHeight={41}
        rows={list}
        columns={columns}
        localeText={{
          columnMenuSortAsc: "Sort A to Z ↑",
          columnMenuSortDesc: "Sort Z to A ↓",
          columnsPanelHideAllButton: <HideAllButton />,
        }}
        hideFooterPagination
        onRowDoubleClick={(params: GridRowParams, event: MuiEvent<React.SyntheticEvent<Element, Event>>) => {
          return params.row.folder ? drillDown(params.row) : openFile(params.row);
        }}
        onRowClick={(params: GridRowParams, event: MuiEvent<React.SyntheticEvent<Element, Event>>) => {
          return setSelected(params.row);
        }}
        components={{
          NoRowsOverlay: SharepointLoadingOverlay,
      }}
      />
    </Box>
  );
};

export default ItemsList;
