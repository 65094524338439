interface ClearButtonProps {
    isSectionOpen: boolean;
    resetData: (boolean) => void;
}
export default function ClearButton(props: ClearButtonProps) {
    const { resetData, isSectionOpen } = props;
    return (
        <button className={`btn--dashboard_clear ${isSectionOpen ? "section_opened" : ""}`} onClick={() => { resetData(false) }}>
            Clear
            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 0.805714L7.19429 0L4 3.19429L0.805714 0L0 0.805714L3.19429 4L0 7.19429L0.805714 8L4 4.80571L7.19429 8L8 7.19429L4.80571 4L8 0.805714Z" fill="#212134" />
            </svg>
        </button>
    )
}