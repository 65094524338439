import { useEffect, useRef } from "react";

export function useInterval(callback: () => void, delay: number, running: boolean) {
    const savedCallback = useRef<() => void>();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        if (running) {
            var id = setInterval(() => {
                if (savedCallback.current) {
                    savedCallback.current();
                }
            }, delay);
            return () => clearInterval(id);
        } else {
            return () => { }
        }
    }, [running, delay]);
}