import { TableComment } from "../../../QBOReport/Types/Comment";

export const  transactionsDataGridRow = (line, transaction_type, cellComments, transactionID, commentsTabIndex) => {
  let gridRowObj;
  const highlightFieldsArray = TableComment.getHighlightFieldArrayForTransactionLine(cellComments?.data,transactionID, line, commentsTabIndex )
  if ("Id" in line === false) return null;

  gridRowObj = { highlight_field: highlightFieldsArray, transaction_type: transaction_type, transaction_id: transactionID };

  if (transaction_type === "Sales Receipt" || transaction_type === "Invoice" || transaction_type === "Credit Memo" || transaction_type === "Refund") {
    
    gridRowObj = {
      ...gridRowObj,
      id: line?.["Id"].toString(),
      product_service: line?.["SalesItemLineDetail"]?.["ItemRef"]?.["name"],
      description: line?.["Description"],
      qty: line?.["SalesItemLineDetail"]?.["Qty"],
      rate: line?.["SalesItemLineDetail"]?.["UnitPrice"],
      amount: line?.["Amount"],
    };
  }
  if (transaction_type === "Journal Entry") {
    gridRowObj = {
      ...gridRowObj,
      id: line?.["Id"],
      account: line?.["JournalEntryLineDetail"]?.["AccountRef"]?.["name"],
      debits: line?.["JournalEntryLineDetail"]["PostingType"] === "Debit" ? line?.["Amount"] : "",
      credits: line?.["JournalEntryLineDetail"]["PostingType"] === "Credit" ? line?.["Amount"] : "",
      name: line?.["JournalEntryLineDetail"]?.["Entity"]?.["EntityRef"]?.["name"] || "",
    };
  }

  if (transaction_type === "Deposit") {
    
    gridRowObj = {
      ...gridRowObj,
      id: line?.["Id"],
      received_from: line?.[line?.["DetailType"]]?.["Entity"]?.["name"],
      account: line?.[line?.["DetailType"]]["AccountRef"]?.["name"],
      description: line?.["Description"],
      amount: line?.["Amount"],
      customer: line?.[line?.["DetailType"]]?.["Entity"]?.["type"]==="CUSTOMER" ?line?.[line?.["DetailType"]]?.["Entity"]?.["name"] : ""
    };
  }
  if (transaction_type === "Expense" || transaction_type === "Check") {
    
    gridRowObj = {
      ...gridRowObj,
      id: line?.["Id"],
      category: line?.[line?.["DetailType"]]?.["AccountRef"]?.["name"],
      description: line?.["Description"],
      amount: line?.["Amount"],
      customer: line?.[line?.["DetailType"]]?.["CustomerRef"]?.["name"] || ""
    };
  }
  if (transaction_type === "Bill") {
    
    gridRowObj = {
      ...gridRowObj,
      id: line?.["Id"],
      category: line?.[line?.["DetailType"]]?.["AccountRef"]?.["name"],
      description: line?.["Description"],
      amount: line?.["Amount"],
      customer: line?.[line?.["DetailType"]]?.["CustomerRef"]?.["name"] || ""
    };
  }

  return gridRowObj;
};
