import { Fragment, MouseEvent, useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { AppModuleUrl, CompanyModule, ModuleConverter } from "../../types/CompanyModules";
import Icons from "./Icons";
import "./sideBar.css";
import { ReactComponent as MightyDigitsLogo } from "../../static/img/sidebar-images/mightydigits-logo-light.svg";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { Logout } from "@mui/icons-material";
import { logoutUser } from "../../APIFunctions/auth";
import { UserProfile } from "../../types/Auth";
import { createImageFromInitials, getRandomColor } from "../../utils/helpers";
import { Company } from "../../types/Company";
import defaultUser from "../../static/img/user.svg";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import AccountMenu from "../AccountMenu";
import { UserRole } from "../../types/ManageUsers";
import ReactTooltip from "react-tooltip";
import MDIcons from "../Common/MDIcons";
import SideBarMenu from "../Common/DropDownComponent/SideBarMenu";

export default function SideBar({ collapsed, toggleCollapse }) {
  const [FPAFlag, setFPAFlag] = useState<boolean>(true);
  const [FPAModulesURLs, setFPAModulesURLs] = useState<AppModuleUrl[] | undefined>();
  const [AccountingFlag, setAccountingFlag] = useState<boolean>(true);
  const [accountingModulesURLs, setAccountingModulesURLs] = useState<AppModuleUrl[] | undefined>();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const { userProfile, setLocalToken, setUserProfile, setUserSelectedCompany, selectedCompany, token } = useContext(AuthContext);
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<{ [x: string]: null | HTMLElement }>({
    account: null,
  });
  const navigate = useNavigate();
  const onUserLogout = () => {
    logoutUser(token);
    localStorage.setItem("UserProfile", "{}");
    localStorage.setItem("SelectedCompany", "{}");
    setLocalToken("");
    setUserProfile(new UserProfile());
    setUserSelectedCompany(new Company());
  };

  const handleClick = (event: MouseEvent<HTMLElement>, itemKey: string) => {
    setAnchorEl({ [itemKey]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl({ account: null });
  };

  useEffect(() => {
    setFPAModulesURLs(selectedCompany?.getFPAModuleUrls());
    setAccountingModulesURLs(selectedCompany?.getAccountingModuleUrls());
  }, []);

  return (
    <>
      <div className="side-nav">
        <div className="side-nav__header">
          <div className="sidebar-toggle-btn" onClick={toggleCollapse}>
            {collapsed ? (
              <MenuIcon fontSize="large" />
            ) : (
              <div className="side-nav__header--expanded">
                <MenuOpenIcon fontSize="large" />
                <MightyDigitsLogo className="nav-header-logo" />
              </div>
            )}
          </div>
        </div>
        <div className="side-nav__listContainer">
          <AccountMenu />
          <Divider variant="middle" sx={{ width: "100%", borderColor: "#EAEAEF", marginBlock: 0, marginInline: 0 }} />
          {collapsed ? (
            <SideBarMenu list={FPAModulesURLs} type={"FPA"} />
          ) : (
            <div onClick={() => setFPAFlag(!FPAFlag)} className={FPAFlag ? "side-nav__SectionHeader ActiveHeader" : "side-nav__SectionHeader"}>
              <div>FP&A</div>
              {FPAFlag ? <MDIcons title="arrow_up__small" /> : <MDIcons title="down_arrow__small" />}
            </div>
          )}

          {FPAFlag &&
            !collapsed &&
            FPAModulesURLs?.map((module_url, index) => {
              return (
                <div data-tip={collapsed ? ModuleConverter.UrlToName(module_url) : ""} key={module_url} onClick={() => navigate(`/${selectedCompany?.getURL()}/${module_url}`)}>
                  <Icons iconName={ModuleConverter.UrlToName(module_url)} pathname={location.pathname} url={`/${selectedCompany?.getURL()}/${module_url}`} />
                  {!collapsed && (
                    <span className={location.pathname === `/${selectedCompany?.getURL()}/${module_url}` ? " active" : ""}>{ModuleConverter.UrlToName(module_url)}</span>
                  )}
                </div>
              );
            })}

          {<Divider variant="middle" sx={{ width: "100%", borderColor: "#EAEAEF", marginBlock: 0, marginInline: 0 }} />}
          {collapsed ? (
            <SideBarMenu list={accountingModulesURLs} type={"Accounting"} />
          ) : (
            <div onClick={() => setAccountingFlag(!AccountingFlag)} className={AccountingFlag ? "side-nav__SectionHeader ActiveHeader" : "side-nav__SectionHeader"}>
              <div>Accounting</div>
              {AccountingFlag ? <MDIcons title="arrow_up__small" /> : <MDIcons title="down_arrow__small" />}
            </div>
          )}

          {AccountingFlag &&
            !collapsed &&
            accountingModulesURLs?.map((module_url, index) => {
              return (
                <div
                  data-tip={collapsed ? ModuleConverter.UrlToName(module_url) : ""}
                  key={module_url}
                  onClick={() => navigate(`/${selectedCompany?.getURL()}/${module_url}`)}
                  className={location.pathname === `/${selectedCompany?.getURL()}/${module_url}` ? " active" : ""}
                >
                  <Icons iconName={ModuleConverter.UrlToName(module_url)} pathname={location.pathname} url={`/${selectedCompany?.getURL()}/${module_url}`} />
                  {!collapsed && (
                    <span className={location.pathname === `/${selectedCompany?.getURL()}/${module_url}` ? " active" : ""}>{ModuleConverter.UrlToName(module_url)}</span>
                  )}
                </div>
              );
            })}
          <Divider variant="middle" sx={{ width: "100%", borderColor: "#EAEAEF", marginBlock: 0, marginInline: 0 }} />
          {selectedCompany?.getOthersModuleUrls().map((module_url, index) => {
            return (
              <>
                <div
                  data-tip={collapsed ? ModuleConverter.UrlToName(module_url) : ""}
                  data-test={collapsed ? ModuleConverter.UrlToName(module_url) : ""}
                  key={module_url}
                  onClick={() => navigate(`/${selectedCompany?.getURL()}/${module_url}`)}
                  className={location.pathname === `/${selectedCompany?.getURL()}/${module_url}` ? " active" : ""}
                >
                  <Icons iconName={ModuleConverter.UrlToName(module_url)} pathname={location.pathname} url={`/${selectedCompany?.getURL()}/${module_url}`} />
                  {!collapsed && (
                    <span className={location.pathname === `/${selectedCompany?.getURL()}/${module_url}` ? " active" : ""}>{ModuleConverter.UrlToName(module_url)}</span>
                  )}
                </div>
              </>
            );
          })}

          {(selectedCompany?.companyName === "Mighty Digits Admin") && (
            <>
              <div
                data-tip={collapsed ? "Asana Automations" : ""}
                onClick={() => navigate(`/${selectedCompany?.getURL()}/asana-automations`)}
                className={location.pathname === `/${selectedCompany?.getURL()}/asana-automations` ? " active" : ""}
              >
                <Icons iconName="Asana Automations" pathname={location.pathname} url={`/${selectedCompany?.getURL()}/asana-automations`} />
                {!collapsed && <span className={location.pathname === `/${selectedCompany?.getURL()}/asana-automations` ? " active" : ""}>Asana Automations</span>}
              </div>
            </>
          )}

          {(userProfile.is_superuser || selectedCompany?.role !== UserRole.User) && !selectedCompany?.is_Onboarding && (
            <>
              <div
                data-tip={collapsed ? "Settings" : ""}
                onClick={() => navigate(`/${selectedCompany?.getURL()}/settings`)}
                className={location.pathname === "/settings" ? " active" : ""}
              >
                <Icons iconName="Settings" pathname={location.pathname} url="/settings" />
                {!collapsed && <span className={location.pathname === "/settings" ? " active" : ""}>Settings</span>}
              </div>
            </>
          )}
        </div>
        <div className="side-nav__footer">
          <IconButton data-tip="Account settings" onClick={(e) => handleClick(e, "account")} size="small" disableRipple disableTouchRipple disableFocusRipple>
            <Avatar>
              <img className="profile-icon" src={userProfile ? createImageFromInitials(50, userProfile?.getInitials()[0], getRandomColor(userProfile?.id)) : defaultUser} alt="" />
            </Avatar>
            {!collapsed ? (
              <span>
                {" "}
                {userProfile.first_name} {userProfile.last_name}
              </span>
            ) : (
              ``
            )}
          </IconButton>

          <Menu
            anchorEl={anchorEl["account"]}
            open={Boolean(anchorEl["account"])}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem sx={{ height: 24 }} onClick={onUserLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </div>
      </div>
    </>
  );
}
