import { createContext, useCallback, useEffect, useState } from "react";
import { getUserProfile } from "../APIFunctions/auth";
import { createCompanyProp } from "../APIFunctions/filters";
import { getHeadCountListFilters } from "../APIFunctions/headcouts";
import APIManger from "../APIManger";
import { CompanyCreateProp } from "../types/API/filtersData";
import { UserProfile } from "../types/Auth";
import { Company } from "../types/Company";
import { CompanyFilters, IFilterOptionItem } from "../types/CompanyFilters";
import { useNavigate,useParams, useLocation } from "react-router-dom";
// import { matchPath, generatePath } from "react-router"
import { useMatch } from "react-router-dom";
import { getIsConnected } from "../APIFunctions/qbo_report";
export interface AuthContextProps {
  token: string;
  isProfileLoading: boolean;
  userProfile: UserProfile;
  selectedCompany: Company | null;
  setSelectedCompany: (selectedCompany: Company | null) => void;
  companyFilter: CompanyFilters;
  setUserProfile: (userProfile: UserProfile) => void;
  isLoggedin: () => boolean;
  isProfileCompleted: () => boolean;
  setIsProfileLoading: (loading: boolean) => void;
  setLocalToken: (token: string) => void;
  setUserSelectedCompany: (company: Company | null) => void;
  updateFiltersAfterAppend: (itemKey: string, value: string) => Promise<IFilterOptionItem | null>;
  setCompanyFilter: (companyFilters: CompanyFilters) => void;
  fetchUserProfile: (refreshProfile?: boolean) => void;
  targetURL: string|undefined|null;
  setTargetURL:(url:string|null) => void;

}

export const AuthContext = createContext<AuthContextProps>(null as any);

export const AuthContextProvider = (props) => {
  let match = useMatch("/:company_name/*");
  let adminProjectsMatch = useMatch("/:company_name/tasks/:tasks_category?");

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const params = useParams();
  const [token, setToken] = useState<string>(localStorage.getItem("Token") || "");
  const [isProfileLoading, setIsProfileLoading] = useState(true);
  const [userProfile, setUserProfile] = useState<UserProfile>(UserProfile.getLocalStorageObj());
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(new Company());
  const [companyFilter, setCompanyFilter] = useState<CompanyFilters>(new CompanyFilters());
  const [targetURL, setTargetURL] = useState<string|undefined|null>()

  if (userProfile.copyingErrorHappen) {
    userProfile.setLocalObject();
  }
  const isLoggedin = useCallback((): boolean => {
    return token ? true : false;
  }, [token]);
  const isProfileCompleted = (): boolean => {
    return userProfile.completed_profile;
  };
  const setLocalToken = (newToken: string): void => {
    if (newToken !== localStorage.getItem("Token") || "") {
      localStorage.setItem("Token", newToken);
    }
    if (newToken !== token) {
      setToken(newToken);
    }
    APIManger.token = newToken;
  };

  APIManger.logout = () => {
    setLocalToken("");
    setUserProfile(new UserProfile());
    setUserSelectedCompany(null);
  };
  // place the token in the api manager
  APIManger.token = token;
  const fetchUserProfile = async (refreshProfile?: boolean) => {
    setIsProfileLoading(true);
    const result = await getUserProfile();

    if (result.id) {
      const newUserProfile: UserProfile = new UserProfile(result);
      if (newUserProfile.copyingErrorHappen || refreshProfile) newUserProfile.setLocalObject();
      const newUserCompany: Company | null = newUserProfile.getFirstCompany();
      setUserProfile(newUserProfile);
      var old_selectedCompany: Company | null = new Company(JSON.parse(localStorage.getItem("SelectedCompany") || "{}"));
      
      
      if(match?.params["company_name"]){
        
          let companyFoundFlag = false
          
          const companies = userProfile.complete_companies
          for (let i=0;i < companies.length;i++) {
              const companyNameNoSpace = companies[i].companyName?.replaceAll(" ","-")
              
              if (companyNameNoSpace === match?.params["company_name"]) {
                
                  const company = new Company(companies[i]);
                  
                  setUserSelectedCompany(company)
                  companyFoundFlag = true
                  break;
              }
          }
          if (companyFoundFlag === false) {
            const companies = userProfile.onboarding_companies
          for (let i=0;i < companies.length;i++) {
              const companyNameNoSpace = companies[i].companyName?.replaceAll(" ","-")
              
              if (companyNameNoSpace === match?.params["company_name"]) {
                
                  const company = new Company(companies[i]);
                  
                  setUserSelectedCompany(company)
                  companyFoundFlag = true
                  break;
              }
          }
          }
          if (companyFoundFlag === false) {
            setUserSelectedCompany(newUserCompany)
          }
      } else if (adminProjectsMatch?.params["company_name"]){
        

        let companyFoundFlag = false
          
          const companies = userProfile.complete_companies
          for (let i=0;i < companies.length;i++) {
              const companyNameNoSpace = companies[i].companyName?.replaceAll(" ","-")
              
              if (companyNameNoSpace === adminProjectsMatch?.params["company_name"]) {
                
                  const company = new Company(companies[i]);
                  
                  setUserSelectedCompany(company)
                  companyFoundFlag = true
                  break;
              }
          }
          if (companyFoundFlag === false) {
            setUserSelectedCompany(newUserCompany)
          }
        
        
      } else if (newUserProfile.companies.length > 0) {
        if (!old_selectedCompany) {
          
          setUserSelectedCompany(newUserCompany);
        } else {
          
          const selectedCompnayUpdates = newUserProfile.companies.find((item: Company) => item.id === old_selectedCompany?.id);
          if (selectedCompnayUpdates) {
            
            setUserSelectedCompany(selectedCompnayUpdates);
          } else {
            
            setUserSelectedCompany(newUserCompany);
          }
        }
      } else {
        setUserSelectedCompany(null);
      }
    }
  };

  useEffect(() => {
    if (isLoggedin()) {
      fetchUserProfile(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedin]);

  const setUserSelectedCompany = async (company: Company | null) => {
    
    setIsProfileLoading(true);
    company = new Company(company);
    if (company !== selectedCompany) {
      
      localStorage.setItem("SelectedCompany", JSON.stringify(company));
      if (company && !company.is_Onboarding) {
        var response = await getHeadCountListFilters(company);
        setCompanyFilter(response);
      }
      
      
      if (company.is_Onboarding) navigate(`/${company.getURL()}/onboarding`);
      if (!company.is_Onboarding) {
        const is_connected = await getIsConnected(company?.id)
        company.is_connected = is_connected['response']
      }
      setSelectedCompany(company);
      setIsProfileLoading(false);
    }

    function updatePath(company_name) {
      if (match) {
        // generatePath(pathPattern, { ...params, itemId })
        // const path = generatePath(pathname, { ...params,company_name:company_name });
        const path = `/${company_name}`
        navigate(path);
        return
      }
    }

    // match?.params["company_name"]
    
    if(match?.params["company_name"]) {
      if (match?.params["company_name"] !== company.getURL() &&match?.params["company_name"] !== "" && !company.is_Onboarding) {
        updatePath(company.getURL())
      }
    }
  };
  async function updateFiltersAfterAppend(prop_name: string, name: string): Promise<IFilterOptionItem | null> {
    if (selectedCompany) {
      const data: CompanyCreateProp = { prop_name, name };
      const newFilter = await createCompanyProp(selectedCompany, data);
      if (newFilter && newFilter.id && newFilter.name) {
        var response = await getHeadCountListFilters(selectedCompany);
        setCompanyFilter(response);
        return { value: newFilter.id.toString(), label: newFilter.name };
      }
      return null;
    }
    return null;
  }

  return (
    <AuthContext.Provider
      value={{
        token,
        isProfileLoading,
        userProfile,
        selectedCompany,
        setSelectedCompany,
        companyFilter,
        setUserProfile,
        isLoggedin,
        isProfileCompleted,
        setIsProfileLoading,
        setLocalToken,
        setUserSelectedCompany,
        updateFiltersAfterAppend,
        setCompanyFilter,
        fetchUserProfile,
        targetURL, setTargetURL
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
