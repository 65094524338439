import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';

export default function useRenderFilters(params: GridRenderCellParams) {
    const renderValue = params.value;
    return (
        <Box
            component="span"
            sx={{
                fontSize: "12px",
            }}>
            {renderValue}
        </Box>
    )
}