import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { getHeadCountEmployeeList } from "../../APIFunctions/headcouts";
import { useInterval } from "../../hooks";
import { HeadCountInformation, HeadCountInformationArray } from "../../types/HeadCount";
import { LoaderStatus } from "../../types/Loaders";
import { showToast } from "../../utils/toastHelpers";
import { AuthContext } from "../AuthContext";
import { SocketContext } from "../SocketContext";

export interface DataConextProps {
    hc_LoaderStatus: LoaderStatus;
    setLoaderStatus: (status: LoaderStatus) => void;

    hc_LoaderValue: number;
    setLoaderValue: (status: number) => void;

    headCountData: HeadCountInformation[];

    headCountDisplay: HeadCountInformationArray;
    searchText: string;
    setSearchText: (searchText: string) => void;
    showToastThenRefresh: (message: string, markedCount?: number) => void;
    loadEntireData: () => Promise<void>;
}

export const DataConext = createContext<DataConextProps>(null as any);

export const DataConextProvider = (props) => {
    const { selectedCompany,companyFilter } = useContext(AuthContext)
    const { setRefreshHeadCountFunction } = useContext(SocketContext)
    const [hc_LoaderStatus, setLoaderStatus] = useState<LoaderStatus>(LoaderStatus.Loading);
    const [hc_LoaderValue, setLoaderValue] = useState<number>(1);
    const [headCountDisplay, setheadCountDisplay] = useState<HeadCountInformationArray>(new HeadCountInformationArray())
    const [headCountData, setheadCountData] = useState<HeadCountInformation[]>([])
    const [searchText, setSearchText] = useState<string>('');

    useInterval(() => {
        const currentLoaderValue = hc_LoaderValue + Math.floor((Math.random() * 2) + 1);
        switch (hc_LoaderStatus) {
            case LoaderStatus.Loading:
                if (currentLoaderValue < 30)
                    setLoaderValue(30)
                else if (currentLoaderValue > 90)
                    setLoaderValue(90)
                else
                    setLoaderValue(currentLoaderValue)
                break;
            case LoaderStatus.Loaded:
                if (currentLoaderValue < 90)
                    setLoaderValue(90)
                else if (currentLoaderValue > 95)
                    setLoaderValue(95)
                else
                    setLoaderValue(currentLoaderValue)
                break;
            case LoaderStatus.GeneratingReports:
                if (currentLoaderValue < 95)
                    setLoaderValue(95)
                else if (currentLoaderValue > 100)
                    setLoaderValue(100)
                else
                    setLoaderValue(currentLoaderValue)
                break;
            default:
                break;
        }
    }, 250, hc_LoaderStatus !== LoaderStatus.Rendered);

    useEffect(() => {
        // This effect is just to assign the headcount data loader function which located in the Socket context
        // That gives us the ability to reload the headcount list from the Socket context when a notification come. 
        setRefreshHeadCountFunction(() => showToastThenRefresh);
    }, [])




    /**
     * Fetch all app filters.
     */
    const loadEntireData = useCallback(async (markedCount?: number) => {
        if (selectedCompany) {
            setLoaderValue(1)
            setLoaderStatus(LoaderStatus.Loading);
            const headcount_employe_list = await getHeadCountEmployeeList(selectedCompany, "")

            setLoaderStatus(LoaderStatus.Loaded);

            HeadCountInformation.company_filter = companyFilter

            var newObjects: HeadCountInformation[] = []
            var newDataGridHeadCountData: HeadCountInformationArray = new HeadCountInformationArray();
            setLoaderStatus(LoaderStatus.GeneratingReports);
            headcount_employe_list.forEach((item, index) => {
                newObjects.push(new HeadCountInformation(selectedCompany, item))
                var headcountPresentationObj: HeadCountInformation = toTablePresentationObject(item, index <= (markedCount ?? 0) - 1)
                if (headcountPresentationObj.isMatching(searchText))
                    newDataGridHeadCountData.add(headcountPresentationObj);
            })
            setheadCountDisplay(newDataGridHeadCountData);
            setheadCountData(newObjects);
            setTimeout(() => {
                if (markedCount) {
                    setTimeout(() => {
                        newDataGridHeadCountData.items = newDataGridHeadCountData.items.map((item) => {
                            item.new = false
                            return item
                        })
                        setheadCountDisplay(newDataGridHeadCountData);
                    }, 3000);
                }
            });
        }
    }, [selectedCompany,companyFilter, searchText])

    useEffect(() => {
        loadEntireData()
    }, [selectedCompany])

    useEffect(() => {
        var newDataGridHeadCountData: HeadCountInformationArray = new HeadCountInformationArray();
        headCountData.forEach((item) => {
            var headcountPresentationObj: HeadCountInformation = toTablePresentationObject(item, - 1)
            if (headcountPresentationObj.isMatching(searchText))
                newDataGridHeadCountData.add(headcountPresentationObj);
        })
        setheadCountDisplay(newDataGridHeadCountData);
    }, [searchText])


    const toTablePresentationObject = useCallback((obj: HeadCountInformation, is_new = false): HeadCountInformation => {
        var presentation_object = new HeadCountInformation(selectedCompany, obj)
        presentation_object.new = is_new
        return presentation_object
    }, [selectedCompany])


    const showToastThenRefresh = (message: string, markedCount?: number) => {
        showToast('success', message, 'Success');
        loadEntireData(markedCount ?? 0);
    }


    return (
        <DataConext.Provider value={{
            hc_LoaderStatus,
            setLoaderStatus,

            hc_LoaderValue,
            setLoaderValue,

            headCountData,

            headCountDisplay,
            searchText,
            setSearchText,
            showToastThenRefresh,

            loadEntireData
        }}>
            {props.children}
        </DataConext.Provider>
    )
}

