import React from 'react'

import Excel__Icon from "../../img/Excel__Icon.svg";
import Folder__Icon from "../../img/Folder__Icon.svg";
import BasicMenu from '../../../Common/DropDownComponent/TaskMoreActions';
import AddIcon from "@mui/icons-material/Add";

const MilestoneLinkButton = ({link,selectedFile, areActionsAllowed,clearLink,onOpenBrowser, showAddButton }) => {
  return (
    <div>
        {link?.sharepoint_file_info?.web_url || selectedFile ? (
        <div className="PayrollLink__Container">
          <img src={Excel__Icon} alt="" />
          <a href={link?.sharepoint_file_info?.web_url} rel="noreferrer" target="_blank">
            <div>{link?.name}</div>
          </a>
          {areActionsAllowed && <BasicMenu deleteItem={clearLink} editItem={onOpenBrowser} type="link" />}
        </div>
      ) : (
        showAddButton && (
          <button id="" className="btn--tertiary btn--S success" onClick={onOpenBrowser}>
            <AddIcon sx={{ path: { fill: "#32324D !important" } }} />
            <div>Add {link?.name} Link</div>
          </button>
        )
      )}
    </div>
  )
}

export default MilestoneLinkButton