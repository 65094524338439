import { useContext, useState } from "react";

import "./style.css";
import EmailChip from "./EmailChip";

const EmailInput = (props) => {
  const [newInput, setNewInput] = useState<string>("");

  const removeTags = (indexToRemove) => {
    props.setTags([...props.tags.filter((_, index) => index !== indexToRemove)]);
  };
  const addTags = (event) => {
    const inputValue = event.target.value;
    if (inputValue !== "") {
      const newTags = props.array ? [...props.tags, inputValue] : [inputValue];
      props.setTags(newTags);
      setNewInput("");
  }
  };
  return (
    <div className="tags-input" data-test="email-chip-container">
      <ul id="tags" data-test="tags">
        {props.tags?.map((tag, index) =>
          props.array ? (
            <EmailChip
              key={index}
              removeTags={removeTags}
              tag={tag}
              index={index}
              allowedEmails={props.allowedEmailsSharepoint}
              setAllowedEmails={props.setAllowedEmailsSharepoint}
              allowedEmailsApp={props.allowedEmailsApp}
              allowedEmailsAsana={props.allowedEmailsAsana}
            />
          ) : (
            <EmailChip
              key={index}
              removeTags={removeTags}
              tag={tag}
              index={index}
              allowedEmails={null}
              setAllowedEmails={null}
              allowedEmailsApp={props.allowedEmailsApp}
              allowedEmailsAsana={props.allowedEmailsAsana}
            />
          )
        )}
      </ul>
      <input
        data-test="email-input"
        type="text"
        onKeyUp={(event) => (event.key === "Enter" ? addTags(event) : null)}
        onChange={(e) => setNewInput(e.target.value)}
        placeholder={props.placeholder}
        value={newInput}
      />
    </div>
  );
};

export default EmailInput;
