import { useContext, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ReactTooltip from 'react-tooltip';
import { DataConext } from '../../../contexts/ManageUsers/DataConext';
import { UserListConext } from '../../../contexts/ManageUsers/UserListConext';
import { LoaderStatus } from '../../../types/Loaders';
import ReportLoading from '../../Common/ReportLoading';
import CompanySettings from '../CompanySettings';
import PaymentPlan from '../PaymentPlan';
import UsersList from '../UsersList';
import './tabs-section.css';
import RollForward from '../RollForward';

export default function SettingsModuleBody() {
    const { settingLoaderStatus, settingLoaderValue } = useContext(DataConext);
    const { tabIndex, setTabIndex } = useContext(UserListConext);

    useEffect(() => {
        ReactTooltip.rebuild();
      }, []);

    return (
        <>
            <Tabs className="tabs-bi" selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
                <TabList>
                    <Tab
                        className="tabs-bi__link settings_tab_link"
                    >
                        Manage users
                    </Tab>
                    <Tab
                        className="tabs-bi__link settings_tab_link"
                        disabledClassName='tabs-bi__link--disabled'
                        selectedClassName='tabs-bi__link--selected'
                    >
                        Payment Plan
                    </Tab>
                    <Tab
                        className="tabs-bi__link settings_tab_link"
                        disabledClassName='tabs-bi__link--disabled'
                        selectedClassName='tabs-bi__link--selected'
                    >
                        Company Settings
                    </Tab>
                    
                    <Tab
                        className="tabs-bi__link settings_tab_link"
                        disabledClassName='tabs-bi__link--disabled'
                        selectedClassName='tabs-bi__link--selected'
                        data-tip="coming soon"
                    >
                        Roll Forward
                    </Tab>
                    <Tab
                        className="tabs-bi__link settings_tab_link"
                        disabled
                        disabledClassName='tabs-bi__link--disabled'
                        selectedClassName='tabs-bi__link--selected'
                        data-tip="coming soon"
                    >
                        My Models
                    </Tab>
                    <Tab
                        className="tabs-bi__link settings_tab_link"
                        disabled
                        disabledClassName='tabs-bi__link--disabled'
                        selectedClassName='tabs-bi__link--selected'
                        data-tip="coming soon"
                    >
                        Integrations
                    </Tab>
                    
                    <Tab
                        className="tabs-bi__link settings_tab_link"
                        disabled
                        disabledClassName='tabs-bi__link--disabled'
                        selectedClassName='tabs-bi__link--selected'
                        data-tip="coming soon"
                    >
                        Account list
                    </Tab>
                </TabList>
                <TabPanel />
                <TabPanel />
                <TabPanel />
                <TabPanel />
                <TabPanel />
                <TabPanel />
                <TabPanel />
            </Tabs>
            {settingLoaderStatus !== LoaderStatus.Rendered && <ReportLoading loaderValue={settingLoaderValue} reportStatus={settingLoaderStatus} />}
            {settingLoaderStatus === LoaderStatus.Rendered &&
                <>
                    {tabIndex === 0 && <UsersList />}
                    {tabIndex === 1 && <PaymentPlan />}
                    {tabIndex === 2 && <CompanySettings />}
                    {tabIndex === 3 && <RollForward />}
                    
                </>
            }
        </>

    )
}