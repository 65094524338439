import { addHighlightingAttribute } from "../../Utils/highlighting";

export const commentHighlightFlag = (rowID, colID, commentsTabIndex, cellComments) => {

  let comments;
  if (rowID && colID) {
    if (commentsTabIndex === 0) {
      comments = cellComments?.filter((x) => x.asana_task.completed === false);
    } else {
      comments = cellComments?.filter((x) => x.asana_task.completed === true);
    }
    const cellCommentExists = comments.filter((x) => decodedID(x.row_id) === decodedID(rowID) && x.column_id === colID);
    if (cellCommentExists?.length > 0) {
      return "HIGHLIGHT";
    } else {
      return false;
    }
  }
};

export const decodedID = (id) => {
  if (id?.includes("-")) {
    const decoded_id = id.split("-")[0]
    return decoded_id
  }
  return id
  
}

export const addFields = (row: string[], headers: string[]) => {
  const dict = {};
  for (let i = 0; i < headers.length; i++) {
      dict[headers[i]] = row[i];
  }
  dict['depth'] = row[row.length - 2]
  dict['bold'] = row[row.length - 1]['bold']
  dict['display_values'] = row[row.length - 1]['display_values']

  return dict;
};

export const getTreeDataStructure = (headers, rows, comments) => {
  let headersWithID = ["id"].concat(headers);
  let dataWithTreePath: any[] = [];
  for (let i = 0; i < rows?.length; i++) {
    
    // const obj = addHighlightingAttribute(addFields(rows[i], headers), comments);
    const obj = addFields(rows[i], headersWithID);
    // assumes the 2nd element is unique, if the first element is empty.
    let obj_id = obj["id"] !== "" ? obj["id"] : rows[i][1];
    obj_id += `-${i}`
    if (i === 0) {
      dataWithTreePath.push({ ...obj, id: obj_id.toString(), hierarchy: [obj_id.toString()] });
    } else {
      if (obj["depth"] > dataWithTreePath[i - 1]["depth"]) {
        dataWithTreePath.push({ ...obj, id: obj_id.toString(), hierarchy: [...dataWithTreePath[i - 1]["hierarchy"], obj_id.toString()] });
      } else if (obj["depth"] === 0) {
        dataWithTreePath.push({ ...obj, id: obj_id.toString(), hierarchy: [obj_id.toString()] });
      } else if (obj["depth"] < dataWithTreePath[i - 1]["depth"]) {
        for (let j = i - 1; j > -1; j--) {
          if (obj["depth"] > dataWithTreePath[j]["depth"]) {
            dataWithTreePath.push({ ...obj, id: obj_id.toString(), hierarchy: [...dataWithTreePath[j]["hierarchy"], obj_id.toString()] });
            break;
          }
          if (j === 0) {
            const parentHierarchy = dataWithTreePath[j]["hierarchy"].slice(0, -1);
            dataWithTreePath.push({ ...obj, id: obj_id.toString(), hierarchy: [...parentHierarchy, obj_id.toString()] });
          }
        }
      } else {
        // remove the last element and add this object's id instead
        const parentHierarchy = dataWithTreePath[i - 1]["hierarchy"].slice(0, -1);
        dataWithTreePath.push({ ...obj, id: obj_id.toString(), hierarchy: [...parentHierarchy, obj_id.toString()] });
      }
    }
  }
  return dataWithTreePath;
};

function commify(n) {
  // var parts = n.toString().split(".");
  const thousands = /\B(?=(\d{3})+(?!\d))/g;

  return n.toString().replace(thousands, ",");
}

export const formatNumber = (value: number, dollarSign: boolean) => {
  if (value < 0) {
    if (dollarSign) {
      return `$(${commify(Math.round(Math.abs(value)))})`;
    } else {
      return `(${commify(Math.round(Math.abs(value)))})`;
    }
  } else if (value > 0) {
    if (dollarSign) {
      return `$${commify(Math.round(value))}`;
    } else {
      return `${commify(Math.round(value))}`;
    }
  } else {
    return "-";
  }
};

export const formatValue = (stringValue: string, dollarSign: boolean) => {
  var isNumber = /^-?\d+\.\d+$/.test(stringValue);
  if (isNumber) {
    return formatNumber(parseFloat(stringValue),dollarSign)
  } else {
    return stringValue
  }
}

export const getColWidth = (zoomLevel) => {
  return zoomLevel === "1"
    ? 40
    : zoomLevel === "2"
    ? 50
    : zoomLevel === "3"
    ? 60
    : zoomLevel === "4"
    ? 70
    : zoomLevel === "5"
    ? 180
    : zoomLevel === "6"
    ? 90
    : zoomLevel === "7"
    ? 100
    : zoomLevel === "8"
    ? 110
    : zoomLevel === "9"
    ? 120
    : 140;
};

export const getWideColWidth = (zoomLevel) => {
  return zoomLevel === "1"
    ? 200
    : zoomLevel === "2"
    ? 220
    : zoomLevel === "3"
    ? 230
    : zoomLevel === "4"
    ? 270
    : zoomLevel === "5"
    ? 290
    : zoomLevel === "6"
    ? 310
    : zoomLevel === "7"
    ? 320
    : zoomLevel === "8"
    ? 330
    : zoomLevel === "9"
    ? 365
    : 400;
};

export const getRowHeight = (zoomLevel) => {
  return zoomLevel === "1"
    ? 14
    : zoomLevel === "2"
    ? 16
    : zoomLevel === "3"
    ? 18
    : zoomLevel === "4"
    ? 20
    : zoomLevel === "5"
    ? 22
    : zoomLevel === "6"
    ? 25
    : zoomLevel === "7"
    ? 26
    : zoomLevel === "8"
    ? 27
    : zoomLevel === "9"
    ? 29
    : 31;
};

