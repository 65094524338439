import { useContext } from 'react';
import { MilestoneEmailContext } from '../../../../contexts/TicketingSystem/MilestoneEmailContext';
import { checkPermission } from '../helpers';

const usePermissions = (tag, allowedEmailsApp, allowedEmailsAsana, allowedEmails) => {
  const { sharepointLinkInEmail } = useContext(MilestoneEmailContext);
  const appPermissionsGranted = checkPermission(allowedEmailsApp, tag);
  const asanaPermissionsGranted = checkPermission(allowedEmailsAsana, tag);
  const permissionGranted = checkPermission(allowedEmails, tag) || !sharepointLinkInEmail?.sharepoint_file_info?.web_url;

  return { appPermissionsGranted, asanaPermissionsGranted, permissionGranted };
};

export default usePermissions;
