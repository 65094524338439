import { useContext, useState } from 'react';
import { AuthContext } from '../../../contexts/AuthContext';
import { completeProfileForm } from '../../../config/invitationConfigs';
import { uppercaseWords } from '../../../utils/helpers';
import { completeProfile } from '../../../APIFunctions/auth';
import { UserProfile } from '../../../types/Auth';
import { CompleteProfileAPI } from '../../../types/API/authData';
import '../Register/Register.css';
import { Company } from '../../../types/Company';

export default function CompleteProfile() {
    const { userProfile, setUserProfile, setUserSelectedCompany } = useContext(AuthContext);
    const initialState = {
        first_name: userProfile.first_name,
        last_name: userProfile.last_name,
    }

    const initialErrors = {
        first_name: false,
        last_name: false,
    }
    const [formState, setFormState] = useState(initialState);
    const [errors, setErrors] = useState(initialErrors);
    const [isLoading, setIsLoading] = useState(false);


    const onDataChange = (e: React.ChangeEvent<HTMLInputElement>, itemKey: string) => {
        setFormState((prev) => ({ ...prev, [itemKey]: e.target.value }))
    }

    const validateUserData = (): boolean => {
        let errors = { ...initialErrors };
        if (formState.first_name === '') errors.first_name = true;
        if (formState.last_name === '') errors.last_name = true;
        setErrors(errors);
        return !Object.values(errors).includes(true);
    }

    const submitcompleteProfile = async () => {
        setIsLoading(true)
        if (validateUserData()) {
            const data: CompleteProfileAPI = {
                first_name: formState.first_name,
                last_name: formState.last_name,
            }
            const result = await completeProfile(data);
            const user: UserProfile = new UserProfile(result);
            const company: Company | null = user.getFirstCompany();
            setFormState((prev) => ({
                ...prev, first_name: user.first_name,
                last_name: user.last_name,
                completed_profile: user.completed_profile
            }));
            
            localStorage.setItem("UserProfile", JSON.stringify(user));
            localStorage.setItem("SelectedCompany", JSON.stringify(company));
            setIsLoading(false);
            setUserProfile(new UserProfile(user));
            setUserSelectedCompany(company);
        } else setIsLoading(false);
    }
    return (
        <>
            <h4>Please complete your information</h4>
            <hr />
            <div className="Registration__Container_Entry_Row">
                {completeProfileForm.map(item => {
                    const itemName = uppercaseWords(item)
                    return (
                        <div key={item} style={{ display: 'inline-flex', justifyContent: 'flex-start', width: '47%', marginTop: '1rem' }}>
                            <div className="Registration__Container__EntryContainer">
                                <label htmlFor={item}>{itemName}</label>
                                <input
                                    type='text'
                                    disabled={isLoading}
                                    name={item}
                                    id={item}
                                    value={formState[item]}
                                    onChange={(e) => onDataChange(e, item)}
                                />
                                {errors[item] && <span style={{ color: 'red', fontWeight: 700 }}>{itemName} is required</span>}
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="Auth__BottomSection">
                <button className="btn--primary btn--L btn--fullWidth" disabled={isLoading} onClick={() => submitcompleteProfile()}>Submit</button>
            </div>
        </>
    )
}