import React, { useContext, useEffect, useState } from "react";
import { updateAsanaTask } from "../../../APIFunctions/Asana";
import { MilestoneContext } from "../../../contexts/TicketingSystem/MilestoneContext";
import MDIcons from "../../Common/MDIcons";
import { SubTasksContext } from "../../../contexts/TicketingSystem/SubTasksContext";

const SubTaskAcceptReject = ({ reviewTask,reviewTaskCompletion, setReviewTask, setPreviousReviewTask, rejectTaskGid, previousReviewTask, milestoneTaskName, milestoneTaskGID, openMilestoneTask }) => {
  const { subTasksAcceptanceArray, setSubTasksAcceptanceArray, setAllowEmailing, setPayrollLoading,payrollLoading } = useContext(MilestoneContext);
  const { addSubTask,openSubTask,setDisplayMilestoneCardContent,subTasksDict } = useContext(SubTasksContext)
  const [rejectionFlag, setRejectionFlag] = useState<boolean>(false);

  const toggleEmailingPermission = (allow: boolean) => {
    if (milestoneTaskName === "Email Draft") {
      setAllowEmailing(allow);
    }
  };

  const updateAcceptanceArray = (state) => {
    const targetObj = subTasksAcceptanceArray.find((x) => x.gid === rejectTaskGid);
    if (targetObj) {
      const updatedObj = { ...targetObj, state };
      const updatedArray = subTasksAcceptanceArray.map((x) => (x.gid === rejectTaskGid ? updatedObj : x));
      setSubTasksAcceptanceArray(updatedArray);
    } else {
      // add a new obj to the array
      const updatedArray = [...subTasksAcceptanceArray];
      updatedArray.push({ gid: rejectTaskGid, state: state });
      setSubTasksAcceptanceArray(updatedArray);
    }
  };

  const onAccept = () => {
    setRejectionFlag(false);
    if (reviewTask?.gid) {
      updateAsanaTask({
        gid: reviewTask.gid,
        completed: true,
      });
      updateAsanaTask({
        gid: rejectTaskGid,
        completed: true,
      });
      setReviewTask({ ...reviewTask, completed: true });
    }
    if (previousReviewTask) {
      updateAsanaTask({
        gid: previousReviewTask["gid"],
        completed: true,
      });
    }
    toggleEmailingPermission(true);
    updateAcceptanceArray("Accepted");
  };

  const onReject = async() => {
    setPayrollLoading(true)
    setRejectionFlag(true);
    updateAcceptanceArray("Rejected");

    updateAsanaTask({
      gid: rejectTaskGid,
      completed: false,
    });
    updateAsanaTask({
      gid: reviewTask.gid,
      completed: false,
    });
    if (previousReviewTask) {
      updateAsanaTask({
        gid: previousReviewTask["gid"],
        completed: false,
      });
    }
    
    if (setPreviousReviewTask) {
      setPreviousReviewTask({ ...previousReviewTask, completed: false });
    }

    setReviewTask({ ...reviewTask, completed: false });
    toggleEmailingPermission(false);

    // const incompleteSubTasks = subTasksDict?.[milestoneTaskGID]?.find(x => x.completed === false)
    if (openMilestoneTask) {
      setDisplayMilestoneCardContent(false)
      openMilestoneTask(rejectTaskGid);
      const newSubTask = await addSubTask(rejectTaskGid)
      openSubTask(newSubTask)
      setDisplayMilestoneCardContent(true)
    }
    setPayrollLoading(false)
    
  };

  useEffect(() => {
    // const taskRejected = subTasksAcceptanceArray.find((x) => x.gid === rejectTaskGid);
    const incompleteSubTasks = subTasksDict?.[milestoneTaskGID]?.find(x => x.completed === false)
    if (incompleteSubTasks) {
      setRejectionFlag(true);
      if (reviewTask) {
        updateAsanaTask({
          gid: reviewTask.gid,
          completed: false,
        });
      }
      
      updateAcceptanceArray("Rejected");
    } else if (reviewTask?.completed) {
      toggleEmailingPermission(true);
      updateAcceptanceArray("Accepted");
    }
  }, [reviewTask, subTasksDict?.[milestoneTaskGID]]);

  return (
    <div className="SubTaskAcceptReject__Container" data-test="SubTaskAcceptReject__Container">
      {reviewTask?.completed ? (
        <button className={"btn--secondary--completed btn--S--square"} disabled={payrollLoading}>
          <MDIcons title="check_mark_for_approval" />
        </button>
      ) : (
        <button id="" className={"btn--tertiary btn--S--square success"} onClick={onAccept} disabled={payrollLoading || !reviewTask}>
          <MDIcons title="check_mark_for_approval" />
        </button>
      )}
      {rejectionFlag ? (
        <button className="btn--secondary-danger btn--S--square" disabled={payrollLoading}>
          <MDIcons title="Cross" />
        </button>
      ) : (
        <button id="danger" className="btn--tertiary btn--S--square" onClick={onReject} disabled={payrollLoading || !reviewTask}>
          <MDIcons title="Cross" />
        </button>
      )}
    </div>
  );
};

export default SubTaskAcceptReject;
