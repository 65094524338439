
import { PAYROLL_EMAIL_ATTACHMENT_CREATE, UPLOAD_FILES } from "./apiConstants"
import { FileUpload__ResponseAPI } from "../types/API/onboardingData"
import APIManger from "../APIManger"
import { EmailAttachmentUpload__ResponseAPI } from "../types/API/EmailData"


export const uploadFile = async (file: any, meta: string,name:string=""): Promise<FileUpload__ResponseAPI> => {
    let url = UPLOAD_FILES
    let apiResult: FileUpload__ResponseAPI = await APIManger.upload(url, file, meta, name)
    return apiResult;
}

export const uploadPayrollEmailAttachment= async (file: any, meta: string,name:string="", email_id:number): Promise<EmailAttachmentUpload__ResponseAPI> => {
    let url = PAYROLL_EMAIL_ATTACHMENT_CREATE
    let apiResult: EmailAttachmentUpload__ResponseAPI = await APIManger.upload(url, file, meta, name,email_id.toString())
    return apiResult;
}