import { createContext, useContext, useEffect, useState } from "react";
import { createAsanaSubTask } from "../../APIFunctions/Asana";
import {
  createEmailNotesHeader,
  createEmailQuestionsHeader,
  createPayrolNotes,
  createPayrolQuestion,
  deletePayrollNote,
  deletePayrollQuestion,
  removeEmailAttachment,
  retrieveTicketingSystemEmail,
  retrievePayrollEmailAttachments,
  retrievePayrollEmailParams,
  retrievePayrollNotesHeadersList,
  retrievePayrollNotesList,
  retrievePayrollQuestionsHeadersList,
  retrieveQuestionsInHeaderList,
  retrievePayrollQuestionsList,
  sendEmail,
  updateTicketingSystemEmail,
  updateNote,
  updateQuestion,
  updateQuestionInHeader,
  updatePayrollQuestionsHeader,
  getMilestoneLinks,
} from "../../APIFunctions/ticketing_system";
import { showToast } from "../../utils/toastHelpers";
import { AuthContext } from "../AuthContext";
import { MilestoneContext } from "./MilestoneContext";
import { PayrollContext } from "./PayrollContext";

export interface MilestoneEmailContextProps {
  email: any | null | undefined;
  getEmailParams: (mileston_type: any) => void;
  questions: any[];
  setQuestions: (questions: any[]) => void;
  questionsHeaders: any[];
  setQuestionsHeaders: (questionsHeaders: any[]) => void;
  notes: any[];
  setNotes: (notes: any[]) => void;
  notesHeaders: any[];
  setNotesHeaders: (notesHeaders: any[]) => void;
  emailReadyToSendFlag: string;
  setEmailReadyToSendFlag: (emailReadyToSendFlag: string) => void;
  emailPreviewFlag: boolean;
  setEmailPreviewFlag: (emailPreviewFlag: boolean) => void;
  cycleDate: any;
  setCycleDate: (cycleDate: any) => void;
  emailGreeting: string;
  setEmailGreeting: (emailGreeting: string) => void;
  richTextEmail: string;
  setRichTextEmail: (richTextEmail: string) => void;
  emailSubject: string;
  setEmailSubject: (richTextEmail: string) => void;
  introToNotes: string;
  setIntroToNotes: (introToNotes: string) => void;
  introToQuestions: string;
  setIntroToQuestions: (introToQuestions: string) => void;
  outro: string;
  setOutro: (outro: string) => void;
  emailSenderSignature: string;
  setEmailSenderSignature: (emailSenderSignature: string) => void;
  recepientsNames: string;
  setRecepientsNames: (recepientsNames: string) => void;
  aboveNotes: string;
  setAboveNotes: (aboveNotes: string) => void;
  aboveQuestions: string;
  setAboveQuestions: (aboveQuestions: string) => void;
  approvalRequiredByNote: string;
  setApprovalRequiredByNote: (approvalRequiredByNote: string) => void;
  secondGreetingsLine: string;
  setSecondGreetingsLine: (secondGreetingsLine: string) => void;
  payrollFileLink: any;
  setPayrollFileLink: (payrollFileLink: any) => void;
  onSendEmail: () => void;
  attachedFiles: any[];
  setAttachedFiles: (attachedFiles: any[]) => void;
  removeAttachment: (id: any) => void;
  downloadAttachment: (id: any) => void;
  ccEmailArray: any[];
  setCCEmailArray: (ccEmailArray: any[]) => void;
  fromEmailArray: any[];
  setFromEmailArray: (fromEmailArray: any[]) => void;
  toEmailArray: any[];
  setToEmailArray: (toEmailArray: any[]) => void;
  onCreateQuestionHeader: (newHeader: string) => void;
  onCreateNote: (newNote: string) => void;
  onCreateQuestion: (newHeader: string) => void;
  onCreateNoteHeader: (newHeader: string) => void;
  onDeleteNote: (id: any) => void;
  onDeleteQuestion: (id: any) => void;
  onUpdateQuestion: (id, questionText) => void;
  onUpdateNote: (id, noteText) => void;
  sharepointLinkInEmail:any;
  setSharepointLinkInEmail:(sharepointLinkInEmail:any) => void;
  emailPendingFlag: boolean;
  setEmailPendingFlag: (emailPendingFlag: boolean) => void;
}

export const MilestoneEmailContext = createContext<MilestoneEmailContextProps>(null as any);

export const MilestoneEmailContextProvider = (props) => {
  const { selectedCompany } = useContext(AuthContext);
  const { onComplete } = useContext(MilestoneContext);
  const [email, setEmail] = useState<any | null | undefined>();
  const [emailPreviewFlag, setEmailPreviewFlag] = useState<boolean>(false);
  const [emailReadyToSendFlag, setEmailReadyToSendFlag] = useState<string>("");
  const [cycleDate, setCycleDate] = useState<any>();
  const [emailGreeting, setEmailGreeting] = useState<string>("");
  const [richTextEmail, setRichTextEmail] = useState<string>("");
  const [emailSubject, setEmailSubject] = useState<string>("");
  const [introToNotes, setIntroToNotes] = useState<any>();
  const [introToQuestions, setIntroToQuestions] = useState<any>();
  const [outro, setOutro] = useState<any>();
  const [emailSenderSignature, setEmailSenderSignature] = useState<string>("");
  const [recepientsNames, setRecepientsNames] = useState<string>("");
  const [aboveNotes, setAboveNotes] = useState<string>("");
  const [aboveQuestions, setAboveQuestions] = useState<string>("");
  const [approvalRequiredByNote, setApprovalRequiredByNote] = useState<string>("");
  const [secondGreetingsLine, setSecondGreetingsLine] = useState<string>("");
  const [payrollFileLink, setPayrollFileLink] = useState<any>(null);
  const [attachedFiles, setAttachedFiles] = useState<any[]>([]);
  const [toEmailArray, setToEmailArray] = useState<any[]>([]);
  const [fromEmailArray, setFromEmailArray] = useState<any[]>([]);
  const [ccEmailArray, setCCEmailArray] = useState<any[]>([]);
  const [questionsHeaders, setQuestionsHeaders] = useState<any[]>([]);
  const [questions, setQuestions] = useState<any[]>([]);
  const [notes, setNotes] = useState<any[]>([]);
  const [notesHeaders, setNotesHeaders] = useState<any[]>([]);
  const [milestoneLinks, setMilestoneLinks] = useState<any>();
  const [sharepointLinkInEmail, setSharepointLinkInEmail] = useState<any>()
  const [emailPendingFlag, setEmailPendingFlag] = useState<boolean>(false);
  const { setPayrollLoading, selectedMilestone, nextStage } = useContext(MilestoneContext);
  const getEmail = async () => {
    
    if (selectedMilestone && selectedCompany) {
      const email = await retrieveTicketingSystemEmail(selectedMilestone.gid, selectedCompany.id);
      setRichTextEmail(email.rich_text_email);
      setEmailSubject(email.subject)
      setEmail(email);
    }
  };

  const onSendEmail = async () => {
    // create asanatasks and get the id, then store the id with the question on the backend.

    if (toEmailArray?.length > 0 && fromEmailArray?.length > 0) {
      const today = new Date();
      setPayrollLoading(true);
      for (let i = 0; i < questions.length; i++) {
        const question = questions[i];
        if (question.asana_task_id === "") {
          const newObj = {
            data: {
              name: "Question",
              notes: question.content,
              assignee: nextStage?.assignee?.gid,
              due_on: today.toISOString().substring(0, 10),
            },
          };
          const subtask = await createAsanaSubTask(nextStage.gid, newObj);

          if (subtask?.data) {
            await updateQuestion(question.id, { asana_task_id: subtask.data.gid });
          }
        }
      }

      // create asana tasks for question headers and questions inside of them as subtasks
      for (let i = 0; i < questionsHeaders.length; i++) {
        const questionHeader = questionsHeaders[i];
        let questionHeaderSubtaskGID;
        if (questionHeader.asana_task_id === "") {
          const newObj = {
            data: {
              name: questionHeader.content,
              assignee: nextStage?.assignee?.gid,
              due_on: today.toISOString().substring(0, 10),
            },
          };
          const response = await createAsanaSubTask(nextStage.gid, newObj);
          questionHeaderSubtaskGID = response.data.gid;
          await updatePayrollQuestionsHeader(questionHeader.id, { asana_task_id: questionHeaderSubtaskGID });
        } else {
          questionHeaderSubtaskGID = questionHeader.asana_task_id;
        }

        if (questionHeaderSubtaskGID) {
          // get questions in header
          const questionsInHeader = await retrieveQuestionsInHeaderList(questionHeader.id);
          for (let j = 0; j < questionsInHeader.length; j++) {
            let questionsInHeaderSubtaskGID;
            const questionInHeader = questionsInHeader[j];
            if (questionHeader.asana_task_id === "") {
              const newObj = {
                data: {
                  name: questionInHeader.content,
                  assignee: nextStage?.assignee?.gid,
                  due_on: today.toISOString().substring(0, 10),
                },
              };
              const response = await createAsanaSubTask(questionHeaderSubtaskGID, newObj);
              questionsInHeaderSubtaskGID = response.data.gid;
            } else {
              questionsInHeaderSubtaskGID = questionInHeader.asana_task_id;
            }

            if (questionsInHeaderSubtaskGID) {
              await updateQuestionInHeader(questionInHeader.id, { asana_task_id: questionsInHeaderSubtaskGID });
            }
          }
        }
      }

      if (email && !emailPendingFlag) {
        setEmailPendingFlag(true);
        const response = await sendEmail(email.id);
        if (response.status === "success") {
          setEmail({...email, sent:true})
          if ((nextStage?.["name"] === "Client Review" || nextStage?.["name"] === "Send Financials to Client") && emailReadyToSendFlag === "move-stage") {
            onComplete();
          }
          setEmailPreviewFlag(false);
          setEmailReadyToSendFlag("");
          showToast("success", "Email was sent!");
        } else {
          showToast("error", response?.response?.data?.error || "Email was not sent!");
        }
        setEmailPendingFlag(false);
      }
      setPayrollLoading(false);
    } else {
      window.alert("Please fill out both 'from' and 'to' fields to send the email!");
    }
  };

  const getEmailParams = async (milestone_type) => {
    if (selectedCompany) {
      setPayrollLoading(true);
      const email_params = await retrievePayrollEmailParams(selectedCompany.id, milestone_type);
      if (email_params) {
        if (email_params.email_to !== "") setToEmailArray(email_params.email_to?.split(","));
        if (email_params.email_from !== "") setFromEmailArray(email_params.email_from?.split(","));
        if (email_params.email_cc !== "") setCCEmailArray(email_params.email_cc?.split(","));
        
        if (email_params.email_sender_signature !== "") setEmailSenderSignature(email_params.email_sender_signature);
        if (email_params.recepients_names !== "") setRecepientsNames(email_params.recepients_names);
      }
      setPayrollLoading(false);
    }
  };

  const getEmailAttachments = async () => {
    if (email?.id) {
      const response = await retrievePayrollEmailAttachments(email.id);
      if (response) {
        setAttachedFiles(response.detail);
      }
    }
  };

  const downloadAttachment = () => {};

  const removeAttachment = async (fileID) => {
    const response = await removeEmailAttachment(fileID);
    if (response.detail) {
      setAttachedFiles(response.detail);
    }
  };

  const onCreateNoteHeader = async (newHeader) => {
    if (email) {
      setPayrollLoading(true);
      const newObj = {
        content: newHeader,
      };
      const response = await createEmailNotesHeader(email.id, newObj);
      const newArray = [response, ...notesHeaders];
      setNotesHeaders(newArray);
      setPayrollLoading(false);
    }
  };

  const onCreateQuestionHeader = async (newHeader) => {
    setPayrollLoading(true);
    const newObj = {
      content: newHeader,
    };
    const response = await createEmailQuestionsHeader(email.id, newObj);
    if (response) {
      setQuestionsHeaders(response);
    } else {
    }

    setPayrollLoading(false);
  };

  const onCreateNote = async (newNote) => {
    const newObj = { content: newNote };
    const response = await createPayrolNotes(email.id, newObj);
    const newArray = [response, ...notes];
    setNotes(newArray);
  };

  const onCreateQuestion = async (newQuestion) => {
    setPayrollLoading(true);
    const newObj = {
      content: newQuestion,
    };
    const response = await createPayrolQuestion(email.id, newObj);
    setQuestions(response);
    setPayrollLoading(false);
  };

  const onDeleteNote = async (id) => {
    setPayrollLoading(true);
    await deletePayrollNote(id);
    const newNotesArray = notes.filter((x) => x.id !== id);
    setNotes(newNotesArray);
    setPayrollLoading(false);
  };

  const onDeleteQuestion = async (id) => {
    setPayrollLoading(true);
    await deletePayrollQuestion(id);
    const newQuestionsArray = questions.filter((x) => x.id !== id);
    setQuestions(newQuestionsArray);
    setPayrollLoading(false);
  };

  const onUpdateQuestion = async (id, questionText) => {
    setPayrollLoading(true);
    const response = await updateQuestion(id, {
      content: questionText,
    });
    if (response) {
      // update questions array
      const newQuestionsArray = questions.map((x) => (x.id === id ? { ...x, content: questionText } : x));
      setQuestions(newQuestionsArray);
    } else if (response.message === "Network Error") {
      // discuss with Mihil: should i create an array of ids of questions which have not been successfully updated on the backend?
    }
    setPayrollLoading(false);
  };

  const onUpdateNote = async (id, noteText) => {
    setPayrollLoading(true);
    const response = await updateNote(id, { content: noteText });
    if (response) {
      // update questions array
      const newNotesArray = notes.map((x) => (x.id === id ? { ...x, content: noteText } : x));
      setNotes(newNotesArray);
    }
    setPayrollLoading(false);
  };

  const getPayrollQuestionsAndNotes = async () => {
    setPayrollLoading(true);
    if (email?.id ) {
      const questions = await retrievePayrollQuestionsList(email.id);
      const notes = await retrievePayrollNotesList(email.id);
      const questionsHeaders = await retrievePayrollQuestionsHeadersList(email.id);
      setQuestionsHeaders(questionsHeaders);
      const notesHeaders = await retrievePayrollNotesHeadersList(email.id);
      setNotesHeaders(notesHeaders);
      setQuestions(questions);
      setNotes(notes);
    }
    setPayrollLoading(false);
  };

  useEffect(() => {
    getEmail();
  }, [selectedMilestone]);

  useEffect(() => {
    getPayrollQuestionsAndNotes();
    getEmailAttachments();
  }, [selectedCompany, email]);

  return (
    <MilestoneEmailContext.Provider
      value={{
        email,
        getEmailParams,
        questions,
        setQuestions,
        questionsHeaders,
        setQuestionsHeaders,
        notes,
        setNotes,
        notesHeaders,
        setNotesHeaders,
        emailPreviewFlag,
        emailReadyToSendFlag,
        ccEmailArray,
        fromEmailArray,
        toEmailArray,
        setEmailPreviewFlag,
        setEmailReadyToSendFlag,
        cycleDate,
        setCycleDate,
        emailGreeting,
        setEmailGreeting,
        richTextEmail, setRichTextEmail,emailSubject,setEmailSubject,
        introToNotes,
        setIntroToNotes,
        introToQuestions,
        setIntroToQuestions,
        outro,
        setOutro,
        emailSenderSignature,
        setEmailSenderSignature,
        aboveNotes,
        setAboveNotes,
        aboveQuestions,
        setAboveQuestions,
        approvalRequiredByNote,
        setApprovalRequiredByNote,
        secondGreetingsLine,
        setSecondGreetingsLine,
        payrollFileLink,
        setPayrollFileLink,
        onSendEmail,
        attachedFiles,
        setAttachedFiles,
        downloadAttachment,
        removeAttachment,
        onCreateQuestionHeader,
        onCreateNote,
        onCreateQuestion,
        onCreateNoteHeader,
        onDeleteNote,
        onDeleteQuestion,
        onUpdateQuestion,
        onUpdateNote,
        setCCEmailArray,
        setFromEmailArray,
        setToEmailArray,
        setRecepientsNames,
        recepientsNames, sharepointLinkInEmail, setSharepointLinkInEmail,
        emailPendingFlag,
        setEmailPendingFlag,
      }}
    >
      {props.children}
    </MilestoneEmailContext.Provider>
  );
};
