import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import { numberFormat } from '../../../../utils/helpers';

export default function useRenderFilters(params: GridRenderCellParams) {
    const renderValue = params.field === 'salary' ? numberFormat(parseInt(params.value)) : params.value;
    return (
        <Box
            component="span"
            sx={{
                fontSize: "12px",
            }}>
            {renderValue}
        </Box>
    )
}