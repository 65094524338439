import { debounce, TextareaAutosize } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { updateAsanaTask } from "../../../../APIFunctions/Asana";
import { MilestoneContext } from "../../../../contexts/TicketingSystem/MilestoneContext";

const MilestoneDescription = ({ milestone, setSelectedMilestone }) => {
  const { setPayrollLoading } = useContext(MilestoneContext);
  const [description, setDescription] = useState<string>("");
  const [editFlag, setEditFlag] = useState<boolean>(false);

  const saveMilestoneDescription = async (e) => {
    setPayrollLoading(true);
    const newDescription = {
      gid: milestone.gid,
      notes: e.target.value,
    };
    await updateAsanaTask(newDescription);
    setSelectedMilestone({ ...milestone, notes: e.target.value });
    setPayrollLoading(false);
  };

  const debouncedChangeDescription = useMemo(() => {
    return debounce(saveMilestoneDescription, 500);
  }, []);

  const onChangeDescription = (e) => {
    const newValue = e.target.value;
    setDescription(newValue);
    debouncedChangeDescription(e);
  };

  useEffect(() => {
    setDescription(milestone.notes);
  }, [milestone]);

  return editFlag ? (
    <TextareaAutosize
      aria-label="empty textarea"
      placeholder="Insert description for this stage..."
      style={{ width: "78.3rem !important", paddingRight: 15, paddingLeft: 15, paddingBottom: 15, overflow: "visible", marginTop: "0rem" }}
      minRows={5}
      className="PayrollCard__Description"
      value={description}
      name="name"
      onChange={(e) => onChangeDescription(e)}
      onBlur={() => setEditFlag(false)}
    />
  ) : (
    <div className="PayrollCard__Description__Container">
      <div className="PayrollCard__Description" dangerouslySetInnerHTML={{ __html: milestone.html_notes }} />
      <button className="btn--tertiary btn--S flex-row--S addComment__Button" onClick={() => setEditFlag(true)}>
        Edit Description
      </button>
    </div>
  );
};

export default MilestoneDescription;
