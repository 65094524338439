import React from "react";
import SubTaskLine from "../SubTaskLine";
import SubTaskContainerHeader from "../SubTaskContainerHeader";
import SubTaskContainer from "../SubTaskContainer";
import { getFeedbackSection } from "./helpers";

const Presentation = ({ displaySubTaskFlag, filteredFeedbackList, setSubTask, openSubTask, changeSubTaskInList, subTask, onCloseSubTask, onCompleteSubTask, onDeleteSubTask }) => {
  return (
    <>
      {!displaySubTaskFlag ? (
        <div className="FeedbackList__Container">
          <div className="FeedbackList__Container__Header">
            <div>Feedback</div>
            <div>Section</div>
          </div>
          <div data-test="FeedbackList__List" className="FeedbackList__List">
            {filteredFeedbackList?.map((feedback) => {
              return (
                <SubTaskLine
                  key={feedback.task.gid}
                  subtaskName={feedback.task?.["name"]}
                  subtask={feedback.task}
                  setSubTask={setSubTask}
                  openSubTask={openSubTask}
                  changeSubTaskInList={changeSubTaskInList(feedback.milestoneTask["gid"])}
                  sectionName={feedback.milestoneTask["name"]}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <>
          <SubTaskContainerHeader
            subtask={subTask}
            setSubTask={setSubTask}
            // retrieveAttachments={retrieveSubTaskAttachments}
            completed={subTask?.completed}
            onClose={onCloseSubTask}
            onComplete={() => onCompleteSubTask(getFeedbackSection(subTask["gid"], filteredFeedbackList)?.["gid"])}
            onDelete={onDeleteSubTask(getFeedbackSection(subTask["gid"], filteredFeedbackList)?.["gid"])}
          />

          <SubTaskContainer
            subTask={subTask}
            subTaskName={subTask?.["name"]}
            setSubTask={setSubTask}
            changeSubTaskInList={changeSubTaskInList(getFeedbackSection(subTask["gid"], filteredFeedbackList)?.["gid"])}
          />
        </>
      )}
    </>
  );
};

export default Presentation;
