import React, { useContext, useEffect, useState } from "react";
import SharepointExplorerGetFileLink from "../../../../Common/SharepointExplorer/SharepointExplorerGetFileLink";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { getSharepointInfoObj } from "../Util";
import { createMilestoneLink } from "../../../../../APIFunctions/ticketing_system";
import { MilestoneEmailContext } from "../../../../../contexts/TicketingSystem/MilestoneEmailContext";
import { MilestoneLinkInfo } from "../../../../../types/TicketingSystem";
import { SharepointFileInfo } from "../../../../../types/Shareppoint";
import { MilestoneLinksContext } from "../../../../../contexts/TicketingSystem/MilestoneLinksContext";
import { useCreateLink } from "./Hooks";
import AddIcon from "@mui/icons-material/Add";

const InsertNewLink = ({ linkType, inTable }) => {
  const { selectedCompany } = useContext(AuthContext);
  const { email } = useContext(MilestoneEmailContext);
  const [displaySharepointBrowser, setDisplaySharepointBrowser] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [selectedFolder, setSelectedFolder] = useState<any>(null);

  const createLinkMutation = useCreateLink(email?.id);

  const onCloseBrowser = () => {
    setDisplaySharepointBrowser(false);
  };

  const createLink = () => {
    const sharepoint_file_info: SharepointFileInfo = getSharepointInfoObj(selectedFile, selectedFolder);
    const link_info: MilestoneLinkInfo = { email: email.id, name: selectedFile.name, link_type: linkType };

    createLinkMutation.mutate({ ...link_info, sharepoint_file_info });
    
  };

  useEffect(() => {
    if (selectedFile) {
      createLink();
    }
  }, [selectedFile]);

  return (
    <>
      <div className={inTable ? "LinksTable__Row--inTable" : "LinksTable__Row"} onClick={() => setDisplaySharepointBrowser(true)}>
        <span className={inTable ? "AddNewLink__Button--inTable no-borders-no-background success": "AddNewLink__Button success"}><AddIcon />Add a new file</span>
      </div>
      {displaySharepointBrowser && (
        <SharepointExplorerGetFileLink
          onCloseBrowser={onCloseBrowser}
          displaySharepointBrowser={displaySharepointBrowser}
          company={selectedCompany}
          setDisplaySharepointBrowser={setDisplaySharepointBrowser}
          setSelectedFile={setSelectedFile}
          setSelectedFolder={setSelectedFolder}
        />
      )}
    </>
  );
};

export default InsertNewLink;
