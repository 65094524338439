import APIManger from "../APIManger";
import { SharepointFileInfo } from "../types/Shareppoint";
import { MilestoneLink, MilestoneLinkInfo, MilestoneLinkPinning } from "../types/TicketingSystem";
import {
  PROJECT_SUBTASKS_RETRIEVE,
  PAYROLL_FILE_LINK_RETRIEVE,
  PAYROLL_TYPES_RETRIEVE,
  PAYROLL_LIST_RETRIEVE,
  PAYROLL_QUESTION_CREATE,
  PAYROLL_NOTE_CREATE,
  PAYROLL_QUESTION_UPDATE,
  PAYROLL_NOTE_UPDATE,
  PAYROLL_QUESTIONS_LIST_RETRIEVE,
  PAYROLL_NOTES_LIST_RETRIEVE,
  PAYROLL_EMAIL_PARAMETERS_RETRIEVE_UPDATE,
  EMAIL_RETRIEVE_UPDATE,
  PAYROLL_TYPES_CREATE,
  PAYROLL_CREATE,
  PAYROLL_FILE_LINK_UPDATE,
  PAYROLL_PLATFORM_LINK_RETRIEVE,
  PAYROLL_EMAIL_ATTACHMENT_LIST_RETRIEVE,
  PAYROLL_EMAIL_ATTACHMENT_REMOVE,
  EMAIL_QUESTIONS_HEADER_CREATE,
  EMAIL_NOTES_HEADER_CREATE,
  PAYROLL_QUESTIONS_HEADERS_LIST_RETRIEVE,
  PAYROLL_NOTES_HEADERS_LIST_RETRIEVE,
  PAYROLL_QUESTIONS_IN_HEADER_LIST_RETRIEVE,
  PAYROLL_NOTES_IN_HEADER_LIST_RETRIEVE,
  PAYROLL_QUESTION_IN_HEADER_CREATE,
  PAYROLL_NOTE_IN_HEADER_CREATE,
  PAYROLL_QUESTION_IN_HEADER_UPDATE,
  PAYROLL_NOTE_IN_HEADER_UPDATE,
  PAYROLL_NOTES_HEADER_UPDATE,
  PAYROLL_QUESTIONS_HEADER_UPDATE,
  EMAIL_RETRIEVE,
  EMAIL_SEND,
  MILESTONE_LINKS_CREATE_RETRIEVE,
  MILESTONE_LINK_EDIT_DELETE,
  SUBTASK_LINKS_CREATE_RETRIEVE,
  SUBTASK_LINK_EDIT_DELETE,
  RETRIEVE_ALL_COMPANY_ICONS,
  MONTH_END_LINKS_CREATE_RETRIEVE,
  MONTH_END_LINK_EDIT_DELETE,
  CELL_COMMENTS_LIST_RETRIEVE,
  CELL_COMMENTS_RETRIEVE_UPDATE_DELETE,
  CELL_COMMENTS_CREATE,
  TICKETING_SYSTEM_CELL_COMMENTS_RETRIEVE_UPDATE_DELETE,
  TICKETING_SYSTEM_CELL_COMMENTS_CREATE,
  TICKETING_SYSTEM_CELL_COMMENTS_LIST_RETRIEVE,
  MILESTONE_LINK_PINNING,
  MILESTONE_LINKS_RETRIEVE,
} from "./apiConstants";

// export const retrieveEmail = async (asanaMilestone_gid: any): Promise<any> => {
//     let url = EMAIL_RETRIEVE.replace("%ASANA_MILESTONE_GID%", asanaMilestone_gid.toString())
//     const apiResult: any = await APIManger.get(url)
//     return apiResult;
// }

export const retrievePayrollEmailAttachments = async (email_id: any): Promise<any> => {
  let url = PAYROLL_EMAIL_ATTACHMENT_LIST_RETRIEVE.replace("%EMAIL_ID%", email_id.toString());
  const apiResult: any = await APIManger.get(url);
  return apiResult;
};

export const removeEmailAttachment = async (file_id: any): Promise<any> => {
  let url = PAYROLL_EMAIL_ATTACHMENT_REMOVE.replace("%FILE_ID%", file_id.toString());
  const apiResult: any = await APIManger.get(url);
  return apiResult;
};

export const retrievePayrollTypeDict = async (company_id: any): Promise<any> => {
  let url = PAYROLL_TYPES_RETRIEVE.replace("%COMPANY_ID%", company_id.toString());
  const apiResult: any = await APIManger.get(url);
  return apiResult;
};

export const createPayrollType = async (company_id: any, data: any): Promise<any> => {
  let url = PAYROLL_TYPES_CREATE.replace("%COMPANY_ID%", company_id.toString());
  const apiResult: any = await APIManger.post(url, data);
  return apiResult;
};

export const createPayrollObj = async (payroll_type_id: any, cycle_date: string): Promise<any> => {
  if (payroll_type_id) {
    let url = PAYROLL_CREATE.replace("%TYPE_ID%", payroll_type_id.toString()).replace("%CYCLE_DATE%", cycle_date.toString());
    const apiResult: any = await APIManger.post(url);
    return apiResult;
  }
};

export const retrievePayrollList = async (cycle_date: any): Promise<any> => {
  let url = PAYROLL_LIST_RETRIEVE.replace("%CYCLE_DATE%", cycle_date.toString());
  const apiResult: any = await APIManger.get(url);
  return apiResult;
};

export const retrievePayrollQuestionsList = async (email_id: any): Promise<any> => {
  let url = PAYROLL_QUESTIONS_LIST_RETRIEVE.replace("%EMAIL_ID%", email_id.toString());
  const apiResult: any = await APIManger.get(url);
  return apiResult;
};

export const retrieveQuestionsInHeaderList = async (header_id: any): Promise<any> => {
  let url = PAYROLL_QUESTIONS_IN_HEADER_LIST_RETRIEVE.replace("%HEADER_ID%", header_id.toString());
  const apiResult: any = await APIManger.get(url);
  return apiResult;
};

export const retrievePayrollQuestionsHeadersList = async (email_id: any): Promise<any> => {
  let url = PAYROLL_QUESTIONS_HEADERS_LIST_RETRIEVE.replace("%EMAIL_ID%", email_id.toString());
  const apiResult: any = await APIManger.get(url);
  return apiResult;
};

export const retrievePayrollNotesHeadersList = async (email_id: any): Promise<any> => {
  let url = PAYROLL_NOTES_HEADERS_LIST_RETRIEVE.replace("%EMAIL_ID%", email_id.toString());
  const apiResult: any = await APIManger.get(url);
  return apiResult;
};

export const retrieveTicketingSystemEmail = async (asana_milestone_id: any, company_id: any): Promise<any> => {
  let url = EMAIL_RETRIEVE_UPDATE.replace("%ASANA_MILESTONE_ID%", asana_milestone_id.toString()).replace("%COMPANY_ID%", company_id.toString());
  const apiResult: any = await APIManger.get(url);
  return apiResult;
};

export const updateTicketingSystemEmail = async (asana_milestone_id: any,company_id:any, data): Promise<any> => {
  let url = EMAIL_RETRIEVE_UPDATE.replace("%ASANA_MILESTONE_ID%", asana_milestone_id.toString()).replace("%COMPANY_ID%", company_id.toString());
  const apiResult: any = await APIManger.patch(url, data);
  return apiResult;
};

export const retrievePayrollEmailParams = async (company_id: any, milestone_type: any): Promise<any> => {
  let url = PAYROLL_EMAIL_PARAMETERS_RETRIEVE_UPDATE.replace("%COMPANY_ID%", company_id.toString()).replace("%MILESTONE_TYPE%", milestone_type.toString());
  const apiResult: any = await APIManger.get(url);
  return apiResult;
};

export const updatePayrollEmailParams = async (company_id: any, milestone_type: any, data: any): Promise<any> => {
  let url = PAYROLL_EMAIL_PARAMETERS_RETRIEVE_UPDATE.replace("%COMPANY_ID%", company_id.toString()).replace("%MILESTONE_TYPE%", milestone_type.toString());
  const apiResult: any = await APIManger.patch(url, data);
  return apiResult;
};

export const retrievePayrollNotesList = async (email_id: any): Promise<any> => {
  let url = PAYROLL_NOTES_LIST_RETRIEVE.replace("%EMAIL_ID%", email_id.toString());
  const apiResult: any = await APIManger.get(url);
  return apiResult;
};

export const retrievePayrollNotesInHeaderList = async (header_id: any): Promise<any> => {
  let url = PAYROLL_NOTES_IN_HEADER_LIST_RETRIEVE.replace("%HEADER_ID%", header_id.toString());
  const apiResult: any = await APIManger.get(url);
  return apiResult;
};

export const createPayrolQuestion = async (email_id: any, data: any): Promise<any> => {
  let url = PAYROLL_QUESTION_CREATE.replace("%EMAIL_ID%", email_id.toString());
  const apiResult: any = await APIManger.post(url, data);
  return apiResult;
};

export const createPayrollQuestionInHeader = async (header_id: any, data: any): Promise<any> => {
  let url = PAYROLL_QUESTION_IN_HEADER_CREATE.replace("%HEADER_ID%", header_id.toString());
  const apiResult: any = await APIManger.post(url, data);
  return apiResult;
};

export const createEmailQuestionsHeader = async (email_id: any, data: any): Promise<any> => {
  let url = EMAIL_QUESTIONS_HEADER_CREATE.replace("%EMAIL_ID%", email_id.toString());
  const apiResult: any = await APIManger.post(url, data);
  return apiResult;
};

export const createEmailNotesHeader = async (email_id: any, data: any): Promise<any> => {
  let url = EMAIL_NOTES_HEADER_CREATE.replace("%EMAIL_ID%", email_id.toString());
  const apiResult: any = await APIManger.post(url, data);
  return apiResult;
};

export const createPayrolNotes = async (email_id: any, data: any): Promise<any> => {
  let url = PAYROLL_NOTE_CREATE.replace("%EMAIL_ID%", email_id.toString());
  const apiResult: any = await APIManger.post(url, data);
  return apiResult;
};

export const createPayrollNotesInHeader = async (header_id: any, data: any): Promise<any> => {
  let url = PAYROLL_NOTE_IN_HEADER_CREATE.replace("%HEADER_ID%", header_id.toString());
  const apiResult: any = await APIManger.post(url, data);
  return apiResult;
};

export const updateQuestion = async (question_id: any, data: any): Promise<any> => {
  let url = PAYROLL_QUESTION_UPDATE.replace("%QUESTION_ID%", question_id.toString());
  const apiResult: any = await APIManger.patch(url, data);
  return apiResult;
};

export const updateQuestionInHeader = async (question_id: any, data: any): Promise<any> => {
  let url = PAYROLL_QUESTION_IN_HEADER_UPDATE.replace("%QUESTION_ID%", question_id.toString());
  const apiResult: any = await APIManger.patch(url, data);
  return apiResult;
};

export const updatePayrollQuestionsHeader = async (header_id: any, data: any): Promise<any> => {
  let url = PAYROLL_QUESTIONS_HEADER_UPDATE.replace("%HEADER_ID%", header_id.toString());
  const apiResult: any = await APIManger.patch(url, data);
  return apiResult;
};

export const updateNote = async (note_id: any, data: any): Promise<any> => {
  let url = PAYROLL_NOTE_UPDATE.replace("%NOTE_ID%", note_id.toString());
  const apiResult: any = await APIManger.patch(url, data);
  return apiResult;
};

export const updateNoteInHeader = async (note_id: any, data: any): Promise<any> => {
  let url = PAYROLL_NOTE_IN_HEADER_UPDATE.replace("%NOTE_ID%", note_id.toString());
  const apiResult: any = await APIManger.patch(url, data);
  return apiResult;
};

export const updatePayrollNotesHeader = async (header_id: any, data: any): Promise<any> => {
  let url = PAYROLL_NOTES_HEADER_UPDATE.replace("%HEADER_ID%", header_id.toString());
  const apiResult: any = await APIManger.patch(url, data);
  return apiResult;
};

export const deletePayrollQuestion = async (question_id: any): Promise<any> => {
  let url = PAYROLL_QUESTION_UPDATE.replace("%QUESTION_ID%", question_id.toString());
  const apiResult: any = await APIManger.delete(url);
  return apiResult;
};

export const deletePayrollQuestionInHeader = async (question_id: any): Promise<any> => {
  let url = PAYROLL_QUESTION_IN_HEADER_UPDATE.replace("%QUESTION_ID%", question_id.toString());
  const apiResult: any = await APIManger.delete(url);
  return apiResult;
};

export const deletePayrollQuestionHeader = async (header_id: any): Promise<any> => {
  let url = PAYROLL_QUESTIONS_HEADER_UPDATE.replace("%HEADER_ID%", header_id.toString());
  const apiResult: any = await APIManger.delete(url);
  return apiResult;
};

export const deletePayrollNote = async (note_id: any): Promise<any> => {
  let url = PAYROLL_NOTE_UPDATE.replace("%NOTE_ID%", note_id.toString());
  const apiResult: any = await APIManger.delete(url);
  return apiResult;
};

export const deleteNoteInHeader = async (note_id: any): Promise<any> => {
  let url = PAYROLL_NOTE_IN_HEADER_UPDATE.replace("%NOTE_ID%", note_id.toString());
  const apiResult: any = await APIManger.delete(url);
  return apiResult;
};

export const deletePayrollNoteHeader = async (header_id: any): Promise<any> => {
  let url = PAYROLL_NOTES_HEADER_UPDATE.replace("%HEADER_ID%", header_id.toString());
  const apiResult: any = await APIManger.delete(url);
  return apiResult;
};

export const getProjectSubtasksData = async (project_id: string): Promise<any> => {
  let url = PROJECT_SUBTASKS_RETRIEVE.replace("%PROJECT_GID%", project_id);
  const apiResult: any = await APIManger.get(url);
  return apiResult;
};

export const getPayrollFileLink = async (payroll_type_id: any): Promise<any> => {
  let url = PAYROLL_FILE_LINK_RETRIEVE.replace("%PAYROLL_TYPE_ID%", payroll_type_id.toString());
  const apiResult: any = await APIManger.get(url);
  return apiResult;
};

export const getPayrollPlatformLink = async (platform_type_id: any): Promise<any> => {
  let url = PAYROLL_PLATFORM_LINK_RETRIEVE.replace("%PLATFORM_TYPE_ID%", platform_type_id.toString());
  const apiResult: any = await APIManger.get(url);
  return apiResult;
};

export const updatePayrollFileLink = async (link_id: any, data: any): Promise<any> => {
  let url = PAYROLL_FILE_LINK_UPDATE.replace("%LINK_ID%", link_id.toString());
  const apiResult: any = await APIManger.patch(url, data);
  return apiResult;
};

export const sendEmail = async (email_id: number): Promise<any> => {
  let url = EMAIL_SEND.replace("%EMAIL_ID%", email_id.toString());
  const apiResult: any = await APIManger.post(url);
  return apiResult;
};


// Milestone links

export const getMilestoneLinks = async (company_id: any, email_id:any): Promise<any> => {
  let url = MILESTONE_LINKS_RETRIEVE.replace("%COMPANY_ID%", company_id.toString()).replace("%EMAIL_ID%",email_id.toString());
  const apiResult: any = await APIManger.get(url);
  return apiResult;
};

export const getMonthEndLinks = async (company_id: any): Promise<any> => {
  let url = MONTH_END_LINKS_CREATE_RETRIEVE.replace("%COMPANY_ID%", company_id.toString());
  const apiResult: any = await APIManger.get(url);
  return apiResult;
};



export const createMilestoneLink = async (company_id: any, data): Promise<MilestoneLink[]> => {
  let url = MILESTONE_LINKS_CREATE_RETRIEVE.replace("%COMPANY_ID%", company_id.toString());
  
  const apiResult: MilestoneLink[] = await APIManger.post(url, data);
  return apiResult;
};

export const editMilestoneLink = async (link_id: any, sharepoint_file_info: SharepointFileInfo): Promise<any> => {
  let url = MILESTONE_LINK_EDIT_DELETE.replace("%LINK_ID%", link_id.toString());
  const apiResult: any = await APIManger.patch(url, {sharepoint_file_info});
  return apiResult;
};

export const pinningMilestoneLink = async (company_id:any, link_id: any, request_data: MilestoneLinkPinning): Promise<any> => {
  let url = MILESTONE_LINK_PINNING.replace("%COMPANY_ID%", company_id.toString()).replace("%LINK_ID%", link_id.toString());
  const apiResult: any = await APIManger.patch(url, request_data);
  return apiResult;
};

export const editMonthEndLink = async (link_id: any, data: any): Promise<any> => {
  let url = MONTH_END_LINK_EDIT_DELETE.replace("%LINK_ID%", link_id.toString());
  const apiResult: any = await APIManger.patch(url, data);
  return apiResult;
};

export const deleteMilestoneLink = async (link_id: any): Promise<any> => {
  let url = MILESTONE_LINK_EDIT_DELETE.replace("%LINK_ID%", link_id.toString());
  const apiResult: any = await APIManger.delete(url);
  return apiResult;
};

export const deleteMilestoneLinkSharepointFile = async (sharepointFileID: any): Promise<any> => {
  let url = MILESTONE_LINK_EDIT_DELETE.replace("%LINK_ID%", sharepointFileID.toString());
  const apiResult: any = await APIManger.delete(url);
  return apiResult;
};

// subtask links

export const getSubtaskLinks = async (subtask_gid: any): Promise<any> => {
  let url = SUBTASK_LINKS_CREATE_RETRIEVE.replace("%SUBTASK_GID%", subtask_gid.toString());
  const apiResult: any = await APIManger.get(url);
  return apiResult;
};

export const createSubtaskLink = async (subtask_gid: any, data: any): Promise<any> => {
  let url = SUBTASK_LINKS_CREATE_RETRIEVE.replace("%SUBTASK_GID%", subtask_gid.toString());
  const apiResult: any = await APIManger.post(url, data);
  return apiResult;
};

export const editSubtaskLink = async (link_id: any, data: any): Promise<any> => {
  let url = SUBTASK_LINK_EDIT_DELETE.replace("%LINK_ID%", link_id.toString());
  const apiResult: any = await APIManger.patch(url, data);
  return apiResult;
};

export const deleteSubtaskLink = async (link_id: any): Promise<any> => {
  let url = SUBTASK_LINK_EDIT_DELETE.replace("%LINK_ID%", link_id.toString());
  const apiResult: any = await APIManger.delete(url);
  return apiResult;
};

export const getAllCompanyIcons = async (): Promise<any> => {
  let url = RETRIEVE_ALL_COMPANY_ICONS
  const apiResult: any = await APIManger.get(url);
  return apiResult;
};

export const getMonthEndCellCommentsList = async (milestone_id: string): Promise<any> => {
  let url = CELL_COMMENTS_LIST_RETRIEVE.replace("%MILESTONE_ID%", milestone_id);
  const apiResult: any = await APIManger.get(url);
  return apiResult;
};
export const getTicketingSystemCellCommentsList = async (milestone_id: string): Promise<any> => {
  let url = TICKETING_SYSTEM_CELL_COMMENTS_LIST_RETRIEVE.replace("%MILESTONE_ID%", milestone_id);
  const apiResult: any = await APIManger.get(url);
  return apiResult;
};

export const updateMonthEndCellComment = async (data: any): Promise<any> => {
  let url = CELL_COMMENTS_RETRIEVE_UPDATE_DELETE.replace("%CELL_COMMENT_ID%", data.id);
  const apiResult: any = await APIManger.patch(url,data);
  return apiResult;
};

export const updateTicketingSystemCellComment = async (data: any): Promise<any> => {
  let url = TICKETING_SYSTEM_CELL_COMMENTS_RETRIEVE_UPDATE_DELETE.replace("%CELL_COMMENT_ID%", data.id);
  const apiResult: any = await APIManger.patch(url,data);
  return apiResult;
};

export const deleteTicketingSystemCellComment = async (data: any): Promise<any> => {
  let url = TICKETING_SYSTEM_CELL_COMMENTS_RETRIEVE_UPDATE_DELETE.replace("%CELL_COMMENT_ID%", data.id);
  const apiResult: any = await APIManger.delete(url,data);
  return apiResult;
};
export const deleteMonthEndCellComment = async (data: any): Promise<any> => {
  let url = CELL_COMMENTS_RETRIEVE_UPDATE_DELETE.replace("%CELL_COMMENT_ID%", data.id);
  const apiResult: any = await APIManger.delete(url,data);
  return apiResult;
};

export const createTicketingSystemCellComment = async (data:any): Promise<any> => {
  let url = TICKETING_SYSTEM_CELL_COMMENTS_CREATE
  const apiResult: any = await APIManger.post(url,data);
  return apiResult;
};
export const createMonthEndCellComment = async (data:any): Promise<any> => {
  let url = CELL_COMMENTS_CREATE
  const apiResult: any = await APIManger.post(url,data);
  return apiResult;
};




