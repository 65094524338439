import { ChangeEvent, Fragment, useContext, useEffect, useState } from 'react';
import { OptionTypeBase } from 'react-select';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import { initialErrors, initialState } from './initialObjects';
import { AuthContext } from '../../../../contexts/AuthContext';
import { uppercaseWords } from '../../../../utils/helpers';
import { editAddUserDisplayForm, editAddUserExpandedForm, editAddUserFormFilter } from '../../../../config/accountSettingsConfigs';
import SelectComponent from '../../../Common/SelectComponent';
import AlertDialog from '../../../Common/AlertDialog';
import { ManageUsers, ManageUsersDialogType, UserRole } from '../../../../types/ManageUsers';
import { FilterItem, IFilterOptionItem } from '../../../../types/CompanyFilters';
import { UserListConext } from '../../../../contexts/ManageUsers/UserListConext';

import CustomSwitch from '../../../Common/CustomSwitch';
import './UsersDialog.css';
import CloseConfirmDialog from '../../../HeadCountModule/TableControls/EmployeeDialog/CloseConfirmDialog';
import { DataConext } from '../../../../contexts/ManageUsers/DataConext';
import { fontWeight } from '@mui/system';

const userRoles: IFilterOptionItem[] = [
    {
        value: '0',
        label: 'Admin'
    },
    {
        value: '1',
        label: 'User'
    },
    {
        value: '2',
        label: 'Mighty Digits Admin'
    }
]
const userOnly: IFilterOptionItem[] = [
    {
        value: '1',
        label: 'User'
    }
]

export default function UsersDialog() {
    const { selectedCompany, companyFilter } = useContext(AuthContext);
    const { userListDisplay } = useContext(DataConext);
    initialState['company'] = selectedCompany?.id



    const {
        onClickSelectedItem,
        applyAddUserItem,
        applyUpdateUserItem,
        openDeleteDialoge,
        closeUserDialog,
        manageUsersDialogType,
        userDialogIsOpenByType,
    } = useContext(UserListConext);

    const isInviteDialog: boolean = manageUsersDialogType === ManageUsersDialogType.INVITE;

    const [selectedUserItem, setSelectedUserItem] = useState<ManageUsers | any>(isInviteDialog ? initialState : onClickSelectedItem);
    const [selectedUserItemErrors, setSelectedUserItemErrors] = useState(initialErrors);
    const [closeConfirmDialogOpen, setCloseConfirmDialogOpen] = useState(false);

    const [selectedUserRole, setSelectedUserRole] = useState<OptionTypeBase>(isInviteDialog ? userListDisplay.find(user => user.current_user)?.role === UserRole.Admin ? userOnly[0] : userRoles[0] : userRoles.filter((role) => role.value === onClickSelectedItem!['role'].toString())[0])
    /** Add User logic start */

    const validateUserObject = (): typeof selectedUserItemErrors => {
        let errors = { ...initialErrors };
        if (selectedUserItem.role === UserRole.User) {
            if (!selectedUserItem.embeded_views_access && !selectedUserItem.headcount_access)
                errors = { ...errors, embeded_views_access: true, headcount_access: true };
            if (selectedUserItem.embeded_views_access && selectedUserItem.divisions.length === 0 && !selectedUserItem["Divisions_select_all"])
                errors = { ...errors, divisions: true };
            if (selectedUserItem.headcount_access && ((selectedUserItem.departments.length === 0 && !selectedUserItem["Departments_select_all"])
                && (selectedUserItem.locations.length === 0 && !selectedUserItem["Locations_select_all"])))
                errors = { ...errors, departments: true, locations: true };
        }
        if (manageUsersDialogType === ManageUsersDialogType.INVITE) {
            if (!selectedUserItem.email || !selectedUserItem.email.match(/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/)) {
                errors = { ...errors, email: true }
            }
        }
        return errors;
    }
    const onAddClick = async () => {
        var errors = validateUserObject()
        if (!Object.values(errors).includes(true)) {
            setSelectedUserItem(initialState);
            closeUserDialog();
            applyAddUserItem(selectedUserItem);
        }
    }

    const onAddDialogClose = () => {
        if (selectedUserItem !== initialState) {
            setCloseConfirmDialogOpen(true);
        } else {
            closeUserDialog();
        }
    }

    /** Add User logic end */


    /** Shared logic start */
    useEffect(() => {
        if (isInviteDialog) {
            const value = userListDisplay.find(user => user.current_user)?.role === UserRole.Admin ? 1 : 0
            initialState['role'] = value
        }
        
    },[])


    const onItemsChange = (e: React.ChangeEvent<HTMLInputElement>, itemKey: string) => {
        const newItem = { ...selectedUserItem };
        var value = e.currentTarget.value
        newItem[itemKey] = value === "" ? null : value;
        setSelectedUserItem(newItem as any);
    };

    function onMultiSelectChange(selectedValues, action) {
        const newItem = { ...selectedUserItem };
        newItem[action.name] = selectedValues.map((item: OptionTypeBase) => { return { id: item.value, name: item.label } })
        setSelectedUserItem(newItem as any);
    }

    const onSelectChange = (value: OptionTypeBase, itemKey: string) => {
        const newItem = { ...selectedUserItem };
        if (itemKey === 'role') {
            setSelectedUserRole(value);
            newItem[itemKey] = Number(value.value);
            if (newItem[itemKey] === UserRole.Admin) {
                newItem['headcount_access'] = true
                newItem['embeded_views_access'] = true
                newItem['divisions'] = []
                newItem['locations'] = []
                newItem['departments'] = []
            }
        } else {
            if (!value)
                newItem[itemKey] = value;
            else
                newItem[itemKey] = value.value;
        }
        setSelectedUserItem(newItem as any);
    };

    useEffect(() => {
        setSelectedUserItem(isInviteDialog ? initialState : onClickSelectedItem);
    }, [onClickSelectedItem, isInviteDialog]);

    useEffect(() => {
        // setCurrentUserManager(userListDisplay.find(user => user.current_user))
        // initialState['role'] = currentUserManager?.role === UserRole.Admin ? 1 : 0
        initialState["Divisions_select_all"] = true
        
    }, [selectedCompany])

    /** Shared logic end */


    const getDisabledSelects = (item: ManageUsers, itemkey: string): boolean => {
        if (editAddUserExpandedForm.includes(itemkey)) {
            switch (itemkey) {
                case 'divisions':
                    return !item['embeded_views_access']
                case 'departments':
                case 'locations':
                    return !item['headcount_access']
                default:
                    return true
            }
        }
        return true
    }



    function updateUserItem() {
        applyUpdateUserItem(selectedUserItem)
    }


    /** Dialog texts and actions start */
    let dialogTitle = 'Invite new users';
    let dialogCloseButtonAction = () => onAddDialogClose();
    let dialogLeftButtonText = 'Cancel';
    let dialogLeftButtonAction = () => onAddDialogClose();
    let dialogRightButtonText = 'Invite';
    let dialogRightButtonAction = () => onAddClick();

    if (manageUsersDialogType === ManageUsersDialogType.EDIT) {
        dialogTitle = 'Edit user';
        dialogCloseButtonAction = () => closeUserDialog();
        dialogLeftButtonText = 'Delete';
        dialogLeftButtonAction = () => openDeleteDialoge();
        dialogRightButtonText = 'Update';
        dialogRightButtonAction = async () => updateUserItem();
    }
    /** Dialog texts and actions end */

    if (!userDialogIsOpenByType[manageUsersDialogType]) return <></>;
    if (!selectedUserItem) return <></>;
    return (
        <AlertDialog
            dialogType="users-add-form"
            open={userDialogIsOpenByType[manageUsersDialogType]}
            onClose={dialogCloseButtonAction}
            title={<span><h3 style={{ fontWeight: 700 }}>{dialogTitle}</h3></span>}
            dialogActions={
                <>
                    {dialogLeftButtonText !== '' &&
                        <button className="btn--tertiary btn--M"
                            onClick={() => dialogLeftButtonAction()}>
                            <span>{dialogLeftButtonText}</span>
                        </button>
                    }
                    {manageUsersDialogType === ManageUsersDialogType.EDIT &&
                        <div className={'notify-input'}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Checkbox
                                    onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                        const newItem = { ...selectedUserItem };
                                        newItem['send_email'] = checked;
                                        setSelectedUserItem(newItem as any);
                                    }}
                                    checked={selectedUserItem["send_email"]}
                                    sx={{ marginLeft: 0.5, zIndex: 0, '& > svg': { width: 20, height: 20 } }} />
                                <label htmlFor="send_email">Notify User</label>
                            </div>
                        </div>
                    }
                    <button
                        className={(Object.values(validateUserObject()).includes(true)) ? "btn--primary--disabled btn--M" : "btn--primary btn--M"}
                        onClick={() => {
                            var form_errors = validateUserObject()
                            if ((Object.values(form_errors).includes(true)))
                                setSelectedUserItemErrors(form_errors)
                            else
                                dialogRightButtonAction()
                        }}
                        autoFocus
                    >
                        <span>{dialogRightButtonText}</span>
                    </button>
                </>
            }
        >
            <div className="userAddTable">
                {editAddUserDisplayForm.map((item) => {
                    const itemName = uppercaseWords(item);
                    return (
                        <Fragment key={item}>
                            <div className="userAddContainerParent">
                                <div className="userAddContainer">
                                    {item === 'email' && (
                                        <>
                                            <label htmlFor={item}>{itemName}</label>
                                            <input
                                                type={item}
                                                id={item}
                                                name={item}
                                                disabled={!isInviteDialog}
                                                placeholder={itemName}
                                                value={selectedUserItem.email}
                                                onChange={(e) => onItemsChange(e, item)}
                                            />
                                        </>
                                    )}

                                    {item === 'role' && (
                                        <>
                                            <label htmlFor={item}>{itemName}</label>
                                            <SelectComponent
                                                name={item}
                                                options={userListDisplay.find(user => user.current_user)?.role === UserRole.Admin  ? userOnly : userRoles}
                                                onChange={(value, _action) =>
                                                    onSelectChange(value, item)
                                                }
                                                isLoading={false}
                                                internalLabel={false}
                                                isSearchable={false}
                                                value={selectedUserRole}
                                            />
                                        </>
                                    )}
                                </div>
                                {selectedUserItemErrors[item] && <span className="errorMsg">{itemName} is required</span>}
                            </div>
                        </Fragment>
                    );
                })}
                <Collapse
                    in={Number(selectedUserRole.value) === UserRole.User}
                    sx={{
                        '& .MuiCollapse-wrapper': {
                            margin: 0,
                            marginBottom: 0,
                            bgcolor: '#FFFFFF',
                            height: "auto",
                        }
                    }}
                >
                    {editAddUserExpandedForm.map((item) => {
                        const itemName = uppercaseWords(item);
                        return (
                            <div key={item} className="userAddContainerParent">
                                <div className="userAddContainer">
                                    {editAddUserFormFilter.includes(item) ? (
                                        <div className='userAddContainer__IndentedContainer'>
                                            <label htmlFor={item}>{itemName}</label>
                                            <FormControlLabel
                                                label={selectedUserItem[`${itemName}_select_all`] ? `All ${itemName} selected` : `Select All ${itemName}`}
                                                control={
                                                <Checkbox
                                                inputProps={{ 'aria-label': itemName }}
                                                checked={(selectedUserItem[`${itemName}_select_all`])}
                                                disabled={(getDisabledSelects(selectedUserItem, item))}
                                                onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                                    const newItem = { ...selectedUserItem };
                                                    newItem[`${itemName}_select_all`] = checked;
                                                    newItem[itemName.toLowerCase()] = []                                         
                                                    setSelectedUserItem(newItem as any);
                                                }}
                                                sx={{
                                                    padding: 0,
                                                    color: '#C0C0CF',
                                                    '&.Mui-checked': {
                                                        color: '#85B440',
                                                    },
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 24
                                                    }
                                                }}
                                            />}
                                                sx={{
                                                    mt: 1.25,
                                                    color: selectedUserItem['is_active'] ? '#85B440' : '#C0C0CF',
                                                    '& .MuiFormControlLabel-label': {
                                                        ml: 1,
                                                        fontSize: 12,
                                                    }
                                                }}
                                            />

                                            <SelectComponent
                                                name={item}
                                                options={companyFilter.lookUpOption(item.slice(0, -1), true)}
                                                onChange={onMultiSelectChange}
                                                isLoading={false}
                                                internalLabel={false}
                                                isSearchable={false}
                                                isMulti
                                                allowSelectAll
                                                isClearable
                                                isDisabled={getDisabledSelects(selectedUserItem, item) || selectedUserItem[`${itemName}_select_all`]}
                                                value={selectedUserItem[item].map((item: FilterItem) => { return { value: item.id, label: item.name } })}

                                            />

                                        </div>
                                    ) : (
                                        <FormGroup>
                                            <FormControlLabel
                                                sx={{
                                                    marginTop: '20px !important',
                                                    fontWeight: 700
                                                }}
                                                control={
                                                    <Checkbox
                                                        inputProps={{ 'aria-label': itemName }}
                                                        checked={selectedUserItem[item]}
                                                        onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                                            const newItem = { ...selectedUserItem };
                                                            newItem[item] = checked;
                                                            if (!checked) {
                                                                if (item === "embeded_views_access")
                                                                    newItem['divisions'] = []
                                                                if (item === "headcount_access") {
                                                                    newItem['locations'] = []
                                                                    newItem['departments'] = []
                                                                }
                                                            }
                                                            setSelectedUserItem(newItem as any);
                                                        }}
                                                        sx={{
                                                            padding: 0,
                                                            color: '#C0C0CF',
                                                            '&.Mui-checked': {
                                                                color: '#85B440',
                                                            },
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 24
                                                            }
                                                        }}
                                                    />
                                                }
                                                label={item === 'embeded_views_access' ? 'Financial Statements Access' : itemName} />
                                        </FormGroup>
                                    )}
                                </div>

                                {selectedUserItemErrors[item] && <span className="errorMsg">{itemName} is required</span>}
                            </div>
                        )
                    })}
                </Collapse>
                {!isInviteDialog && (
                    <FormControlLabel
                        label={selectedUserItem['is_active'] ? 'Active' : 'Deactive'}
                        control={<CustomSwitch
                            checked={selectedUserItem['is_active']}
                            onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                const newItem = { ...selectedUserItem };
                                newItem['is_active'] = checked;
                                setSelectedUserItem(newItem as any);
                            }}
                        />}
                        sx={{
                            mt: 1.25,
                            color: selectedUserItem['is_active'] ? '#85B440' : '#C0C0CF',
                            '& .MuiFormControlLabel-label': {
                                ml: 1,
                                fontSize: 12,
                            }
                        }}
                    />
                )
                }
            </div>
            {closeConfirmDialogOpen && (
                <CloseConfirmDialog
                    open={closeConfirmDialogOpen}
                    onCancel={() => setCloseConfirmDialogOpen(false)}
                    onYes={() => closeUserDialog()}
                />
            )}

        </AlertDialog>
    );
}