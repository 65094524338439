import  { useState } from "react";
import { Link } from "react-router-dom";
import { Checkbox, FormControlLabel } from '@mui/material';
import MyGoogleLogin from "../GoogleAuthentication/GoogleLogin";
import CircularLoader from "../../Common/CircularLoader";
import './Register.css';

const initialState = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    confirm_password: '',
    privacyAndTermsCheck: false,
}

const initialErrors = {
    first_name: false,
    last_name: false,
    email: false,
    password: false,
    confirm_password: false,
    privacyAndTermsCheck: false,
}

export default function Register() {
    const [state, setState] = useState(initialState);
    const [isLoading, setLoading] = useState(false);
    const [errors, setErrors] = useState(initialErrors);
    const [privacyTermsChecked, setPrivacyTermsChecked] = useState(false);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>, item: string) => {
        setErrors(initialErrors);
        setState((prev) => ({ ...prev, [item]: e.target.value }))
    };

    const validateForm = async () => {
        if (!state.first_name || state.first_name.length <= 0) {
            setErrors((prev) => ({ ...prev, first_name: true }));
        }
        if (!state.last_name || state.last_name.length <= 0) {
            setErrors((prev) => ({ ...prev, last_name: true }));
        }
        if (!state.password || state.password.length <= 0) {
            setErrors((prev) => ({ ...prev, password: true }));
        }
        if ((!state.confirm_password || state.confirm_password.length <= 0) && state.password !== state.confirm_password) {
            setErrors((prev) => ({ ...prev, confirm_password: true }));
        }
        if (state.email.length <= 0) {
            setErrors((prev) => ({ ...prev, email: true }));
        }
        if (!state.email.match(/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/)) {
            setErrors((prev) => ({ ...prev, email: true }));
        }
        if (!privacyTermsChecked) {
            setErrors((prev) => ({ ...prev, privacyAndTermsCheck: true }));
        }
        if (!Object.values(errors).includes(true)) {
            // await login(state.email, state.password)
        }
    }

    return (
        <div className="Registration__Container">
            <h1>Register</h1>
            <div className="Registration__Container__EntryContainer">
                <label htmlFor="first_name">First name</label>
                <input
                    name="first_name"
                    type="text"
                    id="first_name"
                    placeholder="First Name"
                    aria-describedby="First Name"
                    value={state.first_name}
                    onChange={(e) => onChange(e, 'first_name')}
                    required
                    autoComplete="off"
                    maxLength={100}
                />
            </div>
            {(errors.first_name) && <span className="formErrors">First name can't be empty</span>}

            <div className="Registration__Container__EntryContainer">
                <label htmlFor="last_name">Last name</label>
                <input
                    name="last_name"
                    type="text"
                    id="last_name"
                    placeholder="Last name"
                    aria-describedby="Last Name"
                    value={state.last_name}
                    onChange={(e) => onChange(e, 'last_name')}
                    required
                    autoComplete="off"
                    maxLength={100}
                />
            </div>
            {(errors.last_name) && <span className="formErrors">Last name can't be empty</span>}

            <div className="Registration__Container__EntryContainer">
                <label htmlFor="email">Email</label>
                <input
                    name="email"
                    type="text"
                    placeholder="Email"
                    id="email"
                    aria-describedby="Email"
                    value={state.email}
                    onChange={(e) => onChange(e, 'email')}
                    required
                    autoComplete="off"
                    maxLength={100}
                />
            </div>
            {(errors.email) && <span className="formErrors">Please enter a valid email</span>}

            <div className="Registration__Container__EntryContainer">
                <label htmlFor="password">Password</label>
                <input
                    name="password"
                    type="password"
                    placeholder="Password"
                    id="password"
                    aria-describedby="Password"
                    value={state.password}
                    onChange={(e) => onChange(e, 'password')}
                    required
                    autoComplete="off"
                    maxLength={100}
                />
            </div>
            {(errors.password) && <span className="formErrors">Please enter a password</span>}

            <div className="Registration__Container__EntryContainer">
                <label htmlFor="confirm_password">Confirm Password</label>
                <input
                    name="confirm_password"
                    type="password"
                    placeholder="Confirm Password"
                    id="confirm_password"
                    aria-describedby="Confirm Password"
                    value={state.confirm_password}
                    onChange={(e) => onChange(e, 'confirm_password')}
                    required
                    autoComplete="off"
                    maxLength={100}
                />
            </div>
            {(errors.confirm_password) && <span className="formErrors">Passwords don't match</span>}

            <div className="TermsNServices__Container" style={{ display: 'inline-flex', justifyContent: 'flex-start', width: '100%', flexDirection: 'column' }}>
                <FormControlLabel
                    control={<Checkbox required color="secondary" sx={{ marginLeft: -1.5, '& > svg': { width: 20, height: 20 } }} checked={privacyTermsChecked} />}
                    label="I agree to the Mighty Digits Terms of Use and Privacy Policy."
                    onChange={(e, checked) => { setPrivacyTermsChecked(checked); setErrors((prev) => ({ ...prev, privacyAndTermsCheck: checked ? false : true })) }}
                    disabled={isLoading}
                />
                {errors.privacyAndTermsCheck && <span style={{ color: 'red', fontWeight: 500 }}>Please aceept the terms and privacy</span>}
            </div>

            <div className="Auth__BottomSection">
                {
                    isLoading ?
                        <CircularLoader color={"#007bff"} size={40} customClassName="custom-loader" />
                        :
                        <button disabled={isLoading} className="btn--primary btn--L btn--fullWidth" onClick={validateForm}> Register </button>
                }

                <div className="m-4">
                    Have an account? <Link to="/">Login</Link>
                </div>
                <div className="Auth__GoogleBtn">
                    {/* <MyGoogleLogin GoogleLogin={GoogleRegister}/> */}
                </div>

            </div>
        </div>

    )
}