import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiFormGroup: {
      styleOverrides: {
        root: {
          flexDirection: "row",
        },
      },
    },
  },
});

interface RadioButtonsGroupProps {
  Title?: string;
  valuesList: valueDict[];
  selectedValue:string;
  onValueChange: (newValue: string) => void;
}

interface valueDict {
  value: string;
  label: string;
}

export default function RadioButtonsGroup({ Title, valuesList, selectedValue, onValueChange }: RadioButtonsGroupProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
    console.log(newValue);
    onValueChange(newValue);
  };
  return (
    <ThemeProvider theme={theme}>
      <FormControl>
        
        {/* {Title && <FormLabel id="demo-radio-buttons-group-label">{Title}</FormLabel>} */}
        <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group" onChange={handleChange} value={selectedValue}>
          {valuesList.map((value) => {
            return <FormControlLabel key={value.value} data-test={Title} value={value.value} control={<Radio />} label={value.label} />;
          })}
        </RadioGroup>
      </FormControl>
    </ThemeProvider>
  );
}
