import { createContext, useState, useContext } from "react";
import { ISelectedFilters } from '../../types/CompanyFilters';
import { HeadCountSelectedFilters } from "../../types/HeadCountSelectedFilters";
import { AuthContext } from "../AuthContext";

export interface ReportFilterContextProps {
    selectedFilters: HeadCountSelectedFilters;
    setSelectedFilters: (selectedFilters: HeadCountSelectedFilters) => void;
    updateFilters: (key: string, selectedFilters: ISelectedFilters[]) => void;
    removeAllFilters: () => void;
}

export const ReportFilterContext = createContext<ReportFilterContextProps>(null as any);

export const ReportFilterContextProvider = (props) => {
    const { selectedCompany } = useContext(AuthContext)
    const [selectedFilters, setSelectedFilters] = useState<HeadCountSelectedFilters>(new HeadCountSelectedFilters(selectedCompany?.offset));

    const removeAllFilters = () => {

        setSelectedFilters(selectedFilters.resetFilters())   
    }
    const updateFilters = (key: string, selectedOptions: ISelectedFilters[]) => {
        setSelectedFilters(selectedFilters.updateSelectedOptions(key, selectedOptions))
    }
    return (
        <ReportFilterContext.Provider value={{
            selectedFilters,
            setSelectedFilters,
            updateFilters,
            removeAllFilters,
        }}>
            {props.children}
        </ReportFilterContext.Provider>
    )
}