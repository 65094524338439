import { Box } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { ManageUsers, UserStatus } from '../../../../types/ManageUsers';

export default function renderStatus(params: GridRenderCellParams) {
    const user_row = params.row as ManageUsers
    return (
        <Box
            component="span"
            sx={{
                color: '#32324D',
                fontSize: "14px",
                padding: 1,
                borderRadius: 1.4,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '::before': {
                    content: '""',
                    width: 8,
                    height: 8,
                    bgcolor: user_row.status === UserStatus.ACTIVE ? '#5CB176' : user_row.status === UserStatus.DEACTIVE ? "#EE5E52" : '#C0C0CF',
                    borderRadius: 4,
                    marginRight: '0.75rem'
                }
            }}>
            {user_row.statusStr}
        </Box>
    )
}