import React from "react";
import MDIcons from "../../../../Common/MDIcons";
import InsertNewLink from "./InsertNewLink";

const NoLinks = ({ linkType }) => {
  return (
    <div className="LinksTable__Container__NoLinks">
      <MDIcons title="EmptyDocuments" />
      <div>No files yet!</div>
      <div>Add your first file</div>
      <InsertNewLink linkType={linkType} inTable={false} />
    </div>
  );
};

export default NoLinks;
