import { AccountTypeEnum, SectionNameEnum } from "../../types/FinancialStatements.ts/Sections";

export enum CalculationDirectionEnum {
    prevMinusCurrent="Asset",
    currentMinusPrev = "Not Asset"
}

export const AccountTypeToCFSection = {
    [AccountTypeEnum.Other_Current_Asset]: {
        section: SectionNameEnum.Δ_in_Current_Assets,
        type:CalculationDirectionEnum.prevMinusCurrent
    },
    [AccountTypeEnum.Bank]: {
        section: SectionNameEnum.NO_SECTION,
        type:CalculationDirectionEnum.currentMinusPrev
    },
    [AccountTypeEnum.Fixed_Asset]: {
        section: SectionNameEnum.Investing_Activities,
        type:CalculationDirectionEnum.prevMinusCurrent
    },
    [AccountTypeEnum.Other_Asset]: {
        section: SectionNameEnum.Δ_in_Current_Assets,
        type:CalculationDirectionEnum.prevMinusCurrent
    },
    [AccountTypeEnum.Credit_Card]: {
        section: SectionNameEnum.Δ_in_Current_Liabilities,
        type:CalculationDirectionEnum.currentMinusPrev
    },
    [AccountTypeEnum.Other_Current_Liability]: {
        section: SectionNameEnum.Δ_in_Current_Liabilities,
        type:CalculationDirectionEnum.currentMinusPrev
    },
    [AccountTypeEnum.Equity]: {
        section: SectionNameEnum.Financing_Activities,
        type:CalculationDirectionEnum.currentMinusPrev
    },
    [AccountTypeEnum.Accounts_Payable]: {
        section: SectionNameEnum.Δ_in_Current_Liabilities,
        type:CalculationDirectionEnum.currentMinusPrev
    },
    [AccountTypeEnum.Accounts_Receivable]: {
        section: SectionNameEnum.Δ_in_Current_Assets,
        type:CalculationDirectionEnum.prevMinusCurrent
    },
    [AccountTypeEnum.Long_Term_Liability]: {
        section: SectionNameEnum.Financing_Activities,
        type:CalculationDirectionEnum.currentMinusPrev
    }
}