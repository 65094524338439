import { QuickBookConnectingStatus } from "../Company";

export enum NotificationTypes {
    NONE = "NONE",
    COMPANY_HEADCOUNT_UPDATED = "COMPANY_HEADCOUNT_UPDATED",
    CONNECTING_QB_STATUS_UPDATED = "CONNECTING_QB_STATUS_UPDATED",
    LOGIC_APP_RUN_UPDATE = "LOGIC_APP_RUN_UPDATE",
    EXPORT_QBO_FILE = "ExportQBOAsFile",
    IMPORT_QBO_DATA = "ImportQBOData"
}
export enum LogicAppStatus {
    INITIATED = 0,
    STARTED = 1,
    PENDING = 2,
    FAILED = 3,
    SUCCESSED = 4,
    TIMEOUT = 5
}

export enum LogicAppTypes {
    SYNC_HEADCOUNT = 0,
    EXPORT_QBO_TO_EXCEL = "ExportQBOToExcel",
    AGED_REPORTS = "AGED_REPORTS",
    AGED_REPORTS_DETAIL = "AGED_REPORTS_DRILL_DETAIL",
    PRINTING_PDF = 4,
    SYNC_QB_COMPANY = 5,
}


export interface NotificationData {
    sender: string;
}
export interface UpdateQBStatus {
    sender: string;
    status: QuickBookConnectingStatus;
}
export interface LogicAppRunUpdateMessage {
    [key: string]: any;
    sender: string;
    type: LogicAppTypes;
    status: LogicAppStatus;
    progress?: number;
    fail_reason?: string;
    data?:any
}
export class Notification {
    type: NotificationTypes = NotificationTypes.NONE;
    data: NotificationData | LogicAppRunUpdateMessage | UpdateQBStatus = { sender: "-1" };

    constructor(obj: any) {
        if (obj) {
            Object.keys(obj).forEach(key => {
                if (key in this)
                    this[key] = obj[key]
            })
        }
    }

}

