import React, { useContext, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { PayrollContextProvider } from "../../contexts/TicketingSystem/PayrollContext";
import { MilestoneEmailContextProvider } from "../../contexts/TicketingSystem/MilestoneEmailContext";
import { UserRole } from "../../types/ManageUsers";
import AdminViewComponent from "./PayrollViews/PayrollAdminView/AdminViewComponent";
import ClientViewComponent from "./PayrollViews/PayrollClientView/ClientViewComponent";
import { MilestoneContextProvider } from "../../contexts/TicketingSystem/MilestoneContext";
import { useMatch } from "react-router-dom";
import { TaskServicesContextProvider } from "../../contexts/Task/TaskServicesContext";
import { TaskAuthContextProvider } from "../../contexts/Task/TaskAuthContext";
import { SubTasksContextProvider } from "../../contexts/TicketingSystem/SubTasksContext";
import PayrollListView from "./PayrollViews/PayrollAdminView/PayrollListView";
import { MilestoneLinksContextProvider } from "../../contexts/TicketingSystem/MilestoneLinksContext";

const PayrollViews = () => {
  const { selectedCompany, userProfile } = useContext(AuthContext);
  const [displayListFlag, setDisplayListFlag] = useState<boolean>(true);
  let match = useMatch("/:company_name/tasks/payroll/:milestone_gid/action/:action");
  return (
    <TaskAuthContextProvider>
      <TaskServicesContextProvider>
        <MilestoneContextProvider>
          <MilestoneEmailContextProvider>
            <PayrollContextProvider>
              {selectedCompany?.role !== UserRole.MDADMIN && userProfile.is_superuser === false ? (
                <ClientViewComponent milestone_gid={match?.params["milestone_gid"]} setDisplayListFlag={setDisplayListFlag} immediateAction={match?.params["action"] || null} />
              ) : (
                <>
                  {displayListFlag ? (
                    <PayrollListView setDisplayListFlag={setDisplayListFlag} />
                  ) : (
                    <SubTasksContextProvider>
                      <MilestoneLinksContextProvider>
                      
                      <AdminViewComponent setDisplayListFlag={setDisplayListFlag}/>
                      
                      </MilestoneLinksContextProvider>
                    </SubTasksContextProvider>
                  )}
                </>
              )}
            </PayrollContextProvider>
          </MilestoneEmailContextProvider>
        </MilestoneContextProvider>
      </TaskServicesContextProvider>
    </TaskAuthContextProvider>
  );
};

export default PayrollViews;
