import React, { useContext, useEffect } from "react";
import { ReactComponent as QBLogo } from "../../../../../static/img/Onboarding/QuickBooks-Online-Logo.svg";
import { connectCompanyQBO } from "../../../../../APIFunctions/qbo_auth";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { SocketContext } from "../../../../../contexts/SocketContext";
import CompanyQBOConnect from "../../../../Common/QBOConnect";

const QBONotConnected = () => {

  return (
    <div className="QBONotConnected__Container">
      <CompanyQBOConnect />
    </div>
  );
};

export default QBONotConnected;
