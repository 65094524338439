import React from 'react'
import { DataGrid } from '@mui/x-data-grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const ContextMenuMUIDataGrid = ({contextMenu,setContextMenu,handleCreateComment}) => {

      const handleClose = () => {
        setContextMenu(null);
      };

      const openCommentDialog = () => {
        handleCreateComment.mutate()
        handleClose()
      }


    
  return (
    <Menu
        
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        componentsProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault();
              handleClose();
            },
          },
        }}
      >
        <MenuItem id="add-cell-comment" onClick={openCommentDialog}>Add Comment</MenuItem>
      </Menu>
  )
}

export default ContextMenuMUIDataGrid