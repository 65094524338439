import React, { useContext } from "react";
import SharepointExplorerGetFileLink from "../../../../Common/SharepointExplorer/SharepointExplorerGetFileLink";
import LinksTablsRow from "./LinksTablsRow";
import InsertNewLink from "./InsertNewLink";
import { useLinksList } from "./Hooks";
import { MilestoneEmailContext } from "../../../../../contexts/TicketingSystem/MilestoneEmailContext";
import NoLinks from "./NoLinks";

const Presentation = ({ linkType }) => {
  const linksListQuery = useLinksList(linkType);
  return (
    <div className="LinksTable__Container">
      {linksListQuery?.data?.length > 0 ? (
        <>
          <div className="LinksTable__Row--inTable">
            <div className="LinksTable__Row__Left">File Name</div>
            <div className="LinksTable__Row__Right">Location</div>
          </div>
          <InsertNewLink linkType={linkType} inTable={true}/>
        </>
      ) : (
        <NoLinks linkType={linkType}/>
      )}
      {linksListQuery?.data?.map((link) => {
          return <LinksTablsRow key={link.id} link={link} />;
        })}
    </div>
  );
};

export default Presentation;
