import React, { useContext, useEffect, useState } from "react";

import "./style.css";

import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import { IconButton, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { createStyles, makeStyles } from "@mui/styles";

import { ReactComponent as BarGraphs } from "../../../../../static/img/bar_graphs.svg";
import { ReactComponent as ArrowRoundedUpIcon } from "../../../../../static/img/arrow_rounded_up_icon.svg";
import DataGridComponent from "./TableComponent/DataGridComponent";
import { LoaderStatus } from "../../../../../types/Loaders";
import ReportLoading from "../../../../Common/ReportLoading";
import { appDateStringToDate, dateObjToAppStringWithTime, getMonthShort } from "../../../../../utils/DateFunctions";
import SyncQBData from "../../../../Common/SyncQBData";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { DataContext } from "../../../../../contexts/FinancialReport/DataContext";
import { FinancialStatementFilterContext } from "../../../../../contexts/FinancialReport/FilterContext";
import { PeriodDisplayOptions } from "../../../../../contexts/HeadCount/ReportDataContext";
import SelectComponent from "../../../../Common/SelectComponent";
import GeneralHeader from "../../../../Common/GeneralHeader";
import { MonthEndCloseContext } from "../../../../../contexts/TicketingSystem/MonthEndCloseContext";
import { useLocation, useSearchParams } from "react-router-dom";
import FinancialReportFilters from "./FinancialReportFilters/FinancialReportFilters";
import { useQueryClient } from "@tanstack/react-query";
import { useCellComments } from "../../../hooks/useComments";
import { getBSTransactionsData, getPLTransactionsData } from "./helpers/transactions";
import { reportDict } from "./helpers/dictionary";
import { reportTabIndexEnum } from "./types";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& .MuiSelect-select": {
        padding: 0,
        paddingLeft: "10px",
        height: "15px",
        fontSize: "12px ",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        height: "36px",
        marginTop: "2px",
      },
    },
  })
);

const FinancialReport = () => {
  const location = useLocation();
  const { selectedCompany } = useContext(AuthContext);

  const queryClient = useQueryClient();

  const { onSelectComment, reportTabIndex, setReportTabIndex, transactionDetailFlag, setTransactionDetailFlag } = useContext(MonthEndCloseContext);

  const {
    financialReport_LoaderStatus,
    setDrilledCellParams,
    setDrillViewFlag,
    drillViewFlag,
    drilledTransactionsData,
    financialReport_LoaderValue,
    selectedPeriodName,
    setSelectedPeriodName,
    startDate,
    endDate,
    currentSyncInformation,
  } = useContext(DataContext);
  let [searchParams, setSearchParams] = useSearchParams();
  const { isModalOpen, toggleModal } = useContext(FinancialStatementFilterContext);
  const [zoomLevel, setZoomLevel] = useState<string>("5");
  const [selectedCell, setSelectedCell] = useState<any>();

  // useQuery
  const cellComments = useCellComments();

  const classes = useStyles();

  const handleChange = (event: SelectChangeEvent) => {
    setZoomLevel(event.target.value);
  };

  const onChangeTabIndex = (index) => {
    setReportTabIndex(index);
    setSearchParams((prevParams) => {
      return new URLSearchParams({
        ...Object.fromEntries(prevParams.entries()),
        ...{ report_type: index.toString(), drill_transactions: "false" },
      });
    });
  };

  const onExitTransactionsDrilling = () => {
    setDrillViewFlag(false);
    setDrilledCellParams(null);
    setSearchParams((prevParams) => {
      return new URLSearchParams({
        ...Object.fromEntries(prevParams.entries()),
        ...{
          drill_transactions: "false",
        },
      });
    });
  };

  const selectCommentBySelectedCell = () => {
    if (selectedCell) {
      const target_row_id = selectedCell?.row?.id;
      const target_col_id = selectedCell?.field;
      let filteredComments;
      let targetComment;
      filteredComments = cellComments.data?.filter((x) => x.account_id === target_row_id);
      if (filteredComments.length === 0) {
        filteredComments = cellComments.data?.filter((x) => x.transaction === target_row_id.toString());
      }
      if (filteredComments?.length > 0) {
        targetComment = filteredComments.find((x) => x.column_id === target_col_id);
      }
      if (targetComment) {
        onSelectComment(targetComment);
      }
    }
  };

  const onChangeViewBy = (value) => {
    setSelectedPeriodName(value);
    setSearchParams((prevParams) => {
      return new URLSearchParams({
        ...Object.fromEntries(prevParams.entries()),
        ...{ view_by: value.value },
      });
    });
  };

  

  const prefetchTransactionsReports = () => {
    // The results of this query will be cached like a normal query
    queryClient.prefetchQuery({
      queryKey: ["PLTransactionsReportData", startDate.toString(), endDate.toString()],
      queryFn: () => getPLTransactionsData(selectedCompany, startDate, endDate),
    });
    queryClient.prefetchQuery({
      queryKey: ["BSTransactionsReportData", startDate.toString(), endDate.toString()],
      queryFn: () => getBSTransactionsData(selectedCompany, startDate, endDate),
    });
  };

  useEffect(() => {
    prefetchTransactionsReports();
  }, []);

  useEffect(() => {
    selectCommentBySelectedCell();
  }, [selectedCell]);

  return (
    <>
      <GeneralHeader title={reportDict[reportTabIndex]} renderDivisions={false}>
        {(reportTabIndex === reportTabIndexEnum.INCOMESTATEMENTSUMMARY || reportTabIndex === reportTabIndexEnum.BALANCESHEETSUMMARY) && (
          <SelectComponent
            name={selectedPeriodName.label}
            options={PeriodDisplayOptions}
            onChange={onChangeViewBy}
            isLoading={false}
            internalLabel={true}
            isSearchable={false}
            value={selectedPeriodName}
          />
        )}
        <span className={`btn--tertiary btn--S flex-row--M ${isModalOpen ? "section_opened" : ""}`}>
          <IconButton
            className="flex-row--S"
            color="inherit"
            aria-label="open filters"
            size="large"
            sx={{ p: 0, fontWeight: 700, fontSize: 12, color: "#32324D !important" }}
            // disabled={hc_LoaderStatus !== LoaderStatus.Rendered}
            onClick={toggleModal}
            disableRipple
            disableFocusRipple
            disableTouchRipple
          >
            <BarGraphs style={{ marginLeft: 0 }} />
            Filters
            <ArrowRoundedUpIcon style={{ transform: isModalOpen ? "rotate(180deg)" : "" }} />
          </IconButton>
          {/* {selectedFiltersNumber > 0 && (
                    <>
                      <span className='vertical-divider'>| </span>
                      <ClearButton resetData={resetAllFilters} isSectionOpen={isModalOpen} />
                    </>
                  )} */}
        </span>
        {selectedCompany?.is_connected === true && <SyncQBData />}
        {/* todo: add filters here */}
      </GeneralHeader>
      <FinancialReportFilters />
      <div>
        <Tabs className="tabs-bi" id="financial-reports-tabs" selectedIndex={reportTabIndex} onSelect={onChangeTabIndex}>
          <TabList>
            <Tab className="tabs-bi__link settings_tab_link">Income Statement</Tab>
            <Tab className="tabs-bi__link settings_tab_link">Balance Sheet</Tab>
            {/* <Tab className="tabs-bi__link settings_tab_link">Cash Flows</Tab> */}
            <Tab className="tabs-bi__link settings_tab_link">Income Statement Transactions</Tab>
            <Tab className="tabs-bi__link settings_tab_link">Balance Sheet Transactions</Tab>
          </TabList>
          <TabPanel />
          <TabPanel />
          {/* <TabPanel /> */}
          <TabPanel />
          <TabPanel />
        </Tabs>
      </div>
      <div className={"FinancialReport__Container"} id="FinancialReport__Container">
        {!transactionDetailFlag && (
          <div className="FinancialReport__Container_ReportHeader">
            <div className="FinancialReport__Container_ReportHeader__Title">
              {drillViewFlag && (reportTabIndex === reportTabIndexEnum.INCOMESTATEMENTSUMMARY || reportTabIndex === reportTabIndexEnum.BALANCESHEETSUMMARY) && (
                <button onClick={onExitTransactionsDrilling}>
                  <ArrowBackIosNewIcon />
                  {"     "} <span>Back to summary report</span>
                </button>
              )}
              <h2>
                {selectedCompany?.companyName} {!drillViewFlag ? reportDict[reportTabIndex] : "Transactions Details"}
              </h2>
            </div>

            <div>
              {!drillViewFlag ? (
                getMonthShort(startDate) !== getMonthShort(endDate) ? (
                  `${getMonthShort(startDate)} - ${getMonthShort(endDate)}`
                ) : (
                  `${getMonthShort(startDate)}`
                )
              ) : (
                <></>
              )}
            </div>

            <div>
              {drillViewFlag ? (
                getMonthShort(appDateStringToDate(searchParams.get("drill_start_date") || "")) !== getMonthShort(appDateStringToDate(searchParams.get("drill_end_date") || "")) ? (
                  `${getMonthShort(appDateStringToDate(searchParams.get("drill_start_date") || ""))} 
            - ${getMonthShort(appDateStringToDate(searchParams.get("drill_end_date") || ""))}`
                ) : (
                  `${getMonthShort(appDateStringToDate(searchParams.get("drill_start_date") || ""))}`
                )
              ) : (
                <></>
              )}
            </div>

            <div className="FinancialReport__Container_ReportHeader__Controls">
              <div className="FinancialReport__Container_ReportHeader__Controls__Zoom">
                <span>Zoom in/out</span>
                <Select
                  className={classes.root}
                  value={zoomLevel}
                  onChange={handleChange}
                  // displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value={"1"}>20%</MenuItem>
                  <MenuItem value={"2"}>40%</MenuItem>
                  <MenuItem value={"3"}>60%</MenuItem>
                  <MenuItem value={"4"}>80%</MenuItem>
                  <MenuItem value={"5"}>100%</MenuItem>
                  <MenuItem value={"6"}>120%</MenuItem>
                  <MenuItem value={"7"}>140%</MenuItem>
                  <MenuItem value={"8"}>160%</MenuItem>
                  <MenuItem value={"9"}>180%</MenuItem>
                  <MenuItem value={"10"}>200%</MenuItem>
                </Select>
              </div>

              {currentSyncInformation.data && (
                <div className="FinancialReport__Container_ReportHeader__Controls__SyncVersion">
                  <span>QBO Sync Date</span>
                  <span>{dateObjToAppStringWithTime(new Date(currentSyncInformation.data?.last_successful_run), "/")}</span>
                </div>
              )}
            </div>
          </div>
        )}
        {/* {financialReport_LoaderStatus !== LoaderStatus.Rendered && <ReportLoading loaderValue={financialReport_LoaderValue} reportStatus={financialReport_LoaderStatus} />} */}
        {/* {financialReport_LoaderStatus === LoaderStatus.Rendered && ( */}
          <>
            <DataGridComponent zoomLevel={zoomLevel} />
          </>
        {/* )} */}
      </div>
    </>
  );
};

export default FinancialReport;
