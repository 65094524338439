import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteAsanaTask, retrieveAsanaAttachments, retrieveAsanaSubTask, retrieveAsanaTask, retrieveAsanaTasks, retrieveAsanaTaskStories } from "../../APIFunctions/Asana";
import { getAllCompanyIcons, getProjectSubtasksData } from "../../APIFunctions/ticketing_system";
import { AsanaTask } from "../../types/AsanaTask";
import { AuthContext } from "../AuthContext";
import { TaskAuthContext } from "./TaskAuthContext";
import { TaskServicesContext } from "./TaskServicesContext";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
export interface TaskTableContextProps {
  projectTasksQuery: UseQueryResult<any,unknown>;
  selectedMilestone: any;
  setSelectedMilestone: (selectedMilestone: any) => void;
  taskDetail: any;
  setTaskDetail: (task: any) => any;
  openTaskDetail: boolean;
  setOpenTaskDetail: (open: boolean) => any;
  onTaskSelection: (task: any) => any;
  tasks: any[];
  setTasks: (tasks: any[]) => void;
  taskDetailChanged: boolean;
  setTaskDetailChanged: (taskChanged: boolean) => void;
  timeAtLastTaskUpdate: number;
  setTimeAtLastTaskUpdate: (number) => void;
  retrieveTaskDetail: (addNew: boolean, taskGID?) => void;
  deleteTaskDetail: () => void;
  deleteDialogeOpen: boolean;
  setDeleteDialogeOpen: (deleteDialogeOpen: boolean) => void;
  closeDeleteDialoge: (apply: boolean) => void;
  openDeleteDialoge: () => void;
  parents: AsanaTask[];
  setParents: (parents: []) => void;
  retrieveParent: (gid: string) => void;
  retrieveAttachments: () => void;
  attachments: any[];
  retrieveSubTasks: (gid?) => void;
  setAttachments: (attachments: any[]) => void;
  subTasks: any[];
  setSubTasks: (subTasks: any[]) => void;
  retrieveStories: () => any;
  taskStories: any[];
  setTaskStories: (taskStories: any[]) => void;
  taskCardLoading: boolean;
  setTaskCardLoading: (taskCardLoading: boolean) => void;
  openTask: (gid: string) => void;
  subTasksObj: any;
  setSubTasksObj: (subTasksObj: any) => void;
  // openMilestone: (milestone: any, adminProjectType: string) => void;
  selectedCompanyID: any;
  companyIcons: any[];
}

export const TaskTableContext = createContext<TaskTableContextProps>(null as any);

export const TaskTableContextProvider = (props) => {
  const navigate = useNavigate();
  const { selectedCompany } = useContext(AuthContext);
  const { accessToken } = useContext(TaskAuthContext);
  const { projectID, tasksCategory } = useContext(TaskServicesContext);
  const [taskDetail, setTaskDetail] = useState(new AsanaTask());
  const [tasks, setTasks] = useState<AsanaTask[]>([]);
  const [subTasksObj, setSubTasksObj] = useState<any>({});
  const [openTaskDetail, setOpenTaskDetail] = useState<boolean>(false);
  const [taskDetailChanged, setTaskDetailChanged] = useState<boolean>(true);
  const [timeAtLastTaskUpdate, setTimeAtLastTaskUpdate] = useState<number>(new Date().getTime());
  const [deleteDialogeOpen, setDeleteDialogeOpen] = useState(false);
  const [parents, setParents] = useState<any[]>([]);
  const [taskStories, setTaskStories] = useState<any[]>([]);
  const [subTasks, setSubTasks] = useState<any[]>([]);
  const [attachments, setAttachments] = useState<any[]>([]);
  const [taskCardLoading, setTaskCardLoading] = useState<boolean>(false);
  const [selectedMilestone, setSelectedMilestone] = useState<any>();
  const [selectedCompanyID, setSelectedCompanyID] = useState<any>();
  const [companyIcons, setCompanyIcons] = useState<any[]>([]);

  const retrieveParent = async (parentGID: string) => {
    const response = await retrieveAsanaTask(parentGID);
    if (response.data) {
      const parent = response.data;
      const newParentsArray: any = parents.map((x) => (x.gid === parent.gid ? parent : x));
      setParents(newParentsArray);
    } else if (response.response?.status === 401) {
      setTimeout(() => {
        retrieveParent(parentGID);
      }, 1000);
    }
  };

  const projectTasksQuery = useQuery({
    queryKey: ["tasks", projectID],
    queryFn: () => retrieveAsanaTasks(projectID),
    staleTime: Infinity,
    enabled: !!projectID,
  });

  const setTaskDetailFromTasks = async () => {
    if (projectID) {
      const currentTaskIndex = projectTasksQuery.data?.findIndex((task) => task.gid === taskDetail?.gid);
      if (currentTaskIndex !== -1 && openTaskDetail && currentTaskIndex) {
        setTaskDetail(projectTasksQuery.data[currentTaskIndex]);
      }
    }
  };

  const retrieveSubTasksDict = async () => {
    const response = await getProjectSubtasksData(projectID);
    if (response) {
      setSubTasksObj(response.subtasks_data);
    }
  };

  const retrieveTaskDetail = async (addNew: boolean = false, taskGid?: string) => {
    var response;
    if (taskGid) {
      response = await retrieveAsanaTask(taskGid);
    } else {
      if (taskDetail?.gid) {
        response = await retrieveAsanaTask(taskDetail.gid);
      }
    }
    const today = new Date();
    if (response.data) {
      const task = response.data;
      if (addNew) {
        const newTask = {
          ...task,
          due_on: today.toISOString().substring(0, 10),
        };
        const newTasksArray = [...tasks];
        newTasksArray.push(task);
        setTasks(newTasksArray);
        setTaskDetail(newTask);
      } else {
        setTaskDetail(task);
      }

      setOpenTaskDetail(true);
    } else if (response.response?.status === 401) {
      setTimeout(() => {
        retrieveTaskDetail();
      }, 1000);
    }
  };

  const retrieveAttachments = async () => {
    setTaskCardLoading(true);
    const response = await retrieveAsanaAttachments(taskDetail?.gid);
    if (response.data) {
      setAttachments(response.data);
      setTaskCardLoading(false);
    } else if (response.response?.status === 401) {
      setTimeout(() => {
        retrieveAttachments();
      }, 1000);
    }
  };

  const retrieveSubTasks = async () => {
    setTaskCardLoading(true);

    const response = await retrieveAsanaSubTask(taskDetail?.gid);

    if (response.data) {
      const subTasks = response.data;
      setSubTasks(subTasks);
      setTaskCardLoading(false);
    } else if (response.response?.status === 401) {
      setTimeout(() => {
        retrieveSubTasks();
      }, 1000);
    }
  };

  const openTask = (gid) => {
    const taskIndex = tasks?.findIndex((x) => x.gid === gid);
    if (taskIndex && taskIndex !== -1) {
      setTaskDetail(tasks[taskIndex]);
      setParents([]);
    }
    

    if (tasksCategory) {
      navigate(`/${selectedCompany?.getURL()}/tasks/project/${tasksCategory}/${gid}`);
    } else {
      navigate(`/${selectedCompany?.getURL()}/tasks/project/user/${gid}`);
    }

    setOpenTaskDetail(true);
  };

  const retrieveStories = async () => {
    setTaskCardLoading(true);
    const subTypes_NotAllowed = ["attachment_added", "added_to_project", "section_changed", "duplicated"];
    const response = await retrieveAsanaTaskStories(taskDetail?.gid);
    if (response.data) {
      const stories = response.data;
      const storiesArray = stories.filter((story) => story.resource_subtype !== "duplicated" && !subTypes_NotAllowed.includes(story.resource_subtype));
      setTaskStories(storiesArray);
      setTaskCardLoading(false);
    } else if (response.response?.status === 401) {
      setTimeout(() => {
        retrieveStories();
      }, 1000);
    }
  };

  const closeDeleteDialoge = (apply: boolean) => {
    setDeleteDialogeOpen(false);
    if (apply) {
      deleteTaskDetail();
    }
    setDeleteDialogeOpen(false);
  };

  const openDeleteDialoge = () => {
    setDeleteDialogeOpen(true);
  };
  const deleteTaskDetail = () => {
    setOpenTaskDetail(false);
    deleteAsanaTask(taskDetail.gid);

    const newTasksArray = tasks?.filter((x) => x.gid !== taskDetail.gid);
    setTasks(newTasksArray);
    setTaskDetail(new AsanaTask());
  };
  const onTaskSelection = (task) => {
    setTaskDetail(task);
    setOpenTaskDetail(true);
  };

  const retrieveCompayIcons = async () => {
    if (selectedCompany?.companyName === "Mighty Digits Admin") {
      const response = await getAllCompanyIcons();
      setCompanyIcons(response);
    }
  };

  useEffect(() => {
    setTaskDetailFromTasks();

    if (tasksCategory !== "user" && tasksCategory !== undefined) {
      retrieveSubTasksDict();
      retrieveCompayIcons();
    }
  }, [projectID, tasksCategory, accessToken]);

  return (
    <TaskTableContext.Provider
      value={{
        projectTasksQuery,
        taskDetail,
        setTaskDetail,
        openTaskDetail,
        setOpenTaskDetail,
        onTaskSelection,
        tasks,
        subTasksObj,
        setSubTasksObj,
        setTasks,
        taskDetailChanged,
        setTaskDetailChanged,
        timeAtLastTaskUpdate,
        setTimeAtLastTaskUpdate,
        retrieveTaskDetail,
        deleteTaskDetail,
        deleteDialogeOpen,
        setDeleteDialogeOpen,
        closeDeleteDialoge,
        openDeleteDialoge,
        setParents,
        parents,
        retrieveAttachments,
        attachments,
        subTasks,
        taskCardLoading,
        setTaskCardLoading,
        setSubTasks,
        retrieveParent,
        retrieveSubTasks,
        setAttachments,
        retrieveStories,
        taskStories,
        setTaskStories,
        openTask,
        setSelectedMilestone,
        selectedMilestone,
        selectedCompanyID,
        companyIcons,
      }}
    >
      {props.children}
    </TaskTableContext.Provider>
  );
};
