import { useContext, useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { AuthContext } from '../../contexts/AuthContext';
import { ModuleType } from '../../types/CompanyModules';
import { ExcelEmbedTab } from '../../types/ExcelEmbedTab';
import ExcelEmbedViewer from './ExcelEmbedViewer';
import NoDataFound from '../Common/NoDataFound';
import { ModuleContext } from '../../contexts/ModuleContext';
import { LoaderStatus } from '../../types/Loaders';
import ReportLoading from '../Common/ReportLoading';
import { EmbedExcelLoadingContext } from '../../contexts/EmbedExcelLoadingContext';

export default function EmbedModuleViewer() {
    const { selectedCompany, companyFilter } = useContext(AuthContext);
    const { selectedDivision, appModuleType, appModule } = useContext(ModuleContext);
    const { reportStatus, setLoaderStatus, loaderValue } = useContext(EmbedExcelLoadingContext);
    const [excelEmbedTabs, setEmbedModuleViewer] = useState<ExcelEmbedTab[] | null>(null);
    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        if (appModuleType === ModuleType.EMBED_EXCEL) {
            setLoaderStatus(LoaderStatus.Loading)
        }
    }, [appModuleType, setLoaderStatus, appModule]);

    useEffect(() => {
        if (selectedCompany && selectedDivision) {
            setEmbedModuleViewer(companyFilter.getEmbedTabs(appModule, selectedDivision))
        }
        else
            setEmbedModuleViewer(null);
    }, [appModule, selectedCompany,companyFilter, selectedDivision]);

    const isLoading = reportStatus !== LoaderStatus.Rendered;
    if (!excelEmbedTabs)
        return <NoDataFound />
    if (excelEmbedTabs.length === 1)
        return (
            <>
                {isLoading && <ReportLoading loaderValue={loaderValue} reportStatus={reportStatus} />}
                <ExcelEmbedViewer visible isShort={false} excel_embed_iframe={excelEmbedTabs[0].excel_embed_iframe} />
            </>
        )
    return (
        <>
            {isLoading && <ReportLoading loaderValue={loaderValue} reportStatus={reportStatus} />}
            <Tabs style={{ display: isLoading ? 'none' : 'flex' }} className="tabs-bi" selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
                <TabList>
                    {excelEmbedTabs.map((tab) => (
                        <Tab key={tab.tab_name} className="tabs-bi__link">{tab.tab_name}</Tab>
                    ))}
                </TabList>
                {excelEmbedTabs.map((tab) => (
                    <TabPanel key={tab.tab_name} />
                ))}
            </Tabs>
            {excelEmbedTabs.map((tab, index) => (
                <ExcelEmbedViewer key={tab.tab_name} isShort visible={tabIndex === index && !isLoading} excel_embed_iframe={tab.excel_embed_iframe} />
            ))}
        </>
    )
}