import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import './ReportLoading.css';
import 'react-circular-progressbar/dist/styles.css';
import ProgressProvider from './ProgressProvider';
import { getLoaderStatus } from '../../../utils/helpers';
import { LoaderStatus } from '../../../types/Loaders';

interface ReportLoadingProps {
    loaderValue: number;
    reportStatus: LoaderStatus|string;
}

export default function ReportLoading(props: ReportLoadingProps) {
    const { loaderValue, reportStatus } = props;

    return (
        <div className={"report-loader"}>
            <div style={{ height: "100%", display:"flex",flexDirection:"column", alignItems: "center", justifyContent: "center" }}>
                <div className='loader_container'>
                    <ProgressProvider valueStart={0} valueEnd={loaderValue}>
                        {(value) =>
                            <CircularProgressbar value={value} text={`${value}%`} strokeWidth={2}
                                styles={buildStyles({
                                    pathTransitionDuration: 0.5,
                                    pathColor: 'var(--Primary600)',
                                    textColor: 'var(--Primary600)',
                                })}
                            />}
                    </ProgressProvider>
                </div>
                <p className='first_text'>{getLoaderStatus(reportStatus)}</p>
            </div>
        </div>
    )
}