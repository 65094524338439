import React, { useContext, useState } from 'react'
import { OnboardingContext } from '../../contexts/Onboarding/OnboardingContext';
import { MuiChipsInput } from 'mui-chips-input';


const FormPage4 = () => {

    const { onBack, onSubmit, onboardingInputs, setOnboardingInputs, done}  = useContext(OnboardingContext);

    const handlegroupingsChange = (newChips) => {
        var newArray = {...onboardingInputs, "pl_groupings":newChips}
        setOnboardingInputs(newArray)
      }

    return (
        <div className='Onboarding_page4'>
            <h1>How would your Profit & Loss to be summarized?<br/>
        <span>This will be how your profit and loss gets shown in a summarized view.</span></h1>
            <div className="bodyContainer">
                <div className="bodyContainer__InputSection">
                    <div className='onboarding__block--y'>
                        <h2>P&L Groupings</h2>
                        <MuiChipsInput value={onboardingInputs["pl_groupings"]}
                        placeholder={"Type a P&L grouping and then press 'enter'"}
                        id={'pl-input'}
                        onChange={handlegroupingsChange}
                        />
                    </div>
                </div>
                <div className="footer--btns">
                    <button className='btn--M btn--tertiary' onClick={onBack}>
                        Previous
                    </button>
                    <input
                        className='btn--primary btn--M '
                        type="submit"
                        value={done ? "Done" : "Next"}
                        onClick={onSubmit}
                        disabled={onboardingInputs["pl_groupings"].length === 0}
                    />
                </div>
            </div>
        </div>
    )
}

export default FormPage4