import APIManger from "../APIManger"
import { COMPANY_CONNECT_QBO, COMPANY_DISCONNECT_QBO } from "./apiConstants"

export const connectCompanyQBO = async (company_id: number): Promise<any> => {
    let url = COMPANY_CONNECT_QBO.replace("%COMPANY_ID%", company_id.toString())
    let apiResult: any = await APIManger.post(url)
    return apiResult
}
export const disconnectCompanyQBO = async (company_id: number): Promise<any> => {
    let url = COMPANY_DISCONNECT_QBO.replace("%COMPANY_ID%", company_id.toString())
    let apiResult: any = await APIManger.post(url)
    return apiResult
}
