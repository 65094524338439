import { useContext, useEffect, useMemo, useState } from "react";
import {
  DataGridPro,
  GridToolbarExport,
  GridCellParams,
  GridColDef,
  useGridApiContext,
  useGridSelector,
  GridRenderCellParams,
  gridFilteredDescendantCountLookupSelector,
  DataGridProProps,
  GRID_TREE_DATA_GROUPING_FIELD,
  MuiEvent,
  GridCallbackDetails,
  useGridApiRef,
  gridSortedRowIdsSelector,
  gridVisibleColumnDefinitionsSelector,
  GridEventListener,
} from "@mui/x-data-grid-pro";

import { createStyles, makeStyles } from "@mui/styles";
import "./tableComponent.css";

import { Box } from "@mui/system";
import { Button, ButtonProps, Theme } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { uppercaseAllWordLetters } from "../../../../../../utils/helpers";
import { DataContext } from "../../../../../../contexts/FinancialReport/DataContext";
import { FinancialStatementFilterContext } from "../../../../../../contexts/FinancialReport/FilterContext";
import { AuthContext } from "../../../../../../contexts/AuthContext";
import { convertMonthYearStringToDate, createDateWithOffsetFromDate, dateObjToAsanaString, endOfMonth, startOfMonth } from "../../../../../../utils/DateFunctions";
import { ReportTypeEnum } from "../../../../../../types/FinancialStatements.ts/interfaces";
import NoTableData from "../../../../../Common/NoTableData";
import { MonthEndCloseContext } from "../../../../../../contexts/TicketingSystem/MonthEndCloseContext";
import TransactionsListComponent from "../../../../../Transactions";
import ContextMenuMUIDataGrid from "../../../../../Common/ContextMenuMUIDataGrid";
import {  useCellComments } from "../../../../hooks/useComments";
import { reportTabIndexEnum } from "../types";

export interface StyleProps {
  zoomLevel: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    root: {
      height: "calc(68vh - 250px)",
      width: "40rem",
      "& .AccountsNames": {
        justifyContent: "flex-start !important",
      },
      "& .ERROR": {
        background: "#F5C0B8",
      },
      "& .FIRST_PROJECTION_COL": {
        borderRight: "1px var(--Neutral500) dashed !important",
      },
      "& .HIGHLIGHT_SELECTED": {
        background: "#FFF500 !important",
      },
      "& .HIGHLIGHT": {
        background: "rgb(255,253,204) !important",
      },
      "& .period__header": {
        flexDirection: "column",
      },

      "& .MuiDataGrid-root": {
        backgroundColor: "#fff",
        border: "none",
        height: "100%",

        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: "bold",
          fontSize: ({ zoomLevel }) =>
            zoomLevel === "1"
              ? 6
              : zoomLevel === "2"
              ? 7
              : zoomLevel === "3"
              ? 8
              : zoomLevel === "4"
              ? 9
              : zoomLevel === "5"
              ? 10
              : zoomLevel === "6"
              ? 11
              : zoomLevel === "7"
              ? 12
              : zoomLevel === "8"
              ? 13
              : zoomLevel === "9"
              ? 14
              : 15,
          lineHeight: "16px",
          color: "#32324D",
        },
        "& .MuiDataGrid-virtualScroller": {
          marginTop: ({ zoomLevel }) =>
            (zoomLevel === "1"
              ? 35
              : zoomLevel === "2"
              ? 40
              : zoomLevel === "3"
              ? 45
              : zoomLevel === "4"
              ? 47
              : zoomLevel === "5"
              ? 60
              : zoomLevel === "6"
              ? 60
              : zoomLevel === "7"
              ? 60
              : zoomLevel === "8"
              ? 52
              : zoomLevel === "9"
              ? 56
              : 60) + "px !important",
        },
        "& .MuiDataGrid-iconSeparator": {
          height: "25px",
          borderLeft: "none",
        },
        "& .MuiDataGrid-columnHeader--sortable": {
          "& .MuiDataGrid-iconButtonContainer": {
            visibility: "visible",
            width: "auto",
            display: "none",
          },
        },
        "& .MuiDataGrid-columnHeader": {
          flexDirection: "column",
          paddingLeft:"0rem",
          // justifyContent: "center",
          // alignItems:"center",
          paddingTop: ({ zoomLevel }) =>
          (zoomLevel === "1"
              ? 0
              : zoomLevel === "2"
              ? 8
              : zoomLevel === "3"
              ? 10
              : zoomLevel === "4"
              ? 10
              : zoomLevel === "5"
              ? 12
              : zoomLevel === "6"
              ? 13
              : zoomLevel === "7"
              ? 14
              : zoomLevel === "8"
              ? 15
              : zoomLevel === "9"
              ? 16
              : 17) + "px !important",
          // paddingRight: "0.1rem",
          // paddingLeft: "2rem",
          height: ({ zoomLevel }) =>
          (zoomLevel === "1"
              ? 15
              : zoomLevel === "2"
              ? 30
              : zoomLevel === "3"
              ? 35
              : zoomLevel === "4"
              ? 40
              : zoomLevel === "5"
              ? 40
              : zoomLevel === "6"
              ? 45
              : zoomLevel === "7"
              ? 50
              : zoomLevel === "8"
              ? 55
              : zoomLevel === "9"
              ? 60
              : 65) + "px !important",
        },
        "& .Header__Actuals::before, .Header__Projections::before": {
          display: "block",
          height:"auto",
          width: "100%",
          textAlign:"center",
          lineHeight:0,
          color: "var(--Neutral500)",
            fontSize: ({ zoomLevel }) =>
            zoomLevel === "1"
              ? 4
              : zoomLevel === "2"
              ? 5
              : zoomLevel === "3"
              ? 6
              : zoomLevel === "4"
              ? 7
              : zoomLevel === "5"
              ? 8
              : zoomLevel === "6"
              ? 9
              : zoomLevel === "7"
              ? 10
              : zoomLevel === "8"
              ? 11
              : zoomLevel === "9"
              ? 12
              : 13,
        },
        "& .Header__Projections::before": {
          display: "block",
          height:"auto",
          width: "100%",
          textAlign:"center",
          lineHeight:0,
          color: "var(--Neutral500)",
            fontSize: ({ zoomLevel }) =>
            zoomLevel === "1"
              ? 4
              : zoomLevel === "2"
              ? 5
              : zoomLevel === "3"
              ? 6
              : zoomLevel === "4"
              ? 7
              : zoomLevel === "5"
              ? 8
              : zoomLevel === "6"
              ? 9
              : zoomLevel === "7"
              ? 10
              : zoomLevel === "8"
              ? 11
              : zoomLevel === "9"
              ? 12
              : 13,
        },
        "& .MuiDataGrid-columnHeaders": {
          flexDirection: "column",
          justifyContent: "center",
          alignItems:"flex-start",
          borderTop: "1px solid #32324D",
          borderBottom: "1px solid #32324D",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          display:"flex",

          height: ({ zoomLevel }) =>
          (zoomLevel === "1"
              ? 31
              : zoomLevel === "2"
              ? 33
              : zoomLevel === "3"
              ? 38
              : zoomLevel === "4"
              ? 40
              : zoomLevel === "5"
              ? 45
              : zoomLevel === "6"
              ? 45
              : zoomLevel === "7"
              ? 50
              : zoomLevel === "8"
              ? 50
              : zoomLevel === "9"
              ? 55
              : 55) + "px !important",
          minHeight: "15px !important",
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            height: ({ zoomLevel }) =>
          (zoomLevel === "1"
              ? 20
              : zoomLevel === "2"
              ? 22
              : zoomLevel === "3"
              ? 27
              : zoomLevel === "4"
              ? 32
              : zoomLevel === "5"
              ? 37
              : zoomLevel === "6"
              ? 42
              : zoomLevel === "7"
              ? 45
              : zoomLevel === "8"
              ? 48
              : zoomLevel === "9"
              ? 52
              : 56) + "px !important",
            flexDirection: "row",
            justifyContent: "center",
          },
          "& .MuiDataGrid-columnHeaderDraggableContainer": {
            flexDirection: "row",
          },
        },
        "& .MuiDataGrid-pinnedColumnHeaders": {
          height: "24px",
          boxShadow: "none",
        },
        "& .MuiDataGrid-pinnedColumns": {
          boxShadow: "none",
        },
      },
      "& .MuiDataGrid-footerContainer": {
        display: "none",
      },
      "& .MuiDataGrid-row": {
        "& :hover": {
          cursor: "pointer",
          background: "#EAEAEF",
        },
        "&.Mui-selected": {
          backgroundColor: "#FFF!important",
          "& :hover": {
            backgroundColor: "#FFF !important",
          },
        },
      },
      "& .MuiDataGrid-cell": {
        display: "flex",
        color: "#666687",
        fontSize: ({ zoomLevel }) =>
          zoomLevel === "1"
            ? 7
            : zoomLevel === "2"
            ? 8
            : zoomLevel === "3"
            ? 9
            : zoomLevel === "4"
            ? 10
            : zoomLevel === "5"
            ? 11
            : zoomLevel === "6"
            ? 12
            : zoomLevel === "7"
            ? 13
            : zoomLevel === "8"
            ? 14
            : zoomLevel === "9"
            ? 15
            : 16,
        borderLeft: "none",
        borderBottom: "none",
        justifyContent: "flex-end",
        // alignItems: "flex-start",
        "& span": {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          fontSize: ({ zoomLevel }) =>
            zoomLevel === "1"
              ? 7
              : zoomLevel === "2"
              ? 8
              : zoomLevel === "3"
              ? 9
              : zoomLevel === "4"
              ? 10
              : zoomLevel === "5"
              ? 11
              : zoomLevel === "6"
              ? 13
              : zoomLevel === "7"
              ? 14
              : zoomLevel === "8"
              ? 16
              : zoomLevel === "9"
              ? 18
              : 20,
        },
      },
      "& .MuiCheckbox-root": {
        color: "#C0C0CF",
      },
      "& .MuiCheckbox-root >svg": {
        border: "0px solid #FFFFFF",
        height: "20px",
        width: "20px",
      },
      "& .MuiCheckbox-root.Mui-checked": {
        color: "#85b440 !important",
      },
    },
  })
);

export const isNavigationKey = (key: string) => key === "Home" || key === "End" || key.indexOf("Arrow") === 0 || key.indexOf("Page") === 0 || key === " ";

const CustomGridTreeDataGroupingCell = (props: GridRenderCellParams) => {
  const { id, field, row, rowNode } = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector);
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleKeyDown: ButtonProps["onKeyDown"] = (event) => {
    if (event.key === " ") {
      event.stopPropagation();
    }
    if (isNavigationKey(event.key) && !event.shiftKey) {
      apiRef.current.publishEvent("cellNavigationKeyDown", props, event);
    }
  };

  const handleClick: ButtonProps["onClick"] = (event) => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };
  return (
    <Box sx={{ ml: rowNode.depth * 1 }}>
      <div>
        {filteredDescendantCount > 0 && rowNode.children !== null ? (
          <Button
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            tabIndex={-1}
            sx={{
              width: "50rem",
              height: "3rem",
              color: "#666687",
              justifyContent: "left !important",
              fontWeight: 700,
              marginLeft: "-2rem",
              "&:hover": {
                background: "rgba(133, 180, 64, 0.1)",
              },
            }}
          >
            <span>
              {!rowNode.childrenExpanded ? <ArrowRightIcon /> : <ArrowDropDownIcon />} {row.name}
            </span>
          </Button>
        ) : row.bold === true ? (
          <Box sx={{ fontWeight: 700 }}>{uppercaseAllWordLetters(row.name)}</Box>
        ) : (
          <Box sx={{ fontWeight: 400 }}>{row.name}</Box>
        )}
      </div>
    </Box>
  );
};


export default function DataGridComponent({ zoomLevel }) {
  let [searchParams, setSearchParams] = useSearchParams();
  let location = useLocation();
  const navigate = useNavigate();
  const { reportDataModel, columnsHeaders,onSetDrilledCellParams,drillViewFlag } = useContext(DataContext);
  const { isModalOpen } = useContext(FinancialStatementFilterContext);
  const { selectedCompany } = useContext(AuthContext);
  const {  commentsTabIndex, summaryCoordinates,
     transactionsCoordinates, reportTabIndex, showCommentsPan, selectedCell, setSelectedCell, createCommentMutation, transactionDetailFlag } =
    useContext(MonthEndCloseContext);
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const cellComments = useCellComments();

  const selectCellForComment = async (params: GridCellParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
    setSelectedCell(params);
  };

  const styleProps = {
    zoomLevel: zoomLevel,
  };

  let lastDateOfActuals: Date = selectedCompany ? new Date(selectedCompany?.last_date_of_actuals) : new Date();

  const classes = useStyles(styleProps);

  const [displayData, setDisplayData] = useState<any>([]);

  const filterNonZeroes = (data) => {
    const tempDisplayData: any = [];
    for (var i = 0; i < data.length; i++) {
      let accountBalance = 0;
      let totalAccountBalance = 0;
      for (var j = 0; j < columnsHeaders.length; j++) {
        if (data[i].accountBalance[columnsHeaders[j]]) {
          accountBalance += data[i].accountBalance[columnsHeaders[j]].value;
        }
        if (data[i].totalAccountBalance[columnsHeaders[j]]) {
          totalAccountBalance += data[i].totalAccountBalance[columnsHeaders[j]].value;
        }
      }
      if (accountBalance !== 0 || totalAccountBalance !== 0 || data[i].bold === true) {
        tempDisplayData.push(data[i]);
      }
    }
    setDisplayData(tempDisplayData);
  };

  // const memoizedCommentHighlightFlag = useMemo(() => commentHighlightFlag(rowID, colID),[cellComments, rowID, colID])

  const commentHighlightFlag = (rowID, colID) => {
    let comments;
    if (rowID && colID) {
      if (commentsTabIndex === 0) {
        comments = cellComments?.data?.filter(x => x.asana_task.completed === false)
      } else {
        comments = cellComments?.data?.filter(x => x.asana_task.completed === true)
      }
      const cellCommentExists = comments.filter((x) => x.account_id === rowID && x.column_id === colID);
      if (cellCommentExists?.length > 0) {
        return "HIGHLIGHT";
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    if (reportDataModel) {
      if (reportTabIndex === reportTabIndexEnum.INCOMESTATEMENTSUMMARY) {
        filterNonZeroes(reportDataModel.getData(ReportTypeEnum.Income_Statement));
      } else if (reportTabIndex === reportTabIndexEnum.BALANCESHEETSUMMARY) {
        var x = reportDataModel.getData(ReportTypeEnum.Balance_Sheet);
        filterNonZeroes(x);
      }
      // } else if (reportTabIndex === 2) {
      //   // filterNonZeroes(reportDataModel.getData(ReportTypeEnum.Cash_Flows));
      //   filterNonZeroes([]);
      // }
    }
    setSelectedCell(null);
  }, [reportDataModel, reportTabIndex, columnsHeaders]);

  const formatNumber = (value: number, dollarSign: boolean) => {
    if (value < 0) {
      if (dollarSign) {
        return `$(${commify(Math.round(Math.abs(value)))})`;
      } else {
        return `(${commify(Math.round(Math.abs(value)))})`;
      }
    } else if (value > 0) {
      if (dollarSign) {
        return `$${commify(Math.round(value))}`;
      } else {
        return `${commify(Math.round(value))}`;
      }
    } else {
      return "-";
    }
  };

  function commify(n) {
    // var parts = n.toString().split(".");
    const thousands = /\B(?=(\d{3})+(?!\d))/g;

    return n.toString().replace(thousands, ",");
  }

  const getTreeDataPath: DataGridProProps["getTreeDataPath"] = (row) => row.hierarchy;

  const groupingColDef: DataGridProProps["groupingColDef"] = {
    headerName: "  ",
    width:
      zoomLevel === "1"
        ? 200
        : zoomLevel === "2"
        ? 220
        : zoomLevel === "3"
        ? 230
        : zoomLevel === "4"
        ? 270
        : zoomLevel === "5"
        ? 290
        : zoomLevel === "6"
        ? 310
        : zoomLevel === "7"
        ? 320
        : zoomLevel === "8"
        ? 330
        : zoomLevel === "9"
        ? 365
        : 400,
    cellClassName: (params: GridCellParams<any>) => "AccountsNames",
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
  };
  const getRowTotal = (values) => {
    var accountBalance = 0;
    var totalAccountBalance = 0;
    for (var i = 0; i < columnsHeaders.length; i++) {
      if (values.accountBalance?.[columnsHeaders[i]]) {
        accountBalance += values.accountBalance[columnsHeaders[i]].value;
      }
    }
    for (i = 0; i < columnsHeaders.length; i++) {
      if (values.totalAccountBalance?.[columnsHeaders[i]]) {
        totalAccountBalance += values.totalAccountBalance[columnsHeaders[i]].value;
      }
    }
    return { accountBalance, totalAccountBalance };
  };
  const headCells: GridColDef[] = columnsHeaders.map((key) => {
    if (key === "Total") {
      return {
        field: key,
        headerName: uppercaseAllWordLetters(key),
        width:
          zoomLevel === "1"
            ? 40
            : zoomLevel === "2"
            ? 50
            : zoomLevel === "3"
            ? 60
            : zoomLevel === "4"
            ? 70
            : zoomLevel === "5"
            ? 80
            : zoomLevel === "6"
            ? 90
            : zoomLevel === "7"
            ? 100
            : zoomLevel === "8"
            ? 110
            : zoomLevel === "9"
            ? 120
            : 140,
        cellClassName: (params: GridCellParams<any>) => (params.row.totalAccountBalance?.[key]?.error && params.row.metaType !== "Section" ? "ERROR" : "NOERROR"),
        renderCell: (cellValues) => {
          const totalObj = getRowTotal(cellValues.row);

          return (
            <>
              {(cellValues.row.metaType === "Section" || cellValues.row.metaType === "First Section") && cellValues.rowNode.childrenExpanded ? (
                <Box></Box>
              ) : cellValues.rowNode.childrenExpanded && cellValues.row.metaType !== "Section" ? (
                <Box sx={{ fontWeight: 700 }}>{formatNumber(totalObj.accountBalance, true)}</Box>
              ) : cellValues.rowNode.children ? (
                <Box sx={{ fontWeight: 700 }}>{formatNumber(totalObj.totalAccountBalance, true)}</Box>
              ) : cellValues.row.bold === true ? (
                <Box sx={{ fontWeight: 700 }}>{formatNumber(totalObj.totalAccountBalance, true)}</Box>
              ) : (
                <Box sx={{ marginLeft: 1, marginTop: 0.4 }}>{formatNumber(totalObj.accountBalance, false)}</Box>
              )}
            </>
          );
        },
      };
    } else {
      
      const dateValue = convertMonthYearStringToDate(key);
      const lastActualsFlag = () => {
        const nextDateValue = createDateWithOffsetFromDate(dateValue, 0, 1, 0);
        if (nextDateValue > lastDateOfActuals && dateValue <= lastDateOfActuals) {
          return true;
        }
      };

      return {
        field: key,
        headerName: uppercaseAllWordLetters(key),
        headerClassName:
          lastActualsFlag() && dateValue <= lastDateOfActuals ? "Header__Actuals FIRST_PROJECTION_COL" : dateValue > lastDateOfActuals ? "Header__Projections" : "Header__Actuals",
        width:
          zoomLevel === "1"
            ? 40
            : zoomLevel === "2"
            ? 50
            : zoomLevel === "3"
            ? 60
            : zoomLevel === "4"
            ? 70
            : zoomLevel === "5"
            ? 80
            : zoomLevel === "6"
            ? 90
            : zoomLevel === "7"
            ? 100
            : zoomLevel === "8"
            ? 110
            : zoomLevel === "9"
            ? 120
            : 140,
        cellClassName: (params: GridCellParams<any>) => {
          const highlightFlag = showCommentsPan ? commentHighlightFlag(params?.row?.id, key) : "";

          return params.row.totalAccountBalance?.[key]?.error && params.row.metaType !== "Section" && lastActualsFlag()
            ? "FIRST_PROJECTION_COL ERROR"
            : params.row.totalAccountBalance?.[key]?.error && params.row.metaType !== "Section"
            ? "ERROR"
            : lastActualsFlag()
            ? "FIRST_PROJECTION_COL NOERROR"
            : highlightFlag === "HIGHLIGHT"
            ? "HIGHLIGHT"
            : "NOERROR";
        },

        renderCell: (cellValues) => {
          return (
            <>
              {(cellValues.row.metaType === "Section" || cellValues.row.metaType === "First Section") && cellValues.rowNode.childrenExpanded ? (
                <Box></Box>
              ) : cellValues.rowNode.childrenExpanded && cellValues.row.metaType !== "Section" ? (
                <Box sx={{ fontWeight: 700 }}>{formatNumber(cellValues.row.accountBalance?.[key]?.value, true)}</Box>
              ) : cellValues.rowNode.children ? (
                <Box sx={{ fontWeight: 700 }}>{formatNumber(cellValues.row.totalAccountBalance?.[key]?.value, true)}</Box>
              ) : cellValues.row.bold === true ? (
                <Box sx={{ fontWeight: 700 }}>{formatNumber(cellValues.row.totalAccountBalance?.[key]?.value, true)}</Box>
              ) : (
                <Box sx={{ marginLeft: 1, marginTop: 0.4 }}>{formatNumber(cellValues.row.accountBalance?.[key]?.value, false)}</Box>
              )}
            </>
          );
        },
      };
    }
  });

  const handleContextMenu = (event: MouseEvent) => {
    const target = event.target as HTMLElement
    let col_id = target.dataset.field
    if (col_id === undefined) {
      var parent = target.parentElement;
      col_id = parent?.dataset.field
    }
    const currentTarget = event.currentTarget as HTMLElement
    const row_id = currentTarget.dataset.id
    
    setSelectedCell({field:col_id,row:{id:row_id, name:`account with id ${row_id}`}})
    event.preventDefault();
    // setSelectedCell(Number(event.currentTarget.getAttribute('data-id')));
    setContextMenu(contextMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null);
  };

  const apiRef = useGridApiRef();

  const rowIndexLocator = (rowId) => {
    try {
      if (rowId) {
        return gridSortedRowIdsSelector(apiRef).findIndex((id) => id === rowId);
      } else {
        return -1
      }
    } catch {
      return -1
    }
  };

  const colIndexLocator = (colId) => {
    try {
      if (colId) {
        return gridVisibleColumnDefinitionsSelector(apiRef).findIndex((column) => column.field === colId);
      } else {
        return -1
      }
    } catch {
      return -1
    }
  };

  useEffect(() => {
    const { rowIndex, colIndex } = summaryCoordinates;
    if (rowIndex && colIndex) {
      const rowIndexFound = rowIndexLocator(rowIndex);
      const colIndexFound = colIndexLocator(colIndex);
      if (rowIndexFound !== -1 && colIndexFound !== -1) {
        apiRef.current.scrollToIndexes({ rowIndex: rowIndexFound, colIndex: colIndexFound });
        setTimeout(() => {
          apiRef.current.setCellFocus(rowIndex, colIndex);
        }, 500);
      }
      
    }
  }, [apiRef, summaryCoordinates]);

  return (
    <div className={isModalOpen ? "tableContainer__ReportModule": "tableContainer__ReportModule__Extend"}>
      <div style={{ display: "flex", height: isModalOpen ? "calc(100vh - 340px)" : "100%" }}>
        {reportTabIndex === reportTabIndexEnum.BALANCESHEETDETAIL || reportTabIndex === reportTabIndexEnum.INCOMESTATEMENTDETAIL || drillViewFlag ? (
          <TransactionsListComponent zoomLevel={zoomLevel} />
        ) : (
          <div style={{ flexGrow: 1, height: isModalOpen ? "calc(100vh - 540px)" : "100%"}} className={classes.root}>
            <DataGridPro
              treeData
              getTreeDataPath={getTreeDataPath}
              groupingColDef={groupingColDef}
              columns={headCells}
              rows={displayData}
              rowHeight={
                zoomLevel === "1"
                  ? 14
                  : zoomLevel === "2"
                  ? 16
                  : zoomLevel === "3"
                  ? 18
                  : zoomLevel === "4"
                  ? 20
                  : zoomLevel === "5"
                  ? 22
                  : zoomLevel === "6"
                  ? 25
                  : zoomLevel === "7"
                  ? 26
                  : zoomLevel === "8"
                  ? 27
                  : zoomLevel === "9"
                  ? 29
                  : 31
              }
              pinnedColumns={{
                left: [GRID_TREE_DATA_GROUPING_FIELD],
              }}
              // getRowId={params => params.row?.gid}
              getRowClassName={(params) => {
                return `DataGrid-marked-row--${params.row.metaType}`;
              }}
              // disableSelectionOnClick
              disableColumnFilter
              disableChildrenSorting
              showColumnRightBorder
              defaultGroupingExpansionDepth={-1}
              components={{
                NoRowsOverlay: NoTableData,
              }}
              onCellClick={(params, event, details) => selectCellForComment(params, event, details)}
              onCellDoubleClick={(params) => onSetDrilledCellParams(params)}
              apiRef={apiRef}
              componentsProps={{
                row: {
                  onContextMenu: handleContextMenu,
                  style: { cursor: "context-menu" },
                },
              }}
              
              
            />
            <ContextMenuMUIDataGrid contextMenu={contextMenu} setContextMenu={setContextMenu} handleCreateComment={createCommentMutation} />
          </div>
        )}
      </div>
    </div>
  );
}
