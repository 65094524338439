import React from "react";
import "./LinksTableStyle.css";
import Container from "./Container";
const LinksTableComponent = ({linkType }) => {
  
  return (
   <Container linkType={linkType} />
  );
};

export default LinksTableComponent;
