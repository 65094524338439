import { AccountTypeToCFSection, CalculationDirectionEnum } from "../../components/FinancialReportSchema/CashFlowMapping";
import { getMonthShort, getQuarter, getYear } from "../../utils/DateFunctions";
import { BalanceSheet, ProfiAndLoss } from "../API/FinancialReport";
import { BSReport } from "./classes";
import { AccountTypeEnum, AccountSubTypeEnum, FormulaNameEnum, SectionItemData, SectionNameEnum, sectionNameToAccountType } from "./Sections";

export enum ReportTypeEnum {
  Income_Statement = "Income Statement",
  Balance_Sheet = "Balance Sheet",
  Cash_Flows = "Cash Flows",
}

export interface IAccountsDictObj {
  id: string;
  name: string;
  parentRef: string;
  immediateChildren: any[];
  subAccount: boolean;
  accountType: AccountTypeEnum;
  accountSubType: AccountSubTypeEnum;
  added: false;
}

export interface IAccountsDict {
  [id: string]: IAccountsDictObj;
}

export interface IaccountBalanceArray {
  [period: string]: { value: number; error: boolean };
}

export interface IgridRow {
  name: string;
  id: string;
  bold: boolean;
  hierarchy: string[];
  metaType: string;
  accountBalance: IaccountBalanceArray;
  totalAccountBalance: IaccountBalanceArray;
}

export enum AccountClassificationType {
  Income_Statement = "Income Statement",
  Balance_Sheet = "Balance Sheet",
}

export enum AccountSubType {
  Retained_Earnings = "RetainedEarnings",
  Other = "Other",
}

export class Account {
  id = "";
  name: string = "";
  accountType: AccountTypeEnum = AccountTypeEnum.Income;
  accountSubType = AccountSubTypeEnum.No_SubType;
  datesArray: Date[] = [];
  priorDate: Date | undefined = new Date();
  accountListDict: IAccountsDict | null = {
    "0": {
      id: "",
      name: "",
      parentRef: "",
      immediateChildren: [],
      subAccount: false,
      accountType: AccountTypeEnum.Income,
      accountSubType: AccountSubTypeEnum.No_SubType,
      added: false,
    },
  };
  accountTypeDict: SectionItemData | null = null;
  pbcData: BalanceSheet | ProfiAndLoss | IgridRow[] = {};
  financialStatementReportType: ReportTypeEnum = ReportTypeEnum.Income_Statement;
  childAccounts: Account[] = [];
  childSections: Section[] = [];
  parentRef: string = "";
  hierarchy: string[] = [];
  section: SectionNameEnum | FormulaNameEnum = SectionNameEnum.NO_SECTION;
  headerRow: IgridRow = {
    name: "",
    id: "",
    bold: true,
    hierarchy: [],
    metaType: "",
    accountBalance: {},
    totalAccountBalance: {},
  };

  totalRow: IgridRow = {
    name: "",
    id: "",
    bold: true,
    hierarchy: [],
    metaType: "",
    accountBalance: {},
    totalAccountBalance: {},
  };
  childIds: string[] = [];
  isIgridRow(data: BalanceSheet | ProfiAndLoss | IgridRow[]): data is IgridRow[] {
    return (data as IgridRow[]).find !== undefined;
  }

  constructor(
    account: IAccountsDictObj | string,
    datesArray: Date[],
    accountListDict: IAccountsDict,
    pbcData: BalanceSheet | ProfiAndLoss | IgridRow[],
    section: SectionNameEnum | FormulaNameEnum,
    financialStatementReportType: ReportTypeEnum,
    priorDate?: Date | undefined,
    netIncome?: IaccountBalanceArray | undefined,
    last_date_of_actuals?: Date | undefined
  ) {
    this.financialStatementReportType = financialStatementReportType;
    this.accountListDict = accountListDict;
    this.pbcData = pbcData;
    this.datesArray = datesArray;
    this.priorDate = priorDate;
    this.section = section;
    if (typeof account !== "string") {
      this.id = account.id;
      this.name = account.name;
      this.accountType = account.accountType;
      this.childIds = account.immediateChildren;
      this.parentRef = account.parentRef;
    } else {
      this.name = account;
      this.accountType = AccountTypeEnum.Equity;
      this.id = "Net_Income";
    }

    this.createChildAccounts();
    this.headerRow = {
      id: this.id,
      name: this.name,
      bold: this.childIds.length ? true : false,
      hierarchy: [this.id],
      metaType: "Account",
      accountBalance: {},
      totalAccountBalance: {},
    };

    if (this.accountListDict[this.id]) {
      this.accountSubType = this.accountListDict[this.id].accountSubType;
      if (this.accountSubType !== AccountSubTypeEnum.Retained_Earnings) {
        this.headerRow.metaType = AccountSubTypeEnum.Retained_Earnings;
      } else {
      }
    }

    if (this.childIds.length > 0 && this.financialStatementReportType !== ReportTypeEnum.Cash_Flows) {
      this.totalRow = {
        id: "Sum(" + this.id + ")",
        name: "Total " + this.name,
        bold: this.childIds.length ? true : false,
        hierarchy: [...this.headerRow.hierarchy, "Sum(" + this.id + ")"],
        metaType: "Total Account",
        accountBalance: {},
        totalAccountBalance: {},
      };
    }

    this.fillAccountValues();

    if (this.accountSubType === AccountSubTypeEnum.Retained_Earnings) {
      if (last_date_of_actuals) {
        for (var i = 0; i < this.datesArray.length; i++) {
          if (this.datesArray[i] > last_date_of_actuals) {
            const period = getMonthShort(this.datesArray[i]);
            const prevPeriod = getMonthShort(this.datesArray[i - 1]);
            const quarter_name = getQuarter(this.datesArray[i], 0);
            const year_name = getYear(this.datesArray[i], 0);
            if (netIncome && netIncome[prevPeriod] && this.headerRow.accountBalance[prevPeriod]) {
              const value = netIncome[prevPeriod].value + this.headerRow.accountBalance[prevPeriod].value;
              this.headerRow.accountBalance[period] = { value: value, error: false };

              if (this.headerRow.accountBalance[quarter_name]) {
                this.headerRow.accountBalance[quarter_name].value += value;
              } else {
                this.headerRow.accountBalance[quarter_name] = { value: value, error: false };
              }

              if (this.headerRow.accountBalance[year_name]) {
                this.headerRow.accountBalance[year_name].value += value;
              } else {
                this.headerRow.accountBalance[year_name] = { value: value, error: false };
              }
            }
          }
        }
      }
    }

    var children: Account[] = [];
    if (this.childAccounts.length > 0) {
      children = this.childAccounts;
    }

    if (children.length > 0 && this.financialStatementReportType !== ReportTypeEnum.Cash_Flows) {
      // get the accountBalance and totalAccountBalance for all the children
      var accountBalanceArray: IaccountBalanceArray[] = [];
      var totalAccountBalanceArray: IaccountBalanceArray[] = [];
      for (var j = 0; j < children.length; j++) {
        const child = children[j];
        // each object in the array contains the values of the child
        const accountBalance = child.getAccountBalanceArray();
        if (
          !((o) => {
            for (let k in o) return false;
            return true;
          })(accountBalance)
        ) {
          accountBalanceArray.push(accountBalance);
          const totalAccountBalance = child.getTotalAccountBalanceArray();
          totalAccountBalanceArray.push(totalAccountBalance);
        } else {
          // delete the child if it doesn't have an accountBalance
          const filteredChildAccounts = children.filter((x) => x.id !== child.id);
          this.childAccounts = filteredChildAccounts;
        }
      }

      // totalAccountBalanceArray gets summed to get the total of the children and their descendants,
      // which, in addition to this account's own account balance, becomes the values for the totalAccountBalance
      // of the header row for when it's collapsed
      for (var i = 0; i < this.datesArray.length; i++) {
        const period = getMonthShort(this.datesArray[i]);
        const quarter_name = getQuarter(this.datesArray[i], 0);
        const year_name = getYear(this.datesArray[i], 0);
        var total = 0;
        if (totalAccountBalanceArray.length > 0) {
          for (j = 0; j < totalAccountBalanceArray.length; j++) {
            const totalAccountBalance = totalAccountBalanceArray[j];
            if (
              !((o) => {
                for (let k in o) return false;
                return true;
              })(totalAccountBalance)
            ) {
              if (totalAccountBalance[period]) {
                total += totalAccountBalance[period].value;
              }
            } else {
              if (
                !((o) => {
                  for (let k in o) return false;
                  return true;
                })(accountBalanceArray[j])
              ) {
                if (accountBalanceArray[j][period]) {
                  total += accountBalanceArray[j][period].value;
                }
              }
            }
          }

          if (this.headerRow.accountBalance[period]) {
            total += this.headerRow.accountBalance[period].value;
          }
          this.headerRow.totalAccountBalance[period] = { value: total, error: false };
          if (this.headerRow.totalAccountBalance[quarter_name]) {
            this.headerRow.totalAccountBalance[quarter_name].value += total;
          } else {
            this.headerRow.totalAccountBalance[quarter_name] = { value: total, error: false };
          }

          if (this.headerRow.totalAccountBalance[year_name]) {
            this.headerRow.totalAccountBalance[year_name].value += total;
          } else {
            this.headerRow.totalAccountBalance[year_name] = { value: total, error: false };
          }

          this.totalRow.totalAccountBalance[period] = { value: total, error: false };

          if (this.totalRow.totalAccountBalance[quarter_name]) {
            this.totalRow.totalAccountBalance[quarter_name].value += total;
          } else {
            this.totalRow.totalAccountBalance[quarter_name] = { value: total, error: false };
          }
          if (this.totalRow.totalAccountBalance[year_name]) {
            this.totalRow.totalAccountBalance[year_name].value += total;
          } else {
            this.totalRow.totalAccountBalance[year_name] = { value: total, error: false };
          }
        } else if (this.childAccounts.length > 0 && this.totalRow.totalAccountBalance[period] && this.headerRow.accountBalance[period]) {
          // all children accounts don't have children
          const childrenTotalAccountBalance = this.totalRow.totalAccountBalance[period].value;
          const accountBalance = this.headerRow.accountBalance[period].value;
          if (childrenTotalAccountBalance) {
            this.headerRow.totalAccountBalance[period] = { value: childrenTotalAccountBalance, error: false };

            if (this.headerRow.totalAccountBalance[quarter_name]) {
              this.headerRow.totalAccountBalance[quarter_name].value += childrenTotalAccountBalance;
            } else {
              this.headerRow.totalAccountBalance[quarter_name] = { value: childrenTotalAccountBalance, error: false };
            }
            if (this.headerRow.totalAccountBalance[year_name]) {
              this.headerRow.totalAccountBalance[year_name].value += childrenTotalAccountBalance;
            } else {
              this.headerRow.totalAccountBalance[year_name] = { value: childrenTotalAccountBalance, error: false };
            }
          }
          if (accountBalance && this.headerRow.totalAccountBalance[period]) {
            this.headerRow.totalAccountBalance[period] = { value: this.headerRow.totalAccountBalance[period].value + accountBalance, error: false };

            if (this.headerRow.totalAccountBalance[quarter_name]) {
              this.headerRow.totalAccountBalance[quarter_name].value += this.headerRow.totalAccountBalance[period].value + accountBalance;
            } else {
              this.headerRow.totalAccountBalance[quarter_name] = { value: this.headerRow.totalAccountBalance[period].value + accountBalance, error: false };
            }
            if (this.headerRow.totalAccountBalance[year_name]) {
              this.headerRow.totalAccountBalance[year_name].value += this.headerRow.totalAccountBalance[period].value + accountBalance;
            } else {
              this.headerRow.totalAccountBalance[year_name] = { value: this.headerRow.totalAccountBalance[period].value + accountBalance, error: false };
            }
          }
        } else {
          this.headerRow.bold = false;
        }
      }
    }
  }

  private fillAccountValues() {
    // cycle through dates to fill the accountBalance values of this account
    if (this.financialStatementReportType !== ReportTypeEnum.Cash_Flows) {
      if (this.name === "Net Income") {
        var refDataKey: string | undefined = "";
        var key = "";
        for (var i = 0; i < this.datesArray.length; i++) {
          const period = getMonthShort(this.datesArray[i]);
          const quarter_name = getQuarter(this.datesArray[i], 0);
          const year_name = getYear(this.datesArray[i], 0);
          key = "Net_Income_" + period.replace(" ", "_");
          refDataKey = Object.keys(this.pbcData).find((x) => x.substring(x.indexOf("_") + 1) === key);
          if (refDataKey && this.pbcData[refDataKey]) {
            const value = this.pbcData[refDataKey].value;
            this.headerRow.accountBalance[period] = { value: value, error: false };

            if (this.headerRow.accountBalance[quarter_name] && this.financialStatementReportType === ReportTypeEnum.Income_Statement) {
              this.headerRow.accountBalance[quarter_name].value += value;
            } else {
              this.headerRow.accountBalance[quarter_name] = { value: value, error: false };
            }

            if (this.headerRow.accountBalance[year_name] && this.financialStatementReportType === ReportTypeEnum.Income_Statement) {
              this.headerRow.accountBalance[year_name].value += value;
            } else {
              this.headerRow.accountBalance[year_name] = { value: value, error: false };
            }
          }
        }
        return;
      }

      const id_date_key_array = this.datesArray.map((x) => this.id + "_" + getMonthShort(x).replaceAll(" ", "_"));

      for (i = 0; i < id_date_key_array.length; i++) {
        const Month_Name = getMonthShort(this.datesArray[i]);
        const quarter_name = getQuarter(this.datesArray[i], 0);
        const year_name = getYear(this.datesArray[i], 0);
        if (this.pbcData[id_date_key_array[i]]) {
          if (this.headerRow.accountBalance[Month_Name]) {
            this.headerRow.accountBalance[Month_Name].value += this.headerRow.accountBalance[Month_Name].value;
          } else {
            this.headerRow.accountBalance[Month_Name] = { value: this.pbcData[id_date_key_array[i]]["value"], error: false };
          }

          if (this.headerRow.accountBalance[quarter_name] && this.financialStatementReportType === ReportTypeEnum.Income_Statement) {
            this.headerRow.accountBalance[quarter_name].value += this.pbcData[id_date_key_array[i]]["value"];
          } else {
            this.headerRow.accountBalance[quarter_name] = { value: this.pbcData[id_date_key_array[i]]["value"], error: false };
          }

          if (this.headerRow.accountBalance[year_name] && this.financialStatementReportType === ReportTypeEnum.Income_Statement) {
            this.headerRow.accountBalance[year_name].value += this.pbcData[id_date_key_array[i]]["value"];
          } else {
            this.headerRow.accountBalance[year_name] = { value: this.pbcData[id_date_key_array[i]]["value"], error: false };
          }
        }
      }
    } else {
      // calculations for cashflow accounts: the difference between each two time period for this account
      // find this account on the Balance Sheet report
      if (this.isIgridRow(this.pbcData)) {
        const accountBalanceBS = this.pbcData.find((x) => x.id === this.id)?.accountBalance;
        if (
          accountBalanceBS &&
          !((o) => {
            for (let k in o) return false;
            return true;
          })(accountBalanceBS)
        ) {
          for (i = 0; i < this.datesArray.length; i++) {
            const period = getMonthShort(this.datesArray[i]);
            var prevPeriod;
            if (this.priorDate) {
              prevPeriod = getMonthShort(this.priorDate);
            }

            var diff = 0;
            var currentValue = 0;
            var priorValue = 0;
            if (i === 0 && accountBalanceBS[prevPeriod] && accountBalanceBS[period]) {
              if (accountBalanceBS[prevPeriod].value) {
                priorValue = accountBalanceBS[prevPeriod].value;
              }
              currentValue = accountBalanceBS[period].value;
            } else if (i > 0 && accountBalanceBS[prevPeriod]) {
              prevPeriod = getMonthShort(this.datesArray[i - 1]);
              if (accountBalanceBS[prevPeriod]) {
                priorValue = accountBalanceBS[prevPeriod].value;
              }
              if (accountBalanceBS[period] && accountBalanceBS[period].value) {
                currentValue = accountBalanceBS[period].value;
              }
            } else if (accountBalanceBS[period]) {
              priorValue = 0;
              if (accountBalanceBS[period].value) {
                currentValue = accountBalanceBS[period].value;
              }
            }
            if (AccountTypeToCFSection[this.accountType].type === CalculationDirectionEnum.currentMinusPrev) {
              diff = currentValue - priorValue;
            } else {
              diff = priorValue - currentValue;
            }
            this.headerRow.accountBalance[period] = { value: diff, error: false };

            const quarter_name = getQuarter(this.datesArray[i], 0);
            const year_name = getYear(this.datesArray[i], 0);
            if (this.headerRow.accountBalance[quarter_name]) {
              this.headerRow.accountBalance[quarter_name].value += diff;
            } else {
              this.headerRow.accountBalance[quarter_name] = { value: diff, error: false };
            }

            if (this.headerRow.accountBalance[year_name]) {
              this.headerRow.accountBalance[year_name].value += diff;
            } else {
              this.headerRow.accountBalance[year_name] = { value: diff, error: false };
            }
          }
        }
      }
    }
  }

  public createChildAccounts() {
    if (this.financialStatementReportType !== ReportTypeEnum.Cash_Flows) {
      if (this.accountListDict) {
        for (var i = 0; i < this.childIds.length; i++) {
          const accountID = this.childIds[i];
          const accountObj = this.accountListDict[accountID];

          var childAccount = new Account(accountObj, this.datesArray, this.accountListDict, this.pbcData, this.section, this.financialStatementReportType);
          const accountBalance = childAccount.headerRow.accountBalance;
          if (
            !((o) => {
              for (let k in o) return false;
              return true;
            })(accountBalance)
          ) {
            this.childAccounts.push(childAccount);
          }
        }
      }
    }
  }

  public getAccountBalanceArray() {
    return this.headerRow.accountBalance;
  }

  public getTotalAccountBalanceArray() {
    return this.headerRow.totalAccountBalance;
  }

  public getTreeGridPresentation() {
    var result: IgridRow[] = [];
    if (this.childAccounts.length === 0) {
      this.headerRow.bold = false;
    }
    result.push(this.headerRow);
    if (this.childAccounts.length > 0 && this.financialStatementReportType !== ReportTypeEnum.Cash_Flows) {
      const childAccountsArray: IgridRow[] = [];
      for (let index = 0; index < this.childAccounts.length; index++) {
        const accountBalance = this.childAccounts[index].headerRow.accountBalance;
        // don't include a child that doesn't have accountBalance
        if (
          !((o) => {
            for (let k in o) return false;
            return true;
          })(accountBalance)
        ) {
          var account = this.childAccounts[index].getTreeGridPresentation();
          for (var i = 0; i < account.length; i++) {
            const accountHierarchy = account[i].hierarchy;
            account[i] = { ...account[i], hierarchy: [this.id, ...accountHierarchy] };
            childAccountsArray.push(account[i]);
          }
        }
      }
      childAccountsArray.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      result = [this.headerRow, ...childAccountsArray, this.totalRow];
    }
    return result;
  }
}

export class Section {
  section: SectionNameEnum | FormulaNameEnum = SectionNameEnum.NO_SECTION;
  datesArray: Date[] = [];
  formulaData1: IaccountBalanceArray | undefined;
  last_date_of_actuals: Date | undefined;
  pbcData: BalanceSheet | ProfiAndLoss | IgridRow[] = {};
  financialStatementReportType: ReportTypeEnum = ReportTypeEnum.Income_Statement;
  parentSection: SectionNameEnum = SectionNameEnum.NO_SECTION;
  childSections: Section[] = [];
  childAccounts: Account[] = [];
  hierarchy: string[] = [];
  formula: boolean = false;
  metaType: string = "";
  headerRow: IgridRow = {
    name: "",
    id: "",
    bold: true,
    hierarchy: [],
    metaType: "",
    accountBalance: {},
    totalAccountBalance: {},
  };
  totalRow: IgridRow = {
    name: "",
    id: "",
    bold: true,
    hierarchy: [],
    metaType: "",
    accountBalance: {},
    totalAccountBalance: {},
  };
  accountTypeDict: SectionItemData | null = null;
  accountListDict: IAccountsDict | null = null;

  constructor(
    pbcData: ProfiAndLoss | BalanceSheet | IgridRow[],
    datesArray: Date[],
    financialStatementReportType: ReportTypeEnum = ReportTypeEnum.Income_Statement,
    section: SectionNameEnum | FormulaNameEnum = SectionNameEnum.NO_SECTION,
    parentSection: SectionNameEnum = SectionNameEnum.NO_SECTION,
    childSections: Section[] = [],
    accountTypeDict: SectionItemData | null = null,
    accountListDict: IAccountsDict | null = null,
    formulaData1?: IaccountBalanceArray | undefined,
    formulaData2?: IaccountBalanceArray | undefined,
    last_date_of_actuals?: Date | undefined
  ) {
    this.datesArray = datesArray;
    this.last_date_of_actuals = last_date_of_actuals;
    this.pbcData = pbcData;
    this.accountListDict = accountListDict;
    this.accountTypeDict = accountTypeDict;
    this.financialStatementReportType = financialStatementReportType;
    if (section === FormulaNameEnum.Gross_Profit || section === FormulaNameEnum.Net_Income || section === FormulaNameEnum.Net_Operating_Income || section === FormulaNameEnum.Net_Other_Income)
      this.formula = true;
    this.section = section;
    this.parentSection = parentSection;
    this.childSections = childSections;
    this.metaType = this.formula ? section : "Section(" + section + ")";
    this.hierarchy = [this.metaType];
    this.formulaData1 = formulaData1;

    this.createChildAccounts();

    this.headerRow = {
      name: this.section,
      id: this.metaType,
      bold: true,
      hierarchy: [this.metaType],
      metaType: this.formula ? "Formula" : "Section",
      accountBalance: {},
      totalAccountBalance: {},
    };

    if (this.section === SectionNameEnum.Revenue || this.section === SectionNameEnum.Assets) {
      this.headerRow.metaType = "First Section";
    }

    if (!this.formula) {
      this.totalRow = {
        name: "Total " + this.section,
        id: "Sum(" + this.section + ")",
        bold: true,
        hierarchy: [...this.headerRow.hierarchy, "Sum(" + this.section + ")"],
        metaType: "Total Section",
        accountBalance: {},
        totalAccountBalance: {},
      };
    }

    if (this.formula) {
      switch (this.section) {
        case FormulaNameEnum.Gross_Profit:
          for (var i = 0; i < this.datesArray.length; i++) {
            const period = getMonthShort(this.datesArray[i]);
            const quarter_name = getQuarter(this.datesArray[i], 0);
            const year_name = getYear(this.datesArray[i], 0);
            if (formulaData1 && formulaData2 && formulaData1[period] && formulaData2[period]) {
              this.headerRow.totalAccountBalance[period] = { value: formulaData1[period].value - formulaData2[period].value, error: false };

              if (this.headerRow.totalAccountBalance[quarter_name]) {
                this.headerRow.totalAccountBalance[quarter_name].value += formulaData1[period].value - formulaData2[period].value;
              } else {
                this.headerRow.totalAccountBalance[quarter_name] = { value: formulaData1[period].value - formulaData2[period].value, error: false };
              }

              if (this.headerRow.totalAccountBalance[year_name]) {
                this.headerRow.totalAccountBalance[year_name].value += formulaData1[period].value - formulaData2[period].value;
              } else {
                this.headerRow.totalAccountBalance[year_name] = { value: formulaData1[period].value - formulaData2[period].value, error: false };
              }
            } else if (formulaData1 && formulaData1[period]) {
              this.headerRow.totalAccountBalance[period] = { value: formulaData1[period].value, error: false };

              if (this.headerRow.totalAccountBalance[quarter_name]) {
                this.headerRow.totalAccountBalance[quarter_name].value += formulaData1[period].value;
              } else {
                this.headerRow.totalAccountBalance[quarter_name] = { value: formulaData1[period].value, error: false };
              }

              if (this.headerRow.totalAccountBalance[year_name]) {
                this.headerRow.totalAccountBalance[year_name].value += formulaData1[period].value;
              } else {
                this.headerRow.totalAccountBalance[year_name] = { value: formulaData1[period].value, error: false };
              }
            } else if (formulaData2 && formulaData2[period]) {
              this.headerRow.totalAccountBalance[period] = { value: -formulaData2[period].value, error: false };

              if (this.headerRow.totalAccountBalance[quarter_name]) {
                this.headerRow.totalAccountBalance[quarter_name].value += -formulaData2[period].value;
              } else {
                this.headerRow.totalAccountBalance[quarter_name] = { value: -formulaData2[period].value, error: false };
              }

              if (this.headerRow.totalAccountBalance[year_name]) {
                this.headerRow.totalAccountBalance[year_name].value += -formulaData2[period].value;
              } else {
                this.headerRow.totalAccountBalance[year_name] = { value: -formulaData2[period].value, error: false };
              }
            }
          }
          break;
        case FormulaNameEnum.Net_Operating_Income:
          for (i = 0; i < this.datesArray.length; i++) {
            const period = getMonthShort(this.datesArray[i]);
            const quarter_name = getQuarter(this.datesArray[i], 0);
            const year_name = getYear(this.datesArray[i], 0);
            if (formulaData1 && formulaData1[period] && formulaData2 && formulaData2[period]) {
              this.headerRow.totalAccountBalance[period] = { value: formulaData1[period].value - formulaData2[period].value, error: false };

              if (this.headerRow.totalAccountBalance[quarter_name]) {
                this.headerRow.totalAccountBalance[quarter_name].value += formulaData1[period].value - formulaData2[period].value;
              } else {
                this.headerRow.totalAccountBalance[quarter_name] = { value: formulaData1[period].value - formulaData2[period].value, error: false };
              }

              if (this.headerRow.totalAccountBalance[year_name]) {
                this.headerRow.totalAccountBalance[year_name].value += formulaData1[period].value - formulaData2[period].value;
              } else {
                this.headerRow.totalAccountBalance[year_name] = { value: formulaData1[period].value - formulaData2[period].value, error: false };
              }
            } else if (formulaData1 && formulaData1[period]) {
              this.headerRow.totalAccountBalance[period] = { value: formulaData1[period].value, error: false };

              if (this.headerRow.totalAccountBalance[quarter_name]) {
                this.headerRow.totalAccountBalance[quarter_name].value += formulaData1[period].value;
              } else {
                this.headerRow.totalAccountBalance[quarter_name] = { value: formulaData1[period].value, error: false };
              }

              if (this.headerRow.totalAccountBalance[year_name]) {
                this.headerRow.totalAccountBalance[year_name].value += formulaData1[period].value;
              } else {
                this.headerRow.totalAccountBalance[year_name] = { value: formulaData1[period].value, error: false };
              }
            } else if (formulaData2 && formulaData2[period]) {
              this.headerRow.totalAccountBalance[period] = { value: -formulaData2[period].value, error: false };

              if (this.headerRow.totalAccountBalance[quarter_name]) {
                this.headerRow.totalAccountBalance[quarter_name].value += -formulaData2[period].value;
              } else {
                this.headerRow.totalAccountBalance[quarter_name] = { value: -formulaData2[period].value, error: false };
              }

              if (this.headerRow.totalAccountBalance[year_name]) {
                this.headerRow.totalAccountBalance[year_name].value += -formulaData2[period].value;
              } else {
                this.headerRow.totalAccountBalance[year_name] = { value: -formulaData2[period].value, error: false };
              }
            }
          }

          break;
        case FormulaNameEnum.Net_Other_Income:
          for (i = 0; i < this.datesArray.length; i++) {
            const period = getMonthShort(this.datesArray[i]);
            const quarter_name = getQuarter(this.datesArray[i], 0);
            const year_name = getYear(this.datesArray[i], 0);
            if (formulaData1 && formulaData1[period] && formulaData2 && formulaData2[period]) {
              this.headerRow.totalAccountBalance[period] = { value: formulaData1[period].value - formulaData2[period].value, error: false };

              if (this.headerRow.totalAccountBalance[quarter_name]) {
                this.headerRow.totalAccountBalance[quarter_name].value += formulaData1[period].value - formulaData2[period].value;
              } else {
                this.headerRow.totalAccountBalance[quarter_name] = { value: formulaData1[period].value - formulaData2[period].value, error: false };
              }

              if (this.headerRow.totalAccountBalance[year_name]) {
                this.headerRow.totalAccountBalance[year_name].value += formulaData1[period].value - formulaData2[period].value;
              } else {
                this.headerRow.totalAccountBalance[year_name] = { value: formulaData1[period].value - formulaData2[period].value, error: false };
              }
            } else if (formulaData1 && formulaData1[period]) {
              this.headerRow.totalAccountBalance[period] = { value: formulaData1[period].value, error: false };

              if (this.headerRow.totalAccountBalance[quarter_name]) {
                this.headerRow.totalAccountBalance[quarter_name].value += formulaData1[period].value;
              } else {
                this.headerRow.totalAccountBalance[quarter_name] = { value: formulaData1[period].value, error: false };
              }

              if (this.headerRow.totalAccountBalance[year_name]) {
                this.headerRow.totalAccountBalance[year_name].value += formulaData1[period].value;
              } else {
                this.headerRow.totalAccountBalance[year_name] = { value: formulaData1[period].value, error: false };
              }
            } else if (formulaData2 && formulaData2[period]) {
              this.headerRow.totalAccountBalance[period] = { value: -formulaData2[period].value, error: false };

              if (this.headerRow.totalAccountBalance[quarter_name]) {
                this.headerRow.totalAccountBalance[quarter_name].value += -formulaData2[period].value;
              } else {
                this.headerRow.totalAccountBalance[quarter_name] = { value: -formulaData2[period].value, error: false };
              }

              if (this.headerRow.totalAccountBalance[year_name]) {
                this.headerRow.totalAccountBalance[year_name].value += -formulaData2[period].value;
              } else {
                this.headerRow.totalAccountBalance[year_name] = { value: -formulaData2[period].value, error: false };
              }
            }
          }
          break;
        case FormulaNameEnum.Net_Income:
          for (i = 0; i < this.datesArray.length; i++) {
            const period: string = getMonthShort(this.datesArray[i]);
            const quarter_name = getQuarter(this.datesArray[i], 0);
            const year_name = getYear(this.datesArray[i], 0);
            if (formulaData1 && formulaData1[period] && formulaData2 && formulaData2[period]) {
              this.headerRow.totalAccountBalance[period] = { value: formulaData1[period].value + formulaData2[period].value, error: false };

              if (this.headerRow.totalAccountBalance[quarter_name]) {
                this.headerRow.totalAccountBalance[quarter_name].value += formulaData1[period].value + formulaData2[period].value;
              } else {
                this.headerRow.totalAccountBalance[quarter_name] = { value: formulaData1[period].value + formulaData2[period].value, error: false };
              }

              if (this.headerRow.totalAccountBalance[year_name]) {
                this.headerRow.totalAccountBalance[year_name].value += formulaData1[period].value + formulaData2[period].value;
              } else {
                this.headerRow.totalAccountBalance[year_name] = { value: formulaData1[period].value + formulaData2[period].value, error: false };
              }
            } else if (formulaData1 && formulaData1[period]) {
              this.headerRow.totalAccountBalance[period] = { value: formulaData1[period].value, error: false };

              if (this.headerRow.totalAccountBalance[quarter_name]) {
                this.headerRow.totalAccountBalance[quarter_name].value += formulaData1[period].value;
              } else {
                this.headerRow.totalAccountBalance[quarter_name] = { value: formulaData1[period].value, error: false };
              }

              if (this.headerRow.totalAccountBalance[year_name]) {
                this.headerRow.totalAccountBalance[year_name].value += formulaData1[period].value;
              } else {
                this.headerRow.totalAccountBalance[year_name] = { value: formulaData1[period].value, error: false };
              }
            } else if (formulaData2 && formulaData2[period]) {
              this.headerRow.totalAccountBalance[period] = { value: -formulaData2[period].value, error: false };

              if (this.headerRow.totalAccountBalance[quarter_name]) {
                this.headerRow.totalAccountBalance[quarter_name].value += -formulaData2[period].value;
              } else {
                this.headerRow.totalAccountBalance[quarter_name] = { value: -formulaData2[period].value, error: false };
              }

              if (this.headerRow.totalAccountBalance[year_name]) {
                this.headerRow.totalAccountBalance[year_name].value += -formulaData2[period].value;
              } else {
                this.headerRow.totalAccountBalance[year_name] = { value: -formulaData2[period].value, error: false };
              }
            }
          }
          break;
      }
    }

    var children;
    var childrenType: string = "";
    if (this.childAccounts.length > 0) {
      children = this.childAccounts;
      childrenType = "Accounts";
    } else if (this.childSections.length > 0) {
      children = this.childSections;
      childrenType = "Sections";
    }

    if (children?.length > 0) {
      // get the accountBalance and totalAccountBalance for all the children
      var accountBalanceArray: IaccountBalanceArray[] = [];
      var totalAccountBalanceArray: IaccountBalanceArray[] = [];
      for (var j = 0; j < children.length; j++) {
        const child = children[j];
        // each object in the array contains the values of the child
        if (childrenType === "Sections") {
          const totalBankAccount = children[j].getTotalAccountBalanceArray();
          if (
            !((o) => {
              for (let k in o) return false;
              return true;
            })(totalBankAccount)
          ) {
            totalAccountBalanceArray.push(totalBankAccount);
          }
        } else {
          const accountBalance = child.getAccountBalanceArray();

          if (
            !((o) => {
              for (let k in o) return false;
              return true;
            })(accountBalance)
          ) {
            accountBalanceArray.push(accountBalance);
            // each object in the array contains the totals for all the descendants of the child
            const totalAccountBalance = child.getTotalAccountBalanceArray();
            if (
              !((o) => {
                for (let k in o) return false;
                return true;
              })(totalAccountBalance)
            ) {
              totalAccountBalanceArray.push(totalAccountBalance);
            } else {
              totalAccountBalanceArray.push(accountBalance);
            }
          } else {
            // discuss with Ibrahim regarding targeting the particular type of children
            if (childrenType === "Accounts") {
              const filteredAccounts = this.childAccounts.filter((item) => item.id !== child.id);
              this.childAccounts = filteredAccounts;
            } else {
              const filteredSections = this.childSections.filter((item) => item.metaType !== child.metaType);
              this.childSections = filteredSections;
            }
          }
        }
      }

      // accountBalanceArray gets summed to get the total of the children, which, become the values for the totalRow of this section
      if (childrenType === "Accounts") {
        for (i = 0; i < this.datesArray.length; i++) {
          const period = getMonthShort(this.datesArray[i]);
          const quarter_name = getQuarter(this.datesArray[i], 0);
          const year_name = getYear(this.datesArray[i], 0);
          var childrenTotal = 0;
          for (j = 0; j < this.childAccounts.length; j++) {
            if (this.childAccounts[j].parentRef === "") {
              const totalAccountBalance = this.childAccounts[j].getTotalAccountBalanceArray();
              if (
                !((o) => {
                  for (let k in o) return false;
                  return true;
                })(totalAccountBalance)
              ) {
                const x = totalAccountBalance[period];
                if (x) {
                  childrenTotal += x.value;
                }
              } else {
                const accountBalance = this.childAccounts[j].getAccountBalanceArray();
                if (
                  !((o) => {
                    for (let k in o) return false;
                    return true;
                  })(accountBalance)
                ) {
                  const x = accountBalance[period];
                  if (x) {
                    childrenTotal += x.value;
                  }
                }
              }
            }
          }
          this.totalRow.totalAccountBalance[period] = { value: childrenTotal, error: false };
          if (this.totalRow.totalAccountBalance[quarter_name] && this.financialStatementReportType === ReportTypeEnum.Income_Statement) {
            this.totalRow.totalAccountBalance[quarter_name].value += childrenTotal;
          } else {
            this.totalRow.totalAccountBalance[quarter_name] = { value: childrenTotal, error: false };
          }

          if (this.totalRow.totalAccountBalance[year_name] && this.financialStatementReportType === ReportTypeEnum.Income_Statement) {
            this.totalRow.totalAccountBalance[year_name].value += childrenTotal;
          } else {
            this.totalRow.totalAccountBalance[year_name] = { value: childrenTotal, error: false };
          }

          this.headerRow.totalAccountBalance[period] = { value: childrenTotal, error: false };
          if (this.headerRow.totalAccountBalance[quarter_name] && this.financialStatementReportType === ReportTypeEnum.Income_Statement) {
            this.headerRow.totalAccountBalance[quarter_name].value += childrenTotal;
          } else {
            this.headerRow.totalAccountBalance[quarter_name] = { value: childrenTotal, error: false };
          }

          if (this.headerRow.totalAccountBalance[year_name] && this.financialStatementReportType === ReportTypeEnum.Income_Statement) {
            this.headerRow.totalAccountBalance[year_name].value += childrenTotal;
          } else {
            this.headerRow.totalAccountBalance[year_name] = { value: childrenTotal, error: false };
          }
        }
      } else {
        // if the children are sections
        for (i = 0; i < this.datesArray.length; i++) {
          const period = getMonthShort(this.datesArray[i]);
          const quarter_name = getQuarter(this.datesArray[i], 0);
          const year_name = getYear(this.datesArray[i], 0);
          childrenTotal = 0;
          for (j = 0; j < totalAccountBalanceArray.length; j++) {
            if (
              !((o) => {
                for (let k in o) return false;
                return true;
              })(totalAccountBalanceArray[j])
            ) {
              const x = totalAccountBalanceArray[j][period];
              if (x) {
                childrenTotal += x.value;
              }
            }
          }
          this.totalRow.totalAccountBalance[period] = { value: childrenTotal, error: false };
          if (this.totalRow.totalAccountBalance[quarter_name] && this.financialStatementReportType === ReportTypeEnum.Income_Statement) {
            this.totalRow.totalAccountBalance[quarter_name].value += childrenTotal;
          } else {
            this.totalRow.totalAccountBalance[quarter_name] = { value: childrenTotal, error: false };
          }

          if (this.totalRow.totalAccountBalance[year_name] && this.financialStatementReportType === ReportTypeEnum.Income_Statement) {
            this.totalRow.totalAccountBalance[year_name].value += childrenTotal;
          } else {
            this.totalRow.totalAccountBalance[year_name] = { value: childrenTotal, error: false };
          }

          this.headerRow.totalAccountBalance[period] = { value: childrenTotal, error: false };
          if (this.headerRow.totalAccountBalance[quarter_name] && this.financialStatementReportType === ReportTypeEnum.Income_Statement) {
            this.headerRow.totalAccountBalance[quarter_name].value += childrenTotal;
          } else {
            this.headerRow.totalAccountBalance[quarter_name] = { value: childrenTotal, error: false };
          }

          if (this.headerRow.totalAccountBalance[year_name] && this.financialStatementReportType === ReportTypeEnum.Income_Statement) {
            this.headerRow.totalAccountBalance[year_name].value += childrenTotal;
          } else {
            this.headerRow.totalAccountBalance[year_name] = { value: childrenTotal, error: false };
          }

          if (this.section === SectionNameEnum.Liabilities_and_Equity && (formulaData1 && formulaData1[period].value - this.totalRow.totalAccountBalance[period].value > 0)) {
            // this.totalRow.totalAccountBalance[period].error = true;
            // this.headerRow.totalAccountBalance[period].error = true;
          }
        }
      }
    }

    this.verifyError();
  }

  private verifyError() {
    for (var i = 0; i < this.datesArray.length; i++) {
      const period: string = getMonthShort(this.datesArray[i]);
      var key = "";
      var refDataKey: string | undefined = "";
      switch (this.section) {
        case SectionNameEnum.Revenue:
          key = "Total_Income_" + period.replace(" ", "_");
          refDataKey = Object.keys(this.pbcData).find((x) => x.substring(x.indexOf("_") + 1) === key);
          if (refDataKey && this.pbcData[refDataKey]) {
            if (Math.abs(this.headerRow.totalAccountBalance[period].value - this.pbcData[refDataKey].value) > 0.5) {
              // this.headerRow.totalAccountBalance[period].error = true;
              // this.totalRow.totalAccountBalance[period].error = true;
            }
          }
          break;
        case SectionNameEnum.Cost_Of_Goods_Sold:
          key = "Total_Cost_of_Goods_Sold_" + period.replace(" ", "_");
          refDataKey = Object.keys(this.pbcData).find((x) => x.substring(x.indexOf("_") + 1) === key);
          if (refDataKey && this.pbcData[refDataKey]) {
            if (Math.abs(this.headerRow.totalAccountBalance[period].value - this.pbcData[refDataKey].value) > 0.5) {
              // this.headerRow.totalAccountBalance[period].error = true;
              // this.totalRow.totalAccountBalance[period].error = true;
            }
          }
          break;
        case SectionNameEnum.Expenses:
          key = "Total_Expenses_" + period.replace(" ", "_");
          refDataKey = Object.keys(this.pbcData).find((x) => x.substring(x.indexOf("_") + 1) === key);
          if (refDataKey && this.pbcData[refDataKey]) {
            if (Math.abs(this.headerRow.totalAccountBalance[period].value - this.pbcData[refDataKey].value) > 0.5) {
              // this.headerRow.totalAccountBalance[period].error = true;
              // this.totalRow.totalAccountBalance[period].error = true;
            }
          }
          break;
        case SectionNameEnum.Other_Income:
          key = "Total_Other_Income_" + period.replace(" ", "_");
          refDataKey = Object.keys(this.pbcData).find((x) => x.substring(x.indexOf("_") + 1) === key);
          if (refDataKey && this.pbcData[refDataKey]) {
            if (Math.abs(this.headerRow.totalAccountBalance[period].value - this.pbcData[refDataKey].value) > 0.5) {
              // this.headerRow.totalAccountBalance[period].error = true;
              // this.totalRow.totalAccountBalance[period].error = true;
            }
          }
          break;
        case SectionNameEnum.Other_Expenses:
          key = "Total_Other_Expenses_" + period.replace(" ", "_");
          refDataKey = Object.keys(this.pbcData).find((x) => x.substring(x.indexOf("_") + 1) === key);
          if (refDataKey && this.pbcData[refDataKey]) {
            if (Math.abs(this.headerRow.totalAccountBalance[period].value - this.pbcData[refDataKey].value) > 0.5) {
              // this.headerRow.totalAccountBalance[period].error = true;
              // this.totalRow.totalAccountBalance[period].error = true;
            }
          }
          break;
        case SectionNameEnum.Assets:
          key = "TOTAL_ASSETS_" + period.replace(" ", "_");
          refDataKey = Object.keys(this.pbcData).find((x) => x.substring(x.indexOf("_") + 1) === key);
          if (refDataKey && this.pbcData[refDataKey]) {
            if (Math.abs(this.headerRow.totalAccountBalance[period].value - this.pbcData[refDataKey].value) > 0.5) {
              // this.headerRow.totalAccountBalance[period].error = true;
              // this.totalRow.totalAccountBalance[period].error = true;
            }
          }
          break;
        case SectionNameEnum.Bank_Accounts:
          key = "Total_Bank_Accounts_" + period.replace(" ", "_");
          refDataKey = Object.keys(this.pbcData).find((x) => x.substring(x.indexOf("_") + 1) === key);
          if (refDataKey && this.pbcData[refDataKey]) {
            if (Math.abs(this.headerRow.totalAccountBalance[period].value - this.pbcData[refDataKey].value) > 0.5) {
              // this.headerRow.totalAccountBalance[period].error = true;
              // this.totalRow.totalAccountBalance[period].error = true;
            }
          }
          break;
        case SectionNameEnum.Liabilities:
          key = "Total_Liabilities_" + period.replace(" ", "_");
          refDataKey = Object.keys(this.pbcData).find((x) => x.substring(x.indexOf("_") + 1) === key);
          if (refDataKey && this.pbcData[refDataKey]) {
            if (Math.abs(this.headerRow.totalAccountBalance[period].value - this.pbcData[refDataKey].value) > 0.5) {
              // this.headerRow.totalAccountBalance[period].error = true;
              // this.totalRow.totalAccountBalance[period].error = true;
            }
          }
          break;
        case SectionNameEnum.Equity:
          key = "Total_Equity_" + period.replace(" ", "_");
          refDataKey = Object.keys(this.pbcData).find((x) => x.substring(x.indexOf("_") + 1) === key);
          if (refDataKey && this.pbcData[refDataKey]) {
            if (Math.abs(this.headerRow.totalAccountBalance[period].value - this.pbcData[refDataKey].value) > 0.5) {
              // this.headerRow.totalAccountBalance[period].error = true;
              // this.totalRow.totalAccountBalance[period].error = true;
            }
          }
          break;
        case FormulaNameEnum.Net_Income:
          key = "Net_Income_" + period.replace(" ", "_");
          refDataKey = Object.keys(this.pbcData).find((x) => x.substring(x.indexOf("_") + 1) === key);
          if (refDataKey && this.pbcData[refDataKey]) {
            if (Math.abs(this.headerRow.totalAccountBalance[period].value - this.pbcData[refDataKey].value) > 0.5) {
              // this.headerRow.totalAccountBalance[period].error = true;
            }
          }
      }
    }
  }

  public createChildAccounts() {
    if (this.financialStatementReportType !== ReportTypeEnum.Cash_Flows) {
      if (this.accountTypeDict && this.accountListDict) {
        var NetIncomeBS;

        if (this.section === SectionNameEnum.Equity) {
          // create net income line in equity, bring it from balance sheet
          NetIncomeBS = new Account("Net Income", this.datesArray, this.accountListDict, this.pbcData, this.section, this.financialStatementReportType);
          this.childAccounts.push(NetIncomeBS);
        }

        if (this.section === SectionNameEnum.Equity) {
          // 'Equity' Section => Net Income Projections
          const NetIncomeObj = this.childAccounts.find((x) => x.name === "Net Income");
          if (NetIncomeObj && this.formulaData1 && this.last_date_of_actuals) {
            for (var i = 1; i < this.datesArray.length; i++) {
              if (this.datesArray[i] > this.last_date_of_actuals) {
                const period = getMonthShort(this.datesArray[i]);
                const quarter_name = getQuarter(this.datesArray[i], 0);
                const year_name = getYear(this.datesArray[i], 0);
                if (this.formulaData1[period]) {
                  const value = this.formulaData1[period].value;
                  NetIncomeObj.headerRow.accountBalance[period] = { value: value, error: false };

                  if (NetIncomeObj.headerRow.accountBalance[quarter_name]) {
                    NetIncomeObj.headerRow.accountBalance[quarter_name].value += value;
                  } else {
                    NetIncomeObj.headerRow.accountBalance[quarter_name] = { value: value, error: false };
                  }

                  if (NetIncomeObj.headerRow.accountBalance[year_name]) {
                    NetIncomeObj.headerRow.accountBalance[year_name].value += value;
                  } else {
                    NetIncomeObj.headerRow.accountBalance[year_name] = { value: value, error: false };
                  }
                }
              }
            }
          }
        }

        var childAccountsIds = this.accountTypeDict[this.section]["children"];
        for (var i = 0; i < childAccountsIds.length; i++) {
          const accountID = childAccountsIds[i];
          const accountObj = this.accountListDict[accountID];
          const accountSubType = this.accountListDict[accountID].accountSubType;
          if (accountSubType === AccountSubTypeEnum.Retained_Earnings) {
            var childAccount = new Account(
              accountObj,
              this.datesArray,
              this.accountListDict,
              this.pbcData,
              this.section,
              this.financialStatementReportType,
              undefined,
              NetIncomeBS.headerRow.accountBalance,
              this.last_date_of_actuals
            );
          } else {
            childAccount = new Account(accountObj, this.datesArray, this.accountListDict, this.pbcData, this.section, this.financialStatementReportType);
          }

          this.childAccounts.push(childAccount);
        }
      }
    }
  }

  public getAccountBalanceArray() {
    return this.headerRow.accountBalance;
  }

  public getTotalAccountBalanceArray() {
    return this.headerRow.totalAccountBalance;
  }

  public getTreeGridPresentation(): IgridRow[] {
    var result: IgridRow[] = [];
    if (this.formula) {
      result.push(this.headerRow);
    }
    if (this.childSections.length > 0) {
      result.push(this.headerRow);
      for (let index = 0; index < this.childSections.length; index++) {
        var section: IgridRow[] = this.childSections[index].getTreeGridPresentation();
        for (let i = 0; i < section.length; i++) {
          const lineHierarchy = section[i]["hierarchy"];
          const line = { ...section[i], hierarchy: [...this.hierarchy, ...lineHierarchy] };
          result.push(line);
        }
      }
      result.push(this.totalRow);
    } else {
      if (this.childAccounts.length > 0) {
        const childAccountsArray: IgridRow[] = [];
        for (let index = 0; index < this.childAccounts.length; index++) {
          if (this.childAccounts[index].parentRef === "") {
            // only process accounts at the root level of the section, because their children accounts will be displayed within them.
            var account: IgridRow[] = this.childAccounts[index].getTreeGridPresentation();
            for (var i = 0; i < account.length; i++) {
              const accountHierarchy = account[i]["hierarchy"];
              const x = { ...account[i], hierarchy: [...this.hierarchy, ...accountHierarchy] };
              childAccountsArray.push(x);
            }
          }
        }
        childAccountsArray.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
        result = [this.headerRow, ...childAccountsArray, this.totalRow];
      }
    }

    return result;
  }
}

export class CashFlowSection {
  datesArray: Date[] = [];
  last_date_of_actuals: Date = new Date();
  priorDate: Date | undefined;
  section: SectionNameEnum | FormulaNameEnum = SectionNameEnum.NO_SECTION;
  pbcData = {};
  balanceSheetReport: IgridRow[] = [];
  financialStatementReportType: ReportTypeEnum = ReportTypeEnum.Cash_Flows;
  parentSection: SectionNameEnum = SectionNameEnum.NO_SECTION;
  childSections: CashFlowSection[] = [];
  childAccounts: Account[] = [];
  hierarchy: string[] = [];
  formula: boolean = false;
  metaType: string = "";
  headerRow: IgridRow = {
    name: "",
    id: "",
    bold: true,
    hierarchy: [],
    metaType: "",
    accountBalance: {},
    totalAccountBalance: {},
  };
  totalRow: IgridRow = {
    name: "",
    id: "",
    bold: true,
    hierarchy: [],
    metaType: "",
    accountBalance: {},
    totalAccountBalance: {},
  };
  accountTypeDict: SectionItemData | null = null;
  accountListDict: IAccountsDict | null = null;

  constructor(
    pbcData: IgridRow[],
    last_date_of_actuals: Date,
    datesArray: Date[],
    financialStatementReportType: ReportTypeEnum = ReportTypeEnum.Cash_Flows,
    section: SectionNameEnum | FormulaNameEnum = SectionNameEnum.NO_SECTION,
    parentSection: SectionNameEnum = SectionNameEnum.NO_SECTION,
    childSections: CashFlowSection[] = [],
    accountTypeDict: SectionItemData | null = null,
    accountListDict: IAccountsDict | null = null,
    formulaData1?: IaccountBalanceArray | undefined,
    formulaData2?: IaccountBalanceArray | undefined,
    formulaData3?: IaccountBalanceArray | undefined,
    priorDate?: Date | undefined
  ) {
    this.datesArray = datesArray;
    this.priorDate = priorDate;
    this.last_date_of_actuals = last_date_of_actuals;
    this.balanceSheetReport = pbcData;
    this.accountListDict = accountListDict;
    this.accountTypeDict = accountTypeDict;
    this.financialStatementReportType = financialStatementReportType;
    if (
      section === FormulaNameEnum.Gross_Profit ||
      section === FormulaNameEnum.Net_Income ||
      section === FormulaNameEnum.Net_Operating_Income ||
      section === FormulaNameEnum.Net_Other_Income ||
      section === FormulaNameEnum.Cash_Burn ||
      section === FormulaNameEnum.Beginning_Ending_Cash ||
      section === FormulaNameEnum.Total_Cash_Flows
    )
      this.formula = true;
    this.section = section;
    this.parentSection = parentSection;
    this.childSections = childSections;
    this.metaType = this.formula ? section : "Section(" + section + ")";
    this.hierarchy = [this.metaType];

    this.createChildAccounts();

    this.headerRow = {
      name: this.section,
      id: this.metaType,
      bold: true,
      hierarchy: [this.metaType],
      metaType: "Section",
      accountBalance: {},
      totalAccountBalance: {},
    };

    if (this.section === SectionNameEnum.Δ_in_Current_Assets || this.section === SectionNameEnum.Δ_in_Current_Liabilities) {
      this.headerRow.metaType = "First Section";
    }

    if (!this.formula) {
      this.totalRow = {
        name: "Total " + this.section,
        id: "Sum(" + this.section + ")",
        bold: true,
        hierarchy: [...this.headerRow.hierarchy, "Sum(" + this.section + ")"],
        metaType: "Total Section",
        accountBalance: {},
        totalAccountBalance: {},
      };
    }

    if (this.formula) {
      if (formulaData1) {
        switch (this.section) {
          case FormulaNameEnum.Net_Income:
            for (var i = 0; i < this.datesArray.length; i++) {
              const period = getMonthShort(this.datesArray[i]);
              const quarter_name = getQuarter(this.datesArray[i], 0);
              const year_name = getYear(this.datesArray[i], 0);
              if (formulaData1[period]) {
                this.headerRow.totalAccountBalance[period] = { value: formulaData1[period].value, error: false };
                if (this.headerRow.totalAccountBalance[quarter_name]) {
                  this.headerRow.totalAccountBalance[quarter_name].value += formulaData1[period].value;
                } else {
                  this.headerRow.totalAccountBalance[quarter_name] = { value: formulaData1[period].value, error: false };
                }

                if (this.headerRow.totalAccountBalance[year_name]) {
                  this.headerRow.totalAccountBalance[year_name].value += formulaData1[period].value;
                } else {
                  this.headerRow.totalAccountBalance[year_name] = { value: formulaData1[period].value, error: false };
                }
              }
            }
            this.headerRow.metaType = "";
            break;
          case FormulaNameEnum.Total_Cash_Flows:
            if (formulaData2 && formulaData3) {
              for (i = 0; i < this.datesArray.length; i++) {
                const period = getMonthShort(this.datesArray[i]);
                const quarter_name = getQuarter(this.datesArray[i], 0);
                const year_name = getYear(this.datesArray[i], 0);
                if (formulaData1[period] && formulaData2[period] && formulaData3[period]) {
                  const value = formulaData1[period].value + formulaData2[period].value + formulaData3[period].value;
                  this.headerRow.totalAccountBalance[period] = {
                    value: value,
                    error: false,
                  };
                  if (this.headerRow.totalAccountBalance[quarter_name]) {
                    this.headerRow.totalAccountBalance[quarter_name].value += value;
                  } else {
                    this.headerRow.totalAccountBalance[quarter_name] = { value: value, error: false };
                  }

                  if (this.headerRow.totalAccountBalance[year_name]) {
                    this.headerRow.totalAccountBalance[year_name].value += value;
                  } else {
                    this.headerRow.totalAccountBalance[year_name] = { value: value, error: false };
                  }
                }
              }
              this.headerRow.metaType = "Formula";
            }
            break;
          case FormulaNameEnum.Beginning_Ending_Cash:
            this.headerRow.name = FormulaNameEnum.Beginning_Cash;
            this.headerRow.id = FormulaNameEnum.Beginning_Cash;
            this.totalRow = {
              name: FormulaNameEnum.Ending_Cash,
              id: FormulaNameEnum.Ending_Cash,
              bold: true,
              hierarchy: [FormulaNameEnum.Ending_Cash],
              metaType: this.formula ? "Formula" : "Total Section",
              accountBalance: {},
              totalAccountBalance: {},
            };
            var beginningCash;
            const totalBankAccountsObj = this.balanceSheetReport.find((x) => x.id === "Sum(" + SectionNameEnum.Bank_Accounts + ")");
            const headerBankAccountsObj = this.balanceSheetReport.find((x) => x.id === "Section(" + SectionNameEnum.Bank_Accounts + ")");
            const totalCurrentAssetsObj = this.balanceSheetReport.find((x) => x.id === "Sum(" + SectionNameEnum.Current_Assets + ")");
            const headerCurrentAssetsObj = this.balanceSheetReport.find((x) => x.id === "Section(" + SectionNameEnum.Current_Assets + ")");
            const totalAssetsObj = this.balanceSheetReport.find((x) => x.id === "Sum(" + SectionNameEnum.Assets + ")");
            const headerAssetsObj = this.balanceSheetReport.find((x) => x.id === "Section(" + SectionNameEnum.Assets + ")");
            if (priorDate) {
              beginningCash = totalBankAccountsObj?.totalAccountBalance[getMonthShort(priorDate)].value;
            }
            var prev_quarter_name = getQuarter(this.datesArray[0], 0);
            var prev_year_name = getYear(this.datesArray[0], 0);
            let firstQuarter = true;
            let firstYear = true;
            for (i = 0; i < this.datesArray.length; i++) {
              const period = getMonthShort(this.datesArray[i]);
              const quarter_name = getQuarter(this.datesArray[i], 0);
              const year_name = getYear(this.datesArray[i], 0);

              if (i === 0) {
                if ((beginningCash || beginningCash === 0) && formulaData1[period]) {
                  const value = beginningCash + formulaData1[period].value;
                  const quarterValue = beginningCash + formulaData1[quarter_name].value;
                  const yearValue = beginningCash + formulaData1[year_name].value;
                  this.headerRow.totalAccountBalance[period] = { value: beginningCash, error: false };
                  this.headerRow.totalAccountBalance[quarter_name] = { value: beginningCash, error: false };
                  this.headerRow.totalAccountBalance[year_name] = { value: beginningCash, error: false };

                  this.totalRow.totalAccountBalance[period] = { value: value, error: false };
                  this.totalRow.totalAccountBalance[quarter_name] = { value: quarterValue, error: false };
                  this.totalRow.totalAccountBalance[year_name] = { value: yearValue, error: false };

                  if (totalBankAccountsObj && totalBankAccountsObj.totalAccountBalance[period]) {
                    if (Math.abs(this.totalRow.totalAccountBalance[period].value - totalBankAccountsObj.totalAccountBalance[period].value) > 0.5) {
                      // this.totalRow.totalAccountBalance[period].error = true;
                    }
                  }
                } else {
                  this.headerRow.totalAccountBalance[period] = { value: 0, error: false };
                  this.headerRow.totalAccountBalance[quarter_name] = { value: 0, error: false };
                  this.headerRow.totalAccountBalance[year_name] = { value: 0, error: false };
                }
              } else {
                const prevPeriod = getMonthShort(this.datesArray[i - 1]);
                const x = getQuarter(this.datesArray[i - 1], 0);
                if (x !== quarter_name) {
                  prev_quarter_name = x;
                  firstQuarter = false;
                }
                const y = getYear(this.datesArray[i - 1], 0);
                if (y !== year_name) {
                  prev_year_name = y;
                  firstYear = false;
                }

                if (this.totalRow.totalAccountBalance[prevPeriod]) {
                  this.headerRow.totalAccountBalance[period] = {
                    value: this.totalRow.totalAccountBalance[prevPeriod].value,
                    error: false,
                  };
                  if (this.totalRow.totalAccountBalance[prev_quarter_name] && !firstQuarter) {
                    this.headerRow.totalAccountBalance[quarter_name] = {
                      value: this.totalRow.totalAccountBalance[prev_quarter_name].value,
                      error: false,
                    };
                  }

                  if (this.totalRow.totalAccountBalance[prev_year_name] && !firstYear) {
                    this.headerRow.totalAccountBalance[year_name] = {
                      value: this.totalRow.totalAccountBalance[prev_year_name].value,
                      error: false,
                    };
                  }
                }

                // ENDING CASH CALCULATIONS: BEGINNING CASH + TOTAL CASH FLOWS
                if (this.headerRow.totalAccountBalance[period] && formulaData1[period]) {
                  const value = this.headerRow.totalAccountBalance[period].value + formulaData1[period].value;
                  this.totalRow.totalAccountBalance[period] = {
                    value: value,
                    error: false,
                  };
                  if (totalBankAccountsObj && totalCurrentAssetsObj && headerBankAccountsObj && headerCurrentAssetsObj && this.datesArray[i] > this.last_date_of_actuals) {
                    totalBankAccountsObj.totalAccountBalance[period].value = value;
                    headerBankAccountsObj.totalAccountBalance[period].value = value;
                    if (totalAssetsObj && totalAssetsObj.totalAccountBalance[period] && headerAssetsObj) {
                      totalAssetsObj.totalAccountBalance[period].value += value;
                      headerAssetsObj.totalAccountBalance[period].value += value;
                      headerCurrentAssetsObj.totalAccountBalance[period].value += value;
                      totalCurrentAssetsObj.totalAccountBalance[period].value += value;
                    }
                  }

                  if (!this.totalRow.totalAccountBalance[quarter_name]) {
                    if (this.headerRow.totalAccountBalance[quarter_name] && formulaData1[quarter_name]) {
                      const quarterValue = this.headerRow.totalAccountBalance[quarter_name].value + formulaData1[quarter_name].value;
                      this.totalRow.totalAccountBalance[quarter_name] = { value: quarterValue, error: false };
                      if (totalBankAccountsObj && totalCurrentAssetsObj && headerCurrentAssetsObj && headerBankAccountsObj && this.datesArray[i] > this.last_date_of_actuals) {
                        totalBankAccountsObj.totalAccountBalance[quarter_name].value = this.totalRow.totalAccountBalance[quarter_name].value;
                        headerBankAccountsObj.totalAccountBalance[quarter_name].value = this.totalRow.totalAccountBalance[quarter_name].value;
                        totalCurrentAssetsObj.totalAccountBalance[quarter_name].value += value;
                        headerCurrentAssetsObj.totalAccountBalance[quarter_name].value += value;
                        if (totalAssetsObj && headerAssetsObj && totalAssetsObj.totalAccountBalance[quarter_name]) {
                          totalAssetsObj.totalAccountBalance[quarter_name].value += value;
                          headerAssetsObj.totalAccountBalance[quarter_name].value += value;
                        }
                      }
                    }
                  }
                  if (this.totalRow.totalAccountBalance[year_name]) {
                    // this.totalRow.totalAccountBalance[year_name].value += value
                    if (totalBankAccountsObj && headerCurrentAssetsObj && totalCurrentAssetsObj && headerBankAccountsObj && this.datesArray[i] > this.last_date_of_actuals) {
                      totalBankAccountsObj.totalAccountBalance[year_name].value = this.totalRow.totalAccountBalance[year_name].value;
                      headerBankAccountsObj.totalAccountBalance[year_name].value = this.totalRow.totalAccountBalance[year_name].value;
                      totalCurrentAssetsObj.totalAccountBalance[year_name].value += value;
                      headerCurrentAssetsObj.totalAccountBalance[year_name].value += value;
                      if (totalAssetsObj && headerAssetsObj && totalAssetsObj.totalAccountBalance[year_name]) {
                        totalAssetsObj.totalAccountBalance[year_name].value += value;
                        headerAssetsObj.totalAccountBalance[year_name].value += value;
                      }
                    }
                  } else {
                    if (this.headerRow.totalAccountBalance[year_name] && formulaData1[year_name]) {
                      const yearValue = this.headerRow.totalAccountBalance[year_name].value + formulaData1[year_name].value;
                      this.totalRow.totalAccountBalance[year_name] = { value: yearValue, error: false };
                    }
                  }
                }

                if (totalBankAccountsObj && totalBankAccountsObj[period]) {
                  if (Math.abs(this.totalRow.totalAccountBalance[period].value - totalBankAccountsObj[period].value) > 0.5) {
                    // this.totalRow.totalAccountBalance[period].error = true;
                  }
                }
              }
            }
            this.headerRow.metaType = "Formula";
            this.totalRow.metaType = "Formula";
            break;
          case FormulaNameEnum.Cash_Burn:
            if (formulaData1 && formulaData2) {
              for (i = 0; i < this.datesArray.length; i++) {
                const period = getMonthShort(this.datesArray[i]);
                const quarter_name = getQuarter(this.datesArray[i], 0);
                const year_name = getYear(this.datesArray[i], 0);
                if (formulaData1[period] && formulaData2[period]) {
                  this.headerRow.totalAccountBalance[period] = { value: formulaData1[period].value + formulaData2[period].value, error: false };
                  this.headerRow.totalAccountBalance[quarter_name] = { value: formulaData1[quarter_name].value + formulaData2[quarter_name].value, error: false };
                  this.headerRow.totalAccountBalance[year_name] = { value: formulaData1[year_name].value + formulaData2[year_name].value, error: false };
                }
              }
            }
            this.headerRow.metaType = "Formula";
            break;
        }
      }
    }

    var children;
    var childrenType: string = "";
    if (this.childAccounts.length > 0) {
      children = this.childAccounts;
      childrenType = "Accounts";
    } else if (this.childSections.length > 0) {
      children = this.childSections;
      childrenType = "Sections";
    }

    if (children?.length > 0) {
      // get the accountBalance and totalAccountBalance for all the children
      var accountBalanceArray: IaccountBalanceArray[] = [];
      var totalAccountBalanceArray: IaccountBalanceArray[] = [];
      for (var j = 0; j < children.length; j++) {
        const child = children[j];
        // each object in the array contains the values of the child
        if (childrenType === "Sections") {
          totalAccountBalanceArray.push(children[j].getTotalAccountBalanceArray());
        } else {
          const accountBalance = child.getAccountBalanceArray();

          if (
            !((o) => {
              for (let k in o) return false;
              return true;
            })(accountBalance)
          ) {
            accountBalanceArray.push(children[j].getAccountBalanceArray());
            // each object in the array contains the totals for all the descendants of the child
            const totalAccountBalance = child.getTotalAccountBalanceArray();
            if (
              !((o) => {
                for (let k in o) return false;
                return true;
              })(totalAccountBalance)
            ) {
              totalAccountBalanceArray.push(totalAccountBalance);
            } else {
              totalAccountBalanceArray.push(accountBalance);
            }
          } else {
            // discuss with Ibrahim tegarding targeting the particular type of children
            if (childrenType === "Accounts") {
              const filteredAccounts = this.childAccounts.filter((item) => item.id !== child.id);
              this.childAccounts = filteredAccounts;
            } else {
              const filteredSections = this.childSections.filter((item) => item.metaType !== child.metaType);
              this.childSections = filteredSections;
            }
          }
        }
      }

      // accountBalanceArray gets summed to get the total of the children, which, become the values for the totalRow of this section
      if (childrenType === "Accounts") {
        for (i = 0; i < this.datesArray.length; i++) {
          const period = getMonthShort(this.datesArray[i]);
          const quarter_name = getQuarter(this.datesArray[i], 0);
          const year_name = getYear(this.datesArray[i], 0);
          var childrenTotal = 0;
          for (j = 0; j < this.childAccounts.length; j++) {
            const accountBalance = this.childAccounts[j].getAccountBalanceArray();
            if (
              !((o) => {
                for (let k in o) return false;
                return true;
              })(accountBalance)
            ) {
              const x = accountBalance[period];
              if (x) {
                childrenTotal += x.value;
              }
            }
          }
          this.totalRow.totalAccountBalance[period] = { value: childrenTotal, error: false };
          if (this.totalRow.totalAccountBalance[quarter_name]) {
            this.totalRow.totalAccountBalance[quarter_name].value += childrenTotal;
          } else {
            this.totalRow.totalAccountBalance[quarter_name] = { value: childrenTotal, error: false };
          }
          if (this.totalRow.totalAccountBalance[year_name]) {
            this.totalRow.totalAccountBalance[year_name].value += childrenTotal;
          } else {
            this.totalRow.totalAccountBalance[year_name] = { value: childrenTotal, error: false };
          }

          this.headerRow.totalAccountBalance[period] = { value: childrenTotal, error: false };
          if (this.headerRow.totalAccountBalance[quarter_name]) {
            this.headerRow.totalAccountBalance[quarter_name].value += childrenTotal;
          } else {
            this.headerRow.totalAccountBalance[quarter_name] = { value: childrenTotal, error: false };
          }
          if (this.headerRow.totalAccountBalance[year_name]) {
            this.headerRow.totalAccountBalance[year_name].value += childrenTotal;
          } else {
            this.headerRow.totalAccountBalance[year_name] = { value: childrenTotal, error: false };
          }
        }
      } else {
        // if the children are sections
        for (i = 0; i < this.datesArray.length; i++) {
          const period = getMonthShort(this.datesArray[i]);
          const quarter_name = getQuarter(this.datesArray[i], 0);
          const year_name = getYear(this.datesArray[i], 0);
          childrenTotal = 0;
          for (j = 0; j < totalAccountBalanceArray.length; j++) {
            const x = totalAccountBalanceArray[j][period];
            if (x) {
              childrenTotal += x.value;
            }
          }
          this.totalRow.totalAccountBalance[period] = { value: childrenTotal, error: false };
          if (this.totalRow.totalAccountBalance[quarter_name]) {
            this.totalRow.totalAccountBalance[quarter_name].value += childrenTotal;
          } else {
            this.totalRow.totalAccountBalance[quarter_name] = { value: childrenTotal, error: false };
          }
          if (this.totalRow.totalAccountBalance[year_name]) {
            this.totalRow.totalAccountBalance[year_name].value += childrenTotal;
          } else {
            this.totalRow.totalAccountBalance[year_name] = { value: childrenTotal, error: false };
          }

          this.headerRow.totalAccountBalance[period] = { value: childrenTotal, error: false };
          if (this.headerRow.totalAccountBalance[quarter_name]) {
            this.headerRow.totalAccountBalance[quarter_name].value += childrenTotal;
          } else {
            this.headerRow.totalAccountBalance[quarter_name] = { value: childrenTotal, error: false };
          }
          if (this.headerRow.totalAccountBalance[year_name]) {
            this.headerRow.totalAccountBalance[year_name].value += childrenTotal;
          } else {
            this.headerRow.totalAccountBalance[year_name] = { value: childrenTotal, error: false };
          }
        }
      }
    }
  }

  public createChildAccounts() {
    var accountTypes: string[] = [];
    for (var key in AccountTypeToCFSection) {
      if (AccountTypeToCFSection[key].section === this.section) {
        accountTypes.push(key);
      }
    }

    if (this.accountTypeDict && this.accountListDict) {
      for (var i = 0; i < accountTypes.length; i++) {
        const accountType = accountTypes[i];
        const section = Object.keys(sectionNameToAccountType).find((key) => sectionNameToAccountType[key] === accountType);
        if (section) {
          var childAccountsIds = this.accountTypeDict[section]["children"];
          for (var j = 0; j < childAccountsIds.length; j++) {
            const accountID = childAccountsIds[j];
            const accountObj = this.accountListDict[accountID];
            if (accountObj.accountSubType !== AccountSubTypeEnum.Retained_Earnings) {
              var childAccount = new Account(accountObj, this.datesArray, this.accountListDict, this.balanceSheetReport, this.section, this.financialStatementReportType, this.priorDate);
              this.childAccounts.push(childAccount);
            }
          }
        }
      }
    }
  }

  private verifyError() {
    for (var i = 0; i < this.datesArray.length; i++) {
      const period = getMonthShort(this.datesArray[i]);
      var key = "";
      switch (this.section) {
        case SectionNameEnum.Revenue:
          key = "Total_Revenue_" + period.replace(" ", "_");
          if (this.pbcData[key]) {
            if (Math.abs(this.headerRow.totalAccountBalance[period].value - this.pbcData[key]) > 0.5) {
              // this.headerRow.totalAccountBalance[period].error = true;
              // this.totalRow.totalAccountBalance[period].error = true;
            }
          }
          break;
        case SectionNameEnum.Cost_Of_Goods_Sold:
          key = "Total_Cost_of_Goods_Sold_" + period.replace(" ", "_");
          if (this.pbcData[key]) {
            if (Math.abs(this.headerRow.totalAccountBalance[period].value - this.pbcData[key]) > 0.5) {
              // this.headerRow.totalAccountBalance[period].error = true;
              // this.totalRow.totalAccountBalance[period].error = true;
            }
          }
          break;
        case SectionNameEnum.Expenses:
          key = "Total_Expenses_" + period.replace(" ", "_");
          if (this.pbcData[key]) {
            if (Math.abs(this.headerRow.totalAccountBalance[period].value - this.pbcData[key]) > 0.5) {
              // this.headerRow.totalAccountBalance[period].error = true;
              // this.totalRow.totalAccountBalance[period].error = true;
            }
          }
          break;
        case SectionNameEnum.Other_Income:
          key = "Total_Other_Income_" + period.replace(" ", "_");
          if (this.pbcData[key]) {
            if (Math.abs(this.headerRow.totalAccountBalance[period].value - this.pbcData[key]) > 0.5) {
              // this.headerRow.totalAccountBalance[period].error = true;
              // this.totalRow.totalAccountBalance[period].error = true;
            }
          }
          break;
        case SectionNameEnum.Other_Expenses:
          key = "Total_Other_Expenses_" + period.replace(" ", "_");
          if (this.pbcData[key]) {
            if (Math.abs(this.headerRow.totalAccountBalance[period].value - this.pbcData[key]) > 0.5) {
              // this.headerRow.totalAccountBalance[period].error = true;
              // this.totalRow.totalAccountBalance[period].error = true;
            }
          }
          break;
        case SectionNameEnum.Assets:
          key = "TOTAL_ASSETS_" + period.replace(" ", "_");
          if (this.pbcData[key]) {
            if (Math.abs(this.headerRow.totalAccountBalance[period].value - this.pbcData[key]) > 0.5) {
              // this.headerRow.totalAccountBalance[period].error = true;
              // this.totalRow.totalAccountBalance[period].error = true;
            }
          }
          break;
        case SectionNameEnum.Bank_Accounts:
          key = "Total_Bank_Accounts_" + period.replace(" ", "_");
          if (this.pbcData[key]) {
            if (Math.abs(this.headerRow.totalAccountBalance[period].value - this.pbcData[key]) > 0.5) {
              // this.headerRow.totalAccountBalance[period].error = true;
              // this.totalRow.totalAccountBalance[period].error = true;
            }
          }
          break;
        case SectionNameEnum.Liabilities:
          key = "Total_Liabilities_" + period.replace(" ", "_");
          if (this.pbcData[key]) {
            if (Math.abs(this.headerRow.totalAccountBalance[period].value - this.pbcData[key]) > 0.5) {
              // this.headerRow.totalAccountBalance[period].error = true;
              // this.totalRow.totalAccountBalance[period].error = true;
            }
          }
          break;
        case SectionNameEnum.Equity:
          key = "Total_Equity_" + period.replace(" ", "_");
          if (this.pbcData[key]) {
            if (Math.abs(this.headerRow.totalAccountBalance[period].value - this.pbcData[key]) > 0.5) {
              // this.headerRow.totalAccountBalance[period].error = true;
              // this.totalRow.totalAccountBalance[period].error = true;
            }
          }
          break;
      }
    }
  }

  public getAccountBalanceArray() {
    return this.headerRow.accountBalance;
  }

  public getTotalAccountBalanceArray() {
    return this.headerRow.totalAccountBalance;
  }

  public getTreeGridPresentation(): IgridRow[] {
    var result: IgridRow[] = [];
    result.push(this.headerRow);
    if (this.childSections.length > 0) {
      for (let index = 0; index < this.childSections.length; index++) {
        var section: IgridRow[] = this.childSections[index].getTreeGridPresentation();
        for (let i = 0; i < section.length; i++) {
          const lineHierarchy = section[i]["hierarchy"];
          const line = { ...section[i], hierarchy: [...this.hierarchy, ...lineHierarchy] };
          result.push(line);
        }
      }
    } else {
      for (let index = 0; index < this.childAccounts.length; index++) {
        // only process accounts at the root level of the section, because their children accounts will be displayed within them.
        var account: IgridRow[] = this.childAccounts[index].getTreeGridPresentation();
        for (var i = 0; i < account.length; i++) {
          const accountHierarchy = account[i].hierarchy;
          account[i] = { ...account[i], hierarchy: [this.metaType, ...accountHierarchy] };
          result.push(account[i]);
        }
      }
    }
    if (!this.formula || this.metaType === FormulaNameEnum.Beginning_Ending_Cash) {
      result.push(this.totalRow);
    }

    return result;
  }
}
