import React from "react";
import TransactionLineTable from "./TransactionLineTable";

const BillTransaction = ({ transaction }) => {
  return (
    <div>
      <div className="TransactionDetail__GroupContainer">
      <div className="TransactionDetail__ParameterContainer">
        <span>Vendor</span>
        <div>{transaction?.["detail"]?.["Bill"]?.["VendorRef"]?.["name"]}</div>
      </div>
      <div className="TransactionDetail__ParameterContainer">
        <span>Billing address</span>
        <div className="TransactionDetail__ParameterContainer__MultiLine">
        <span>{transaction?.["detail"]?.["Bill"]?.["VendorAddr"]?.["Line1"]}</span>
        <span>{transaction?.["detail"]?.["Bill"]?.["VendorAddr"]?.["City"]},{transaction?.["detail"]?.["Bill"]?.["VendorAddr"]?.["CountrySubDivisionCode"]}</span>
        <span>{transaction?.["detail"]?.["Bill"]?.["VendorAddr"]?.["PostalCode"]}</span>
        </div>
      </div>
      </div>
      <TransactionLineTable lines={transaction?.["detail"]?.["Bill"]?.["Line"]} transactionID={transaction?.["detail"]?.["Bill"]?.["Id"]} transaction_type="Bill" />
      <div className="TransactionDetail__Subtotal">
        <span>Total Amount</span>
        <div>${transaction?.["detail"]?.["Bill"]?.["TotalAmt"]}</div>
      </div>
    </div>
  );
};

export default BillTransaction;
