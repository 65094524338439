import { useContext, useEffect } from "react";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { MilestoneContext } from "../../../../../contexts/TicketingSystem/MilestoneContext";
import AgedReports from "./Container";
import QBONotConnected from "../Financials/QBONotConnected";

const AgedReportsRoot = ({CustomEndDate, parentTaskName}) => {
    const { userProfile,selectedCompany } = useContext(AuthContext);
    const { setFullWidthMode } = useContext(MilestoneContext);

    
  
    useEffect(() => {
      setFullWidthMode(true);
  
      return () => {
        setFullWidthMode(false);
      };
    }, []);
    return (
      <>
        { selectedCompany?.is_connected === true ?(
            <AgedReports CustomEndDate={CustomEndDate} parentTaskName={parentTaskName}/>
        )
        :
        <QBONotConnected />
      
      }
      </>
    );
  };
  
  export default AgedReportsRoot;