import React, { useContext, useEffect, useState } from "react";
import MDIcons from "../../../../../Common/MDIcons";
import { MonthEndCloseContext } from "../../../../../../contexts/TicketingSystem/MonthEndCloseContext";
import MonthEndComment from "./MonthEndComment";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useSearchParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useCellComments } from "../../../../hooks/useComments";
import CommentSkeleton from "./CommentSkeleton";

const MonthEndCommentsList = () => {
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();

  // Contexts
  const { commentsTabIndex, setCommentsTabIndex, showCommentsPan, setShowCommentsPan, setSelectedCommentID, createCommentMutation, selectedCommentID } =
    useContext(MonthEndCloseContext);

  // useQuery
  const cellComments = useCellComments();
  

  const onToggleCommentsPan = () => {
    setShowCommentsPan(!showCommentsPan);

    if (showCommentsPan === true) {
      searchParams.delete("comment");
      setSearchParams(searchParams);
      setSelectedCommentID(null);
    }
  };

  const onChangeCommentsTab = (index) => {
    setCommentsTabIndex(index);
    updateCellHighlighting(index);
  };

  const updateCellHighlighting = (index) => {
    queryClient.invalidateQueries(["PLTransactionsReportData"]);
    queryClient.invalidateQueries(["BSTransactionsReportData"]);
  };

  

  useEffect(() => {
    updateCellHighlighting(commentsTabIndex);
  }, [cellComments.data]);

  
  return showCommentsPan ? (
    <div className="MonthEndComments__Container">
      <div className="MonthEndComments__Container__Title">
        <div id="close-comments-pan" onClick={onToggleCommentsPan}>
          <MDIcons title="chevron__right__double" />
        </div>

        <Tabs className="cell-comments__tabs" data-test="cell-comments__tabs" selectedIndex={commentsTabIndex} onSelect={onChangeCommentsTab}>
          <TabList>
            <Tab id="tab-unresolved" className="cell_comments_tab_link">
              Open Items
            </Tab>
            <Tab id="tab-resolved" className="cell_comments_tab_link">
              Resolved Items
            </Tab>
          </TabList>
          <TabPanel />
          <TabPanel />
        </Tabs>
      </div>
      <div className="MonthEndComments__Container__List">
        {commentsTabIndex === 0
          ? cellComments.data?.filter((x) => x.asana_task?.completed === false)
              .map((x) => {
                return (
                  <div key={x.id} className={`MonthEndComments__Comment__Container${selectedCommentID === x?.id ? " SelectedCommentContainer" : ""}`}>
                    <MonthEndComment comment={x} />
                  </div>
                );
              })
          : cellComments.data?.filter((x) => x.asana_task?.completed === true)
              .map((x) => {
                return (
                  <div key={x.id} className={`MonthEndComments__Comment__Container${selectedCommentID === x?.id ? " SelectedCommentContainer" : ""}`}>
                    <MonthEndComment comment={x} />
                  </div>
                );
              })}

        {createCommentMutation.isLoading ? <CommentSkeleton /> : <></>}
      </div>
    </div>
  ) : (
    <>
      <div className="MonthEndComments__Container--collapse MonthEndComments__Container">
        <div className="MonthEndComments__Container__Title">
          <div id="open-comments-pan" onClick={onToggleCommentsPan}>
            <MDIcons title="chevron__left__double" />
          </div>
          <div onClick={onToggleCommentsPan} className="MonthEndComments__Container__Title__DiscussionIconContainer">
            <MDIcons title="discuss__icon" />
            {cellComments?.data?.filter((x) => x.asana_task?.completed === false).length > 0 && (
              <div className="MonthEndComments__Container__Title__DiscussionIconContainer__UnresolvedCount">
                {cellComments?.data?.filter((x) => x.asana_task?.completed === false).length}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MonthEndCommentsList;
