import { useEffect, useState } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { userDataForm, disabledUserFields } from "../../../config/invitationConfigs";
import { uppercaseWords } from "../../../utils/helpers";
import CircularLoader from "../../Common/CircularLoader";
import { acceptOnboardingInvite, invitationOnboardingCompleteProfile } from "../../../APIFunctions/auth";
import { UserProfile } from "../../../types/Auth";
import { InvitationCode__RequestAPI, InvitationCompleteProfileAPI } from "../../../types/API/authData";
import "../Register/Register.css";
import success_people_celebrating from "../../../static/img/success_people_celebrating.png";
import { Onboarding } from "../../../types/Onboarding";

const initialState = {
  first_name: "",
  last_name: "",
  email: "",
  companyName: "",
  password: "",
  confirm_password: "",
  completed_profile: false,
};

const initialErrors = {
  first_name: false,
  last_name: false,
  password: false,
  confirm_password: false,
  privacyAndTermsCheck: false,
  shortPassword: false,
};

interface RouteParams {
  inviteCode: string;
}

export default function OnboardingInvitation() {
  const location = useLocation();
  const { inviteCode } = useParams();
  const [formState, setFormState] = useState(initialState);
  const [errors, setErrors] = useState(initialErrors);
  const [privacyTermsChecked, setPrivacyTermsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    async function verify(): Promise<void> {
      if (inviteCode) {
        setIsLoading(true);
        const data: InvitationCode__RequestAPI = { invitation_code: inviteCode };
        const result = await acceptOnboardingInvite(data);
        if (result.onboarding) {
          const invitationUser: UserProfile = new UserProfile(result.profile);
          const invitationOnboarding: Onboarding = result.onboarding as Onboarding;
          setFormState((prev) => ({
            ...prev,
            first_name: invitationUser.first_name,
            last_name: invitationUser.last_name,
            email: invitationUser.email,
            completed_profile: invitationUser.completed_profile,
            companyName: invitationOnboarding.companyName,
          }));
          setIsLoading(false);
        } else navigate("/");
      }
    }
    if (inviteCode && inviteCode !== "") verify();
  }, [location, inviteCode]);

  const onDataChange = (e: React.ChangeEvent<HTMLInputElement>, itemKey: string) => {
    setFormState((prev) => ({ ...prev, [itemKey]: e.target.value }));
  };

  const validateUserData = (): boolean => {
    let errors = { ...initialErrors };
    if (formState.first_name === "") errors.first_name = true;
    if (formState.last_name === "") errors.last_name = true;
    if (formState.password === "") errors.password = true;
    if (formState.password.length <= 8 && formState.password.length > 0) errors.shortPassword = true;
    if (formState.confirm_password === "" || formState.confirm_password.length <= 8 || formState.confirm_password !== formState.password) errors.confirm_password = true;
    if (!privacyTermsChecked) errors.privacyAndTermsCheck = true;
    setErrors(errors);
    return !Object.values(errors).includes(true);
  };

  const submitcompleteProfile = async () => {
    if (validateUserData() && inviteCode) {
      const data: InvitationCompleteProfileAPI = {
        first_name: formState.first_name,
        last_name: formState.last_name,
        password: formState.password,
      };
      const result = await invitationOnboardingCompleteProfile(data, inviteCode);
      const invitationUser: UserProfile = new UserProfile(result);
      setFormState((prev) => ({
        ...prev,
        first_name: invitationUser.first_name,
        last_name: invitationUser.last_name,
        email: invitationUser.email,
        completed_profile: invitationUser.completed_profile,
      }));
    }
  };
  return (
    <div className="Registration__Container">
      {isLoading ? (
        <>
          <h4>Please wait! We are validating your invitation link</h4>
          <CircularLoader />
        </>
      ) : (
        <>
          <h3>
            Hi! You successfully accepted the invitation to on-board <b>{formState.companyName}</b>
          </h3>

          {formState.completed_profile ? (
            <>
              <img src={success_people_celebrating} alt={""} />
              <h4>
                Your are ready to go now!, Click <Link to={"/"}> here </Link> to login.
              </h4>
            </>
          ) : (
            <>
              <h4 className="completeInfo">Please complete your information</h4>
              <hr />
              <div className="Registration__Container_Entry_Row">
                <div className="Registration__Container__EntryContainer">
                  <label htmlFor={"first_name"}>{"First Name"}</label>
                  <input
                    type={"text"}
                    disabled={disabledUserFields.includes("first_name") || isLoading}
                    readOnly={disabledUserFields.includes("first_name")}
                    name={"first_name"}
                    id={"first_name"}
                    value={formState["first_name"]}
                    onChange={(e) => onDataChange(e, "first_name")}
                  />
                  {errors["first_name"] && <span style={{ color: "red", fontWeight: 700 }}>{"First Name"} is required</span>}
                </div>
                <div className="Registration__Container__EntryContainer">
                  <label htmlFor={"last_name"}>{"Last Name"}</label>
                  <input
                    type={"text"}
                    disabled={disabledUserFields.includes("last_name") || isLoading}
                    readOnly={disabledUserFields.includes("last_name")}
                    name={"last_name"}
                    id={"last_name"}
                    value={formState["last_name"]}
                    onChange={(e) => onDataChange(e, "last_name")}
                  />
                  {errors["last_name"] && <span style={{ color: "red", fontWeight: 700 }}>{"Last Name"} is required</span>}
                </div>
              </div>
              <div className="Registration__Container_Entry_Row">
                <div className="Registration__Container__EntryContainer">
                  <label htmlFor={"email"}>{"Email"}</label>
                  <input
                    type={"email"}
                    disabled={disabledUserFields.includes("email") || isLoading}
                    readOnly={disabledUserFields.includes("email")}
                    name={"email"}
                    id={"email"}
                    value={formState["email"]}
                    onChange={(e) => onDataChange(e, "email")}
                  />
                  {errors["email"] && <span style={{ color: "red", fontWeight: 700 }}>{"Email"} is required</span>}
                </div>
                <div className="Registration__Container__EntryContainer">
                  <label htmlFor={"companyName"}>{"Company Name"}</label>
                  <input
                    type={"text"}
                    disabled={disabledUserFields.includes("companyName") || isLoading}
                    readOnly={disabledUserFields.includes("companyName")}
                    name={"companyName"}
                    id={"companyName"}
                    value={formState["companyName"]}
                    onChange={(e) => onDataChange(e, "companyName")}
                  />
                  {errors["companyName"] && <span style={{ color: "red", fontWeight: 700 }}>{"Company Name"} is required</span>}
                </div>
              </div>
              {userDataForm.map((item) => {
                const itemName = uppercaseWords(item);
                return (
                  <div key={item} className="Registration__Container__EntryContainer">
                    <label htmlFor={item}>{itemName}</label>
                    <input type="password" disabled={isLoading} name={item} id={item} value={formState[item]} onChange={(e) => onDataChange(e, item)} />
                    {errors[item] && <span style={{ color: "red", fontWeight: 700 }}>{itemName} is required</span>}
                    {item === "password" && errors["shortPassword"] && <span style={{ color: "red", fontWeight: 700 }}>{itemName} is too short</span>}
                  </div>
                );
              })}
              <div className="TermsNServices__Container" style={{ display: "inline-flex", justifyContent: "flex-start", width: "100%", flexDirection: "column" }}>
                <FormControlLabel
                  control={<Checkbox required color="secondary" sx={{ marginLeft: -1.5, "& > svg": { width: 20, height: 20 } }} checked={privacyTermsChecked} />}
                  label=<div>I agree to the Mighty Digits <a href="https://www.mightydigits.com/terms-of-service" rel="noreferrer" target="_blank">Terms of Service</a> and <a href="https://www.mightydigits.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>.</div>
                  onChange={(e, checked) => setPrivacyTermsChecked(checked)}
                  disabled={isLoading}
                />
                {errors.privacyAndTermsCheck && <span style={{ color: "red", fontWeight: 700 }}>Please aceept the terms and privacy</span>}
              </div>
              <div style={{ display: "inline-flex", justifyContent: "stretch", width: "100%" }}>
                <button className="btn--primary btn--L btn--fullWidth" disabled={isLoading} style={{ width: "100%" }} onClick={() => submitcompleteProfile()}>
                  Register
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
