
export const LIVE_BASE_URL = "https://admin.mightydigits.com/api/"
export const STAGING_BASE_URL = "https://staging.admin.mightydigits.com/api/"
export const DEV_BASE_URL = "https://dev4.admin.mightydigits.com/api/"
export const LOCAL_BASE_URL = "http://localhost:8000/api/"

export const CURRENT_BASE_SERVER_URL = LIVE_BASE_URL



export const LIVE_WS_BASE_URL = "wss://admin.mightydigits.com/ws/"
export const STAGING_WS_BASE_URL = "wss://staging.admin.mightydigits.com/ws/"
export const DEV_WS_BASE_URL = "wss://dev4.admin.mightydigits.com/ws/"
export const LOCAL_WS_BASE_URL = "ws://127.0.0.1:8000/ws/"

export const CURRENT_WS_BASE_SERVER_URL = LIVE_WS_BASE_URL
