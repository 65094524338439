import { OptionTypeBase } from "react-select";
import { getFiscalYearStartAndEnd } from "../../utils/DateFunctions";


export interface ISelectedFilters {
    [key: string]: OptionTypeBase[];
}

export class FinancialStatementSelectedFilters {
    private __fiscalYearStartDate: Date = new Date(new Date().getFullYear(), 0, 1);
    private __fiscalYearEndDate: Date = new Date(new Date().getFullYear(), 0, 1);
    private __startDate: Date = new Date(new Date().getFullYear(), 0, 1);
    private __endDate: Date = new Date(new Date().getFullYear(), 7, 1);
    private __options: ISelectedFilters = {};
    private __offset: number = 0

    constructor(offset: number = 0) {
        const FiscalYearDates: Date[] = getFiscalYearStartAndEnd(offset)
        this.__offset = offset;
        this.__startDate = this.__fiscalYearStartDate = FiscalYearDates[0];
        this.__endDate = this.__fiscalYearEndDate = FiscalYearDates[1];
        this.__options = {}
    }
    private clone(): FinancialStatementSelectedFilters {
        var cloned: FinancialStatementSelectedFilters = new FinancialStatementSelectedFilters(this.__offset)
        Object.keys(this).forEach(key => {
            if (key in this)
                cloned[key] = this[key]
        })
        return cloned
    }

    public get startDate() {
        return this.__startDate;
    }
    public get endDate() {
        return this.__endDate;
    }
    public filterSelectedOptions(filterName: string) {
        return this.__options[filterName] ? this.__options[filterName] : [];
    }
    public updateDate(startDate: Date, endDate: Date): FinancialStatementSelectedFilters {
        this.__startDate = startDate;
        this.__endDate = endDate;
        return this.clone()
    }
    public resetFilters(): FinancialStatementSelectedFilters {
        this.__startDate = this.__fiscalYearStartDate;
        this.__endDate = this.__fiscalYearEndDate;
        this.__options = {}
        return this.clone()
    }
    public updateSelectedOptions(key: string, slectedOptions: OptionTypeBase[]): FinancialStatementSelectedFilters {
        this.__options[key] = slectedOptions
        return this.clone()
    }


    private isDefaultDate(): boolean {
        return this.startDate === this.__fiscalYearStartDate && this.endDate === this.__fiscalYearEndDate
    }
    public filterCount(): number {
        let count = !this.isDefaultDate() ? 1 : 0;
        Object.keys(this.__options).map((key) => count += this.__options[key].length)
        return count;
    }
}