import React, { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { QBOFilterContext } from "../../Context/FilterContext";
import Presentation from "./Presentation";

const Container = () => {
  const { setStartDate, startDate, endDate, setEndDate, setSelectedFilters, selectedFilters } = useContext(QBOFilterContext);
  const { isModalOpen } = useContext(QBOFilterContext);

  return (
    <Presentation
      isModalOpen={isModalOpen}
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
      selectedFilters={selectedFilters}
      setSelectedFilters={setSelectedFilters}
    />
  );
};

export default Container;
