import React, { useContext } from 'react'
import PermanentLink from './Links/PermanentLink'
import { MilestoneLinksContext } from '../../../contexts/TicketingSystem/MilestoneLinksContext';
import { MilestoneContext } from '../../../contexts/TicketingSystem/MilestoneContext';
import PayrollFileLink from '../PayrollViews/Components/PayrollFileLink';


const DefaultMilestoneFileLinksUI = () => {
    const { milestoneType } = useContext(MilestoneContext)
const { monthEndBackupFileLink, monthEndDeferredRevenueFileLink } = useContext(MilestoneLinksContext);
  return (
    <div className="MilestoneCard__Container__Header__FileLink">
        {milestoneType === 0 &&
        <>
        <PayrollFileLink />
        </>
}
        {milestoneType === 1 && <>
        <PermanentLink link={monthEndBackupFileLink} areActionsAllowed={true} showAddButton={true} />
        <PermanentLink link={monthEndDeferredRevenueFileLink} areActionsAllowed={true} showAddButton={true} />
        </>}
        
        
    </div>
  )
}

export default DefaultMilestoneFileLinksUI