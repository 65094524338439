import { useRef, useState } from "react";
import { Cropper } from "react-cropper";
import AlertDialog from "./AlertDialog";

const ImageCropper  = ({image, setImage, originalImage, isCropDialogOpen, setIsCropDialogOpen}) => {
    const cropperRef = useRef<HTMLImageElement>(null);
    const [preview, setPreview] = useState("");
  
    const onCrop = () => {
      const imageElement: any = cropperRef?.current;
      const cropper: any = imageElement?.cropper;
      setPreview(cropper.getCroppedCanvas().toDataURL());
    };
  
    
  
    return (image ?
      <>
        <AlertDialog
          dialogType="user-form"
          open={isCropDialogOpen}
          onClose={() => setIsCropDialogOpen(false)}
          title={<span className="BulkAddEmployee__Title"> <h3 style={{ fontWeight: 700 }}>{"Crop Logo Image"}</h3> </span>}
          dialogActions={
            <>
              <div className='action_btns_container'>
                <button className={"btn--tertiary btn--M"} onClick={() => setImage(originalImage)}>Reset Crop</button>
                <button className="btn--primary btn--M" onClick={() => {setImage(preview); setIsCropDialogOpen(false);}}>Set Crop</button>
              </div>
            </>}
        >
          <div className="image-cropper-container">
            <Cropper
              src={image}
              className="Cropper-Component"
              // Cropper.js options
              aspectRatio={1}
              guides={false}
              crop={onCrop}
              ref={cropperRef}
            />
  
  
          </div>
        </AlertDialog>
  
      </>
      :
      <></>
    );
  };

  export default ImageCropper;