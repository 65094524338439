import {  useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { createTheme, ThemeProvider } from "@mui/material";
import { deleteAsanaTask } from "../../../APIFunctions/Asana";
import { ModuleConverter } from "../../../types/CompanyModules";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import Icons from "../../SideBar/Icons";
import MDIcons from "../MDIcons";

export default function SideBarMenu({ list,type }) {
  const {selectedCompany } = useContext(AuthContext)
  const navigate = useNavigate();
  const location = useLocation();
  const theme = createTheme({
    components: {
      // Name of the component
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            border: "none !important",
            width: "3rem",
            minWidth: "0rem",
            "&:hover": {
              backgroundColor: "var(--Primary100) !important",
              svg: {
                path: {
                  fill: "var(--Primary600)",
                },
              },
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: "#b72b1a !important",
            ".normal-action": {
              color: "var(--Neutral900)",
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          root: {
            width:"28.6rem"
          },
        },
      }
    },
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        id={type}
        onClick={handleClick}
        // id={type === "comment" ? "" : "success"} className='btn--tertiary btn--M--centered selectTaskActions'
      >
      <MDIcons title={type === "Accounting" ? "accounting" : "data_analysis"} />

      </Button>
      <Menu
      style={{width:"24rem !important", maxWidth:"24rem !important"}}
        className="side-nav__listContainer"
        id={`${type}-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {list?.map((url) => {
          return (
            <MenuItem
            key={url}
            id={`${ModuleConverter.UrlToName(url)}-menu-item`}
            onClick={() => {navigate(`/${selectedCompany?.getURL()}/${url}`); handleClose();}}
            className={location.pathname === `/${selectedCompany?.getURL()}/${url}` ? " active" : ""}
            ><Icons iconName={ModuleConverter.UrlToName(url)} pathname={location.pathname} url={`/${selectedCompany?.getURL()}/${url}`} />
            <span className={location.pathname === `/${selectedCompany?.getURL()}/${url}` ? " active" : ""}>{ModuleConverter.UrlToName(url)}</span></MenuItem>
          );
        })}
      </Menu>
    </ThemeProvider>
  );
}
