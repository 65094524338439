import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { createAsanaSubTask, retrieveAsanaSubTask, retrieveAsanaSubTaskBatch } from "../../APIFunctions/Asana";
import { MilestoneContext } from "./MilestoneContext";
import { dateObjToAsanaString } from "../../utils/DateFunctions";
import { feedbackListType } from "../../components/TicketingSystem/types/feedback_tasks";

export interface SubTasksContextProps {
  preparationStageTasks: any[];
  updatePreparationStageTasks: (updatedSubTask: any) => void;
  subTasksDict: any;
  editSubTasksDict: (milestoneTask: any, subTask: any, action: "add" | "edit" | "delete") => void;
  addSubTask: (milestoneTask: any) => any;
  setSubTasksToDisplay: (dict: any, milestoneTaskGID: string) => void;
  displayedSubTasks: any[] | undefined;
  setDisplayedSubTasks: (displayedSubTasks: any[]) => void;
  openSubTask: (subTask: any) => void;
  subTask: any;
  setSubTask: (subTask: any) => void;
  displaySubTaskFlag: boolean;
  setDisplaySubTaskFlag: (displaySubTaskFlag: boolean) => void;
  displayMilestoneCardContent: boolean;
  setDisplayMilestoneCardContent: (displayMilestoneCardContent: boolean) => void;
  staffAssignee: any;
  feedbackList:feedbackListType[];
}

export const SubTasksContext = createContext<SubTasksContextProps>(null as any);

export const SubTasksContextProvider = (props) => {
  const { subTasks, setPayrollLoading, setFullLoadedFlag, milestoneType, setSubTasksAcceptanceArray, setMilestoneTasksCompletionArray, prevStage } = useContext(MilestoneContext);
  const [preparationStageTasks, setPreparationStageTasks] = useState<any[]>([]);
  const [staffAssignee, setStaffAssignee] = useState<any>();
  const [subTasksDict, setSubTasksDict] = useState<any>();
  const [displayedSubTasks, setDisplayedSubTasks] = useState<any[]>();
  const [subTask, setSubTask] = useState<any>();
  const [displaySubTaskFlag, setDisplaySubTaskFlag] = useState<boolean>(false);
  const [displayMilestoneCardContent, setDisplayMilestoneCardContent] = useState<boolean>(true);
  const [feedbackList, setFeedbackList] = useState<feedbackListType[]>([])

  
  


  const openSubTask = (subTask) => {
    setSubTask(subTask);
    setDisplaySubTaskFlag(true);
  };

  const updatePreparationStageTasks = (updatedSubTask) => {
    const newArray = preparationStageTasks.map((x) => (x.gid === updatedSubTask.gid ? updatedSubTask : x));
    setPreparationStageTasks(newArray);
  };

  const setSubTasksToDisplay = (dict, gid) => {
    setDisplayedSubTasks(dict[gid]);
  };




  const getFeedbackList = (subTasksDict) => {
    let newArray: feedbackListType[] = [];
    for (var i = 0; i < preparationStageTasks?.length; i++) {
      const preparationTask = preparationStageTasks[i];
      if (preparationTask.gid in subTasksDict) {
        const feedbackTasks = subTasksDict[preparationTask.gid];
        for (var j = 0; j < feedbackTasks.length; j++) {
          const newObj = { task: feedbackTasks[j], milestoneTask: preparationTask };
          newArray.push(newObj);
        }
      }
    }
    // setFeedbackList(newArray)
    setFeedbackList(newArray)
  } 

  const editSubTasksDict = (milestoneTaskGID, subTask, action) => {
    let newDict = { ...subTasksDict };
    let newArray;
    if (action === "edit") {
      newArray = newDict[milestoneTaskGID].map((x) => (x.gid !== subTask.gid ? x : subTask));
    } else if (action === "add") {
      newDict[milestoneTaskGID].push(subTask);
      newArray = newDict[milestoneTaskGID];
      setDisplayedSubTasks(newArray);
      getFeedbackList(newDict)
    } else if (action === "delete") {
      newArray = newDict[milestoneTaskGID].filter((x) => x.gid !== subTask.gid);
    }
    newDict[milestoneTaskGID] = newArray;
    setSubTasksDict(newDict);
    getFeedbackList(newDict)
    setSubTasksToDisplay(newDict, milestoneTaskGID);
  };

  const addSubTask = async (milestoneTaskGID) => {
    const today = dateObjToAsanaString(new Date());
    const newSubTaskObj = {
      data: { assignee: prevStage?.assignee ? prevStage?.assignee?.gid : staffAssignee.gid, due_on: today },
    };
    setPayrollLoading(true);
    const response = await createAsanaSubTask(milestoneTaskGID, newSubTaskObj);
    if (response.data) {
      setPayrollLoading(false);
      editSubTasksDict(milestoneTaskGID, response.data, "add");
      return response.data;
    } else if (response.response?.status === 401) {
      setTimeout(() => {
        addSubTask(milestoneTaskGID);
      }, 1000);
    }
  };

  const getPreparationStageTasks = async () => {
    const preparationTask = subTasks.find((x) => x.name === "Staff Preparation");

    if (preparationTask) {
      setStaffAssignee(preparationTask["assignee"]);
      const response = await retrieveAsanaSubTask(preparationTask?.gid);
      if (response.data) {
        let subTasksArray = response.data;
        const newAcceptanceArray: any = [];
        const newCompletionArray: any = [];
        for (let i = 0; i < response.data.length; i++) {
          newAcceptanceArray.push({ gid: response.data[i]["gid"], state: "" });
          newCompletionArray.push({ gid: response.data[i]["gid"], completed: response.data[i]["completed"] });
        }
        setSubTasksAcceptanceArray(newAcceptanceArray);
        setMilestoneTasksCompletionArray(newCompletionArray);
        setPreparationStageTasks(subTasksArray);

        // cycle through and get the subtasks for each preparation task
        let tempSubTasksDict: any = {};
        let gidList: string[] = [];

        for (let i = 0; i < subTasksArray.length; i++) {
          gidList.push(subTasksArray[i].gid);
        }

        const result = await retrieveAsanaSubTaskBatch(gidList);
        for (let i = 0; i < gidList.length; i++) {
          // to filter for the comments-related tasks; add x.tags.filter((y) => y.name === "cell-comment")
          const filteredTasks = result[i].data.filter((x) => x.name !== "Final Review" && x.name !== "Manager Review" && x.name !== "Senior Manager Review");
          tempSubTasksDict[gidList[i]] = filteredTasks;
        }
        setSubTasksDict(tempSubTasksDict);
        
      }
    }
  };

  useEffect(() => {
    if (subTasksDict){
      getFeedbackList(subTasksDict)
    }
  },[preparationStageTasks,subTasksDict])

  useEffect(() => {
    getPreparationStageTasks();
    if (milestoneType === 1) {
      setFullLoadedFlag(true);
    }
  }, [subTasks]);

  return (
    <SubTasksContext.Provider
      value={{
        preparationStageTasks,
        updatePreparationStageTasks,
        subTasksDict,
        editSubTasksDict,
        addSubTask,
        setSubTasksToDisplay,
        displayedSubTasks,
        setDisplayedSubTasks,
        subTask,
        setSubTask,
        displaySubTaskFlag,
        setDisplaySubTaskFlag,
        openSubTask,
        displayMilestoneCardContent,
        setDisplayMilestoneCardContent,
        staffAssignee,
        feedbackList
      }}
    >
      {props.children}
    </SubTasksContext.Provider>
  );
};
