import { Fragment, useContext, useEffect, useState } from 'react';
import { OptionTypeBase } from 'react-select';
import DatePicker from 'react-datepicker';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Checkbox } from '@mui/material';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import NumberFormat from 'react-number-format';
import './EmployeeDialog.css';
import { initialEditBulkState, initialErrors, initialState } from './initialObjects';
import { AuthContext } from '../../../../contexts/AuthContext';
import { DataConext } from '../../../../contexts/HeadCount/DataConext';
import { EmployeeDialogType, HeadCountInformation } from '../../../../types/HeadCount';
import { dateFromString, displayDateFormatter, stringToDateConverter, uppercaseWords } from '../../../../utils/helpers';
import CreatableSelectComponent from '../../../Common/CreatableSelectComponent';
import { editAddEmployeeCreatableFilter, editAddEmployeeDisplayForm, editAddEmployeeFormFilter } from '../../../../config/headcountConfigs';
import SelectComponent from '../../../Common/SelectComponent';
import { EmployeeListConext } from '../../../../contexts/HeadCount/EmployeeListConext';
import AlertDialog from '../../../Common/AlertDialog';
import { IFilterOptionItem } from '../../../../types/CompanyFilters';
import CloseConfirmDialog from './CloseConfirmDialog';

export default function EmployeeDialog() {
    const { selectedCompany, companyFilter, updateFiltersAfterAppend } = useContext(AuthContext);
    initialState['company'] = selectedCompany?.id

    const { headCountDisplay } = useContext(DataConext)
    const {
        onClickSelectedItem,
        applyAddEmployeeItem,
        applyUpdateEmployeeItem,
        openDeleteDialoge,
        employeeDialogType,
        employeeDialogIsOpenByType,
        selectedFlatRows,
        closeEmployeeDialog,
        applyBulkEdit,
    } = useContext(EmployeeListConext);

    const isAddDialog: boolean = employeeDialogType === EmployeeDialogType.ADD;
    const isEditBulkDialog: boolean = employeeDialogType === EmployeeDialogType.BULK_EDIT;

    const [selectedEmployeeItem, setSelectedEmployeeItem] = useState<HeadCountInformation | any>(isAddDialog ? initialState : isEditBulkDialog ? initialEditBulkState : onClickSelectedItem);
    const [selectedEmployeeItemErrors, setSelectedEmployeeItemErrors] = useState(initialErrors);
    const [allowEndDateUpdate, setAllowEndDateUpdate] = useState(false);
    const [startDateDisclamer, setStartDateDisclamer] = useState<Date | null>(null);
    const [endDateDisclamer, setEndDateDisclamer] = useState<Date | null>(null);
    const [closeConfirmDialogOpen, setCloseConfirmDialogOpen] = useState(false);

    const [loaders, setLoaders] = useState<{ [x: string]: boolean; }>({
        "job_title": false,
        "location": false,
        "department": false,
    });

    /** Add Employee logic start */

    const validateEmployeeObject = (): typeof selectedEmployeeItemErrors => {
        let errors = { ...initialErrors };
        if (employeeDialogType === EmployeeDialogType.BULK_EDIT) {
            if (selectedEmployeeItem === initialEditBulkState)
                errors = { ...errors, job_title: true };
        }
        else {

            if (selectedEmployeeItem.job_title === '') errors.job_title = true;
            if (selectedEmployeeItem.department === '-1') errors.department = true;
            if (selectedEmployeeItem.type === -1) errors.type = true;
            if (!selectedEmployeeItem.salary) errors.salary = true;
            if (parseInt(selectedEmployeeItem.salary, 10) <= 0) errors.salary = true;
            if (!selectedEmployeeItem.start_date) errors.start_date = true;
            if (selectedEmployeeItem.repeat_times < 1) errors.repeat_times = true;
            if (selectedEmployeeItem.repeat_times > 10) errors.repeat_times = true;
        }

        if (selectedEmployeeItem.schedule_raise_amount > 0 && selectedEmployeeItem.schedule_raise_date === null) errors.schedule_raise_date = true;
        if (selectedEmployeeItem.schedule_raise_date !== null && !selectedEmployeeItem.schedule_raise_amount) errors.schedule_raise_amount = true;

        if (selectedEmployeeItem.schedule_bonus_amount > 0 && selectedEmployeeItem.schedule_bonus_repeat_every === null) errors.schedule_bonus_repeat_every = true;
        if (selectedEmployeeItem.schedule_bonus_repeat_every !== null && !selectedEmployeeItem.schedule_bonus_amount) errors.schedule_bonus_amount = true;

        if (selectedEmployeeItem.schedule_commission_amount > 0 && selectedEmployeeItem.schedule_commission_repeat_every === null) errors.schedule_commission_repeat_every = true;
        if (selectedEmployeeItem.schedule_commission_repeat_every !== null && !selectedEmployeeItem.schedule_commission_amount) errors.schedule_commission_amount = true;
        return errors;
    }
    const onAddClick = async () => {
        var errors = validateEmployeeObject()
        if (!Object.values(errors).includes(true)) {
            setSelectedEmployeeItem(initialState);
            closeEmployeeDialog();
            applyAddEmployeeItem(selectedEmployeeItem);
        }
    }

    const onAddDialogClose = () => {
        if (selectedEmployeeItem !== initialState) {
            setCloseConfirmDialogOpen(true);
        } else {
            closeEmployeeDialog();
        }
    }

    /** Add Employee logic end */


    /** Bulk edit employee logic start */

    const onBulkUpdateClick = async () => {
        if (selectedEmployeeItem === initialEditBulkState)
            return
        setSelectedEmployeeItem(initialState);
        closeEmployeeDialog();
        if (selectedCompany) {
            var updates = {}
            Object.keys(selectedEmployeeItem).forEach(key => {
                if (selectedEmployeeItem[key] !== "" && selectedEmployeeItem[key] !== "-1" && selectedEmployeeItem[key] !== null) {
                    if (key.includes("date")) {
                        updates[key] = stringToDateConverter(selectedEmployeeItem[key] as any) as any
                    }
                    else
                        updates[key] = selectedEmployeeItem[key]
                }
                else {
                    if (key === 'end_date' && allowEndDateUpdate)
                        updates[key] = null

                }
            })
            applyBulkEdit(updates)
        }
    }
    const onUpdateDialogClose = () => {
        if (selectedEmployeeItem !== initialState) {
            setCloseConfirmDialogOpen(true);
        } else {
            closeEmployeeDialog();
        }
    }

    /** Bulk edit employee logic end */

    /** Shared logic start */

    const onDateChange = (date: Date | null, itemKey: string) => {
        const newItem = { ...selectedEmployeeItem };
        switch (employeeDialogType) {
            case EmployeeDialogType.EDIT:
                if (date)
                    newItem[itemKey] = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
                else
                    newItem[itemKey] = null;
                break;
            case EmployeeDialogType.ADD:
            case EmployeeDialogType.BULK_EDIT:
                newItem[itemKey] = date;
                break;
            default:
                break;
        }
        setSelectedEmployeeItem(newItem as any);
        if (employeeDialogType === EmployeeDialogType.BULK_EDIT) {
            if (itemKey === "start_date") {
                var new_start_date = date
                if (new_start_date === null)
                    setStartDateDisclamer(null)
                else {
                    // in case the user set the start_date and end_date 
                    if (selectedEmployeeItem['end_date']) {
                        setStartDateDisclamer(null)
                        setEndDateDisclamer(null)
                    }
                    else {
                        var max_end_date = headCountDisplay.minEndDate([...selectedFlatRows])
                        if (max_end_date && new_start_date && new_start_date > max_end_date) {
                            setStartDateDisclamer(max_end_date)
                        }
                        else {
                            setStartDateDisclamer(null)
                        }
                    }
                }

            }
            if (itemKey === "end_date") {
                var new_end_date = date
                if (new_end_date === null)
                    setEndDateDisclamer(null)
                else {
                    // in case the user set the start_date and end_date 
                    if (selectedEmployeeItem['start_date']) {
                        setStartDateDisclamer(null)
                        setEndDateDisclamer(null)
                    }
                    else {
                        var min_start_date = headCountDisplay.maxStartDate([...selectedFlatRows])
                        if (min_start_date && new_end_date && new_end_date < min_start_date) {
                            setEndDateDisclamer(min_start_date)
                        }
                        else {
                            setEndDateDisclamer(null)
                        }
                    }
                }
            }
        }
    };

    const onTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>, itemKey: string) => {
        const newItem = { ...selectedEmployeeItem };
        newItem[itemKey] = e.currentTarget.value;
        setSelectedEmployeeItem(newItem as any);
    };
    const preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };
    const onItemsChange = (e: React.ChangeEvent<HTMLInputElement>, itemKey: string) => {
        const newItem = { ...selectedEmployeeItem };
        var value = e.currentTarget.value
        var numberFields = ["salary", "schedule_commission_amount", "schedule_raise_amount", "schedule_bonus_amount"]
        if (numberFields.includes(itemKey)) {
            if (value[0] === "$")
                value = value.substring(1);
            if (value.length === 0)
                value = '0'
            if (value.length >= 1) {
                if (value[0] === "0")
                    value = value.substring(1);
                value = value.replaceAll(",", "");
            }
        }
        newItem[itemKey] = value === "" ? null : value;
        setSelectedEmployeeItem(newItem as any);
    };

    const onSelectChange = (value: OptionTypeBase, itemKey: string) => {
        const newItem = { ...selectedEmployeeItem };
        if (!value)
            newItem[itemKey] = value;
        else
            newItem[itemKey] = value.value;
        setSelectedEmployeeItem(newItem as any);
    };

    const onCreatableSelectChange = (value: string, itemKey: string) => {
        const newItem = { ...selectedEmployeeItem };
        newItem[itemKey] = value;
        setSelectedEmployeeItem(newItem as any);
    };

    const onCreateNewSelectOption = async (
        name: string,
        prop_name: string
    ): Promise<IFilterOptionItem | null> => {
        setLoaders({ [prop_name]: true });
        const newfilter = await updateFiltersAfterAppend(prop_name, name);
        if (newfilter) {
            const newItem = { ...selectedEmployeeItem };
            newItem[prop_name] = newfilter.value;
            setSelectedEmployeeItem(newItem as any);
            setLoaders({ [prop_name]: false });
            return newfilter;
        } else {
            setLoaders({ [prop_name]: false });
        }
        return null;
    };

    useEffect(() => {
        setSelectedEmployeeItem(isAddDialog ? initialState : isEditBulkDialog ? initialEditBulkState : onClickSelectedItem);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onClickSelectedItem, isAddDialog, isEditBulkDialog]);

    /** Shared logic end */


    /** Dialog texts and actions start */
    let dialogTitle = 'Add new employee';
    let dialogCloseButtonAction = () => onAddDialogClose();
    let dialogLeftButtonText = 'Cancel';
    let dialogLeftButtonAction = () => onAddDialogClose();
    let dialogRightButtonText = 'Add Employee';
    let dialogRightButtonAction = () => onAddClick();

    switch (employeeDialogType) {
        case EmployeeDialogType.BULK_EDIT:
            dialogTitle = `Edit bulk ${selectedFlatRows.length} employees`;
            dialogCloseButtonAction = () => onUpdateDialogClose();
            dialogLeftButtonText = '';
            dialogRightButtonText = 'Update';
            dialogRightButtonAction = () => onBulkUpdateClick();
            break;
        case EmployeeDialogType.EDIT:
            dialogTitle = 'Edit employee';
            dialogCloseButtonAction = () => closeEmployeeDialog();
            dialogLeftButtonText = 'Delete';
            dialogLeftButtonAction = () => openDeleteDialoge();
            dialogRightButtonText = 'Save';
            dialogRightButtonAction = () => applyUpdateEmployeeItem(selectedEmployeeItem);
            break;
        default:
            break;
    }
    /** Dialog texts and actions end */

    if (!employeeDialogIsOpenByType[employeeDialogType]) return <></>;
    if (!selectedEmployeeItem) return <></>;
    return (
        <AlertDialog
            dialogType="user-form"
            open={employeeDialogIsOpenByType[employeeDialogType]}
            onClose={dialogCloseButtonAction}
            title={<span> <h3 style={{ fontWeight: 600 }}>{dialogTitle}</h3> </span>}
            dialogActions={
                <>
                    {dialogLeftButtonText !== '' &&
                        <button className="btn--secondary-danger btn--M"
                            onClick={() => dialogLeftButtonAction()}>
                            <span>{dialogLeftButtonText}</span>
                        </button>
                    }
                    {employeeDialogType === EmployeeDialogType.ADD &&
                        <div className={`replicate-input ${selectedEmployeeItemErrors["repeat_times"] && 'error'}`}>
                            <div>
                                <label htmlFor="repeat_times">Replicate</label>
                                <input
                                    type="number"
                                    name="repeat_times"
                                    min="1"
                                    max="10"
                                    placeholder="Ex. 1"
                                    value={selectedEmployeeItem["repeat_times"]}
                                    onChange={(e) => onItemsChange(e, "repeat_times")}
                                />
                            </div>
                            {selectedEmployeeItemErrors["repeat_times"] && <span className="errorMsg">Valid numbers only from 1 to 10</span>}
                        </div>
                    }
                    <button
                        className={(Object.values(validateEmployeeObject()).includes(true)) ? "btn--primary--disabled btn--M" : "btn--primary btn--M"}
                        onClick={() => {
                            var form_errors = validateEmployeeObject()
                            if ((Object.values(form_errors).includes(true)))
                                setSelectedEmployeeItemErrors(form_errors)
                            else
                                dialogRightButtonAction()
                        }}
                        autoFocus
                    >
                        <span>{dialogRightButtonText}</span>
                    </button>
                </>
            }
        >
            <div className="EmployeeAddTable">
                <Tabs>
                    <TabList>
                        <Tab>INFORMATION</Tab>
                        <Tab>RAISES & BONUS OR COMMISSION</Tab>
                    </TabList>

                    <TabPanel>
                        {editAddEmployeeDisplayForm.map((item) => {
                            const itemName = uppercaseWords(item);
                            return (
                                <Fragment key={item}>
                                    {employeeDialogType === EmployeeDialogType.BULK_EDIT &&
                                        <>
                                            {item === 'note' && startDateDisclamer &&
                                                <div className='information--small'>
                                                    <ErrorRoundedIcon fontSize="large" sx={{ color: "#85B440" }} />
                                                    End-Dates like {displayDateFormatter(startDateDisclamer)} and dates after it, will be set to equal your selected start date
                                                </div>
                                            }
                                            {item === 'note' && endDateDisclamer &&
                                                <div className='information--small'>
                                                    <ErrorRoundedIcon fontSize="large" sx={{ color: "#85B440" }} />
                                                    Start-Dates like {displayDateFormatter(endDateDisclamer)} and dates before it, will be set to equal your selected End date
                                                </div>
                                            }
                                        </>
                                    }
                                    <div className={item === 'note' ? "employeeAddContainerParent_Note" : "employeeAddContainerParent"}>
                                        <div className="employeeAddContainer">
                                            {editAddEmployeeFormFilter.includes(item) ? (
                                                <>
                                                    <label htmlFor={item}>{itemName}</label>
                                                    {editAddEmployeeCreatableFilter.includes(item) ? (
                                                        <CreatableSelectComponent
                                                            key={item}
                                                            options={companyFilter.lookUpOption(item,true)}
                                                            value={
                                                                companyFilter.lookUpOption(item,true).filter(
                                                                    (filter) => filter.value === selectedEmployeeItem[item]
                                                                )[0]
                                                            }
                                                            onChange={(value) =>
                                                                onCreatableSelectChange(value, item)
                                                            }
                                                            onCreate={(inputValue) =>
                                                                onCreateNewSelectOption(inputValue, item)
                                                            }
                                                            isLoading={loaders[item]}
                                                        />
                                                    ) : (
                                                        <SelectComponent
                                                            name={item}
                                                            options={companyFilter.lookUpOption(item,true)}
                                                            onChange={(value, _action) =>
                                                                onSelectChange(value, item)
                                                            }
                                                            isLoading={false}
                                                            internalLabel={false}
                                                            isSearchable={false}
                                                            value={companyFilter.lookUpOption(item,true).filter(
                                                                (filter) => filter.value === selectedEmployeeItem[item]
                                                            )}
                                                        />
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {employeeDialogType === EmployeeDialogType.BULK_EDIT && item === 'end_date' &&
                                                        <>
                                                            <div style={{ display: "flex" }}>
                                                                <Checkbox
                                                                    onChange={() => {
                                                                        onDateChange(null, "end_date");
                                                                        setAllowEndDateUpdate(!allowEndDateUpdate);
                                                                    }}
                                                                    checked={allowEndDateUpdate}
                                                                    sx={{ marginLeft: 0.5, zIndex: 0, '& > svg': { width: 20, height: 20 } }} />
                                                                <label htmlFor={item}>Allow End Date Update</label>
                                                            </div>
                                                        </>
                                                    }
                                                    {employeeDialogType === EmployeeDialogType.BULK_EDIT && item !== 'end_date' && <label htmlFor={item}>{itemName}</label>}
                                                    {employeeDialogType !== EmployeeDialogType.BULK_EDIT && <label htmlFor={item}>{itemName}</label>}
                                                    {item === "start_date" || item === "end_date" ? (
                                                        <DatePicker
                                                            selected={(isAddDialog || isEditBulkDialog) ? selectedEmployeeItem[item] :
                                                                selectedEmployeeItem[item]
                                                                    ? dateFromString(selectedEmployeeItem[item])
                                                                    : null
                                                            }
                                                            onChange={(date: Date) => onDateChange(date, item)}
                                                            maxDate={
                                                                item === "start_date"
                                                                    ? selectedEmployeeItem["end_date"]
                                                                        ? new Date(selectedEmployeeItem["end_date"])
                                                                        : null
                                                                    : null
                                                            }
                                                            minDate={
                                                                item === "end_date"
                                                                    ? new Date(selectedEmployeeItem["start_date"])
                                                                    : null
                                                            }
                                                            dateFormat="MMM dd,yyyy"
                                                            showYearDropdown
                                                            isClearable={item === "end_date"}
                                                            disabled={item === 'end_date' && employeeDialogType === EmployeeDialogType.BULK_EDIT && !allowEndDateUpdate}
                                                        />
                                                    ) : item === "note" ? (
                                                        <textarea
                                                            rows={3}
                                                            cols={11}
                                                            id={item}
                                                            name={item}
                                                            placeholder={"Type a note..."}
                                                            value={selectedEmployeeItem[item]}
                                                            onChange={(e) => onTextAreaChange(e, item)}
                                                        />
                                                    ) : item === "salary" ? (
                                                        <div className="employeeSalary">
                                                            <NumberFormat
                                                                thousandSeparator={true} onKeyPress={preventMinus}
                                                                prefix={'$'}
                                                                id={item}
                                                                name={item}
                                                                placeholder="Ex. 5000"
                                                                value={selectedEmployeeItem[item]}
                                                                onChange={(e) => onItemsChange(e, item)}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <input
                                                            type="text"
                                                            id={item}
                                                            name={item}
                                                            className="employee_input"
                                                            placeholder={itemName}
                                                            value={selectedEmployeeItem[item]}
                                                            onChange={(e) => onItemsChange(e, item)}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        {selectedEmployeeItemErrors[item] && <span className="errorMsg">{itemName} is required</span>}
                                    </div>
                                </Fragment>
                            );
                        })}
                    </TabPanel>
                    <TabPanel>
                        <div className="botSection__Container">
                            <div className="botSection__Row">
                                <div className="botSection__InputContainer">
                                    <label htmlFor="schedule_raise_amount">Raise</label>
                                    <div className='iconContainer_raisesbonuscommission'>
                                        <NumberFormat thousandSeparator={true} onKeyPress={preventMinus}
                                            prefix={'$'} id="schedule_raise_amount" name="schedule_raise_amount" placeholder="Ex.5000" value={selectedEmployeeItem['schedule_raise_amount']} onChange={(e) => onItemsChange(e, 'schedule_raise_amount')} />
                                    </div>
                                    {selectedEmployeeItemErrors["schedule_raise_amount"] && <span className="errorMsg">is required</span>}
                                </div>
                                <div className="botSection__InputContainer">
                                    <label htmlFor="schedule_raise_date">Date</label>
                                    <div className='datepicker'>
                                        <DatePicker
                                            selected={(isAddDialog || isEditBulkDialog) ? selectedEmployeeItem["schedule_raise_date"] :
                                                selectedEmployeeItem["schedule_raise_date"]
                                                    ? dateFromString(selectedEmployeeItem["schedule_raise_date"])
                                                    : null
                                            }
                                            onChange={(date: Date) => onDateChange(date, "schedule_raise_date")}
                                            dateFormat="MMMM yyyy"
                                            minDate={selectedEmployeeItem['start_date'] ? new Date(selectedEmployeeItem['start_date']) : null}
                                            maxDate={selectedEmployeeItem['end_date'] ? new Date(selectedEmployeeItem['end_date']) : null}
                                            showMonthYearPicker
                                            showFullMonthYearPicker
                                        />
                                    </div>
                                    {selectedEmployeeItemErrors["schedule_raise_date"] && <span className="errorMsg">is required</span>}
                                </div>
                            </div>

                            <div className="botSection__Row">
                                <div className="botSection__InputContainer">
                                    <label htmlFor="schedule_bonus_amount">Bonus</label>
                                    <div className='iconContainer_raisesbonuscommission'>
                                        <NumberFormat thousandSeparator={true} onKeyPress={preventMinus}
                                            prefix={'$'} id="schedule_bonus_amount" name="schedule_bonus_amount" placeholder="Ex.5000"
                                            value={selectedEmployeeItem['schedule_bonus_amount']} onChange={(e) => onItemsChange(e, 'schedule_bonus_amount')} />
                                    </div>
                                    {selectedEmployeeItemErrors["schedule_bonus_amount"] && <span className="errorMsg">is required</span>}
                                </div>
                                <div className="botSection__InputContainer">
                                    <label htmlFor="schedule_bonus_repeat_every">Repeat Every</label>
                                    <SelectComponent
                                        key={"schedule_bonus_repeat_every"}
                                        name={"schedule_bonus_repeat_every"}
                                        options={companyFilter.lookUpOption("repeat_every")}
                                        onChange={(value) => onSelectChange(value, "schedule_bonus_repeat_every")}
                                        isLoading={false}
                                        internalLabel={false}
                                        isSearchable={false}
                                        isClearable={true}
                                        value={companyFilter.lookUpOption("repeat_every").filter(filter => filter.value === selectedEmployeeItem["schedule_bonus_repeat_every"])}
                                    />
                                    {selectedEmployeeItemErrors["schedule_bonus_repeat_every"] && <span className="errorMsg">is required</span>}
                                </div>
                            </div>
                            <div className="botSection__Row">
                                <div className="botSection__InputContainer">
                                    <label htmlFor="schedule_commission_amount">Commission</label>
                                    <div className='iconContainer_raisesbonuscommission'>
                                        <NumberFormat thousandSeparator={true} onKeyPress={preventMinus}
                                            prefix={'$'} id="schedule_commission_amount" name="schedule_commission_amount" placeholder="Ex.5000"
                                            value={selectedEmployeeItem['schedule_commission_amount']} onChange={(e) => onItemsChange(e, 'schedule_commission_amount')} />
                                    </div>
                                    {selectedEmployeeItemErrors["schedule_commission_amount"] && <span className="errorMsg">is required</span>}
                                </div>
                                <div className="botSection__InputContainer">
                                    <label htmlFor="schedule_commission_repeat_every">Repeat Every</label>
                                    <SelectComponent
                                        key={"schedule_commission_repeat_every"}
                                        name={"schedule_commission_repeat_every"}
                                        options={companyFilter.lookUpOption("repeat_every")}
                                        onChange={(value) => onSelectChange(value, "schedule_commission_repeat_every")}
                                        isLoading={false}
                                        internalLabel={false}
                                        isSearchable={false}
                                        isClearable={true}
                                        value={companyFilter.lookUpOption("repeat_every").filter(filter => filter.value === selectedEmployeeItem["schedule_commission_repeat_every"])}
                                    />
                                    {selectedEmployeeItemErrors["schedule_commission_repeat_every"] && <span className="errorMsg">is required</span>}
                                </div>

                            </div>
                        </div>
                    </TabPanel>
                </Tabs>
            </div>
            {closeConfirmDialogOpen && (
                <CloseConfirmDialog
                    open={closeConfirmDialogOpen}
                    onCancel={() => setCloseConfirmDialogOpen(false)}
                    onYes={() => closeEmployeeDialog()}
                />
            )}

        </AlertDialog>
    );
}