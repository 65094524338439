import { BalanceSheet, ProfiAndLoss } from "../API/FinancialReport";
import { CashFlowSection, IaccountBalanceArray, IAccountsDict, IgridRow, ReportTypeEnum, Section } from "./interfaces";
import { FormulaNameEnum, SectionItemData, SectionNameEnum } from "./Sections";

export class FinancialReportBaseClass {

    reportSections: Section[]|CashFlowSection[] = []

    public getData(): IgridRow[] {
        var result: IgridRow[] = []
        for (let i = 0; i < this.reportSections.length; i++) {
            result = result.concat(this.reportSections[i].getTreeGridPresentation())
        }
        return result
    }
}

export class PLReport extends FinancialReportBaseClass {

    constructor(pbcData:ProfiAndLoss, datesArray:Date[],accountTypeDict: SectionItemData, accountListDict: IAccountsDict) {
        super()
        const revenueSection = new Section(pbcData,datesArray, ReportTypeEnum.Income_Statement, SectionNameEnum.Revenue, SectionNameEnum.NO_SECTION,[],accountTypeDict, accountListDict)
        const cogsSection = new Section(pbcData,datesArray, ReportTypeEnum.Income_Statement, SectionNameEnum.Cost_Of_Goods_Sold, SectionNameEnum.NO_SECTION,[],accountTypeDict, accountListDict)
        const revenueTotal: IaccountBalanceArray|undefined = revenueSection.getTreeGridPresentation().find(x => x.id === "Sum(" + SectionNameEnum.Revenue + ")")?.totalAccountBalance
        const cogsTotal: IaccountBalanceArray|undefined  = cogsSection.getTreeGridPresentation().find(x => x.id === "Sum(" + SectionNameEnum.Cost_Of_Goods_Sold + ")")?.totalAccountBalance
        const grossProfit = new Section(pbcData,datesArray, ReportTypeEnum.Income_Statement, FormulaNameEnum.Gross_Profit, SectionNameEnum.NO_SECTION,[],accountTypeDict, 
            accountListDict, revenueTotal, cogsTotal)
        const grossProfitTotal: IaccountBalanceArray|undefined  = grossProfit.getTreeGridPresentation().find(x => x.id === FormulaNameEnum.Gross_Profit)?.totalAccountBalance
        const expenseSection = new Section(pbcData,datesArray, ReportTypeEnum.Income_Statement, SectionNameEnum.Expenses, SectionNameEnum.NO_SECTION,[],accountTypeDict, accountListDict)
        const expensesTotal: IaccountBalanceArray|undefined  = expenseSection.getTreeGridPresentation().find(x => x.id === "Sum(" + SectionNameEnum.Expenses + ")")?.totalAccountBalance
        const netOperatingIncome = new Section(pbcData,datesArray, ReportTypeEnum.Income_Statement, FormulaNameEnum.Net_Operating_Income, SectionNameEnum.NO_SECTION,[],
            accountTypeDict, accountListDict,grossProfitTotal ,expensesTotal)
        const netOperatingIncomeTotal: IaccountBalanceArray|undefined  = netOperatingIncome.getTreeGridPresentation().find(x => x.id === FormulaNameEnum.Net_Operating_Income)?.totalAccountBalance
        const otherIncomeSection = new Section(pbcData,datesArray, ReportTypeEnum.Income_Statement, SectionNameEnum.Other_Income, SectionNameEnum.NO_SECTION,[],accountTypeDict, accountListDict)
        const otherExpensesSection = new Section(pbcData,datesArray, ReportTypeEnum.Income_Statement, SectionNameEnum.Other_Expenses, SectionNameEnum.NO_SECTION,[],accountTypeDict, accountListDict)
        const otherIncomeTotal: IaccountBalanceArray|undefined = otherIncomeSection.getTreeGridPresentation().find(x => x.id === "Sum(" + SectionNameEnum.Other_Income + ")")?.totalAccountBalance
        const otherExpensesTotal: IaccountBalanceArray|undefined = otherExpensesSection.getTreeGridPresentation().find(x => x.id === "Sum(" + SectionNameEnum.Other_Expenses + ")")?.totalAccountBalance
        const netOtherIncome = new Section(pbcData,datesArray, ReportTypeEnum.Income_Statement, FormulaNameEnum.Net_Other_Income, SectionNameEnum.NO_SECTION,[],accountTypeDict, 
            accountListDict, otherIncomeTotal, otherExpensesTotal)
        const netOtherIncomeTotal: IaccountBalanceArray|undefined = netOtherIncome.getTreeGridPresentation().find(x => x.id === FormulaNameEnum.Net_Other_Income)?.totalAccountBalance
        const netIncome = new Section(pbcData,datesArray, ReportTypeEnum.Income_Statement, FormulaNameEnum.Net_Income, SectionNameEnum.NO_SECTION,[],accountTypeDict, accountListDict,netOperatingIncomeTotal ,netOtherIncomeTotal )

        this.reportSections =
            [
                revenueSection,
                cogsSection,
                grossProfit,
                expenseSection,
                netOperatingIncome,
                otherIncomeSection,
                otherExpensesSection,
                netOtherIncome,
                netIncome
            ]
    }
}


export class BSReport extends FinancialReportBaseClass {
    constructor(pbcData:BalanceSheet, last_date_of_actuals:Date, datesArray:Date[], accountTypeDict: SectionItemData, accountListDict: IAccountsDict, netIncome: IaccountBalanceArray|undefined) {
        super()

        const assetsSection = new Section(pbcData,datesArray, ReportTypeEnum.Balance_Sheet, SectionNameEnum.Assets, SectionNameEnum.NO_SECTION,
            [
                new Section(pbcData,datesArray, ReportTypeEnum.Balance_Sheet, SectionNameEnum.Current_Assets, SectionNameEnum.Assets, [
                    new Section(pbcData,datesArray, ReportTypeEnum.Balance_Sheet, SectionNameEnum.Bank_Accounts, SectionNameEnum.Current_Assets,[],accountTypeDict, accountListDict),
                    new Section(pbcData,datesArray, ReportTypeEnum.Balance_Sheet, SectionNameEnum.Accounts_Receivable, SectionNameEnum.Current_Assets,[],accountTypeDict, accountListDict),
                    new Section(pbcData,datesArray,ReportTypeEnum.Balance_Sheet, SectionNameEnum.Other_Current_Assets, SectionNameEnum.Current_Assets,[],accountTypeDict, accountListDict),
                ],accountTypeDict, accountListDict),
                new Section(pbcData, datesArray, ReportTypeEnum.Balance_Sheet, SectionNameEnum.Fixed_Assets, SectionNameEnum.Assets,[],accountTypeDict, accountListDict),
                new Section(pbcData, datesArray, ReportTypeEnum.Balance_Sheet, SectionNameEnum.Other_Assets, SectionNameEnum.Assets,[],accountTypeDict, accountListDict)
            ])
        const assetsTotal: IaccountBalanceArray|undefined = 
        assetsSection.getTreeGridPresentation().find(x => x.id === "Sum(" + SectionNameEnum.Assets + ")")?.totalAccountBalance
        const liabilitiesAndEquitySection = new Section(pbcData,datesArray, ReportTypeEnum.Balance_Sheet, SectionNameEnum.Liabilities_and_Equity, SectionNameEnum.NO_SECTION,
            [
                new Section(pbcData,datesArray, ReportTypeEnum.Balance_Sheet, SectionNameEnum.Liabilities, SectionNameEnum.Liabilities_and_Equity, [
                    new Section(pbcData,datesArray, ReportTypeEnum.Balance_Sheet, SectionNameEnum.Current_Liabilities, SectionNameEnum.Liabilities, [
                        new Section(pbcData,datesArray, ReportTypeEnum.Balance_Sheet, SectionNameEnum.Accounts_Payable, SectionNameEnum.Current_Liabilities,[],accountTypeDict, accountListDict),
                        new Section(pbcData,datesArray, ReportTypeEnum.Balance_Sheet, SectionNameEnum.Credit_Cards, SectionNameEnum.Current_Liabilities,[],accountTypeDict, accountListDict),
                        new Section(pbcData,datesArray, ReportTypeEnum.Balance_Sheet, SectionNameEnum.Other_Current_Liabilities, SectionNameEnum.Current_Liabilities,[],accountTypeDict, accountListDict),
                    ],accountTypeDict, accountListDict),
                    new Section(pbcData,datesArray, ReportTypeEnum.Balance_Sheet, SectionNameEnum.Long_Term_Liabilities, SectionNameEnum.Liabilities,[],accountTypeDict, accountListDict),
                ]),
                new Section(pbcData,datesArray, ReportTypeEnum.Balance_Sheet, SectionNameEnum.Equity, SectionNameEnum.Liabilities_and_Equity,[],accountTypeDict, accountListDict,netIncome,undefined,last_date_of_actuals),
                
            ], null,null,assetsTotal)
        
        const liabilitiesAndEquityTotal: IaccountBalanceArray|undefined = 
        liabilitiesAndEquitySection.getTreeGridPresentation().find(x => x.id === "Sum(" + SectionNameEnum.Liabilities_and_Equity + ")")?.totalAccountBalance

        this.reportSections = [
            assetsSection,
            liabilitiesAndEquitySection
        ]
    }
}


export class CFReport extends FinancialReportBaseClass {
    constructor(BSReport:IgridRow[], last_date_of_actuals, datesArray:Date[],accountTypeDict: SectionItemData, accountListDict: IAccountsDict,netIncome:IaccountBalanceArray|undefined, priorDate:Date ) {
        super()
        const operatingActivities = new CashFlowSection(BSReport,last_date_of_actuals, datesArray, ReportTypeEnum.Cash_Flows, SectionNameEnum.Operating_Activities, SectionNameEnum.NO_SECTION,
            [
                new CashFlowSection(BSReport,last_date_of_actuals,datesArray, ReportTypeEnum.Cash_Flows, FormulaNameEnum.Net_Income, SectionNameEnum.Operating_Activities,[],accountTypeDict, accountListDict,netIncome,undefined,undefined,priorDate),
                new CashFlowSection(BSReport,last_date_of_actuals,datesArray, ReportTypeEnum.Cash_Flows, SectionNameEnum.Δ_in_Current_Assets, SectionNameEnum.Operating_Activities,[],accountTypeDict, accountListDict,undefined, undefined, undefined, priorDate),
                new CashFlowSection(BSReport,last_date_of_actuals,datesArray, ReportTypeEnum.Cash_Flows, SectionNameEnum.Δ_in_Current_Liabilities, SectionNameEnum.Operating_Activities,[],accountTypeDict, accountListDict,undefined, undefined, undefined, priorDate)
            ])
        const operatingActivitiesTotal: IaccountBalanceArray|undefined = operatingActivities.getTreeGridPresentation().find(x => x.id === "Sum(" + SectionNameEnum.Operating_Activities + ")")?.totalAccountBalance
        const investingActivities = new CashFlowSection(BSReport,last_date_of_actuals,datesArray, ReportTypeEnum.Cash_Flows, SectionNameEnum.Investing_Activities, SectionNameEnum.NO_SECTION,[],accountTypeDict, accountListDict, undefined, undefined,undefined, priorDate)
        const investingActivitiesTotal: IaccountBalanceArray|undefined = investingActivities.getTreeGridPresentation().find(x => x.id === "Sum(" + SectionNameEnum.Investing_Activities + ")")?.totalAccountBalance
        const financingActivities = new CashFlowSection(BSReport,last_date_of_actuals,datesArray, ReportTypeEnum.Cash_Flows, SectionNameEnum.Financing_Activities, SectionNameEnum.NO_SECTION,[],accountTypeDict, accountListDict, undefined, undefined,undefined, priorDate)
        const financingActivitiesTotal: IaccountBalanceArray|undefined = financingActivities.getTreeGridPresentation().find(x => x.id === "Sum(" + SectionNameEnum.Financing_Activities + ")")?.totalAccountBalance
        const totalCashFlows = new CashFlowSection(BSReport,last_date_of_actuals,datesArray, ReportTypeEnum.Cash_Flows, FormulaNameEnum.Total_Cash_Flows, SectionNameEnum.NO_SECTION,[],accountTypeDict, accountListDict, 
            operatingActivitiesTotal, investingActivitiesTotal, financingActivitiesTotal,priorDate)
        const totalCashFlowsTotal: IaccountBalanceArray|undefined = totalCashFlows.getTreeGridPresentation().find(x => x.name === FormulaNameEnum.Total_Cash_Flows)?.totalAccountBalance
        this.reportSections = [
            operatingActivities,
            investingActivities,
            financingActivities,
            totalCashFlows,
            new CashFlowSection(BSReport,last_date_of_actuals,datesArray, ReportTypeEnum.Cash_Flows, FormulaNameEnum.Beginning_Ending_Cash, SectionNameEnum.NO_SECTION,[],accountTypeDict, accountListDict,totalCashFlowsTotal,undefined,undefined,priorDate),
            new CashFlowSection(BSReport,last_date_of_actuals,datesArray, ReportTypeEnum.Cash_Flows, FormulaNameEnum.Cash_Burn, SectionNameEnum.NO_SECTION,[],
                accountTypeDict, accountListDict, operatingActivitiesTotal, investingActivitiesTotal),
        ]
    }
}