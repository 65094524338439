import moment from 'moment';
import { DashboardReportType } from '../types/Dashboard';
import { LoaderStatus } from '../types/Loaders';
import kebabcase from "lodash.kebabcase";

export const uppercaseFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

export const uppercaseAllWordLetters = (str: string) => str.split('_').join(' ').toUpperCase();

export const uppercaseWords = (str: string) => str.split('_').join(' ').replace(/^(.)|\s+(.)/g, c => c.toUpperCase());

export const replaceDashesWithSpacesWithUppercaseWords = (str: string) => str.split('-').join(' ').replace(/^(.)|\s+(.)/g, c => c.toUpperCase());

export const objKeyToUppercaseWords = (str: string) => str.split('_').filter((v => v.match(/(\b[A-Z]+|\b[A-Z]\b)/g))).join(' ').replace(/([a-z0-9])([A-Z])/g, '$1 $2');

export const options: Intl.DateTimeFormatOptions = { year: "numeric", timeZone: undefined, month: "short" };

export const DateFormatterLongMonth: Intl.DateTimeFormatOptions = { year: "numeric", month: "long" };
export const DateFormatterShortMonth: Intl.DateTimeFormatOptions = { year: "numeric", month: "short" };

export const displayStrDateFormatter = (date: string) => {
    return (
    date ? moment(date).toDate().toLocaleDateString('en-US', { ...options, month: 'short', day: '2-digit' }) : ""
    )}

export const displayDateFormatter = (date: Date) => date ? date.toLocaleDateString('en-US', { year: "numeric", month: 'short', day: '2-digit' }) : ""

export const dateFromString = (date: string) => {
    if (date) {
        var date_splits = date.split('-')
        return new Date(Number(date_splits[0]), Number(date_splits[1]) - 1, Number(date_splits[2]))
    }
    return null

}

export const displayDateTimeFormatter = (date: string) => date ? new Date(date).toLocaleDateString('en-US', { ...options, month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' }) : ""

export const stringToDateConverter = (date: string) => {
    if (!date) return null
    const dateObj = new Date(date);
    return `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`
}

export const numberFormat = (value: number): string => {
    return Math.round(value).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 0 })
}

export const getLoaderStatus = (status: LoaderStatus|string) => {
    var statusStr: string = ""
    switch (status) {
        case LoaderStatus.ApplyingUpdates:
            statusStr = "We are applying your updates"
            break;
        case LoaderStatus.Loading:
            statusStr = "We are loading your data"
            break;
        case LoaderStatus.Loaded:
            statusStr = "Your data is loaded."
            break;
        case LoaderStatus.GeneratingReports:
            statusStr = "We are rendering your data."
            break;
        case LoaderStatus.Rendered:
            statusStr = "Your data is ready now!"
            break;

        default:
            statusStr = status
            break;
    }
    return statusStr
}
const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

export const getSelectedPeriodDate = (date: Date | undefined, periodType: DashboardReportType): string => {
    let selectedPeriodDate: string = "";
    if (date === undefined || date === null)
        return ""
    switch (periodType) {
        case "Quarter":
            selectedPeriodDate = getQuarterFromDate(date);
            break;
        case "Month":
        case "Period":
            selectedPeriodDate = getMonthFromDate(date);
            break;
        case "Year":
            selectedPeriodDate = getYearFromDate(date);
            break;
        default:
            break;
    }
    return selectedPeriodDate;
}

export const getQuarterFromDate = (date: Date): string => {
    let quarterName: string = "";
    var q = [1, 2, 3, 4];

    let month = q[Math.floor(date.getMonth() / 3)];
    let year = date.getFullYear();
    quarterName = `${year} Q${month}`;
    return quarterName;
}

export const getMonthFromDate = (date: Date): string => {
    let monthName: string = "";
    monthName = `${monthNames[date.getMonth()]} ${date.getFullYear().toString()}`;
    return monthName;
}

export const getYearFromDate = (date: Date): string => {
    let year: string = "";
    year = date.getFullYear().toString();
    return year;
}

export function isToday(date) {
    const today = new Date();
  
    if(date) {
        if (today.toDateString() === date.toDateString()) {
            return true;
          }
    }
    
    return false;
}

export function isInThePast(date) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    return date < today;    
  }

// export function dateToDisplay(date: Date|string){
//     var targetDate: Date;
//     if (typeof date !== "string") {
//         // if input is a date
//         targetDate = date
//     } else if (typeof date === "string"){
//         const x = Date.parse(date)
//         if (x) {
//             targetDate = x
//         }
//     }
//     if(isToday(targetDate) === true) {
//         return "Today"
//     }
//     if(isTomorrow(targetDate) === true) {
//         return "Tomorrow"
//     }
//     if(isYesterday(targetDate) === true) {
//         return "Yesterday"
//     }

//     if(targetDate && isInThePast(targetDate) === true) {
//         return targetDate
//     }

// }

export function isYesterday(date) {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
  
    if (date) {
        if (yesterday.toDateString() === date.toDateString()) {
            return true;
          }
    }
    
  
    return false;
  }

export function isTomorrow(date) {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    if(date) {
        if (tomorrow.toDateString() === date.toDateString()) {
            return true;
        }
    }
    

    return false;
}

export const getRandomColor = (userID: number) => {
    const colors = ['#FF47B5', '#F49797', '#1A3668', '#F88964', '#909DB5', '#3FD6EE', '#FFBF5C', '#E24C4B', '#3985F8', '#AB83FF',
        '#88009E', '#00CE78', '#FF7A00', '#196989', '#4461A8', '#5900B3', '#B12911', '#9F616A', '#388E3C', '#2F2F2F'];
    var profileColor = localStorage.getItem(`profileColor_${userID}`) || "";
    if (!profileColor || profileColor === "") {
        profileColor = colors[Math.floor(Math.random() * colors.length)];
        localStorage.setItem(`profileColor_${userID}`, profileColor);
    }
    return profileColor;
}
export const createImageFromInitials = (size, nameInitials, color) => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    canvas.width = canvas.height = size
    if (context) {
        context.fillStyle = color
        context.fillRect(0, 0, size, size)

        context.fillStyle = '#ffffff';
        context.textBaseline = 'middle'
        context.textAlign = 'center'
        context.font = `${size / 2}px Roboto`
        context.fillText(nameInitials.toUpperCase(), (size / 2), (size / 2))
    }
    return canvas.toDataURL()
};

export const getInitials = (name) => {
    if(name && name !=='') {
        const nameList = name.split(" ")
        const I1 = nameList[0][0].toUpperCase()
        if (nameList[1]) {
            var I2 = nameList[1][0].toUpperCase()
            return I1 + I2
        } else {
            return I1
        }
    }
    
  }

export function capitalizeFirstLetter(str) {
    return str[0].toUpperCase() + str.slice(1);
  }

export function stringToSlugConverter(str) {
        return kebabcase(str.replace(/[\W_]+/g, '-'));
}

export function hexToRgbA(hex){
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
    }
    throw new Error('Bad Hex');
}