import React, { useContext } from "react";
import { SocketContext } from "../../../../../contexts/SocketContext";
import { DataContextProvider } from "../../QBOReport/Context/DataContext";
import { getAgedReports, getAgedReportsDetail, runAgedReports } from "../../../../../APIFunctions/qbo_report";
import { QBOFilterContextProvider } from "../../QBOReport/Context/FilterContext";
import { CommentContextProvider } from "../../QBOReport/Context/CommentContext";
import { DataGridContextProvider } from "../../QBOReport/Context/DataGridContext";
import AgedReportsRoot from ".";
import Presentation from "./Presentation";

const AgedReports = ({ CustomEndDate, parentTaskName }) => {
  const { setAgedReportsDataImportStatusHandle, setAgedReportsDetailDataImportStatusHandle } = useContext(SocketContext);
  return (
    <QBOFilterContextProvider CustomEndDate={CustomEndDate}>
      <DataContextProvider
        setDataImportStatusHandle={setAgedReportsDataImportStatusHandle}
        setDetailDataImportStatusHandle={setAgedReportsDetailDataImportStatusHandle}
        dataQueryFunction={runAgedReports}
        detailQueryFunction={getAgedReportsDetail}
      >
        <CommentContextProvider parentTaskName={parentTaskName} >
          <DataGridContextProvider>
            <Presentation />
          </DataGridContextProvider>
        </CommentContextProvider>
      </DataContextProvider>
    </QBOFilterContextProvider>
  );
};

export default AgedReports;
