import React, { useContext } from 'react'
import { reportTabIndexEnum } from '../../../MilestoneTaskComponent/Financials/types'
import Container from './Container'
import { useSearchParams } from 'react-router-dom';
import { DataContext } from '../../Context/DataContext';

const QBOReportHeader = ({setDrillFlag,setDrilledCellParams}) => {
  const { setDetailRowsDisplay, setDetailHeadersDisplay, setReportDetailLevel} = useContext(DataContext)
  return (
    <Container setDrillFlag={setDrillFlag} setDrilledCellParams={setDrilledCellParams} setDetailRowsDisplay={setDetailRowsDisplay} setDetailHeadersDisplay={setDetailHeadersDisplay} setReportDetailLevel={setReportDetailLevel}/>
  )
}

export default QBOReportHeader