import React, { useContext } from 'react';
import { SocketContext } from '../../contexts/SocketContext';
import { DataConext } from '../../contexts/HeadCount/DataConext';


SectionTitleUpdate.defaultProps = {
}
export default function SectionTitleUpdate() {

    const { hasHoldUpdates, setHasHoldUpdate } = useContext(SocketContext)
    const { showToastThenRefresh } = useContext(DataConext)

    const localUpdate = async () => {
        setHasHoldUpdate(false)
        showToastThenRefresh("Update done!")
    }

    return (
        <div className="Title__refreshStatus">
            {hasHoldUpdates && <div className='container'>
                <div className='row'>
                    <button onClick={localUpdate}>
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.94968 0.930471L7.96788 1.91227C7.12329 1.06753 5.95657 0.544922 4.66761 0.544922C2.16871 0.544922 0.128435 2.50867 0.00583452 4.97714C-0.00054581 5.10546 0.103045 5.21254 0.231517 5.21254H0.759353C0.878923 5.21254 0.977921 5.11933 0.984697 4.99997C1.09465 3.06033 2.69993 1.52362 4.66761 1.52362C5.68702 1.52362 6.60913 1.93623 7.27647 2.60368L6.25773 3.62244C6.11544 3.76473 6.21621 4.00799 6.41742 4.00799H9.10937C9.2341 4.00799 9.33523 3.90686 9.33523 3.78214V1.09019C9.33523 0.88897 9.09195 0.788202 8.94968 0.930471ZM9.10371 5.21254H8.57587C8.4563 5.21254 8.35731 5.30574 8.35053 5.4251C8.24058 7.36474 6.63529 8.90146 4.66761 8.90146C3.64821 8.90146 2.72609 8.48884 2.05876 7.82139L3.0775 6.80263C3.21979 6.66034 3.11902 6.41708 2.9178 6.41708H0.225852C0.101125 6.41708 0 6.51821 0 6.64293V9.33488C0 9.5361 0.243281 9.63687 0.385549 9.49458L1.36735 8.5128C2.21194 9.35754 3.37867 9.88015 4.66761 9.88015C7.16654 9.88015 9.20679 7.9164 9.32939 5.44793C9.33577 5.31961 9.23218 5.21254 9.10371 5.21254Z" fill="white" />
                        </svg>
                        Update</button>
                </div>
            </div>}
        </div>
    );
}