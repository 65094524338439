import { useContext } from "react";
import { OptionTypeBase } from "react-select";
import { AuthContext } from "../../../contexts/AuthContext";
import { AsanaTask } from "../../../types/AsanaTask";
import MDIcons from "../../Common/MDIcons";
import MilestoneTitle from "./MilestoneTitle";

interface MilestoneHeaderProps {
    title: string;
    
    children?: any;
    milestone?:AsanaTask;
    backToList?: () => void;
}

export default function MilestoneHeader(props: MilestoneHeaderProps) {
    const { selectedCompany } = useContext(AuthContext);
    if (!selectedCompany) return <></>
    return (
        <>
            <header className="header-bi">
                {props.backToList && <button className="btn--tertiary btn--S no-borders-no-background Black-Button" onClick={props.backToList}><MDIcons title="Header__Back__Button" /></button>}
                <MilestoneTitle title={props.title} milestone={props.milestone}/>
                {props.children}
            </header>
        </>
    )
}