
export const editAddUserDisplayForm = ['email', 'role']
export const editAddUserExpandedForm = ['embeded_views_access', 'divisions', 'headcount_access', 'departments', 'locations']
export const editAddUserFormFilter = ['locations', 'divisions', 'departments']


export const usersTableDisplay = ['user', 'role', 'divisions', 'departments', 'locations', 'status']
export const usersTableFilter = ['status']
export const usersTableFilterOptions = ['status']

export const usersTableSearchOptions = ['user', 'role', 'divisions', 'departments', 'locations', 'status']