import { TextareaAutosize } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { createAsanaTaskComment, retrieveAsanaTaskStories } from "../../../../APIFunctions/Asana";
import { MilestoneContext } from "../../../../contexts/TicketingSystem/MilestoneContext";
import { PayrollContext } from "../../../../contexts/TicketingSystem/PayrollContext";
import Comment from "../../../Tasks/Components/Comment";

const ClientReviewQA = ({ task_gid }) => {
  const { setPayrollLoading } = useContext(MilestoneContext);
  const [comments, setComments] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [newComment, setNewComment] = useState<string>("");

  const getComments = async (task_gid) => {
    setPayrollLoading(true);
    const response = await retrieveAsanaTaskStories(task_gid);
    if (response.data) {
      const stories = response.data;
      const storiesArray = stories.filter((story) => story.type === "comment");
      setComments(storiesArray);
    } else if (response.response?.status === 401) {
      setTimeout(() => {
        getComments(task_gid);
      }, 1000);
    }
    setPayrollLoading(false);
  };

  const addComment = async () => {
    setPayrollLoading(true);
    const newCommentObj = {
      data: {
        text: newComment,
      },
    };

    // todo: setTaskCardLoading(true);
    const response = await createAsanaTaskComment(task_gid, newCommentObj);
    if (response.data) {
      setNewComment(" ");
      await getComments(task_gid);
      // todo:  setTaskCardLoading(false);
    } else if (response.response?.status === 401) {
      setTimeout(() => {
        addComment();
      }, 1000);
    }
    setPayrollLoading(false);
  };

  useEffect(() => {
    getComments(task_gid);
  }, [task_gid]);

  return (
    <div className="QA__Container">
      <div className="QA__Container__Comments" style={{ padding: comments.length > 0 ? "1rem" : "0rem" }}>
        {comments.map((comment) => {
          return <Comment key={comment.gid} story={comment} taskStories={comments} setTaskStories={setComments} />;
        })}
      </div>

      <div className="NewComment__Container">
        <TextareaAutosize
          aria-label="empty textarea"
          placeholder="Ask a question or post an update..."
          style={{ width: "100%", padding: 10 }}
          className="TaskCard__Description"
          value={newComment}
          name="notes"
          onChange={(e) => setNewComment(e.target.value)}
        />
        <button className="btn--primary btn--S flex-row--S addComment__Button " onClick={addComment}>
          <span>Comment</span>
        </button>
      </div>
    </div>
  );
};

export default ClientReviewQA;
