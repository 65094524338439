import moment from "moment";

import { capitalizeFirstLetter, DateFormatterLongMonth, DateFormatterShortMonth } from "./helpers"

const monthsArray = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"]

export const getMonthDayNumber = (date: Date): number => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
}

export const formatDateStandard = (date: Date):string => {
    const dayNum = date.getDate()
    const shortMonth = capitalizeFirstLetter(monthsArray[date.getMonth()])
    const year = date.getFullYear()
    return `${shortMonth} ${dayNum},${year}`

}

export const isSameMonthYear = (date1: Date | null, date2: Date | null): boolean => {
    if (!date1 || !date2)
        return false
    return !(date1.getMonth() !== date2.getMonth() || date1.getFullYear() !== date2.getFullYear())
}

export const getMonth = (date: Date): string => {
    return date.toLocaleDateString('en-US', DateFormatterLongMonth)
}
export const getMonthShort = (date: Date): string => {
    return date.toLocaleDateString('en-US', DateFormatterShortMonth)
}
export const getYear = (date: Date, offset: number = 0): string => {
    if (date.getMonth() >= offset)
        return date.getFullYear().toString();
    else
        return (date.getFullYear() - 1).toString();
}
export const getFiscalYearStartAndEnd = (offset: number = 0): Date[] => {
    var startDate = new Date(new Date().getFullYear(), offset, 1)
    var endDate = new Date(startDate)
    endDate.setMonth(endDate.getMonth() + 11)
    return [startDate, endDate]
}
export const IslastMonthInYear = (date: Date, offset: number = 0): boolean => {
    var startDate = new Date(new Date().getFullYear(), offset, 1)
    var endDate = new Date(startDate)
    endDate.setMonth(endDate.getMonth() + 11)
    return endDate.getMonth() === date.getMonth()
}
export const getQuarter = (date: Date, offset: number = 0): string => {
    var given_date = new Date(date)
    given_date.setMonth(given_date.getMonth() - offset)
    return 'Q' + Math.ceil((given_date.getMonth() + 1) / 3) + " " + given_date.getFullYear()
}
export const IslastMonthInQuarter = (date: Date, offset: number = 0): boolean => {
    var calculateMonthWithOffset: number = offset + date.getMonth()
    if (calculateMonthWithOffset > 11)
        calculateMonthWithOffset -= 12

    return ((calculateMonthWithOffset + 1 + 3) % 3) === 0
}
export const endOfMonth = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}
export const startOfMonth = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth(), 1);
}

export const isDateIntersectWithTwoDates = (date: Date, startDate: Date, endDate: Date | null): boolean => {

    startDate = startOfMonth(startDate)
    if (endDate) {
        endDate = endOfMonth(endDate)
        if (date >= startDate && date <= endDate)
            return true
        else
            return false
    }
    else {
        if (date >= startDate)
            return true
        else
            return false
    }
}

export const getNumberOfDaysBetweenTwoDays = (date1: Date, date2: Date): number => {
    // To calculate the time difference of two dates
    var Difference_In_Time = date2.getTime() - date1.getTime();
    // To calculate the no. of days between two dates
    return Difference_In_Time / (1000 * 3600 * 24)
}

export const createDateWithOffset = (days, months, years) => {
    var date = new Date();
    date.setDate(date.getDate() + days);
    date.setMonth(date.getMonth() + months);
    date.setFullYear(date.getFullYear() + years);
    return date;
}

export const createDateEndOfLastMonth = () => {
    var date = new Date();
    date.setMonth(date.getMonth() - 1);
    
    return endOfMonth(date);
}

export const FirstDayCurrentYear = () => {
    const currentYear = new Date().getUTCFullYear();
    return new Date(currentYear, 0, 1)
}

export const createDateWithOffsetFromDate = (startDate, days, months, years) => {
    var date = startOfMonth(new Date(startDate));
    date.setDate(date.getDate() + days);
    date.setMonth(date.getMonth() + months);
    date.setFullYear(date.getFullYear() + years);
    return date;
}

export const convertMonthLongYearStringToDate = (dateString: string) : Date => {
    // accepts format MMMM YYYY
    if (dateString) {
        const arr = dateString.split(" ")
        const monthNumber = months.indexOf(arr[0].toLowerCase())
        const yearNumber = Number(arr[1])
        
        const date = new Date(yearNumber, monthNumber, 1);
        return date;
    }
    return new Date()
    
}

export const convertMonthYearStringToDate = (dateString: string) : Date => {
    // accepts format MMM YYYY
    if (dateString) {
        const arr = dateString.split(" ")
        const monthNumber = monthsArray.indexOf(arr[0].toLowerCase())
        const yearNumber = Number(arr[1])
        
        const date = new Date(yearNumber, monthNumber, 1);
        return date;
    } else {
        return new Date()
    }
    
}

export const asanaTaskDateStringToDate = (dateString: string|undefined) : Date => {

    if (dateString === null || dateString === undefined) return new Date()
    const arr = dateString.split("-")
    const yearNumber = Number(arr[0])
    const monthNumber = Number(arr[1]) - 1
    const dayNumber = Number(arr[2])

    const date = new Date(yearNumber, monthNumber, dayNumber);
    return date;
}

export const asanaTaskDateStringToUTCDate = (dateString: string|undefined) : Date => {
    if (dateString === null || dateString === undefined || dateString === "") return new Date()
// Check if the month of the date string is different from the month of the date object
let dateObject = moment.utc(dateString); // Create a moment object in UTC
let jsDate = dateObject.toDate();

// Check if the month of jsDate is one month behind the dateString
const x = moment(dateString).month()
const y = jsDate.getMonth()
if (moment(dateString).month() - jsDate.getMonth() === 1 
    || moment(dateString).month() - jsDate.getMonth() === -11) {
    // If it is, adjust the jsDate by adding one day
    jsDate.setUTCDate(jsDate.getUTCDate() + 1);
}

// Check if the month of jsDate is one month ahead of the dateString
if (jsDate.getMonth() - moment(dateString).month() === 1 
    || jsDate.getMonth() - moment(dateString).month() === -11) {
    // If it is, adjust the jsDate by subtracting one day
    jsDate.setUTCDate(jsDate.getUTCDate() - 1);
}

    return jsDate;
}

export const appDateStringToDate = (dateString: string|undefined) : Date => {

    if (dateString === null || dateString === undefined) return new Date()
    const arr = dateString.split("-")
    const monthNumber = Number(arr[0]) - 1
    const dayNumber = Number(arr[1])
    const yearNumber = Number(arr[2])

    const date = new Date(yearNumber, monthNumber, dayNumber);
    return date;

}

export const dateObjToAsanaString = (dateObj:Date) => {
    var dd = (dateObj.getDate() < 10 ? '0' : '') + dateObj.getDate();
   // 01, 02, 03, ... 10, 11, 12
   var MM = ((dateObj.getMonth() + 1) < 10 ? '0' : '') + (dateObj.getMonth() + 1);
    return `${dateObj.getFullYear()}-${MM}-${dd}`
}

export const dateObjToAppString = (dateObj:any, separator) => {
    let dateObjJS
    try{
        dateObjJS = dateObj.toDate()
        var dd = (dateObjJS.getDate() < 10 ? '0' : '') + dateObjJS.getDate();
        // 01, 02, 03, ... 10, 11, 12
        var MM = ((dateObjJS.getMonth() + 1) < 10 ? '0' : '') + (dateObjJS.getMonth() + 1);
         return `${MM}${separator}${dd}${separator}${dateObjJS.getFullYear()}`
    }
    catch {
        dateObjJS = dateObj
        var dd = (dateObjJS.getDate() < 10 ? '0' : '') + dateObjJS.getDate();
        // 01, 02, 03, ... 10, 11, 12
        var MM = ((dateObjJS.getMonth() + 1) < 10 ? '0' : '') + (dateObjJS.getMonth() + 1);
         return `${MM}${separator}${dd}${separator}${dateObjJS.getFullYear()}`
    }
   
}

export const dateObjToAppStringWithTime = (dateObj:Date, separator) => {
    
    var dd = (dateObj.getDate() < 10 ? '0' : '') + dateObj.getDate();
   // 01, 02, 03, ... 10, 11, 12
   var MM = ((dateObj.getMonth() + 1) < 10 ? '0' : '') + (dateObj.getMonth() + 1);
   var hours = dateObj.getHours()
   var minutes = dateObj.getMinutes()
    return `${MM}${separator}${dd}${separator}${dateObj.getFullYear()} ${hours}:${minutes}`
}

export const asanaTimeToHoursMinutes = (timeString: string): Number => {
if (timeString === null || timeString === undefined) return -2
    const arr = timeString.split(":")
    let hours = 0
    if (timeString.slice(-2) === "PM") {
        hours = Number(arr[0]) + 12
    } else {
        hours = Number(arr[0])
    }
    
    return hours - 2
}

export function addHours(date, hours) {
    date.setHours(date.getHours() + hours);
  
    return date;
}
  

export const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'
]

export function QuarterStartEnd(quarterString) {
    // Input format : Q1 2023
    const quarter: "1"|"2"|"3"|"4" = quarterString.split(" ")[0][1]
    const year = quarterString.split(" ")[1]
    var startDate = new Date(year, (Number(quarter) * 3) - 3,1);
    var endDate = new Date(year, startDate.getMonth() + 2,1);
    return {startDate:startOfMonth(startDate), endDate: endOfMonth(endDate)}
}