import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import { ManageUsers } from '../../../../types/ManageUsers';

export default function useRenderFilters(params: GridRenderCellParams) {
    const user = params.row as ManageUsers
    const renderValue = params.field === 'user' ? user.user_name :
        params.field === 'role' ? user.roleStr :
            params.field === 'divisions' ? user.userDivisions :
                params.field === 'departments' ? user.userDepartments :
                    params.field === 'locations' ? user.userLocations : params.value;
    return (
        <Box
            component="span"
            sx={{
                fontSize: "12px",
            }}>
            {renderValue}
        </Box>
    )
}