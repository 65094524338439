import { createContext, useContext, useEffect, useState } from "react";
import { retrieveAsanaTasksInSection } from "../../APIFunctions/Asana";
import { createPayrollType, getPayrollFileLink, retrievePayrollTypeDict, updatePayrollFileLink } from "../../APIFunctions/ticketing_system";
import { payrollLocation, payrollPlatform, payrollType } from "../../types/TicketingSystem";
import { asanaTaskDateStringToDate } from "../../utils/DateFunctions";
import { AuthContext } from "../AuthContext";
import { TaskServicesContext } from "../Task/TaskServicesContext";
import { MilestoneContext } from "./MilestoneContext";
import { MilestoneEmailContext } from "./MilestoneEmailContext";
import { convertLegacyLinkToMilestoneLink } from "../../components/TicketingSystem/Components/Links/Util";

export interface PayrollContextProps {
  completedMilestones: any[];
  payrollTypeID: any;
  cycleStartDate: any;
  cycleEndDate: any;
  platform: string;
  location: string;
  payroll_type:string;
  upcomingMilestones: any[];
  payrollFileLink: any;
  setPayrollFileLink: (payrollFileLink: any) => void;
  setLinkInputType: (type: any) => void;
  onAddNewPayrollLink: () => void;
  onChangePayrollFileLinkInput: (e: any) => void;
  newPayrollFileLink: string;
  setNewPayrollFileLink: (newPayrollFileLink: string) => void;
  linkInputType: string;
  getPlatform: (milestone:any) => any;
  getLocation: (milestone:any) => any;
  getType: (milestone:any) => any;
  getCycleStartDate: (milestone:any) => any;
  getCycleEndDate: (milestone:any) => any;
}

export const PayrollContext = createContext<PayrollContextProps>(null as any);

export const PayrollContextProvider = (props) => {
  const { selectedCompany } = useContext(AuthContext);
  const { getEmailParams,setSharepointLinkInEmail, email  } = useContext(MilestoneEmailContext);
  const { setSelectedMilestone, selectedMilestone,refreshMilestonesFlag, setRefreshMilestonesFlag, setMilestoneType } = useContext(MilestoneContext);
  const [payrollFileLink, setPayrollFileLink] = useState<any>(null);
  const [tasks, setTasks] = useState<any[]>([]);
  const [completedMilestones, setCompletedMilestones] = useState<any[]>([]);
  const [upcomingMilestones, setUpcomingMilestones] = useState<any[]>([]);
  const [linkInputType, setLinkInputType] = useState<string>("");
  const [newPayrollFileLink, setNewPayrollFileLink] = useState<string>(payrollFileLink?.link);
  const [payrollTypeID, setPayrollTypeID] = useState<string>();

  const { sectionsQuery } = useContext(TaskServicesContext);
  const [cycleStartDate, setCycleStartDate] = useState<any>();
  const [cycleEndDate, setCycleEndDate] = useState<any>();
  const [platform, setPlatform] = useState<string>("")
  const [location, setLocation] = useState<string>("")
  const [payroll_type, setPayrollType] = useState<string>("")

  const getPlatform = () => {
    const platform = selectedMilestone?.custom_fields.find((x) => x.name === "Platform")?.display_value;
    if (platform) { setPlatform(platform)}
  };
  const getLocation = () => {
    const location = selectedMilestone?.custom_fields.find((x) => x.name === "Location")?.display_value;
    if (location) { setLocation(location)}
  };

  const getType = () => {
    const payrollType =  selectedMilestone?.custom_fields.find((x) => x.name === "Type")?.display_value;
    if (payrollType) { setPayrollType(payrollType)}
  };

  const getCycleStartDate = (milestone) => {
    const date = milestone?.custom_fields.find((x) => x.name === "Payroll Cycle Start Date")?.date_value?.date;
    setCycleStartDate(date);
  };

  const getCycleEndDate = (milestone) => {
    const date = milestone?.custom_fields.find((x) => x.name === "Payroll Cycle End Date")?.date_value?.date;
    setCycleEndDate(date);
  };

  const getPayrollType = async () => {
    const platform = selectedMilestone?.custom_fields.find((x) => x.name === "Platform")?.display_value;
    const location = selectedMilestone?.custom_fields.find((x) => x.name === "Location")?.display_value;
    const payroll_type =  selectedMilestone?.custom_fields.find((x) => x.name === "Type")?.display_value;
    if (platform && location && payroll_type) {
      const INT_platform = Number(payrollPlatform[platform]);
      const INT_location = Number(payrollLocation[location]);
      const INT_type = Number(payrollType[payroll_type]);
      
      const response = await createPayrollType(selectedCompany?.id, { platform: INT_platform, location: INT_location, payroll_type: INT_type });
      if (response) {
  
        setPayrollTypeID(response.id);
      }
      
    }
  };

  // function to get the milestones with the lowest due_date and 'completed: false'
  const getPayrollTasks = async () => {
    const payrollSectionGID = sectionsQuery.data?.data?.find((x) => x.name === "Payroll")?.["gid"];
    if (payrollSectionGID) {
      const tasks = await retrieveAsanaTasksInSection(payrollSectionGID);
      setRefreshMilestonesFlag(false)
      getUpcomingMilestones(tasks.data);
      setTasks(tasks.data);
    }
  };

  const getPayrollHistory = () => {
    if (tasks?.length > 0) {
      const completedTasks = tasks?.filter((x) => x.completed === true);
      setCompletedMilestones(completedTasks);
    }
  };

  // const getLowestDueDate = (incompleteMilestones: any[]): Date => {
  //   let lowest_due_date: Date = new Date();
  //   let flag = false;
  //   for (let i = 0; i < incompleteMilestones.length; i++) {
  //     const due_date: Date | null = asanaTaskDateStringToDate(incompleteMilestones[i].due_on);
  //     if (due_date) {
  //       if (flag === false) {
  //         // to initiate
  //         lowest_due_date = due_date;
  //         flag = true;
  //       } else {
  //         if (lowest_due_date > due_date) {
  //           lowest_due_date = due_date;
  //         }
  //       }
  //     }
  //   }
  //   return lowest_due_date;
  // };

  const getUpcomingMilestones = async (payrollTasks) => {
    // get upcoming milestones from asana
    if (payrollTasks?.length > 0) {
      const incompleteMilestones = payrollTasks?.filter((x) => x.completed === false);
      // let cycle_date_string = "";
      
      setUpcomingMilestones(incompleteMilestones);
      const targetMilestone = upcomingMilestones[0];
      const platform = targetMilestone?.custom_fields.find((x) => x.name === "Platform");
      const location = targetMilestone?.custom_fields.find((x) => x.name === "Location");
      const endDate = targetMilestone?.custom_fields.find((x) => x.name === "Payroll Cycle End Date");
      const startDate = targetMilestone?.custom_fields.find((x) => x.name === "Payroll Cycle Start Date");
      const employees = targetMilestone?.custom_fields.find((x) => x.name === "Type");
      if (platform && location && endDate && startDate && employees) {
        setSelectedMilestone(upcomingMilestones[0]);
      }

      // get upcoming milestones objects from the backend
    }
  };

  const onChangePayrollFileLinkInput = (e) => {
    setNewPayrollFileLink(e.target.value);
  };

  

  const onAddNewPayrollLink = async () => {
    const newPayrollObj = { ...payrollFileLink, link: newPayrollFileLink };
    setPayrollFileLink(newPayrollObj);
    // todo: update as sharepointLinkInEmail should follow the new structure of Milestone link
    
    const link = convertLegacyLinkToMilestoneLink(payrollFileLink.id, email?.id,"Excel Backup Payroll File",newPayrollFileLink,payrollFileLink.sharepoint_id, "","","Excel Backup Payroll File",2)
    setSharepointLinkInEmail(link)
    if (payrollFileLink.id) {
      await updatePayrollFileLink(payrollFileLink.id, { link: newPayrollFileLink });
    
    }
    setLinkInputType("");
    
  };

  const getFileLink = async () => {
    if (selectedCompany && payrollTypeID) {
      const response = await getPayrollFileLink(payrollTypeID);
      if (response) {
        setPayrollFileLink(response);
        
        const link = convertLegacyLinkToMilestoneLink(response.id, email?.id,response.file_name,response.link,response.sharepoint_id, "","","Excel Backup Payroll File",2)
        setSharepointLinkInEmail(link)
        setNewPayrollFileLink(response.link);
      } else {
        setPayrollFileLink(null);
        setSharepointLinkInEmail(null);
      }
    }
  };

  useEffect(() => {
    getPayrollTasks();
  }, [sectionsQuery.data,refreshMilestonesFlag]);

  useEffect(() => {
    getPayrollHistory();
    getPayrollType();
  }, [tasks]);

  useEffect(() => {
    if (selectedMilestone) {
      getType();
      getPlatform();
      getLocation()
      getPayrollType();
    }
    
    getCycleEndDate(selectedMilestone);
    getCycleStartDate(selectedMilestone);
    getEmailParams(0);
    setMilestoneType(0)
  }, [selectedMilestone]);

  useEffect(() => {
    getFileLink();
  }, [payrollTypeID]);

  return (
    <PayrollContext.Provider
      value={{
        payrollTypeID,
        platform,
        location,
        payroll_type,
        completedMilestones,
        upcomingMilestones,
        cycleStartDate,
        cycleEndDate,
        payrollFileLink,
        setPayrollFileLink,
        setLinkInputType,
        onAddNewPayrollLink,
        onChangePayrollFileLinkInput,
        newPayrollFileLink,
        setNewPayrollFileLink,
        linkInputType,getCycleEndDate,getCycleStartDate,getLocation,getPlatform,getType
      }}
    >
      {props.children}
    </PayrollContext.Provider>
  );
};
