import React, { useContext } from "react";
import MDIcons from "../../Common/MDIcons";
import GoToAsanaButton from "../../AsanaComponents/GoToAsanaButton";
import PermanentLink from "./Links/PermanentLink";
import MilestoneTaskLine from "./MilestoneTaskLine";
import { MilestoneEmailContext } from "../../../contexts/TicketingSystem/MilestoneEmailContext";
import { MilestoneContext } from "../../../contexts/TicketingSystem/MilestoneContext";
import DefaultMilestoneFileLinksUI from "./DefaultMilestoneFileLinksUI";
import { SubTasksContext } from "../../../contexts/TicketingSystem/SubTasksContext";
import { milestoneTaskComponentsList } from "./MilestoneTaskComponent";
import { useNavigate } from "react-router-dom";
import Table from "../MonthEndViews/Components/PlayrollCard/Table";

const   MilestoneTasksCard = ({ openMilestoneTask }) => {
  const navigate = useNavigate();
  const { setEmailReadyToSendFlag } = useContext(MilestoneEmailContext);
  const { selectedMilestone } = useContext(MilestoneContext);
  const { currentStage } = useContext(MilestoneContext);
  const { preparationStageTasks } = useContext(SubTasksContext);


  const openMilestoneTaskComponent = (milestoneTask) => {
    if (milestoneTaskComponentsList.includes(milestoneTask?.name)) {
      navigate(`${milestoneTask["gid"]}/component`);
    } else {
      openMilestoneTask(milestoneTask["gid"]);
    }
  };

  

  return (
    <>
      <div className="MilestoneCard__Container__Header">
        <div className="MilestoneCard__Container__Header__Email">
          <button className="btn--tertiary btn--S" onClick={() => setEmailReadyToSendFlag("send-only")}>
            <MDIcons title="Mail" /> Email
          </button>
          <div className="btn--tertiary btn--XXS--centered">
            <a href="https://app.qbo.intuit.com/app/companyselection?useNeo=true" target="_blank" rel="noreferrer">
              <MDIcons title="qbo_logo_small_notext" />
            </a>
          </div>
          {selectedMilestone && <GoToAsanaButton taskGID={selectedMilestone?.gid} />}
        </div>

        <DefaultMilestoneFileLinksUI />
      </div>

      <Table />
        <div className="MilestoneCard__SubTasks__Container">
          <div className="MilestoneCard__SubTasks__Title">Subtasks</div>
          <div className="MilestoneCard__SubTasks__List__Container--full-length">
            {preparationStageTasks.map((milestoneTask) => {
              return (
                <MilestoneTaskLine
                  key={milestoneTask.gid}
                  milestoneTask={milestoneTask}
                  openMilestoneTask={openMilestoneTask}
                  openMilestoneTaskComponent={openMilestoneTaskComponent}
                  type={currentStage?.name === "Staff Preparation" ? "Preparation" : "Review"}
                />
              );
            })}
          </div>
        </div>
    </>
  );
};

export default MilestoneTasksCard;
