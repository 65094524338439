
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { AuthContextProvider } from './contexts/AuthContext';
import EntryPoint from './EntryPoint';
import { SideBarContextProvider } from './contexts/SideBarContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

LicenseInfo.setLicenseKey(
  'c0c1b5d8f688419324a9960fc0057ce2T1JERVI6Mzc1NjYsRVhQSVJZPTE2NzYwNTQyNzQwMDAsS0VZVkVSU0lPTj0x',
);
toast.configure()


const queryClient = new QueryClient()

export default function App() {
  

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthContextProvider>
          <ToastContainer position="bottom-right" />
          <SideBarContextProvider>
            <EntryPoint />
          </SideBarContextProvider>
        </AuthContextProvider>
      </Router >
      <ReactTooltip
        backgroundColor="#1A3668"
        type="dark"
        place="bottom"
        effect="solid"
        multiline
        textColor="#FFFFFF"
        delayShow={100}
        className="data-tooltip"
        offset={{ top: -5 }}
      />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
