import React, { useContext } from 'react'
import DatePicker from 'react-datepicker';
import { OnboardingContext } from '../../contexts/Onboarding/OnboardingContext';
import SelectComponent from '../Common/SelectComponent';
import { asanaTaskDateStringToUTCDate } from '../../utils/DateFunctions';


const initialErrors = {
  start_end_misaligned: false,
  start_last_misaligned: false,
  last_end_misaligned: false
}

export const MonthsOfYearOptions = [
  { value: "0", label: "January" },
  { value: "-1", label: "February" },
  { value: "-2", label: "March" },
  { value: "-3", label: "April" },
  { value: "-4", label: "May" },
  { value: "-5", label: "June" },
  { value: "-6", label: "July" },
  { value: "-7", label: "August" },
  { value: "-8", label: "September" },
  { value: "-9", label: "October" },
  { value: "-10", label: "November" },
  { value: "-11", label: "December" }
]


function FormPage2() {

  const { onBack, onSubmit, onboardingInputs, setOnboardingInputs, onDateChange, done } = useContext(OnboardingContext);
  console.log("onboardingInputs start date:", onboardingInputs["start_date"]);
  const validateDatesObject = () => {
    let errors = { ...initialErrors }
    if (onboardingInputs["start_date"] >= onboardingInputs["end_date"]) errors.start_end_misaligned = true;
    if (onboardingInputs["end_date"] <= onboardingInputs["last_actuals_date"]) errors.last_end_misaligned = true;
    if (onboardingInputs["start_date"] >= onboardingInputs["last_actuals_date"]) errors.start_last_misaligned = true;
    return errors;
  }


  // useEffect(() => {
  //   ReactTooltip.rebuild();
  // }, []);

  return (
    <>
      <div>
        <h1>When would you like your financial model to start and end?<br /><span>We suggest you do one year of actuals and 2 years of projections</span></h1>

      </div>
      <div className="bodyContainer">
        <div className="bodyContainer__InputSection">

          <div className='onboarding__block--x'>
            <div className='onboarding__block--y'>
              <h2>Model start date</h2>
              {/* <span><MDIcons title={"info_icon"} /></span> */}
              <DatePicker
                selected={asanaTaskDateStringToUTCDate(onboardingInputs["start_date"])}
                onChange={(date: Date) => onDateChange(date, "start_date")}
                dateFormat="MMMM yyyy"
                minDate={null}
                maxDate={null}
                showMonthYearPicker
                showFullMonthYearPicker
              />
            </div>

            <div className='onboarding__block--y'>
              <h2>Model End Date</h2>
              <DatePicker
                selected={new Date(onboardingInputs["end_date"])}
                onChange={(date: Date) => onDateChange(date, "end_date")}
                dateFormat="MMMM yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
              />
            </div>
          </div>
          <div className='onboarding__block--x'>
            <div className='onboarding__block--y'>
              <h2>Model's Last Date of Actuals</h2>
              <DatePicker
                selected={new Date(onboardingInputs["last_actuals_date"])}
                onChange={(date: Date) => onDateChange(date, "last_actuals_date")}
                dateFormat="MMMM yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
              />
            </div>

            <div className='onboarding__block--y'>
              <h2>Start of the fiscal year</h2>
              <SelectComponent
                name={MonthsOfYearOptions[0].label}
                options={MonthsOfYearOptions}
                onChange={(value) => {
                  var newArray = { ...onboardingInputs, "offset": value }
                  setOnboardingInputs(newArray)
                }}
                isLoading={false}
                internalLabel={true}
                isSearchable={false}
                value={onboardingInputs["offset"]}
              />
            </div>
          </div>
        </div>
        <div className="footer--btns">
          <button className='btn--M btn--tertiary' onClick={onBack}>
            Previous
          </button>
          <input
            className='btn--primary btn--M '
            type="submit"
            value={done ? "Done" : "Next"}
            onClick={onSubmit}
            disabled={(Object.values(validateDatesObject()).includes(true))}
          />
        </div>
      </div>

    </>
  )
}

export default FormPage2