import { Button } from '@mui/material';
import AlertDialog from './AlertDialog';
import AutorenewIcon from '@mui/icons-material/Autorenew';

interface SyncConfirmDialogProps {
    text: string;
    open: boolean;
    onCancel: () => void;
    onYes: () => void;
}
export default function SyncConfirmDialog({ text, open, onCancel, onYes }: SyncConfirmDialogProps) {

    return (
        <AlertDialog
            dialogType="yes_or_no"
            open={open}
            onClose={onCancel}
            title={text}
            dialogActions={
                <>
                    <Button onClick={() => onCancel()} className='btn--tertiary btn--XS btn--80Width'>
                        Cancel
                    </Button>
                    <Button onClick={() => onYes()} className="btn--secondary btn--XS btn--80Width" autoFocus style={{marginLeft:"0rem"}}>
                        <AutorenewIcon sx={{ width: 17, height: 17 }} />
                        Sync
                    </Button>
                </>
            }
        />
    )
}