import React from "react";

interface UpdateToastProps {
  onUpdate: () => void;
  onCancel: () => void;
}

export default function UpdateToast({ onUpdate, onCancel }: UpdateToastProps) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        justifyItems: "center",
        alignContent: "space-between",
      }}
    >
      <span
        style={{
          fontSize: 12,
          marginBottom: 20,
          color: "#32324D",
          lineHeight: "18px",
        }}
      >
        <span>There are some new updates, do you want update your report?</span>
      </span>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <button
          className="btn--med--green"
          style={{ width: 85, height: 38 }}
          onClick={onUpdate}
        >
          <span>Update</span>
        </button>
        <button
          style={{
            marginLeft: 16,
            border: "none",
            backgroundColor: "transparent",
            fontSize: 14,
            fontWeight: 500,
            color: "#38434DBF",
            width: 85,
            height: 38,
          }}
          onClick={onCancel}
        >
          <span>Not Now</span>
        </button>
      </div>
    </div>
  );
}
