import * as React from 'react';
import { Box } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { EmployeeStatus } from '../../../../types/HeadCount';

export default function renderStatus(params: GridRenderCellParams) {
    return (
        <Box
            component="span"
            sx={{
                backgroundColor: params.row.status === EmployeeStatus.Active ? '#EAFBE7'
                    : params.row.status === EmployeeStatus.Terminated ? '#FCECEA' : '#FDF4DC',

                color: params.row.status === EmployeeStatus.Active ? '#2F6846' : params.row.status === EmployeeStatus.Terminated ? '#B72B1A' : '#BE5D01',
                boxShadow: 'inset -1px 0 0 #f4f4f4!important',
                border: `1px solid ${params.row.status === EmployeeStatus.Active ? '#C6F0C2' : params.row.status === EmployeeStatus.Terminated ? '#F5C0B8' : '#FAE7B9'}`,
                width: 75,
                fontSize: "12px",
                height: 22,
                padding: 1,
                borderRadius: 1.4,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
            {EmployeeStatus[params.row.status]}
        </Box>
    )
}