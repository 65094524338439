import React, { useContext, useEffect } from "react";
import { ReactComponent as QBLogo } from "../../static/img/Onboarding/QuickBooks-Online-Logo.svg";
import { Company, QuickBookConnectingStatus } from "../../types/Company";
import { AuthContext } from "../../contexts/AuthContext";
import { connectCompanyQBO, disconnectCompanyQBO } from "../../APIFunctions/qbo_auth";
import { SocketContext } from "../../contexts/SocketContext";
import MDIcons from "./MDIcons";

const CompanyQBOConnect = () => {
  const { selectedCompany, setSelectedCompany, setUserSelectedCompany } = useContext(AuthContext);
  const { qb_ConnectingStatus, setQB_ConnectingStatus } = useContext(SocketContext);

  const onConnect = async (connect) => {
    if (selectedCompany) {
      if (connect) {
        const consentURL = await connectCompanyQBO(selectedCompany.id);
        window.open(consentURL, "", "width=800,height=800,top=100,left=500");
      } else if (connect === false) {
        const response = await disconnectCompanyQBO(selectedCompany.id);
      }
    }
  };

  useEffect(() => {
    var currentCompany: Company|null = selectedCompany
    if (currentCompany){
        if (qb_ConnectingStatus === QuickBookConnectingStatus.FAILED) {
            currentCompany.is_connected = false
            setUserSelectedCompany(currentCompany)
        } else if (qb_ConnectingStatus === QuickBookConnectingStatus.SUCCESSED) {
            currentCompany.is_connected = true
            setUserSelectedCompany(currentCompany)
        }
        
    }
  },[qb_ConnectingStatus])

  useEffect(() => {
    setQB_ConnectingStatus(selectedCompany?.is_connected ? 3 : 0)
  },[selectedCompany])

  return (
    <div className="CompanyQBOConnect">
      <h1>Connect to Accounting Software</h1>
      <span>Your data is secure and confidential. Learn more at our <a href="https://www.mightydigits.com/terms-of-service" target="_blank" rel="noreferrer">Terms of Use</a></span>
      <div className="CompanyQBOConnect__Container">
        <div className={"bodyContainer__QBORow"}>
          <QBLogo />
          {qb_ConnectingStatus === QuickBookConnectingStatus.NOT_CONNECTED ? (
            <button className="btn--primary btn--S" onClick={() => onConnect(true)}>
              Connect
            </button>
          ) : (
            <>
              {qb_ConnectingStatus === QuickBookConnectingStatus.SUCCESSED ? (
                <>
                  <div className="QBO__Container__Success">
                    <div>
                      <MDIcons title={"check_mark"} /> Your account is connected!
                    </div>
                    <div className="QBO__Container__Disconnect" onClick={() => onConnect(false)}>
                      Disconnect
                    </div>
                  </div>
                </>
              ) : qb_ConnectingStatus === QuickBookConnectingStatus.FAILED ? (
                <>
                  <div className="QBO__Container__Failure">
                    <MDIcons title={"QBFailure"} /> Something went wrong!
                  </div>
                  <div className="btn--secondary btn--S" onClick={() => onConnect(true)}>
                    Try Again
                  </div>
                </>
              ) : (
                <>
                  {/* <div className='QBO__Container__Failure'>
                  </div> */}
                  <div className="btn--secondary btn--S" style={{ cursor: "not-allowed" }} onClick={() => onConnect(true)}>
                    Connect
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyQBOConnect;
