import APIManger from "../APIManger"
import { LOGIN, LOGOUT, SIGN_UP, ACCEPT_INVITATION, INVITATION_COMPLETE_PROFILE, COMPLETE_PROFILE, GET_USER_PROFILE, SEND_TEMP_PASSWORD_CODE, VERIFY_PASSWORD_CODE, RESET_PASSWORD, GOOGLE_INVITATION_COMPLETE_PROFILE, COMPANY_SETTINGS_CHANGE, ACCEPT_ONBOARDING_INVITATION, INVITATION_ONBOARDING_COMPLETE_PROFILE } from '../APIFunctions/apiConstants';
import { AcceptOnboardingInvitation__ResponseAPI, CompleteProfileAPI, ForgetPasswordResponseAPI, GoogleInvitationCompleteProfileAPI, InvitationCompleteProfileAPI, Login__ResponseAPI, ResetPasswordResponseAPI, VerifyCdoeResponseAPI } from "../types/API/authData";
import { AcceptInvitation__ResponseAPI, InvitationCode__RequestAPI } from "../types/API/authData";
import { UserProfile } from "../types/Auth";

export const loginUser = async (email: string, password: string): Promise<Login__ResponseAPI> => {
    const url = LOGIN
    let apiResult: Login__ResponseAPI = await APIManger.post(url, { email, password })
    return apiResult;
}
export const logoutUser = async (token: string): Promise<boolean> => {
    const url = LOGOUT
    let apiResult: string = await APIManger.post(url, undefined, token)
    return apiResult === '';
}
export const invitationCompleteProfile = async (profile: InvitationCompleteProfileAPI, code: string): Promise<UserProfile> => {
    const url = INVITATION_COMPLETE_PROFILE.replace("%INVITATION_CODE%", code)
    let apiResult: UserProfile = await APIManger.post(url, profile)
    return apiResult;
}


export const googleInvitationCompleteProfile = async (data:any, inviteCode: string, token:string): Promise<any> => {
    const url = GOOGLE_INVITATION_COMPLETE_PROFILE.replace("%INVITATION_CODE%", inviteCode)
    let apiResult: any = await APIManger.post(url, {idToken:token,data:data})
    return apiResult;
}

export const invitationOnboardingCompleteProfile = async (profile: InvitationCompleteProfileAPI, code: string): Promise<UserProfile> => {
    const url = INVITATION_ONBOARDING_COMPLETE_PROFILE.replace("%INVITATION_CODE%", code)
    let apiResult: UserProfile = await APIManger.post(url, profile)
    return apiResult;
}


export const completeProfile = async (profile: CompleteProfileAPI): Promise<UserProfile> => {
    const url = COMPLETE_PROFILE
    let apiResult: UserProfile = await APIManger.post(url, profile)
    return apiResult;
}
export const acceptInvite = async (data: InvitationCode__RequestAPI): Promise<AcceptInvitation__ResponseAPI> => {
    const url = ACCEPT_INVITATION
    let apiResult: AcceptInvitation__ResponseAPI = await APIManger.post(url, data)
    return apiResult;
}
export const acceptOnboardingInvite = async (data: InvitationCode__RequestAPI): Promise<AcceptOnboardingInvitation__ResponseAPI> => {
    const url = ACCEPT_ONBOARDING_INVITATION
    let apiResult: AcceptOnboardingInvitation__ResponseAPI = await APIManger.post(url, data)
    return apiResult;
}

export const getUserProfile = async (): Promise<UserProfile> => {
    const url = GET_USER_PROFILE
    let apiResult: UserProfile = await APIManger.get(url)
    return apiResult;
}

export const registerUser = async (data: any): Promise<any> => {
    const url = SIGN_UP
    let apiResult: any = await APIManger.post(url, data)
    return apiResult;
}

export const userForgetPassword = async (email: string): Promise<ForgetPasswordResponseAPI> => {
    const url = SEND_TEMP_PASSWORD_CODE
    let apiResult: any = await APIManger.post(url, { email })
    return apiResult;
}

export const verifyPasswordCode = async (email: string, code: string): Promise<VerifyCdoeResponseAPI> => {
    const url = VERIFY_PASSWORD_CODE
    let apiResult: any = await APIManger.post(url, { email, code })
    return apiResult;
}

export const resetPassword = async (email: string, code: string, new_password: string): Promise<ResetPasswordResponseAPI> => {
    const url = RESET_PASSWORD
    let apiResult: any = await APIManger.post(url, { email, code, new_password })
    return apiResult;
}

export const changeCompanySettings = async (company_id: number, data:any): Promise<ResetPasswordResponseAPI> => {
    const url = COMPANY_SETTINGS_CHANGE.replace("%COMPANY_ID%", company_id.toString())
    let apiResult: any = await APIManger.post(url, data)
    return apiResult;
}

