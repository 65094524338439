export default function QBOExportCreateFailureToast({errorMessage}) {

    return (
        <div>
            <span style={{ fontSize: 14 }}><b>Something went wrong!</b></span>
            <div className="d-flex justify-content-start align-items-center">
                <div className="ml-8">
                    <p style={{ fontSize: 12 }}>We couldn't generate your QBO report. Please contact the system administrator!
                    Error: {errorMessage}
                    </p>
                </div>
            </div>
        </div>
    );
};