import React, { useContext, useState } from "react";
import { MilestoneEmailContext } from "../../../contexts/TicketingSystem/MilestoneEmailContext";
import AlertDialog from "../../Common/AlertDialog";
import EmailContainerOtherActions from "./EmailContainerHeader";
import { MilestoneContext } from "../../../contexts/TicketingSystem/MilestoneContext";
import LoadingBar from "../../Common/LoadingBar";

const EmailReadyToSendContainer = (props) => {
  const { setEmailReadyToSendFlag, onSendEmail, email, emailPendingFlag, sharepointLinkInEmail } = useContext(MilestoneEmailContext);
  const { allowEmailing, payrollLoading, milestoneType } = useContext(MilestoneContext);

  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const onShowConfirmDialog = () => {
    setShowConfirmDialog(true);
  };

  const onCloseConfirmDialog = () => {
    setShowConfirmDialog(false);
  };

  return (
    <AlertDialog
      open={true}
      onClose={() => setEmailReadyToSendFlag("")}
      title="Send to Client"
      dialogType="user-form"
      dialogActions={
        <div className="flex-row-space-between">
          <button className="btn--tertiary btn--S flex-row--S" onClick={() => setEmailReadyToSendFlag("")}>
            Cancel
          </button>
          <EmailContainerOtherActions />
          <button
            disabled={emailPendingFlag}
            style={{ marginLeft: "1rem" }}
            className="btn--primary btn--S flex-row--S"
            onClick={allowEmailing && !email?.sent ? onSendEmail : onShowConfirmDialog}
          >
            <span>Send</span>
          </button>
        </div>
      }
    >
      <LoadingBar loading={payrollLoading} />
      {props.children}
      <AlertDialog
        onClose={onCloseConfirmDialog}
        open={showConfirmDialog}
        title={
          email?.sent
            ? "Warning: This email was sent before, are you sure you want to send again?"
            : !sharepointLinkInEmail?.sharepoint_file_info_web_url
            ? `Warning: You haven't attached a ${milestoneType === 0 ? "Payroll" : "Month End Close"} link. Are you sure you want to send?`
            : "Warning: Senior Manager has not approved. Do you still want to send this email?"
        }
        dialogType="alert"
        dialogActions={
          <div className="flex-row-space-between">
            <button className="btn--tertiary btn--M btn--fullWidth" onClick={onCloseConfirmDialog}>
              Cancel
            </button>
            <button style={{ marginLeft: "1rem" }} className="btn--primary btn--M btn--fullWidth" onClick={onSendEmail}>
              <span>Send</span>
            </button>
          </div>
        }
      ></AlertDialog>
    </AlertDialog>
  );
};

export default EmailReadyToSendContainer;
