import React, { useContext, useEffect } from "react";
import { DataContext, DataContextProvider } from "../../../../../contexts/FinancialReport/DataContext";
import { ReportFilterContextProvider } from "../../../../../contexts/FinancialReport/FilterContext";
import { MilestoneContext } from "../../../../../contexts/TicketingSystem/MilestoneContext";
import FinancialReport from "./Report";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { useQueryClient } from "@tanstack/react-query";
import { getMonthEndCellCommentsList } from "../../../../../APIFunctions/ticketing_system";
import { useSelectedMonthEndCloseMilestone } from "../../../hooks/useSelectedMilestone";
import QBONotConnected from "./QBONotConnected";

const Financials = () => {
  const { selectedCompany } = useContext(AuthContext);
  const { setFullWidthMode } = useContext(MilestoneContext);

  const connectionStatus = selectedCompany?.is_connected

  useEffect(() => {
    setFullWidthMode(true);
    return () => {
      setFullWidthMode(false);
    };
  }, [connectionStatus]);
  return (
    <>
      { connectionStatus === true ?(
        
          <FinancialReport />
      )
      :
      <QBONotConnected />
    
    }
    </>
  );
};

export default Financials;
