import React, { useContext } from "react";
import { SubTasksContext } from "../../../contexts/TicketingSystem/SubTasksContext";
import SubTaskLine from "./SubTaskLine";
import AddIcon from "@mui/icons-material/Add";

const SubTasksList = ({ openSubTask, setSubTask, changeSubTaskInList, onAddSubTask }) => {
  const { displayedSubTasks } = useContext(SubTasksContext);
  return (
    <>
      {displayedSubTasks && displayedSubTasks?.length > 0 && (
        <div className="SubTask__Body__Container__SubTasks__Container">
          <div className="MilestoneCard__SubTasks__Container">
            <div className="MilestoneCard__SubTasks__Title">Feedback</div>
            <div>
              {displayedSubTasks?.map((subtask) => {
                return (
                  <SubTaskLine
                    key={subtask.gid}
                    subtaskName={subtask?.["name"]}
                    subtask={subtask}
                    setSubTask={setSubTask}
                    openSubTask={openSubTask}
                    changeSubTaskInList={changeSubTaskInList}
                    sectionName={null}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
      <div className="SubTask__Body__Container__SubTasks__Container__Footer">
        <button id="" className="btn--tertiary btn--S flex-row--S addSubTask__Button success" onClick={onAddSubTask}>
          <AddIcon /> <span>Add Feedback</span>
        </button>
      </div>
    </>
  );
};

export default SubTasksList;
