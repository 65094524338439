import { feedbackListType } from "../../types/feedback_tasks";

export const getFeedbackSection = (feedbackTaskID,feedbackList) => {
    const list = feedbackList;
    for (let i = 0; i < list.length; i++) {
      const feedbackObj: feedbackListType = list[i];
      if (feedbackObj["task"]["gid"] === feedbackTaskID) {
        return feedbackObj.milestoneTask;
      }
    }
  };