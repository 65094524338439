import React, { useState } from "react";
import { createProjectMilestones } from "../../APIFunctions/AsanaAdminAutomations";
import { showToast } from "../../utils/toastHelpers";
import './style.css'
import SelectComponent from "../Common/SelectComponent";
import GeneralHeader from "../Common/GeneralHeader";
import SectionTitle from "../Common/SectionTitle";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Box, LinearProgress } from "@mui/material";

const MilestoneTypesOption = [
  {value:"Month End Close", label:"Month End Close"},
  {value:"Payroll", label:"Payroll"},
  {value:"Accounts Payable", label:"Accounts Payable"}
]

const MilestonesAutomations = () => {
  const [milestoneType, setMilestoneType] = useState<string>(MilestoneTypesOption[0]["value"]);
  const [loading, setLoading] = useState<boolean>(false)

  const progressBarTheme = createTheme({
    components: {
      // Name of the component
      MuiLinearProgress: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            backgroundColor: "#E5E5E5",
            span: {
              backgroundColor: "#85b440 !important",
            },
          },
        },
      },
    },
  });

  const createMilestones = async () => {
    setLoading(true)
    showToast("success","Milestones creation is initiated successfully. We will let you know when it's done!")
    const response = await createProjectMilestones(milestoneType);
    setLoading(false)
    
    // if (response === "success") {
    //   showToast("success","Milestones have been created.")
    // } else {
    //   showToast("error", "Something went wrong, try again!")
    // }
  };

  const handleChange = (selection) => {
    
    setMilestoneType(selection["value"]);
  };

  return (
    <>
    <GeneralHeader renderDivisions={false} title={"Asana Automations"} />
    {loading ? (
        <Box sx={{ width: "100%" }}>
          <ThemeProvider theme={progressBarTheme}>
            <LinearProgress />
          </ThemeProvider>
        </Box>
      ) : (
        <Box sx={{ width: "100%", backgroundColor: "#E5E5E5", height: "4px" }}></Box>
      )}
      <header className="settings-header-bi">
        <div className="Title__Container">
          <SectionTitle
            title="Milestones Batch Creation"
          />
        </div>
        <div className="header-controllers__container">
        </div>
      </header>

    <div className="MilestonesAutomations__Container">
      <div className="MilestonesAutomations__Container__MilestoneSelection__Container">
      <SelectComponent
            name="MilestoneType"
            options={MilestoneTypesOption}
            onChange={(value) => handleChange(value)}
            isLoading={false}
            value={MilestoneTypesOption.filter(filter => filter.value === milestoneType)}
        />
        </div>
      <button className="btn--primary btn--M" onClick={createMilestones} disabled={loading}>
        Batch Create Asana Milestones
      </button>
    </div>
    </>
  );
};

export default MilestonesAutomations;
