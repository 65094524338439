import { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { useSearchParams } from "react-router-dom";
import { QBOFilterContext } from "./FilterContext";
import { dateObjToAsanaString } from "../../../../../utils/DateFunctions";
import { getAgedReports } from "../../../../../APIFunctions/qbo_report";

export interface DataContextProps {
  reportTabIndex: number;
  setReportTabIndex: (reportTabIndex: number) => void;
  onSelectReportTab: (tabIndex: number) => void;
  headersDisplay: string[];
  detailHeadersDisplay: string[];
  setDetailHeadersDisplay: (detailHeadersDisplay: string[]) => void;
  rowsDisplay: string[]|undefined;
  detailRowsDisplay: string[]|undefined;
  setDetailRowsDisplay: (detailRowsDisplay: string[]|undefined) =>void;
  reportType: any;
  runDetailQuery: (params: any) => void;
  reportDetailLevel:'Summary'|'Detail'|null;
  setReportDetailLevel: (reportDetailLevel:'Summary'|'Detail'|null) => void;
  selectedTransaction: {transaction_type:string, transaction_id:string}|null;
  setSelectedTransaction: (selectedTransaction:{transaction_type:string, transaction_id:string}|null) => void;
  loadingMessage:string;
  dataStatus: any;
}

export const DataContext = createContext<DataContextProps>(null as any);

export const DataContextProvider = (props) => {
  const { selectedCompany } = useContext(AuthContext);
  const { startDate, endDate } = useContext(QBOFilterContext);
  let [searchParams, setSearchParams] = useSearchParams();
  const [reportTabIndex, setReportTabIndex] = useState<number>(0);
  const [dataStatus, setDataStatus] = useState<any>();
  const [loadingMessage, setLoadingMessage] = useState<string>("");
  const [data, setData] = useState<any>();
  const [detailData, setDetailData] = useState<any>();
  const [headersDisplay, setHeadersDisplay] = useState<string[]>([]);
  const [detailHeadersDisplay, setDetailHeadersDisplay] = useState<string[]>([]);
  const [rowsDisplay, setRowsDisplay] = useState<string[]|undefined>(undefined);
  const [detailRowsDisplay, setDetailRowsDisplay] = useState<string[]|undefined>(undefined);
  const [reportType, setReportType] = useState<any>();
  const [reportDetailLevel, setReportDetailLevel] = useState<'Summary'|'Detail'|null>(null);
  const [selectedTransaction, setSelectedTransaction] = useState<{transaction_type:string, transaction_id:string}|null>(null)

  const runDataQuery = async () => {
    if (selectedCompany && startDate && endDate) {
      await props.dataQueryFunction(selectedCompany?.id, { start_date: dateObjToAsanaString(startDate), end_date: dateObjToAsanaString(endDate) });
    }
  };

  const runDetailQuery = async (cellParams) => {
    setDataStatus(0)
    const entity_id = cellParams["id"].split("-")[0];
    let reportDetailType;
    if (reportType === "ap_summary_report") {
      reportDetailType = "AgedPayableDetail";
    }
    if (reportType === "ar_summary_report") {
      reportDetailType = "AgedReceivableDetail";
    }

    await props.detailQueryFunction(
      selectedCompany?.id,
      `start_date=${dateObjToAsanaString(startDate)}&end_date=${dateObjToAsanaString(endDate)}&report_type=${reportDetailType}&entity_id=${entity_id}`
    );
    
  };

  const onSelectReportTab = (tabIndex: number) => {
    if (data) {

      Object.keys(data).forEach(function (key) {
        if (data[key]["report_tab"] === tabIndex) {
          setHeadersDisplay(data[key]["headers"]);
          setRowsDisplay(data[key]["rows"]);
          setReportType(key);
          setReportDetailLevel(data[key]['detail_level'])
          return;
        }
      });
    }
  };

  const getDetailData = async(run_id)=> {
    const response = await getAgedReports(selectedCompany?.id, run_id)
    if ('data' in response) {
      const data = response['data']
      setDetailData(data['detail_report']);
      setDetailHeadersDisplay(data['detail_report']["headers"]);
      setDetailRowsDisplay(data['detail_report']["rows"]);
      // setReportType('detail_report');
      setReportDetailLevel(data['detail_report']['detail_level'])

    }
  }

  const handleWebSocketDetailData = (message, status?, data?) => {
    setLoadingMessage(message);
    setDataStatus(status);
    if (data) {
      getDetailData(data)
    }
    
  };

  const getData = async(run_id) => {
    if (selectedCompany) {
      const response = await getAgedReports(selectedCompany?.id, run_id)
      setData(response['data'])
    }
  }

  const handleWebSocketData = (message, status?, data?) => {
    setLoadingMessage(message);
    setDataStatus(status);
    if (data) {
      // todo: make a request to get the aged reports using this id
      getData(data)
    }
  };

  // todo: create a dynamic way to create a query based on the available params in the url.
  // const getReportByUrl = async () => {
  //     const startDate = searchParams.get("start_date");
  //     const endDate = searchParams.get("end_date");
  //     const reportType = searchParams.get("report_type");
  //       if (startDate && endDate) {
  //         await getTransactionsData({
  //           startDate: dateObjToAsanaString(new Date(startDate)),
  //           endDate: dateObjToAsanaString(new Date(endDate)),
  //           accountID: accountID,
  //         });
  //       }
  //   };

  useEffect(() => {
    onSelectReportTab(reportTabIndex);
  }, [data, reportTabIndex]);

  useEffect(() => {
    setReportTabIndex(searchParams.get("report_type") ? Number(searchParams.get("report_type")) : 0);
    setRowsDisplay(undefined)
    setDataStatus(0)
    props.setDataImportStatusHandle(() => handleWebSocketData);
    runDataQuery();
  }, [startDate, endDate]);

  useEffect(() => {
    props.setDetailDataImportStatusHandle(() => handleWebSocketDetailData);
  }, []);

  return (
    <DataContext.Provider
      value={{
        setReportTabIndex,
        reportTabIndex,
        onSelectReportTab,
        headersDisplay,
        detailHeadersDisplay,
        setDetailHeadersDisplay,
        rowsDisplay,
        detailRowsDisplay,
        setDetailRowsDisplay,
        reportType,
        runDetailQuery,
        reportDetailLevel,
        selectedTransaction,
        setSelectedTransaction,
        setReportDetailLevel,
        loadingMessage,
        dataStatus
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};
