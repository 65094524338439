import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createMilestoneLink, deleteMilestoneLink, editMilestoneLink, getMilestoneLinks, pinningMilestoneLink } from "../../../../../APIFunctions/ticketing_system";
import { SharepointFileInfo } from "../../../../../types/Shareppoint";
import { useContext } from "react";
import { MilestoneEmailContext } from "../../../../../contexts/TicketingSystem/MilestoneEmailContext";
import { MilestoneLinkPinning } from "../../../../../types/TicketingSystem";
import { AuthContext } from "../../../../../contexts/AuthContext";

export const useCreateLink = (email_id) => {
  const queryClient = useQueryClient();
  const { selectedCompany } = useContext(AuthContext);
  const createLinkMutation = useMutation({
    mutationFn: (data: any) => {
      return createMilestoneLink(selectedCompany?.id, data);
    },
    onSuccess: (data) => {
      
      queryClient.invalidateQueries(["MilestoneLink", email_id, selectedCompany?.id]);
    },
  });
  return createLinkMutation;
};

export const useDeleteLink = (link_id) => {
  const queryClient = useQueryClient();
  const { email } = useContext(MilestoneEmailContext);
  const { selectedCompany } = useContext(AuthContext);
  const deleteLinkMutation = useMutation({
    mutationFn: () => deleteMilestoneLink(link_id),
    onSuccess: (data) => {
      queryClient.invalidateQueries(["MilestoneLink", email?.id, selectedCompany?.id], data);
    },
  });
  return deleteLinkMutation;
};

export const useEditLink = (link_id) => {
  const queryClient = useQueryClient();
  const { email } = useContext(MilestoneEmailContext);
  const { selectedCompany } = useContext(AuthContext);

  const editLinkMutation = useMutation({
    mutationFn: (data: SharepointFileInfo) => editMilestoneLink(link_id, data),
    onSuccess: (data) => {
      queryClient.invalidateQueries(["MilestoneLink", email?.id, selectedCompany?.id], data);
    },
  });
  return editLinkMutation;
};

export const usePinLink = (link_id) => {
  const queryClient = useQueryClient();
  const { email } = useContext(MilestoneEmailContext);
  const { selectedCompany } = useContext(AuthContext);
  const pinLinkMutation = useMutation({
    mutationFn: (data: MilestoneLinkPinning) => pinningMilestoneLink(selectedCompany?.id,link_id, data),
    onSuccess: (data) => {
      queryClient.setQueryData(["MilestoneLink", email?.id, selectedCompany?.id], (oldData:any) => {
        const newData = oldData?.map((x) => (x.id === data.id ? data : x));
        return newData;
      });
    },
  });
  return pinLinkMutation;
};

export const useLinksList = (linkType) => {
  const { email } = useContext(MilestoneEmailContext);
  const { selectedCompany } = useContext(AuthContext);
  const linksListQuery = useQuery({
    queryKey: ["MilestoneLink", email?.id, selectedCompany?.id],
    queryFn: async () => await getMilestoneLinks(selectedCompany?.id, email?.id),
    select(data) {
      return data.filter((x) => x.link_type === linkType);
    },
    staleTime: Infinity,
    enabled: !!(email?.id && selectedCompany?.id),
    retry: 5,
    retryDelay: (attempt) => Math.min(attempt > 1 ? 2 ** attempt * 2000 : 1000, 10 * 1000),
  });
  return linksListQuery;
};

// const useGetAsanaSubtasks = (taskGID:string) => {
//     console.log("taskGID passed into GetAsanaSubTasks hook", taskGID)
//     const getAsanaSubTasks = async( ) => {
//         return await retrieveAsanaSubTask(taskGID)
//     }

//     const getAsanaTaskQuery = useQuery({
//         queryKey: ["asana-task", taskGID],
//         queryFn: getAsanaSubTasks,
//         staleTime: Infinity,
//         enabled: !!taskGID,
//         retry: 5,
//         retryDelay: (attempt) => Math.min(attempt > 1 ? 2 ** attempt * 2000 : 1000, 10 * 1000),
//       });
//   return getAsanaTaskQuery
// }
