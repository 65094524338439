import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CircularLoader from "../../Common/CircularLoader";
import { acceptOnboardingInvite } from "../../../APIFunctions/auth";
import { UserProfile } from "../../../types/Auth";
import { InvitationCode__RequestAPI } from "../../../types/API/authData";
import { AuthContext } from "../../../contexts/AuthContext";
import success_people_celebrating from "../../../static/img/success_people_celebrating.png";
import "../LoggedUserInvitation/LoggedUserInvitation.css";
import { Onboarding } from "../../../types/Onboarding";
import { Company } from "../../../types/Company";

interface RouteParams {
  inviteCode: string;
}

export default function LoggedUserOnboardingInvitation() {
  const { inviteCode } = useParams();
  const location = useLocation()
  const { setUserProfile } = useContext(AuthContext);
  const [companyName, setCompanyName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    async function verify(): Promise<void> {
      if (inviteCode) {
        setIsLoading(true);

        const data: InvitationCode__RequestAPI = { invitation_code: inviteCode };
        const result = await acceptOnboardingInvite(data);
        if (result.onboarding) {
          const user: UserProfile = new UserProfile(result.profile);
          localStorage.setItem("UserProfile", JSON.stringify(user));
          setUserProfile(user);
          const invitationOnboarding: Company = new Company(user.companies.find((onboarding) => onboarding.id === result.onboarding.id) ?? user.getFirstCompany());
          setCompanyName(invitationOnboarding.companyName);
          localStorage.setItem("SelectedOnboarding", JSON.stringify(invitationOnboarding));
          // setUserSelectedCompany(invitationOnboarding);
          setIsLoading(false);
        } else navigate("/");
      }
    }
    if (inviteCode && inviteCode !== "") verify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, inviteCode]);
  return (
    <div className="successContainer">
      {isLoading ? (
        <>
          <h4>Please wait! We are validating your invitation link</h4>
          <CircularLoader />
        </>
      ) : (
        <>
          <img src={success_people_celebrating} alt="success!" />
          <h1>
            Thanks! You have successfully initiated the onboarding process for&nbsp;<span>{companyName}</span>
          </h1>
          <h4>
            If you have any questions, please contact &nbsp;<a href="mailto:support@mightydigits.com">support@mightydigits.com</a>.
          </h4>
          <button onClick={() => navigate("/")}>Get Start with onboarding your company</button>
        </>
      )}
    </div>
  );
}
