import { SharepointFileInfo } from "./Shareppoint";

export enum payrollPlatform {
Rippling=0, Gusto, Justworks, Humi, QBO, Trinet, Deel
}

export enum payrollLocation {
    US=0, CAD, Overseas
    }

export enum payrollType {
    Contractor=0, Salary, Hourly
    }

export type MilestoneLinkInfo = {
    email:number;
    name:string;
    link_type:number;
}

export type MilestoneLink = {
    id:number;
    sharepoint_file_info:SharepointFileInfo,
    name:string;
    email:string;
}

export type MilestoneLinkPinning = {
    pinned: boolean;
    company_id: number|null|undefined;
    email_id: number|null|undefined;
}

export type PartialMilestoneLink = Partial<MilestoneLink>