import axios, { AxiosInstance } from "axios";
import { ASANA_BASE_URL } from "../APIFunctions/apiConstants";
import { AccessToken } from "../types/AsanaTask";
import { asanaErrorHanlder } from "../utils/errorHandler";

class AsanaAPIManger {
  axiosInstance: AxiosInstance;
  // token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdXRob3JpemF0aW9uIjoxMjAzMjc2MDM1MTcxOTY0LCJzY29wZSI6ImRlZmF1bHQgaWRlbnRpdHkiLCJzdWIiOjExOTU5NjM5NjMyNDM3NDcsImlhdCI6MTY2NzMzMDY3NCwiZthwIjoxNjY3MzM0Mjc0fQ.JDycdQUqhGuDZpBzwFpOAXOV6jyC4S7OscsuWAgYtXk"
  token = AccessToken.getLocalStorageObj().asana_access_token;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: ASANA_BASE_URL,
      headers: {
        "Content-type": "application/json",
      },
    });
    this.axiosInstance.interceptors.response.use(
      (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data

        return response.data;
      },
      (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        if (error.response?.status === 412) {
          return error.response.data.sync;
        } else if (error.response?.status === 401) {
          this.refreshToken();
        } else {
          asanaErrorHanlder(error);
        }

        /**
         * TODO: Handle errors that require UI
         */
        return error;
      }
    );
  }

  get = async (url: string) => this.axiosInstance.get(url, { headers: { Authorization: "Bearer " + this.token, "Content-Type": "application/json" } });

  getBatch = async (urls: string[]) => {
    return axios.all(urls.map((url) => this.axiosInstance.get(url, { headers: { Authorization: "Bearer " + this.token, "Content-Type": "application/json" } })));
  };

  download = async (url: string) => {
    const element = document.createElement("a");
    element.setAttribute("href", url);
    element.setAttribute("target", "_blank");
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  upload = async (url: string, file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    return this.axiosInstance.post(url, formData, { headers: { Authorization: "Bearer " + this.token, "Content-Type": "multipart/form-data", Accept: "application/json" } });
  };

  delete = async (url: string, data?) => this.axiosInstance.delete(url, { headers: { Authorization: "Bearer " + this.token, Accept: "application/json" } });

  post = async (url: string, data?) => {
    if (this.token) {
      return this.axiosInstance.post(url, data, { headers: { Authorization: "Bearer " + this.token, "Content-Type": "application/json" } });
    } else {
      return this.axiosInstance.post(url, data);
    }
  };

  put = async (url: string, data) => {
    if (this.token) {
      return this.axiosInstance.put(url, data, { headers: { Authorization: "Bearer " + this.token, "Content-Type": "application/json" } });
    } else {
      return this.axiosInstance.put(url, data);
    }
  };

  refreshToken = async () => {};
}

export default new AsanaAPIManger();
