import React, { useContext, useEffect, useRef, useState } from "react";
import { TaskServicesContext } from "../../contexts/Task/TaskServicesContext";
import { TaskTableContextProvider } from "../../contexts/Task/TaskTableContext";
import TasksList from "./TasksTable";
import { BrowserRouter as Router, Route, Routes, useLocation, Outlet } from "react-router-dom";
import { TaskCardContextProvider } from "../../contexts/Task/TaskCardContext";
import { CircularProgress } from "@mui/material";
import PayrollViews from "../TicketingSystem/PayrollViews";
import MonthEndCloseViews from "../TicketingSystem/MonthEndViews/MonthEndViewsRoot";
import MDIcons from "../Common/MDIcons";
import { ReactComponent as AsanaLogo } from "../../static/img/Asana/asana-logo.svg";

const TaskServices = () => {
  const { userInTeam, projectID } = useContext(TaskServicesContext);
  const [denyAccess, setDenyAccess] = useState(false);

  const location = useLocation();

  const scheduleDenyAccess = () => {
    setTimeout(() => {
      displayDenyAccess();
    }, 10000);
  };

  const displayDenyAccess = () => {
    if (!userInTeam) {
      setDenyAccess(true);
    }
  };

  useEffect(() => {
    if (projectID === undefined) {
    }
    scheduleDenyAccess();
  }, [projectID, location.pathname]);

  return (
    <TaskTableContextProvider>
      {userInTeam ? (
        <TaskCardContextProvider>
          <Outlet />
        </TaskCardContextProvider>
      ) : (
        <>
          <div>
            {denyAccess ? (
              <div className="verifyingAccess__Container">
                <div className="verifyingAccess__Container__ImageContainer">
                  <AsanaLogo className="asana_logo__no_access" />
                  <MDIcons title="no_access" />
                  <div className="verifyingAccess__Container__ImageContainer__MainMessage">You do not have access to this asana project!</div>
                  <div className="verifyingAccess__Container__ImageContainer__CallToActionMessage">
                    {`Please ask a Mighty Digits admin to add you to the asana project with id # ${projectID}`}
                  </div>
                </div>
              </div>
            ) : (
              <div className="verifyingAccess__Container">
                <CircularProgress size={150} sx={{ color: "#85b440" }} />
                <span>Verifying your access to this project...</span>
              </div>
            )}
          </div>
        </>
      )}
    </TaskTableContextProvider>
  );
};

export default TaskServices;
