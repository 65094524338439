import {
  DataGridPro,
  GridToolbarExport,
  GridCellParams,
  GridColDef,
  useGridApiContext,
  useGridSelector,
  GridRenderCellParams,
  gridFilteredDescendantCountLookupSelector,
  DataGridProProps,
  GRID_TREE_DATA_GROUPING_FIELD,
  GridToolbarContainer,
  MuiEvent,
  GridCallbackDetails,
  GRID_DATE_COL_DEF,
  GridValueGetterParams,
  useGridApiRef,
  gridSortedRowIdsSelector,
  gridVisibleColumnDefinitionsSelector,
} from "@mui/x-data-grid-pro";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { displayStrDateFormatter } from "../../utils/helpers";
import { MonthEndCloseContext } from "../../contexts/TicketingSystem/MonthEndCloseContext";
import { createStyles, makeStyles } from "@mui/styles";
import { Box, Button, ButtonProps, CircularProgress, Theme } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import NoTableData from "../Common/NoTableData";
import { MilestoneContext } from "../../contexts/TicketingSystem/MilestoneContext";
import ContextMenuMUIDataGrid from "../Common/ContextMenuMUIDataGrid";
import { DataContext } from "../../contexts/FinancialReport/DataContext";
import { getBSTransactionsData, getPLTransactionsData, processTransactionsData } from "../TicketingSystem/Components/MilestoneTaskComponent/Financials/helpers/transactions";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useBSTransactionsReport, usePLTransactionsReport } from "../TicketingSystem/hooks/useTransactions";
import { useCellComments } from "../TicketingSystem/hooks/useComments";
import TransactionDetail from "../TicketingSystem/Components/MilestoneTaskComponent/Financials/TransactionDetail/TransactionDetail";
import { reportTabIndexEnum } from "../TicketingSystem/Components/MilestoneTaskComponent/Financials/types";
import { decodedID } from "../TicketingSystem/Components/QBOReport/Components/DataGrid/utils";
// import { StyleProps } from "../TicketingSystem/Components/MilestoneTaskComponent/Financials/TableComponent/DataGridComponent";

export interface StyleProps {
  zoomLevel: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    root: {
      height: "calc(68vh - 250px)",
      width: "40rem",
      "& .AccountsNames": {
        justifyContent: "flex-start !important",
      },
      "& .ERROR": {
        background: "#F5C0B8",
      },
      "& .FIRST_PROJECTION_COL": {
        borderRight: "1px var(--Neutral500) dashed !important",
      },
      "& .HIGHLIGHT_SELECTED": {
        background: "#FFF500 !important",
      },
      "& .HIGHLIGHT": {
        background: "rgb(255,253,204) !important",
      },
      "& .period__header": {
        flexDirection: "column",
      },

      "& .MuiDataGrid-root": {
        backgroundColor: "#fff",
        border: "none",
        height: "100%",

        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: "bold",
          fontSize: ({ zoomLevel }) =>
            zoomLevel === "1"
              ? 6
              : zoomLevel === "2"
              ? 7
              : zoomLevel === "3"
              ? 8
              : zoomLevel === "4"
              ? 9
              : zoomLevel === "5"
              ? 11
              : zoomLevel === "6"
              ? 12
              : zoomLevel === "7"
              ? 13
              : zoomLevel === "8"
              ? 15
              : zoomLevel === "9"
              ? 17
              : 19,
          lineHeight: "16px",
          color: "#32324D",
        },
        "& .MuiDataGrid-virtualScroller": {
          marginTop: ({ zoomLevel }) =>
            (zoomLevel === "1"
              ? 15
              : zoomLevel === "2"
              ? 16
              : zoomLevel === "3"
              ? 20
              : zoomLevel === "4"
              ? 20
              : zoomLevel === "5"
              ? 50
              : zoomLevel === "6"
              ? 24
              : zoomLevel === "7"
              ? 26
              : zoomLevel === "8"
              ? 28
              : zoomLevel === "9"
              ? 30
              : 32) + "px !important",
        },
        "& .MuiDataGrid-iconSeparator": {
          height: "25px",
          borderLeft: "none",
        },
        "& .MuiDataGrid-columnHeader--sortable": {
          "& .MuiDataGrid-iconButtonContainer": {
            visibility: "visible",
            width: "auto",
            display: "none",
          },
        },
        "& .MuiDataGrid-columnHeader": {
          flexDirection: "column",
          paddingTop: "0.5rem",
          paddingRight: "0.1rem",
          paddingLeft: "2rem",
        },
        "& .MuiDataGrid-columnHeaders": {
          borderTop: "1px solid #32324D",
          borderBottom: "1px solid #32324D",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,

          height: ({ zoomLevel }) =>
            zoomLevel === "1"
              ? 14
              : zoomLevel === "2"
              ? 16
              : zoomLevel === "3"
              ? 20
              : zoomLevel === "4"
              ? 20
              : zoomLevel === "5"
              ? 50
              : zoomLevel === "6"
              ? 24
              : zoomLevel === "7"
              ? 26
              : zoomLevel === "8"
              ? 28
              : zoomLevel === "9"
              ? 30
              : 32,
          minHeight: "15px !important",
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            flexDirection: "row",
            justifyConext: "center",
          },
          "& .MuiDataGrid-columnHeaderDraggableContainer": {
            flexDirection: "row",
            width: "85%"
          },
        },
        "& .MuiDataGrid-pinnedColumnHeaders": {
          height: "24px",
          boxShadow: "none",
        },
        "& .MuiDataGrid-pinnedColumns": {
          boxShadow: "none",
        },
      },
      "& .MuiDataGrid-footerContainer": {
        display: "none",
      },
      "& .MuiDataGrid-row": {
        "& :hover": {
          cursor: "pointer",
          background: "#EAEAEF",
        },
        "&.Mui-selected": {
          backgroundColor: "#FFF!important",
          "& :hover": {
            backgroundColor: "#FFF !important",
          },
        },
      },
      "& .MuiDataGrid-cell": {
        display: "flex",
        color: "#666687",
        fontSize: ({ zoomLevel }) =>
          zoomLevel === "1"
            ? 7
            : zoomLevel === "2"
            ? 8
            : zoomLevel === "3"
            ? 9
            : zoomLevel === "4"
            ? 10
            : zoomLevel === "5"
            ? 11
            : zoomLevel === "6"
            ? 13
            : zoomLevel === "7"
            ? 14
            : zoomLevel === "8"
            ? 16
            : zoomLevel === "9"
            ? 18
            : 20,
        borderLeft: "none",
        borderBottom: "none",
        justifyContent: "flex-end",
        alignItems: "center",
        "& span": {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          fontSize: ({ zoomLevel }) =>
            zoomLevel === "1"
              ? 7
              : zoomLevel === "2"
              ? 8
              : zoomLevel === "3"
              ? 9
              : zoomLevel === "4"
              ? 10
              : zoomLevel === "5"
              ? 11
              : zoomLevel === "6"
              ? 13
              : zoomLevel === "7"
              ? 14
              : zoomLevel === "8"
              ? 16
              : zoomLevel === "9"
              ? 18
              : 20,
        },
      },
      "& .MuiCheckbox-root": {
        color: "#C0C0CF",
      },
      "& .MuiCheckbox-root >svg": {
        border: "0px solid #FFFFFF",
        height: "20px",
        width: "20px",
      },
      "& .MuiCheckbox-root.Mui-checked": {
        color: "#85b440 !important",
      },
    },
  })
);

export const isNavigationKey = (key: string) => key === "Home" || key === "End" || key.indexOf("Arrow") === 0 || key.indexOf("Page") === 0 || key === " ";

const CustomGridTreeDataGroupingCell = (props: GridRenderCellParams) => {
  const { id, field, row, rowNode } = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector);
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleKeyDown: ButtonProps["onKeyDown"] = (event) => {
    if (event.key === " ") {
      event.stopPropagation();
    }
    if (isNavigationKey(event.key) && !event.shiftKey) {
      apiRef.current.publishEvent("cellNavigationKeyDown", props, event);
    }
  };

  const handleClick: ButtonProps["onClick"] = (event) => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };
  const displayValue = row["type"] === "transaction" ? displayStrDateFormatter(row["date"]) : row["date"];

  return (
    <Box sx={{ ml: rowNode.depth * 1 }}>
      <div>
        {filteredDescendantCount > 0 && rowNode.children !== null ? (
          <Button
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            tabIndex={-1}
            sx={{
              width: "60rem",
              height: "3rem",
              color: "#666687",
              justifyContent: "left !important",
              fontWeight: 700,
              marginLeft: "-2rem",
              "&:hover": {
                background: "rgba(133, 180, 64, 0.1)",
              },
            }}
          >
            <span>
              {!rowNode.childrenExpanded ? <ArrowRightIcon /> : <ArrowDropDownIcon />} {displayValue}
            </span>
          </Button>
        ) : row["type"] !== "transaction" ? (
          <Box sx={{ fontWeight: 700 }}>{displayValue}</Box>
        ) : (
          <Box sx={{ fontWeight: 400 }}>{displayValue}</Box>
        )}
      </div>
    </Box>
  );
};

const TransactionsListComponent = ({ zoomLevel }) => {
  const { selectedCompany } = useContext(AuthContext);
  const searchParams = new URLSearchParams(document.location.search);
  const {
    summaryCoordinates,
    commentsTabIndex,
    setSelectedCell,
    selectedCell,
    createCommentMutation,
    setTransactionDetailFlag,
    transactionDetailFlag,
    selectedTransactionDetail,
    setSelectedTransactionDetail,
    reportTabIndex,
    showCommentsPan,
  } = useContext(MonthEndCloseContext);
  const { startDate, endDate, drilledTransactionsData } = useContext(DataContext);
  const [transactionsLoading, setTransactionsLoading] = useState<boolean>(false);
  const reportType = searchParams.get("report_type") || "0";

  const cellComments = useCellComments();

  const PLTransactionsReportData = usePLTransactionsReport(startDate, endDate);
  const BSTransactionsReportData = useBSTransactionsReport(startDate, endDate);

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const [headCells, setHeadcells] = useState<GridColDef[]>([]);

  const [dataRows, setDataRows] = useState<any[]>([]);

  const styleProps = {
    zoomLevel: zoomLevel,
  };

  const classes = useStyles(styleProps);

  const selectCellForComment = async (params: GridCellParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
    setSelectedCell(params);
  };

  const onDisplayTransactionDetail = async (params: GridCellParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
    setTransactionDetailFlag(true);

    setSelectedTransactionDetail(params["row"]);
  };

  const getTreeDataPath: DataGridProProps["getTreeDataPath"] = (row) => row.hierarchy;

  function commify(n) {
    // var parts = n.toString().split(".");
    const thousands = /\B(?=(\d{3})+(?!\d))/g;

    return n.toString().replace(thousands, ",");
  }

  const formatNumber = (value: number, dollarSign: boolean) => {
    if (value < 0) {
      if (dollarSign) {
        return `$(${commify(Math.round(Math.abs(value)))})`;
      } else {
        return `(${commify(Math.round(Math.abs(value)))})`;
      }
    } else if (value > 0) {
      if (dollarSign) {
        return `$${commify(Math.round(value))}`;
      } else {
        return `${commify(Math.round(value))}`;
      }
    } else {
      return "-";
    }
  };

  const commentHighlightFlag = (rowID, colID) => {
    let comments;
    if (rowID && colID) {
      if (commentsTabIndex === 0) {
        comments = cellComments?.data?.filter((x) => x.asana_task.completed === false);
      } else {
        comments = cellComments?.data?.filter((x) => x.asana_task.completed === true);
      }

      const cellCommentExists = comments?.filter((x) => decodedID(x.account_id) === decodedID(rowID) && x.column_id === colID);
      if (cellCommentExists?.length > 0) {
        return "HIGHLIGHT";
      } else {
        return false;
      }
    }
  };

  let newDataRowsArray: any = [];
  const retrieveTransactionsList = () => {
    if (reportType === reportTabIndexEnum.INCOMESTATEMENTDETAIL.toString() && PLTransactionsReportData?.data?.data) {
      let data: any;
      data = processTransactionsData(
        PLTransactionsReportData?.data?.data,
        commentsTabIndex === 0 ? cellComments?.data?.filter((x) => x.asana_task.completed === false) : cellComments?.data?.filter((x) => x.asana_task.completed === true)
      );
      data?.map((x) => {
        newDataRowsArray.push({
          id: x["id"],
          type: x["type"],
          date: x["type"] === "transaction" ? x["date"] : x["name"],
          transaction_type: x["txn_type"],
          transaction_id: x["qb_transaction_id"],
          num: x["num"],
          name: x["type"] === "transaction" ? x["name"] : "",
          memo: x["description"],
          split: x["split"],
          amount: x["type"] === "transaction" ? x["amount"] : x["total"],
          // balance: x["balance"],
          hierarchy: x["hierarchy"],
          highlight_field: x["highlight_field"] ? x["highlight_field"] : [],
        });
      });
    } else if (reportType === reportTabIndexEnum.BALANCESHEETDETAIL.toString() && BSTransactionsReportData?.data?.data) {
      let data: any;
      data = processTransactionsData(
        BSTransactionsReportData?.data?.data,
        commentsTabIndex === 0 ? cellComments?.data?.filter((x) => x.asana_task.completed === false) : cellComments.data?.filter((x) => x.asana_task.completed === true)
      );
      data?.map((x) => {
        newDataRowsArray.push({
          id: x["id"],
          type: x["type"],
          date: x["type"] === "transaction" ? x["date"] : x["name"],
          transaction_type: x["txn_type"],
          transaction_id: x["qb_transaction_id"],
          num: x["num"],
          name: x["type"] === "transaction" ? x["name"] : "",
          memo: x["description"],
          split: x["split"],
          amount: x["type"] === "transaction" ? x["amount"] : x["total"],
          balance: x["balance"],
          debit: x["debt"],
          credit: x["credit"],
          hierarchy: x["hierarchy"],
          highlight_field: x["highlight_field"] ? x["highlight_field"] : [],
        });
      });
    } else if (reportType === reportTabIndexEnum.INCOMESTATEMENTSUMMARY.toString() && drilledTransactionsData.data?.length > 0) {
      processTransactionsData(
        drilledTransactionsData.data,
        commentsTabIndex === 0 ? cellComments?.data?.filter((x) => x.asana_task.completed === false) : cellComments.data?.filter((x) => x.asana_task.completed === true)
      )?.map((x) => {
        newDataRowsArray.push({
          id: x["id"],
          type: x["type"],
          date: x["type"] === "transaction" ? x["date"] : x["name"],
          transaction_type: x["txn_type"],
          transaction_id: x["qb_transaction_id"],
          num: x["num"],
          name: x["type"] === "transaction" ? x["name"] : "",
          memo: x["description"],
          split: x["split"],
          amount: x["type"] === "transaction" ? x["amount"] : x["total"],
          // balance: x["balance"],
          hierarchy: x["hierarchy"],
          highlight_field: x["highlight_field"] ? x["highlight_field"] : [],
        });
      });
    } else if (reportType === reportTabIndexEnum.BALANCESHEETSUMMARY.toString() && drilledTransactionsData.data?.length > 0) {
      processTransactionsData(
        drilledTransactionsData.data,
        commentsTabIndex === 0 ? cellComments?.data?.filter((x) => x.asana_task.completed === false) : cellComments.data?.filter((x) => x.asana_task.completed === true)
      )?.map((x) => {
        newDataRowsArray.push({
          id: x["id"],
          type: x["type"],
          date: x["type"] === "transaction" ? x["date"] : x["name"],
          transaction_type: x["txn_type"],
          transaction_id: x["qb_transaction_id"],
          num: x["num"],
          name: x["type"] === "transaction" ? x["name"] : "",
          memo: x["description"],
          split: x["split"],
          amount: x["type"] === "transaction" ? x["amount"] : x["total"],
          balance: x["balance"],
          hierarchy: x["hierarchy"],
          highlight_field: x["highlight_field"] ? x["highlight_field"] : [],
        });
      });
    }

    const key_value = {
      Amount: "amount",
      "Transaction Type": "transaction_type",
      Num: "num",
      "Memo/Description": "memo",
      Balance: "balance",
      Split: "split",
      Name: "name",
      Debit: "debit",
      Credit: "credit",
    };

    setDataRows(newDataRowsArray);

    const headCellsArrayBS = ["Transaction Type", "Num", "Name", "Memo/Description", "Split", "Debit", "Credit", "Amount", "Balance"];
    const headCellsArrayIS = ["Transaction Type", "Num", "Name", "Memo/Description", "Split", "Debit", "Credit", "Amount"];
    const headCellsArrayByReport =
      reportTabIndex === reportTabIndexEnum.INCOMESTATEMENTSUMMARY || reportTabIndex === reportTabIndexEnum.INCOMESTATEMENTDETAIL ? headCellsArrayIS : headCellsArrayBS;

    const headCellsArray = headCellsArrayByReport.map((key) => {
      if (["Debit", "Credit", "Amount", "Balance"].includes(key)) {
        return {
          field: key,
          headerName: key,
          flex: 1,
          cellClassName: (params: GridCellParams<number>) => {
            const highlightFlag = commentHighlightFlag(params["row"]["id"], params["field"]);

            return highlightFlag === "HIGHLIGHT" ? "HIGHLIGHT" : "NOERROR";
          },
          valueGetter: (params: GridValueGetterParams) => (params.row[key_value[key]] ? params.row[key_value[key]] : ""),
          renderCell: (cellValues) => {
            return (
              <>
                {cellValues.row.type !== "transaction" && cellValues.rowNode.childrenExpanded ? (
                  <Box></Box>
                ) : cellValues.rowNode.childrenExpanded && cellValues.row.type !== "transaction" ? (
                  <Box sx={{ fontWeight: 700 }}>{formatNumber(cellValues.row[key_value[key]], true)}</Box>
                ) : cellValues.rowNode.children ? (
                  <Box sx={{ fontWeight: 700 }}>{formatNumber(cellValues.row[key_value[key]], true)}</Box>
                ) : cellValues.row.type !== "transaction" ? (
                  <Box sx={{ fontWeight: 700 }}>{formatNumber(cellValues.row[key_value[key]], true)}</Box>
                ) : (
                  <Box sx={{ marginLeft: 1, marginTop: 0.4 }}>{formatNumber(cellValues.row[key_value[key]], true)}</Box>
                )}
              </>
            );
          },
        };
      } else {
        return {
          field: key,
          headerName: key,
          flex: ["Num"].includes(key) ? 1 : 2,
          cellClassName: (params: GridCellParams<number>) => {
            const highlightFlag = commentHighlightFlag(params["row"]["id"], params["field"]);

            return highlightFlag === "HIGHLIGHT" && showCommentsPan ? "HIGHLIGHT" : "NOERROR";
          },
          valueGetter: (params: GridValueGetterParams) => (params.row[key_value[key]] ? params.row[key_value[key]] : ""),
        };
      }
    });
    setHeadcells(headCellsArray);

    // setLoaded(true);
  };
  //

  const handleContextMenu = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    let col_id = target.dataset.field;
    if (col_id === undefined) {
      var parent = target.parentElement;
      col_id = parent?.dataset.field;
    }
    const currentTarget = event.currentTarget as HTMLElement;
    const row_id = currentTarget.dataset.id;

    setSelectedCell({ field: col_id, row: { id: row_id, name: `account with id ${row_id}` } });
    event.preventDefault();
    // setSelectedCell(Number(event.currentTarget.getAttribute('data-id')));
    setContextMenu(contextMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null);
  };

  const apiRef = useGridApiRef();

  const rowIndexLocator = (rowId) => {

    try {
      if (rowId) {
        return gridSortedRowIdsSelector(apiRef).findIndex((id) => {
          
          return decodedID(id.toString()) === decodedID(rowId);
        });
      } else {
        return -1;
      }
    } catch {
      return -1;
    }
  };

  const colIndexLocator = (colId) => {
    try {
      if (colId) {
        return gridVisibleColumnDefinitionsSelector(apiRef).findIndex((column) => column.field === colId);
      } else {
        return -1;
      }
    } catch {
      return -1;
    }
  };

  useEffect(() => {
    const { rowIndex, colIndex } = summaryCoordinates;
    if (rowIndex && colIndex) {
      
      const rowIndexFound = rowIndexLocator(rowIndex);
      const colIndexFound = colIndexLocator(colIndex);
      if (rowIndexFound !== -1 && colIndexFound !== -1) {
        apiRef.current.scrollToIndexes({ rowIndex: rowIndexFound, colIndex: colIndexFound });
        setTimeout(() => {
          apiRef.current.setCellFocus(dataRows[rowIndexFound]["id"], colIndex);
        }, 500);
      }
    }
  }, [apiRef, summaryCoordinates]);

  const groupingColDef: DataGridProProps["groupingColDef"] = {
    headerName: "Transactions",
    flex: 3,
    cellClassName: (params: GridCellParams<number>) => "AccountsNames",
    ...GRID_DATE_COL_DEF,
    valueGetter: (params: GridValueGetterParams) => params.row["date"],
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
  };

  useEffect(() => {
    setTransactionsLoading(true);
    retrieveTransactionsList();
    setTransactionsLoading(false);
  }, [reportType, PLTransactionsReportData.data, BSTransactionsReportData?.data, drilledTransactionsData]);

  const queryClient = useQueryClient();
  const currentSyncInformation: any = queryClient.getQueryData(["current-sync-info"]);

  return (
    <>
      {PLTransactionsReportData.isLoading ||
      BSTransactionsReportData.isLoading ||
      (drilledTransactionsData.status === "loading" && drilledTransactionsData.fetchStatus !== "idle") ||
      currentSyncInformation?.status === "Running" ||
      currentSyncInformation === undefined ? (
        <div className="verifyingAccess__Container">
          <CircularProgress size={150} sx={{ color: "#85b440" }} />
          <span>loading transactions...</span>
        </div>
      ) : selectedTransactionDetail && transactionDetailFlag ? (
        <TransactionDetail transaction_type={selectedTransactionDetail?.["transaction_type"]} transaction_id={selectedTransactionDetail?.["transaction_id"]} />
      ) : (
        <div style={{ flexGrow: 1, height: "100%" }} className={classes.root}>
          <DataGridPro
            treeData
            getTreeDataPath={getTreeDataPath}
            groupingColDef={groupingColDef}
            defaultGroupingExpansionDepth={-1}
            // disableColumnFilter
            // disableChildrenSorting
            showColumnRightBorder
            columns={headCells}
            rows={dataRows}
            apiRef={apiRef}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  Debit: false,
                  Credit: false,
                },
              },
            }}
            components={{
              NoRowsOverlay: NoTableData,
            }}
            componentsProps={{
              row: {
                onContextMenu: handleContextMenu,
                style: { cursor: "context-menu" },
              },
            }}
            onCellClick={(params, event, details) => selectCellForComment(params, event, details)}
            onCellDoubleClick={(params, event, details) => onDisplayTransactionDetail(params, event, details)}
            rowHeight={
              zoomLevel === "1"
                ? 14
                : zoomLevel === "2"
                ? 16
                : zoomLevel === "3"
                ? 18
                : zoomLevel === "4"
                ? 20
                : zoomLevel === "5"
                ? 22
                : zoomLevel === "6"
                ? 25
                : zoomLevel === "7"
                ? 26
                : zoomLevel === "8"
                ? 27
                : zoomLevel === "9"
                ? 29
                : 31
            }
          />
          <ContextMenuMUIDataGrid contextMenu={contextMenu} setContextMenu={setContextMenu} handleCreateComment={createCommentMutation} />
        </div>
      )}
    </>
  );
};

export default TransactionsListComponent;
