import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../contexts/AuthContext'
import { Document, Page, pdfjs } from 'react-pdf';
import DownloadIcon from '@mui/icons-material/Download';

import './PaymentPlan.css'
import { exportPaymentPlan } from '../../../APIFunctions/accountSettings';
import MDIcons from '../../Common/MDIcons';
import NoDataFound from '../../Common/NoDataFound';

export default function PaymentPlan()  {

  pdfjs.GlobalWorkerOptions.workerSrc = 
    `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const { selectedCompany } = useContext(AuthContext)
    const [ numPages, setNumPages ] = useState(null);
    const [ pageNumber, setPageNumber ] = useState(1);

    const onBack = () => {
      setPageNumber((pageNumber) => pageNumber > 1 ? pageNumber - 1 : 1)
    }

    const onNext = () => {
      setPageNumber((pageNumber) => pageNumber + 1 )
    }

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }

  return (
    <>
     {selectedCompany?.companyPaymentPlanURL ?
     <>
     <div className='PaymentPlan__Header'>
       <button className='btn--secondary btn--S' onClick={() => {if (selectedCompany) exportPaymentPlan(selectedCompany)}}>
        <DownloadIcon /> <span>Download</span>
      </button>
    </div>
    <div className='PaymentPlan__Container'>
        <Document file={selectedCompany?.companyPaymentPlanURL ? selectedCompany.companyPaymentPlanURL:undefined} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
    </div>
    {numPages && 
    <footer>
      {pageNumber !== numPages && <button onClick={onNext}>Next<MDIcons title='next'/></button>}
     </footer>}
     </> :
     <>
      <NoDataFound />
     </>
     }
     </>
  )
}
