import { Button } from '@mui/material';
import AlertDialog from '../../../Common/AlertDialog';
import DeleteIcon from '@mui/icons-material/Delete';

interface DeleteEmployeeDialogProps {
    open: boolean;
    onCancel: () => void;
    onYes: () => void;
}
export default function DeleteEmployeeDialog({ open, onCancel, onYes }: DeleteEmployeeDialogProps) {

    return (
        <AlertDialog
            dialogType="yes_or_no"
            open={open}
            onClose={onCancel}
            title="Are you sure you want to delete this?"
            dialogActions={
                <>
                    <Button onClick={() => onCancel()} className='btn--tertiary btn--XS btn--80Width'>
                        Cancel
                    </Button>
                    <Button onClick={() => onYes()} className="btn--secondary-danger btn--XS btn--80Width" autoFocus style={{marginLeft:"0rem"}}>
                    <DeleteIcon sx={{marginRight:"0px"}}/>
                        Delete
                    </Button>
                </>
            }
        />
    )
}