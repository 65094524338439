export enum PayrollTaskType {
  STAFF_PREPARATION, MANAGER_REVIEW, CLIENT_REVIEW, READY_TO_PROCESS, PROCESSED
}

export const getPayrollTaskType = (taskName) => {

  
  if (taskName?.toLowerCase().includes("staff")) return PayrollTaskType.STAFF_PREPARATION
  if (taskName?.toLowerCase().includes("manager")) return PayrollTaskType.MANAGER_REVIEW
  if (taskName?.toLowerCase().includes("client")) return PayrollTaskType.CLIENT_REVIEW
  if (taskName?.toLowerCase().includes("process")) return PayrollTaskType.READY_TO_PROCESS
  if (taskName?.toLowerCase().includes("processed")) return PayrollTaskType.PROCESSED
      
}

export enum MonthEndTaskType {
  STAFF_PREPARATION, MANAGER_REVIEW, FINAL_REVIEW, SEND_TO_CLIENTS
}

export const getMonthEndTaskType = (taskName) => {

  
  if (taskName?.toLowerCase().includes("manager")) return MonthEndTaskType.MANAGER_REVIEW
  if (taskName?.toLowerCase().includes("final")) return MonthEndTaskType.FINAL_REVIEW
  if (taskName?.toLowerCase().includes("senior")) return MonthEndTaskType.FINAL_REVIEW
  if (taskName?.toLowerCase().includes("staff")) return MonthEndTaskType.STAFF_PREPARATION
  if (taskName?.toLowerCase().includes("send")) return MonthEndTaskType.SEND_TO_CLIENTS
      
}



export const attachmentOptions = [
{ value: "Download Attachment", label: "Download Attachment" },
{ value: "Delete Attachment", label: "Delete Attachment" }
]

export const statusColor = (status) => {
switch (status) {
  case "On Deck":
    return "F29D41";
  case "Not Started":
    return "EE5E52";
  case "In Progress":
    return "C0C0CF";
  case "In Review":
    return "rgba(133, 180, 64, 0.75)";
  case "Waiting - Internal":
    return "#0C75AF";
  case "Waiting - External":
    return "#66B7F1";
  case "Complete":
    return "#5CB176";
  default:
}};

export const priorityColor = (priority) => {
switch (priority) {
  case "Low":
    return "#C0C0CF";
  case "Medium":
    return "#F29D41";
  case "HIGH":
    return "#EE5E52";
  default:
}};