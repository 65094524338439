export const checkPermission = (emails, tag) => 
  emails?.map((x) => x.toLowerCase())?.includes(tag.toLowerCase()) ||
  emails === null ||
  tag.includes("@mightydigits.com");

export const TooltipMessage = ({ customErrorMessages, sharepointLinkInEmail, permissionGranted, asanaPermissionsGranted, appPermissionsGranted }) => {
  if (customErrorMessages) {
    return customErrorMessages;
  } else if (sharepointLinkInEmail?.sharepoint_file_info?.web_url && !permissionGranted) {
    return "This recipient has no access to the link you're attaching to this email. CLICK to grant him access now!";
  } else if (!asanaPermissionsGranted) {
    return "No access to Asana.";
  } else if (!appPermissionsGranted) {
    return "No access to the app.";
  } else {
    return "All required permissions are granted!";
  }
};
