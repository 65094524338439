import comingsoon from '../../static/img/no-data-found.svg';
import './ComingSoon.css';

export default function NoViewerFound() {
    return (
        <>
            <div className="ComingSoon__Container">
                <img src={comingsoon} alt="Coming Soon" />
                <span className="large-bold">
                    No Viewer Found!
                </span>
                <span className="med-light">
                    If you have any questions, please contact&nbsp;
                    <span><a className="help-link" href="mailto:support@mightydigits.com">support@mightydigits.com</a></span>.
                </span>
            </div>
        </>
    )
}