import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { getAccountSettingsUsers } from "../../APIFunctions/accountSettings";
import { useInterval } from "../../hooks";
import { LoaderStatus } from "../../types/Loaders";
import { ManageUsers } from "../../types/ManageUsers";
import { showToast } from "../../utils/toastHelpers";
import { AuthContext } from "../AuthContext";

export interface DataConextProps {
    settingLoaderStatus: LoaderStatus;
    setLoaderStatus: (status: LoaderStatus) => void;

    settingLoaderValue: number;
    setLoaderValue: (status: number) => void;

    userListData: ManageUsers[];
    userListDisplay: ManageUsers[];

    searchText: string;
    setSearchText: (searchText: string) => void;
    showToastThenRefresh: (message: string, markedCount?: number) => void;
    loadEntireData: () => Promise<void>;

}

export const DataConext = createContext<DataConextProps>(null as any);

export const DataConextProvider = (props) => {
    const { selectedCompany, userProfile } = useContext(AuthContext)
    const [settingLoaderStatus, setLoaderStatus] = useState<LoaderStatus>(LoaderStatus.Loading);
    const [settingLoaderValue, setLoaderValue] = useState<number>(1);
    const [userListData, setuserListData] = useState<ManageUsers[]>([])
    const [userListDisplay, setuserListDisplay] = useState<ManageUsers[]>([])
    const [searchText, setSearchText] = useState<string>('');

    useInterval(() => {
        const currentLoaderValue = settingLoaderValue + Math.floor((Math.random() * 2) + 1);
        switch (settingLoaderStatus) {
            case LoaderStatus.Loading:
                if (currentLoaderValue < 30)
                    setLoaderValue(30)
                else if (currentLoaderValue > 90)
                    setLoaderValue(90)
                else
                    setLoaderValue(currentLoaderValue)
                break;
            case LoaderStatus.Loaded:
                if (currentLoaderValue < 90)
                    setLoaderValue(90)
                else if (currentLoaderValue > 95)
                    setLoaderValue(95)
                else
                    setLoaderValue(currentLoaderValue)
                break;
            case LoaderStatus.GeneratingReports:
                if (currentLoaderValue < 95)
                    setLoaderValue(95)
                else if (currentLoaderValue > 100)
                    setLoaderValue(100)
                else
                    setLoaderValue(currentLoaderValue)
                break;
            default:
                break;
        }
    }, 250, settingLoaderStatus !== LoaderStatus.Rendered);


    const MangeUserFactory = useCallback((obj: ManageUsers, is_new = false): ManageUsers => {
        var presentation_object = new ManageUsers(obj, userProfile.id === obj.user?.id)
        if(presentation_object.id > 0)
            presentation_object.new = is_new
        return presentation_object
    }, [])


    const loadEntireData = useCallback(async (markedCount?: number) => {
        if (selectedCompany) {
            setLoaderValue(1)
            setLoaderStatus(LoaderStatus.Loading);
            const user_list = await getAccountSettingsUsers(selectedCompany)
            setLoaderStatus(LoaderStatus.Loaded);
            var allData: ManageUsers[] = []
            var filteredData: ManageUsers[] = []
            setLoaderStatus(LoaderStatus.GeneratingReports);
            user_list.forEach((item, index) => {
                var user_obj: ManageUsers = MangeUserFactory(item, index <= (markedCount ?? 0))
                allData.push(user_obj)
                if (user_obj.isMatching(searchText) && user_obj.role !== 2)
                    filteredData.push(user_obj);
            })

            setuserListData(allData);
            setuserListDisplay(filteredData);
            setLoaderStatus(LoaderStatus.Rendered);
            setTimeout(() => {
                if (markedCount) {
                    setTimeout(() => {
                        filteredData = filteredData.map((item) => {
                            item.new = false
                            return item
                        })
                        setuserListDisplay(filteredData);
                    }, 3000);
                }
            });
        }
    }, [selectedCompany, searchText])

    useEffect(() => {
        loadEntireData()
        
    }, [selectedCompany])


    useEffect(() => {
        var filteredData: ManageUsers[] = []
        userListData.forEach((item) => {
            var user_obj: ManageUsers = MangeUserFactory(item, false)
            if (user_obj.isMatching(searchText))
                filteredData.push(user_obj);
        })
        setuserListDisplay(filteredData);
    }, [searchText])

    const showToastThenRefresh = (message: string, markedCount?: number) => {
        showToast('success', message, 'Success');
        loadEntireData(markedCount ?? 0);
    }


    return (
        <DataConext.Provider value={{
            settingLoaderStatus,
            setLoaderStatus,

            settingLoaderValue,
            setLoaderValue,

            userListData,
            userListDisplay,

            searchText,
            setSearchText,
            showToastThenRefresh,

            loadEntireData,
        }}>
            {props.children}
        </DataConext.Provider>
    )
}

