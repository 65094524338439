import { ReactComponent as Dashboard } from '../../static/img/sidebar-images/dashboard.svg';
import { ReactComponent as FinancialStatement } from '../../static/img/sidebar-images/financial-statements.svg';
import { ReactComponent as HeadCount } from '../../static/img/sidebar-images/headcount.svg';
import { ReactComponent as PitchDeck } from '../../static/img/sidebar-images/pitch-deck.svg';
import { ReactComponent as BudgetToActuals } from '../../static/img/sidebar-images/budget-to-actuals.svg';
import { ReactComponent as Summary } from '../../static/img/sidebar-images/summary.svg';
import { ReactComponent as Settings } from '../../static/img/sidebar-images/settings.svg';
import { ReactComponent as Payroll } from '../../static/img/sidebar-images/payroll.svg';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { ReactComponent as AP } from '../../static/img/sidebar-images/accounts-payable.svg';
import { ReactComponent as MonthEndClose } from '../../static/img/sidebar-images/month-end-close.svg';
import { ReactComponent as Export_To_Excel } from '../../static/img/sidebar-images/export-to-excel.svg';
import { ReactComponent as month_end_close } from '../../static/img/sidebar-images/month-end-close-icon.svg';
import { ReactComponent as asana } from '../../static/img/sidebar-images/Asana-sidebar.svg';


const sideBarIcons = {
    'Dashboard': Dashboard,
    'Financial Statements': FinancialStatement,
    'Headcount': HeadCount,
    'Pitch Deck': PitchDeck,
    'Budget To Actuals': BudgetToActuals,
    'Summary': Summary,
    'Settings': Settings,
    'Revenue': MonetizationOnIcon,
    "User":FormatListBulletedIcon,
    "AdminModules":AdminPanelSettingsIcon,
    "Payroll":Payroll,
    "month End Close":MonthEndClose,
    "accounts Payable":AP,
    'Export Financials To Excel':Export_To_Excel,
    "Month End Close":month_end_close,
    "Asana Automations":asana
}

interface IconsProps {
    iconName: string;
    pathname: string;
    url: string;
}
export default function Icons({ iconName, pathname, url }: IconsProps) {

    const ModuleIcon = sideBarIcons[iconName];
    if (ModuleIcon)
        return <ModuleIcon className={pathname === url ? ' active' : ''} sx={{fontSize:20}}/>
    else return <></>
}
