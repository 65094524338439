import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { retrieveAsanaIncompleteMilestonesInSection, retrieveAsanaTask, retrieveAsanaTasksInSection } from "../../../APIFunctions/Asana";
import { useNavigate, useParams } from "react-router-dom";
import { TaskServicesContext } from "../../../contexts/Task/TaskServicesContext";
import { MilestoneContext } from "../../../contexts/TicketingSystem/MilestoneContext";

export const useSelectedMonthEndCloseMilestone = () => {
  let { milestonegid } = useParams();
  const [selectedMilestoneFirstIncompleteQueryEnabled, setSelectedMilestoneFirstIncompleteQueryEnabled] = useState<boolean>(false)
  const [selectedMilestoneEnabled, setSelectedMilestoneEnabled] = useState<boolean>(false)
  const { sectionsQuery } = useContext(TaskServicesContext);
  const [milestoneSectionGID, setMilestoneSectionGID] = useState<string>(sectionsQuery.data?.data?.find((x) => x.name === "Month End Close")?.["gid"] || "");
  
  
  const { refreshMilestonesFlag, setSelectedMilestone } = useContext(MilestoneContext);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // const selectedMilestone = useGetAsanaTask(milestonegid ? milestonegid : "")
  const selectedMilestone =  useQuery({
    queryKey: ["month-end", "selected-milestone", "by-url",milestonegid],
    queryFn: () => retrieveAsanaTask(milestonegid || ""),
    staleTime: Infinity,
    enabled: selectedMilestoneEnabled,
  });

  const selectedMilestoneFirstIncompleteQuery = useQuery({
    queryKey: ["month-end", "selected-milestone", "first-incomplete", milestoneSectionGID],
    queryFn: ({queryKey}) => retrieveAsanaIncompleteMilestonesInSection(queryKey[3]),
    staleTime: Infinity,
    enabled: selectedMilestoneFirstIncompleteQueryEnabled,
    select: useCallback((data) => data?.data?.[0], [milestoneSectionGID]),
  });

  useEffect(() => {
    if (sectionsQuery.data?.data?.length > 0) {
      const gid = sectionsQuery.data?.data.find((x) => x.name === "Month End Close")?.["gid"];
      setMilestoneSectionGID(gid || "");
      queryClient.refetchQueries({ queryKey: ["month-end", "selected-milestone", "first-incomplete"] });
    }
  }, [sectionsQuery.data]);

  useEffect(() => {
    if (milestonegid && milestonegid !== "") {
      
      setSelectedMilestoneEnabled(true)
      setSelectedMilestoneFirstIncompleteQueryEnabled(false)
      queryClient.refetchQueries({ queryKey: ["month-end", "selected-milestone", "by-url",milestonegid] });
    } else {
      setSelectedMilestoneEnabled(false)
      setSelectedMilestoneFirstIncompleteQueryEnabled(true)
      queryClient.refetchQueries({ queryKey: ["month-end", "selected-milestone", "first-incomplete",milestoneSectionGID] });
    }
  }, [milestonegid]);

  useEffect(() => {
    if (selectedMilestoneFirstIncompleteQuery.data && !milestonegid) {
      
      navigate(selectedMilestoneFirstIncompleteQuery.data?.gid);
    }
  }, [selectedMilestoneFirstIncompleteQuery.fetchStatus]);
  
  
  useEffect(() => {
      if (milestoneSectionGID && milestoneSectionGID !== "" && !milestonegid) {
        
        setSelectedMilestoneFirstIncompleteQueryEnabled(true)
        queryClient.refetchQueries({ queryKey: ["month-end", "selected-milestone", "first-incomplete",milestoneSectionGID] });
      } else {
        setSelectedMilestoneFirstIncompleteQueryEnabled(false)
      }
  }, [milestoneSectionGID, refreshMilestonesFlag]);

  useEffect(() => {
    setSelectedMilestone(selectedMilestone.data ? selectedMilestone.data.data : null);
    if (selectedMilestone.data?.data?.gid && !milestonegid) {
      
      navigate(selectedMilestone.data?.data?.gid);
    }
  }, [selectedMilestone.data]);

  return selectedMilestone;

  // discuss with Mihail
};
