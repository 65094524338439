import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../contexts/AuthContext";
import { MilestoneContext } from "../../../../contexts/TicketingSystem/MilestoneContext";
import { MilestoneLinksContext } from "../../../../contexts/TicketingSystem/MilestoneLinksContext";
import { MilestoneEmailContext } from "../../../../contexts/TicketingSystem/MilestoneEmailContext";
import MonthEndQBOContainer from "../../../ExportQBO/MonthEndQBOContainer";
import { MonthEndCloseContext } from "../../../../contexts/TicketingSystem/MonthEndCloseContext";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import CustomToast from "../../../Common/CutomToast";
import { showToast } from "../../../../utils/toastHelpers";
import MonthEndCloseDefaultSecondLine from "../../MilestoneEmail/EmailPreviewComponent/DefaultSecondLine.tsx/MonthEndClose";
import { SocketContext } from "../../../../contexts/SocketContext";
import { UploadedSharepointFileInfo } from "../../../../types/QBOExport";
import { convertLegacyLinkToMilestoneLink, getSharepointInfoObj } from "../Links/Util";
import { useCreateLink, useEditLink, useLinksList } from "../Links/LinksTableComponent/Hooks";
import { createMilestoneLink, editMonthEndLink } from "../../../../APIFunctions/ticketing_system";
import CustomTooltip from "../../../Common/Tooltip";
import { MilestoneLinkInfo } from "../../../../types/TicketingSystem";
dayjs.extend(relativeTime);

const DraftFinancialPackage = () => {
  const { selectedCompany } = useContext(AuthContext);
  const { email, setSharepointLinkInEmail, sharepointLinkInEmail } = useContext(MilestoneEmailContext);
  const { setHandleUploadedMECFinancialPackageFunction } = useContext(SocketContext);
  const { setPayrollLoading } = useContext(MilestoneContext);
  const { monthEndPeriod } = useContext(MonthEndCloseContext);
  const [displaySharepointBrowser, setDisplaySharepointBrowser] = useState<boolean>(false);
  const [saveFolder, setSaveFolder] = useState<any>();
  const linksListQuery = useLinksList(1);
  const createLinkMutation = useCreateLink(email?.id);
  const editLinkMutation = useEditLink(sharepointLinkInEmail?.id)


  const updateDraftFinancialPackageLink = () => {
    
    const targetLink = linksListQuery.data?.[0];
    if (targetLink) {
      setSharepointLinkInEmail(targetLink);
    }
  };

  const handleNewlyUploadedFile = async (uploadedFile: UploadedSharepointFileInfo) => {
    if ("webUrl" in uploadedFile && "id" in uploadedFile) {
      const link = linksListQuery?.data?.[0];
      const placeHolderFolder = {
        parentReference: { path: "" },
        webUrl: uploadedFile.webUrl,
        name: "Month End Financial Package",
      };

      const sharepoint_file_info = getSharepointInfoObj(uploadedFile, placeHolderFolder);
      if (link) {
        editLinkMutation.mutate(sharepoint_file_info)
        setSharepointLinkInEmail({...sharepointLinkInEmail, sharepoint_file_info})
      } else {
        // create the link if it doesn't exist
        if (email) {
          const link_info: MilestoneLinkInfo = { email: email?.id, name: "Month End Financial Package", link_type: 1 };
          const data = { ...link_info, sharepoint_file_info: sharepoint_file_info };
          createLinkMutation.mutate(data);
        }
      }
    } else {
      showToast("Error", "We couldn't generate the file for you.");
    }
  };

  const [lastExportTime, setLastExportTime] = useState<any>();

  useEffect(() => {
    setHandleUploadedMECFinancialPackageFunction(() => handleNewlyUploadedFile);
    updateDraftFinancialPackageLink();
  }, [email, linksListQuery.data]);

  return (
    <>
      <MonthEndQBOContainer
        monthEndPeriod={monthEndPeriod}
        company={selectedCompany}
        displaySharepointBrowser={displaySharepointBrowser}
        setDisplaySharepointBrowser={setDisplaySharepointBrowser}
        setSaveFolder={setSaveFolder}
        saveFolder={saveFolder}
        byCompany={false}
        setCompany={null}
        setLoading={setPayrollLoading}
        setLastExportTime={setLastExportTime}
        outputFormat="Pivoted"
      />
      <div className="MilestoneTaskComponent__ExportQBO__Footer">
        <div>{lastExportTime && `Last generated: ${dayjs(lastExportTime).fromNow()}`}</div>
        <CustomTooltip
          title={
            <React.Fragment>{selectedCompany?.is_connected ? "Click to select Sharepoint Folder to Export QBO report there." : "Quickbooks is NOT connected!"}</React.Fragment>
          }
        >
          <button className="btn--primary btn--L" onClick={() => setDisplaySharepointBrowser(true)} disabled={!selectedCompany?.is_connected}>
            Run Report
          </button>
        </CustomTooltip>
      </div>
    </>
  );
};

export default DraftFinancialPackage;
