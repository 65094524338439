import React from 'react';
import { MoonLoader } from "react-spinners";
import { css } from '@emotion/react';

interface CircularLoaderProps {
    color?: string;
    size?: number;
    customClassName?: string;
}

export default function CircularLoader(props: CircularLoaderProps) {
    const { color = '#007bff', size = 60, customClassName = null } = props;
    const override = css`
                        display: block;
                        margin: 0 auto;
                        border-color: red;
                        `;
    return (
        <div className={customClassName || "report-loader"}>
            <MoonLoader color={color} loading={true} css={override} size={size} />
        </div>
    )
}