import React from 'react'
import TransactionLineTable from './TransactionLineTable'

const ExpenseTransaction = ({transaction}) => {
  return (
    <div>
      <div className="TransactionDetail__GroupContainer">
        <div className="TransactionDetail__ParameterContainer">
          <span>Payment date</span>
          <div>{transaction?.["detail"]?.["Purchase"]?.["TxnDate"]}</div>
        </div>
        <div className="TransactionDetail__ParameterContainer">
          <span>{transaction?.["detail"]?.["Purchase"]?.["EntityRef"]?.["type"]}</span>
          <div>{transaction?.["detail"]?.["Purchase"]?.["EntityRef"]?.["name"]}</div>
        </div>
        <div className="TransactionDetail__ParameterContainer">
          <span>Payment Account</span>
          <div>{transaction?.["detail"]?.["Purchase"]?.["AccountRef"]?.["name"]}</div>
        </div>
        </div>
        <TransactionLineTable lines={transaction?.["detail"]?.["Purchase"]?.["Line"]} transactionID={transaction?.["detail"]?.["Purchase"]?.["Id"]} transaction_type="Expense"/>
        <div className="TransactionDetail__Subtotal">
          <span>Total Amount</span>
          <div>${transaction?.["detail"]?.["Purchase"]?.["TotalAmt"]}</div>
        </div>
    </div>
  )
}

export default ExpenseTransaction