import React, { useContext } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import MDIcons from "../../Common/MDIcons";
import TaskHeaderButtons from "../../Common/Asana/TaskHeaderButtons";
import { MilestoneContext } from "../../../contexts/TicketingSystem/MilestoneContext";

const SubTaskContainerHeader = ({ subtask, setSubTask, completed, onClose, onComplete, onDelete }) => {
  
  const { setPayrollLoading, payrollLoading } = useContext(MilestoneContext);
  return (
    <>
      <div className="SubTask__Header">
        <button id="back-btn" data-test="back-btn" onClick={onClose} disabled={payrollLoading}>
          <ArrowBackIosNewIcon />
        </button>
        <button id="" style={{ cursor: "pointer" }} className={completed ? "btn--secondary--completed btn--S flex-row--S success" : "btn--tertiary btn--S success"} onClick={onComplete}>
          <MDIcons title="check_button" />
          {completed ? "Marked completed" : "Mark complete"}
        </button>
        <TaskHeaderButtons
          task={subtask}
          setTask={setSubTask}
          setLoading={setPayrollLoading}
          // retrieveAttachments={retrieveAttachments}
          onDelete={onDelete}
          loading={payrollLoading}
        />
      </div>
    </>
  );
};

export default SubTaskContainerHeader;
