import { createContext, useState } from "react";


export interface SideBarContextProps {
    isSideBarCollapsed: boolean;
    toggleSideBar: () => void;
}

export const SideBarContext = createContext<SideBarContextProps>(null as any);

export const SideBarContextProvider = (props) => {
    const [isSideBarCollapsed, setIsSideBarCollapsed] = useState(true);

    const toggleSideBar = () => {
        setIsSideBarCollapsed(!isSideBarCollapsed)
    }
return  (
    <SideBarContext.Provider value={{
        isSideBarCollapsed,
        toggleSideBar
    }}>
        {props.children}
    </SideBarContext.Provider>
)
}