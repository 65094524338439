export const reportTypeNameDict = {
    "Income Statement": "PL",
    "Income Statement Detail": "PLDetail",
    "Balance Sheet": "BS",
    "Balance Sheet Detail": "BSDetail",
    "Cash Flow": "CF"
  }
  
export const allChecked = (checked) => {
    var allChecked = Object.values(checked).every((val, i, arr) => val === true);
    return allChecked;
  };

export const indeterminate = (checked) => {
    var allEqual = Object.values(checked).every((val, i, arr) => val === arr[0]);
    return !allEqual;
  };