import React, { useContext } from "react";
import { AuthContext } from "../../../../../contexts/AuthContext";
import Excel_logo from "../static/excel-logo.svg";
import { MilestoneEmailContext } from "../../../../../contexts/TicketingSystem/MilestoneEmailContext";
import { PayrollContext } from "../../../../../contexts/TicketingSystem/PayrollContext";
import { MonthEndCloseContext } from "../../../../../contexts/TicketingSystem/MonthEndCloseContext";
import { MilestoneContext } from "../../../../../contexts/TicketingSystem/MilestoneContext";
import { asanaTaskDateStringToDate } from "../../../../../utils/DateFunctions";
import { MilestoneLinksContext } from "../../../../../contexts/TicketingSystem/MilestoneLinksContext";
import { getMonthShort } from "../../../../../utils/DateFunctions";
import { useLinksList } from "../../../Components/Links/LinksTableComponent/Hooks";

const MonthEndPreviewMainTitleView = () => {
  const { selectedCompany } = useContext(AuthContext);
  const { monthEndPeriod } = useContext(MonthEndCloseContext)
  const link = useLinksList(1);

  return (
    <>
      <div className="SubTask__EmailPreview__Body__MainTitle__MainContent">
        <div className="SubTask__EmailPreview__Body__MainTitle__MainContent__Title">
          <span>{selectedCompany?.companyName}</span>
          <button>
            <img src={Excel_logo} alt="Excel" />
            <span>
              <a href={link.data?.[0]?.sharepoint_file_info?.web_url} rel="noreferrer" target="_blank" style={{ color: "#fff" }}>
              {link.data?.[0]?.sharepoint_file_info?.web_url ? "View Draft Month End Financials" : "Missing Link!"}
              </a>{" "}
            </span>
          </button>
        </div>
        <div className="SubTask__EmailPreview__Body__MainTitle__MainContent__customFields">{monthEndPeriod}</div>
      </div>
    </>
  );
};

export default MonthEndPreviewMainTitleView;
