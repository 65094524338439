import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useSelectedMonthEndCloseMilestone } from "../../../hooks/useSelectedMilestone";
import { deleteTicketingSystemCellComment, getTicketingSystemCellCommentsList } from "../../../../../APIFunctions/ticketing_system";
import { deleteAsanaTask, updateAsanaTask } from "../../../../../APIFunctions/Asana";

export const useCellComments = (reportPage) => {
  const selectedMilestone = useSelectedMonthEndCloseMilestone();
  const CommentsQuery = useQuery({
    queryKey: ["cellComments",reportPage, selectedMilestone.data?.data?.gid],
    queryFn: () => getTicketingSystemCellCommentsList(selectedMilestone.data?.data?.gid),
    staleTime: 2 * 60 * 1000,
    enabled: !!selectedMilestone.data?.data?.gid,
  });

  return CommentsQuery;
};

export const useDeleteComment = (comment,reportPage) => {
  const queryClient = useQueryClient();

  const deleteMonthEndCellCommentMutation = useMutation({
    mutationFn: () => deleteTicketingSystemCellComment(comment),
    onSuccess: (data) => {
      queryClient.invalidateQueries(["cellComments",reportPage]);
    },
  });

  const deleteAsanaTaskMutation = useMutation({
    mutationFn: () => deleteAsanaTask(comment.asana_task_id),
  });

  return [deleteMonthEndCellCommentMutation, deleteAsanaTaskMutation];
};

export const useResolveComment = (comment,reportPage) => {
  const queryClient = useQueryClient();
  const cellComments = useCellComments(reportPage);
  const selectedMilestone = useSelectedMonthEndCloseMilestone();
  const completeAsanaTaskMutation = useMutation({
    mutationFn: (completed: boolean) => updateAsanaTask({ gid: comment.asana_task_id, completed: completed }),
    onSuccess: (data) => {
      // queryClient.invalidateQueries(["cellComments"]);
      queryClient.setQueryData(["cellComments", reportPage, selectedMilestone.data?.data?.gid], () => {
        const updatedCommentsArray = cellComments.data?.map((x) =>
          x.id === comment.id ? { ...comment, asana_task: { ...comment.asana_task, completed: data["data"]?.completed } } : x
        );
        return updatedCommentsArray;
      });
    },
  });

  return completeAsanaTaskMutation;
};


export const useEditComment = (commentID, editComment) => {
  const queryClient = useQueryClient();
  const selectedMilestone = useSelectedMonthEndCloseMilestone();
  const editCommentMutation = useMutation({
    mutationFn: ({ asanaTaskID, updateObj }: any) => editComment(commentID, asanaTaskID, updateObj),
    onSuccess: (data) => {
      queryClient.invalidateQueries(["cellComments",selectedMilestone.data?.data?.gid]);
    },
  });

  return editCommentMutation;
}
