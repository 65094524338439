import { createContext, useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { dateObjToAsanaString } from "../../../../../utils/DateFunctions";
import { GridCellParams } from "@mui/x-data-grid-pro";
import { DataContext } from "./DataContext";

export interface DataGridContextProps {
  drillFlag: boolean;
  setDrillFlag: (drillFlag: boolean) => void;
  drilledCellParams: any;
  setDrilledCellParams: (drilledCellParams: any) => void;
}

export const DataGridContext = createContext<DataGridContextProps>(null as any);

export const DataGridContextProvider = (props) => {
  
  const [drillFlag, setDrillFlag] = useState<boolean>(false);
  const [drilledCellParams, setDrilledCellParams] = useState<any>(null);

  return (
    <DataGridContext.Provider
      value={{
        drillFlag,
        setDrillFlag,
        drilledCellParams,
        setDrilledCellParams,
      }}
    >
      {props.children}
    </DataGridContext.Provider>
  );
};
