import { createContext, useContext, useEffect, useState } from "react";
import { deleteAsanaTask, updateAsanaTask } from "../../APIFunctions/Asana";
import { TaskServicesContext } from "./TaskServicesContext";
import { TaskTableContext } from "./TaskTableContext";
import { AuthContext } from "../AuthContext";
import { useNavigate } from "react-router-dom";

export interface TaskCardContextProps {

    onDateChange: (date: Date, itemKey: string, subTask?: any) => void;
    setTaskUpdateObj: (obj: any) => void;
    taskUpdateObj: any;
    setSubTaskUpdateObj: (obj: any) => void;
    subTaskUpdateObj: any;
    onCustomFieldChange: (optionGID: string, fieldIndex: number) => void;
    taskPriority: string;
    taskStatus: string;
    setTaskPriority: (taskPriority: string) => void;
    setTaskStatus: (taskPriority: string) => void;
    activeSubTask: string;
    setActiveSubTask: (activeSubTask: string) => void;
    openParent: (gid: string) => void;
    deleteSubTask: (gid) => void;
    onClearAssignee: () => void;
    onClearDueDate: () => void;
    openSubTask: (gid: any) => void;
    sectionsOptions: any[]
    taskCardLoading: boolean;
    getSectionsOptions: () => void;
    parentSubTasks: any[];
}

export const TaskCardContext = createContext<TaskCardContextProps>(null as any);

export const TaskCardContextProvider = (props) => {
    const navigate = useNavigate();
    const {selectedCompany} = useContext(AuthContext)
    const { taskDetail, setTaskDetail,setOpenTaskDetail, setTimeAtLastTaskUpdate, setParents, parents,
        setSubTasks, subTasks, setTaskCardLoading, taskCardLoading, retrieveSubTasks } = useContext(TaskTableContext)
    const { sectionsQuery, tasksCategory } = useContext(TaskServicesContext)
    const [sectionsOptions, setSectionsOptions] = useState<any[]>([])
    const [activeSubTask, setActiveSubTask] = useState<string>("")
    const [taskUpdateObj, setTaskUpdateObj] = useState({})
    const [subTaskUpdateObj, setSubTaskUpdateObj] = useState({})
    const [taskPriority, setTaskPriority] = useState<string>("")
    const [taskStatus, setTaskStatus] = useState<string>("")
    const [parentSubTasks, setParentSubTasks] = useState<any[]>([])

    const getSectionsOptions = () => {
        if (sectionsQuery.data) {
            const options = sectionsQuery.data?.data.map(x => ({ "value": x.gid, "label": x.name }))
            setSectionsOptions(options)
        } else {
            setSectionsOptions([])
        }
    }

    const automaticallyUpdateSubTask = () => {
        if (subTaskUpdateObj.hasOwnProperty('gid')) {
            updateAsanaTask(subTaskUpdateObj)
            setSubTaskUpdateObj({})
            setTimeAtLastTaskUpdate(new Date().getTime())
        }
    }

    const onClearAssignee = (gid?) => {
        if (gid) {

        } else {
            const newItem = { ...taskDetail };
            newItem["assignee"] = { "gid": "", "name": "" }
            setTaskDetail(newItem)
            if (taskUpdateObj.hasOwnProperty('gid')) {
                setTaskUpdateObj({ ...taskUpdateObj, gid: taskDetail.gid, "assignee": null })

            } else {
                setTaskUpdateObj({ gid: taskDetail.gid, "assignee": null })
            }
            setTimeAtLastTaskUpdate(new Date().getTime())
        }
    }

    const onClearDueDate = () => {
        const newItem = { ...taskDetail };
        newItem["due_on"] = null
        setTaskDetail(newItem)
        if (taskUpdateObj.hasOwnProperty('gid')) {
            setTaskUpdateObj({ ...taskUpdateObj, gid: taskDetail.gid, "due_on": null })

        } else {
            setTaskUpdateObj({ gid: taskDetail.gid, "due_on": null })
        }
        setTimeAtLastTaskUpdate(new Date().getTime())
    }

    const onDateChange = (date: Date, itemKey: string, subTask?) => {
        var newItem;
        if (subTask) {
            newItem = { ...subTask };
        } else {
            newItem = { ...taskDetail };
        }

        date.setTime(date.getTime() - new Date().getTimezoneOffset() * 60 * 1000);
        newItem[itemKey] = date.toISOString().substring(0, 10)

        if (subTask) {
            const subTasksArray = subTasks.slice().map(x => (x.gid === newItem?.gid) ? newItem : x)
            setSubTasks(subTasksArray)
            if (setSubTaskUpdateObj.hasOwnProperty('gid')) {
                setSubTaskUpdateObj({
                    ...subTaskUpdateObj,
                    gid: newItem.gid,
                    due_on: newItem.due_on
                })
            } else {
                setSubTaskUpdateObj({
                    gid: newItem.gid,
                    due_on: newItem.due_on
                })
            }
        } else {
            setTaskDetail(newItem)
            if (taskUpdateObj.hasOwnProperty('gid')) {
                setTaskUpdateObj({
                    ...taskUpdateObj,
                    gid: newItem.gid,
                    due_on: newItem.due_on
                })
            } else {
                setTaskUpdateObj({
                    gid: newItem.gid,
                    due_on: newItem.due_on
                })
            }
        }
    }

    const deleteSubTask = async (gid) => {
        setTaskCardLoading(true)
        await deleteAsanaTask(gid)
        await retrieveSubTasks()
        setTaskCardLoading(false)
    }

    const openParent = (gid) => {
        var newParentsArray;
        const targetParentIndex = parents.findIndex(x => x.gid === gid)
        if (targetParentIndex !== -1) {
            setTaskDetail(parents[targetParentIndex])
        }
        if (targetParentIndex - 1 >= 0) {
            newParentsArray = parents.slice(0, targetParentIndex)
        } else {
            newParentsArray = []
        }
        setParents(newParentsArray)
    }

    const openSubTask = (gid) => {
        const newParentsArray: any = parents.slice()
        newParentsArray.push(taskDetail)
        setParents(newParentsArray)
        // store the subtasks in a state for admin processes awareness
        if (newParentsArray.length === 1 && tasksCategory !== "user") {
            setParentSubTasks(subTasks)
        }
        const taskIndex = subTasks.findIndex(x => x.gid === gid)
        
        const url = `/${selectedCompany?.getURL()}/tasks/project/user/${gid}`
        navigate(url)
        setTaskDetail(subTasks[taskIndex])
        setOpenTaskDetail(true);
    }

    const onCustomFieldChange = (value, fieldIndex) => {
        const custom_fields = { [taskDetail.custom_fields[fieldIndex].gid]: value }
        if (fieldIndex === 0) {
            setTaskStatus(value)
        } else {
            setTaskPriority(value)
        }

        
        const customFields = taskDetail.custom_fields
        
        const enum_value = taskDetail.custom_fields[fieldIndex].enum_options.find(x => x.gid === value)
        if (enum_value) {
        const customField = {...taskDetail.custom_fields[fieldIndex], enum_value: enum_value}
        customFields[fieldIndex] = customField
            setTaskDetail({...taskDetail,custom_fields: customFields} )
        }
        if (value === "") {
            const customField = {...taskDetail.custom_fields[fieldIndex], enum_value: {gid:"",name:"-"}}
            customFields[fieldIndex] = customField
                setTaskDetail({...taskDetail,custom_fields: customFields} )
            }
        updateAsanaTask({ gid: taskDetail.gid, custom_fields })
        setTimeAtLastTaskUpdate(new Date().getTime())
    }

    useEffect(() => {
        const timerId = setTimeout(() => {
            automaticallyUpdateSubTask()
        }, 300);
        return () => {
            clearTimeout(timerId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subTaskUpdateObj]);

    useEffect(() => {
        if (subTaskUpdateObj.hasOwnProperty('gid')) {
            updateAsanaTask(subTaskUpdateObj)
            setTimeAtLastTaskUpdate(new Date().getTime())
            setSubTaskUpdateObj({})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSubTask])

    useEffect(() => {
        const timerId = setTimeout(() => {
            if (taskUpdateObj.hasOwnProperty('gid')) {
                
                updateAsanaTask(taskUpdateObj)
                setTimeAtLastTaskUpdate(new Date().getTime())
                setTaskUpdateObj({})
            }
        }, 4000);
        return () => {
            clearTimeout(timerId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskUpdateObj]);




    return (
        <TaskCardContext.Provider value={{
            openSubTask, parentSubTasks,
            taskUpdateObj, setTaskUpdateObj,
            subTaskUpdateObj, setSubTaskUpdateObj,
            deleteSubTask,
            onDateChange,
            onClearAssignee,
            onCustomFieldChange,
            taskPriority, taskStatus,
            setTaskPriority, setTaskStatus, openParent, activeSubTask, setActiveSubTask, taskCardLoading,
            sectionsOptions, getSectionsOptions,onClearDueDate

        }}>
            {props.children}
        </TaskCardContext.Provider>
    )

}