import { CircularProgress } from '@mui/material'
import React from 'react'

const SharepointLoadingOverlay = () => {
  return (
    <div className="SharepointLoadingOverlay__Container">
      <CircularProgress size={150} sx={{ color: "#85b440" }} />
      <span>Retrieving Sharepoint folders and files ...</span>
    </div>
  )
}

export default SharepointLoadingOverlay