import React, { useCallback, useContext, useEffect, useState } from "react";
import { updatePayrollEmailParams, updatePayrollFileLink } from "../../../APIFunctions/ticketing_system";
import { AuthContext } from "../../../contexts/AuthContext";
import { MilestoneContext } from "../../../contexts/TicketingSystem/MilestoneContext";
import EmailInput from "./EmailInput";
import { MilestoneEmailContext } from "../../../contexts/TicketingSystem/MilestoneEmailContext";
import { getSharePointFilePermissions } from "../../../APIFunctions/graph_api";
import { MilestoneLinksContext } from "../../../contexts/TicketingSystem/MilestoneLinksContext";
import { getAccountSettingsUsers } from "../../../APIFunctions/accountSettings";
import { TaskServicesContext } from "../../../contexts/Task/TaskServicesContext";
import { useEditLink } from "../Components/Links/LinksTableComponent/Hooks";

const EmailContactsInput = ({ emailArray, setEmailArray, email_type }) => {
  const { selectedCompany } = useContext(AuthContext);
  const { team } = useContext(TaskServicesContext);
  const { milestoneType, setPayrollLoading } = useContext(MilestoneContext);
  const { sharepointLinkInEmail, setSharepointLinkInEmail } = useContext(MilestoneEmailContext);

  const [allowedEmailsSharepoint, setAllowedEmailsSharepoint] = useState<string[]>([]);
  const [allowedEmailsApp, setAllowedEmailsApp] = useState<any[]>([]);
  const [allowedEmailsAsana, setAllowedEmailsAsana] = useState<any[]>([]);

  const linkEditMutation = useEditLink(sharepointLinkInEmail?.id);

  const saveEmailArray = useCallback(async () => {
    if (selectedCompany && emailArray?.length > 0) {
      const saveArray = emailArray.join(",");
      let fieldName = `email_${email_type}`;
      if (email_type === "email_first_line") {
        fieldName = "email_first_line";
      }
      
      await updatePayrollEmailParams(selectedCompany.id, milestoneType, {
        [fieldName]: saveArray,
      });
    }
  }, [selectedCompany, emailArray, email_type, milestoneType]);


  const getAllowedEmailsAsana = useCallback(async () => {
    const emails = team.map((x) => x.email);
    setAllowedEmailsAsana(emails);
  }, [team]);

  const getAllowedEmailsApp = useCallback(async () => {
    if (selectedCompany) {
      const user_list = await getAccountSettingsUsers(selectedCompany);
      const emails = user_list.map((x) => x.user.email);
      setAllowedEmailsApp(emails);
    }
  }, [selectedCompany]);

/**
   * Gets permissions and link for a SharePoint file
   * @param {void} - No arguments.
   * @returns {void} - No return value.
   * @description
   - Fetches permissions and link for a SharePoint file given a company and file ID.
   - Updates state with permissions and link. 
   - Calls mutation to update payroll file link if not a milestone.
   - Handles errors if file doesn't exist.
   */
  const getAllowedEmailsListByUrl = useCallback(async () => {
    
    setPayrollLoading(true);
    const sharepointId = sharepointLinkInEmail?.sharepoint_file_info?.sharepoint_id;

    if (selectedCompany && sharepointLinkInEmail && sharepointId) {
      const response = await getSharePointFilePermissions(selectedCompany, sharepointId);
      
      const permissions = response["permissions"];
      const link = response["link"];

      if (permissions) {
        setAllowedEmailsSharepoint(permissions);
        if (link !== "") {
          const sharepointFileInfo = {...sharepointLinkInEmail["sharepoint_file_info"], web_url: link};
          if (milestoneType !== 0) {
            linkEditMutation.mutate(sharepointFileInfo);
          } else if(sharepointLinkInEmail?.id) {
            
            await updatePayrollFileLink(sharepointLinkInEmail.id, { link });
          }
          setSharepointLinkInEmail({ ...sharepointLinkInEmail, sharepoint_file_info: sharepointFileInfo });
        }
      } else if ("error" in response) {
        const errorMessage = `${sharepointLinkInEmail?.sharepoint_file_info?.web_url} doesn't exist. Please set a different link.`;
        setAllowedEmailsSharepoint(["error", errorMessage]);
      }
    }
    setPayrollLoading(false);
  }, [setPayrollLoading, selectedCompany, sharepointLinkInEmail, milestoneType, setSharepointLinkInEmail, linkEditMutation]);


  useEffect(() => {
    saveEmailArray();
    if (email_type !== "from") {
      getAllowedEmailsListByUrl();
      getAllowedEmailsApp();
      getAllowedEmailsAsana();
    }
  }, [selectedCompany, emailArray, milestoneType]);

  return (
    <>
      <EmailInput
        tags={emailArray}
        setTags={setEmailArray}
        array={email_type === "from" ? false : true}
        allowedEmailsSharepoint={allowedEmailsSharepoint}
        setAllowedEmailsSharepoint={setAllowedEmailsSharepoint}
        allowedEmailsApp={allowedEmailsApp}
        allowedEmailsAsana={allowedEmailsAsana}
        placeholder="Press enter to add emails..."
      />
    </>
  );
};

export default EmailContactsInput;
