import React from 'react'
import TransactionLineTable from './TransactionLineTable'

const DepositTransaction = ({transaction}) => {
  return (
    <div>
      <div className="TransactionDetail__GroupContainer">
        <div className="TransactionDetail__ParameterContainer">
          <span>Deposit date</span>
          <div>{transaction?.["detail"]?.["Deposit"]?.["TxnDate"]}</div>
        </div>
        </div>
        <TransactionLineTable lines={transaction?.["detail"]?.["Deposit"]?.["Line"]} transactionID={transaction?.["detail"]?.["Deposit"]?.["Id"]} transaction_type="Deposit"/>
        <div className="TransactionDetail__Subtotal">
          <span>Total Amount</span>
          <div>${transaction?.["detail"]?.["Deposit"]?.["TotalAmt"]}</div>
        </div>
    </div>
  )
}

export default DepositTransaction