import { createDateWithOffset, createDateWithOffsetFromDate, FirstDayCurrentYear } from '../utils/DateFunctions'
import { QuickBookConnectingStatus } from './Company';

export class onboardingCompanies {
    id: number = -1;
    companyName: string = "";
}

export const defaultHeadcountDepartments:string[] = ["G&A", "Sales","Marketing","Engineering"]
export const defaultHeadcountLocations:string[] = ["USA", "Overseas"]
export const defaultPLGroupings:string[] = ["Other G&A", "Professional fees", "Payroll", "Sales & Marketing", "Software & Tech"]

export class Onboarding {
    id: number = -1;
    companyName: string = "";
    offset = { value: "0", label: "January" };
    company_avatar: number = -1;
    // start_date: Date= FirstDayCurrentYear();
    // end_date: Date= createDateWithOffsetFromDate(FirstDayCurrentYear(),0,11,3)
    // last_actuals_date: Date= createDateWithOffset(0,-2,0)
    start_date: string="2022-01-01";
    end_date: string="2022-12-31";
    last_actuals_date: string="2022-11-30";
    headcount_departments: string [] = defaultHeadcountDepartments;
    headcount_locations: string [] = defaultHeadcountLocations;
    pl_groupings: string [] = defaultPLGroupings;
    data_type: number = 0;
    qbo_classes_enabled: boolean = false;
    onboarding_step: number = 0;
    onboarding_completed: boolean = false;
    qb_status: QuickBookConnectingStatus = QuickBookConnectingStatus.NOT_CONNECTED;

    constructor(obj: Onboarding | null = null) {
        if (obj) {
            Object.keys(obj).forEach(key => {
                if (key in this)
                    this[key] = obj[key]
            })
        }
    }

    public static toAPIRepresentation = (object: Onboarding): any => {

        let obj: any = {}
        Object.keys(object).forEach((key) => {
            obj[key] = object[key]
        })
        obj.offset = object.offset.value
        return obj;

    }
}