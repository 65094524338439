import APIManger from "../APIManger";
import { Company } from "../types/Company";
import { ADD_SHAREPOINT_FILE_PERMISSION, ADD_SHAREPOINT_FOLDER, DELETE_SHAREPOINT_FILE_PERMISSION, LIST_SHAREPOINT_CHILDREN, LIST_SHAREPOINT_FILE_PERMISSIONS, RENAME_SHAREPOINT_ITEM, SHAREPOINTFILEINFO, SYNC_EXCEL_FILE } from "./apiConstants";

export const syncExcelFileAPI = async (company: Company): Promise<boolean> => {
  let url = SYNC_EXCEL_FILE.replace("%COMPANY_ID%", company?.id?.toString());
  const apiResult: any = await APIManger.post(url);
  if (apiResult.detail === "Success") return true;
  else return false;
};

export const listSharePointChildren = async (company: Company, folder_id: any): Promise<boolean> => {
  const PARAMS = `?folder_id=${folder_id}&company_id=${company?.id}&site_id=0`;
  let url = LIST_SHAREPOINT_CHILDREN + PARAMS;
  const apiResult: any = await APIManger.get(url);
  return apiResult;
};

export const addSharePointFolder = async (company: Company, parent_folder_id: string,new_folder_name:string): Promise<boolean> => {
  const data = {
    new_folder_name,
    parent_folder_id,
    company_id: company?.id,
    site_id:0
  }
  let url = ADD_SHAREPOINT_FOLDER;
  const apiResult: any = await APIManger.post(url, data);
  return apiResult;
};

export const renameSharePointItem = async (company: Company, item_id: string,new_name:string): Promise<boolean> => {
  const data = {
    new_name,
    item_id,
    company_id: company?.id,
    site_id:0
  }
  let url = RENAME_SHAREPOINT_ITEM;
  const apiResult: any = await APIManger.post(url, data);
  return apiResult;
};





export const getSharePointFolder = async (company: Company, folder_id: any): Promise<boolean> => {
  let url = LIST_SHAREPOINT_CHILDREN.replace("%COMPANY_ID%", company?.id?.toString()).replace("%FOLDER_ID%", folder_id);
  const apiResult: any = await APIManger.post(url);
  return apiResult;
};

export const getSharePointFilePermissions = async (company: Company, file_id: any): Promise<any> => {
  const PARAMS = `?folder_id=${file_id}&company_id=${company?.id}&site_id=0`;
  let url = LIST_SHAREPOINT_FILE_PERMISSIONS + PARAMS;
  
  const apiResult: any = await APIManger.get(url);
  return apiResult;
};

export const addSharePointFilePermissions = async (company: Company, file_id: any, email: string): Promise<any> => {
  const data = {
    file_id,
    email,
    company_id: company?.id,
    site_id:0
  }
  let url = ADD_SHAREPOINT_FILE_PERMISSION;
  const apiResult: any = await APIManger.post(url, data);
  return apiResult;
};

export const deleteSharePointFilePermissions = async (company: Company, file_id: any, email: string): Promise<any> => {
  let url = DELETE_SHAREPOINT_FILE_PERMISSION.replace("%COMPANY_ID%", company?.id?.toString()).replace("%FILE_ID%", file_id).replace("%EMAIL%", email);
  const apiResult: any = await APIManger.post(url);
  return apiResult;
};

export const editSharePointFileInfo = async (sharepoint_file_id: string, updateObj:any): Promise<any> => {
  let url = SHAREPOINTFILEINFO.replace("%SHAREPOINTFILE_ID%", sharepoint_file_id)
  const apiResult: any = await APIManger.patch(url, updateObj);
  return apiResult;
};

export const deleteSharePointFileInfo = async (sharepoint_file_id: string): Promise<any> => {
  let url = SHAREPOINTFILEINFO.replace("%SHAREPOINTFILE_ID%", sharepoint_file_id)
  const apiResult: any = await APIManger.delete(url);
  return apiResult;
};



