import { useContext } from "react";
import { OnboardingContext } from "../../contexts/Onboarding/OnboardingContext";
import { months } from "../../utils/DateFunctions";
import AlertDialog from "../Common/AlertDialog";
import MDIcons from "../Common/MDIcons";
import { ReactComponent as QBFailure } from '../../static/img/QBO_failure.svg';
import { SocketContext } from "../../contexts/SocketContext";
import { QuickBookConnectingStatus } from "../../types/Company";
import { uppercaseFirstLetter } from "../../utils/helpers";
import { RadioSelection } from "./FormPage5";

export default function SummaryDialog({ summaryIsOpen, closeSummaryDialog }) {

    const { onboardingInputs, onBoardingCompleted, setOnboardingCompleted, PLFile, BSFile, image, applySaveStep } = useContext(OnboardingContext);
    const { qb_ConnectingStatus  } = useContext(SocketContext);

    const start_date_month = new Date(onboardingInputs["start_date"]).getMonth();
    const start_date_year = new Date(onboardingInputs["start_date"]).getFullYear();
    const end_date_month = new Date(onboardingInputs["end_date"]).getMonth();
    const end_date_year = new Date(onboardingInputs["end_date"]).getFullYear();
    const actuals_date_month = new Date(onboardingInputs["last_actuals_date"]).getMonth();
    const actuals_date_year = new Date(onboardingInputs["last_actuals_date"]).getFullYear();

    const onDialogClose = () => {
        closeSummaryDialog();
    }
    return (
        <AlertDialog
            dialogType="user-form"
            open={summaryIsOpen}
            onClose={onDialogClose}
            title={<span>Review your answers</span>}
            dialogActions={<>
                    {!onBoardingCompleted && <>
                        <button className="btn--tertiary btn--M"
                            onClick={onDialogClose}>
                            <span>Cancel</span>
                        </button>
                        <button
                            className={"btn--primary btn--M"}
                            onClick={() => {
                                onDialogClose()
                                setOnboardingCompleted(true)
                                applySaveStep(true,"send email")
                            }}
                            autoFocus
                        >
                            <span>Complete Onboarding</span>
                        </button>
                    </>}
            </>
            }
        >
            <div className="Summary__ParentContainer">

                <div className={"Summary__SectionContainer"}>
                    <div className="Summary__SectionContainer__Title">
                        Company Info
                    </div>

                    <div className="Summary__SectionContainer__Body__Col1">
                        <div className="Summary__SectionContainer__Body__Col__Block">
                            <label>Name</label><br/>
                            <span>{onboardingInputs["companyName"]}</span>
                        </div>
                    </div>
                    {!onBoardingCompleted && 
                    <div className="Summary__SectionContainer__Body__Col2">
                        <div className="Summary__SectionContainer__Body__Col__Block">
                            <label>Logo</label>
                            <img src={image} alt=""/>
                        </div>
                    </div>}
                </div>
                <div className={"Summary__SectionContainer"}>
                    <div className="Summary__SectionContainer__Title">
                        Financial Model
                    </div>
                    <div className="Summary__SectionContainer__Body__Col1">
                        <div className="Summary__SectionContainer__Body__Col__Block">
                            <label>Start Date</label><br/>
                            <span>{uppercaseFirstLetter(months[start_date_month]) + ' ' + start_date_year}</span>
                        </div>
                        <div className="Summary__SectionContainer__Body__Col__Block">
                            <label>Last Date</label><br/>
                            <span>{uppercaseFirstLetter(months[actuals_date_month]) + ' ' + actuals_date_year}</span>
                        </div>
                    </div>
                    <div className="Summary__SectionContainer__Body__Col2">
                        <div className="Summary__SectionContainer__Body__Col__Block">
                            <label>End Date</label><br/>
                            <span>{uppercaseFirstLetter(months[end_date_month]) + ' ' + end_date_year}</span>
                        </div>
                        <div className="Summary__SectionContainer__Body__Col__Block">
                            <label>Start of Fiscal Year</label><br/>
                            <span>{onboardingInputs.offset.label}</span>
                        </div>
                    </div>

                </div>

                <div className={"Summary__SectionContainer"}>
                    <div className="Summary__SectionContainer__Title">
                        Departments & Locations
                    </div>

                    <div className="Summary__SectionContainer__Body__Col1">
                        <div className="Summary__SectionContainer__Body__Col__Block">
                            <label>Departments</label><br/>
                            {Array.isArray(onboardingInputs["headcount_departments"]) && 
                                onboardingInputs["headcount_departments"].map(dept => {
                                    return (<span key={dept}>
                                        {dept}
                                    </span>)
                                })
                            }
                            
                        </div>
                    </div>
                    <div className="Summary__SectionContainer__Body__Col2">
                        <div className="Summary__SectionContainer__Body__Col__Block">
                            <label>Locations</label><br/>
                            {Array.isArray(onboardingInputs["headcount_locations"]) && 
                            onboardingInputs["headcount_locations"].map(loc => {
                                return (<span key={loc}>
                                    {loc}
                                </span>)
                            })
                            }
                        </div>
                    </div>

                </div>

                <div className={"Summary__SectionContainer"}>
                    <div className="Summary__SectionContainer__Title">
                        Income Statements (P&L) Groupings
                    </div>

                    <div className="Summary__SectionContainer__Body__Col1">
                    <div className="Summary__SectionContainer__Body__Col__Block__ExtendedRow">
                            <label>(P&L) Groupings</label><br/>
                            {Array.isArray(onboardingInputs["pl_groupings"]) && 
                            onboardingInputs["pl_groupings"].map(groupings => {
                                return (<span key={groupings}>
                                    {groupings}
                                </span>)
                            })
                            }
                        </div>
                    </div>

                </div>


                {!onBoardingCompleted && <div className={"Summary__SectionContainer"}>
                    <div className="Summary__SectionContainer__Title">
                        Data Connection
                    </div>

                    <div className="Summary__SectionContainer__Body__Col1">
                        {onboardingInputs["data_type"] === RadioSelection.QBO  && qb_ConnectingStatus === QuickBookConnectingStatus.SUCCESSED ? 
                        <><MDIcons title={"check_mark"} />
                        <span className="Summary__SectionContainer__Body__Col1__SuccssText" >Your account is connected!</span>
                        </> : 
                        (PLFile || BSFile) &&  onboardingInputs["data_type"] === RadioSelection.FileUpload ?
                        <>
                        {PLFile && <div className="Summary__SectionContainer__Body__Col__Block__ExtendedRow">
                        <MDIcons title={"check_mark"} />
                        <span className="Summary__SectionContainer__Body__Col1__SuccssText" >{PLFile?.name}</span>
                        </div>}
                        {BSFile && <div className="Summary__SectionContainer__Body__Col__Block__ExtendedRow">
                        <MDIcons title={"check_mark"} />
                        <span className="Summary__SectionContainer__Body__Col1__SuccssText" >{BSFile?.name}</span>
                        </div>}
                        </>

                        :
                        <>
                        <MDIcons title={"x_mark"} />
                        <span className="Summary__SectionContainer__Body__Col1__FailureText">you provided no data!</span>
                        </>
                        }
                    </div>
                </div>}

            </div>
        </AlertDialog >
    )
}