import React, { useContext, useRef, useState } from 'react'
import { Cropper } from 'react-cropper';
import "cropperjs/dist/cropper.css";
import { AuthContext } from '../../contexts/AuthContext';
import { OnboardingContext } from '../../contexts/Onboarding/OnboardingContext';
import defaultCompany from '../../static/img/default_company.png';
import AlertDialog from '../Common/AlertDialog';
import ImageUploader from '../Common/ImageUploader';
import MDIcons from '../Common/MDIcons';

const ImageCropper: React.FC = () => {
  const { image, setImage, originalImage, isCropDialogOpen, setIsCropDialogOpen } = useContext(OnboardingContext);
  const cropperRef = useRef<HTMLImageElement>(null);
  const [preview, setPreview] = useState("");

  const onCrop = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    setPreview(cropper.getCroppedCanvas().toDataURL());
  };

  

  return (image ?
    <>
      <AlertDialog
        dialogType="user-form"
        open={isCropDialogOpen}
        onClose={() => setIsCropDialogOpen(false)}
        title={<span className="BulkAddEmployee__Title"> <h3 style={{ fontWeight: 700 }}>{"Crop Logo Image"}</h3> </span>}
        dialogActions={
          <>
            <div className='action_btns_container'>
              <button className={"btn--tertiary btn--M"} onClick={() => setImage(originalImage)}>Reset Crop</button>
              <button className="btn--primary btn--M" onClick={() => {setImage(preview); setIsCropDialogOpen(false);}}>Set Crop</button>
            </div>
          </>}
      >
        <div className="image-cropper-container">
          <Cropper
            src={image}
            className="Cropper-Component"
            // Cropper.js options
            aspectRatio={1}
            guides={false}
            crop={onCrop}
            ref={cropperRef}
          />


        </div>
      </AlertDialog>

    </>
    :
    <></>
  );
};


const FormPage1 = ({ o= false }) => {
  const { selectedCompany } = useContext(AuthContext)

  const { image, setImage, originalImage, setOriginalImage,isCropDialogOpen, setIsCropDialogOpen,onSubmit, onboardingInputs, onOnboardingInputsChange, setImageFile,done  } = useContext(OnboardingContext);

  return (
    <>
      <h1>Tell us more about your company</h1>
      <div className="bodyContainer">
        <div className="bodyContainer__InputSection">
          <div className='onboarding__block--y'>
            <h2>Logo</h2>
            <div className='companyLogoEdit__Container'>
              {isCropDialogOpen && <ImageCropper />}

              {selectedCompany?.companyAvatarURL && !image ?
                <img src={selectedCompany.companyAvatarURL} alt="" />
                :
                image ?
                  <img src={image} alt='' />
                  : <img src={defaultCompany} alt="" />}
              <div className='companyLogoEdit__Container__ButtonContainer'>

                <ImageUploader setImage={setImage} setOriginalImage={setOriginalImage} setIsCropDialogOpen={setIsCropDialogOpen} setImageFile={setImageFile} />
                <p>Support Up to 2 MB, PNG, JPG</p>
                {image && <button className={"btn--secondary btn--S"} onClick={() => setIsCropDialogOpen(true)}><MDIcons title="crop"/>Re-crop</button>}
              </div>
            </div>
          </div>
          <div className='onboarding__block--y'>
            <label htmlFor="company name">Company Name</label>
            <input type="text" value={onboardingInputs["companyName"]} title="company-name" onChange={(e) =>
              onOnboardingInputsChange(e, "companyName")} />
          </div>
        </div>
        <div className="footer--btns">
          <input
            className='btn--primary btn--fullWidth btn--M '
            type="submit"
            value={done ? "Done" : "Next"}
            onClick={onSubmit}
            disabled={onboardingInputs["companyName"] === ""}
          />
        </div>
      </div>

    </>
  )
}

export default FormPage1;