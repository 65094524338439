import { CompanyModule, AppModules, ModuleType, AppModuleUrl, ModuleConverter } from "./CompanyModules";
import { UserRole, UserStatus } from "./ManageUsers";

export enum CompanyState {
    NORMAL = "NORMAL",
    ONBOARDING = "ONBOARDING",
}
export enum QuickBookConnectingStatus {
    NOT_CONNECTED = 0,
    CONSENTING = 1,
    FAILED = 2,
    SUCCESSED = 3
}
export enum CalculationType {
    PerPercentage = 0,
    PerEmployeeNumber = 1
}
export interface FinancialStatmentSetting {
    enabled: string;
    folder_path: string;
    site_url: string;
}
export interface HeadCountSetting {
    fiscal_offset: number;
    assum_pay_tax: number;
    assum_pay_tax_option: CalculationType;
    assum_health_ben: number;
    assum_health_ben_option: CalculationType;
    assum_pay_proc: number;
    assum_pay_proc_option: CalculationType;
}

export interface CompanySetting {
    hc_setting: HeadCountSetting;
    fs_setting: FinancialStatmentSetting;
}

export class Company {
    id: number = -1;
    companyName: string = "";
    offset: number = 0;
    company_modules: CompanyModule[] = [];
    companyAvatarURL?: string;
    companyPaymentPlanURL?: string;
    printing_documentId: string = "";
    settings?: CompanySetting;
    embeded_views_access: boolean = true;
    headcount_access: boolean = false;
    status: UserStatus = UserStatus.DEACTIVE;
    role: UserRole = UserRole.User;
    is_Onboarding: boolean = false;
    asana_project_id: string = "";
    asana_team_id: string = "";
    start_date: string = "";
    last_date_of_actuals: string = "";
    end_date:string="";
    is_connected = false;

    accountingModules = [AppModules.PAYROLL,AppModules.MONTH_END_CLOSE, AppModules.EXPORT_FINANCIALS]
    othersModules = [AppModules.TASKS]

    constructor(obj: Partial<Company> | null = null) {
        if (obj) {
            Object.keys(obj).forEach(key => {
                if (key in this)
                    this[key] = obj[key]
            })
        }
        if (this.offset < 0) this.offset *= -1
        while (this.offset > 11) this.offset -= 12
    }

    public get state_name(): string {
        return this.is_Onboarding ? CompanyState.ONBOARDING : CompanyState.NORMAL
    }

    isValidModule(moduleName: AppModules): boolean {
        if (!(moduleName in AppModules))
            return false;
        return true;
    }

    get ordered_company_modules() {
        let ordered_company_modules: CompanyModule[] = []
        
        if (this.company_modules.findIndex(module => module.name_str === "Payroll Admin") !== -1) {
            ordered_company_modules.push(this.company_modules[this.company_modules.findIndex(module => module.name_str === "Payroll Admin")])
        }
        if (this.company_modules.findIndex(module => module.name_str === "Month End Close Admin") !== -1) {
            ordered_company_modules.push(this.company_modules[this.company_modules.findIndex(module => module.name_str === "Month End Close Admin")])
        }
        if (this.company_modules.findIndex(module => module.name_str === "Accounts Payable Admin") !== -1) {
            ordered_company_modules.push(this.company_modules[this.company_modules.findIndex(module => module.name_str === "Accounts Payable Admin")])
        }
        
        
        if (this.company_modules.findIndex(module => module.name_str === "Payroll") !== -1) {
            ordered_company_modules.push(this.company_modules[this.company_modules.findIndex(module => module.name_str === "Payroll")])
        }
        if (this.company_modules.findIndex(module => module.name_str === "Month End Close") !== -1) {
            ordered_company_modules.push(this.company_modules[this.company_modules.findIndex(module => module.name_str === "Month End Close")])
        }
        if (this.company_modules.findIndex(module => module.name_str === "Dashboard") !== -1) {
            ordered_company_modules.push(this.company_modules[this.company_modules.findIndex(module => module.name_str === "Dashboard")])
        }
        if (this.company_modules.findIndex(module => module.name_str === "Summary") !== -1) {
            ordered_company_modules.push(this.company_modules[this.company_modules.findIndex(module => module.name_str === "Summary")])
        }
        if (this.company_modules.findIndex(module => module.name_str === "Financial Statements") !== -1) {
            ordered_company_modules.push(this.company_modules[this.company_modules.findIndex(module => module.name_str === "Financial Statements")])
        }
        if (this.company_modules.findIndex(module => module.name_str === "Headcount") !== -1) {
            ordered_company_modules.push(this.company_modules[this.company_modules.findIndex(module => module.name_str === "Headcount")])
        }
        if (this.company_modules.findIndex(module => module.name_str === "Budget To Actuals") !== -1) {
            ordered_company_modules.push(this.company_modules[this.company_modules.findIndex(module => module.name_str === "Budget To Actuals")])
        }
        if (this.company_modules.findIndex(module => module.name_str === "Pitch Deck") !== -1) {
            ordered_company_modules.push(this.company_modules[this.company_modules.findIndex(module => module.name_str === "Pitch Deck")])
        }
        if (this.company_modules.findIndex(module => module.name_str === "Revenue") !== -1) {
            ordered_company_modules.push(this.company_modules[this.company_modules.findIndex(module => module.name_str === "Revenue")])
        }
        if (this.company_modules.findIndex(module => module.name_str === "Tasks") !== -1) {
            ordered_company_modules.push(this.company_modules[this.company_modules.findIndex(module => module.name_str === "Tasks")])
        }
        if (this.company_modules.findIndex(module => module.name_str === "Export Financials") !== -1) {
            ordered_company_modules.push(this.company_modules[this.company_modules.findIndex(module => module.name_str === "Export Financials")])
        }
        return ordered_company_modules
    }

    getModules(): AppModules[] {
        let app_modules: AppModules[] = [];
        this.ordered_company_modules.forEach(module => {
            app_modules.push(module.name)
        });
        return app_modules;
    }

    getURL():string{
        return this.companyName.replaceAll(" ","-")
    }

    getModuleUrls(): any[] {
        let app_module_urls: AppModuleUrl[] = [];
        this.ordered_company_modules.forEach(module => {
            app_module_urls.push(ModuleConverter.IdToUrl(module.name))
        });
        return app_module_urls;
    }

    getFPAModuleUrls(): AppModuleUrl[] {
        let app_module_urls: AppModuleUrl[] = [];
        this.ordered_company_modules.forEach(module => {
            if (!this.accountingModules.includes(module.name) && !this.othersModules.includes(module.name)) {
                app_module_urls.push(ModuleConverter.IdToUrl(module.name))
            }
            
        });
        return app_module_urls;
    }

    getAccountingModuleUrls(): AppModuleUrl[] {
        let app_module_urls: AppModuleUrl[] = [];
        this.ordered_company_modules.forEach(module => {
            if (this.accountingModules.includes(module.name)) {
                app_module_urls.push(ModuleConverter.IdToUrl(module.name))
            }
            
        });
        return app_module_urls;
    }

    getOthersModuleUrls(): AppModuleUrl[] {
        let app_module_urls: AppModuleUrl[] = [];
        this.ordered_company_modules.forEach(module => {
            if (this.othersModules.includes(module.name)) {
                app_module_urls.push(ModuleConverter.IdToUrl(module.name))
            }
            
        });
        return app_module_urls;
    }

    isEmbedModule(moduleName: AppModules): boolean {
        if (!this.isValidModule(moduleName))
            return false
        if (this.ordered_company_modules.find((item) => item.name === moduleName)?.type !== ModuleType.EMBED_EXCEL)
            return false
        return true;
    }
    getModuleType(moduleName: AppModules): ModuleType {
        if (!this.isValidModule(moduleName))
            return ModuleType.DEFAULT
        var company_module = this.ordered_company_modules.find((item) => item.name === moduleName)
        if (company_module)
            return company_module.type
        else
            return ModuleType.DEFAULT
    }

    isModuleExists(moduleName: AppModules): boolean {
        if (!this.isValidModule(moduleName))
            return false;
        if (!this.ordered_company_modules.find((module) => module.name === moduleName))
            return false;
        return true;
    }

    getFirstAvailableModule(): AppModules {
        if (this.ordered_company_modules && this.ordered_company_modules.length > 0)
            return this.ordered_company_modules[0].name
        else
            return AppModules.DEFAULT
    }
}
