
import APIManger from "../APIManger";
import AsanaAPIManager from "../APIManger/Asana"
import { AsanaProject, AsanaTask } from "../types/AsanaTask";
import { ASANA_TASK_RETRIEVE, ASANA_TASK_RETRIEVE_UPDATE, ASANA_PROJECT_CREATE, ASANA_TASKS_RETRIEVE, ASANA_TASK_CREATE, ASANA_TASK_ADD_PROJECT, ASANA_SECTIONS_RETRIEVE, ASANA_TASK_STORIES_RETRIEVE, 
    ASANA_TASK_SUBTASKS_RETRIEVE, ASANA_TASK_ATTACHMENT_UPLOAD, ASANA_TASK_ATTACHMENTS_RETRIEVE, ASANA_SUBTASK_CREATE, ASANA_TASK_ATTACHMENT_DELETE, ASANA_TASK_ATTACHMENT_RETRIEVE, ASANA_TASK_DELETE,
     ASANA_TASK_COMMENT_CREATE, ASANA_TASK_COMMENT_DELETE
    , ASANA_PROJECT_EVENT_RETRIEVE, ASANA_TEAM_RETRIEVE, ASANA_TASK_STORY_RETRIEVE, 
    ASANA_TASK_ADD_TO_SECTION, ASANA_CUSTOM_FIELDS_RETRIEVE, ASANA_CONNECT, ASANA_DISCONNECT, ASANA_REFRESH, ASANA_RETRIEVE_ACCESS_TOKEN, ASANA_TASKS_IN_SECTION_RETRIEVE,
     ASANA_SEARCH_BY_SECTION_INCOMPLETE_MILESTONES } from "./apiConstants";

export const connectToAsana = async (): Promise<any> => {
    let url = ASANA_CONNECT
    let apiResult: any = await APIManger.post(url)
    return apiResult;
}

export const getAccessToken = async (): Promise<any> => {
    let url = ASANA_RETRIEVE_ACCESS_TOKEN
    let apiResult: any = await APIManger.get(url)
    return apiResult;
} 

export const RefreshAsana = async (): Promise<any> => {
    let url = ASANA_REFRESH
    let apiResult: any = await APIManger.post(url)
    return apiResult;
}

export const disconnectFromAsana = async (): Promise<any> => {
    let url = ASANA_DISCONNECT
    let apiResult: any = await APIManger.post(url)
    return apiResult;
}

export const updateAsanaTask = async (data: any): Promise<AsanaTask> => {
    let url = ASANA_TASK_RETRIEVE_UPDATE.replace("%TASK_GID%", data?.gid)
    let apiResult: AsanaTask = await AsanaAPIManager.put(url, {data:data})
    return apiResult;
}

export const deleteAsanaTask = async (gid): Promise<AsanaTask> => {
    let url = ASANA_TASK_DELETE.replace("%TASK_GID%", gid)
    let apiResult: AsanaTask = await AsanaAPIManager.delete(url)
    return apiResult;
}

export const retrieveAsanaTask = async (gid: string): Promise<any> => {
    let url = ASANA_TASK_RETRIEVE.replace("%TASK_GID%", gid)
    let apiResult: any = await AsanaAPIManager.get(url)
    return apiResult;
}

export const retrieveAsanaTasks = async (gid: string): Promise<any> => {
    let offset = ""
    let overallResult:any = []
    

    const func = async() => {
        let url = ASANA_TASKS_RETRIEVE.replace("%PROJECT_GID%", gid)
        if (offset) {
            url = url + "&offset=" + offset
        }
        let apiResult: any = await AsanaAPIManager.get(url)
        overallResult.push(apiResult.data)

        if (apiResult.next_page) {
            offset = apiResult.next_page.offset
            await func()
        } else {
            offset = ""
        }

    }

    await func()

    
    return overallResult.reduce((prev,cur) => {
        if (cur?.length > 0) {
            return [...prev,...cur]
        }
        
    },[]);
}

export const retrieveAsanaIncompleteMilestonesInSection = async (gid: string): Promise<any> => {
    let url = ASANA_SEARCH_BY_SECTION_INCOMPLETE_MILESTONES.replace("%SECTION_GID%", gid)
    let apiResult: any = await AsanaAPIManager.get(url)
    return apiResult;
}

export const retrieveAsanaTasksInSection = async (gid: string): Promise<any> => {
    let url = ASANA_TASKS_IN_SECTION_RETRIEVE.replace("%SECTION_GID%", gid)
    let apiResult: any = await AsanaAPIManager.get(url)
    return apiResult;
}


export const retrieveAsanaCustomFields = async(projectgid: string): Promise<any> => {
    let url = ASANA_CUSTOM_FIELDS_RETRIEVE.replace("%PROJECT_GID%", projectgid)
    let apiResult: any = await AsanaAPIManager.get(url)
    return apiResult;
}


export const createAsanaProject = async (project_gid:string, data: any): Promise<AsanaProject> => {
    let url = ASANA_PROJECT_CREATE.replace("%PROJECT_GID%", project_gid)
    let apiResult: any = await AsanaAPIManager.post(url, data)
    return apiResult;
}


export const getProjectEvents = async (project_gid:string, sync_gid:string): Promise<any> => {
    let url = ASANA_PROJECT_EVENT_RETRIEVE.replace("%PROJECT_GID%", project_gid).replace("%SYNC_GID%", sync_gid)
    let apiResult: any = await AsanaAPIManager.get(url)
    return apiResult;
}

export const createAsanaTask = async (data: any): Promise<any> => {
    let url = ASANA_TASK_CREATE
    let apiResult: any = await AsanaAPIManager.post(url, {data:data})
    return apiResult;
}   

export const addTaskToSection = async (taskGid: any, sectionGID:any): Promise<any> => {
    let url = ASANA_TASK_ADD_TO_SECTION.replace("%SECTION_GID%", sectionGID)
    let apiResult: any = await AsanaAPIManager.post(url, {data:{"task":taskGid}})
    return apiResult;
}



export const createAsanaSubTask = async (gid:any, data: any): Promise<any> => {
    let url = ASANA_SUBTASK_CREATE.replace("%TASK_GID%", gid)
    let apiResult: any = await AsanaAPIManager.post(url, data)
    return apiResult;
}

export const createAsanaTaskComment = async (gid:any, data: any): Promise<any> => {
    let url = ASANA_TASK_COMMENT_CREATE.replace("%TASK_GID%", gid)
    let apiResult: any = await AsanaAPIManager.post(url, data)
    return apiResult;
}



export const deleteAsanaTaskComment = async (gid:any): Promise<AsanaTask> => {
    let url = ASANA_TASK_COMMENT_DELETE.replace("%STORY_GID%", gid)
    let apiResult: any = await AsanaAPIManager.delete(url)
    return apiResult;
}

export const uploadAsanaAttachment = async (gid, file): Promise<any> => {
    let url = ASANA_TASK_ATTACHMENT_UPLOAD.replace("%TASK_GID%", gid)
    let apiResult: any = await AsanaAPIManager.upload(url, file)
    return apiResult;
}

export const addTaskToProject = async (task_gid:string, data: any): Promise<any> => {
    let url = ASANA_TASK_ADD_PROJECT.replace("%TASK_GID%", task_gid)
    let apiResult: any = await AsanaAPIManager.post(url, data)
    return apiResult;
}

export const retrieveAsanaTeam = async (gid: string): Promise<any> => {
    let url = ASANA_TEAM_RETRIEVE.replace("%PROJECT_GID%", gid)
    let apiResult:any= await AsanaAPIManager.get(url)
    return apiResult;
}

export const retrieveAsanaSections = async (gid: string): Promise<any> => {
    let url = ASANA_SECTIONS_RETRIEVE.replace("%PROJECT_GID%", gid)
    let apiResult:any= await AsanaAPIManager.get(url)
    return apiResult;
}

export const retrieveAsanaTaskStories = async (gid: string): Promise<any> => {
    let url = ASANA_TASK_STORIES_RETRIEVE.replace("%TASK_GID%", gid)
    let apiResult:any= await AsanaAPIManager.get(url)
    return apiResult;
}

export const updateAsanaTaskStory = async (data: any): Promise<any> => {
    
    let url = ASANA_TASK_STORY_RETRIEVE.replace("%STORY_GID%", data.gid)
    let apiResult: AsanaTask = await AsanaAPIManager.put(url, {data:data})
    return apiResult;
}


export const retrieveAsanaSubTask = async (gid: string): Promise<any> => {
    let url = ASANA_TASK_SUBTASKS_RETRIEVE.replace("%TASK_GID%", gid)
    let apiResult:any= await AsanaAPIManager.get(url)
    return apiResult;
}

// todo
export const retrieveAsanaSubTaskBatch = async (gidList: string[]): Promise<any> => {
    
    let urls = gidList.map(gid => ASANA_TASK_SUBTASKS_RETRIEVE.replace("%TASK_GID%", gid))
    
    let apiResult:any= await AsanaAPIManager.getBatch(urls)
    return apiResult;
}


export const retrieveAsanaAttachments = async (gid: string): Promise<any> => {
    let url = ASANA_TASK_ATTACHMENTS_RETRIEVE.replace("%TASK_GID%", gid)
    let apiResult:any= await AsanaAPIManager.get(url)
    return apiResult;
}


export const removeAsanaAttachment = async (gid: string): Promise<any> => {
    let url = ASANA_TASK_ATTACHMENT_DELETE.replace("%ATTACHMENT_GID%", gid)
    let apiResult:any= await AsanaAPIManager.delete(url)
    return apiResult;
}

export const retrieveAsanaAttachment = async (gid: string): Promise<any> => {
    let url = ASANA_TASK_ATTACHMENT_RETRIEVE.replace("%ATTACHMENT_GID%", gid)
    let apiResult:any= await AsanaAPIManager.get(url)
    return apiResult;
}