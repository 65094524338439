import { useContext, useState } from 'react';
import { downloadCSVSample, getHeadCountListFilters, uploadEmployeesList } from '../../../../APIFunctions/headcouts';
import { AuthContext } from '../../../../contexts/AuthContext';
import { DataConext } from '../../../../contexts/HeadCount/DataConext';
import AlertDialog from '../../../Common/AlertDialog';

import MDIcons from '../../../Common/MDIcons';
import './BulkAddEmployeeDialog.css';

interface IBulkAddEmployeeDialogProps {
    open: boolean;
    onClose: () => void;
}

export default function BulkAddEmployeeDialog({ open, onClose }: IBulkAddEmployeeDialogProps) {
    const [selectedFile, setSelectedFile] = useState(null);
    const { selectedCompany, setCompanyFilter } = useContext(AuthContext);
    const { showToastThenRefresh } = useContext(DataConext);


    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0])
    }

    const onAddClick = async () => {
        if (selectedFile && selectedCompany) {
            var result = await uploadEmployeesList(selectedCompany, selectedFile)
            if (result.detail) {
                if (result.updateLookups) {
                    // showToast('success', result.detail);
                    if (selectedCompany && !selectedCompany.is_Onboarding) {
                        var response = await getHeadCountListFilters(selectedCompany)
                        setCompanyFilter(response)
                    }
                    showToastThenRefresh(result.detail, result.count)
                    onClose();
                }
                else {
                    showToastThenRefresh(result.detail, result.count)
                    onClose();
                }
            }
            else {
                onClose()
            }

        }
    }

    return (
        <AlertDialog
            dialogType="user-form"
            open={open}
            onClose={onClose}
            title={<span className="BulkAddEmployee__Title"> <h3 style={{ fontWeight: 600 }}>{"Add Bulk employees"}</h3> </span>}
            dialogActions={
                <div className='action_btns_container'>
                    <button className={"btn--tertiary btn--M"} onClick={onClose} autoFocus>
                        <span>Cancel</span>
                    </button>
                    <button className={!selectedFile ? "btn--primary--disabled btn--M" : "btn--primary btn--M"} onClick={() => onAddClick()} autoFocus>
                        <span>Upload</span>
                    </button>
                </div>

            }
        >
            <div className='BulkAddEmployee__Body__Container'>
                <div>
                    <MDIcons title={'File'} />
                    <label className="custom_file_upload">
                        <input type="file" accept=".csv" onChange={handleFileSelect} />
                        Click here to browse and select a local file
                    </label>
                    <span className='disclaimer'>We only accept our Excel template, <span className='downloadTemplate' onClick={() => {
                        downloadCSVSample()
                    }}>Download it now</span></span>
                    <br></br>
                    <br></br>
                    <br></br>
                </div>

            </div>
        </AlertDialog>
    )
}