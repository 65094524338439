import { useContext, useState, SyntheticEvent, useEffect } from "react";
import {
  DataGridPro,
  GridRowParams,
  GridColDef,
  MuiEvent,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageSizeSelector,
  GridRowId,
  GRID_DATE_COL_DEF,
  GridColumns,
  GridRowsProp,
  GridRenderCellParams,
  gridFilteredDescendantCountLookupSelector,
  DataGridProProps,
} from "@mui/x-data-grid-pro";
import { createStyles, makeStyles } from "@mui/styles";
import TablePagination from "@mui/material/TablePagination";
import columnsRenderFunctions from "./RenderComponents";
import { ReactComponent as FilterDropdownIcon } from "../../../static/img/filter_dropdown_icon.svg";
import "./tableComponent.css";
import NoTableData from "../../Common/NoTableData";
import CustomSwitch from "../../Common/CustomSwitch";
import { tasksTableDisplay, tasksTableFilter, tasksTableSearchOptions } from "../../../config/taskConfigs";
import HideAllButton from "../../HeadCountModule/TableComponent/HideAllButton";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box } from "@mui/system";
import { updateAsanaTask } from "../../../APIFunctions/Asana";
import { dateFromString, displayStrDateFormatter, getInitials, isInThePast, isToday, isTomorrow, isYesterday, uppercaseAllWordLetters, uppercaseFirstLetter } from "../../../utils/helpers";
import { Button, ButtonProps } from "@mui/material";
import { TaskServicesContext } from "../../../contexts/Task/TaskServicesContext";
import { TaskTableContext } from "../../../contexts/Task/TaskTableContext";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MDIcons from "../../Common/MDIcons";
import CustomTooltip from "../../Common/Tooltip";
import defaultCompany from "../../../static/img/default_company.png"
import { getMonthShort } from "../../../utils/DateFunctions";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& .MuiDataGrid-columnHeaderTitle": {
        "& ::first-letter": {
          textTransform: "capitalize",
        },
      },
      "& .MuiDataGrid-root": {
        border: "none",
        "& .MuiDataGrid-footerContainer": {
          display: "none",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          // fontWeight: "bold",
          fontSize: "12px",
          lineHeight: "16px",
          color: "#666687",
        },
        "& .MuiDataGrid-iconSeparator": {
          height: "37px",
          borderLeft: "1px solid #EAEAEF",
        },
        "& .MuiDataGrid-columnHeader--sortable": {
          "& .MuiDataGrid-iconButtonContainer": {
            visibility: "visible",
            width: "auto",
          },
        },
        "& .MuiDataGrid-columnHeaders": {
          height: "37px",
          minHeight: "37px !important",
          background: "#fff",
          borderBottom: "1px solid #EAEAEF",
        },
        "& .MuiDataGrid-virtualScroller": {
          marginTop: "37px !important",
        },
        "& .MuiDataGrid-columnHeader": {
          height: "37px",
          borderRight: "1px solid #EAEAEF",
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            flexDirection: "row",
          },
          "& .MuiDataGrid-columnHeaderDraggableContainer": {
            flexDirection: "row",
            "& .MuiDataGrid-menuIcon": {
              visibility: "visible",
              marginRight: "2px",
              width: "auto !important",
            },
          },
        },
      },
      "& .MuiDataGrid-row": {
        backgroundColor: "#fff",
        "& :hover": {
          cursor: "pointer",
        },
        "&.Mui-selected": {
          backgroundColor: "#85b4401A !important",
          "& :hover": {
            backgroundColor: "#F6F6F9 !important",
          },
        },
      },
      "& .MuiDataGrid-cell": {
        color: "#212134",
        fontSize: 14,
        borderBottom: "1px solid #EAEAEF",
        borderRight: "1px solid #EAEAEF",
        justifyContent: "flex-start",
        "& span": {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
      },
      "& .MuiCheckbox-root": {
        color: "#C0C0CF",
      },
      "& .MuiCheckbox-root >svg": {
        border: "0px solid #FFFFFF",
        height: "20px",
        width: "20px",
      },
      "& .MuiCheckbox-root.Mui-checked": {
        color: "#85b440 !important",
      },
      "& .DataGrid-marked-row--header": {
        height: "56px !important",
        maxHeight: "56px !important",
        "& .MuiDataGrid-cell": {
          height: "56px !important",
          maxHeight: "56px !important",
          borderRight: "none",
        },
      },
    },
  })
);

export default function DataGridAdminProject({ completedFilter }) {
  const { team, sectionsQuery, customFields, tasksCategory, projectID } = useContext(TaskServicesContext);
  const { projectTasksQuery, setTasks, openTask, subTasksObj, companyIcons } = useContext(TaskTableContext);
  const classes = useStyles();
  const [gridRows, setGridRows] = useState<GridRowsProp[]>([]);
  // const [columnsData, setColumnsData] = useState<GridColumns>([])
  // const [rowsData, setRowsData] = useState<GridRowsProp>([])
  const toGridRows = () => {
    if (projectTasksQuery.data?.length > 0) {
      var updatedGridRows: any[] = [];
      switch (completedFilter) {
        case "incomplete":
          updatedGridRows = projectTasksQuery.data
            .filter((i) => i.completed === false)
            .map((x) => {
              return {
                ...x,
                section: x.memberships.find((x) => x.project.gid === projectID)?.section?.name,
              };
            });
          break;
        case "completed":
          updatedGridRows = projectTasksQuery.data
            .filter((i) => i.completed === true)
            .map((x) => {
              return {
                ...x,
                section: x.memberships.find((x) => x.project.gid === projectID)?.section?.name,
              };
            });
          break;
        case "all":
          updatedGridRows = projectTasksQuery.data?.map((x) => {
            return { ...x, section: x.memberships.find((x) => x.project.gid === projectID)?.section?.name };
          });
          break;
      }

      setGridRows([...updatedGridRows]);
    }
  };

  function getCustomFieldValue(params, key) {
    const customField = params.row.custom_fields?.find((x) => x.name === key);
    if (customField) {
      if (customField.type === "enum") {
        return customField.enum_value?.name;
      } else if (customField.type === "date") {
        return customField.date_value?.date;
      }
    }
  }

  function getMilestoneAssignees(params) {
    if (params.row.gid) {
      if (params.row.gid === "1203672725980910") {
      }
      if (subTasksObj?.[params.row.gid]) {
        let assignees: any = [];
        const subTasks = subTasksObj[params.row.gid];
        let pendingFlag = false;
        for (let i = 0; i < subTasks.length; i++) {
          if (subTasks[i].assignee?.name) {
            if ((i === 0 && subTasks[i].completed === false) || (subTasks[i - 1]?.completed === true && subTasks[i].completed === false)) {
              pendingFlag = true;
            } else {
              pendingFlag = false;
            }
            assignees.push({
              assignee: subTasks[i].assignee?.name,
              photo: subTasks[i].assignee?.photo?.["image_27x27"],
              status: subTasks[i].completed ? "done" : pendingFlag ? "pending" : "scheduled",
            });
          }
        }
        return assignees;
      }
    } else {
      return [];
    }
  }

  function getNextIncompleteTask(gid) {
    let nextIncompleteSubtask: any = {};
    const subTasks = subTasksObj[gid];
    for (let i = 0; i < subTasks.length; i++) {
      if (subTasks[i].completed === false) {
        nextIncompleteSubtask = subTasks[i];
        return nextIncompleteSubtask;
      }
    }
  }

  function getMilestoneDueDate(params) {
    if (tasksCategory !== "user" && tasksCategory !== undefined) {
      // get the next incomplete task's due date
      if (params.row.gid) {
        if (subTasksObj?.[params.row.gid]) {
          return getNextIncompleteTask(params.row.gid)?.due_on;
        } else {
          return params.value;
        }
      }
    } else {
      return params.value;
    }
  }

  const customFieldsKeys = customFields.map((x) => x.type === "enum" && x.name);
  let allFields: any = [];
  if (tasksCategory === "payroll") {
    allFields = ["client"]
      .concat(customFieldsKeys)
      .concat(["payroll cycle"])
      .concat(["assignee"])
      .concat(["stage"])
      .concat(["next_stage_due_on"])
      .concat(["due_on"])
      .filter((x) => x);
  } else if (tasksCategory === "month-end-close") {
    allFields = ["client"]
      .concat(customFieldsKeys)
      .concat(["period"])
      .concat(["assignee"])
      .concat(["stage"])
      .concat(["next_stage_due_on"])
      .concat(["due_on"])
      .filter((x) => x);
  } else {
    allFields = ["client"]
      .concat(customFieldsKeys)
      .concat(["assignee"])
      .concat(["stage"])
      .concat(["next_stage_due_on"])
      .concat(["due_on"])
      .filter((x) => x);
  }

  const headCells: GridColDef[] = allFields.map((key) => {
    const cellRenerItem = columnsRenderFunctions[key];
    if (key === "client") {
      return {
        field: key,
        headerName: "Client",
        flex: 1,
        minWidth: 300,
        valueGetter: (value) => value.row.name,
        renderCell: (params) => {
          const onComplete = (TaskId) => {
            const Task = projectTasksQuery.data?.find((x) => x.gid === TaskId);
            const newItem = { ...Task };
            newItem.completed = !Task.completed;
            Task.completed = newItem.completed;
            const newTaskArray = projectTasksQuery.data?.map((x) => (x.gid === Task.gid ? Task : x));
            setTasks(newTaskArray);
            updateAsanaTask({
              gid: Task.gid,
              completed: newItem.completed,
            });
          };
          const companyImage = () => {
            const image = companyIcons.find(x => x.companyName === params.row.name.split(":")[0])
            return image
          }
          return (
            <Box>
              <div className="taskName__Cell">
                <Box sx={{ marginBottom: "0.5rem" }} className="no-borders-no-background" id="success" onClick={() => onComplete(params.row.gid)}>
                  {projectTasksQuery.data?.find((x) => x.gid === params.row.gid)?.completed ? <CheckCircleRoundedIcon sx={{ color: "#85B440" }} /> : <MDIcons title="asana_table_rows_check" />}
                </Box>
                <Box><img className="company-icon-header" src={companyImage() ? companyImage()?.["companyAvatarURL"] : defaultCompany} alt="" /></Box><Box sx={{ marginLeft: 0, marginTop: 0 }}>{params.row.name.split(":")[0]}</Box>
              </div>
            </Box>
          );
        },
      };
    } else if (key === "stage") {
      return {
        field: key,
        headerName: uppercaseFirstLetter(key),
        flex: 1,
        minWidth: 100,
        valueGetter: (value) => value.row.section,
        renderCell: (params) => {
          return (
            <>
              <Box>{params.row.section}</Box>
            </>
          );
        },
      };
    } else if (key === "payroll cycle") {
      return {
        field: key,
        headerName: uppercaseFirstLetter(key),
        flex: 1,
        minWidth: 150,
        valueGetter: (value) => value.row.section,
        renderCell: (params) => {
          const startDate = getCustomFieldValue(params, "Payroll Cycle Start Date");
          const endDate = getCustomFieldValue(params, "Payroll Cycle End Date");
          return (
            <>
              <Box>
                {startDate} - {endDate}
              </Box>
            </>
          );
        },
      };
    } else if (key === "period") {
      return {
        field: key,
        headerName: uppercaseFirstLetter(key),
        width: 100,
        valueGetter: (value) => value.row.section,
        renderCell: (params) => {
          return (
            <>
              <Box>
                {getMonthShort(dateFromString(getCustomFieldValue(params, "Period"))|| new Date(getCustomFieldValue(params, "Period")))||""}
              </Box>
            </>
          );
        },
      };
     } else if (key === "assignee") {
      return {
        field: key,
        headerName: uppercaseFirstLetter(key),
        flex: 1,
        minWidth: 150,
        renderCell: (params) => {
          let assigneeArray: any = [];
          let assignee: any = {};
          assigneeArray = getMilestoneAssignees(params);

          return (
            <>
              {assigneeArray?.length > 0
                ? assigneeArray.map((x) => {
                    return (
                      <Box className="AdminProject__Assignee" sx={{ marginRight: 1, marginTop: 0 }}>
                        <div style={{ opacity: x.status !== "pending" ? 0.5 : 1 }}>
                          <CustomTooltip title={<>{x.assignee}</>}>
                            {x.photo ? (
                              <img className={x.status === "pending" ? "AsanaPhoto CircleBorder__Green" : "AsanaPhoto"} src={x.photo} alt="" />
                            ) : (
                              <div className="user__circle">
                                <div className="user__circle_inner">{getInitials(x.assignee)}</div>
                              </div>
                            )}
                          </CustomTooltip>
                        </div>

                        {x.status === "done" && <MDIcons title="checkbox_assignee" />}
                      </Box>
                    );
                  })
                : assignee?.label && (
                    <Box sx={{ marginLeft: 0, marginTop: 0 }}>
                      {assignee?.photo ? (
                        <img className="AsanaPhoto" src={assignee?.photo["image_27x27"]} alt="" />
                      ) : (
                        <div className="user__circle">
                          <div className="user__circle_inner">{getInitials(assignee?.label)}</div>
                        </div>
                      )}
                    </Box>
                  )}
              <Box sx={{ marginLeft: 1, marginTop: 0 }}>{assignee?.label}</Box>
            </>
          );
        },
      };
    } else if (key === "due_on") {
      return {
        ...GRID_DATE_COL_DEF,
        field: key,
        headerName: "Due Date (Milestone)",
        valueGetter: (value) => value.row.due_on,
        renderCell: (params) => {
          // discuss with Mihai: how to refactor this and use it for the Payroll Client View?
          if (params.row.due_on) {
            if (isToday(dateFromString(params.row.due_on)) === true) {
              return <Box sx={{ color: "#5CB176" }}>Today</Box>;
            }
            if (isTomorrow(dateFromString(params.row.due_on)) === true) {
              return <Box sx={{ color: "#5CB176" }}>Tomorrow</Box>;
            }
            if (isYesterday(dateFromString(params.row.due_on)) === true) {
              return <Box sx={{ color: "#EE5E52" }}>Yesterday</Box>;
            }

            if (isInThePast(dateFromString(params.row.due_on)) === true) {
              return <Box sx={{ color: "#EE5E52" }}>{displayStrDateFormatter(params.row.due_on)}</Box>;
            }
            return displayStrDateFormatter(params.row.due_on);
          }
        },
        flex: 1,
        minWidth: 150,
      };
    } else if (key === "next_stage_due_on") {
      return {
        ...GRID_DATE_COL_DEF,
        field: key,
        headerName: "Due Date (Stage)",
        valueGetter: (value) => dateFromString(getMilestoneDueDate(value)),
        renderCell: (params) => {
          // discuss with Mihai: how to refactor this and use it for the Payroll Client View?

          const message = "Imporant! This payroll processing is due today!"
          if (isToday(params.value) === true) {
            return (
              <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                <Box sx={{ color: "#5CB176" }}>Today</Box>

                <>
                  {params.row?.section === "Ready to Process" && (
                    <CustomTooltip title={<>{message}</>}>
                      <div>
                      <MDIcons title="due_date_warning" />
                      </div>
                    </CustomTooltip>
                  )}
                </>
              </Box>
            );
          }
          if (isTomorrow(params.value) === true) {
            return <Box sx={{ color: "#5CB176" }}>Tomorrow</Box>;
          }
          if (isYesterday(params.value) === true) {
            return <Box sx={{ color: "#EE5E52" }}>Yesterday</Box>;
          }

          if (isInThePast(params.value) === true) {
            return <Box sx={{ color: "#EE5E52" }}>{displayStrDateFormatter(params.value)}</Box>;
          }
          return displayStrDateFormatter(params.value);
        },
        flex: 1,
        minWidth: 150,
      };
    } else if (key === "Priority") {
      return {
        field: key,
        headerName: uppercaseFirstLetter(key),
        flex: 1,
        minWidth: 100,
        valueGetter: (params) => getCustomFieldValue(params, key),
        renderCell: (params) => {
          const color = params.value === "High" ? "#EE5E52" : params.value === "Medium" ? "#F29D41" : "#5CB176";
          return <Box sx={{ color: color }}>{params.value}</Box>;
        },
      };
    } else if (key === "Status") {
      return {
        field: key,
        headerName: uppercaseFirstLetter(key),
        flex: 1,
        minWidth: 70,
        valueGetter: (params) => getCustomFieldValue(params, key),
        renderCell: (cellValues) => {
          const value = cellValues.row.custom_fields?.find((x) => x.name === "Status")?.enum_value?.name;
          const color = value === "In Progress" ? "rgba(133, 180, 64, 0.75)" : "#5CB176";
          return (
            <>
              <Box sx={{ color: color }}>{value}</Box>
            </>
          );
        },
      };
    } else {
      return {
        field: key,
        headerName: uppercaseFirstLetter(key),
        flex: 1,
        valueGetter: (params) => getCustomFieldValue(params, key),
        renderCell: (cellValues) => {
          const value = getCustomFieldValue(cellValues, key);
          const color =
            value === "In Progress"
              ? "rgba(133, 180, 64, 0.75)"
              : value === "Complete"
              ? "#5CB176"
              : value === "High"
              ? "#EE5E52"
              : value === "Medium"
              ? "#F29D41"
              : value === "Low"
              ? "#5CB176"
              : "var(--Neutral600)";
          return (
            <>
              <Box sx={{ color: color }}>{value}</Box>
            </>
          );
        },
      };
    }
  });

  // const headCells: GridColDef[] = [{field:'name',headerName:'Task', width:400}

  // ]
  useEffect(() => {
    if (projectTasksQuery.data?.length > 0) {
      toGridRows();
    }
  }, [projectTasksQuery.data, sectionsQuery, completedFilter, subTasksObj]);
  return (
    <div className="tableContainer__TasksModule">
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ flexGrow: 1, height: "100%" }} className={classes.root}>
          <DataGridPro
            initialState={{
              sorting: {
                sortModel: [
                  { field: "next_stage_due_on", sort: "asc" },
                  { field: "due_on", sort: "asc" },
                ],
              },
            }}
            columns={headCells}
            rows={gridRows}
            rowHeight={48}
            // getRowId={params => params.row?.gid}
            getRowClassName={(params) => {
              return `DataGrid-marked-row--${params.row.section?.length === 1 ? "header" : ""}`;
            }}
            disableSelectionOnClick
            disableColumnPinning
            disableChildrenSorting
            showColumnRightBorder
            showCellRightBorder
            localeText={{
              columnMenuSortAsc: "Sort A to Z ↑",
              columnMenuSortDesc: "Sort Z to A ↓",
              columnsPanelHideAllButton: <HideAllButton />,
            }}
            onRowClick={(params: GridRowParams, event: MuiEvent<SyntheticEvent<Element, Event>>) => {
              event.defaultMuiPrevented = true;
              // if ((event.target as HTMLInputElement).type === "checkbox" || (event.target as HTMLInputElement).nodeName === "svg" || (event.target as HTMLInputElement).nodeName === "path") return;
              // openTask(params.row.gid);
            }}
            components={{
              NoRowsOverlay: NoTableData,
              BaseSwitch: CustomSwitch,
              ColumnMenuIcon: FilterDropdownIcon,
            }}
            defaultGroupingExpansionDepth={-1}
            // checkboxSelection={taskListDisplay.length > 0}
          />
        </div>
      </div>
    </div>
  );
}
