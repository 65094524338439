export default function NoTableData() {
    return (
        <div className="no-results-container">
            <svg width="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M46.5558 90.1116C70.611 90.1116 90.1116 70.611 90.1116 46.5558C90.1116 22.5006 70.611 3 46.5558 3C22.5006 3 3 22.5006 3 46.5558C3 70.611 22.5006 90.1116 46.5558 90.1116Z" stroke="#BCBCBC" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M46.5 26C57.8025 26 67 35.1975 67 46.5C67 57.8025 57.8025 67 46.5 67C35.1975 67 26 57.8025 26 46.5C26 35.1975 35.1975 26 46.5 26ZM36.5017 52.3571L40.6429 56.4983L46.5 50.6412L52.3571 56.4983L56.4983 52.3571L50.6412 46.5L56.4983 40.6429L52.3571 36.5017L46.5 42.3588L40.6429 36.5017L36.5017 40.6429L42.3588 46.5L36.5017 52.3571Z" fill="#BCBCBC" />
                <path d="M101.001 101L77.3174 77.3167" stroke="#BCBCBC" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <span> Sorry we couldn't find any results. </span>
        </div>
    )
}