import { useContext } from 'react';
import SideBar from './components/SideBar';
import AppRouter from './Routers/AppRouter';
import { AuthContext } from './contexts/AuthContext';
import ProfileLoading from './components/Common/ProfileLoading';
import { ModuleContextProvider } from './contexts/ModuleContext';
import { SocketContextProvider } from './contexts/SocketContext';
import { SideBarContext } from './contexts/SideBarContext';
import { OnboardingContextProvider } from './contexts/Onboarding/OnboardingContext';

export default function AppContainer() {
    const { isProfileLoading, selectedCompany } = useContext(AuthContext);
    const { isSideBarCollapsed, toggleSideBar } = useContext(SideBarContext)

    return (
        isProfileLoading ? <ProfileLoading /> :

            <div className={`AppContainer ${isSideBarCollapsed ? 'collapsed' : ""}`} >
                <div className={`SideBar__Container ${isSideBarCollapsed ? 'collapsed' : ""}`}>
                    <SideBar collapsed={isSideBarCollapsed} toggleCollapse={toggleSideBar} />
                </div>
                <div className={`bi-report-wrapper ${isSideBarCollapsed ? 'collapsed' : ""} ${selectedCompany && selectedCompany.getModuleUrls().length === 0 && 'no-modules'}`}>
                    <SocketContextProvider>
                        <ModuleContextProvider>
                            <OnboardingContextProvider>
                                <AppRouter />
                            </OnboardingContextProvider>
                        </ModuleContextProvider>
                    </SocketContextProvider>
                </div>
            </div>
    )
}