import React from "react";
import TransactionLineTable from "./TransactionLineTable";

const SalesReceiptTransaction = ({ transaction }) => {
  return (
    <div>
      <div className="TransactionDetail__GroupContainer">
        <div className="TransactionDetail__ParameterContainer">
          <span>Customer</span>
          <div>{transaction?.["detail"]?.["SalesReceipt"]?.["CustomerRef"]?.["name"]}</div>
        </div>
        <div className="TransactionDetail__ParameterContainer">
          <span>Email</span>
          <div>{transaction?.["detail"]?.["SalesReceipt"]?.["BillEmail"]?.["Address"] || "no email provided"}</div>
        </div>
        <div className="TransactionDetail__ParameterContainer">
          <span>Billing address</span>
          <div className="TransactionDetail__ParameterContainer__MultiLine">
            <span>{transaction?.["detail"]?.["SalesReceipt"]?.["BillAddr"]?.["Line1"]}, </span>
            <span>{transaction?.["detail"]?.["SalesReceipt"]?.["BillAddr"]?.["Line2"]}, </span>
            <span>{transaction?.["detail"]?.["SalesReceipt"]?.["BillAddr"]?.["Line3"]}</span>
          </div>
        </div>
        <div className="TransactionDetail__ParameterContainer">
          <span>Sales Receipt date</span>
          <div>{transaction?.["detail"]?.["SalesReceipt"]?.["TxnDate"]}</div>
        </div>
        <div className="TransactionDetail__ParameterContainer">
          <span>Deposit to</span>
          <div>{transaction?.["detail"]?.["SalesReceipt"]?.["DepositToAccountRef"]?.["name"]}</div>
        </div>
        <div className="TransactionDetail__ParameterContainer">
          <span>Document #</span>
          <div>{transaction?.["detail"]?.["SalesReceipt"]?.["DocNumber"]}</div>
        </div>
      </div>
      <TransactionLineTable
        lines={transaction?.["detail"]?.["SalesReceipt"]?.["Line"]}
        transactionID={transaction?.["detail"]?.["SalesReceipt"]?.["Id"]}
        transaction_type="Sales Receipt"
      />
      <div className="TransactionDetail__Subtotal">
        <span>Subtotal</span>
        <div>${transaction?.["detail"]?.["SalesReceipt"]?.["Line"].find((x) => x["DetailType"] === "SubTotalLineDetail")?.["Amount"]}</div>
      </div>
      <div className="TransactionDetail__ParameterContainer">
        <span>Message</span>
        <div>{transaction?.["detail"]?.["SalesReceipt"]?.["CustomerMemo"]?.["value"]}</div>
      </div>
    </div>
  );
};

export default SalesReceiptTransaction;
