import React, { useContext, useState } from 'react'
import { AuthContext } from '../../contexts/AuthContext';
import { OnboardingContext } from '../../contexts/Onboarding/OnboardingContext';
import { MuiChipsInput } from 'mui-chips-input';


const FormPage3 = () => {

  const { onBack, onSubmit, onboardingInputs, setOnboardingInputs, done } = useContext(OnboardingContext);
  
  const handleDepartmentsChange = (newChips) => {
    var newArray = {...onboardingInputs, "headcount_departments":newChips}
    setOnboardingInputs(newArray)
  }

  const handleLocationsChange = (newChips) => {
    var newArray = {...onboardingInputs, "headcount_locations":newChips}
    setOnboardingInputs(newArray)
  }

  return (
    <>
      <h1>What departments & locations make up your headcount?</h1>
      <div className="bodyContainer">
        <div className="bodyContainer__InputSection">
          <div className='onboarding__block--y'>
            <h2>Headcount departments</h2>
            <MuiChipsInput value={onboardingInputs["headcount_departments"]}
              placeholder={"Type a headcount department name then press 'enter'"}
              id={'departments-input'}
              onChange={handleDepartmentsChange}
              
            />
          </div>
          <div className='onboarding__block--y'>
            <h2>Headcount locations</h2>
            <MuiChipsInput value={onboardingInputs["headcount_locations"]}
              placeholder={"Type a location name then press 'enter'"}
              id={'locations-input'}
              onChange={handleLocationsChange}
            />
          </div>
      </div>
      <div className="footer--btns">
        <button className='btn--M btn--tertiary' onClick={onBack}>
          Previous
        </button>
        <input
          className='btn--primary btn--M '
          type="submit"
          value={done ? "Done" : "Next"}
          onClick={onSubmit}
          disabled={onboardingInputs["headcount_locations"].length === 0 || onboardingInputs["headcount_departments"].length === 0 }
        />
      </div>
    </div>

    </>
  )
}

export default FormPage3