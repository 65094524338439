import React, { useCallback, useContext, useMemo } from "react";
import AlertDialog from "../../../../../Common/AlertDialog";
import { useQuery } from "@tanstack/react-query";
import { getTransactionDetail } from "../../../../../../APIFunctions/financial_statement";
import { AuthContext } from "../../../../../../contexts/AuthContext";
import "./style.css";
import SalesReceiptTransaction from "./SalesReceiptTransaction";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { MonthEndCloseContext } from "../../../../../../contexts/TicketingSystem/MonthEndCloseContext";
import JournalEntryTransaction from "./JournalEntryTransaction";
import { useSearchParams } from "react-router-dom";
import DepositTransaction from "./DepositTransaction";
import CreditMemoTransaction from "./CreditMemoTransaction";
import ExpenseTransaction from "./ExpenseTransaction";
import RefundTransaction from "./RefundTransaction";
import BillTransaction from "./BillTransaction";
import InvoiceTransaction from "./InvoiceTransaction";
import { CircularProgress } from "@mui/material";
import { DataContext } from "../../Context/DataContext";
import { CommentContext } from "../../Context/CommentContext";

const TransactionDetail = ({ transaction_type, transaction_id }) => {
  const { selectedCompany } = useContext(AuthContext);
  const { setSelectedTransaction } = useContext(DataContext);
  const { setSelectedCommentID } = useContext(CommentContext)
  const [searchParams, setSearchParams] = useSearchParams();

  let transactionTypeAPIParam;
  if (transaction_type === "Sales Receipt") {
    transactionTypeAPIParam = "salesreceipt";
  }
  if (transaction_type === "Invoice") {
    transactionTypeAPIParam = "invoice";
  }
  if (transaction_type === "Journal Entry") {
    transactionTypeAPIParam = "journalentry";
  }
  if (transaction_type === "Deposit") {
    transactionTypeAPIParam = "deposit";
  }
  if (transaction_type === "Credit Memo") {
    transactionTypeAPIParam = "creditmemo";
  }
  if (transaction_type === "Expense" || transaction_type === "Credit Card Expense" || transaction_type === "Cash Expense" ||transaction_type === "Check" ) {
    transactionTypeAPIParam = "purchase";
  }
  if (transaction_type === "Refund") {
    transactionTypeAPIParam = "refundreceipt";
  }
  if (transaction_type === "Bill") {
    transactionTypeAPIParam = "bill";
  }


  const transactionDetailQuery = useQuery({
    queryKey: ["transaction-detail", transactionTypeAPIParam, transaction_id],
    queryFn: () => getTransactionDetail(selectedCompany?.id, transactionTypeAPIParam, transaction_id),
    staleTime: 2 * 60 * 1000,
    enabled: !!(transaction_id && selectedCompany),
  });

  const onClose = () => {
    setSelectedTransaction(null)
    searchParams.delete("comment");
    setSearchParams(searchParams);
    setSelectedCommentID(null);
  };

  const TransactionViews = useMemo(() => {
    if (transaction_type === "Sales Receipt") {
      return <SalesReceiptTransaction transaction={transactionDetailQuery.data} />
    }
    if (transaction_type === "Invoice") {
      
      return <InvoiceTransaction transaction={transactionDetailQuery.data} />
    }
    if (transaction_type === "Journal Entry") {
      
      return <JournalEntryTransaction transaction={transactionDetailQuery.data} />
    }
    if (transaction_type === "Deposit") {
      
      return <DepositTransaction transaction={transactionDetailQuery.data} />
    }
    if (transaction_type === "Credit Memo") {
      
      return <CreditMemoTransaction transaction={transactionDetailQuery.data} />
    }
    if (transaction_type === "Expense" || transaction_type === "Credit Card Expense" || transaction_type === "Cash Expense" ||transaction_type === "Check" ) {
      
      return <ExpenseTransaction transaction={transactionDetailQuery.data} />
    }
    if (transaction_type === "Refund") {
      
      return <RefundTransaction transaction={transactionDetailQuery.data} />
    }
    if (transaction_type === "Bill") {
      
      return <BillTransaction transaction={transactionDetailQuery.data} />
    }
    return <></>
  },[transaction_type,transactionDetailQuery.data])

  

  return (
    <div className="TransactionDetail__Container">
      <div className="TransactionDetail__Header">
        <button id="back-btn" data-test="back-btn" onClick={onClose} className="">
          <ArrowBackIosNewIcon />
          {"     "} <span>Back to Transactions Report</span>
        </button>
        <div className="TransactionDetail__Header__Title" data-test="TransactionDetail__Header__Title">
          {transaction_type}
        </div>
      </div>
      {transactionDetailQuery.data ?  TransactionViews : 
      <div className="verifyingAccess__Container">
      <CircularProgress size={150} sx={{ color: "#85b440" }} />
      <span>loading transaction detail...</span>
    </div>
      }
    </div>
  );
};

export default TransactionDetail;
