import { useContext } from "react";
import { OptionTypeBase } from "react-select";
import { AuthContext } from "../../contexts/AuthContext";
import { ModuleContext } from "../../contexts/ModuleContext";
import { showToast } from "../../utils/toastHelpers";
import SectionTitle from "./SectionTitle";
import SelectComponent from "./SelectComponent";
import { ReactComponent as Excelsvg } from '../../static/img/Excel-SVG.svg';
import PublishBtn from "./PublishBtn";
import { SocketContext } from "../../contexts/SocketContext";
import { AppModules } from "../../types/CompanyModules";
import { AsanaTask } from "../../types/AsanaTask";

interface GeneralHeaderProps {
    title: string;
    renderDivisions: boolean;
    children?: any;
    milestone?:AsanaTask;
}

export default function GeneralHeader(props: GeneralHeaderProps) {
    const { selectedCompany, companyFilter } = useContext(AuthContext);
    const { printPDFRunning } = useContext(SocketContext);
    const { setSelectedDivision, selectedDivision, appModule } = useContext(ModuleContext);
    const onSelectChange = (value: OptionTypeBase) => {
        var selectedDivision = companyFilter?.division?.filter(filter => filter.name === value.value)[0]
        if (selectedDivision)
            setSelectedDivision(selectedDivision)
    }
    if (!selectedCompany || !companyFilter) return <></>
    return (
        <>
            <header className="header-bi">
                <SectionTitle title={props.title} milestone={props.milestone}/>
                {appModule === AppModules.PITCH_DECK && selectedDivision && <PublishBtn disabled={printPDFRunning} />}
                {props.children}
                {props.renderDivisions &&
                    <>
                        <SelectComponent
                            name="Division"
                            options={companyFilter.getDivisionOptions()}
                            onChange={(value) => onSelectChange(value)}
                            isLoading={false}
                            value={companyFilter.getDivisionOptions().filter(filter => filter.value === selectedDivision?.name)}
                        />
                        {selectedDivision && <button
                            className="btn--primary btn--M--centered" onClick={() => {
                                if (selectedDivision.file_link === "") {
                                    showToast('info', 'Please contact the Admin to setup this division link.')
                                }
                                else
                                    window.open(selectedDivision.file_link, "_blank");
                            }}>
                            <Excelsvg />
                        </button>
                        }
                    </>
                }
            </header>
        </>
    )
}