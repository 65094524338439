import { employeeTableFilterOptions } from "../config/headcountConfigs";
import { getMonthDayNumber, isDateIntersectWithTwoDates, IslastMonthInQuarter, IslastMonthInYear, isSameMonthYear, startOfMonth } from "../utils/DateFunctions";
import { dateFromString } from "../utils/helpers";
import { Company, CalculationType } from "./Company";
import { CompanyFilters } from "./CompanyFilters";

export enum EmployeeType {
    FTE = 0,
    Consultant = 1,
    PT = 2
}
export enum ExpenseType {
    FTE_Salary = "1",   
    Consultant_Salary = "2",
    Part_Time_Salary = "8",
    Health_Benefits = "3",
    Payroll_Taxes = "4",
    Payroll_Processing_Fees = "5",
    Bonus = "6",
    Commission = "7",
    
}
export enum GroupType {
    Managment = 0,
    Staff = 1
}
export enum PayType {
    Salaried = 0,
    Hourly = 1
}
export enum RepeatEveryChoices {
    Monthly = 0,
    Quarterly = 1,
    Annually = 2,
}
export enum EmployeeStatus {
    Active = 0,
    Terminated = 1,
    Projected = 2,
}

interface ExpensesCalculations {
    [key: string]: (date: Date) => number;
}
export interface GridSingleSelectFilterOption {
    value: string;
    label: string;
}

export class HeadCountInformation {
    public static company_filter: CompanyFilters = new CompanyFilters();

    [key: string]: any;
    id: string = "";
    counter: number = -1;
    created: string = "";
    modified: string = "";
    first_name: string = "";
    last_name: string = "";

    salary: number = 0;
    start_date: string = "";
    end_date: string = "";

    company: number = 0;

    location: number = 0;
    department: number = 0;
    job_title: number = 0;

    type: EmployeeType = EmployeeType.FTE;
    pay_type: PayType = PayType.Salaried;
    group: GroupType = GroupType.Staff;
    status: EmployeeStatus = EmployeeStatus.Active;

    schedule_raise_amount: number = 0;
    schedule_raise_date: string = "";

    schedule_bonus_amount: number = 0;
    schedule_bonus_repeat_every: RepeatEveryChoices = RepeatEveryChoices.Monthly;

    schedule_commission_amount: number = 0;
    schedule_commission_repeat_every: RepeatEveryChoices = RepeatEveryChoices.Monthly;

    note: string = "";

    startDate: Date = new Date();
    endDate: Date | null = null;
    scheduleRaiseDate: Date | null = null;

    companyObj: Company | null;

    private ___locationStr: string = "";
    private ___departmentStr: string = "";
    private ___job_titleStr: string = "";

    constructor(company: Company | null = null, obj: HeadCountInformation | null = null) {
        if (obj) {
            Object.keys(obj).forEach(key => {
                if (key in this)
                    this[key] = obj[key]
            })
        }
        this.companyObj = company;
        var start_date = dateFromString(this.start_date)
        this.startDate = start_date ? start_date : new Date()
        this.endDate = dateFromString(this.end_date)
        this.scheduleRaiseDate = dateFromString(this.schedule_raise_date)
    }



    public get employe_name(): string {
        var first_name: string = (!this.first_name || this.first_name === "") ? "" : this.first_name
        var last_name: string = (!this.last_name || this.last_name === "") ? "" : this.last_name
        return this.counter + ". " + first_name + " " + last_name
    }
    public get all(): string {
        return "All"
    }
    public get locationStr(): string {
        if (!this.location)
            return ""
        if (!this.___locationStr)
            this.___locationStr = HeadCountInformation.company_filter.lookUpOptionValue("location", this.location.toString())
        return this.___locationStr
    }
    public get departmentStr(): string {
        if (!this.___departmentStr)
            this.___departmentStr = HeadCountInformation.company_filter.lookUpOptionValue("department", this.department.toString())
        return this.___departmentStr
    }
    public get job_titleStr(): string {
        if (!this.___job_titleStr)
            this.___job_titleStr = HeadCountInformation.company_filter.lookUpOptionValue("job_title", this.job_title.toString())
        return this.___job_titleStr
    }
    public get typeStr(): string {
        switch (this.type) {
            case EmployeeType.FTE:
                return "FTE"
            case EmployeeType.Consultant:
                return "Consultant"
            case EmployeeType.PT:
                return "Part Time"
            default:
                return "FTE"
        }
    }
    public get statusStr(): string {
        switch (this.status) {
            case EmployeeStatus.Active:
                return "Active"
            case EmployeeStatus.Projected:
                return "Projected"
            case EmployeeStatus.Terminated:
                return "Terminated"
            default:
                return "Active"
        }
    }

    public isMatching = (searchText: string): boolean => {
        if (!searchText || searchText === "")
            return true
        var searchTextReg: RegExp = new RegExp(searchText, "i")
        if (
            this.employe_name.match(searchTextReg) || this.salary.toString().match(searchTextReg) || this.statusStr.toString().match(searchTextReg) ||
            this.departmentStr.match(searchTextReg) || this.job_titleStr.match(searchTextReg) || this.locationStr.match(searchTextReg) || this.typeStr.match(searchTextReg)
        )
            return true
        return false
    }
    public calc_expense = (expenseKey: string, date: Date): number => {
        return this.__Expenses_Calculation[expenseKey](date)
    }

    public getQuarterStrings = (offset: number, date: Date): string => {
        date.setDate(1);
        date.setMonth(date.getMonth() - offset * 3);
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        var quarter = Math.ceil(month / 3);
        return ("Q" + quarter + ", " + year);
    }

    public getMonthWorkingDays = (date: Date): number => {
        var working_days: number = 0
        var total_month_day_number: number = getMonthDayNumber(date)
        // incase the user wasn't an employee in this month
        if (!isDateIntersectWithTwoDates(date, this.startDate, this.endDate))
            return working_days

        // incase this month is the same as start hiring date then, working days will equal month total number - the date of start
        if (isSameMonthYear(date, this.startDate)) {
            working_days = total_month_day_number - (this.startDate.getDate() - 1)
            return working_days
        }
        // in case this month is the same as the enddate then, the working days will equal the end date number
        if (this.endDate && isSameMonthYear(date, this.endDate)) {
            working_days = this.endDate.getDate()
            return working_days
        }
        // if this month not the same start or the end date, then working days will be the entire month day number
        else
            return total_month_day_number
    }
    public getMonthSalary = (date: Date): number => {
        var working_days: number = this.getMonthWorkingDays(date)
        // incase the employee wasn't working in this month
        if (working_days <= 0)
            return 0

        var monthlyCalculatedSalary: number = this.salary / 12;
        // in case this month after the raise applyment we have to use the raise not the salry
        if (this.schedule_raise_amount && this.scheduleRaiseDate && startOfMonth(this.scheduleRaiseDate) <= startOfMonth(date))
            monthlyCalculatedSalary = this.schedule_raise_amount / 12

        var total_month_day_number: number = getMonthDayNumber(date)
        return (monthlyCalculatedSalary * working_days) / total_month_day_number
    }

    public calcMonthBonuesAndCommission = (date: Date, amount: number, repeat_every: RepeatEveryChoices): number => {
        // incase employee doesn't have bonus
        if (amount <= 0)
            return 0

        switch (repeat_every) {
            case RepeatEveryChoices.Monthly:
                var working_days: number = this.getMonthWorkingDays(date)
                var total_month_day_number: number = getMonthDayNumber(date)
                return (amount * working_days) / total_month_day_number

            case RepeatEveryChoices.Quarterly:
                if (IslastMonthInQuarter(date, this.companyObj?.offset)) {
                    var total_quarter_working_days: number = 0
                    var total_quarter_days: number = 0
                    var quarter_months_loop = new Date(date)
                    for (let index = 0; index < 3; index++) {
                        total_quarter_working_days += this.getMonthWorkingDays(quarter_months_loop)
                        total_quarter_days += getMonthDayNumber(quarter_months_loop)
                        quarter_months_loop.setMonth(quarter_months_loop.getMonth() - 1)
                    }
                    return (amount * total_quarter_working_days) / total_quarter_days
                }
                return 0

            case RepeatEveryChoices.Annually:
                if (IslastMonthInYear(date, this.companyObj?.offset)) {
                    var total_year_working_days: number = 0
                    var total_year_days: number = 0
                    var year_months_loop = new Date(date)
                    for (let index = 0; index < 12; index++) {
                        total_year_working_days += this.getMonthWorkingDays(year_months_loop)
                        total_year_days += getMonthDayNumber(year_months_loop)
                        year_months_loop.setMonth(year_months_loop.getMonth() - 1)
                    }
                    return (amount * total_year_working_days) / total_year_days
                }
                return 0
            default:
                return 0
        }

    }

    private __Expenses_Calculation: ExpensesCalculations = {
        'FTE Salary': (date: Date) => {
            if (this.type === EmployeeType.FTE)
                return this.getMonthSalary(date)
            else
                return 0
        },
        'Consultant Salary': (date: Date) => {
            if (this.type === EmployeeType.Consultant)
                return this.getMonthSalary(date)
            else
                return 0

        },
        'Part Time Salary': (date: Date) => {
            if (this.type === EmployeeType.PT)
                return this.getMonthSalary(date)
            else
                return 0

        },
        'Health Benefits': (date: Date) => {
            if (this.type === EmployeeType.FTE && this.companyObj?.settings) {
                if (this.companyObj?.settings.hc_setting.assum_health_ben_option === CalculationType.PerPercentage)
                    return this.getMonthSalary(date) * Number(this.companyObj.settings.hc_setting.assum_health_ben)
                else
                    return Number(this.companyObj?.settings.hc_setting.assum_health_ben)

            }
            return 0;
        },
        'Payroll Taxes': (date: Date) => {
            if (this.type === EmployeeType.FTE && this.companyObj?.settings) {
                if (this.companyObj?.settings.hc_setting.assum_pay_tax_option === CalculationType.PerPercentage)
                    return (this.getMonthSalary(date) + this.calcMonthBonuesAndCommission(date, this.schedule_bonus_amount, this.schedule_bonus_repeat_every)) * Number(this.companyObj.settings.hc_setting.assum_pay_tax)
                else
                    return Number(this.companyObj.settings.hc_setting.assum_pay_tax)
            }
            return 0;
        },
        'Payroll Processing Fees': (date: Date) => {
            if (this.type === EmployeeType.FTE && this.companyObj?.settings) {
                if (this.companyObj.settings.hc_setting.assum_pay_proc_option === CalculationType.PerPercentage)
                    return this.getMonthSalary(date) * Number(this.companyObj.settings.hc_setting.assum_pay_proc)
                else
                    return Number(this.companyObj.settings.hc_setting.assum_pay_proc)

            }
            return 0;
        },
        'Bonus': (date: Date) => {
            return this.calcMonthBonuesAndCommission(date, this.schedule_bonus_amount, this.schedule_bonus_repeat_every);
        },
        'Commission': (date: Date) => {
            return this.calcMonthBonuesAndCommission(date, this.schedule_commission_amount, this.schedule_commission_repeat_every);
        },
    };

    public keyToFilterOption = (key: string): GridSingleSelectFilterOption | null => {
        if (key in this)
            return { value: this[key], label: this[key] }
        else
            return null
    }
    public static GetFilterOptionArray = (key: string, items: HeadCountInformation[]) => {
        var result: GridSingleSelectFilterOption[] = []
        var collected: Object = {}
        items.forEach((item) => {
            var filter_option = item.keyToFilterOption(key)
            if (filter_option) {
                if (!(filter_option.value in collected)) {
                    collected[filter_option.value] = filter_option.value
                    result.push(filter_option)
                }
            }
        })
        return result
    }
}

export class HeadCountInformationArray {
    public static EmployeeTableFilterOptions = employeeTableFilterOptions;
    items: HeadCountInformation[] = [];

    constructor(headCountInfo: HeadCountInformation[] = []) {
        this.items = headCountInfo

    }
    public get length(): number {
        return this.items.length
    }
    add(item: HeadCountInformation) {
        this.items.push(item);
    }
    maxStartDate(ids: string[]): Date | null {
        var max_date: Date | null = null
        this.items.forEach(item => {
            if (ids.includes(item.id.toString())) {
                var start_date: Date | null = dateFromString(item.start_date)
                if (!max_date)
                    max_date = start_date
                if (max_date && start_date && start_date > max_date)
                    max_date = start_date
            }
        });
        return max_date
    }
    minEndDate(ids: string[]): Date | null {
        var min_date: Date | null = null
        this.items.forEach(item => {
            if (ids.includes(item.id.toString())) {
                var end_date: Date | null = item.end_date ? dateFromString(item.end_date) : null
                if (end_date !== null) {
                    if (min_date === null)
                        min_date = end_date
                    else {
                        if (end_date < min_date)
                            min_date = end_date
                    }
                }
            }
        });
        return min_date
    }
}

export enum EmployeeDialogType {
    DEFAULT,
    ADD,
    BULK_ADD,
    EDIT,
    BULK_EDIT,
}