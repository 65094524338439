import { useContext, useState, SyntheticEvent } from 'react';
import { DataGridPro, GridRowParams, GridColDef, MuiEvent, useGridApiContext, useGridSelector, gridPageSelector, gridPageSizeSelector, GridRowId } from '@mui/x-data-grid-pro';
import { createStyles, makeStyles } from '@mui/styles';
import TablePagination from '@mui/material/TablePagination';
import columnsRenderFunctions from './RenderComponents';
import { ReactComponent as FilterDropdownIcon } from '../../../static/img/filter_dropdown_icon.svg';
import './tableComponent.css';
import NoTableData from '../../Common/NoTableData';
import CustomSwitch from '../../Common/CustomSwitch';
import { uppercaseAllWordLetters } from '../../../utils/helpers';
import { DataConext } from '../../../contexts/ManageUsers/DataConext';
import { usersTableDisplay, usersTableFilter, usersTableFilterOptions } from '../../../config/accountSettingsConfigs';
import { UserListConext } from '../../../contexts/ManageUsers/UserListConext';
import HideAllButton from '../../HeadCountModule/TableComponent/HideAllButton';
import { UserRole } from '../../../types/ManageUsers';


const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                '& .MuiDataGrid-root': {
                    backgroundColor: '#fafafa',
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: "bold",
                        fontSize: "11px",
                        lineHeight: "16px",
                        color: "#666687",
                    },
                    '& .MuiDataGrid-iconSeparator': {
                        height: "56px",
                        borderLeft: "1px solid #F6F6F9"
                    },
                    '& .MuiDataGrid-columnHeader--sortable': {
                        '& .MuiDataGrid-iconButtonContainer': {
                            visibility: 'visible',
                            width: "auto"
                        },
                    },
                    '& .MuiDataGrid-columnHeader': {
                        '& .MuiDataGrid-columnHeaderTitleContainer': {
                            flexDirection: 'row',
                        },
                        '& .MuiDataGrid-columnHeaderDraggableContainer': {
                            flexDirection: 'row',
                            '& .MuiDataGrid-menuIcon': {
                                visibility: 'visible',
                                marginRight: '2px',
                                width: 'auto !important'
                            }
                        }
                    }
                },
                '& .MuiDataGrid-row': {
                    '& :hover': {
                        cursor: 'pointer',
                    },
                    '&.Mui-selected': {
                        backgroundColor: '#85b4401A !important',
                        '& :hover': {
                            backgroundColor: '#85b4401A !important',
                        },
                    },
                },
                '& .MuiDataGrid-cell': {
                    color: '#666687',
                    fontSize: 14,
                    borderRight: "1px solid #F6F6F9",
                    justifyContent: 'flex-start',
                    '& span': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }
                },
                '& .MuiCheckbox-root': {
                    color: '#C0C0CF',
                },
                '& .MuiCheckbox-root >svg': {
                    border: "0px solid #FFFFFF",
                    height: "20px",
                    width: "20px"
                },
                '& .MuiCheckbox-root.Mui-checked': {
                    color: '#85b440 !important',
                },
                '& .DataGrid-marked-row--true': {
                    backgroundColor: '#FDF4DC',
                },
            }
        }));

export default function DataGridComponent() {
    const classes = useStyles();
    const { selectedFlatRows, setSelectedFlatRows, openEditDialoge } = useContext(UserListConext);
    const { userListDisplay } = useContext(DataConext);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const headCells: GridColDef[] = usersTableDisplay.map(key => {
        const cellRenerItem = columnsRenderFunctions[key];
        return {
            field: usersTableFilter.includes(key) ? key + "Str" : key,
            headerName: uppercaseAllWordLetters(key),
            renderCell: cellRenerItem,
            type: usersTableFilterOptions.includes(key) ? 'singleSelect' : 'string',
            flex: (key === 'locations' || key === 'divisions' || key === 'departments') ? 2 : 1,
        }
    });


    function CustomPagination() {
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

        return (
            <TablePagination
                rowsPerPageOptions={[10, 30, 70, 100]}
                component="div"
                colSpan={3}
                page={page}
                count={userListDisplay.length}
                rowsPerPage={pageSize}
                labelRowsPerPage="Entries per page"
                onPageChange={(event, value) => {
                    apiRef.current.setPage(value);
                }}
                onRowsPerPageChange={(event) => {
                    apiRef.current.setPageSize(parseInt(event.target.value, 10));
                    setRowsPerPage(parseInt(event.target.value, 10));
                }}
                sx={{
                    '& .MuiTablePagination-toolbar': {
                        paddingRight: 3,
                    },
                    '& .MuiTablePagination-selectLabel': {
                        mb: 0,
                        fontSize: 12,
                        lineHeight: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        color: '#8E8EA9'
                    },
                    '& .MuiTablePagination-displayedRows': {
                        mb: 0,
                    },
                    '& .MuiTablePagination-select': {
                        paddingRight: '24px',
                        minWidth: "16px",
                        fontSize: "1.3rem",
                        lineHeight: "2.3rem",
                        alignSelf: 'center',
                        '&.MuiTablePagination-select': {
                            backgroundColor: "#FFFFFF",
                            border: "1px solid #EAEAEF",
                            borderRadius: " 4px",
                        }
                    },
                }}
                SelectProps={{
                    MenuProps: {
                        BackdropProps: {
                            invisible: true,
                            className: 'pagination-list-background',
                        },
                    }
                }}
            />
        );
    }

    return <div className="tableContainer__SettingsModule">
        <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1, height: "100%" }} className={classes.root}>
                <DataGridPro
                    columns={headCells}
                    rows={userListDisplay}
                    getRowClassName={(params) => {
                        return `DataGrid-marked-row--${params.row.new}`
                    }}
                    disableSelectionOnClick
                    disableColumnPinning
                    disableColumnFilter
                    disableChildrenSorting
                    showColumnRightBorder
                    showCellRightBorder
                    localeText={{
                        columnMenuSortAsc: 'Sort A to Z ↑',
                        columnMenuSortDesc: 'Sort Z to A ↓',
                        columnsPanelHideAllButton: <HideAllButton />,
                    }}
                    onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
                    onSelectionModelChange={(newSelectionModel) => {                        
                        const currentUserManager = userListDisplay.find(user => user.current_user)
                        if(currentUserManager && currentUserManager.role === UserRole.Admin) {
                            const usersIds: GridRowId[] = userListDisplay.filter(user => user.role === UserRole.User).map(user => user.id as GridRowId)
                            setSelectedFlatRows(newSelectionModel.filter(item => usersIds.includes(item)).map(item => item.toString()));
                        } else {
                            setSelectedFlatRows(newSelectionModel.filter(item => item > -1).map(item => item.toString()));
                        }
                        
                    }}
                    selectionModel={selectedFlatRows.filter(item => parseInt(item) > 0).map(item => parseInt(item))}
                    onRowClick={(params: GridRowParams, event: MuiEvent<SyntheticEvent<Element, Event>>) => {
                        event.defaultMuiPrevented = true;
                        if ((event.target as HTMLInputElement).type === 'checkbox')
                            return
                        openEditDialoge(params.row.id);
                    }}
                    components={{
                        NoRowsOverlay: NoTableData,
                        BaseSwitch: CustomSwitch,
                        Pagination: CustomPagination,
                        ColumnMenuIcon: FilterDropdownIcon
                    }}
                    pageSize={rowsPerPage}
                    pagination={userListDisplay.length > 0}
                    rowsPerPageOptions={[10, 30, 70, 100]}
                    checkboxSelection={userListDisplay.length > 0}
                />
            </div>
        </div>
    </div>
}