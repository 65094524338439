import React from "react";
import RichTextEditor from "../../../../../Common/RichTextEditor";
import AssigneeSkeleton from "../../../../../Common/Skeletons/AssigneeSkeleton";
import AssigneeMenu from "../../../../../Common/DropDownComponent/Assignee";
import CloseIcon from "@mui/icons-material/Close";

const CommentEditView = ({ description, setDescription, team, editCommentMutation, comment }) => {
  const onChangeAssignee = (updatedTask) => {
    editCommentMutation.mutate({ asanaTaskID: comment?.asana_task?.gid, updateObj: { assignee: updatedTask.assignee.gid } });
  };
  const onClearAssignee = () => {
    editCommentMutation.mutate({ asanaTaskID: comment?.asana_task?.gid, updateObj: { assignee: null } });
  };
  return (
    <div className="MonthEndComments__Comment__Body">
      <RichTextEditor htmlValue={description} setHTMLValue={setDescription} onChange={(value) => setDescription(value)} people={team} placeholder={"Add comment here..."}/>
      <div className="AssigneeEdit__Container">
        <span>Assign to</span>
        {editCommentMutation.isLoading ? <AssigneeSkeleton /> : <AssigneeMenu task={comment?.asana_task} setTask={onChangeAssignee} size={21} optionsSize={21} />}
        {comment?.asana_task?.assignee?.name === "" || comment?.asana_task?.assignee === null || comment?.asana_task?.assignee?.name === undefined ? (
          <span className="placeholder">Add Assignee</span>
        ) : (
          <span className="SelectedAssigneeName">
            {comment?.asana_task?.assignee?.name}
            <CloseIcon onClick={() => onClearAssignee()} />
          </span>
        )}
      </div>
      
    </div>
  );
};

export default CommentEditView;
