import React from "react";
import "./style.css";
import termsOfServiceImage from "../../../static/img/terms-of-service.png";
import mdLogoHeader from "../../../static/img/md-logo-card-header.png";
const TermsOfService = () => {
  return (
    <div className="AppInformation__Container">
      <div className="AppInformation__Container__CenterCard">
        <img className="AppInformation__Container__MDLogoImage" src={mdLogoHeader} alt="" />
        <img className="AppInformation__Container__BannerImage" src={termsOfServiceImage} alt="" />
        <div className="AppInformation__Container__CenterCard__TextContent">
          <span style={{ color: "#8e8ea9", fontSize: "1.4rem", fontWeight: "700", marginTop: "3.9rem" }}>This Terms of Service was last modified June 28, 2022.</span>

          <div className="AppInformation__Container__CenterCard__TextContent__Bold">
          These terms of service (the "<u>Terms of Service</u>") are a legal agreement between you and Mighty Digits LLC (“<u>Mighty</u>”, “<u>Mighty Digits</u>”, “<u>we</u>,” “<u>us</u>,” or “<u>our</u>”).  
          These Terms of Service specify the terms under which you may access and use our website located at www.mightydigits.com (the “<u>Site</u>”) and the services 
          (the Site with the services, collectively the “<u>Services</u>”).
          </div>
          <div className="AppInformation__Container__CenterCard__TextContent__Bold">
          PLEASE READ THESE TERMS OF SERVICE CAREFULLY BEFORE ACCESSING AND USING THE SERVICES OR ANY PORTION THEREOF, BECAUSE BY USING ANY OF THE SERVICES, CLICKING “I AGREE,” OR OTHERWISE MANIFESTING YOUR ASSENT TO THESE TERMS OF SERVICE, YOU AGREE TO BE BOUND BY THESE TERMS OF SERVICE AND OUR <u><a href={`${window.location.origin}/privacy-policy`}>PRIVACY POLICY</a></u> (THE “<a href={`${window.location.origin}/privacy-policy`}>PRIVACY POLICY</a> ”, TOGETHER WITH THE TERMS OF SERVICE, THE “<u>AGREEMENT</u>”), WHICH IS HEREBY INCORPORATED BY REFERENCE.  IF YOU DO NOT AGREE TO (OR CANNOT COMPLY WITH) ALL OF THE TERMS OF THESE TERMS OF SERVICE, DO NOT ACCESS OR USE THE SERVICES.  
          </div>

          <br />
          <div className="AppInformation__Container__CenterCard__TextContent__Bold">
          THE SERVICES, CONTENT, SITE AND DELIVERABLES (AS DEFINED BELOW) THAT ARE PROVIDED TO YOU ARE INTENDED FOR YOUR INTERNAL BUSINESS USE ONLY AND ARE NOT GAAP ASSURED. MIGHTY IS NOT A CERTIFIED PUBLIC ACCOUNTING FIRM, AND MIGHTY EXPRESSLY DISCLAIMS ANY RELIANCE BY YOU ON THE SERVICES, CONTENT, SITE, OR DELIVERABLES BEING COMPLIANT WITH GAAP OR ANY OTHER ACCOUNTING STANDARDS. 
          </div>
          <div className="AppInformation__Container__CenterCard__TextContent__Bold">
          THE SECTIONS BELOW TITLED “BINDING ARBITRATION” AND “CLASS ACTION WAIVER” CONTAIN A BINDING ARBITRATION AGREEMENT, AND CLASS ACTION WAIVER.  THEY AFFECT YOUR LEGAL RIGHTS.  PLEASE READ THEM.
          </div>
          <br />
          <div className="AppInformation__Container__CenterCard__TextContent__Paragraph">
          If you accept or agree to this Agreement on behalf of a company or other legal entity, you represent and warrant that you have the authority to bind that company or other legal entity to the Agreement and, in such event, “you” and “your” will refer and apply to that company or other legal entity.  
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default TermsOfService;
