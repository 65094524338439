import { useNavigate } from 'react-router-dom';
import '../Auth/LoggedUserInvitation/LoggedUserInvitation.css';
import success_people_celebrating from '../../static/img/success_people_celebrating.png';
export default function InvitationWelcome() {
    const navigate = useNavigate();
    return (
        <div className="successContainer">
            <img src={success_people_celebrating} alt="success!" />
            <h1>Thanks! You have successfully joined&nbsp;<span>{"your company"}</span>&nbsp;team</h1>
            <h4>If you have any questions, please contact &nbsp;<a href="mailto:support@mightydigits.com">support@mightydigits.com</a>.</h4>
            <button onClick={() =>  navigate('/')}>Go to your account</button>
        </div>
    )
}