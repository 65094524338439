import React, { useContext, useEffect, useState } from "react";
import FolderPath from "../../../../Common/SharepointExplorer/FolderPath";
import { useDeleteLink, useEditLink, usePinLink } from "./Hooks";
import BasicMenu from "../../../../Common/DropDownComponent/TaskMoreActions";
import SharepointExplorerGetFileLink from "../../../../Common/SharepointExplorer/SharepointExplorerGetFileLink";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { SharepointFileInfo } from "../../../../../types/Shareppoint";
import { getSharepointInfoObj } from "../Util";
import MDIcons from "../../../../Common/MDIcons";
import { MilestoneEmailContext } from "../../../../../contexts/TicketingSystem/MilestoneEmailContext";
import ScrollContainer from "react-indiana-drag-scroll";
import CustomTooltip from "../../../../Common/Tooltip";
import LinkOptiosMenu from "./LinkOptionsMenu";
import LinkOptionsMenu from "./LinkOptionsMenu";

const LinksTablsRow = ({ link }) => {
  const { email } = useContext(MilestoneEmailContext);
  const { selectedCompany } = useContext(AuthContext);
  const [displaySharepointBrowser, setDisplaySharepointBrowser] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [selectedFolder, setSelectedFolder] = useState<any>(null);

  const deleteMutation = useDeleteLink(link?.id);
  const editLinkMutation = useEditLink(link?.id);
  const pinLinkMutation = usePinLink(link?.id)

  const onCloseBrowser = () => {
    setDisplaySharepointBrowser(false);
  };

  const folderPath = () => {
    // remove everything before "root:,"
    const array = link.sharepoint_file_info?.folder_path?.split("root:,")[1]?.split(",");
    return array;
  };

  const editLink = () => {
    const sharepoint_file_info: SharepointFileInfo = getSharepointInfoObj(selectedFile, selectedFolder);

    if (link) {
      editLinkMutation.mutate(sharepoint_file_info);
    }
  };

  const pinItems = () => {
    pinLinkMutation.mutate({
      pinned:!link.pinned,
      company_id: selectedCompany?.id,
      email_id: email?.id
    })
  }


  useEffect(() => {}, []);

  useEffect(() => {
    if (selectedFile) {
      editLink();
    }
  }, [selectedFile]);

  return (
    <>
      <div className="LinksTable__Row--inTable">
      
        <div className="LinksTable__Row__Left">
          <MDIcons title="Explorer_file_small" />
          <CustomTooltip
        title={
          <React.Fragment>
            {link.sharepoint_file_info?.file_name}
          </React.Fragment>
        }
      >
          <a href={link.sharepoint_file_info?.web_url} rel="noreferrer" target="_blank">
            {link.sharepoint_file_info?.file_name ?? ""}
          </a>
          </CustomTooltip>
        </div>
        <ScrollContainer className="LinksTable__Row__Right" hideScrollbars={false}>
          <FolderPath folderPathArray={folderPath()} />
        </ScrollContainer>
        {link.pinned && <MDIcons title="Pin" />}
        <LinkOptionsMenu deleteItem={() => deleteMutation.mutate()} editItem={() => setDisplaySharepointBrowser(true)} pinItem={() => pinItems()} pinned={link.pinned} type="link" />
      </div>
      {displaySharepointBrowser && (
        <SharepointExplorerGetFileLink
          onCloseBrowser={onCloseBrowser}
          displaySharepointBrowser={displaySharepointBrowser}
          company={selectedCompany}
          setDisplaySharepointBrowser={setDisplaySharepointBrowser}
          setSelectedFile={setSelectedFile}
          setSelectedFolder={setSelectedFolder}
        />
      )}
    </>
  );
};

export default LinksTablsRow;
