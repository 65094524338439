import { useContext, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { ModuleContext } from '../../contexts/ModuleContext';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { SocketContext } from '../../contexts/SocketContext';
import SyncConfirmDialog from './SyncConfirmDialog';

export default function SyncExcelFile() {
  const { SyncSelectedDivisionExcelFile } = useContext(ModuleContext)
  const { isTrackingLogicAppStatus } = useContext(SocketContext)
  const [isOpen, setIsOpen] = useState(false);

  const applySync = () => {
    setIsOpen(false)
    SyncSelectedDivisionExcelFile()
  }

  const showtoolTip = (isTrackingLogicAppStatus)

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);
  return (
    <>
      <div data-tip={showtoolTip ? "Please wait until the current syncing is done" : ""}>
        <button
          disabled={isTrackingLogicAppStatus}
          className="btn--primary btn--M--centered"
          data-tip="Sync to Excel Model"
          onClick={() => {
            setIsOpen(true)
          }}>
          <AutorenewIcon sx={{ width: 17, height: 17 }} />
        </button>
      </div>
      <SyncConfirmDialog
        text={"Are you sure you want to Sync your Mighty Digits App with your Excel Model?"}
        open={isOpen} onCancel={() => { setIsOpen(false) }} onYes={() => applySync()} />
    </>
  );
};