import React, { useContext, useEffect, useState } from 'react';
import {
  addTaskToSection,
  createAsanaSubTask,
  updateAsanaTask,
} from '../../APIFunctions/Asana';
import { dateFromString } from '../../utils/helpers';
import { attachmentOptions } from './utils';

import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AsanaAPIManager from '../../APIManger/Asana';
import './tasks.css';
import {
  removeAsanaAttachment,
  retrieveAsanaAttachment,
  uploadAsanaAttachment,
} from '../../APIFunctions/Asana';
import MDIcons from '../Common/MDIcons';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import CloseIcon from '@mui/icons-material/Close';

import SelectComponent from '../Common/SelectComponent';
import CustomDatePicker from '../Common/CustomDatePicker';
import { Divider } from '@mui/material';
import SubTask from './SubTask';
import Comment from './Components/Comment';
import AssigneeMenu from '../Common/DropDownComponent/Assignee';
import { TaskCardContext } from '../../contexts/Task/TaskCardContext';
import { TaskTableContext } from '../../contexts/Task/TaskTableContext';
import { TaskServicesContext } from '../../contexts/Task/TaskServicesContext';
import { useEditAsanaTask } from '../TicketingSystem/hooks/useAsanaTask';
import RichTextEditor, { convertQuillHTMLToAsana, convertQuillMentionsToAsana } from '../Common/RichTextEditor';

function TaskContent() {
  const { customFields, team } = useContext(TaskServicesContext);
  const asanaTaskMutation = useEditAsanaTask();
  const {
    tasks,
    setTasks,
    setTimeAtLastTaskUpdate,
    taskDetail,
    setTaskDetail,
    parents,
    retrieveAttachments,
    attachments,
    subTasks,
    setTaskCardLoading,
    setSubTasks,
    taskStories,
    retrieveSubTasks,
    retrieveStories,
    setTaskStories,
    setAttachments,
  } = useContext(TaskTableContext);
  const {
    sectionsOptions,
    onDateChange,
    setTaskUpdateObj,
    taskUpdateObj,
    onClearAssignee,
    onClearDueDate,
    openParent,
  } = useContext(TaskCardContext);
  const [showAllComment, setShowAllComment] = useState<boolean>(false);
  const [taskDetailLocal, setTaskDetailLocal] = useState(taskDetail);
  const [description, setDescription] = useState<string>("");

  

  const updateSubTaskInAsana = async (updatedTask) => {
    await asanaTaskMutation.mutate({ gid: updatedTask.gid, assignee: updatedTask.assignee?.gid })
  };

  const onShowComments = () => {
    setShowAllComment(!showAllComment);
  };


  const removeAttachment = async (gid) => {
    setTaskCardLoading(true);
    await removeAsanaAttachment(gid);
    await retrieveAttachments();
    setTaskCardLoading(false);
  };

  const downloadAttachment = async (gid) => {
    const downloadURL = (await retrieveAsanaAttachment(gid)).data.download_url;
    await AsanaAPIManager.download(downloadURL);
  };

  const onUpdateTaskDetailState = (e) => {
    const field = e.target.name;
    const updatedTask = { ...taskDetail };
    updatedTask[field] = e.target.value;
    setTaskDetail(updatedTask);
  };

  const onAutoUpdateTask = (e) => {
    const field = e.target.name;
    const updatedTask = { ...taskDetailLocal };
    updatedTask[field] = e.target.value;
    setTaskDetailLocal(updatedTask);
    if (taskUpdateObj.hasOwnProperty('gid')) {
      setTaskUpdateObj({
        ...taskUpdateObj,
        gid: taskDetailLocal.gid,
        [field]: updatedTask[field],
      });
    } else {
      setTaskUpdateObj({
        gid: taskDetailLocal.gid,
        [field]: updatedTask[field],
      });
    }
  };

  const onChangeDescription = (value) => {
    setDescription(value)
    const asana_format_description = convertQuillHTMLToAsana(convertQuillMentionsToAsana(value));
    const updatedTask = { ...taskDetailLocal };
    updatedTask['html_notes'] = asana_format_description;
    setTaskDetailLocal(updatedTask);
    if (taskUpdateObj.hasOwnProperty('gid')) {
      setTaskUpdateObj({
        ...taskUpdateObj,
        gid: taskDetailLocal.gid,
        'html_notes': updatedTask['html_notes'],
      });
    } else {
      setTaskUpdateObj({
        gid: taskDetailLocal.gid,
        'html_notes': updatedTask['html_notes'],
      });
    }

  }

  const onCustomFieldChange = (value, fieldName) => {
    const taskCustomFields = taskDetail.custom_fields;
    const targetCustomFieldIndex = taskCustomFields.findIndex(
      (x) => x.name === fieldName
    );
    const enum_value = taskCustomFields[
      targetCustomFieldIndex
    ].enum_options.find((x) => x.gid === value);
    if (enum_value) {
      const customField = {
        ...taskCustomFields[targetCustomFieldIndex],
        enum_value: enum_value,
      };
      taskCustomFields[targetCustomFieldIndex] = customField;
      setTaskDetail({ ...taskDetail, custom_fields: taskCustomFields });
    } else {
      const customField = {
        ...taskCustomFields[targetCustomFieldIndex],
        enum_value: '',
      };
      taskCustomFields[targetCustomFieldIndex] = customField;
      setTaskDetail({ ...taskDetail, custom_fields: taskCustomFields });
    }
    const custom_fields = {
      [customFields.find((x) => x.name === fieldName).gid]: value,
    };
    updateAsanaTask({ gid: taskDetail.gid, custom_fields });
    setTimeAtLastTaskUpdate(new Date().getTime());
  };

  const onChangeAssignee = (updatedObj) => {
    setTaskDetail(updatedObj)
    updateSubTaskInAsana(updatedObj);
  };

  const handleFileSelect = async (event) => {
    const selectedFile = event.target.files[0];
    setTaskCardLoading(true);
    if (selectedFile && selectedFile.size <= 9000000) {
      const response = await uploadAsanaAttachment(
        taskDetail.gid,
        selectedFile
      );
      if (response.data) {
        await retrieveAttachments();
        setTaskCardLoading(false);
      } else if (response.response?.status === 401) {
        setTimeout(async () => {
          await uploadAsanaAttachment(taskDetail.gid, selectedFile);
          await retrieveAttachments();
          setTaskCardLoading(false);
        }, 1000);
      }
    }
  };

  const customFieldEnumOptionsArray = (name) => {
    const custom_field = customFields.find((x) => x.name === name);
    if (custom_field) {
      const options: Array<{ value: string; label: string }> =
        custom_field.enum_options?.map((x) => ({
          value: x.gid,
          label: x.name,
        }));

      if (options) {
        return [{ value: '', label: '-' }, ...options];
      } else {
        return [{ value: '', label: '-' }];
      }
    } else {
      return [{ value: '', label: '-' }];
    }
  };

  const selectedCustomFieldValue = (name) => {
    var labelIndex = -1;
    const custom_field = taskDetail.custom_fields?.find((x) => x.name === name);

    if (
      'custom_fields' in taskDetail &&
      taskDetail.custom_fields?.length > 0 &&
      custom_field?.enum_value !== null
    ) {
      labelIndex = custom_field?.enum_options?.findIndex(
        (x) => x.gid === custom_field?.enum_value?.gid
      );
    }
    if (labelIndex !== undefined && labelIndex !== -1) {
      return custom_field?.enum_options[labelIndex];
    } else {
      return { value: '', label: '' };
    }
  };

  const onSectionChange = (value) => {
    const memberships = [{ section: { gid: value.value, name: value.label } }];
    const updatedTask = { ...taskDetail, memberships };
    setTaskDetail(updatedTask);
    addTaskToSection(taskDetail.gid, value.value);
    setTimeAtLastTaskUpdate(new Date().getTime());
    const newTasksArray = tasks.map((x) =>
      x.gid === taskDetail.gid ? updatedTask : x
    );
    setTasks(newTasksArray);
  };

  const addSubTask = async () => {
    const newSubTaskObj = {
      data: {},
    };
    setTaskCardLoading(true);
    const response = await createAsanaSubTask(taskDetail.gid, newSubTaskObj);
    if (response.data) {
      const newSubTaskGID = response.data.gid;
      setTaskCardLoading(false);
      newSubTaskObj.data['gid'] = newSubTaskGID;
      const newSubTasksArray = [...subTasks];
      newSubTasksArray.push(newSubTaskObj.data);
      setSubTasks(newSubTasksArray);
      setTimeAtLastTaskUpdate(new Date().getTime());
    } else if (response.response?.status === 401) {
      setTimeout(() => {
        addSubTask();
      }, 1000);
    }
  };

  useEffect(() => {}, [customFields, sectionsOptions]);

  useEffect(() => {
    retrieveStories();
    retrieveSubTasks();
    retrieveAttachments();
    setDescription(taskDetail?.html_notes.replace("</body>", "").replace("<body>", "").replaceAll("\n", "<br/>"))
    // TimeAgo.addDefaultLocale(en)
    if (taskUpdateObj.hasOwnProperty('gid')) {
      setTaskUpdateObj({
        ...taskUpdateObj,
        gid: taskDetailLocal.gid,
        due_on: taskDetail.due_on,
      });
    } else {
      setTaskUpdateObj({
        gid: taskDetailLocal.gid,
        due_on: taskDetail.due_on,
      });
    }

    return () => {
      setTaskStories([]);
      setSubTasks([]);
      setAttachments([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="Parents__Container">
        {parents.length > 0 &&
          parents.map((parent) => (
            <div key={parent.gid} onClick={() => openParent(parent.gid)}>
              {parent.name}
              <span>
                <ArrowForwardIosIcon />
              </span>
            </div>
          ))}
      </div>
      <TextareaAutosize
        aria-label="empty textarea"
        placeholder="Insert Task Name ..."
        style={{ width: '100%', padding: 10 }}
        minRows={1}
        className="TaskCard__Title"
        value={taskDetailLocal.name}
        name="name"
        onChange={(e) => onAutoUpdateTask(e)}
        onBlur={(e) => onUpdateTaskDetailState(e)}
      />
      <div className="TaskCard__Container">
        <div className="TaskCard__Line">
          <div className="TaskCard__Label">Assignee</div>
          <AssigneeMenu  task={taskDetail} setTask={onChangeAssignee} size={36} optionsSize={27}/>
          {taskDetail.assignee?.name === '' ||
          taskDetail.assignee === null ||
          taskDetail.assignee?.name === undefined ? (
            <span className="placeholder">Add Assignee</span>
          ) : (
            <span className="SelectedAssigneeName">
              {taskDetail.assignee?.name}
              <CloseIcon onClick={() => onClearAssignee()} />
            </span>
          )}
        </div>
        <div className="TaskCard__Line">
          <div className="TaskCard__Label">Due On</div>

          <CustomDatePicker
            selected={
              taskDetail.due_on ? dateFromString(taskDetail.due_on) : null
            }
            onChange={(date: Date) => onDateChange(date, 'due_on')}
            dateFormat="MM-dd-yyyy"
            showYearDropdown
            showMonthYearPicker={false}
          />
          {taskDetail.due_on && <CloseIcon onClick={() => onClearDueDate()} />}
        </div>

        {customFields.map((x) => {
          if (x.type === 'enum') {
            return (
              <div className={`TaskCard__Line customField`}>
                <div className="TaskCard__Label">{x.name}</div>
                <SelectComponent
                  name={x.name}
                  options={customFieldEnumOptionsArray(x.name)}
                  onChange={(value) => onCustomFieldChange(value.value, x.name)}
                  isLoading={false}
                  internalLabel={true}
                  isSearchable={false}
                  value={{
                    value: selectedCustomFieldValue(x.name).gid,
                    label: selectedCustomFieldValue(x.name).name,
                  }}
                />
              </div>
            );
          }
        })}

        {taskDetail.memberships?.length > 0 && (
          <div className={`TaskCard__Line`}>
            <div className="TaskCard__Label">Section</div>
            <SelectComponent
              name={'Section'}
              options={sectionsOptions}
              onChange={(value) => onSectionChange(value)}
              isLoading={false}
              internalLabel={true}
              isSearchable={false}
              value={{
                value: taskDetail.memberships[0].section.gid,
                label: taskDetail.memberships[0].section.name,
              }}
            />
          </div>
        )}

        <div className="flex_col">
          <div className="TaskCard__Label">Description</div>

          {/* <TextareaAutosize
            aria-label="empty textarea"
            placeholder="Insert Task Description ..."
            style={{ width: '100%', padding: 10 }}
            className="TaskCard__Description"
            value={taskDetailLocal.notes}
            name="notes"
            onBlur={(e) => onUpdateTaskDetailState(e)}
            onChange={(e) => onAutoUpdateTask(e)}
          /> */}
          <RichTextEditor htmlValue={description} setHTMLValue={setDescription} onChange={(value) => onChangeDescription(value)} people={team} placeholder={"Type email body..."}/>
        </div>

        {/* display attachments containers */}
        <div className="TaskCard__Attachments__Area">
          {attachments?.length > 0 &&
            attachments.map((attachment) => (
              <div
                className="TaskCard__Attachments__Area__Container"
                key={attachment.gid}
              >
                <MDIcons title="Files-icon" />
                <span className="TaskCard__Attachments__Area__Container__Name">
                  {attachment?.name}
                </span>
                <SelectComponent
                  name={'attachment-actions'}
                  options={attachmentOptions}
                  onChange={(value) => {
                    value.value === 'Download Attachment'
                      ? downloadAttachment(attachment.gid)
                      : removeAttachment(attachment.gid);
                  }}
                  isLoading={false}
                  internalLabel={true}
                  isSearchable={false}
                  value={{ value: '', label: '' }}
                />
              </div>
            ))}
          {attachments?.length > 0 && (
            <label
              id=""
              className="TaskCard__Attachments__Area__Container dashed-border success"
            >
              <MDIcons title="add_taskAttachment_Button" />
              <input id="uploadFile" type="file" onChange={handleFileSelect} />
            </label>
          )}
        </div>

        {/* Sub Tasks Section */}

        <div className="TaskCard__SubTasksContainer">
          {subTasks?.length > 0 && (
            <div className="TaskCard__Label">Subtasks</div>
          )}
          <div className="TaskCard__SubTasksContainer__Body">
            {subTasks &&
              subTasks.map((subtask) => (
                <SubTask key={subtask.gid} subtask={subtask} />
              ))}
            {parents.length <= 4 && (
              <div
                id=""
                className="btn--tertiary btn--S flex-row--S addSubTask__Button success"
                onClick={addSubTask}
              >
                <AddIcon />
                <span>Add Subtask</span>
              </div>
            )}
          </div>
        </div>
      </div>

      <Divider />

      <div className="TaskCard__StoriesContainer">
        {taskStories && taskStories.length > 5 && showAllComment ? (
          <>
            {taskStories.slice(0, taskStories.length - 5).map((story) => (
              <Comment key={story.gid} story={story} taskStories={taskStories} setTaskStories={setTaskStories}/>
            ))}
            <div
              className="TaskCard__StoriesContainer__toggleStories"
              onClick={onShowComments}
            >
              {taskStories.length > 5
                ? 'hide previous ' + (taskStories.length - 5) + ' stores'
                : ''}
            </div>
          </>
        ) : (
          <>
            <div
              className="TaskCard__StoriesContainer__toggleStories"
              onClick={onShowComments}
            >
              {taskStories.length > 5
                ? 'show all previous ' + (taskStories.length - 5) + ' stories'
                : ''}
            </div>
          </>
        )}
        {taskStories &&
          taskStories
            .slice(-5)
            .map((story) => <Comment key={story.gid} story={story} taskStories={taskStories} setTaskStories={setTaskStories}/>)}
      </div>
    </>
  );
}

export default TaskContent;
