import React, { useContext, useState } from "react";
import { MonthEndTaskType, getMonthEndTaskType } from "../../../Tasks/utils";
import { MilestoneContext } from "../../../../contexts/TicketingSystem/MilestoneContext";
import { MilestoneEmailContext } from "../../../../contexts/TicketingSystem/MilestoneEmailContext";
import MDIcons from "../../../Common/MDIcons";
import { Close } from "@mui/icons-material";
import AlertDialog from "../../../Common/AlertDialog";

const StageApproveRejectMonthEndClose = ({ allCompleted, allAccepted, allReviewed, revisionSelected, onSubmitRequestRevision }) => {
  const { currentStage, onComplete, prevStage } = useContext(MilestoneContext);
  const { setEmailReadyToSendFlag } = useContext(MilestoneEmailContext);

  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false)

  const onShowConfirmDialog = () => {
    setShowConfirmDialog(true)
  }

  const onCloseConfirmDialog = () => {
    setShowConfirmDialog(false)
  }


  return (
    <>
    <div className={"completeButton"}>
        <div className="flex-row">
          <button
            id=""
            className="btn--tertiary btn--S flex-row--S success"
            onClick={getMonthEndTaskType(currentStage?.name) === MonthEndTaskType.FINAL_REVIEW ? () => onShowConfirmDialog() : () => onComplete()}
            // disabled={currentStage?.completed === true || (getMonthEndTaskType(currentStage?.name) === MonthEndTaskType.MANAGER_REVIEW && (!allAccepted() || !allReviewed())) || !allCompleted()}
          >
            <MDIcons title="check_button" />
            <span>
              {/* SCENARIO: USER IS ASSIGNED THE CURRENT STAGE */}
              {getMonthEndTaskType(currentStage?.name) === MonthEndTaskType.STAFF_PREPARATION
                ? "Submit for review"
                : getMonthEndTaskType(currentStage?.name) === MonthEndTaskType.MANAGER_REVIEW
                ? prevStage?.completed === true
                  ? "Send to Senior Manager for Review"
                  : "Waiting on Staff Preparation"
                : getMonthEndTaskType(currentStage?.name) === MonthEndTaskType.FINAL_REVIEW
                ? prevStage?.completed === true
                  ? "Mark Final Approval"
                  : "Waiting on Senior Manager Approval"
                : getMonthEndTaskType(currentStage?.name) === MonthEndTaskType.SEND_TO_CLIENTS
                ? prevStage?.completed === true
                  ? "Send Financials to client"
                  : "Waiting on Client Approval"
                : "Mark Complete"}
            </span>
          </button>

          {/* REJECT */}
          {(getMonthEndTaskType(currentStage?.name) === MonthEndTaskType.MANAGER_REVIEW || getMonthEndTaskType(currentStage?.name) === MonthEndTaskType.FINAL_REVIEW) && (
            <button id="danger" className="btn--tertiary btn--S flex-row--S" onClick={onSubmitRequestRevision} disabled={!revisionSelected()}>
              <Close />
              <span>Request Revision</span>
            </button>
          )}
        </div>

    </div>
      <AlertDialog onClose={onCloseConfirmDialog} open={showConfirmDialog} title={"Do you want to send Month End Close email as well?"} dialogType="alert"
      dialogActions={
        <div className="flex-row-space-between">
          <button className="btn--tertiary btn--M btn--fullWidth" onClick={() => {onCloseConfirmDialog(); onComplete();}}>
            Just Mark Completed
          </button>
            <button style={{ marginLeft: "1rem" }} className="btn--primary btn--M btn--fullWidth" onClick={() => {setEmailReadyToSendFlag("move-stage"); onCloseConfirmDialog()}}>
              <span>View Email Dialog</span>
            </button>
        </div>
      }
      >

      </AlertDialog>
    </>
  );
};

export default StageApproveRejectMonthEndClose;
