import { Flip, toast } from 'react-toastify';
import { TypeOptions } from 'react-toastify/dist/types';
import CustomToast from '../components/Common/CutomToast';
import UpdateToast from '../components/Common/UpdateToast';
import ToastCloseButton from '../components/Common/ToastCloseButton';
import PDFCreateToast from '../components/Common/PDFCreateToast';
import PDFCreateSuccessToast from '../components/Common/PDFCreateSuccessToast';
import QBOExportCreateSuccessToast from '../components/Common/QBOExportCreateSuccessToast';
import QBOExportCreateFailureToast from '../components/Common/QBOExportCreateFailureToast';

export const showToast = (type: string, message: string, title: string = "") => {
    if (type.toLowerCase() === 'success') return toast.success(<CustomToast title={title ?? "Success"} message={message} />,)
    else if (type.toLowerCase() === 'error') return toast.error(<CustomToast title={title ?? "Error"} message={message} />)
    else if (type.toLowerCase() === 'info') return toast.info(<CustomToast title={title ?? "Info"} message={message} />)
}
export const loadingToast = (title: string, message: string, type: TypeOptions, percentage?: number): string => {
    const id = toast.loading(
        <CustomToast title={title} message={message} />,
        {
            type: type,
            progress: percentage,
            autoClose:4000
        }
    )
    return id.toString()
}
export const CreatingNewPDFToast = () => {
    toast(
        <PDFCreateToast />,
        {
            autoClose:4000,
            hideProgressBar: true,
            className: 'ToastBGWhite'
        }
    )
}

export const NewQBOExportToastSuccess = (export_link, downloadable_file, export_run_id) => {
    toast(
        <QBOExportCreateSuccessToast export_link={export_link} downloadable_file={downloadable_file} export_run_id={export_run_id}/>,
        {
            autoClose:false,
            hideProgressBar: true,
            className: 'ToastBGWhite'
        }
    )
}

export const NewQBOExportToastFailure = (errorMessage) => {
    toast.error(
        <QBOExportCreateFailureToast errorMessage={errorMessage}/>,
        {
            autoClose:false,
            hideProgressBar: true,
        }
    )
}

export const NewPDFToastSuccess = () => {
    toast(
        <PDFCreateSuccessToast />,
        {
            autoClose:4000,
            hideProgressBar: true,
            className: 'ToastBGWhite'
        }
    )
}
export const isExistToast = (id: string): any => {
    return toast.isActive(id)
}
export const updateToast = (id: string, title: string, type: TypeOptions, message: string, percentage: number): any => {
    if (type.toLowerCase() === 'info') {
        toast.update(id, {
            render: <CustomToast title={title} message={message} />,
            type: type,
            progress: percentage,
            transition: Flip,
            hideProgressBar: false,
        });
    }
    else {
        toast.dismiss(id);
        showToast(type, message, title)

    }
}
export const dismissToast = (id?: string): any => {
    toast.dismiss(id);
}

export const showUpdateToast = (onUpdate: () => void, onCancel: () => void): string => {
    const id = toast.info(<UpdateToast onUpdate={onUpdate} onCancel={onCancel} />, { autoClose: false, icon: false, draggable: false, closeOnClick: false })
    function onUpdateWithClose() {
        onUpdate()
        toast.dismiss(id)
    }
    function onCancelWithClose() {
        onCancel()
        toast.dismiss(id)
    }
    const closeButton = <ToastCloseButton onClose={onCancelWithClose} />;

    toast.update(id,
        {
            render: <UpdateToast onUpdate={onUpdateWithClose} onCancel={onCancelWithClose} />,
            closeButton: closeButton,
            autoClose: false,
            draggable: false,
            icon: false,
            closeOnClick: false,
        });
    return id.toString()
}