import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../../../contexts/AuthContext";
import { PayrollContext } from "../../../../contexts/TicketingSystem/PayrollContext";
import AlertDialog from "../../../Common/AlertDialog";
import EmailContactsInput from "../EmailContactsInput";
import "../style.css";
import logo from "./static/logo.svg";
import Excel_logo from "./static/excel-logo.svg";
import MDIcons from "../../../Common/MDIcons";
import PointsInHeader from "./PointsInHeader";
import { MilestoneEmailContext } from "../../../../contexts/TicketingSystem/MilestoneEmailContext";
import { MilestoneContext } from "../../../../contexts/TicketingSystem/MilestoneContext";
import EmailSubjectPayroll from "../EmailSubjectPayroll";
import PayrollPreviewMainTitleView from "./MainTitleViews/Payroll";
import MonthEndPreviewMainTitleView from "./MainTitleViews/MonthEndClose";
import EmailSubjectMonthEnd from "../EmailSubjectMonthEnd";
import MonthEndCloseDefaultSecondLine from "./DefaultSecondLine.tsx/MonthEndClose";

// This component will be triggered from within the main Email component or from the 'SubmitApproveReject' component.
const EmailPreviewComponent = () => {
  const { milestoneType } = useContext(MilestoneContext);
  const { setToEmailArray, setFromEmailArray, setCCEmailArray, toEmailArray, fromEmailArray, ccEmailArray, emailSubject } = useContext(MilestoneEmailContext);
  const { setEmailPreviewFlag, attachedFiles, richTextEmail } = useContext(MilestoneEmailContext);

  return (
    <AlertDialog open={true} onClose={() => setEmailPreviewFlag(false)} title="Email Preview" dialogType="user-form" dialogActions={<div className="flex-row-space-between"></div>}>
      <div>
        <div className="SubTask__EmailPreview__EmailContacts__ListContainer">
          <div className="SubTask__EmailPreview__EmailContacts">
            <label>To</label>
            <EmailContactsInput emailArray={toEmailArray} setEmailArray={setToEmailArray} email_type="to" />
          </div>
          <div className="SubTask__EmailPreview__EmailContacts">
            <label>From</label>
            <EmailContactsInput emailArray={fromEmailArray} setEmailArray={setFromEmailArray} email_type="from" />
          </div>
          <div className="SubTask__EmailPreview__EmailContacts">
            <label>CC</label>
            <EmailContactsInput emailArray={ccEmailArray} setEmailArray={setCCEmailArray} email_type="cc" />
          </div>
        </div>

        <div className="SubTask__EmailPreview__Title">{emailSubject}</div>
        <div className="SubTask__EmailPreview__Container">
          <div className="SubTask__EmailPreview__Body__Container__Banner">
            <div className="SubTask__EmailPreview__Body__Container__Banner__TopLine"></div>
            <div className="SubTask__EmailPreview__Body__Container__Banner__Logo">
              <img src={logo} alt="Mighty Digits logo" />
            </div>
            <div className="SubTask__EmailPreview__Body__MainTitle__Container">{milestoneType === 0 ? <PayrollPreviewMainTitleView /> : <MonthEndPreviewMainTitleView />}</div>
            <div className="SubTask__EmailPreview__Body__MainTitle__Text__Container">
              <div dangerouslySetInnerHTML={{ __html: richTextEmail }} />
              {/* <div>
                <span>{emailGreeting}</span>
                <br />
                
                {milestoneType === 0 ? <></> : <><MonthEndCloseDefaultSecondLine /><br /></>}
                {secondGreetingsLine && <><br/><div>{secondGreetingsLine}</div></>}
              </div>
              <br />
              {introToNotes && (
                <>
                  <div>{introToNotes}</div>
                  <br />
                </>
              )}
              {(notes.length > 0 || notesHeaders.length > 0) && <div style={{ marginBottom: "0.5rem" }}>{aboveNotes}</div>}
              {notes.length > 0 && (
                <>
                  <div>
                    {notes.map((note) => {
                      return <li>{note.content}</li>;
                    })}
                  </div>
                  <br />
                </>
              )}
              {notesHeaders.length > 0 && (
                <>
                  <div>
                    {notesHeaders.map((header) => {
                      return (
                        <>
                          <div>{header.content}</div>
                          <PointsInHeader headerID={header.id} type="Note" />
                          <br />
                        </>
                      );
                    })}
                  </div>
                </>
              )}
              {introToQuestions && (
                <>
                  <div>{introToQuestions}</div>
                  <br />
                </>
              )}

              {(questions.length > 0 || questionsHeaders.length > 0) && <div style={{ marginBottom: "0.5rem" }}>{aboveQuestions}</div>}
              {questions.length > 0 && (
                <>
                  <div className="SubTask__EmailPreview__Body__QuestionsContainer">
                    {questions.map((question) => {
                      return <div className="SubTask__EmailPreview__Body__Points__Line">{question.content}</div>;
                    })}
                  </div>
                  <br />
                </>
              )}
              {questionsHeaders.length > 0 && (
                <>
                  <div>
                    {questionsHeaders.map((header) => {
                      return (
                        <>
                          <div>{header.content}</div>
                          <PointsInHeader headerID={header.id} type="Question" />
                        </>
                      );
                    })}
                  </div>
                  <br />
                </>
              )}
              {approvalRequiredByNote && <><div style={{ fontWeight: "700" }}>{approvalRequiredByNote}</div><br /></>}
              
              {outro && (
                <>
                  <div>{outro}</div>
                  <br />
                </>
              )}
              <div>Thanks!</div>
              <div style={{ fontWeight: "700" }}>{emailSenderSignature}</div> */}
              {attachedFiles?.length > 0 && (
                <div className="SubTask__EmailPreview__Body__Container__AttachmentsContainer">
                  {attachedFiles.map((attachment) => {
                    return (
                      <div className="SubTask__EmailPreview__Body__Container__Attachment__Container">
                        <MDIcons title="Files-icon" />
                        {attachment["attachment_name"]}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </AlertDialog>
  );
};

export default EmailPreviewComponent;
