import { useRef } from 'react';
import Select, { ActionMeta, OptionTypeBase, components } from 'react-select';
import makeAnimated from 'react-select/animated';
import createClass from "create-react-class";
import './select.css';

const animatedComponents = makeAnimated();

const Option = createClass({
  render() {
    return (
      <components.Option {...this.props}>
        <div className="checkBoxContainer">
          <input
            type="checkbox"
            style={{ appearance: 'checkbox' }}
            checked={this.props.isSelected}
            onChange={e => null}
          />{" "}
        </div>
        <label>{this.props.label} </label>
      </components.Option>

    );
  }
});
export const Control = (props: any) => {
  return (
    <>
      <components.Control {...props} >
        {props.children}
      </components.Control>
    </>
  );
};

export const customSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#85B440' : '#32324D',
    paddingLeft: 15,
    fontFamily: "SF Pro Text",
    fontWeight: state.isSelected ? 600 : 400,
    background: 'white',
    lineHeight: 2,
    alignContent: 'center',
    cursor: 'pointer',
  }),
  container: (provided, state) => ({
    ...provided,
    marginLeft: 0,
    fontFamily: "SF Pro Text",
    color: '#32324D',
    fontWeight: 500,
    fontSize: 14,
    borderWidth: 0,
    "&:hover": {
      // border: 'none',
      background: 'white'
    },
    borderRadius: 4,
    top: 0,
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',

  }), valueContainer: (provided, state) => ({
    ...provided,
    top: 0,
    fontFamily: "SF Pro Text",
    color: 'rgba(142, 142, 169, 1)',
    fontSize: 14,
    borderRadius: 4,
    marginLeft: 'auto',
    cursor: 'pointer',
    height: '100%',
    transition: 'none',
    border: 'none',
    "&:hover": { background: 'none', borderWidth: 1 }
  }),
  control: (provided, state) => ({
    ...provided,
    top: 0,
    fontFamily: "SF Pro Text",
    color: 'rgba(142, 142, 169, 1)',
    fontSize: 14,
    borderRadius: 4,
    marginLeft: 'auto',
    cursor: 'pointer',
    minHeight: '4rem',
    transition: 'none',
    // outline: '0.5px solid rgba(98, 120, 140, 0.4)',
    border: '0.5px solid rgba(98, 120, 140, 0.4)',
    borderStyle: 'solid',
    "&:hover": { background: 'white'},
    "&:focus": { border: 'none', background: '#F7F7F7' }
  }),
  indicatorContainer: (provided, state) => ({
    ...provided,
    top: 0,
    fontFamily: "SF Pro Text",
    color: 'rgba(142, 142, 169, 1)',
    fontSize: 14,
    borderRadius: 4,
    marginLeft: 'auto',
    cursor: 'pointer',
    height: '100%',
    transition: 'none',
    padding: '5px',
    border: '0.5px solid rgba(98, 120, 140, 0.4)',
    "&:hover": { background: 'white', borderWidth: 0 },
    "&:focus": { borderStyle: 'none', border: 'none', background: '#F7F7F7' }
  }),
  menu: (provided, state) => ({
    ...provided,
    // position: "relative",
    // top: '0%'
  }),
}


interface SelectComponentProps {
  name: string;
  options: Array<{ value: string, label: string }>;
  onChange: (value: any, action: ActionMeta<any>) => void;
  isLoading: boolean;
  value: OptionTypeBase;
  isMulti?: boolean;
  internalLabel?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
  allowSelectAll?: boolean;
  isDisabled?: boolean;
}

export default function SelectComponent(props: SelectComponentProps) {
  const { name, options, onChange, isLoading, value, isMulti, internalLabel, isSearchable, allowSelectAll, isClearable, isDisabled } = props;

  const valueRef = useRef(value);
  valueRef.current = value;

  const selectAllOption = {
    value: "All",
    label: "All"
  };


  const isSelectAllSelected = () => {
    if (allowSelectAll && valueRef.current) {
      return valueRef.current.length === options.length;
    } else return false
  }

  const isOptionSelected = option => {
    if (allowSelectAll && valueRef.current) {
      return valueRef.current.some(({ value }) => value === option.value) ||
        isSelectAllSelected();
    } else return false
  }

  var localOptions: Array<{ value: string, label: string }> = options
  if (allowSelectAll) {
    localOptions = [selectAllOption, ...options];
  }
  const onSelectChange = (newValue, actionMeta) => {
    if (allowSelectAll) {
      const { action, option, removedValue } = actionMeta;
      if (action === "select-option" && option.value === selectAllOption.value) {
        onChange(options, actionMeta);
      } else if (
        (action === "deselect-option" &&
          option.value === selectAllOption.value) ||
        (action === "remove-value" &&
          removedValue.value === selectAllOption.value)
      ) {
        onChange([], actionMeta);
      } else if (
        actionMeta.action === "deselect-option" &&
        isSelectAllSelected()
      ) {
        onChange(
          options.filter(({ value }) => value !== option.value),
          actionMeta
        );
      } else {
        onChange(newValue || [], actionMeta);
      }
    } else {
      onChange(newValue, actionMeta);
    }

  };


  return (
    <Select
      isClearable={isClearable}
      defaultValue={"all"}
      isOptionSelected={isOptionSelected}
      closeMenuOnSelect={false}
      isDisabled={isDisabled}
      blurInputOnSelect={!isMulti}
      removeSelected={false}
      name={name}
      components={isMulti && internalLabel ? { Option, Control } : isMulti ? { Option } : internalLabel ? { Control } : animatedComponents}
      options={localOptions}
      hideSelectedOptions={false}
      onChange={onSelectChange}
      isLoading={isLoading}
      value={value}
      styles={customSelectStyles}
      isMulti={isMulti}
      isSearchable={isSearchable}
      theme={theme => ({
        ...theme,
        borderRadius: 4,
        colors: {
          ...theme.colors,
          primary: 'rgba(133, 180, 64, 0.05)',
          primary50: 'rgba(133, 180, 64, 0.05)',
          primary25: 'rgba(133, 180, 64, 0.05)',
        },
      })}
    />
  );
}

SelectComponent.defaultProps = {
  isClearable: false,
  isMulti: false,
  isSearchable: true,
  internalLabel: false
}