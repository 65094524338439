import React, { useEffect, useState } from "react";
import AlertDialog from "../AlertDialog";
import { addSharePointFolder, listSharePointChildren, renameSharePointItem } from "../../../APIFunctions/graph_api";
import LoadingBar from "../LoadingBar";
import MDIcons from "../MDIcons";
import ItemsList from "./ItemsList";


const SharepointExplorerGetFileLink = ({ onCloseBrowser, displaySharepointBrowser, setDisplaySharepointBrowser, company, setSelectedFile, setSelectedFolder }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [currentSharepointFolderID, setCurrentSharepointFolderID] = useState<string>("root");
  const [folderChildren, setFolderChildren] = useState<any>([]);
  const [prevLevelID, setPrevLevelID] = useState<string>("root");
  const [selected, setSelected] = useState<any>();
  const [displayedFolderPath, setDisplayedFoldePath] = useState<string[]>([]);
  const [renameValue, setRenameValue] = useState<string>("");

  const getFolderChildren = async () => {
    setLoading(true);
    if (company) {
      const response = await listSharePointChildren(company, currentSharepointFolderID);
      setFolderChildren(response["folder_children"]["value"]);
      if (response["parent_folder"]) {
        setPrevLevelID(response["parent_folder"]["parentReference"]["id"]);

        const fullPath = response["parent_folder"]["parentReference"]["path"] + "/" + response["parent_folder"]["name"];
        const pathArray = fullPath.split("/");
        // remove everything before the company name and the company name
        const companyNamePosition = pathArray.indexOf(company.companyName);
        if (companyNamePosition !== -1) {
          const trimmedPath = pathArray.slice(companyNamePosition + 1);
          setDisplayedFoldePath(trimmedPath);
        }

        if (setSelectedFolder) {
          setSelectedFolder(response["parent_folder"]);
        }
      }
    }
    setLoading(false);
  };

  const renameItem = async (selectedItemID: any, e: any) => {
    setLoading(true);
    if (company) {
      const value = e.target.value.replace("."," ").replace("/"," ").replace(":"," ")
      const response = await renameSharePointItem(company, selectedItemID, value);
      if (response["renamed_item"]) {
        const newChildrenArray = folderChildren.map((x) => (x.id === response["renamed_item"]["id"] ? response["renamed_item"] : x));
        setFolderChildren(newChildrenArray);
        setSelected(response["renamed_item"]);
        setRenameValue("");
      }
    }

    setLoading(false);
  };

  const renameItemOnEnter = (selectedItemID: any, e: any) => {
    if (e.keyCode === 13) {
      renameItem(selectedItemID, e);
    }
  };

  const renameItemOnBlur = (selectedItemID: any, e: any) => {
    renameItem(selectedItemID, e);
  };

  const openFile = (item) => {
    window.open(item.webUrl, "_blank", "noreferrer");
  };

  const drillDown = (item) => {
    if (item["folder"]) {
      if (currentSharepointFolderID === "root") {
        setPrevLevelID("root");
      } else {
        if (!(prevLevelID === "root")) {
          setPrevLevelID(item["parentReference"]["id"]);
        }
      }

      setCurrentSharepointFolderID(item.id);
    }
  };

  const goBack = () => {
    setCurrentSharepointFolderID(prevLevelID);
  };

  const onGetSelectedFileLink = async () => {
    setSelectedFile(selected);
    setDisplaySharepointBrowser(false);
  };

  useEffect(() => {
    getFolderChildren();
  }, [currentSharepointFolderID, company]);

  return (
    displaySharepointBrowser ? <AlertDialog
      onClose={onCloseBrowser}
      open={displaySharepointBrowser}
      title="Sharepoint Browser"
      dialogType="user-form"
      dialogActions={
        <div className="action_btns_container">
          <button className={"btn--tertiary btn--M"} onClick={onCloseBrowser} autoFocus>
            <span>Cancel</span>
          </button>
          
          <div className="action_btns_container--right">
          <button disabled={!selected} className="btn--tertiary btn--M" onClick={() => setRenameValue(selected?.["name"])} autoFocus>
            <span>Rename</span>
          </button>
          {selected && <button disabled={!("file" in selected)} className="btn--primary btn--M" onClick={onGetSelectedFileLink} autoFocus>
            <span>Get File Link</span>
          </button>}
          </div>
          
          
        </div>
      }
    >
      <LoadingBar loading={loading} />
      <div className="SharepointExplorer__Header">
        {displayedFolderPath?.length > 0 && (
          <button onClick={goBack} className="btn--tertiary btn--S no-borders-no-background Black-Button">
            <MDIcons title="Header__Back__Button" />
          </button>
        )}
        {displayedFolderPath?.map((x, i) => {
          return (
            <>
              <div>{x}</div>
              <MDIcons title="forward-arrow" />
            </>
          );
        })}
      </div>

      <div className="SharepointExplorer__Container">
      <ItemsList
          list={folderChildren}
          drillDown={drillDown}
          selected={selected}
          setSelected={setSelected}
          openFile={openFile}
          renameValue={renameValue}
          setRenameValue={setRenameValue}
          renameItemOnEnter={renameItemOnEnter}
          renameItemOnBlur={renameItemOnBlur}
        />
      </div>
    </AlertDialog>:<></>
  );
};

export default SharepointExplorerGetFileLink;
