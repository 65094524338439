import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { createAsanaTask, getProjectEvents } from '../../APIFunctions/Asana'
import { TaskAuthContext } from '../../contexts/Task/TaskAuthContext'
import GeneralHeader from '../Common/GeneralHeader'
import DataGridComponent from './TableComponent/DataGridComponent'
import TaskCard from './TaskCard'
import { CircularProgress } from '@mui/material';
import { GridRowsProp } from '@mui/x-data-grid-pro';
import { TaskCardContextProvider } from '../../contexts/Task/TaskCardContext';
import { TaskTableContext } from '../../contexts/Task/TaskTableContext';
import { TaskServicesContext } from '../../contexts/Task/TaskServicesContext';
import SelectComponent from '../Common/SelectComponent';
import { OptionTypeBase } from 'react-select';
import CustomTooltip from '../Common/Tooltip';
import DataGridAdminProject from './TableComponent/DataGridAdminProject';
import { AuthContext } from '../../contexts/AuthContext';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';


export const tasksCompletedDisplayOptions = [
    { value: "incomplete", label: "Incomplete" },
    { value: "completed", label: "Completed" },
    { value: "all", label: "All Tasks" }
];

const TasksList = () => {
    const queryClient = useQueryClient();
    const {selectedCompany} = useContext(AuthContext)
    const { projectID, tasksCategory } = useContext(TaskServicesContext)
    const { setLoading, loading } = useContext(TaskAuthContext)
    const { setTimeAtLastTaskUpdate, retrieveTaskDetail, setTaskDetail,
        openTaskDetail, taskDetail, parents, setParents, retrieveParent, attachments, retrieveStories, subTasks
     ,retrieveSubTasks, retrieveAttachments, timeAtLastTaskUpdate, setTasks, tasks, setOpenTaskDetail, projectTasksQuery} = useContext(TaskTableContext)

     
     const [syncGID, setSyncGID] = useState("")
     const [completedFilter, setCompletedFilter] = useState<OptionTypeBase>(tasksCompletedDisplayOptions[0]);
    const {task_gid} = useParams()
    const displayTaskByUrl = () => {
        if (task_gid) {
            setOpenTaskDetail(true)
            retrieveTaskDetail(false,task_gid)
           }
    }
   
    
    const timeSinceLastSubTaskUpdate = (timeAtLastSubTaskUpdate) => {
        const currentTime = new Date().getTime()
        const seconds = (currentTime - timeAtLastSubTaskUpdate)/1000
        return seconds
    }

    

    

    const tableData = projectTasksQuery.data?.map(function(task){
        task["id"] = task["gid"];
        return task
    })

    const addTask = async() => {
        const sendData = {
            "projects":projectID
        }
    
        setLoading(true)
        const response = await createAsanaTask(sendData)
        if (response.data) {
            const newTaskGID = response.data.gid
            retrieveTaskDetail(true, newTaskGID)
            setParents([])
            setTimeAtLastTaskUpdate(new Date().getTime())
            setLoading(false)
        } else if (response.response?.status === 401) {
            setTimeout(() => {
                addTask()
              },1000)
        }
        
      }

    
    const updateSubTasksWithEvents = (eventsData) => {
        const resourcesGIDArray = eventsData.map(x => x.resource["gid"]);
        for(var i=0;i < subTasks.length;i++) {
            if (resourcesGIDArray.includes(subTasks[i].gid)) {
                // if one of the subtasks is in the list of resourcrs that have been changed
                retrieveSubTasks()
                return
            }
        }

        // check for new subtasks
        for (i=0; i<eventsData.length;i++) {
            if (eventsData[i].action === "added" && eventsData[i].parent["gid"] === taskDetail.gid){
                retrieveSubTasks()
                return
            }
        }
    }

    

    const pollEvents = async() => {
        if(projectID) {
        const event = (await getProjectEvents(projectID,syncGID))
        if (event?.data?.length > 0) {
            const eventsData = event?.data
            if (timeSinceLastSubTaskUpdate(timeAtLastTaskUpdate) > 7) {
                queryClient.invalidateQueries({queryKey: ["tasks", projectID]});
                updateSubTasksWithEvents(eventsData)
                setSyncGID(event?.sync)
            }

        var i;
        const attachmentsGIDArray = attachments.map(x => x["gid"]);

        // check for new attachments or removed attachments
        for (i=0; i<eventsData.length;i++) {
            if (eventsData[i].action === "added" && eventsData[i].parent["gid"] === taskDetail.gid && eventsData[i].type === "attachment"){
                retrieveAttachments()
                break;
            }
            if (eventsData[i].action === "deleted" && attachmentsGIDArray.includes(eventsData[i].resource["gid"])){
                retrieveAttachments()
                break;
            }
        }

            // check for new comments or edited comments or deleted comments
            for (i=0; i<eventsData.length;i++) {
                if (eventsData[i].resource["type"] === "comment") {
                    retrieveStories()
                    break;
                }
            }

            // check for updates to parents array
            if (parents.length > 0){
                const resourcesGIDArray = eventsData.map(x => x.resource["gid"]);
                for (i=0; i<parents.length;i++) {
                    if(resourcesGIDArray.includes(parents[i].gid)) {
                        retrieveParent(parents[i].gid)
                    }
    
                }
            }
            

        } else if(event?.sync){
            setSyncGID(event?.sync)
        } else {
            setSyncGID(event)
        }

    }
    }
    

    useEffect(() => {
        displayTaskByUrl()
        setOpenTaskDetail(false)
    },[task_gid,projectID, tasksCategory,selectedCompany])
    

    useLayoutEffect(() => {

        if (taskDetail) {
            const updatedTasksArray = projectTasksQuery.data?.slice().map(subTask => 
                (subTask.gid === taskDetail.gid) ? taskDetail : subTask)
            setTasks(updatedTasksArray)
            setTimeAtLastTaskUpdate(new Date().getTime())
        }
        
    },[taskDetail, openTaskDetail,selectedCompany, projectID])

    const useInterval = (callback, delay) => {
        const savedCallback = React.useRef<any>();
      
        React.useEffect(() => {
          savedCallback.current = callback;
        }, [callback]);
      
        React.useEffect(() => {
          const tick = () => {
            savedCallback.current();
          }
          if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
          }
        }, [delay]);
      };

    useInterval(() => pollEvents(), 15000)
    

      console.log("projectTasksQuery.isLoading",projectTasksQuery)

    return (
    <>
    <GeneralHeader title={"Tasks"} renderDivisions={false}>
        <div className='header-controllers__container'>
            <SelectComponent
                  name={tasksCompletedDisplayOptions[0].label}
                  options={tasksCompletedDisplayOptions}
                  onChange={(value) => setCompletedFilter(value)}
                  isLoading={false}
                  internalLabel={true}
                  isSearchable={false}
                  value={completedFilter}
            />
            
            <button className='btn--M--centered btn--secondary' onClick={addTask}>
                {
                    loading ? 
                    <CircularProgress size={15} sx={{color:"#85b440"}}/>
                    :
                    <CustomTooltip title={
                        <React.Fragment>
                            <div>Add New Task</div>
                        </React.Fragment>
                    }>
                    <AddIcon />
                    </CustomTooltip>

                }
            </button>
        </div>
    </GeneralHeader>
    
    {projectTasksQuery.data && !projectTasksQuery.isLoading ?
        (tasksCategory !== "user" && tasksCategory !== undefined  ? 
        <DataGridAdminProject completedFilter={completedFilter.value} /> 
        :
        <DataGridComponent completedFilter={completedFilter.value} />
        )
        :
        // <div>no tasks to show</div>
        <div className='fetchTasks__Container'>
            <CircularProgress size={85} sx={{color:"#85b440"}}/>
            Attempting to fetch tasks if there's any...
        </div>
        
    }
    

        { openTaskDetail && taskDetail && taskDetail?.gid !== "" && 
        <TaskCardContextProvider>
            <TaskCard />
        </TaskCardContextProvider>
        }
    </>
    
)

}

export default TasksList