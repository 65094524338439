import { decodedID } from "../../../QBOReport/Components/DataGrid/utils";

export const addHighlightingAttribute = (dataPoint, comments) => {

  let newDataPoint = { ...dataPoint };

  const matchingComments = comments?.filter((x) => x?.transaction?.toString() === decodedID(dataPoint.id.toString()));
  
  if (matchingComments?.length > 0) {
    
    let highlightFieldsArray: any = [];
    for (let i = 0; i < matchingComments.length; i++) {
      const comment = matchingComments[i];
      highlightFieldsArray.push(comment.column_id);
    }
    newDataPoint["highlight_field"] = highlightFieldsArray;
    return newDataPoint;
  }
  newDataPoint["highlight_field"] = [];
  return newDataPoint;
};

export const addHighlightingAttributeTransactionDetail = (dataPoint, comments) => {

  let newDataPoint = { ...dataPoint };

  const matchingComments = comments?.filter((x) => x?.transaction_line_number?.toString() === dataPoint.id.toString());
  
  if (matchingComments?.length > 0) {
    
    let highlightFieldsArray: any = [];
    for (let i = 0; i < matchingComments.length; i++) {
      const comment = matchingComments[i];
      highlightFieldsArray.push(comment.column_id);
    }
    newDataPoint["highlight_field"] = highlightFieldsArray;
    return newDataPoint;
  }
  newDataPoint["highlight_field"] = [];
  return newDataPoint;
};

