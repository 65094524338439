import { useContext, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import { AuthContext } from "../../../../contexts/AuthContext";
import BasicMenu from "../../../Common/DropDownComponent/TaskMoreActions";
import { MilestoneLinksContext } from "../../../../contexts/TicketingSystem/MilestoneLinksContext";


import SharepointExplorerGetFileLink from "../../../Common/SharepointExplorer/SharepointExplorerGetFileLink";
import { getSharepointFolderPath } from "../../../Common/SharepointExplorer/utils";
import { getSharepointInfoObj } from "./Util";
import LinkButton from "./LinkButton";

const PermanentLink = ({ link, areActionsAllowed, showAddButton }) => {
  // lowest level of link components
  const { selectedCompany } = useContext(AuthContext);
  const { editLink, editMonthEndCloseLink, monthEndBackupFolderLink, setMonthEndBackupFolderLink, monthEndDeferredRevenueFolderLink,setMonthEndDeferredRevenueFolderLink } = useContext(MilestoneLinksContext);
  const [displaySharepointBrowser, setDisplaySharepointBrowser] = useState<boolean>();
  const [selectedFile, setSelectedFile] = useState<any>();
  const [selectedFolder, setSelectedFolder] = useState<any>();
  const onCloseBrowser = () => {
    setDisplaySharepointBrowser(false);
  };

  const onOpenBrowser = () => {
    setDisplaySharepointBrowser(true);
  };

  const clearLink = async() => {    
    await editMonthEndCloseLink(link, {web_url:"",sharepoint_id:""});
    if (link.name === "Month End Close Backup File") {
      await editMonthEndCloseLink(monthEndBackupFolderLink, {web_url:"",sharepoint_id:""});
      setMonthEndBackupFolderLink({...monthEndBackupFolderLink,link:""})
    }
    if (link.name === "Month End Close Deferred Revenue File") {
      await editMonthEndCloseLink(monthEndDeferredRevenueFolderLink, {web_url:"",sharepoint_id:""});
      setMonthEndDeferredRevenueFolderLink({...monthEndDeferredRevenueFolderLink,link:""})
    }
    setSelectedFile(null);
  };

  const updateLink = async () => {
    if (selectedFile) {
      const sharepoint_file_info_obj = getSharepointInfoObj(selectedFile, selectedFolder);
      
      await editMonthEndCloseLink(link, sharepoint_file_info_obj);
    }
  };


  useEffect(() => {
    updateLink();
  }, [selectedFile]);


  return (
    <>
      <LinkButton link={link} selectedFile={selectedFile} areActionsAllowed={areActionsAllowed} clearLink={clearLink} onOpenBrowser={onOpenBrowser} showAddButton={showAddButton} />
      {displaySharepointBrowser && (
        <SharepointExplorerGetFileLink
          onCloseBrowser={onCloseBrowser}
          displaySharepointBrowser={displaySharepointBrowser}
          company={selectedCompany}
          setDisplaySharepointBrowser={setDisplaySharepointBrowser}
          setSelectedFile={setSelectedFile}
          setSelectedFolder={setSelectedFolder}
        />
      )}
    </>
  );
};

export default PermanentLink;
