import { useContext, useState } from "react";
import GeneralHeader from "../Common/GeneralHeader";
import ExportQBOContainer from "./ExportQBOContainer";
import { AuthContext } from "../../contexts/AuthContext";
import LoadingBar from "../Common/LoadingBar";



const ExportQBOToExcel = () => {
  const [loading] = useState<boolean>(false);
  
  const [saveFolder, setSaveFolder] = useState<any>();


  return (
    <>
      <GeneralHeader title="Export Financials to Excel" renderDivisions={false}>
      

      </GeneralHeader>
      <LoadingBar loading={loading} />
      <ExportQBOContainer
        saveFolder={saveFolder}
        setSaveFolder={setSaveFolder}
      />
    </>
  );
};

export default ExportQBOToExcel;
