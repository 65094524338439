import { createContext, useEffect, useState } from "react";
import { useInterval } from "../hooks";
import { LoaderStatus } from "../types/Loaders";

export interface EmbedExcelLoadingContextProps {
    reportStatus: LoaderStatus;
    setLoaderStatus: (status: LoaderStatus) => void;
    loaderValue: number;
}
export const EmbedExcelLoadingContext = createContext<EmbedExcelLoadingContextProps>(null as any);

export const EmbedExcelLoadingContextProvider = (props) => {
    const [reportStatus, setLoaderStatus] = useState<LoaderStatus>(LoaderStatus.Loading);
    const [loaderValue, setLoaderValue] = useState(0);


    useEffect(() => {
        const intervalTimer = setInterval(() => {
            switch (reportStatus) {
                case LoaderStatus.Loading:
                    setLoaderStatus(LoaderStatus.Loaded);
                    break;
                case LoaderStatus.Loaded:
                    setLoaderStatus(LoaderStatus.GeneratingReports);
                    break;
                case LoaderStatus.GeneratingReports:
                    setLoaderStatus(LoaderStatus.Rendered);
                    break;
                case LoaderStatus.Rendered:
                    setLoaderStatus(LoaderStatus.Rendered);
                    break;
                default:
                    setLoaderStatus(LoaderStatus.Rendered);
                    break;
            }
        }, 1000);
        return () => clearInterval(intervalTimer);
    }, [reportStatus]);

    /**
     * Hook to update the loader values based on the report diffrent status
     */
    useInterval(() => {
        const currentLoaderValue = loaderValue + Math.floor((Math.random() * 2) + 1);
        switch (reportStatus) {
            case LoaderStatus.Loading:
                if (currentLoaderValue < 30)
                    setLoaderValue(30)
                else if (currentLoaderValue > 80)
                    setLoaderValue(80)
                else
                    setLoaderValue(currentLoaderValue)
                break;
            case LoaderStatus.Loaded:
                if (currentLoaderValue < 80)
                    setLoaderValue(80)
                else if (currentLoaderValue > 95)
                    setLoaderValue(95)
                else
                    setLoaderValue(currentLoaderValue)
                break;
            case LoaderStatus.GeneratingReports:
                if (currentLoaderValue < 95)
                    setLoaderValue(95)
                else if (currentLoaderValue > 100)
                    setLoaderValue(100)
                else
                    setLoaderValue(currentLoaderValue)
                break;
            default:
                break;
        }
    }, 250, reportStatus !== LoaderStatus.Rendered);

    return (
        <EmbedExcelLoadingContext.Provider value={{
            reportStatus,
            setLoaderStatus,
            loaderValue
        }}>
            {props.children}
        </EmbedExcelLoadingContext.Provider>
    )
}