import { useContext, useState } from "react";
import { uploadFile, uploadPayrollEmailAttachment } from "../../../APIFunctions/file";
import { MilestoneEmailContext } from "../../../contexts/TicketingSystem/MilestoneEmailContext";
import { PayrollContext } from "../../../contexts/TicketingSystem/PayrollContext";
import { IFileResource } from "../../../types/API/fileResource";
// import { showToast } from '../../../../utils/toastHelpers';

// import AlertDialog from '../../../Common/AlertDialog';

// import MDIcons from '../../../Common/MDIcons';
import { Company } from "../../../types/Company";
import { Onboarding } from "../../../types/Onboarding";
import { showToast } from "../../../utils/toastHelpers";
import AlertDialog from "../AlertDialog";
import MDIcons from "../MDIcons";
import "./UploadFileDialog.css";

interface IFileUploadDialogProps {
  open: boolean;
  onClose: () => void;
  meta: string;
  onSuccess?: (files: IFileResource[]) => void;
}

export default function PayrollEmailAttachmentUploadDialog({ open, onClose, meta, onSuccess }: IFileUploadDialogProps) {
  const { email } = useContext(MilestoneEmailContext);
  const [selectedFile, setSelectedFile] = useState<any>(null);

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const onAddClick = async () => {
    if (selectedFile) {
      if (selectedFile.size > 150 * 1024 * 1024) {
        // 150 MB
        console.error("File size is too big");
        return;
      }
      
      if (email.id) {
        
        var result = await uploadPayrollEmailAttachment(selectedFile, meta, selectedFile.name, email.id);
        
        if (result.detail) {
          showToast("success", "File uploaded successfully!");
          if (onSuccess) {
            onSuccess(result.detail);
          }

          onClose();
        } else {
          onClose();
        }
      }
    }
  };

  return (
    <AlertDialog
      dialogType="user-form"
      open={open}
      onClose={onClose}
      title={
        <span className="BulkAddEmployee__Title">
          {" "}
          <h3 style={{ fontWeight: 600 }}>{"Upload file"}</h3>{" "}
        </span>
      }
      dialogActions={
        <div className="action_btns_container">
          <button className={"btn--tertiary btn--M"} onClick={onClose} autoFocus>
            <span>Cancel</span>
          </button>
          <button className={!selectedFile ? "btn--primary--disabled btn--M" : "btn--primary btn--M"} onClick={() => onAddClick()} autoFocus>
            <span>Upload</span>
          </button>
        </div>
      }
    >
      <div className="UploadFile__Body__Container">
        <div>
          <MDIcons title={"File"} />
          <span className="Drag_Drop">
            <label><input id="uploadFile" type="file" onChange={handleFileSelect} /> {selectedFile ? selectedFile.name : "click here to upload"}</label>
          </span>
          <br></br>
          <br></br>
          <br></br>
        </div>
      </div>
    </AlertDialog>
  );
}
