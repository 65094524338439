import { SharepointFileInfo } from "../../../../types/Shareppoint"
import { MilestoneLinkInfo } from "../../../../types/TicketingSystem"
import { getSharepointFolderPath } from "../../../Common/SharepointExplorer/utils"

export const getSharepointInfoObj = (selectedFile,selectedFolder):SharepointFileInfo => {
    const sharepoint_id = selectedFile?.id
    const file_name = selectedFile?.name
    const web_url = selectedFile?.webUrl
    const folder_web_url = selectedFolder?.webUrl
    const folder_path = getSharepointFolderPath(selectedFolder).join(",")
    const newLinkObj = { web_url, sharepoint_id, folder_web_url,folder_path, file_name};
    return newLinkObj
}

export const convertLegacyLinkToMilestoneLink = (link_id, email_id, file_name, file_web_url, file_sharepoint_id, folder_web_url, folder_name, link_name, linkType) => {
    
    const placeHolderFolder = {
        parentReference: { path: "" },
        webUrl: folder_web_url,
        name: folder_name,
      };
      const linkFile = {
        name: file_name,
        id: file_sharepoint_id,
        webUrl:file_web_url
      }
      const sharepoint_file_info_obj = getSharepointInfoObj(linkFile, placeHolderFolder);
      const link_info: MilestoneLinkInfo = { email: email_id, name: link_name, link_type: linkType };
      const data = { ...link_info, sharepoint_file_info: sharepoint_file_info_obj, id: link_id };
      return data
}