import { useContext, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import { AuthContext } from "../../../../contexts/AuthContext";
import BasicMenu from "../../../Common/DropDownComponent/TaskMoreActions";
import { MilestoneLinksContext } from "../../../../contexts/TicketingSystem/MilestoneLinksContext";

import SharepointExplorerGetFileLink from "../../../Common/SharepointExplorer/SharepointExplorerGetFileLink";
import { getSharepointFolderPath } from "../../../Common/SharepointExplorer/utils";
import { getSharepointInfoObj } from "./Util";
import LinkButton from "./LinkButton";
import { useCreateLink, useDeleteLink, useEditLink, useLinksList } from "./LinksTableComponent/Hooks";
import MilestoneLinkButton from "./MilestoneLinkButton";
import { SharepointFileInfo } from "../../../../types/Shareppoint";
import { MilestoneLinkInfo } from "../../../../types/TicketingSystem";
import { MilestoneEmailContext } from "../../../../contexts/TicketingSystem/MilestoneEmailContext";

const PermanentTicketingSystemLink = ({ linkType, linkName, areActionsAllowed, showAddButton }) => {
  // lowest level of link components
  const { selectedCompany } = useContext(AuthContext);
  const { email, setSharepointLinkInEmail, sharepointLinkInEmail } = useContext(MilestoneEmailContext);
  const [displaySharepointBrowser, setDisplaySharepointBrowser] = useState<boolean>();
  const [selectedFile, setSelectedFile] = useState<any>();
  const [selectedFolder, setSelectedFolder] = useState<any>();
  const createLinkMutation = useCreateLink(email?.id);
  const link = useLinksList(1);
  const deleteMutation = useDeleteLink(link.data?.[0]?.id);
  const linkMutation = useEditLink(link.data?.[0]?.id);
  const onCloseBrowser = () => {
    setDisplaySharepointBrowser(false);
  };

  const onOpenBrowser = () => {
    setDisplaySharepointBrowser(true);
  };

  const createLink = () => {
    const sharepoint_file_info: SharepointFileInfo = getSharepointInfoObj(selectedFile, selectedFolder);
    const link_info: MilestoneLinkInfo = { email: email.id, name: linkName ? linkName : selectedFile.name, link_type: linkType };

    createLinkMutation.mutate({ ...link_info, sharepoint_file_info });
  };

  const clearLink = async () => {
    deleteMutation.mutate();
    setSelectedFile(null);
  };

  const updateLink = async () => {
    if (selectedFile) {
      const sharepoint_file_info = getSharepointInfoObj(selectedFile, selectedFolder);
      linkMutation.mutate(sharepoint_file_info);
    }
  };

  useEffect(() => {
    
    if (link.data?.length > 0) {
      setSharepointLinkInEmail(link.data?.[0])
    } else {
      setSharepointLinkInEmail(null)
    }
    
  },link.data)

  console.log("link", link.data?.[0]);

  useEffect(() => {
    if (selectedFile) {
      if (link.data?.[0]) {
        updateLink();
      } else {
        createLink();
      }
    }
  }, [selectedFile]);

  return (
    <>
      <MilestoneLinkButton
        link={link.data?.[0]}
        selectedFile={selectedFile}
        areActionsAllowed={areActionsAllowed}
        clearLink={clearLink}
        onOpenBrowser={onOpenBrowser}
        showAddButton={showAddButton}
      />
      {displaySharepointBrowser && (
        <SharepointExplorerGetFileLink
          onCloseBrowser={onCloseBrowser}
          displaySharepointBrowser={displaySharepointBrowser}
          company={selectedCompany}
          setDisplaySharepointBrowser={setDisplaySharepointBrowser}
          setSelectedFile={setSelectedFile}
          setSelectedFolder={setSelectedFolder}
        />
      )}
    </>
  );
};

export default PermanentTicketingSystemLink;
