import React, { useContext, useEffect, useState } from "react";
import AlertDialog from "../Common/AlertDialog";
import "./tasks.css";
import { createAsanaTaskComment, updateAsanaTask, uploadAsanaAttachment } from "../../APIFunctions/Asana";
import MDIcons from "../Common/MDIcons";
import TextareaAutosize from "@mui/material/TextareaAutosize";

import AttachmentIcon from "@mui/icons-material/Attachment";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, LinearProgress } from "@mui/material";
import CustomTooltip from "../Common/Tooltip";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TaskContent from "./TaskContent";
import { TaskTableContext } from "../../contexts/Task/TaskTableContext";
import { TaskCardContext } from "../../contexts/Task/TaskCardContext";
import { TaskServicesContext } from "../../contexts/Task/TaskServicesContext";
import { showToast } from "../../utils/toastHelpers";
import DeleteTaskDialog from "./DeleteTaskDialog";
import Close from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router-dom";
import LikeButton from "../AsanaComponents/AsanaLikeButton";
import { AuthContext } from "../../contexts/AuthContext";

const TaskCard = () => {
  const navigate = useNavigate();
  const { task_gid } = useParams()
  const { selectedCompany } = useContext(AuthContext);
  const {
    taskDetail,
    setTaskDetail,
    openTaskDetail,
    setOpenTaskDetail,
    openDeleteDialoge,
    closeDeleteDialoge,
    deleteDialogeOpen,
    setSubTasks,
    retrieveSubTasks,
    retrieveStories,
    retrieveAttachments,
    setTaskCardLoading,
    taskCardLoading,
    setAttachments,
    setTaskStories,
    parents,
    subTasksObj,
    setSubTasksObj,
  } = useContext(TaskTableContext);
  const { setTaskUpdateObj, taskUpdateObj, getSectionsOptions, parentSubTasks } = useContext(TaskCardContext);
  const { sectionsQuery, tasksCategory } = useContext(TaskServicesContext);
  const [prevStage, setPrevStage] = useState<any>({});
  const [comment, setComment] = useState<string>("");
  const getTaskLink = () => {
    showToast("success", "Task link copied!", "Success");
    return window.location.href;
  };

  const addComment = async () => {
    const newComment = {
      data: {
        text: comment,
      },
    };

    setTaskCardLoading(true);
    const response = await createAsanaTaskComment(taskDetail.gid, newComment);
    if (response.data) {
      setComment(" ");
      await retrieveStories();
      setTaskCardLoading(false);
    } else if (response.response?.status === 401) {
      setTimeout(() => {
        addComment();
      }, 1000);
    }
  };

  const getPrevStage = () => {
    for (let i = 0; i < parentSubTasks.length; i++) {
      if (parentSubTasks[i + 1]?.gid === taskDetail?.gid) {
        setPrevStage(parentSubTasks[i]);
        return;
      }
    }
    setPrevStage({});
  };

  const onComplete = () => {
    const newItem = { ...taskDetail };
    newItem.completed = !taskDetail.completed;
    setTaskDetail(newItem);
    updateAsanaTask({ gid: taskDetail.gid, completed: newItem.completed });
    // update the subtasks object
    if (parents.length === 1 && subTasksObj[parents[0].gid]) {
      const subtasksArray = subTasksObj[parents[0].gid].map((x) => (x.gid === taskDetail.gid ? newItem : x));
      const newSubTasksObj = { ...subTasksObj };
      newSubTasksObj[parents[0].gid] = subtasksArray;
      setSubTasksObj(newSubTasksObj);
    }
  };

  const handleFileSelect = async (event) => {
    const selectedFile = event.target.files[0];
    setTaskCardLoading(true);
    if (selectedFile && selectedFile.size <= 9000000) {
      const response = await uploadAsanaAttachment(taskDetail.gid, selectedFile);
      if (response.data) {
        await retrieveAttachments();
        setTaskCardLoading(false);
      } else if (response.response?.status === 401) {
        setTimeout(async () => {
          await uploadAsanaAttachment(taskDetail.gid, selectedFile);
          await retrieveAttachments();
          setTaskCardLoading(false);
        }, 1000);
      }
    }
  };

  const onLikeTask = () => {
    const updatedTask = { ...taskDetail };
    updatedTask.liked = !taskDetail.liked;
    setTaskDetail(updatedTask);
    if (taskUpdateObj.hasOwnProperty("gid")) {
      setTaskUpdateObj({
        ...taskUpdateObj,
        gid: taskDetail.gid,
        liked: updatedTask["liked"],
      });
    } else {
      setTaskUpdateObj({
        gid: taskDetail.gid,
        liked: updatedTask["liked"],
      });
    }
  };

  const progressBarTheme = createTheme({
    components: {
      // Name of the component
      MuiLinearProgress: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            backgroundColor: "#E5E5E5",
            span: {
              backgroundColor: "#85b440 !important",
            },
          },
        },
      },
    },
  });

  useEffect(() => {
    retrieveStories();
    retrieveSubTasks();
    retrieveAttachments();
    if (parents.length === 1) {
      getPrevStage();
    }

    return () => {
      setTaskStories([]);
      setSubTasks([]);
      setAttachments([]);
    };
  }, []);

  useEffect(() => {
    if (sectionsQuery.data?.data?.length > 0) {
      getSectionsOptions();
    }
  }, [sectionsQuery.data]);

  useEffect(() => {
    if (parents.length === 1) {
      getPrevStage();
    }
  }, [parents]);

  // todo: onClose should do more than just setOpenTaskDetail(false)

  return (
    <AlertDialog
      key={taskDetail.gid}
      dialogType="task-form"
      open={openTaskDetail}
      onClose={() => {
        setOpenTaskDetail(false);
        if (tasksCategory) {
          navigate(`/${selectedCompany?.getURL()}/tasks/project/${tasksCategory}/`);
        } else {
          navigate(`/${selectedCompany?.getURL()}/tasks/project/user/`);
        }
      }}
      title={
        <>
          <div className={"completeButton"}>
            {(tasksCategory === "user" || parents?.length !== 1) &&
              (!taskDetail.completed ? (
                <button id="" className="btn--tertiary btn--S flex-row--S success" onClick={onComplete}>
                  <MDIcons title="check_button" />
                  <span>Mark Complete</span>
                </button>
              ) : (
                <div className="btn--secondary--completed btn--S flex-row--S" onClick={onComplete}>
                  <MDIcons title="check_button" />
                  <span>Completed</span>
                </div>
              ))}
          </div>

          <div className="buttonsContainer">
            <LikeButton task={taskDetail} setTask={setTaskDetail} />
            <CustomTooltip title={<React.Fragment>Upload an attachment!</React.Fragment>}>
              <label id="" className="btn--tertiary btn--M--centered success">
                <AttachmentIcon />

                <input id="uploadFile" type="file" onChange={handleFileSelect} />
              </label>
            </CustomTooltip>
            <CustomTooltip title="copy task link">
              <div
                id=""
                className="btn--tertiary btn--M--centered success"
                onClick={() => {
                  navigator.clipboard.writeText(getTaskLink());
                }}
              >
                <MDIcons title="Link_svg" />
              </div>
            </CustomTooltip>
            <CustomTooltip
              title={
                <React.Fragment>
                  <div>Delete this task!</div>
                </React.Fragment>
              }
            >
              <button id="danger" className="btn--tertiary btn--M--centered" onClick={openDeleteDialoge}>
                <DeleteIcon />
              </button>
            </CustomTooltip>
          </div>
        </>
      }
      dialogActions={
        <div className="NewComment__Container">
          <TextareaAutosize
            aria-label="empty textarea"
            placeholder="Insert a new comment ..."
            style={{ width: "100%", padding: 10 }}
            className="TaskCard__Description"
            value={comment}
            name="notes"
            onChange={(e) => setComment(e.target.value)}
          />
          <button className="btn--primary btn--S flex-row--S addComment__Button " onClick={addComment}>
            <span>Comment</span>
          </button>
        </div>
      }
    >
      {taskCardLoading ? (
        <Box sx={{ width: "100%" }}>
          <ThemeProvider theme={progressBarTheme}>
            <LinearProgress />
          </ThemeProvider>
        </Box>
      ) : (
        <Box sx={{ width: "100%", backgroundColor: "#E5E5E5", height: "4px" }}></Box>
      )}

      <TaskContent />

      <DeleteTaskDialog
        open={deleteDialogeOpen}
        onCancel={() => {
          closeDeleteDialoge(false);
        }}
        onYes={() => closeDeleteDialoge(true)}
      />
    </AlertDialog>
  );
};

export default TaskCard;
