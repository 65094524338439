"use client";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider,  } from '@react-oauth/google';
import { ErrorBoundary } from "react-error-boundary";
import MDIcons from './components/Common/MDIcons';

function ErrorPage({ error, resetErrorBoundary }){
  return (
    <div className='AppError__Container'>
      
      <MDIcons title='page_not_found' />

      <div className='opps'>Opps...something went wrong!</div>
      <div className='message'>Please refresh the page!</div>
      </div>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="800279025061-nliijtdpur2607u1v8nbqdqc6p40ncfa.apps.googleusercontent.com">
    <ErrorBoundary fallbackRender={ErrorPage}>

        <App />
          
    </ErrorBoundary>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
