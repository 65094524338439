import Skeleton from '@mui/material/Skeleton';
import DashboardLoading from "./DashboardCardsLoading";

export default function ProfileLoading() {
    return (
        <div className="AppContainer collapsed">
            <div className="SideBar__Container collapsed">
                <div className="side-nav">
                    <div className="side-nav__listContainer">
                        {Array.from(Array(5).keys()).map((element) => {
                            return (
                                <Skeleton key={element} variant="rectangular" width={40} height={40} sx={{ marginY: 1.25, borderRadius: 1.25 }} />
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="bi-report-wrapper collapsed">
                <header className="header-bi" style={{ height: 65 }}>
                    {Array.from(Array(2).keys()).map((element) => {
                        return (
                            <Skeleton key={element} variant="rectangular" width={200} height={54} sx={{ margin: 1.25, borderRadius: 1.25 }} />
                        );
                    })}
                </header>
                <DashboardLoading />
            </div>
        </div>
    )
}