import { OptionTypeBase } from "react-select";
import { getFiscalYearStartAndEnd, isDateIntersectWithTwoDates } from "../utils/DateFunctions";
import { ISelectedFilters } from "./CompanyFilters";
import { HeadCountInformation } from "./HeadCount";

export class HeadCountSelectedFilters {
    private __fiscalYearStartDate: Date = new Date(new Date().getFullYear(), 0, 1);
    private __fiscalYearEndDate: Date = new Date(new Date().getFullYear(), 0, 1);
    private __startDate: Date = new Date(new Date().getFullYear(), 0, 1);
    private __endDate: Date = new Date(new Date().getFullYear(), 11, 31);
    private __options: ISelectedFilters = {};
    private __offset: number = 0

    constructor(offset: number = 0) {
        const FiscalYearDates: Date[] = getFiscalYearStartAndEnd(offset)
        this.__offset = offset;
        this.__startDate = this.__fiscalYearStartDate = FiscalYearDates[0];
        this.__endDate = this.__fiscalYearEndDate = FiscalYearDates[1];
        this.__options = {}
    }
    private clone(): HeadCountSelectedFilters {
        var cloned: HeadCountSelectedFilters = new HeadCountSelectedFilters(this.__offset)
        Object.keys(this).forEach(key => {
            if (key in this)
                cloned[key] = this[key]
        })
        return cloned
    }

    public get startDate() {
        return this.__startDate;
    }
    public get endDate() {
        return this.__endDate;
    }
    public filterSelectedOptions(filterName: string) {
        return this.__options[filterName] ? this.__options[filterName] : [];
    }
    public updateDate(startDate: Date, endDate: Date): HeadCountSelectedFilters {
        this.__startDate = startDate;
        this.__endDate = endDate;
        return this.clone()
    }
    public resetFilters(): HeadCountSelectedFilters {
        this.__startDate = this.__fiscalYearStartDate;
        this.__endDate = this.__fiscalYearEndDate;
        this.__options = {}
        return this.clone()
    }
    public updateSelectedOptions(key: string, slectedOptions: OptionTypeBase[]): HeadCountSelectedFilters {
        this.__options[key] = slectedOptions
        return this.clone()
    }

    public isEmployeeMatching(employee: HeadCountInformation): boolean {
        if (!isDateIntersectWithTwoDates(employee.startDate, this.__startDate, this.__endDate) && employee.endDate && !isDateIntersectWithTwoDates(employee.endDate, this.__startDate, this.__endDate)) {
            return false
        }
        return Object.keys(this.__options).filter(key => key !== "expenses_type").every((key: string) => {
            // for every filter either the filter is empty or this employe has at least one match with selected options
            return this.__options[key].length === 0 || this.__options[key].some((value: OptionTypeBase) => employee[key].toString() === value.value.toString())
        })
    }

    private isDefaultDate(): boolean {
        return this.startDate === this.__fiscalYearStartDate && this.endDate === this.__fiscalYearEndDate
    }
    public filterCount(): number {
        let count = !this.isDefaultDate() ? 1 : 0;
        Object.keys(this.__options).map((key) => count += this.__options[key].length)
        return count;
    }
}