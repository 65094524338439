import { useCallback, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { AppModules, AppModuleUrl, ModuleConverter, ModuleType } from '../../types/CompanyModules';
import EmbedModuleViewer from '../EmbedModules/EmbedModuleViewer';
import NoUserCompany from '../Common/NoUserCompany';
import GeneralHeader from '../Common/GeneralHeader';
import NoDataFound from '../Common/NoDataFound';
import { ModuleContext } from '../../contexts/ModuleContext';
import { EmbedExcelLoadingContextProvider } from '../../contexts/EmbedExcelLoadingContext';
import NoViewerFound from '../Common/NoViewerFound';
import HeadCountModule from '../HeadCountModule';
import ExportQBOToExcel from '../ExportQBO/ExportQBOToExcel';
import TasksModule from '../Tasks';
import PayrollViews from '../TicketingSystem/PayrollViews';
import MonthEndCloseViews from '../TicketingSystem/MonthEndViews/MonthEndViewsRoot';
import { useMatch,useNavigate, useParams } from 'react-router-dom';


// interface MatchParams {
//     module_url: AppModuleUrl;
// }


export default function AppModuleViewer() {
    const {module_url} = useParams()
    const navigate = useNavigate();

    const { selectedCompany } = useContext(AuthContext);
    const { appModule, setAppModule, appModuleType, setAppModuleType } = useContext(ModuleContext);
    const [title, setTitle] = useState<string>(ModuleConverter.UrlToId(module_url) === AppModules.DEFAULT ? "" : ModuleConverter.UrlToName(module_url))

    useEffect(() => {
        setAppModule(ModuleConverter.UrlToId(module_url) || AppModules.DEFAULT)
        setAppModuleType(selectedCompany ? selectedCompany.getModuleType(appModule) : ModuleType.DEFAULT)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        document.title += ` - ${title}`;
        return () => { document.title = 'Mighty Digits'; };
    }, [title]);

    useEffect(() => {
        if (selectedCompany) {
            
            if (!selectedCompany.isModuleExists(ModuleConverter.UrlToId(module_url))) {
                
                var new_module = selectedCompany.getFirstAvailableModule()
                if (new_module === AppModules.DEFAULT)
                    navigate('/not-found')
                else {
                    setAppModule(new_module)
                    setAppModuleType(selectedCompany.getModuleType(new_module))
                    setTitle(ModuleConverter.UrlToName(ModuleConverter.IdToUrl(new_module)))
                    navigate(`/${selectedCompany?.getURL()}/${ModuleConverter.IdToUrl(new_module)}`)
                }
            }
            else {
                setAppModuleType(selectedCompany.getModuleType(ModuleConverter.UrlToId(module_url)))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompany]);

    useEffect(() => {
        
        if (selectedCompany) {
            
            // check no module url or invalid module url
            if (!module_url || ModuleConverter.UrlToId(module_url) === AppModules.DEFAULT) {
                var new_module = selectedCompany.getFirstAvailableModule()
                if (new_module === AppModules.DEFAULT && new_module !== appModule)
                    navigate('/not-found')
                else if (new_module !== appModule) {
                    // on page reload to check if the module changed
                    setTitle(ModuleConverter.UrlToName(ModuleConverter.IdToUrl(new_module)))
                    setAppModule(new_module)
                    setAppModuleType(selectedCompany.getModuleType(new_module))
                    navigate(`/${selectedCompany?.getURL()}/${ModuleConverter.IdToUrl(new_module)}`)
                }
            } else {
                // if valid module url and not the current module, apply it.
                
                var module_allowed = selectedCompany.getModuleUrls().includes(module_url) 
                if (module_allowed) {
                    if (ModuleConverter.UrlToId(module_url) !== appModule) {
                        setTitle(ModuleConverter.UrlToName(module_url))
                        // 
                        setAppModule(ModuleConverter.UrlToId(module_url))
                        // 
                        setAppModuleType(selectedCompany.getModuleType(ModuleConverter.UrlToId(module_url)))
                    }
                } else {
                    new_module = selectedCompany.getFirstAvailableModule()
                    setTitle(ModuleConverter.UrlToName(ModuleConverter.IdToUrl(new_module)))
                    setAppModule(new_module)
                    setAppModuleType(selectedCompany.getModuleType(new_module))
                    navigate(`/${selectedCompany?.getURL()}/${ModuleConverter.IdToUrl(new_module)}`)
                }
                
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [module_url]);



    const wrapperRetun = useCallback(() => {
        if (!selectedCompany)
            return <><GeneralHeader renderDivisions={false} title={title} /><NoUserCompany /></>
        if (appModule === AppModules.DEFAULT){
            return <><GeneralHeader renderDivisions={false} title={title} /> <NoDataFound /></>}
        switch (appModuleType) {
            case ModuleType.EMBED_EXCEL:
                return (
                    <EmbedExcelLoadingContextProvider>
                        <GeneralHeader renderDivisions={true} title={title} /><EmbedModuleViewer />
                    </EmbedExcelLoadingContextProvider>
                )
            case ModuleType.CUSTOM:
                switch (appModule) {
                    case AppModules.HEADCOUNT:
                        return (<HeadCountModule />)
                    case AppModules.EXPORT_FINANCIALS:
                        return (<ExportQBOToExcel />)
                    // case AppModules.TASKS:
                    //     return (<TasksModule />)
                    // case AppModules.PAYROLL:
                    //     return (<TasksModule />)
                    case AppModules.MONTH_END_CLOSE:
                        return (<TasksModule />)
                    default:
                        return (<><GeneralHeader renderDivisions={false} title={title} /> <NoViewerFound /></>)
                }
            case ModuleType.DEFAULT:
                return <><GeneralHeader renderDivisions={false} title={title} /> <NoViewerFound /></>
            default:
                return <><GeneralHeader renderDivisions={false} title={title} /> <NoViewerFound /></>
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appModule, appModuleType])

    return (
        <>
            {wrapperRetun()}
        </>
    )
}