import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import CircularLoader from "../../Common/CircularLoader";
import { resetPassword, userForgetPassword, verifyPasswordCode } from "../../../APIFunctions/auth";
import { UserProfile } from "../../../types/Auth";
import { AuthContext } from "../../../contexts/AuthContext";

import "./ForgotPassword.css";

const initialState = {
  email: "",
  resetCode: "",
  password: "",
  confirm_password: "",
};

const initialErrors = {
  email: false,
  verifyCode: false,
  verifyCodeTrials: 0,
  password: false,
  confirm_password: false,
};
export enum ForgetPasswordFormStatus {
  SendTempCode,
  VerifyCode,
  ResetPassword,
}

export default function ForgotPassword() {
  const { setUserProfile, setLocalToken, isProfileCompleted, setUserSelectedCompany } = useContext(AuthContext);
  const [state, setState] = useState(initialState);
  const [formState, setFormState] = useState<ForgetPasswordFormStatus>(ForgetPasswordFormStatus.SendTempCode);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(initialErrors);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, item: string) => {
    setErrors(initialErrors);
    setState((prev) => ({ ...prev, [item]: e.target.value }));
  };
  const sendVerifyCode = async (): Promise<any> => {
    if (state.email.length <= 0) {
      setErrors((prev) => ({ ...prev, email: true, verifyCode: false, password: false }));
      return;
    }
    if (!state.email.match(/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/)) {
      setErrors((prev) => ({ ...prev, email: true, verifyCode: false, password: false }));
      return;
    }
    setIsLoading(true);
    const result = await userForgetPassword(state.email);
    if (result.detail === `Check your ${state.email} to reset the password`) {
      setIsLoading(false);
      setErrors((prev) => ({ ...prev, email: false, verifyCode: false, password: false }));
      setFormState(ForgetPasswordFormStatus.VerifyCode);
    } else {
      setIsLoading(false);
      setErrors((prev) => ({ ...prev, email: true, verifyCode: false, password: false }));
      setState(initialState);
    }
  };
  const verifyCode = async () => {
    if (errors.verifyCodeTrials === 5) window.location.reload();
    if (state.resetCode.length <= 0 || state.resetCode.length > 6) {
      setErrors((prev) => ({ ...prev, email: false, verifyCode: true, password: false }));
      return;
    }
    if (!state.resetCode.match(/^([0-9])/)) {
      setErrors((prev) => ({ ...prev, email: false, verifyCode: true, password: false }));
      return;
    }
    setIsLoading(true);
    const result = await verifyPasswordCode(state.email, state.resetCode);
    if (result.detail === "Correct code") {
      setIsLoading(false);
      setState((prev) => ({ ...prev, resetCode: state.resetCode }));
      setFormState(ForgetPasswordFormStatus.ResetPassword);
    } else if (result.detail === "Wrong code") {
      setIsLoading(false);
      setState((prev) => ({ ...prev, resetCode: "" }));
      setErrors((prev) => ({ ...prev, email: false, verifyCode: true, password: false, verifyCodeTrials: result.attemps }));
    } else if (result.detail === "Too much trials, please generate new code.") {
      setIsLoading(false);
      setState(initialState);
      setFormState(ForgetPasswordFormStatus.SendTempCode);
      setErrors(initialErrors);
    } else {
      setIsLoading(false);
      setErrors((prev) => ({ ...prev, email: false, verifyCode: true, password: false }));
      setState((prev) => ({ ...prev, resetCode: "" }));
    }
  };
  const passwordReset = async () => {
    if (!state.password || state.password.length < 8) {
      setErrors((prev) => ({ ...prev, email: false, verifyCode: false, password: true }));
      return;
    }
    if (state.password !== state.confirm_password) {
      setErrors((prev) => ({ ...prev, email: false, verifyCode: false, password: false, confirm_password: true }));
      return;
    }
    if (!state.resetCode.match(/^([0-9])/)) {
      setErrors((prev) => ({ ...prev, email: false, verifyCode: false, password: true }));
      return;
    }
    setIsLoading(true);
    const result = await resetPassword(state.email, state.resetCode, state.password);
    if (result.detail === "password reseted successfuly") {
      setIsLoading(false);
      if (result.token && result.profile) {
        setIsLoading(false);
        setLocalToken(result.token);
        var new_user: UserProfile = new UserProfile(result.profile);
        localStorage.setItem("UserProfile", JSON.stringify(new_user));
        localStorage.setItem("SelectedCompany", JSON.stringify(new_user.getFirstCompany()));
        setUserProfile(new_user);
        setUserSelectedCompany(new_user.getFirstCompany());
        if (!isProfileCompleted()) navigate("/complete");
      }
    } else {
      setIsLoading(false);
      setErrors((prev) => ({ ...prev, email: false, verifyCode: false, password: true }));
      setState((prev) => ({ ...prev, password: "" }));
    }
  };
  return (
    <div className="ForgotPassword__Container">
      <h1>Forgot Password</h1>
      <div>
        {formState === ForgetPasswordFormStatus.SendTempCode ? (
          <div className="ForgotPassword__Container__EntryContainer">
            <span>Please enter your email to reset your password</span>
            <label>Email address</label>
            <input
              type="email"
              name="email"
              placeholder="Email"
              maxLength={100}
              id="id_email"
              onChange={(e) => onChange(e, "email")}
              value={state.email}
              disabled={isLoading}
              required
              autoComplete="off"
            />
          </div>
        ) : formState === ForgetPasswordFormStatus.VerifyCode ? (
          <div className="ForgotPassword__Container__EntryContainer">
            <span>Please enter the verification code you received in your email: {state.email}</span>
            <label>Verification Code</label>
            <input
              type="text"
              name="resetCode"
              placeholder="Verification Code"
              maxLength={6}
              id="id_resetCode"
              pattern="[0-9.]+"
              onChange={(e) => onChange(e, "resetCode")}
              value={state.resetCode}
              disabled={isLoading || errors.verifyCodeTrials === 5}
              required
            />
          </div>
        ) : (
          formState === ForgetPasswordFormStatus.ResetPassword && (
            <>
              <div className="ForgotPassword__Container__EntryContainer">
                <span>Welcome, {state.email}</span>
                <input type="email" name="email" placeholder="Email" maxLength={100} id="id_email" value={state.email} disabled hidden required autoComplete="off" />
                <label>New Password</label>
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  maxLength={100}
                  id="id_password"
                  onChange={(e) => onChange(e, "password")}
                  value={state.password}
                  disabled={isLoading}
                  required
                />
                <label>Confirm New Password</label>
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  maxLength={100}
                  id="id_password"
                  onChange={(e) => onChange(e, "confirm_password")}
                  value={state.confirm_password}
                  disabled={isLoading}
                  required
                />
                <br />

                {errors.confirm_password && (
                  <span style={{ color: "red" }} className="formErrors">
                    Passwords are not matched.
                  </span>
                )}
                {errors.password && (
                  <span style={{ color: "red" }} className="formErrors">
                    Password should be at least 8 char.
                  </span>
                )}
              </div>
            </>
          )
        )}
        {errors.email && <span className="formErrors">Please enter a valid email</span>}
        {errors.verifyCode && errors.verifyCodeTrials < 5 ? (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <span className="formErrors">Please enter a valid verification code.</span>
            <span className="formErrors">You have {5 - errors.verifyCodeTrials} attempts left.</span>
          </div>
        ) : (
          errors.verifyCodeTrials === 5 && (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <span className="formErrors">Please get a new code again.</span>
            </div>
          )
        )}
        <div className="Auth__BottomSection">
          {isLoading ? (
            <CircularLoader color={"#007bff"} size={40} customClassName="custom-loader" />
          ) : formState === ForgetPasswordFormStatus.SendTempCode ? (
            <button disabled={isLoading} className="btn--primary btn--L btn--fullWidth" onClick={() => sendVerifyCode()}>
              {" "}
              Send Verification Code{" "}
            </button>
          ) : formState === ForgetPasswordFormStatus.VerifyCode ? (
            <button disabled={isLoading} className="btn--primary btn--L btn--fullWidth" onClick={() => verifyCode()}>
              {errors.verifyCodeTrials === 5 ? "Restart" : "Verify Code "}
            </button>
          ) : (
            formState === ForgetPasswordFormStatus.ResetPassword && (
              <button
                disabled={isLoading || Object.values(state).some((value) => value === "")}
                className={isLoading || Object.values(state).some((value) => value === "") ? "btn--primary--disabled btn--L btn--fullWidth" : "btn--primary btn--L btn--fullWidth"}
                onClick={() => passwordReset()}
              >
                {" "}
                Update Password{" "}
              </button>
            )
          )}
          <div className="m-4">
            <label>
              Remembered your account ? <Link to="/">Login</Link>
            </label>
          </div>
          {/* <div className="m-4">
                        <label>Don’t have an account ? <Link to="/register">Register</Link></label>
                    </div>
                    <div className="Auth__GoogleBtn">
                        <MyGoogleLogin />
                    </div> */}
        </div>
      </div>
    </div>
  );
}
