import * as React from "react";
import Box from "@mui/material/Box";
import {
  DataGridPro,
  GridToolbarExport,
  GridCellParams,
  GridColDef,
  useGridApiContext,
  useGridSelector,
  GridRenderCellParams,
  gridFilteredDescendantCountLookupSelector,
  DataGridProProps,
  GRID_TREE_DATA_GROUPING_FIELD,
  MuiEvent,
  GridCallbackDetails,
  useGridApiRef,
  gridSortedRowIdsSelector,
  gridVisibleColumnDefinitionsSelector,
} from "@mui/x-data-grid-pro";
import ContextMenuMUIDataGrid from "../ContextMenuMUIDataGrid";
import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { MonthEndCloseContext } from "../../../contexts/TicketingSystem/MonthEndCloseContext";

export default function DataGridWithCommentContextMenu({ rows, columns, selectedCell, setSelectedCell, createCommentMutation, coordinates, transactionType, showCommentsPan }) {


  
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        marginTop:"1rem",
        border: "solid 1px var(--Neutral150)",
        borderRadius:"4px",
        "& .MuiDataGrid-columnHeaderTitle": {
          "& ::first-letter": {
            textTransform: "capitalize",
          },
          border: "none",
        },
        "& .HIGHLIGHT": {
          background: "rgb(255,253,204) !important",
        },
        "& .MuiDataGrid-root": {
          border: "none",
          "& .MuiDataGrid-footerContainer": {
            display: "none",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            // fontWeight: "bold",
            fontSize: "12px",
            lineHeight: "16px",
            color: "#666687",
          },
          "& .MuiDataGrid-iconSeparator": {
            height: "none",
            borderLeft: "none",
            display: "none",
          },
          "& .MuiDataGrid-columnHeader--sortable": {
            "& .MuiDataGrid-iconButtonContainer": {
              visibility: "visible",
              width: "auto",
            },
          },
          "& .MuiDataGrid-columnHeaders": {
            height: "37px",
            minHeight: "37px !important",
            background: "#fff",
            borderBottom: "1px solid #EAEAEF",
          },
          "& .MuiDataGrid-virtualScroller": {
            marginTop: "44px !important",
          },
          "& .MuiDataGrid-columnHeader": {
            height: "37px",
            borderRight: "none",
            "& .MuiDataGrid-columnHeaderTitleContainer": {
              flexDirection: "row",
            },
            "& .MuiDataGrid-columnHeaderDraggableContainer": {
              flexDirection: "row",
              "& .MuiDataGrid-menuIcon": {
                visibility: "visible",
                marginRight: "2px",
                width: "auto !important",
              },
            },
          },
          "& .MuiDataGrid-cell": {
            "& :focus": {
              outline: "none",
            },
            "& :focus-wthin": {
              outline: "none",
            },
            "& span": {
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
          },
        },
        "& .MuiDataGrid-row": {
          backgroundColor: "#fff",
          "& :hover": {
            cursor: "pointer",
          },
          "&.Mui-selected": {
            backgroundColor: "#85b4401A !important",
          },
        },
        "& .MuiDataGrid-cell": {
          color: "#212134",
          fontSize: 14,
          borderBottom: "1px solid #EAEAEF",
          borderRight: "none",
          justifyContent: "flex-start",
          "& :focus": {
            outline: "solid #85b4401A 1px",
          },
          "& :focus-wthin": {
            outline: "#85b4401A",
          },
          "& span": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          },
        },
        "& .MuiCheckbox-root": {
          color: "#C0C0CF",
        },
        "& .MuiCheckbox-root >svg": {
          border: "0px solid #FFFFFF",
          height: "20px",
          width: "20px",
        },
        "& .MuiCheckbox-root.Mui-checked": {
          color: "#85b440 !important",
        },
        "& .DataGrid-marked-row--header": {
          height: "37px !important",
          maxHeight: "41px !important",
          "& .MuiDataGrid-cell": {
            height: "41px !important",
            maxHeight: "41px !important",
            borderRight: "none",
          },
        },
      },
    })
  );

  const classes = useStyles();

  const selectCellForComment = async (params: GridCellParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
    setSelectedCell(params);
  };

  const commentHighlightFlag = (rowParams) => {
    if (rowParams.row["highlight_field"].includes(rowParams.field) && showCommentsPan) {
      return "HIGHLIGHT";
    }
    return false;
  };

  const handleContextMenu = (event: MouseEvent) => {
    const target = event.target as HTMLElement
    let col_id = target.dataset.field
    if (col_id === undefined) {
      var parent = target.parentElement;
      col_id = parent?.dataset.field
    }
    const currentTarget = event.currentTarget as HTMLElement
    const row_id = currentTarget.dataset.id
    
    const row_index = rowIndexLocator(row_id)
    const transaction_id = rows[row_index]['transaction_id']
    setSelectedCell({field:col_id,row:{id:row_id,transaction_id:transaction_id, transaction_type:transactionType}})
    event.preventDefault();
    // setSelectedCell(Number(event.currentTarget.getAttribute('data-id')));
    setContextMenu(contextMenu === null && selectedCell ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null);
  };

  const apiRef = useGridApiRef();

  const rowIndexLocator = (rowId) => {
    
    try {
      if (rowId) {
        return gridSortedRowIdsSelector(apiRef).findIndex((id) => id === rowId);
      } else {
        return -1
      }
    } catch {
      return -1
    }
  };

  const colIndexLocator = (colId) => {
    try {
      if (colId) {
        return gridVisibleColumnDefinitionsSelector(apiRef).findIndex((column) => column.field === colId);
      } else {
        return -1
      }
    } catch {
      return -1
    }
  };

  // const addHighlighting = () => {
    const updatedColumns = columns.map(x => {
      return (
        {
          ...x,
          cellClassName: (params: GridCellParams<number>) => {
              const highlightFlag = commentHighlightFlag(params);
          
              return highlightFlag === "HIGHLIGHT" ? "HIGHLIGHT" : "NOERROR";
            }
        }
      )
    })
    
  // }

  React.useEffect(() => {
    
    const { rowIndex, colIndex } = coordinates;
    if (rowIndex && colIndex) {
      const rowIndexFound = rowIndexLocator(rowIndex);
      const colIndexFound = colIndexLocator(colIndex);
      if (rowIndexFound !== -1 && colIndexFound !== -1) {
        apiRef.current.scrollToIndexes({ rowIndex: rowIndexFound, colIndex: colIndexFound });
        setTimeout(() => {
          apiRef.current.setCellFocus(rowIndex, colIndex);
        }, 500);
      }
    }
  }, [apiRef, coordinates]);



  return (
    <div className={classes.root}>
      <DataGridPro
      autoHeight
        rows={rows}
        columns={updatedColumns}
        apiRef={apiRef}
        componentsProps={{
          row: {
            onContextMenu: handleContextMenu,
            style: { cursor: "context-menu" },
          },
        }}
        onCellClick={(params, event, details) => selectCellForComment(params, event, details)}
      />
      <ContextMenuMUIDataGrid contextMenu={contextMenu} setContextMenu={setContextMenu} handleCreateComment={createCommentMutation} />
    </div>
  );
}
