import { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { IFilterOptionItem } from '../../types/CompanyFilters';
import { customSelectStyles } from './SelectComponent';

interface CreatableSelectComponentProps {
  options: Array<{ value: string, label: string }>;
  value: { value: string, label: string };
  onChange: (value: string) => void;
  onCreate: (inputValue: string) => Promise<IFilterOptionItem | null>;
  isLoading: boolean;
}

export default function CreatableSelectComponent(props: CreatableSelectComponentProps) {
  const { options, value, onChange, onCreate, isLoading } = props;
  const [selectedValue, setSelectedValue] = useState(value);

  const handleChange = (newValue: any) => {
    setSelectedValue(newValue);
    onChange(newValue ? newValue.value : '');
  };
  const handleCreate = async (inputValue: string) => {
    const newOption = await onCreate(inputValue);
    if (newOption)
      setSelectedValue(newOption);
  };

  return (
    <CreatableSelect
      isSearchable
      isClearable
      isDisabled={isLoading}
      isLoading={isLoading}
      onChange={handleChange}
      onCreateOption={handleCreate}
      options={options}
      value={selectedValue}
      styles={customSelectStyles}
      theme={theme => ({
        ...theme,
        borderRadius: 10,
        colors: {
          ...theme.colors,
          primary: 'rgba(98, 120, 140, 0.4)',
          primary50: '#F4F4F4',
          primary25: '#F4F4F4',
        },
      })}
    />
  );
}
