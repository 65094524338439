import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { MilestoneContext } from "../../../contexts/TicketingSystem/MilestoneContext";
import { createAsanaSubTask, deleteAsanaTask, retrieveAsanaSubTask, updateAsanaTask } from "../../../APIFunctions/Asana";
import { ReactComponent as EmailLarge } from "../img/Email__Large.svg";
import { SubTasksContext } from "../../../contexts/TicketingSystem/SubTasksContext";
import "./style.css";
import MilestoneTaskContainerHeader from "./MilestoneTaskContainerHeader";
import SubTaskContainerHeader from "./SubTaskContainerHeader";
import SubTaskContainer from "./SubTaskContainer";
import SubTasksList from "./SubTasksList";
import InternalQuestions from "./InternalQuestions";
import MDIcons from "../../Common/MDIcons";
import { CircularProgress } from "@mui/material";
import MonthEndCommentsList from "./MilestoneTaskComponent/Financials/CellComments/MonthEndCommentsList";
import { AuthContext } from "../../../contexts/AuthContext";
import { MilestoneCardOutletContextType } from "../MonthEndViews/Components/PlayrollCard/MilestoneCard";
import { getMonthEndCellCommentsList } from "../../../APIFunctions/ticketing_system";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import LinksTableComponent from "./Links/LinksTableComponent";
import { Outlet, useNavigate, useOutletContext, useParams } from "react-router-dom";
import AgedReportsRoot from "./MilestoneTaskComponent/AgedReports";
import { asanaTaskDateStringToDate, getMonth } from "../../../utils/DateFunctions";
import { MilestoneLinksContext } from "../../../contexts/TicketingSystem/MilestoneLinksContext";
import { useLinksList } from "./Links/LinksTableComponent/Hooks";
import RichTextEditor, { convertQuillHTMLToAsana, convertQuillMentionsToAsana } from "../../Common/RichTextEditor";
import debounce from "lodash.debounce";
import { TaskServicesContext } from "../../../contexts/Task/TaskServicesContext";
import DefaultMilestoneFileLinksUI from "./DefaultMilestoneFileLinksUI";

const MilestoneTaskContainer = () => {
  const navigate = useNavigate();
  let { milestoneTaskParam } = useParams();
  const { team } = useContext(TaskServicesContext);
  const { milestoneTask, setMilestoneTask, setIsMilestoneTaskOpen, displayComponentFlag, setDisplayComponentFlag } = useOutletContext<MilestoneCardOutletContextType>();
  const { setPayrollLoading, currentStage, updateMilestoneTasksCompletionArray, fullWidthMode, selectedMilestone } = useContext(MilestoneContext);
  const {
    subTasksDict,
    updatePreparationStageTasks,
    addSubTask,
    editSubTasksDict,
    displayedSubTasks,
    setSubTasksToDisplay,
    displaySubTaskFlag,
    setDisplaySubTaskFlag,
    subTask,
    setSubTask,
    openSubTask,
    displayMilestoneCardContent,
    preparationStageTasks,
  } = useContext(SubTasksContext);

  const type = useRef("");
  const [completed, setCompleted] = useState<boolean>(milestoneTask?.completed);
  const [reviewTask, setReviewTask] = useState<any>();
  const [previousReviewTask, setPreviousReviewTask] = useState<any>();
  const [notes, setNotes] = useState<string>("");

  // const [subTaskAttachments, setSubTaskAttachments] = useState<any[]>();

  const ReviewFlag = currentStage?.name === "Manager Review" || currentStage?.name === "Final Review" ? true : false;

  const onAddSubTask = async () => {
    setPayrollLoading(true);
    // update on asana
    await addSubTask(milestoneTask["gid"]);
    // update locally
    // setSubTasks([...subTasks, newTask]);
    setPayrollLoading(false);
  };

  const saveMilestoneTask = (e, type) => {
    if (type === "notes") {
      const cleaned_notes = convertQuillHTMLToAsana(convertQuillMentionsToAsana(e));
      const newMilestoneTask = { gid:milestoneTask.gid, name:milestoneTask?.name,  html_notes: cleaned_notes };
      setMilestoneTask(newMilestoneTask);
      updateAsanaTask(newMilestoneTask);
      updatePreparationStageTasks(newMilestoneTask)
    }
  };

  const onChangeNotes = (e) => {
    // setNotes(e);
    debouncedMilestoneTaskSave(e, "notes");
  };

  const debouncedMilestoneTaskSave = useMemo(() => {
    return debounce(saveMilestoneTask, 500);
  }, [notes]);

  const changeSubTaskInList = (updatedSubTask) => {
    editSubTasksDict(milestoneTask?.["gid"], updatedSubTask, "edit");
  };

  const onDeleteSubTask = async (deletedTask) => {
    setDisplaySubTaskFlag(false);
    const response = await deleteAsanaTask(deletedTask.gid);

    if (response["response"]?.status === 401) {
      setTimeout(() => {
        onDeleteSubTask(deletedTask.gid);
      }, 1000);
    } else {
      setSubTask(null);
      // const newSubTaskArray = displayedSubTasks?.filter((x) => x.gid !== GID);
      editSubTasksDict(milestoneTask["gid"], deletedTask, "delete");
    }
  };

  const onComplete = () => {
    // for completing a subtask
    if (milestoneTask?.gid) {
      const newItem = { ...milestoneTask };
      newItem.completed = !milestoneTask.completed;
      updateAsanaTask({
        gid: milestoneTask.gid,
        completed: !completed,
      });
      updatePreparationStageTasks(newItem);
      setCompleted(!completed);
      updateMilestoneTasksCompletionArray(newItem.completed, milestoneTask.gid);
    }
  };

  const onCompleteSubTask = async () => {
    setPayrollLoading(true);
    if (subTask?.gid) {
      const newItem = { ...subTask };
      newItem.completed = !subTask.completed;
      const response = await updateAsanaTask({
        gid: subTask.gid,
        completed: newItem.completed,
      });

      if (response["response"]?.status === 401) {
        setTimeout(() => {
          updateAsanaTask({
            gid: subTask.gid,
            completed: newItem.completed,
          });
        }, 1000);
      }
      setSubTask(newItem);
      editSubTasksDict(milestoneTask["gid"], newItem, "edit");
    }

    // adjust the subtasks list

    setPayrollLoading(false);
  };

  const onClose = () => {
    navigate("..");
    setIsMilestoneTaskOpen(false);
    setMilestoneTask(null);
    setDisplayComponentFlag(false);
  };

  const onCloseSubTask = () => {
    // navigate('..')
    setSubTask(null);
    setDisplaySubTaskFlag(false);
    setDisplayComponentFlag(false);
  };

  const onOpenComponent = () => {
    navigate("component");
    setDisplayComponentFlag(true);
  };

  const setMilestoneTaskType = () => {
    // discuss with Mihail: does useRef make sense here?
    if (milestoneTask?.name === "Email Draft") {
      type.current = "Email";
    } else if (milestoneTask?.name === "Workpapers") {
      type.current = "Workpapers";
    } else if (milestoneTask?.name === "Prepare backup sheet") {
      type.current = "Prepare backup sheet";
    } else if (milestoneTask?.name === "Draft Financial Package") {
      type.current = "Draft Financial Package";
    } else if (milestoneTask?.name === "Financials") {
      type.current = "Financials";
    } else if (milestoneTask?.name === "AP report") {
      type.current = "AP report";
    } else if (milestoneTask?.name === "AR report") {
      type.current = "AR report";
    } else {
      type.current = "";
    }
  };

  const getReviewSubTask = async () => {
    // get the review subtask inside
    let reviewTask;
    if (ReviewFlag && milestoneTask) {
      setPayrollLoading(true);
      const response = await retrieveAsanaSubTask(milestoneTask?.gid);
      if (response?.data) {
        // look for a subtask inside the current stage which is named "Manager Review"
        reviewTask = response.data.find((x) => x.name === currentStage?.name);
        if (!reviewTask) {
          // if the review task doesn't exist then create it.
          const newSubTaskObj = {
            data: { name: currentStage?.name },
          };
          reviewTask = await createAsanaSubTask(milestoneTask?.gid, newSubTaskObj);
        }
      }
      setPayrollLoading(false);
    }
    setReviewTask(reviewTask);
  };

  useEffect(() => {
    if (milestoneTaskParam && preparationStageTasks.length > 0) {
      const target = preparationStageTasks?.find((x) => x.gid === milestoneTaskParam);
      setMilestoneTask(target);
    }
  }, [milestoneTaskParam, preparationStageTasks]);

  useEffect(() => {
    if (subTasksDict && milestoneTask) {
      setSubTasksToDisplay(subTasksDict, milestoneTask?.gid);
    }
    setMilestoneTaskType();
    getReviewSubTask();
  }, [subTasksDict, milestoneTask]);

  useEffect(() => {
    if (milestoneTask && window.location.href.indexOf("component") !== -1) {
      setDisplayComponentFlag(true);
    }
  }, [milestoneTask, window.location.href]);


  useEffect(() => {
    const html_string = milestoneTask?.html_notes.replace("</body>", "").replace("<body>", "").replaceAll("\n", "<br/>");
    setNotes(html_string);
    return () => {
      debouncedMilestoneTaskSave.cancel();
    };
  }, []);

  return (
    <>
      {!((type.current === "AP report" || type.current === "AR report") && displayComponentFlag) ? (
        <>
          <div className="SubTask__Container">
            {!displaySubTaskFlag && displayMilestoneCardContent && (
              <MilestoneTaskContainerHeader
                milestoneTask={milestoneTask}
                ReviewFlag={ReviewFlag}
                completed={completed}
                reviewTask={reviewTask}
                setReviewTask={setReviewTask}
                previousReviewTask={previousReviewTask}
                setPreviousReviewTask={setPreviousReviewTask}
                displayComponentFlag={displayComponentFlag}
                type={type}
                onClose={onClose}
                onComplete={onComplete}
                subTasksDict={subTasksDict}
              />
            )}
            {displaySubTaskFlag && (
              <SubTaskContainerHeader
                subtask={subTask}
                setSubTask={setSubTask}
                // retrieveAttachments={retrieveSubTaskAttachments}
                completed={subTask?.completed}
                onClose={onCloseSubTask}
                onComplete={onCompleteSubTask}
                onDelete={onDeleteSubTask}
              />
            )}
            {displayMilestoneCardContent && (
              <div className="SubTask__Body__Container">
                {!displayComponentFlag && !displaySubTaskFlag && type.current !== "" && (
                  <div className="SubTask__Body__Container__MilestoneTaskComponentButton">
                    {type.current === "Email" && (
                      <div id="" className="SubTask__Body__Container__MilestoneTaskComponentButton__Button success" onClick={onOpenComponent}>
                        <EmailLarge />
                        <div>Open Email Editor</div>
                      </div>
                    )}
                    {type.current === "Workpapers" && <LinksTableComponent linkType={0} />}

                    {type.current === "Prepare backup sheet" && <DefaultMilestoneFileLinksUI />}

                    {type.current === "Draft Financial Package" && (
                      <div id="" className="SubTask__Body__Container__MilestoneTaskComponentButton__Button success" onClick={onOpenComponent}>
                        <>
                          <MDIcons title="Financial__Icon" />
                          <div>Generate Financial Package</div>
                        </>
                      </div>
                    )}

                    {type.current === "Financials" && (
                      <div id="Review Financials" className="SubTask__Body__Container__MilestoneTaskComponentButton__Button success" onClick={onOpenComponent}>
                        <>
                          <MDIcons title="Financial__Icon" />
                          <div>Review Financials</div>
                        </>
                      </div>
                    )}
                    {(type.current === "AP report" || type.current === "AR report") && (
                      <div id="Review Aged Reports" className="SubTask__Body__Container__MilestoneTaskComponentButton__Button success" onClick={onOpenComponent}>
                        <>
                          <MDIcons title="Financial__Icon" />
                          <div>Review Aged Reports</div>
                        </>
                      </div>
                    )}
                  </div>
                )}
                {displayComponentFlag ? (
                  <div className={fullWidthMode ? "SubTask__Body__Container__MilestoneTaskComponent full-width" : "SubTask__Body__Container__MilestoneTaskComponent"}>
                    {/* <MilestoneTaskComponent milestoneTaskName={milestoneTask["name"]} /> */}
                    <Outlet />
                  </div>
                ) : !displaySubTaskFlag ? (
                  // todo: add rich text container
                  <>
                  <div className="MilestoneCard__SubTasks__Container">
                  <RichTextEditor htmlValue={notes} setHTMLValue={setNotes} onChange={(value) => onChangeNotes(value)} people={team} placeholder={"Add notes here..."}/>
                  </div>
                  <SubTasksList openSubTask={openSubTask} setSubTask={setSubTask} changeSubTaskInList={changeSubTaskInList} onAddSubTask={onAddSubTask} />
                  </>
                ) : (
                  <>
                    <SubTaskContainer subTask={subTask} subTaskName={subTask?.["name"]} setSubTask={setSubTask} changeSubTaskInList={changeSubTaskInList} />
                  </>
                )}
              </div>
            )}
            {!displayMilestoneCardContent && !displayComponentFlag && !displaySubTaskFlag && (
              <div className="SubTask__Container__Loading">
                <CircularProgress size={100} sx={{ color: "#85b440" }} />
                <span>Please wait while we load your content ...</span>
              </div>
            )}
          </div>

          {!fullWidthMode && <InternalQuestions gid={displaySubTaskFlag ? subTask?.gid : milestoneTask?.gid} />}
          {fullWidthMode && <MonthEndCommentsList />}
        </>
      ) : (
        <>
          <div className="SubTask__Container">
            <MilestoneTaskContainerHeader
              milestoneTask={milestoneTask}
              ReviewFlag={ReviewFlag}
              completed={completed}
              reviewTask={reviewTask}
              setReviewTask={setReviewTask}
              previousReviewTask={previousReviewTask}
              setPreviousReviewTask={setPreviousReviewTask}
              displayComponentFlag={displayComponentFlag}
              type={type}
              onClose={onClose}
              onComplete={onComplete}
              subTasksDict={subTasksDict}
            />
            <AgedReportsRoot
              CustomEndDate={getMonth(asanaTaskDateStringToDate(selectedMilestone?.custom_fields?.find((x) => x.name === "Period")?.date_value?.date) || new Date())}
              parentTaskName={type.current}
            />
          </div>
        </>
      )}
    </>
  );
};

export default MilestoneTaskContainer;
