import { useContext, useEffect } from "react";
import "./SettingsModuleHeader.css";
import SectionTitle from "../../Common/SectionTitle";
import ReactTooltip from "react-tooltip";
import GeneralHeader from "../../Common/GeneralHeader";
import { UserListConext } from "../../../contexts/ManageUsers/UserListConext";

export default function SettingsModuleHeader() {
  const { tabIndex } = useContext(UserListConext);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [tabIndex]);

  return (
    <>
      <GeneralHeader renderDivisions={false} title={"Settings"} />
      <header className="settings-header-bi">
        <div className="Title__Container">
          <SectionTitle
            title="Account Settings"
          />
        </div>
        <div className="header-controllers__container">
        </div>
      </header>
    </>
  );
}
