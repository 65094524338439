import { Collapse } from "@mui/material";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { useSearchParams } from "react-router-dom";
import { dateObjToAppString } from "../../../../../../utils/DateFunctions";
import "./style.css";

const Presentation = ({ isModalOpen, startDate, setStartDate, endDate, setEndDate, setSelectedFilters, selectedFilters }) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [dateError, setDateError] = useState(false);

  function onDateChange(minDate: Date, maxDate: Date) {
    if (minDate < maxDate) {
      setSelectedFilters(selectedFilters.updateDate(minDate, maxDate));
    } else {
      setDateError(true);
    }
  }

  return (
    <>
      <Collapse
        in={isModalOpen}
        className={isModalOpen ? "collapse-open" : "collapse-closed"}
        classes={{ wrapperInner: "compare_wrapper_inner" }}
        sx={{
          "& .MuiCollapse-wrapper": {
            margin: 0,
            marginBottom: 0,
            padding: 3,
            paddingBottom: "20px",
            bgcolor: "#FFFFFF",
            height: "auto",
            paddingLeft: "30px",
          },
        }}
      >
        {
          <div style={{ display: "flex", flexDirection: "row", backgroundColor: "#FFFFFF", height: "auto", flexWrap: "wrap", alignItems: "center" }}>
            <div className="dateEntryContainer">
              <label className="label">Start Date</label>
              <DatePicker
                id="date-picker-start-date"
                selected={startDate}
                onChange={(date: Date) => {
                  if (date) {
                    // let minDate = new Date(date.getFullYear(), date.getMonth(), 1)
                    // let maxDate = new Date(selectedFilters.endDate.getFullYear(), selectedFilters.endDate.getMonth() + 1, 0)
                    setStartDate(date);
                    setSearchParams((prevParams) => {
                      return new URLSearchParams({
                        ...Object.fromEntries(prevParams.entries()),
                        ...{ start_date: dateObjToAppString(date, "-") },
                      });
                    });
                  }
                }}
                // maxDate={selectedFilters.endDate}
                // dateFormat="MMMM yyyy"
                // showMonthYearPicker
                showFullMonthYearPicker
              />
              {dateError && <label style={{ color: "red" }}>Start date should come before the end date </label>}
            </div>
            <div className="dateEntryContainer">
              <label className="label">End Date</label>
              <DatePicker
                id="date-picker-end-date"
                selected={endDate}
                onChange={(date: Date) => {
                  if (date) {
                    // let minDate = new Date(selectedFilters.startDate.getFullYear(), selectedFilters.startDate.getMonth(), 1)
                    // let maxDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)
                    setEndDate(date);
                    setSearchParams((prevParams) => {
                      return new URLSearchParams({
                        ...Object.fromEntries(prevParams.entries()),
                        ...{ end_date: dateObjToAppString(date, "-") },
                      });
                    });
                  }
                }}
                minDate={startDate}
                // dateFormat="MMMM yyyy"
                // showMonthYearPicker
                showFullMonthYearPicker
              />
            </div>
          </div>
        }
      </Collapse>
    </>
  );
};

export default Presentation;
