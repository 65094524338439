import { useContext, useEffect, useState } from 'react';
import {  useNavigate, useParams } from 'react-router-dom';
import CircularLoader from '../../Common/CircularLoader';
import { acceptInvite } from '../../../APIFunctions/auth';
import { UserProfile } from '../../../types/Auth';
import { InvitationCode__RequestAPI } from '../../../types/API/authData';
import { AuthContext } from '../../../contexts/AuthContext';
import success_people_celebrating from '../../../static/img/success_people_celebrating.png';
import './LoggedUserInvitation.css';
import { Company } from '../../../types/Company';



export default function LoggedUserInvitation() {
    const  { inviteCode } = useParams()
    const { setUserSelectedCompany, setUserProfile } = useContext(AuthContext);
    const [companyName, setCompanyName] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    async function verify(): Promise<void> {
        setIsLoading(true);
        if (inviteCode) {
            const data: InvitationCode__RequestAPI = { invitation_code: inviteCode };
            const result = await acceptInvite(data);
            if (result.company) {
                const user: UserProfile = new UserProfile(result.profile);
                localStorage.setItem("UserProfile", JSON.stringify(user));
                setUserProfile(user);
                const invitationCompany: Company = new Company(user.companies.find((company) => company.id === result.company.id) ?? user.getFirstCompany());
                setCompanyName(invitationCompany.companyName);
                localStorage.setItem("SelectedCompany", JSON.stringify(invitationCompany));
                setUserSelectedCompany(invitationCompany);
                setIsLoading(false);
            }
        }
        
        navigate('/');
    }

    useEffect(() => {

        if (inviteCode && inviteCode !== '')
            verify();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inviteCode])

    return (
        <div className="successContainer">
            {isLoading ?
                <>
                    <h4>Please wait! We are validating your invitation link</h4>
                    <CircularLoader />
                </>
                :
                <>
                    <img src={success_people_celebrating} alt="success!" />
                    <h1>Thanks! You have successfully joined&nbsp;<span>{companyName}</span>&nbsp;team</h1>
                    <h4>If you have any questions, please contact &nbsp;<a href="mailto:support@mightydigits.com">support@mightydigits.com</a>.</h4>
                    <button onClick={() => navigate('/')}>
                        Go to your account
                    </button>
                </>
            }
        </div>
    )
}