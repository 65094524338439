import { createContext, useContext, useEffect, useState } from "react";
import { retrieveAsanaTasksInSection } from "../../APIFunctions/Asana";
import { createMilestoneLink, editMilestoneLink, editMonthEndLink, getMilestoneLinks, getMonthEndLinks, retrievePayrollTypeDict } from "../../APIFunctions/ticketing_system";
import { asanaTaskDateStringToDate } from "../../utils/DateFunctions";
import { AuthContext } from "../AuthContext";
import { TaskServicesContext } from "../Task/TaskServicesContext";
import { MilestoneContext } from "./MilestoneContext";
import { MilestoneEmailContext } from "./MilestoneEmailContext";
import MonthEndCloseDefaultSecondLine from "../../components/TicketingSystem/MilestoneEmail/EmailPreviewComponent/DefaultSecondLine.tsx/MonthEndClose";
import { SharepointFileInfo } from "../../types/Shareppoint";
import { MilestoneLink, MilestoneLinkInfo, PartialMilestoneLink } from "../../types/TicketingSystem";
import { SharePointFile } from "../../types/FinancialStatement";
import { useLinksList } from "../../components/TicketingSystem/Components/Links/LinksTableComponent/Hooks";
import { UseQueryResult } from "@tanstack/react-query";

export interface milestoneLinksContextProps {
  monthEndBackupFileLink: any;
  monthEndBackupFolderLink: any;
  monthEndDeferredRevenueFileLink: any;
  monthEndDeferredRevenueFolderLink: any;
  setMonthEndBackupFolderLink: (link: any) => void;
  setMonthEndDeferredRevenueFolderLink: (link: any) => void;
  editLink: (link: MilestoneLink, updateObj: any) => void;
  editMonthEndCloseLink: (link: any, updateObj: any) => void;
  milestoneLinks: MilestoneLink[];
  setMilestoneLinks: (milestoneLinks: MilestoneLink[]) => void;
}

export const MilestoneLinksContext = createContext<milestoneLinksContextProps>(null as any);

export const MilestoneLinksContextProvider = (props) => {
  const { selectedCompany } = useContext(AuthContext);
  const { milestoneType } = useContext(MilestoneContext);
  const { email } = useContext(MilestoneEmailContext);
  const [milestoneLinks, setMilestoneLinks] = useState<MilestoneLink[]>([]);
  const [monthEndBackupFileLink, setMonthEndBackupFileLink] = useState<any>();
  const [monthEndBackupFolderLink, setMonthEndBackupFolderLink] = useState<any>();
  const [monthEndDeferredRevenueFileLink, setMonthEndDeferredRevenueFileLink] = useState<any>();
  const [monthEndDeferredRevenueFolderLink, setMonthEndDeferredRevenueFolderLink] = useState<any>();

  const getLinks = async () => {
    if (email) {
      if (milestoneType === 1 && email.id) {
        if (selectedCompany) {
          const monthEndLinks = await getMonthEndLinks(selectedCompany?.id);
          const monthEndBackUpFileLinkFound = monthEndLinks.find((x) => x.name === "Month End Close Backup File");
          if (monthEndBackUpFileLinkFound) {
            setMonthEndBackupFileLink(monthEndBackUpFileLinkFound);
          }
          const monthEndFolderLinkFound = monthEndLinks.find((x) => x.name === "Month End Close Backup Folder");
          if (monthEndFolderLinkFound) {
            setMonthEndBackupFolderLink(monthEndFolderLinkFound);
          }
          const monthEndDeferredRevenueFileLinkFound = monthEndLinks.find((x) => x.name === "Month End Close Deferred Revenue File");
          if (monthEndDeferredRevenueFileLinkFound) {
            setMonthEndDeferredRevenueFileLink(monthEndDeferredRevenueFileLinkFound);
          }
          const monthEndDeferredRevenueFolderLinkFound = monthEndLinks.find((x) => x.name === "Month End Close Deferred Revenue Folder");
          if (monthEndDeferredRevenueFolderLinkFound) {
            setMonthEndDeferredRevenueFolderLink(monthEndDeferredRevenueFolderLinkFound);
          }
        }
      }
    }
  };

  const editMonthEndCloseLink = async (link, obj: any) => {
    if (link) {
      const response = await editMonthEndLink(link?.id, { link: obj.web_url, sharepoint_id: obj.sharepoint_id });
      if (link.name === "Month End Close Backup File") {
        setMonthEndBackupFileLink(response);
        const folderLink = await editMonthEndLink(monthEndBackupFolderLink?.id, { link: obj.folder_web_url, sharepoint_id: "" });
        setMonthEndBackupFolderLink(folderLink);
      } else if (link.name === "Month End Close Deferred Revenue File") {
        setMonthEndDeferredRevenueFileLink(response);
        const folderLink = await editMonthEndLink(monthEndDeferredRevenueFolderLink?.id, { link: obj.folder_web_url, sharepoint_id: "" });
        setMonthEndDeferredRevenueFolderLink(folderLink);
      }
    }
  };

  const editLink = async (link, obj: SharepointFileInfo) => {
    if (link) {
      const response = await editMilestoneLink(link?.id, obj);
      return response;
    }
  };

  const deleteSharepointFileLink = async (linkID) => {};

  useEffect(() => {
    getLinks();
  }, [email]);

  useEffect(() => {}, []);

  return (
    <MilestoneLinksContext.Provider
      value={{
        monthEndBackupFileLink,
        monthEndDeferredRevenueFileLink,
        monthEndBackupFolderLink,
        monthEndDeferredRevenueFolderLink,
        setMonthEndBackupFolderLink,
        setMonthEndDeferredRevenueFolderLink,
        editLink,
        editMonthEndCloseLink,
        milestoneLinks,
        setMilestoneLinks,
      }}
    >
      {props.children}
    </MilestoneLinksContext.Provider>
  );
};
