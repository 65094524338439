import React, { useContext, useState } from "react";
import { MilestoneEmailContext } from "../../../contexts/TicketingSystem/MilestoneEmailContext";
import { PayrollContext } from "../../../contexts/TicketingSystem/PayrollContext";
import { IFileResource } from "../../../types/API/fileResource";
import MDIcons from "../../Common/MDIcons";
import SingleFileUploadDialog from "../../Common/UploadFileDialog";
import PayrollEmailAttachmentUploadDialog from "../../Common/UploadFileDialog/PayrollEmailAttachmentUploadDialog";
import EmailPreviewComponent from "./EmailPreviewComponent";

const EmailContainerOtherActions = () => {
  const { setEmailPreviewFlag, emailPreviewFlag, setAttachedFiles, attachedFiles } = useContext(MilestoneEmailContext);
  const [emailFileUploadFlag, setEmailFileUploadFlag] = useState<boolean>(false);

  const onFileUpload = (files: IFileResource[]) => {
    setAttachedFiles(files);
  };



  return (
    <>
      <div className="SubTask__Header__OtherActions">
        <button
          id=""
          style={{ cursor: "pointer" }}
          className="btn--tertiary btn--S success"
          onClick={() => {
            setEmailPreviewFlag(true);
          }}
        >
          <MDIcons title="Publish Icon" />
          Preview
        </button>
        <button  id=""
          style={{ cursor: "pointer" }}
          className="btn--tertiary btn--S success" onClick={() => setEmailFileUploadFlag(true)}>
            <MDIcons title="add_emailAttachment_Button" />
            Attach File</button>
      </div>
      {emailPreviewFlag && <EmailPreviewComponent />}
      {emailFileUploadFlag && (
        <PayrollEmailAttachmentUploadDialog
          open={true}
          meta={"email-attachment"}
          onSuccess={onFileUpload}
          onClose={() => {
            setEmailFileUploadFlag(false);
          }}
        />
      )}
    </>
  );
};

export default EmailContainerOtherActions;
