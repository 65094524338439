import React, { useCallback, useContext, useMemo } from "react";
import { SubTasksContext } from "../../../../contexts/TicketingSystem/SubTasksContext";
import SubTaskLine from "../SubTaskLine";
import SubTaskContainer from "../SubTaskContainer";
import SubTaskContainerHeader from "../SubTaskContainerHeader";
import { useNavigate } from "react-router-dom";
import { MilestoneContext } from "../../../../contexts/TicketingSystem/MilestoneContext";
import { deleteAsanaTask } from "../../../../APIFunctions/Asana";
import { feedbackListType } from "../../types/feedback_tasks";
import { useDeleteAsanaTask, useEditAsanaTask } from "../../hooks/useAsanaTask";
import Presentation from "./Presentation";

const Container = ({ tasksFilter }) => {
  const asanaTaskEditMutation = useEditAsanaTask();
  const asanaTaskDeleteMutation = useDeleteAsanaTask();
  const { setPayrollLoading } = useContext(MilestoneContext);
  const { setSubTask, openSubTask, editSubTasksDict, displaySubTaskFlag, setDisplaySubTaskFlag, subTask, feedbackList } = useContext(SubTasksContext);
  // const [feedbackList, setFeedbackList] = useState<feedbackListType[]>([])

  const onCloseSubTask = () => {
    setSubTask(null);
    setDisplaySubTaskFlag(false);
  };

  const onCompleteSubTask = async (milestoneTaskGID) => {
    setPayrollLoading(true);
    if (subTask?.gid) {
      const newItem = { ...subTask };
      newItem.completed = !subTask.completed;
      await asanaTaskEditMutation.mutate({
        gid: subTask.gid,
        completed: newItem.completed,
      });
      setSubTask(newItem);
      editSubTasksDict(milestoneTaskGID, newItem, "edit");
    }
    // adjust the subtasks list
    setPayrollLoading(false);
  };

  const onDeleteSubTask = (milestoneTaskGID) => {
    return (deletedTask) => {
      setDisplaySubTaskFlag(false);
      asanaTaskDeleteMutation.mutate(deletedTask.gid);
      setSubTask(null);
      editSubTasksDict(milestoneTaskGID, deletedTask, "delete");
    };
  };

  const changeSubTaskInList = (milestoneTaskGID) => {
    return (updatedSubTask) => editSubTasksDict(milestoneTaskGID, updatedSubTask, "edit");
  };

  

  const filteredFeedbackList = useMemo(() => {
    if (tasksFilter.value === "Incomplete") {
      return feedbackList.filter((x) => x.task.completed === false);
    }
    if (tasksFilter.value === "Completed") {
      return feedbackList.filter((x) => x.task.completed === true);
    }
    if (tasksFilter.value === "All") {
      return feedbackList;
    }
  }, [tasksFilter, feedbackList]);

  return (
    <Presentation
      displaySubTaskFlag={displaySubTaskFlag}
      filteredFeedbackList={filteredFeedbackList}
      setSubTask={setSubTask}
      openSubTask={openSubTask}
      changeSubTaskInList={changeSubTaskInList}
      subTask={subTask}
      onCloseSubTask={onCloseSubTask}
      onCompleteSubTask={onCompleteSubTask}
      onDeleteSubTask={onDeleteSubTask}
    />
  );
};

export default Container;
