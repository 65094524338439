import APIManger from "../APIManger";
import { FinancialReportData__ResponseAPI } from "../types/API/FinancialReport";
import { Company } from "../types/Company";
import { CHECK_SYNC_STATUS, GET_TRANSACTIONS, SYNC_QB_DATA, TRANSACTION_DETAIL } from "./apiConstants";

export const syncQbData = async (company: Company, startDate:string, endDate:string, asana_milestone_gid:string): Promise<boolean> => {
  let url = SYNC_QB_DATA.replace("%COMPANY_ID%", company?.id?.toString()).replace("%START_DATE%",startDate).replace("%END_DATE%",endDate).replace("%ASANA_MILESTONE_GID%",asana_milestone_gid);
  const apiResult: any = await APIManger.post(url);
  if (apiResult.detail === "Success") return true;
  else return false;
};


export const getTransactions = async (data:any): Promise<any> => {
  let url = GET_TRANSACTIONS;
  const apiResult: any = await APIManger.post(url,data);
  return apiResult;
};

export const checkSyncStatus = async (company: Company|null, asana_milestone_gid:string): Promise<any> => {
  if (company) {
    let url = CHECK_SYNC_STATUS.replace("%COMPANY_ID%", company?.id?.toString()).replace("%ASANA_MILESTONE_GID%", asana_milestone_gid)
    const apiResult: any = await APIManger.get(url);
    return apiResult
  }
};

export const getTransactionDetail = async (company_id:any,transaction_type:string, transaction_id:string ): Promise<any> => {
  let url = TRANSACTION_DETAIL.replace("%COMPANY_ID%",company_id).replace("%TRANSACTION_TYPE%",transaction_type).replace("%TRANSACTION_ID%",transaction_id)
  const apiResult: any = await APIManger.get(url);
  return apiResult;
};
