import React, { useContext, useEffect, useState } from "react";
import { Link } from "@mui/icons-material";
import BasicMenu from "../../../Common/DropDownComponent/TaskMoreActions";
import AddIcon from "@mui/icons-material/Add";
import AlertDialog from "../../../Common/AlertDialog";
import { getPayrollFileLink, updatePayrollFileLink } from "../../../../APIFunctions/ticketing_system";
import { AuthContext } from "../../../../contexts/AuthContext";
import { PayrollContext } from "../../../../contexts/TicketingSystem/PayrollContext";
import { payrollLocation, payrollPlatform } from "../../../../types/TicketingSystem";
import { MilestoneContext } from "../../../../contexts/TicketingSystem/MilestoneContext";
import { MilestoneEmailContext } from "../../../../contexts/TicketingSystem/MilestoneEmailContext";
import SharepointExplorer from "../../../Common/SharepointExplorer/SharepointExplorer";
import SharepointExplorerGetFileLink from "../../../Common/SharepointExplorer/SharepointExplorerGetFileLink";
import { convertLegacyLinkToMilestoneLink } from "../../Components/Links/Util";

const PayrollFileLink = () => {
  const { selectedCompany } = useContext(AuthContext);
  const { setSharepointLinkInEmail, sharepointLinkInEmail, email } = useContext(MilestoneEmailContext);
  const { payrollTypeID, payrollFileLink, setPayrollFileLink } = useContext(PayrollContext);
  const [displaySharepointBrowser, setDisplaySharepointBrowser] = useState<boolean>();
  const [selectedFile, setSelectedFile] = useState<any>();

  const onCloseBrowser = () => {
    setDisplaySharepointBrowser(false);
  };

  const onOpenBrowser = () => {
    setDisplaySharepointBrowser(true);
  };

  const clearLink = async () => {
    const newPayrollObj = { ...payrollFileLink, link: "", sharepoint_id: "" };
    setPayrollFileLink(newPayrollObj);
    setSharepointLinkInEmail({name:"Payroll File Link",sharepoint_file_info:{web_url:""}});
    if (payrollFileLink?.id) {
      await updatePayrollFileLink(payrollFileLink.id, { link: "", sharepoint_id: "" });
    }
    
    setSelectedFile(null)
  };

  const updatePayrollLink = async () => {
    
    if (selectedFile) {      
      const sharepointID = selectedFile?.id
      const fileLink = selectedFile?.webUrl
      const newPayrollObj = { ...payrollFileLink, link: fileLink, sharepoint_id: sharepointID };
      
      setPayrollFileLink(newPayrollObj);
      const convertedLink = convertLegacyLinkToMilestoneLink(payrollFileLink.id, email?.id,"Excel Backup Payroll File",fileLink,sharepointID, "","","Excel Backup Payroll File",2)
      setSharepointLinkInEmail(convertedLink);
      await updatePayrollFileLink(payrollFileLink.id, { link: fileLink, sharepoint_id: sharepointID });
      onCloseBrowser();
    }
    
  };

  const getFileLink = async () => {
    if (selectedCompany && payrollTypeID) {
      const response = await getPayrollFileLink(payrollTypeID);
      if (response) {
        
        setPayrollFileLink(response);
        const link = convertLegacyLinkToMilestoneLink(response.id, email?.id,response.file_name,response.link,response.sharepoint_id, "","","Excel Backup Payroll File",2)
        setSharepointLinkInEmail(link);
      } else {
        setPayrollFileLink(null);
        setSharepointLinkInEmail(null);
      }
    }
  };

  useEffect(() => {
    getFileLink();
  }, []);

  useEffect(() => {
    updatePayrollLink();
  }, [selectedFile]);


  return (
    <>
      {sharepointLinkInEmail?.sharepoint_file_info?.web_url !== "" || selectedFile? (
        <div className="PayrollLink__Container">
          <Link />
          <a href={sharepointLinkInEmail?.sharepoint_file_info?.web_url} rel="noreferrer" target="_blank">
            <div>Payroll Backup File</div>
          </a>
          <BasicMenu deleteItem={clearLink} editItem={onOpenBrowser} type="link" />
        </div>
      ) : (
        <button id="" className="btn--tertiary btn--S success" onClick={onOpenBrowser}>
          <AddIcon sx={{ path: { fill: "#32324D !important" } }} />
          <span>Add Payroll Link</span>
        </button>
      )}
      <SharepointExplorerGetFileLink
        onCloseBrowser={onCloseBrowser}
        displaySharepointBrowser={displaySharepointBrowser}
        company={selectedCompany}
        setDisplaySharepointBrowser={setDisplaySharepointBrowser}
        setSelectedFile={setSelectedFile}
        setSelectedFolder={null}
      />
    </>
  );
};

export default PayrollFileLink;
