import React, { useContext, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Comments from "../MonthEndViews/AdminView/Comments";
import { TaskServicesContext } from "../../../contexts/Task/TaskServicesContext";

const InternalQuestions = ({ gid }) => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const { team } = useContext(TaskServicesContext)
  return (
    <div className="InternalQuestions__Container">
      <div className="Question__Container__Body">
        <Tabs className="tabs-bi" selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            <Tab className="Question__Container__tabs">INTERNAL DISCUSSION</Tab>
          </TabList>
          <TabPanel />
        </Tabs>
        {tabIndex === 0 && <Comments task_gid={gid} people={team}/>}
      </div>
    </div>
  );
};

export default InternalQuestions;
