import { UserProfile } from "./Auth";
import { FilterItem } from "./CompanyFilters";

export enum UserStatus {
    PENDING = 0,
    ACTIVE = 1,
    DEACTIVE = 2
}
export enum UserRole {
    Owner = -1,
    Admin = 0,
    User = 1,
    MDADMIN = 2
}

export interface GridSingleSelectFilterOption {
    value: string;
    label: string;
}
export class OptionsValueList {
    [key: string]: GridSingleSelectFilterOption[];
}
export class ManageUsers {
    [key: string]: any;
    id: number = 0;
    user: Partial<UserProfile> = {};
    departments: FilterItem[] = [];
    divisions: FilterItem[] = [];
    locations: FilterItem[] = [];
    embeded_views_access: boolean = true;
    headcount_access: boolean = false;
    status: UserStatus = UserStatus.DEACTIVE;
    role: UserRole = UserRole.User;
    company: number = 0;
    current_user: boolean = false;
    is_active: boolean = false;
    send_email: boolean = true;

    constructor(obj: ManageUsers | null = null, current_user: boolean = false) {
        if (obj) {
            Object.keys(obj).forEach(key => {
                if (key in this)
                    this[key] = obj[key]
            })
        }
        this.current_user = current_user
        if (this.role === UserRole.Owner)
            this.status = UserStatus.ACTIVE
    }

    public get user_name(): string {
        var first_name: string = (!this.user.first_name || this.user.first_name === "") ? "" : this.user.first_name
        const last_name: string = (!this.user.last_name || this.user.last_name === "") ? "" : this.user.last_name
        if (!first_name && !last_name && this.user.email)
            first_name = this.user.email
        return `${first_name} ${last_name} ${this.current_user ? ' (Me)' : ''}`
    }

    public set email(email: string) {
        this.user.email = email
    }
    public get email(): string {
        return this.user.email ?? ""
    }

    public get userDivisions(): string {
        const userDivisions = this.divisions.map((division) => {
            return division?.name
        })
        return userDivisions.length > 0 ? userDivisions.join(', ') : '-'
    }
    public get userDepartments(): string {
        const userDepartments = this.departments.map((department) => {
            return department?.name
        })
        return userDepartments.length > 0 ? userDepartments.join(', ') : '-'
    }
    public get userLocations(): string {
        const userLocations = this.locations.map((location) => {
            return location?.name
        })
        return userLocations.length > 0 ? userLocations.join(', ') : '-'
    }

    public get statusStr(): string {
        switch (this.status) {
            case UserStatus.ACTIVE:
                return "Active"
            case UserStatus.PENDING:
                return "Pending"
            case UserStatus.DEACTIVE:
                return "Deactive"
            default:
                return "Deactive"
        }
    }

    public get roleStr(): string {
        switch (this.role) {
            case UserRole.Admin:
                return "Admin"
            case UserRole.Owner:
                return "Owner"
            case UserRole.User:
                return "User"
            case UserRole.MDADMIN:
                return "Mighty Digits Admin"
            default:
                return "User"
        }
    }

    public isMatching = (searchText: string): boolean => {
        if (!searchText || searchText === "")
            return true
        var searchTextReg: RegExp = new RegExp(searchText, "i")
        if (
            this.user_name.match(searchTextReg) || this.statusStr.toString().match(searchTextReg) ||
            this.userDivisions.toString().match(searchTextReg) || this.userDepartments.toString().match(searchTextReg) ||
            this.userLocations.toString().match(searchTextReg) || this.roleStr.toString().match(searchTextReg)
        )
            return true
        return false
    }

    public static toAPIRepresentation = (object: ManageUsers): any => {
        let obj: any = {}
        Object.keys(object).forEach((key) => {
            obj[key] = object[key]
        })
        obj.departments = object.departments.map(item => item.id)
        obj.locations = object.locations.map(item => item.id)
        obj.divisions = object.divisions.map(item => item.id)
        return obj;
    }

}

export enum ManageUsersDialogType {
    DEFAULT,
    INVITE,
    EDIT
}