import { DataGridPro, DataGridProProps, GridRowsProp, GRID_TREE_DATA_GROUPING_FIELD, GridColDef } from '@mui/x-data-grid-pro';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ReportDataContext } from '../../../contexts/HeadCount/ReportDataContext';
import { createStyles, makeStyles } from '@mui/styles';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { ReactComponent as FilterDropdownIcon } from '../../../static/img/filter_dropdown_icon.svg';
import NoTableData from '../../Common/NoTableData';
import { EmployeeListConext } from '../../../contexts/HeadCount/EmployeeListConext';
import { SideBarContext } from '../../../contexts/SideBarContext';


const theme = createTheme({ typography: { fontFamily: ["SF Pro Text", "Arial"].join(',') } });

const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                '& .MuiDataGrid-root': {
                    backgroundColor: '#fafafa',
                    fontFamily: 'SF Pro Text',
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: "bold",
                        fontSize: 11,
                        lineHeight: "16px",
                        color: "#666687",
                    },
                    '& .MuiDataGrid-iconSeparator': {
                        height: "56px",
                        borderLeft: "1px solid #F6F6F9"
                    },
                    '& .MuiDataGrid-columnSeparator': {
                        color: 'transparent'
                    },
                    '& .MuiDataGrid-columnSeparator--sideRight': {
                        right: -14,
                    },
                    '& .MuiDataGrid-columnHeader--sortable': {
                        '& .MuiDataGrid-iconButtonContainer': {
                            visibility: 'visible',
                            width: "auto"
                        },
                    },
                    '& .MuiDataGrid-columnHeader': {
                        '& .MuiDataGrid-columnHeaderTitleContainer': {
                            flexDirection: 'row',
                        },
                        '& .MuiDataGrid-columnHeaderDraggableContainer': {
                            flexDirection: 'row',
                            '& .MuiDataGrid-menuIcon': {
                                visibility: 'visible',
                                marginRight: '2px',
                                width: 'auto !important'
                            }
                        }
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: '#FFFFFF',
                    },
                },
                '& .MuiDataGrid-row:last-child': {
                    fontWeight: 700,
                    backgroundColor: '#FFFFFF',
                },
                '& .MuiDataGrid-row:nth-child(odd)': {
                    backgroundColor: '#FFFFFF',
                },
                '& .MuiDataGrid-row:nth-child(even)': {
                    backgroundColor: '#F6F6F940',
                },
                '& .MuiDataGrid-row': {
                    '& :hover': {
                        cursor: 'pointer',
                    },
                    '&.Mui-selected': {
                        backgroundColor: '#85b4401A !important',
                        '& :hover': {
                            backgroundColor: '#85b4401A !important',
                        },
                    },
                },
                '& .MuiDataGrid-cell': {
                    color: '#666687',
                    fontSize: 12,
                    borderRight: "1px solid #F6F6F9",
                    justifyContent: 'flex-start'
                },
                '& .MuiCheckbox-root': {
                    color: '#C0C0CF',
                },
                '& .MuiCheckbox-root >svg': {
                    border: "0px solid #FFFFFF",
                    height: "20px",
                    width: "20px"
                },
                '& .MuiCheckbox-root.Mui-checked': {
                    color: '#85b440 !important',
                },
                '& .DataGrid-marked-row--true': {
                    backgroundColor: '#FDF4DC',
                },
            }
        }));

export default function ReportNestedTree() {
    const classes = useStyles();
    const { reportDataModel, selectedProjectionName, selectedPeriodName } = useContext(ReportDataContext);
    const { isModalOpen, tabIndex } = useContext(EmployeeListConext);
    const { isSideBarCollapsed } = useContext(SideBarContext)


    const [columnsData, setColumnsData] = useState<any>([])
    const [rowsData, setRowsData] = useState<GridRowsProp>([])

    useEffect(() => {
        if (tabIndex > 0) {
            setColumnsData(reportDataModel.getReportHeaders(tabIndex === 1 ? 'expenses' : "hires", selectedPeriodName.label.toLowerCase()))
            setRowsData(reportDataModel.getReportData(tabIndex === 1 ? 'expenses' : "hires", selectedProjectionName.value, selectedPeriodName.label.toLowerCase()))
        }

    }, [selectedProjectionName, selectedPeriodName, tabIndex, reportDataModel])

    const getTreeDataPath: DataGridProProps['getTreeDataPath'] = useCallback((row) => row[selectedProjectionName.value], [rowsData])

    return <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <div className={isModalOpen && !isSideBarCollapsed ?
                "tableContainer filterExtended sideBarExtended" : isModalOpen ?
                    "tableContainer filterExtended" : "tableContainer"}>
                <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 1, height: "100%" }} className={classes.root}>
                        <DataGridPro
                            treeData
                            columns={columnsData}
                            rows={rowsData}
                            getTreeDataPath={getTreeDataPath}
                            groupingColDef={{
                                leafField: selectedProjectionName.value,
                                headerName: selectedProjectionName.label,
                                hideDescendantCount: true,
                                flex: 1,
                                maxWidth: 700,
                                minWidth: 400,
                                resizable: true,
                            }}
                            pinnedColumns={{
                                left: [GRID_TREE_DATA_GROUPING_FIELD],
                            }}
                            components={{
                                ColumnMenuIcon: FilterDropdownIcon,
                                TreeDataExpandIcon: () => <AddIcon style={{ fontSize: '1.5rem' }} />,
                                TreeDataCollapseIcon: () => <RemoveIcon style={{ fontSize: '1.5rem' }} />,
                                NoRowsOverlay: NoTableData,
                            }}
                            disableColumnReorder
                            disableMultipleColumnsSorting
                            disableColumnFilter
                            disableColumnMenu
                            hideFooter
                        />
                    </div>
                </div>
            </div>
        </ThemeProvider>
    </StyledEngineProvider>
}
