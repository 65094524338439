import { ReactNode, useContext } from "react";
import { AsanaTask } from "../../types/AsanaTask";
import MDIcons from "./MDIcons";
import SectionTitleIcons from "./SectionTitleIcons";
import defaultCompany from "../../static/img/default_company.png";
import { AuthContext } from "../../contexts/AuthContext";

import { asanaTaskDateStringToDate } from "../../utils/DateFunctions";
import { getMonthShort } from "../../utils/DateFunctions";
import { getYear } from "../../utils/DateFunctions";
export interface SectionTitleProps {
  title: string;
  updateComponent?: ReactNode;
  milestone?: AsanaTask | null;
}
export default function SectionTitle({ title, updateComponent, milestone = null }: SectionTitleProps) {

  const { selectedCompany } = useContext(AuthContext);

  const milestoneStartDate = milestone?.custom_fields?.find(x => x.name === "Payroll Cycle Start Date")?.date_value?.date
  const milestoneEndDate = milestone?.custom_fields?.find(x => x.name === "Payroll Cycle End Date")?.date_value?.date
  const location = milestone?.custom_fields?.find(x => x.name === "Location")?.enum_value?.name
  const platform = milestone?.custom_fields?.find(x => x.name === "Platform")?.enum_value?.name
  const type = milestone?.custom_fields?.find(x => x.name === "Type")?.enum_value?.name  
  const period = milestone?.custom_fields?.find(x => x.name === "Period")?.date_value?.date
  const periodDate = asanaTaskDateStringToDate(period)

  return (
    <div className="title">
      <SectionTitleIcons title={title} />
      <span className="title__text--mid">{title}</span>
      {milestone && (
        <div className="title__Milestone__Container">
          <MDIcons title="filter_dropdown_icon_right" />
          <img className="company-icon-header" src={selectedCompany?.companyAvatarURL ? selectedCompany.companyAvatarURL : defaultCompany} alt="" />
          <span>{milestone.name.split(":")[0]}:</span>
          {!period? 
            <span>{platform} {location} {type} Payroll - {milestoneStartDate} - {milestoneEndDate}</span> :
            <span>{periodDate && getMonthShort(periodDate)}</span>
          }
          
        </div>
      )}
      <span className="">{updateComponent}</span>
    </div>
  );
}
