export enum ReportTypes {
    INCOME_STATEMENT= "income statement"
}

export const reportTabsDict = {
    "AP report": {
        "0":"AP Summary Report",
        "1":"AR Summary Report",
        "2":"AP Detail Report",
        "3":"AR Detail Report"
    },
    "AR report": {
        "0":"AP Summary Report",
        "1":"AR Summary Report",
        "2":"AP Detail Report",
        "3":"AR Detail Report"
    }
}

export const reportTypeToTabIndex = {
    "ap_summary_report":0,
    "ar_summary_report":1,
    "ap_detail_report":2,
    "ar_detail_report":3
}

export enum reportDetailLevel {
SUMMARY, DETAIL, 
}