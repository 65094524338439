
import { ReactComponent as File } from '../../static/img/File.svg';
import { ReactComponent as OnboardingCompleted } from '../../static/img/Onboarding/onboarding_completed.svg';
import { ReactComponent as check_mark } from '../../static/img/Onboarding/check_mark.svg';
import { ReactComponent as check_button } from '../../static/img/check-for-button.svg';
import { ReactComponent as x_mark } from '../../static/img/Onboarding/x_mark.svg';
import { ReactComponent as QBFailure } from '../../static/img/Onboarding/QBO_failure.svg';
import { ReactComponent as info_icon } from '../../static/img/info_icon.svg';
import { ReactComponent as crop } from '../../static/img/Onboarding/Crop.svg';
import { ReactComponent as close } from '../../static/img/close-alert-icon.svg';
import { ReactComponent as upload } from '../../static/img/upload.svg';
import { ReactComponent as next } from '../../static/img/next_icon.svg';
import { ReactComponent as back } from '../../static/img/back_icon.svg';
import { ReactComponent as add_taskAttachment_Button } from '../../static/img/Add_Attachment_Button.svg';
import { ReactComponent as add_emailAttachment_Button } from '../../static/img/Add_EmailAttachment_Button.svg';
import { ReactComponent as calendar_icon } from '../../static/img/calendar-icon.svg';
import { ReactComponent as File_icon } from '../../static/img/Files-icon.svg';
import { ReactComponent as Link_svg } from '../../static/img/Link.svg';
import { ReactComponent as asana_table_rows_check } from '../../static/img/Asana/check.svg';
import { ReactComponent as checkbox_assignee } from '../../static/img/Asana/checkbox_assignee.svg';
import { ReactComponent as MD_Logo_21x21 } from '../../static/img/Asana/MD_Logo_21x21.svg';
import { ReactComponent as Cross} from '../../static/img/Cross.svg';
import { ReactComponent as check_mark_for_approval} from '../../static/img/check_mark_for_approval.svg';
import { ReactComponent as filter_dropdown_icon_right} from '../../static/img/Filter-Dropdown-Icon-right.svg';
import { ReactComponent as google_button_icon} from '../../static/img/google-button-icon.svg';
import { ReactComponent as due_date_warning} from '../../static/img/Asana/due_date_warning.svg';
import { ReactComponent as publish_icon} from '../../static/img/Publish Icon.svg';
import { ReactComponent as Mail} from '../../static/img/Mail.svg';
import { ReactComponent as filter_dropdown_icon} from '../../static/img/filter_dropdown_icon.svg';
import { ReactComponent as down_arrow__small} from '../../static/img/down-arrow__small.svg';
import { ReactComponent as arrow_up__small} from '../../static/img/arrow_up__small.svg';
import { ReactComponent as FPAHeader__Icon} from '../../static/img/FPAHeader__Icon.svg';
import { ReactComponent as accounting} from '../../static/img/accounting.svg';
import { ReactComponent as data_analysis} from '../../static/img/data-analysis.svg';
import { ReactComponent as qbo_logo_small_notext } from '../../static/img/qbo_logo_small_notext.svg'; 
import { ReactComponent as open_component } from '../../static/img/open-component.svg'; 
import { ReactComponent as subtask__icon } from '../../static/img/subtask__icon.svg'; 
import { ReactComponent as Header__Back__Button } from '../../static/img/Header-Back-Button.svg';
import { ReactComponent as Financial__Icon } from '../../static/img/sidebar-images/financial-statements.svg';
import { ReactComponent as Explorer_folder } from '../../static/img/Explorer_folder.svg';
import { ReactComponent as Explorer_folder_small } from '../../static/img/Explorer_folder_small.svg';
import { ReactComponent as Explorer_file_small } from '../../static/img/Explorer_file_small.svg';
import { ReactComponent as forward_arrow } from '../../static/img/forward-arrow.svg';
import { ReactComponent as ExclamationMarkCircle } from '../../static/img/ExclamationMarkCircle.svg';
import { ReactComponent as asana } from '../../static/img/sidebar-images/Asana-sidebar.svg';
import { ReactComponent as right_arrow_filled } from '../../static/img/right_arrow_filled.svg';
import { ReactComponent as arrow_down_thin } from '../../static/img/arrow-down_thin.svg';
import { ReactComponent as chevron__right__double } from '../../static/img/chevron__right__double.svg';
import { ReactComponent as chevron__left__double } from '../../static/img/chevron__left__double.svg';
import { ReactComponent as discuss__icon } from '../../static/img/discuss__icon.svg';
import { ReactComponent as submit_arrow } from '../../static/img/submit-arrow.svg';
import { ReactComponent as PinMap } from '../../static/img/PinMap.svg';
import { ReactComponent as page_not_found } from '../../static/img/page_not_found.svg';
import { ReactComponent as no_access } from '../../static/img/no_access.svg';
import { ReactComponent as EmptyDocuments } from '../../static/img/EmptyDocuments.svg';
import { ReactComponent as Pin } from '../../static/img/pin.svg';


const IconsList = {
    'File': File,
    'OnboardingCompleted':OnboardingCompleted,
    "check_mark":check_mark,
    "check_button":check_button,
    "x_mark":x_mark,
    "QBFailure":QBFailure,
    "info_icon": info_icon,
    "crop":crop,
    "close":close,
    "upload":upload,
    "next":next,
    "back":back,
    "add_taskAttachment_Button":add_taskAttachment_Button,
    "add_emailAttachment_Button":add_emailAttachment_Button,
    "calendar-icon":calendar_icon,
    "Files-icon":File_icon,
    "Link_svg":Link_svg,
    "asana_table_rows_check": asana_table_rows_check,
    'checkbox_assignee':checkbox_assignee,
    "MD_Logo_21x21":MD_Logo_21x21,
    'Cross':Cross,
    'check_mark_for_approval':check_mark_for_approval,
    'filter_dropdown_icon_right':filter_dropdown_icon_right,
    'filter_dropdown_icon':filter_dropdown_icon,
    'down_arrow__small':down_arrow__small,
    'google_button_icon':google_button_icon,
    'due_date_warning':due_date_warning,
    "Publish Icon":publish_icon,
    "Mail":Mail,
    'arrow_up__small':arrow_up__small,
    "FPAHeader__Icon":FPAHeader__Icon,
    "accounting":accounting,
    "data_analysis":data_analysis,
    "qbo_logo_small_notext":qbo_logo_small_notext,
    'open_component':open_component,
    "subtask__icon":subtask__icon,
    "Header__Back__Button":Header__Back__Button,
    "Financial__Icon":Financial__Icon,
    "Explorer_folder":Explorer_folder,
    "Explorer_folder_small":Explorer_folder_small,
    "Explorer_file_small":Explorer_file_small,
    'forward-arrow':forward_arrow,
    "ExclamationMarkCircle":ExclamationMarkCircle,
    "asana":asana,
    "right_arrow_filled":right_arrow_filled,
    "arrow_down_thin":arrow_down_thin,
    "chevron__right__double":chevron__right__double,
    "chevron__left__double":chevron__left__double,
    "discuss__icon":discuss__icon,
    "submit_arrow":submit_arrow,
    "PinMap":PinMap,
    "page_not_found":page_not_found,
    "no_access":no_access,
    "EmptyDocuments":EmptyDocuments,
    "Pin":Pin,
}

interface MDIconsProps {
    title: string;
    onClick?: () => void;
}
export default function MDIcons({ title }: MDIconsProps) {
    const ModuleIcon = IconsList[title];
    if (ModuleIcon)
        return <ModuleIcon />
    else return <></>
}