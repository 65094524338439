import { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../contexts/AuthContext"
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem'
import ReactTooltip from "react-tooltip";
import { ModuleContext } from "../../contexts/ModuleContext";

export default function PublishBtn({ disabled }: { disabled: boolean }) {
    const { selectedCompany } = useContext(AuthContext)
    const { CreateNewPDF, DownloadPDF } = useContext(ModuleContext)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleDownloadPDF = () => {
        handleClose()
        DownloadPDF()
    };
    const handleCreateNewPDF = () => {
        handleClose()
        CreateNewPDF()
    };

    useEffect(() => {
        ReactTooltip.rebuild();
    }, []);

    if (!selectedCompany) return <></>
    return (
        <div data-tip={disabled ? "Publishing your PDF is in progress." : ""}>
            {selectedCompany.printing_documentId &&
                <div>
                    <Button

                        disabled={disabled}
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        sx={{ bgcolor: "white", ":hover": { bgcolor: "white" } }} variant="contained" startIcon={<svg style={{ marginRight: ".5rem" }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M2.86432 0.399902C2.53295 0.399902 2.26432 0.668532 2.26432 0.999903C2.26432 1.33127 2.53295 1.5999 2.86432 1.5999H13.0717C13.4031 1.5999 13.6717 1.33127 13.6717 0.999903C13.6717 0.668532 13.4031 0.399902 13.0717 0.399902H2.86432ZM15.3307 4.2666C15.3307 3.38294 14.6144 2.6665 13.7307 2.6665H7.9974H2.26406C1.38041 2.6665 0.664062 3.39044 0.664062 4.27409C0.664065 10.3831 0.664081 5.13319 0.664081 5.13319L0.664085 13.5332C0.664086 14.4168 1.38053 15.1332 2.26418 15.1332H4.33073H11.6641H13.7303C14.6139 15.1332 15.3307 14.4168 15.3307 13.5332V4.78317V4.2666ZM6.1414 9.2105C6.24806 9.02206 6.3014 8.81406 6.3014 8.5865C6.3014 8.34117 6.24806 8.12428 6.1414 7.93584C6.03473 7.74739 5.87473 7.59984 5.6614 7.49317C5.45162 7.3865 5.19562 7.33317 4.8934 7.33317H3.33073V11.0932H4.37606V9.82384H4.8934C5.19918 9.82384 5.45695 9.76873 5.66673 9.6585C5.88006 9.54473 6.03829 9.39539 6.1414 9.2105ZM5.23473 8.5865C5.23473 8.85673 5.08718 8.99184 4.79206 8.99184H4.37606V8.17584H4.79206C5.08718 8.17584 5.23473 8.31273 5.23473 8.5865ZM9.24402 7.57317C8.94891 7.41317 8.60402 7.33317 8.20936 7.33317H6.72136V11.0932H8.20936C8.60047 11.0932 8.94358 11.0132 9.23869 10.8532C9.53736 10.6896 9.76669 10.4656 9.92669 10.1812C10.0902 9.89673 10.172 9.57317 10.172 9.2105C10.172 8.84428 10.092 8.51895 9.93202 8.2345C9.77202 7.95006 9.54269 7.72962 9.24402 7.57317ZM8.84936 9.91984C8.67513 10.0869 8.43513 10.1705 8.12936 10.1705H7.76669V8.23984H8.12936C8.43513 8.23984 8.67513 8.32517 8.84936 8.49584C9.02358 8.66295 9.11069 8.90117 9.11069 9.2105C9.11069 9.51628 9.02358 9.75273 8.84936 9.91984ZM13.1926 7.33317V8.16517H11.6886V8.8265H12.7979V9.62117H11.6886V11.0932H10.6432V7.33317H13.1926Z" fill={disabled ? "#666687" : "#85B440"} />
                        </svg>} endIcon={<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle r="9" transform="matrix(-1 0 0 1 9 9)" fill="#85B440" fill-opacity="0.1" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M11.625 8.58333C11.625 8.67361 11.5925 8.75174 11.5276 8.81771L9.23071 11.151C9.16577 11.217 9.08887 11.25 9 11.25C8.91113 11.25 8.83423 11.217 8.76929 11.151L6.47241 8.81771C6.40747 8.75174 6.375 8.67361 6.375 8.58333C6.375 8.49306 6.40747 8.41493 6.47241 8.34896C6.53735 8.28299 6.61426 8.25 6.70313 8.25H11.2969C11.3857 8.25 11.4626 8.28299 11.5276 8.34896C11.5925 8.41493 11.625 8.49306 11.625 8.58333Z" fill={disabled ? "#666687" : "#85B440"} />
                        </svg>
                        }>
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={handleDownloadPDF}>Download PDF</MenuItem>
                        <MenuItem onClick={handleCreateNewPDF}>Publish New PDF</MenuItem>
                    </Menu>
                </div>
            }
            {!selectedCompany.printing_documentId &&
                <button
                    disabled={disabled}
                    style={
                        {
                            padding: "0.7rem 1.5rem",
                            fontSize: "1.2rem",
                            backgroundColor: disabled ? "#EAEAEF" : "#fff",
                            color: disabled ? "#666687" : "#85B440",
                            fontWeight: 700,
                            border: '1px solid rgba(133, 180, 64, 0.1)'
                        }}
                    className="btn" onClick={() => { CreateNewPDF() }}>
                    <svg style={{ marginRight: ".5rem" }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M2.86432 0.399902C2.53295 0.399902 2.26432 0.668532 2.26432 0.999903C2.26432 1.33127 2.53295 1.5999 2.86432 1.5999H13.0717C13.4031 1.5999 13.6717 1.33127 13.6717 0.999903C13.6717 0.668532 13.4031 0.399902 13.0717 0.399902H2.86432ZM15.3307 4.2666C15.3307 3.38294 14.6144 2.6665 13.7307 2.6665H7.9974H2.26406C1.38041 2.6665 0.664062 3.39044 0.664062 4.27409C0.664065 10.3831 0.664081 5.13319 0.664081 5.13319L0.664085 13.5332C0.664086 14.4168 1.38053 15.1332 2.26418 15.1332H4.33073H11.6641H13.7303C14.6139 15.1332 15.3307 14.4168 15.3307 13.5332V4.78317V4.2666ZM6.1414 9.2105C6.24806 9.02206 6.3014 8.81406 6.3014 8.5865C6.3014 8.34117 6.24806 8.12428 6.1414 7.93584C6.03473 7.74739 5.87473 7.59984 5.6614 7.49317C5.45162 7.3865 5.19562 7.33317 4.8934 7.33317H3.33073V11.0932H4.37606V9.82384H4.8934C5.19918 9.82384 5.45695 9.76873 5.66673 9.6585C5.88006 9.54473 6.03829 9.39539 6.1414 9.2105ZM5.23473 8.5865C5.23473 8.85673 5.08718 8.99184 4.79206 8.99184H4.37606V8.17584H4.79206C5.08718 8.17584 5.23473 8.31273 5.23473 8.5865ZM9.24402 7.57317C8.94891 7.41317 8.60402 7.33317 8.20936 7.33317H6.72136V11.0932H8.20936C8.60047 11.0932 8.94358 11.0132 9.23869 10.8532C9.53736 10.6896 9.76669 10.4656 9.92669 10.1812C10.0902 9.89673 10.172 9.57317 10.172 9.2105C10.172 8.84428 10.092 8.51895 9.93202 8.2345C9.77202 7.95006 9.54269 7.72962 9.24402 7.57317ZM8.84936 9.91984C8.67513 10.0869 8.43513 10.1705 8.12936 10.1705H7.76669V8.23984H8.12936C8.43513 8.23984 8.67513 8.32517 8.84936 8.49584C9.02358 8.66295 9.11069 8.90117 9.11069 9.2105C9.11069 9.51628 9.02358 9.75273 8.84936 9.91984ZM13.1926 7.33317V8.16517H11.6886V8.8265H12.7979V9.62117H11.6886V11.0932H10.6432V7.33317H13.1926Z" fill={disabled ? "#666687" : "#85B440"} />
                    </svg>
                    Publish
                </button>
            }

        </div>

    )
}