import { DataConextProvider } from "../../contexts/HeadCount/DataConext";
import { EmployeeListConextProvider } from "../../contexts/HeadCount/EmployeeListConext";
import { ReportFilterContextProvider } from "../../contexts/HeadCount/ReportFilterContext";
import { ReportDataContextProvider } from "../../contexts/HeadCount/ReportDataContext";
import HeadCountBody from "./HeadCountBody";
import HeadCountHeader from "./HeadCountHeader";
import HeadCountFilters from "./HeadCountFilters/HeadCountFilters";

export default function HeadCountModule() {
    return (
        <DataConextProvider>
            <ReportFilterContextProvider>
                <ReportDataContextProvider>
                    <EmployeeListConextProvider>
                        <HeadCountHeader />
                        <HeadCountFilters />
                        <HeadCountBody />
                    </EmployeeListConextProvider>
                </ReportDataContextProvider>
            </ReportFilterContextProvider>
        </DataConextProvider>
    )
}