import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Invitation from "../components/Auth/Invitation";
import OnboardingInvitation from "../components/Auth/OnboardingInvitation";
import Login from "../components/Auth/Login";
import ForgotPassword from "../components/Auth/ForgotPassword";
// import Register from '../components/Auth/Register';
import CompleteProfile from "../components/Auth/CompleteProfile";
import "./AuthRouter.css";
import Register from "../components/Auth/Register";
import TermsOfService from "../components/Auth/Information/TermsOfService";
import PrivacyPolicy from "../components/Auth/Information/PrivacyPolicy";

export default function Router() {
  const shouldRedirect = true;
  return window.location.href.includes("terms-of-service") || window.location.href.includes("privacy-policy") ? (
    <>
      <Routes>
        <Route path="/terms-of-service" element={<TermsOfService/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
      </Routes>
    </>
  ) : (
    <div className="Parent_Container">
      <div className="Container">
        <span>
          Welcome to{" "}
          <svg width="118" height="25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="m36.079 10.582-2.777 3.702h-.316l-2.715-3.715v6.199h-1.607V8.244h1.85l2.667 3.665 2.667-3.665h1.838v8.524h-1.607v-6.186Z"
              fill="var(--Primary600)"
            />
            <path d="M40.802 8.232h-1.596v8.523h1.596V8.232Z" fill="var(--Primary600)" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M48.643 10.315a3.265 3.265 0 0 0-2.167-.853c-1.827 0-2.935 1.388-2.935 3.142 0 1.395.817 2.849 2.935 2.849.703.02 1.39-.223 1.924-.681V13.26h-2.18v-1.437h3.654v3.592c-.841.963-1.903 1.535-3.398 1.535-3.203 0-4.505-2.107-4.505-4.347 0-2.4 1.497-4.612 4.505-4.612 1.187 0 2.327.468 3.17 1.303l-1.003 1.02ZM56.996 16.768V13.32h-4.19v3.447H51.2V8.244h1.607v3.592h4.19V8.244h1.594v8.524h-1.594ZM62.303 9.693H59.6V8.232h7.014v1.461H63.91v7.062h-1.608V9.693Z"
              fill="var(--Primary600)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="m71.69 11.752 2.265-3.52h1.936v.074l-3.397 4.93v3.52h-1.609v-3.52l-3.274-4.93v-.074h1.911l2.168 3.52Z"
              fill="var(--Primary600)"
              stroke="var(--Primary600)"
              strokeWidth=".57"
              strokeMiterlimit="22.93"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M86.556 12.421c.036 2.157-1.28 4.334-4.263 4.334h-3.348V8.232h3.348c2.924 0 4.227 2.083 4.263 4.186v.003Zm-6.013 2.788h1.75c1.937 0 2.704-1.413 2.664-2.8-.037-1.327-.816-2.655-2.664-2.655h-1.75v5.455Z"
              fill="#fff"
            />
            <path d="M89.356 8.232H87.76v8.523h1.596V8.232Z" fill="#fff" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M97.196 10.315a3.265 3.265 0 0 0-2.167-.853c-1.826 0-2.935 1.388-2.935 3.142 0 1.395.816 2.849 2.935 2.849a2.833 2.833 0 0 0 1.923-.681V13.26h-2.179v-1.437h3.654v3.592c-.84.963-1.902 1.535-3.397 1.535-3.202 0-4.506-2.107-4.506-4.347 0-2.4 1.498-4.616 4.507-4.616 1.187 0 2.326.468 3.17 1.303l-1.005 1.024Z"
              fill="#fff"
            />
            <path d="M101.347 8.232h-1.596v8.523h1.596V8.232Z" fill="#fff" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M105.45 9.693h-2.704V8.232h7.014v1.461h-2.708v7.062h-1.602V9.693ZM115.994 10.29c-.293-.487-1.096-.95-2.022-.95-1.193 0-1.765.499-1.765 1.133 0 .742.876.949 1.902 1.07 1.776.22 3.434.683 3.434 2.716 0 1.902-1.681 2.716-3.581 2.716-1.74 0-3.081-.536-3.714-2.095l1.34-.693c.38.937 1.364 1.35 2.399 1.35 1.015 0 1.96-.352 1.96-1.278 0-.803-.84-1.133-1.972-1.253-1.742-.208-3.349-.671-3.349-2.583 0-1.752 1.729-2.472 3.298-2.483 1.327 0 2.703.38 3.349 1.692l-1.279.658Z"
              fill="#fff"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.445 21.944A9.487 9.487 0 0 1 3.172 10.47v-.531l.095.127 3.06 4.185h.367l3.225-4.3v7.187h1.863V7.231H9.647L6.55 11.494 4.045 8.052 3.45 7.23h-1.08a11.347 11.347 0 0 0 19.946 10.815h-2.216a9.478 9.478 0 0 1-7.656 3.898Z"
              fill="var(--Primary600)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M23.706 11.046a11.345 11.345 0 0 0-20.64-4.97h2.366a9.489 9.489 0 0 1 16.425 5.178c.115.484.14.985.07 1.478-.19 1.372-1.12 2.595-3.06 2.595H16.83V8.985h2.037a3.261 3.261 0 0 1 1.695.424c-.175-1.103-.583-1.776-.866-2.139a6.42 6.42 0 0 0-.829-.053h-3.89v9.907h3.89a5.671 5.671 0 0 0 2.023-.346c1.806-.686 2.732-2.302 2.9-4.014a5.842 5.842 0 0 0-.084-1.717Z"
              fill="#fff"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.457 24.913a12.457 12.457 0 1 1 12.456-12.456 12.47 12.47 0 0 1-12.456 12.456Zm0-24.422a11.967 11.967 0 1 0 11.967 11.966A11.98 11.98 0 0 0 12.457.49Z"
              fill="var(--Primary600)"
            />
          </svg>
        </span>

        <div className="Input__Container">
          <Routes>
            <Route  path="/" element={<Login/>} />
            <Route  path="/forgot-password" element={<ForgotPassword/>} />
            <Route path="/register" element={<Register/>} />
            <Route path="/invitations/:inviteCode" element={<Invitation/>} />
            <Route path="/onboarding-invitations/:inviteCode" element={<OnboardingInvitation/>} />
            <Route path="/complete" element={<CompleteProfile/>} />
            <Route path="/*" element={<Login />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}
