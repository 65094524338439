import CustomTooltip from '../Common/Tooltip';
import MDIcons from '../Common/MDIcons';

const GoToAsanaButton = ({ taskGID }) => {


  return (
    <>
      {taskGID && (
        <CustomTooltip
          title={
            <>
              See task in asana!
            </>
          }
        >
          
            <div
              data-test="go-to-asana-task-button"
              className="btn--tertiary btn--XXS--centered success"
            ><a href={`https://app.asana.com/0/0/${taskGID}`} rel="noreferrer" target="_blank">
              <MDIcons title='asana' />
              </a>
            </div>

        </CustomTooltip>
      )}
    </>
  );
};

export default GoToAsanaButton;
