export const editAddEmployeeDisplayForm = ['first_name', 'last_name', 'job_title', 'location', 'group', 'department', 'type', 'salary', 'start_date', 'end_date', 'note']
export const editAddEmployeeFormFilter = ['type', 'job_title', 'group', 'department', 'location']

export const editAddEmployeeCreatableFilter = ['job_title']

export const viewEmployeeDisplayForm = ['first_name', 'last_name', 'type', 'salary', 'start_date', 'end_date', 'location', 'status', 'note', 'schedule_raise_amount', 'schedule_bonus_amount']
export const viewEmployeeFormFilter = ['type', 'location']

export const employeeTableDisplay = ['job_title', 'first_name', 'last_name', 'status', 'department', 'location', 'salary', 'start_date', 'end_date', 'type']
export const employeeTableFilter = ['job_title', 'department', 'location', 'type','status']
export const employeeTableFilterOptions = ['job_title', 'department', 'location', 'type', 'status']

export const employeeTableSearchOptions = ['names', 'job titles', 'departments','status', 'locations', 'salaries']