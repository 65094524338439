import { GridColDef, GridColumns, GridEnrichedColDef, GridRowModel, GridRowsProp, GridValueGetterParams } from "@mui/x-data-grid-pro";
import { OptionTypeBase } from "react-select";
import { Company } from "../../../types/Company";
import { ExpenseType, HeadCountInformation } from "../../../types/HeadCount";
import { HeadCountSelectedFilters } from "../../../types/HeadCountSelectedFilters";
import { getMonth, getQuarter, getYear, isDateIntersectWithTwoDates, IslastMonthInQuarter } from "../../../utils/DateFunctions";
import { numberFormat } from "../../../utils/helpers";

export declare type TypeName = 'expenses' | 'hires';
export declare type ProjectionName = 'department' | 'location' | 'job_title' | 'employe_name' | 'group' | 'all';
export declare type PeriodName = 'month' | 'quarter' | 'year';

const reportTypes = ['expenses', 'hires']
const reportProjections = ['department', 'location', 'job_title', 'employe_name', 'group', 'all']
const reportPeriods = ['month', 'quarter', 'year']

interface dataModel {
    [key: string]: { [key: string]: { [key: string]: { [key: string]: GridRowModel } } }
}
interface HeaderDataModel {
    [key: string]: GridEnrichedColDef[]
}
export class TreeDataProvider {
    private _header: HeaderDataModel = {}
    private _data: dataModel = {}
    private static GridHeaderSetting: GridColDef = {
        field: "", hideable: false, pinnable: false,
        resizable: false,
        sortable: false, minWidth: 100, filterable: false,
        valueGetter: (params: GridValueGetterParams) => {
            if (params.value) {
                if (params.id.toString().startsWith("expenses"))
                    return numberFormat(params.value)
                else
                    return params.value
            }
            else
                return "-"

        }
    }
    private calcHiers(report_projection: ProjectionName, periodName: PeriodName, key_projection_name: string, key_period_name: string, rowKeys: string[]) {

        if (this._data['hires'][report_projection][periodName][key_projection_name]) {
            if (this._data['hires'][report_projection][periodName][key_projection_name][key_period_name]) {
                this._data['hires'][report_projection][periodName][key_projection_name][key_period_name]++
            }
            else {
                this._data['hires'][report_projection][periodName][key_projection_name][key_period_name] = 1
            }
        }
        else {
            this._data['hires'][report_projection][periodName][key_projection_name] = {
                id: "hires_" + report_projection + "_" + key_period_name + "_" + key_projection_name,
                [report_projection]: rowKeys,
                [key_period_name]: 1,
            }
        }
    }

    private calcExpenses(report_projection: ProjectionName, periodName: PeriodName, key_projection_name: string, key_period_name: string, rowKeys: string[], value: number) {
        if (this._data['expenses'][report_projection][periodName][key_projection_name]) {
            if (this._data['expenses'][report_projection][periodName][key_projection_name][key_period_name]) {
                this._data['expenses'][report_projection][periodName][key_projection_name][key_period_name] += value
            }
            else {
                this._data['expenses'][report_projection][periodName][key_projection_name][key_period_name] = value
            }
            this._data['expenses'][report_projection][periodName][key_projection_name]['total'] += value
        }
        else {
            this._data['expenses'][report_projection][periodName][key_projection_name] = {
                id: "expenses_" + report_projection + "_" + key_period_name + "_" + key_projection_name,
                [report_projection]: rowKeys,
                [key_period_name]: value,
                total: value,
            }
        }
    }


    constructor(headcountlist: HeadCountInformation[] | null = null, company: Company | null = null, selectedFilters: HeadCountSelectedFilters | null = null) {
        for (let i = 0; i < reportTypes.length; i++) {
            const reprot_type = reportTypes[i];
            this._data[reprot_type] = {}
            for (let j = 0; j < reportProjections.length; j++) {
                const report_projection = reportProjections[j];
                this._data[reprot_type][report_projection] = {}
                for (let y = 0; y < reportPeriods.length; y++) {
                    const report_period = reportPeriods[y];
                    this._data[reprot_type][report_projection][report_period] = {}
                }
            }

        }
        this._header['month'] = []
        this._header['quarter'] = []
        this._header['year'] = []
        if (!headcountlist || !selectedFilters || !company)
            return

        var loop = new Date(selectedFilters.startDate);
        var last_quarter_name: string = ""
        var last_year_name: string = ""

        var filtered_keys: string[] = Object.keys(ExpenseType).filter((key: string) => {
            var selected_expenses_filter: OptionTypeBase[] = selectedFilters.filterSelectedOptions("expenses_type")
            return selected_expenses_filter.length === 0 || selected_expenses_filter.some((value: OptionTypeBase) => ExpenseType[key].toString() === value.value.toString())
        })
        var order_counter = 1
        while (loop <= selectedFilters.endDate) {
            const month_name = getMonth(loop)
            const key_month_name = month_name.replaceAll(" ", "_")
            this._header['month'].push({ ...TreeDataProvider.GridHeaderSetting, field: key_month_name, headerName: month_name })

            const quarter_name = getQuarter(loop, company.offset)
            const key_quarter_name = quarter_name.replaceAll(" ", "_")
            if (last_quarter_name !== quarter_name) {
                this._header['quarter'].push({ ...TreeDataProvider.GridHeaderSetting, field: key_quarter_name, headerName: quarter_name })
                last_quarter_name = quarter_name
            }
            const year_name = getYear(loop, company.offset)
            const key_year_name = year_name.replaceAll(" ", "_")
            if (last_year_name !== year_name) {
                this._header['year'].push({ ...TreeDataProvider.GridHeaderSetting, field: key_year_name, headerName: year_name })
                last_year_name = year_name
            }
            for (let i = 0; i < headcountlist.length; i++) {
                const employee = headcountlist[i];

                if (!isDateIntersectWithTwoDates(loop, employee.startDate, employee.endDate))
                    continue

                if (!selectedFilters.isEmployeeMatching(employee))
                    continue

                // define counter for the employee order the will be represented in the employe_name in the HeadcountInfo class
                // This also to support the uniqueness for each employee in the display be employee name projection
                if (employee.counter === -1) {
                    employee.counter = order_counter
                    order_counter++
                }
                for (let h = 0; h < reportProjections.length; h++) {
                    const report_projection = reportProjections[h];
                    var lookUpOptionValue: string = HeadCountInformation.company_filter.lookUpOptionValue(report_projection, employee[report_projection])
                    if (!lookUpOptionValue) lookUpOptionValue = "Unassigned"
                    var total_sum = 0
                    for (let j = 0; j < filtered_keys.length; j++) {
                        const key = filtered_keys[j].replaceAll("_", " ");
                        const value = employee.calc_expense(key, loop);
                        total_sum += value
                        var coulumn_tree: string[] = report_projection === 'all' ? [key] : [lookUpOptionValue, key]
                        this.calcExpenses(report_projection as ProjectionName, "month", employee[report_projection] + key, key_month_name, coulumn_tree, value)
                        this.calcExpenses(report_projection as ProjectionName, "quarter", employee[report_projection] + key, key_quarter_name, coulumn_tree, value)
                        this.calcExpenses(report_projection as ProjectionName, "year", employee[report_projection] + key, key_year_name, coulumn_tree, value)
                    }
                    if (report_projection !== 'all') {
                        this.calcExpenses(report_projection as ProjectionName, "month", employee[report_projection], key_month_name, [lookUpOptionValue], total_sum)
                        this.calcExpenses(report_projection as ProjectionName, "quarter", employee[report_projection], key_quarter_name, [lookUpOptionValue], total_sum)
                        this.calcExpenses(report_projection as ProjectionName, "year", employee[report_projection], key_year_name, [lookUpOptionValue], total_sum)
                    }

                    this.calcExpenses(report_projection as ProjectionName, "month", "Total", key_month_name, ["Total"], total_sum)
                    this.calcExpenses(report_projection as ProjectionName, "quarter", "Total", key_quarter_name, ["Total"], total_sum)
                    this.calcExpenses(report_projection as ProjectionName, "year", "Total", key_year_name, ["Total"], total_sum)


                    this.calcHiers(report_projection as ProjectionName, "month", employee[report_projection], key_month_name, [lookUpOptionValue])
                    this.calcHiers(report_projection as ProjectionName, "month", "Total", key_month_name, ["Total"])
                    if (IslastMonthInQuarter(loop, company.offset)) {
                        this.calcHiers(report_projection as ProjectionName, "quarter", employee[report_projection], key_quarter_name, [lookUpOptionValue])
                        this.calcHiers(report_projection as ProjectionName, "quarter", "Total", key_quarter_name, ["Total"])
                    }
                    var nextMonth = new Date(loop)
                    nextMonth.setMonth(nextMonth.getMonth() + 1);
                    const next_month_year_name = getYear(nextMonth, company.offset)
                    if (next_month_year_name !== year_name || nextMonth > selectedFilters.endDate) {
                        this.calcHiers(report_projection as ProjectionName, "year", employee[report_projection], key_year_name, [lookUpOptionValue])
                        this.calcHiers(report_projection as ProjectionName, "year", "Total", key_year_name, ["Total"])
                    }
                }
            }

            loop.setMonth(loop.getMonth() + 1);
        };
    }
    public getReportHeaders(typeName: TypeName, periodName: PeriodName): GridColumns {
        switch (typeName) {
            case "hires":
                return this._header[periodName];
            case "expenses":
                return [...this._header[periodName], { ...TreeDataProvider.GridHeaderSetting, field: "total", headerName: "Total" }];
            default:
                return this._header[periodName];
        }
    }
    public getReportData(typeName: TypeName, projectionName: ProjectionName, periodName: PeriodName): GridRowsProp {
        var x = Object.values(this._data[typeName][projectionName][periodName]);
        if (projectionName !== "employe_name" && projectionName !== "all") {
            x.sort((a, b) => {
                let fa = a[projectionName][0].toLowerCase(),
                    fb = b[projectionName][0].toLowerCase();

                if (fa < fb) {
                    return -1;
                } else if (fa > fb) {
                    return 1;
                }
                return 0;
            })
        }
        x.sort((a, b) => b[projectionName].includes("Total") ? -1 : 0)
        return x
    }
}