import { TextareaAutosize } from "@mui/material";
import React, { useContext, useEffect, useMemo } from "react";
import { updateTicketingSystemEmail, updatePayrollEmailParams } from "../../../APIFunctions/ticketing_system";
import { AuthContext } from "../../../contexts/AuthContext";
import NotesQuestions from "../Components/NotesAndQuestions/NotesQuestions";
import debounce from "lodash.debounce";
import EmailContactsInput from "./EmailContactsInput";
import { MilestoneEmailContext } from "../../../contexts/TicketingSystem/MilestoneEmailContext";
import MDIcons from "../../Common/MDIcons";
import DeleteIcon from "@mui/icons-material/Delete";
import { MilestoneContext } from "../../../contexts/TicketingSystem/MilestoneContext";
import EmailSubjectPayroll from "./EmailSubjectPayroll";
import EmailSubjectMonthEnd from "./EmailSubjectMonthEnd";
import MonthEndCloseDefaultSecondLine from "./EmailPreviewComponent/DefaultSecondLine.tsx/MonthEndClose";
import RichTextEditor from "../../Common/RichTextEditor";

const EmailBuilder = () => {
  const { setPayrollLoading, milestoneType, selectedMilestone } = useContext(MilestoneContext);
  // discuss with Mihail: how can i use this component when we're not using 'PayrollContext'?
  //
  const { selectedCompany } = useContext(AuthContext);
  const {
    setFromEmailArray,
    setCCEmailArray,
    setToEmailArray,
    toEmailArray,
    fromEmailArray,
    ccEmailArray,
    emailGreeting,
    setEmailGreeting,
    richTextEmail,
    setRichTextEmail,
    emailSenderSignature,
    setEmailSenderSignature,
    attachedFiles,
    removeAttachment,
    email,
    recepientsNames,emailSubject,setEmailSubject
  } = useContext(MilestoneEmailContext);

  const saveEmail = async (value, type) => {
    let response;
    setPayrollLoading(true);
    if (email && selectedCompany) {
      if (type === "RichTextEmail" && selectedCompany && selectedMilestone) {
        response = await updateTicketingSystemEmail(selectedMilestone.gid, selectedCompany?.id, {
          rich_text_email: value,
        });
      } else if (type === "EmailSenderSignature" && selectedCompany) {
        response = await updatePayrollEmailParams(selectedCompany?.id, milestoneType, {
          email_sender_signature: value,
        });
      } else if (type === "EmailSubject" && selectedCompany) {
        response = await updateTicketingSystemEmail(selectedMilestone.gid, selectedCompany?.id, {
          subject: value,
        });
      }
    }

    setPayrollLoading(false);
  };

  const debouncedEmailSave = useMemo(() => {
    return debounce(saveEmail, 1000);
  }, []);

  const onChangeEmailGreeting = (value) => {
    if (!value) {
      const defaultFirstLine = `Hi ${combineRecepientsNames(recepientsNames)},`;
      setEmailGreeting(defaultFirstLine);
      debouncedEmailSave(defaultFirstLine, "EmailGreeting");
    } else {
      setEmailGreeting(value);
      debouncedEmailSave(value, "EmailGreeting");
    }
  };

  const onChangeRichTextEmail = (value) => {
    setRichTextEmail(value);
    debouncedEmailSave(value, "RichTextEmail");
  };

  const onChangeEmailSubject = (value) => {
    setEmailSubject(value);
    debouncedEmailSave(value, "EmailSubject");
  };

  const onChangeEmailSenderSignature = (value) => {
    if (!value) {
      const defaultSignature = `${selectedCompany?.companyName} Accounting`;
      setEmailSenderSignature(defaultSignature);
      debouncedEmailSave(defaultSignature, "EmailSenderSignature");
    } else {
      setEmailSenderSignature(value);
      debouncedEmailSave(value, "EmailSenderSignature");
    }
  };

  const combineRecepientsNames = (names) => {
    let result = "";
    if (names) {
      const splittedNames = names.split(",");
      for (let i = 0; i < splittedNames.length; i++) {
        if (i === splittedNames.length - 1 && splittedNames.length > 1) {
          result += " and " + splittedNames[i];
        } else if (i > 0) {
          result += ", " + splittedNames[i];
        } else if (i === 0) {
          result += splittedNames[i];
        }
      }
      return result;
    }
    return "";
  };

  useEffect(() => {
    if (emailGreeting === "") {
      const defaultFirstLine = `Hi ${combineRecepientsNames(recepientsNames)},`;
      onChangeEmailGreeting(defaultFirstLine);
    }

    if (!emailSenderSignature) {
      const defaultSignature = `${selectedCompany?.companyName} Accounting`;
      onChangeEmailSenderSignature(defaultSignature);
    }
    return () => {
      debouncedEmailSave.cancel();
    };
  }, []);

  return (
    <>
      <div className="SubTask__EmailPreview__EmailContacts__ListContainer" data-test="email-input-list">
        <div className="SubTask__EmailPreview__EmailContacts" data-test="email-input-component">
          <label>From</label>
          <EmailContactsInput emailArray={fromEmailArray} setEmailArray={setFromEmailArray} email_type="from" />
        </div>
        <div className="SubTask__EmailPreview__EmailContacts" data-test="email-input-component">
          <label>To</label>
          <EmailContactsInput emailArray={toEmailArray} setEmailArray={setToEmailArray} email_type="to" />
        </div>
        <div className="SubTask__EmailPreview__EmailContacts" data-test="email-input-component">
          <label>CC</label>
          <EmailContactsInput emailArray={ccEmailArray} setEmailArray={setCCEmailArray} email_type="cc" />
        </div>
      </div>

      <div className="SubTask__EmailPreview__Title">
        <input placeholder="Insert email subject ..." value={emailSubject} onChange={(e) => onChangeEmailSubject(e.target.value)} />
      </div>

      <div className="SubTask__EmailPreview__Body__Container">
        {/* quill element goes here */}
        <RichTextEditor htmlValue={richTextEmail} setHTMLValue={setRichTextEmail} onChange={(value) => onChangeRichTextEmail(value)} people={null} placeholder={"Type email body..."}/>
        {attachedFiles?.length > 0 && (
          <div className="SubTask__EmailPreview__Body__Container__AttachmentsContainer" data-test="email_attachments_container">
            {attachedFiles.map((attachment) => {
              return (
                <div key={attachment.id} className="SubTask__EmailPreview__Body__Container__Attachment__Container" data-test="email_attachment_container">
                  <MDIcons title="Files-icon" />
                  <div>{attachment["attachment_name"]}</div>
                  <button
                    className="btn--XXS--centered btn--secondary-danger delete-on-hover"
                    data-test="email_attachment_delete_btn"
                    onClick={() => removeAttachment(attachment.id)}
                  >
                    <DeleteIcon />
                  </button>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default EmailBuilder;
