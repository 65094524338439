import React, { useContext, useEffect, useState } from "react";
import { createAsanaSubTask, retrieveAsanaSubTask } from "../../../../../APIFunctions/Asana";
import { addHours, asanaTimeToHoursMinutes } from "../../../../../utils/DateFunctions";
import { getPayrollFileLink, getPayrollPlatformLink, updatePayrollFileLink } from "../../../../../APIFunctions/ticketing_system";
import { payrollLocation, payrollPlatform } from "../../../../../types/TicketingSystem";
import PayrollFileLink from "../PayrollFileLink";

import BasicMenu from "../../../../Common/DropDownComponent/TaskMoreActions";
import { PayrollContext } from "../../../../../contexts/TicketingSystem/PayrollContext";
import MDIcons from "../../../../Common/MDIcons";
import { MilestoneContext } from "../../../../../contexts/TicketingSystem/MilestoneContext";
import { MilestoneEmailContext } from "../../../../../contexts/TicketingSystem/MilestoneEmailContext";
import MilestoneTaskLine from "../../../Components/MilestoneTaskLine";
import { SubTasksContext } from "../../../../../contexts/TicketingSystem/SubTasksContext";
import MilestoneTaskContainer from "../../../Components/MilestoneTaskContainer";
import PayrollTable from "./Table";
import { Outlet, useNavigate } from "react-router-dom";
import GoToAsanaButton from "../../../../AsanaComponents/GoToAsanaButton";
import PayrollMilestoneTasksCard from "../../../Components/PayrollMilestoneTasksCard";
import MilestoneFeedbackList from "../../../Components/FeedbackList/Container";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import SelectComponent from "../../../../Common/SelectComponent";
import { OptionTypeBase } from "react-select";
import { selectedFeedbackStatusFilterOptions } from "../../../types/feedback_tasks";

const PayrollCard = ({ setIsMilestoneTaskOpen, isMilestoneTaskOpen }) => {
  const navigate = useNavigate();
  const [selectedFeedbackStatusFilter, setSelectedFeedbackStatusFilter] = useState<OptionTypeBase>(selectedFeedbackStatusFilterOptions[0]);
  const { setFullLoadedFlag, selectedMilestone, currentStage, milestoneType } = useContext(MilestoneContext);
  const { setEmailReadyToSendFlag } = useContext(MilestoneEmailContext);
  const { preparationStageTasks } = useContext(SubTasksContext);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [displayComponentFlag, setDisplayComponentFlag] = useState<boolean>(false);
  const [payrollCardSubTasks, setPayrollCardSubTasks] = useState<any[]>([]);
  const [milestoneTask, setMilestoneTask] = useState<any>();
  // const [preparationStageTasks, setPreparationStageTasks] = useState<any[]>([]);
  const [linkInputType, setLinkInputType] = useState<string>("");

  const openMilestoneTaskComponent = (milestoneTask) => {
    openMilestoneTask(milestoneTask["gid"]);
    setDisplayComponentFlag(true);
  };

  const updatePayrollCardSubTasks = (updatedSubTask) => {
    const newArray = payrollCardSubTasks.map((x) => (x.gid === updatedSubTask.gid ? updatedSubTask : x));
    setPayrollCardSubTasks(newArray);
  };

  let countdownDate = new Date();
  const DueDate = selectedMilestone?.due_on;

  const DueDateWithTime = selectedMilestone?.due_at;
  if (DueDateWithTime) {
    countdownDate = DueDateWithTime;
  } else if (DueDate) {
    countdownDate = DueDate;
  }

  // const DueDateWithTime = addHours(new Date(DueDate), asanaTimeToHoursMinutes(DueTime));

  const openMilestoneTask = (milestoneTaskGID) => {
    navigate(milestoneTaskGID);
    setIsMilestoneTaskOpen(true);
    const target = preparationStageTasks.find((x) => x.gid === milestoneTaskGID);
    if (target) {
      setMilestoneTask(target);
    }
  };

  useEffect(() => {
    setFullLoadedFlag(false);
    setFullLoadedFlag(true);
  }, [currentStage]);

  

  return (
    <>
      {isMilestoneTaskOpen === true ? (
        <Outlet context={{ milestoneTask, setMilestoneTask, setIsMilestoneTaskOpen, displayComponentFlag, setDisplayComponentFlag }} />
      ) : (
        <div className="MilestoneCard__Container">
          <Tabs className="tabs-bi" selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
            <TabList>
              <Tab className="tabs-bi__link settings_tab_link" selectedClassName="tabs-bi__link--selected">
                TASKS
              </Tab>
              <Tab className="tabs-bi__link settings_tab_link" selectedClassName="tabs-bi__link--selected">
                FEEDBACK
              </Tab>
            </TabList>
            <TabPanel />
            <TabPanel />
          </Tabs>
          {tabIndex === 0 ? <PayrollMilestoneTasksCard openMilestoneTask={openMilestoneTask} /> : <MilestoneFeedbackList tasksFilter={selectedFeedbackStatusFilter} />}
          {tabIndex === 1 && <div className="MilestoneCard__Container__Feedback__Filter" data-test="MilestoneCard__Container__Feedback__Filter">
            <SelectComponent
              name={selectedFeedbackStatusFilter.label}
              options={selectedFeedbackStatusFilterOptions}
              onChange={(value) => setSelectedFeedbackStatusFilter(value)}
              isLoading={false}
              internalLabel={true}
              isSearchable={false}
              value={selectedFeedbackStatusFilter}
            />
          </div>}
        </div>
      )}
    </>
  );
};

export default PayrollCard;
