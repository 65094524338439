export const countIncompleteSubtasks = (subtasks:[]) => {
    // subTasksDict?.[milestoneTask.gid]
    let incompleteCount = 0
    for (let i=0;i < subtasks.length; i++) {
        const subtask = subtasks[i]
        if (subtask['completed'] === false) {
            incompleteCount++
        }
    }
    return incompleteCount

}