import { useContext, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import EditIcon from '@mui/icons-material/Edit';
import DuplicateIcon from '@mui/icons-material/ContentCopyTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import { EmployeeDialogType } from '../../../types/HeadCount';
import { EmployeeListConext } from '../../../contexts/HeadCount/EmployeeListConext';
import BulkAddEmployeeDialog from './BulkAddEmployeeDialog';
import DeleteEmployeeDialog from './DeleteEmployeeDialog';
import DuplicateEmployeeDialog from './DuplicateEmployeeDialog';
import EmployeeDialog from './EmployeeDialog';
import SearchComponent from '../../Common/SearchComponent';
import { DataConext } from '../../../contexts/HeadCount/DataConext';
import { employeeTableSearchOptions } from '../../../config/headcountConfigs';
import { uppercaseWords } from '../../../utils/helpers';

export default function TableControls() {
    const { selectedFlatRows,
        deleteDialogeOpen,
        openDeleteDialoge,
        openEditBulkDialoge,
        closeDeleteDialoge,
        employeeDialogType,
        closeEmployeeDialog,
        openDuplicateDialoge,
        duplicateDialogeOpen,
        closeDuplicateDialoge,
        employeeDialogIsOpenByType, tabIndex } = useContext(EmployeeListConext);
    const { searchText, setSearchText } = useContext(DataConext);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, []);

    return (
        <div className='table--controlsContainer'>
            {selectedFlatRows.length > 0 &&
                <>
                    <button className="btn--S btn--secondary" onClick={() => openEditBulkDialoge()}>
                        <EditIcon />
                        <span>Edit</span>
                    </button>
                    <button className="btn--S btn--secondary" onClick={() => openDuplicateDialoge()} >
                        <DuplicateIcon />
                        <span>Duplicate</span>
                    </button>
                    <button className="btn--S btn--secondary-danger" onClick={() => openDeleteDialoge()} >
                        <DeleteIcon />
                        <span>Delete</span>
                    </button>
                </>
            }
            {tabIndex === 0 && (
                <SearchComponent
                    searchText={searchText}
                    setSearchText={setSearchText}
                    searchPlaceHolder={`Search...`} />
            )}
            {employeeDialogIsOpenByType[employeeDialogType] && employeeDialogType !== EmployeeDialogType.DEFAULT && <EmployeeDialog />}
            {employeeDialogIsOpenByType[employeeDialogType] && employeeDialogType !== EmployeeDialogType.DEFAULT && employeeDialogType === EmployeeDialogType.BULK_ADD &&
                <BulkAddEmployeeDialog open={employeeDialogIsOpenByType[EmployeeDialogType.BULK_ADD]} onClose={() => { closeEmployeeDialog() }} />
            }
            <DeleteEmployeeDialog open={deleteDialogeOpen} onCancel={() => { closeDeleteDialoge(false) }} onYes={() => closeDeleteDialoge(true)} />
            <DuplicateEmployeeDialog open={duplicateDialogeOpen} onCancel={() => { closeDuplicateDialoge(0) }} onYes={closeDuplicateDialoge} />
        </div>
    )
}