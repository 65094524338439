import { OptionTypeBase } from 'react-select';
import { AppModules } from './CompanyModules';
import { Division } from './Division';
import { ExcelEmbedTab } from './ExcelEmbedTab';
export interface ISelectedFilters {
    [key: string]: OptionTypeBase[];
}
export interface IAllFilters {
    [key: string]: IFilterOptions;
}

export interface IFilterOptions {
    name: string;
    options: IFilterOptionItem[];
    displayName: string;
}


export interface IFilterOptionItem {
    value: string;
    label: string;
}

export interface FilterItem {
    id: string;
    name: string;
}
export class HCFilters {
    location: FilterItem[] = []
    job_title: FilterItem[] = []
    department: FilterItem[] = []
}

export class CompanyFilters {
    public static allfilters = ['location', 'job_title', 'department', 'group', 'repeat_every', 'division', 'status', 'expenses_type', 'type', 'status']
    public static allFiltersOrHeadcount = ['location', 'job_title', 'department']

    hc_filters: HCFilters = new HCFilters();
    all_filters: HCFilters = new HCFilters();
    repeat_every: FilterItem[] = []
    group: FilterItem[] = []
    status: FilterItem[] = []
    expenses_type: FilterItem[] = []
    type: FilterItem[] = []
    division: Division[] = [];

    constructor(obj: CompanyFilters | null = null) {
        if (obj) {
            Object.keys(obj).forEach(key => {
                if (key in this)
                    this[key] = obj[key]
            })
        }
    }
    lookUpOptionNames = (itemKey: string, all = true): IFilterOptionItem[] => {
        try {
            var result: FilterItem[] = []
            if (CompanyFilters.allFiltersOrHeadcount.includes(itemKey)) {
                if (all)
                    result = this.all_filters[itemKey]
                else
                    result = this.hc_filters[itemKey]
            }
            else
                result = this[itemKey]
            return result.map((item: FilterItem) => { return { value: item.name, label: item.name } })
        } catch { return [] }
    }
    lookUpOption = (itemKey: string, all = true): IFilterOptionItem[] => {
        try {
            var result: FilterItem[] = []
            if (CompanyFilters.allFiltersOrHeadcount.includes(itemKey)) {
                if (all)
                    result = this.all_filters[itemKey]
                else
                    result = this.hc_filters[itemKey]
            }
            else
                result = this[itemKey]
            return result.map((item: FilterItem) => { return { value: item.id, label: item.name } })
        } catch { return [] }
    }
    lookUpOptionValue = (itemKey: string, id: string): string => {
        var result: string = id
        try {
            if (CompanyFilters.allFiltersOrHeadcount.includes(itemKey)) {
                result = this.all_filters[itemKey].filter(opt => opt.id.toString() === id.toString())[0].name
            }
            else
                result = this[itemKey].filter(opt => opt.id.toString() === id.toString())[0].name
            return result
        } catch { return result }
    }
    getDivisionOptions(): IFilterOptionItem[] {
        let result: IFilterOptionItem[] = []
        for (let i = 0; i < this.division.length; i++) {
            let obj = { value: this.division[i].name, label: this.division[i].name }
            result.push(obj)
        }
        return result
    }
    getEmbedTabs(moduleName: AppModules, selectedDivision?: Division | null): ExcelEmbedTab[] | null {
        let companyExcelTabs: ExcelEmbedTab[] | null = null
        if (!selectedDivision && this.division && this.division.length > 0)
            selectedDivision = this.division[0]
        if (selectedDivision) {
            companyExcelTabs = selectedDivision.excel_embed_tabs.filter((item) => item.module === moduleName).sort((a, b) => a.order - b.order);
            if (companyExcelTabs.length <= 0)
                companyExcelTabs = null
        }
        return companyExcelTabs
    }
}