import React, { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import CompanyQBOConnect from "../Common/QBOConnect";

const QBOFooter = ({ creationMethod, exportToExcel, setDisplaySharepointBrowser }) => {
  const { selectedCompany } = useContext(AuthContext);

  const run = () => {
    if (creationMethod === "Upload To Sharepoint") {
      setDisplaySharepointBrowser(true);
      return;
    } else {
      exportToExcel();
    }
  };
  return (
    <div className="ExportQBOToExcel__Footer">
      {/* <CustomTooltip
          title={
            <React.Fragment>{isConnected ? "Click to select Sharepoint Folder to Export QBO report there." : "Click to connect to quickbooks!"}</React.Fragment>
          }
        > */}
      {selectedCompany?.is_connected === true ? 
      
      <button className="btn--primary btn--fullWidth" onClick={run}>
        Export Financials To Excel
      </button>
      :
      <CompanyQBOConnect />}
      {/* </CustomTooltip> */}
    </div>
  );
};

export default QBOFooter;
