import { GridColDef } from "@mui/x-data-grid-pro";
import {
  createDateWithOffsetFromDate,
  FirstDayCurrentYear,
  getMonth,
  getMonthShort,
  getQuarter,
  getYear,
} from "../../utils/DateFunctions";
import {
  AccountList,
  BalanceSheet,
  ProfiAndLoss,
} from "../API/FinancialReport";
import { Company } from "../Company";
import { BSReport, CFReport, PLReport } from "./classes";
import { IaccountBalanceArray, IAccountsDict, IgridRow, ReportTypeEnum } from "./interfaces";
import {
  AccountTypeEnum,
  AccountSubTypeEnum,
  FormulaNameEnum,
  SectionItemData,
  SectionNameEnum,
  sectionNameToAccountType,
} from "./Sections";
import { FinancialStatementSelectedFilters } from "./SelectedFilters";

type dataModel = {
  [key in ReportTypeEnum]: IgridRow[];
}

export declare type PeriodName = 'Month' | 'Quarter' | 'Year';

export class FinancialStatements {
  private _header = {}
  company: Company = new Company();
  Dates: Date[] = [];
  priorDate: Date = new Date();
  PLData: ProfiAndLoss = {};
  BSData: BalanceSheet = {};
  AccountList: AccountList = {};
  Account_List_Ordered_Ids_PL:string[]=[]
  accountListDict: IAccountsDict = {
    "0": {
      id: "",
      name: "",
      parentRef: "",
      immediateChildren: [],
      subAccount: false,
      accountType: AccountTypeEnum.Income,
      accountSubType: AccountSubTypeEnum.No_SubType,
      added: false
    },
  };
  private _data: dataModel = { "Income Statement": [], "Balance Sheet": [], "Cash Flows": [] };
  public accountTypeDict: SectionItemData = { children: [], total: {} };

  constructor(company: Company | null, PLData: ProfiAndLoss | null = null, BSData: BalanceSheet | null = null, AccountList: AccountList | null = null, 
    startDate?:Date, endDate?:Date) {

    if (!company || !PLData || !BSData || !AccountList || !startDate || !endDate)
      return


    this.company = company;
    this.PLData = PLData;
    this.BSData = BSData;
    this.AccountList = AccountList;




    this.createDates();

    // create accountTypesArray
    const AllSections: string[] = [
      ...Object.values(FormulaNameEnum),
      ...Object.values(SectionNameEnum),
    ] as string[];
    for (var i = 0; i < AllSections.length; i++) {
      this.accountTypeDict[AllSections[i]] = {
        children: [],
        total: { period: {value:0,error:false} },
      } as SectionItemData;
    }

    // go through PL Account list and add it to the account list dictionary
    for (const key in this.AccountList) {
      if (this.AccountList[key] && !this.accountListDict[this.AccountList[key].id]) {
        this.AddToAccountListDict(
          this.AccountList[key]
        );
      }
    }

    // Intialize the reports data
    this._data[ReportTypeEnum.Income_Statement] = new PLReport(PLData, this.Dates, this.accountTypeDict, this.accountListDict).getData();
    const netIncome: IaccountBalanceArray|undefined  = this._data[ReportTypeEnum.Income_Statement].find(x => x.id === FormulaNameEnum.Net_Income)?.totalAccountBalance
    this._data[ReportTypeEnum.Balance_Sheet] = new BSReport(BSData,new Date(this.company.last_date_of_actuals), [this.priorDate,...this.Dates], this.accountTypeDict, this.accountListDict, netIncome).getData();
    // const netIncomeBS: IaccountBalanceArray|undefined  = this._data[ReportTypeEnum.Balance_Sheet].find(x => x.name === FormulaNameEnum.Net_Income)?.accountBalance
    this._data[ReportTypeEnum.Cash_Flows] = new CFReport(this._data[ReportTypeEnum.Balance_Sheet], new Date(this.company.last_date_of_actuals), this.Dates,this.accountTypeDict, this.accountListDict, netIncome, this.priorDate).getData();
  }

  

  // // build the dictionary
  private AddToAccountListDict = (accountListObj) => {
    const id = accountListObj.id;
    const name = accountListObj.name;
    const SubAccount = accountListObj.subAccount;
    const accountType = accountListObj.accountType;
    const ParentRef = accountListObj.parentRef;
    this.accountListDict[id] = {
      id: id,
      name: name,
      parentRef: ParentRef,
      immediateChildren: [],
      subAccount: SubAccount,
      accountType: accountType,
      accountSubType:accountListObj.accountSubType,
      added: false,
    };
    // const section = Object.keys(sectionNameToAccountType).find(key => sectionNameToAccountType[key] === value);
    const section = Object.keys(sectionNameToAccountType).find(key => sectionNameToAccountType[key] === accountType);
    if (section) this.accountTypeDict[section]["children"].push(id);
    

    if (ParentRef !== "") {
      if (!this.accountListDict[ParentRef]) {
        // there's a parent, but it hasn't been added yet
        const parentObj = this.AccountList[ParentRef];
        this.AddToAccountListDict(parentObj);
      }
      const parentObj = this.accountListDict[ParentRef];
      parentObj.immediateChildren.push(id);
    }
  };


  public getReportHeaders(periodName: PeriodName, startDate:Date, endDate:Date, reportTab): string[] {
      this._header['Month'] = []
      this._header['Quarter'] = []
      this._header['Year'] = []
      const last_date_of_actuals = new Date(this.company.last_date_of_actuals);
      var loop = new Date(startDate)
      const end_date = new Date(endDate)
      let last_quarter_name: string = ""
      let last_year_name: string = ""
      while (loop <= end_date) {
        const month_name = getMonthShort(loop)
        this._header['Month'].push(month_name)
        if (periodName === "Quarter") {
          const quarter_name = getQuarter(loop, 0)
          if (last_quarter_name !== quarter_name) {
              this._header['Quarter'].push(quarter_name)
              last_quarter_name = quarter_name
          }
        }
        if (periodName === "Year") {
          const year_name = getYear(loop, 0)
          if (last_year_name !== year_name) {
              this._header['Year'].push(year_name)
              last_year_name = year_name
          }
        }
        loop.setMonth(loop.getMonth() + 1);
      };
      
      if (reportTab === 0) {
        this._header['Month'].push("Total")
        this._header['Quarter'].push("Total")
        this._header['Year'].push("Total")
      }
      

      return this._header[periodName];
    }

  private createDates = () => {
    const dates: Date[] = [];
    const loop = new Date(this.company.start_date);
    this.priorDate = new Date(loop.setMonth(loop.getMonth() - 1))
    loop.setMonth(loop.getMonth() + 1);
    const last_date_of_actuals = new Date(this.company.end_date);
    while (loop <= last_date_of_actuals) {
      const x = new Date(loop)
      dates.push(x);
      loop.setMonth(loop.getMonth() + 1);
    }
    this.Dates = dates;
  };

  public getData(report_name: ReportTypeEnum): IgridRow[] {
    return this._data[report_name];
  }
}