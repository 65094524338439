export class TableComment {
  row_id: string | number;
  column_id: string | number;
  private report_type!: "ap_summary_report" | "ar_summary_report" | "ap_detail_report" | "ar_detail_report" | undefined;
  private meta_data!: {
    transaction_id: string;
    transaction_type: string;
  } | null;
  asana_milestone_id: string;
  report_page: string;
  asana_task_id: string;
  company_id: number | undefined;
  asana_task_assignee: string;
  dataRow?: any;

  name = "Cell Comment";
  commentTaskDescription!: string;
  constructor(
    company_id: number | undefined,
    row_id: string | number,
    column_id: string | number,
    report_type: "ap_summary_report" | "ar_summary_report" | "ap_detail_report" | "ar_detail_report" | undefined,
    reportDetailLevel: "Summary" | "Detail" | null,
    report_page: string,
    asana_milestone_id: string,
    asana_task_id: string,
    asana_task_assignee: string,
    dataRow?: any
  ) {
    this.row_id = row_id;
    this.column_id = column_id;
    this.asana_milestone_id = asana_milestone_id;
    this.report_page = report_page;
    this.asana_task_id = asana_task_id;
    this.company_id = company_id as number;
    this.asana_task_assignee = asana_task_assignee;
    this.commentReportType(report_type, reportDetailLevel);
    this.setCommentTaskDescription();
    if (dataRow) {
      this.setMetaData(dataRow);
    }
  }

  commentReportType = (reportType, reportDetailLevel) => {
    if (reportType === "ap_summary_report" && reportDetailLevel === "Detail") {
      this.report_type = "ap_detail_report";
    } else if (reportType === "ar_summary_report" && reportDetailLevel === "Detail") {
      this.report_type = "ar_detail_report";
    } else {
      this.report_type = reportType;
    }
  };

  setCommentTaskDescription = () => {
    let newCommentLink = window.location.href;
    if (newCommentLink.includes("component?") === false) {
      newCommentLink = newCommentLink.replace("component", "component?");
    }
    if (newCommentLink.includes("comment=") === false) {
      newCommentLink = newCommentLink.replace("component?", "component?comment=123");
    }

    this.commentTaskDescription = `<body>Comment link → <a href="${newCommentLink}">Go to comment</a>\n</body>`;
  };

  setMetaData = (dataRow: any) => {
    if ("transaction_type" in dataRow && "transaction_id" in dataRow) {
      this.meta_data = {
        transaction_id: dataRow["transaction_id"],
        transaction_type: dataRow["transaction_type"],
      };
    } else {
      this.meta_data = null;
    }
  };

  public static getHighlightFieldArrayForTransactionLine(cellComments, transactionID,line,commentsTabIndex ) {
    let highlightFieldsArray: any = [];
  const matchingComments = cellComments?.filter((x) => {
    return (
    x?.transaction_line_id?.toString() === line?.["Id"] && x.transaction_id === transactionID.toString()
    && ((x.asana_task.completed && commentsTabIndex === 1 )|| (x.asana_task.completed === false && commentsTabIndex === 0) )
    )
  });
  if (matchingComments?.length > 0) {
    for (let i = 0; i < matchingComments.length; i++) {
      const comment = matchingComments[i];
      highlightFieldsArray.push(comment.column_id);
    }
  }
  return highlightFieldsArray
  }

  public get newCommentObj():any {
    return {
      company_id: this.company_id,
      row_id: this.row_id,
      column_id: this.column_id,
      report_type: this.report_type,
      report_page: this.report_page,
      asana_milestone_id: this.asana_milestone_id,
      asana_task_id: this.asana_task_id,
      name: this.name,
      asana_task_assignee: this.asana_task_assignee,
      taskDescriptionHTML: this.commentTaskDescription,
      meta_data:this.meta_data
    };
  };
}
