import { useContext, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './tabs-section.css';
import { DataConext } from '../../../contexts/HeadCount/DataConext';
import EmployeeList from '../EmployeeList';
import ReportNestedTree from '../ReportNestedTree';
import { EmployeeListConext } from '../../../contexts/HeadCount/EmployeeListConext';
import ReportLoading from '../../Common/ReportLoading';
import { LoaderStatus } from '../../../types/Loaders';

export default function HeadCountBody() {
    const { hc_LoaderStatus, hc_LoaderValue } = useContext(DataConext)
    const { tabIndex, setTabIndex } = useContext(EmployeeListConext)


    return (
        <>
            <Tabs className="tabs-bi" selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
                <TabList>
                    <Tab className="tabs-bi__link">Employee List</Tab>
                    <Tab className="tabs-bi__link">Expenses</Tab>
                    <Tab className="tabs-bi__link">Hires</Tab>
                </TabList>
                <TabPanel />
                <TabPanel />
                <TabPanel />
            </Tabs>
            {hc_LoaderStatus !== LoaderStatus.Rendered && <ReportLoading loaderValue={hc_LoaderValue} reportStatus={hc_LoaderStatus} />}
            {hc_LoaderStatus === LoaderStatus.Rendered &&
                <>
                    {tabIndex === 0 && <EmployeeList />}
                    {tabIndex > 0 && <ReportNestedTree />}
                </>
            }
        </>

    )
}