import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { useSelectedMonthEndCloseMilestone } from "../../../hooks/useSelectedMilestone";
import { MilestoneContext } from "../../../../../contexts/TicketingSystem/MilestoneContext";
import { UseQueryResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { updateAsanaTask } from "../../../../../APIFunctions/Asana";
import { DataContext } from "./DataContext";
import { createTicketingSystemCellComment } from "../../../../../APIFunctions/ticketing_system";
import { useCellComments } from "../Hooks/useComments";
import { reportTypeToTabIndex } from "../Types/Report";
import { TableComment } from "../Types/Comment";

type CommentContextProviderProps = {
  editComment: (commentID: number, asanaTaskID: string, updateObj: any) => any;
  selectedCommentID: any;
  setSelectedCommentID: (selectedCommentID: any) => void;
  commentsTabIndex: number;
  setCommentsTabIndex: (commentsTabIndex: number) => void;
  showCommentsPan: boolean;
  setShowCommentsPan: (showCommentsPan: boolean) => void;
  onSelectComment: (comment: any) => void;
  commentCellCoordinates: {
    rowIndex: any;
    colIndex: any;
  };
  setCommentCellCoordinates: (summaryCoordinates: { rowIndex: any; colIndex: any }) => void;
  createCommentMutation: any;
  commentEditID: number | null;
  setCommentEditID: (commentEditID: number | null) => void;
  goToCommentCell: (comment) => void;
  selectedCell: any;
  setSelectedCell: (selectedCell: any) => void;
  CommentsListQuery: UseQueryResult<any, unknown>;
  reportPage: string;
};

export const CommentContext = createContext<CommentContextProviderProps>(null as any);

export const CommentContextProvider = (props) => {
  const queryClient = useQueryClient();
  let [searchParams, setSearchParams] = useSearchParams();
  const { selectedCompany } = useContext(AuthContext);
  const selectedMilestone = useSelectedMonthEndCloseMilestone();
  const { setMilestoneType, prevStage, preparationStageTasks, staffAssignee } = useContext(MilestoneContext);
  const { setReportTabIndex, reportType, reportDetailLevel, setSelectedTransaction } = useContext(DataContext);
  const [selectedCell, setSelectedCell] = useState<any>();
  const [selectedCommentID, setSelectedCommentID] = useState<any>();
  const [commentsTabIndex, setCommentsTabIndex] = useState<number>(0);
  const [showCommentsPan, setShowCommentsPan] = useState<boolean>(false);
  const [commentEditID, setCommentEditID] = useState<number | null>(null);
  const [commentCellCoordinates, setCommentCellCoordinates] = useState<{ rowIndex: any; colIndex: any }>({ rowIndex: 0, colIndex: 0 });

  const parentTask = useMemo(() => {
    const parentTaskName = props.parentTaskName;
    const findPreparationStageTasks = preparationStageTasks.find((x) => x.name === parentTaskName);
    return findPreparationStageTasks;
  }, [preparationStageTasks]);

  const reportPage = useMemo(() => props.parentTaskName, [props.parentTaskName]);

  const CommentsListQuery = useCellComments(props.parentTaskName);

  const onCreateComment = async () => {
    setShowCommentsPan(true);
    setCommentsTabIndex(0);
    // Add comment to an account
    const asana_task_assignee = prevStage?.assignee ? prevStage?.assignee?.gid : staffAssignee ? staffAssignee.gid : "me";
    const newComment = new TableComment(
      selectedCompany?.id,
      selectedCell?.row["id"],
      selectedCell?.["field"],
      reportType,
      reportDetailLevel,
      reportPage,
      selectedMilestone.data?.data?.gid,
      parentTask.gid,
      asana_task_assignee,
      selectedCell?.["row"]
    );  
    const response = await createTicketingSystemCellComment(newComment.newCommentObj);
    return response;
  };

  const editComment = async (commentID, asanaTaskID, updateObj) => {
    const data = { gid: asanaTaskID, ...updateObj };
    await updateAsanaTask(data);
    // todo: implement an api call to the backend that would create the asana task ..
    //  ..and place its id in the comment object on the backend.
  };

  const createCommentMutation = useMutation({
    mutationFn: onCreateComment,
    onSuccess: (newComment) => {
      queryClient.invalidateQueries(["cellComments", props.parentTaskName]);
      setCommentEditID(newComment.id);
    },
  });

  const onSelectComment = (comment) => {
    if (selectedCommentID !== comment.id) {
      setSelectedCommentID(comment.id);
      setSearchParams((prevParams) => {
        return new URLSearchParams({
          ...Object.fromEntries(prevParams.entries()),
          ...{
            comment: comment.id?.toString(),
            // report_type: comment.report_type,
          },
        });
      });
    }
  };

  let comments: any[] = [];
  if (commentsTabIndex === 0) {
    if (CommentsListQuery.data?.length > 0) {
      comments = CommentsListQuery.data.filter((x) => x.asana_task.completed === false);
    }
  } else {
    if (CommentsListQuery.data?.length > 0) {
      comments = CommentsListQuery.data.filter((x) => x.asana_task.completed === true);
    }
  }

  const goToCommentCell = (comment) => {
    if (!comment.meta_data) {
      setSelectedTransaction(null)
      setReportTabIndex(reportTypeToTabIndex[comment.report_type]);
    } else {
      setSelectedTransaction({transaction_type:comment.meta_data.transaction_type, transaction_id:comment.meta_data.transaction_id})
    }
    

    setTimeout(() => {
      if (comment.row_id) {
        setCommentCellCoordinates({ rowIndex: comment.row_id, colIndex: comment.column_id });
      }
    }, 100);
  };

  const onSelectCommentById = (commentId: Number) => {
    let targetComment;

    targetComment = CommentsListQuery.data?.find((x) => x.id === commentId);
    if (targetComment?.asana_task?.completed === true) {
      setCommentsTabIndex(1);
    } else {
      setCommentsTabIndex(0);
    }
    if (targetComment) {
      setShowCommentsPan(true);
      setSelectedCommentID(commentId);
      // setTimeout(() => {
      onSelectComment(targetComment);
      goToCommentCell(targetComment);
      // }, 2000);
    }
  };

  useEffect(() => {
    if (searchParams.get("comment")) {
      setShowCommentsPan(true);
      onSelectCommentById(Number(searchParams.get("comment")));
    }
  }, [CommentsListQuery.data]);

  return (
    <CommentContext.Provider
      value={{
        selectedCell,
        setSelectedCell,
        editComment,
        selectedCommentID,
        setCommentCellCoordinates,
        setCommentEditID,
        setCommentsTabIndex,
        setSelectedCommentID,
        setShowCommentsPan,
        commentCellCoordinates,
        commentEditID,
        commentsTabIndex,
        goToCommentCell,
        showCommentsPan,
        createCommentMutation,
        onSelectComment,
        CommentsListQuery,
        reportPage,
      }}
    >
      {props.children}
    </CommentContext.Provider>
  );
};
