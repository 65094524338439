import React from 'react';
import CustomTooltip from '../Common/Tooltip';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import { updateAsanaTask } from '../../APIFunctions/Asana';

const AsanaLikeButton = ({ task, setTask }) => {
  const onLikeTask = () => {
    const updatedTask = { ...task };
    updatedTask.liked = !task.liked;
    updateAsanaTask({
      gid: task.gid,
      liked: updatedTask['liked'],
    });
    setTask(updatedTask)
  };

  return (
    <>
      {task && (
        <CustomTooltip
          title={
            <React.Fragment>
              {task.likes?.length > 0
                ? task.likes.map((x) => <div key={x.user?.gid}>{x.user?.name}</div>)
                : 'be the first to like!'}
            </React.Fragment>
          }
        >
          {task.liked ? (
            <div
              id=""
              data-tip={task.likes.length}
              onClick={onLikeTask}
              className="btn--tertiary btn--XXS--centered success"
            >
              {task.likes.length > 1 && (
                <span className="Likes">{task.likes.length}</span>
              )}
              <ThumbUpIcon />
            </div>
          ) : (
            <div
              id=""
              onClick={onLikeTask}
              className="btn--tertiary btn--M--centered success"
            >
              <ThumbUpOutlinedIcon />
            </div>
          )}
        </CustomTooltip>
      )}
    </>
  );
};

export default AsanaLikeButton;
