import { endOfMonth, startOfMonth } from "date-fns";
import { dateObjToAsanaString } from "../../../../../../utils/DateFunctions";
import { getTransactions } from "../../../../../../APIFunctions/financial_statement";
import { addHighlightingAttribute } from "./cellHighlighting";

export const getPLTransactionsData = async (company, startDate, endDate) => {
  if (company && startDate && endDate) {
    const startDateString = dateObjToAsanaString(startDate);
    const endDateString = dateObjToAsanaString(endDate);
    const data = {
      company_id: company?.id,
      start_date: startDateString,
      end_date: endDateString,
      section_name: "Net Income",
    };

    const response = await getTransactions(data);
    return response;
  }
};

export const getBSTransactionsData = async (company, startDate, endDate) => {
  if (company && startDate && endDate) {
    const startDateString = dateObjToAsanaString(startDate);
    const endDateString = dateObjToAsanaString(endDate);
    const data = {
      company_id: company?.id,
      start_date: startDateString,
      end_date: endDateString,
      section_name: "Balance Sheet",
    };

    const response = await getTransactions(data);
    return response;
  }
};

export const processTransactionsData = (transactionsData, comments) => {
  let dataWithTreePath: any[] = [];
  for (let i = 0; i < transactionsData?.length; i++) {
    const obj = addHighlightingAttribute(transactionsData[i], comments);
    const encodedID = obj["id"].toString() + "-" + i.toString()
    if (i === 0) {
      dataWithTreePath.push({ ...obj, id: encodedID, hierarchy: [encodedID] });
    } else {
      if (obj["depth"] > dataWithTreePath[i - 1]["depth"]) {
        dataWithTreePath.push({ ...obj, id: encodedID, hierarchy: [...dataWithTreePath[i - 1]["hierarchy"], encodedID] });
      } else if (obj["depth"] === 0) {
        dataWithTreePath.push({ ...obj, id: encodedID, hierarchy: [encodedID] });
      } else if (obj["depth"] < dataWithTreePath[i - 1]["depth"]) {
        for (let j = i - 1; j > -1; j--) {
          if (obj["depth"] > dataWithTreePath[j]["depth"]) {
            dataWithTreePath.push({ ...obj, id: encodedID, hierarchy: [...dataWithTreePath[j]["hierarchy"], encodedID] });
            break;
          }
          if (j === 0) {
            const parentHierarchy = dataWithTreePath[j]["hierarchy"].slice(0, -1);
            dataWithTreePath.push({ ...obj, id: encodedID, hierarchy: [...parentHierarchy, encodedID] });
          }
        }
      } else {
        // remove the last element and add this object's id instead
        const parentHierarchy = dataWithTreePath[i - 1]["hierarchy"].slice(0, -1);
        dataWithTreePath.push({ ...obj, id: encodedID, hierarchy: [...parentHierarchy, encodedID] });
      }
    }
  }
  return dataWithTreePath;
};

