import { useContext, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { SocketContext } from "../../contexts/SocketContext";
import SyncConfirmDialog from "./SyncConfirmDialog";
import { DataContext } from "../../contexts/FinancialReport/DataContext";
import CircularStatic from "./CircularLoaderMUI";
import CircularProgressWithLabel from "./CircularLoaderMUI";

export default function SyncQBData() {
  const { SyncQBData,currentSyncInformation } = useContext(DataContext);
  const { isTrackingLogicAppStatus } = useContext(SocketContext);
  const [isOpen, setIsOpen] = useState(false);

  const applySync = () => {
    setIsOpen(false);
    SyncQBData();
  };

  const showtoolTip = currentSyncInformation;

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);
  return (
    <>
      {
        <div data-tip={currentSyncInformation.data?.status === "Running" ? "Please wait until the current syncing is done" : ""}>
          <button
            className={`${currentSyncInformation.data?.status === "Running" ? "btn--tertiary ": "btn--primary"} btn--XXS--centered`}
            data-tip="Sync to Quickbooks Online"
            id="sync-qbo"
            onClick={() => {
              setIsOpen(true);
            }}
          >
            {currentSyncInformation.data?.status === "Running" ? <CircularProgressWithLabel value={currentSyncInformation.data?.progress} /> : <AutorenewIcon sx={{ width: 17, height: 17 }} />}
          </button>
        </div>
      }
      <SyncConfirmDialog
        text={"Are you sure you want to Sync your Mighty Digits App with your Quickbooks Online data?"}
        open={isOpen}
        onCancel={() => {
          setIsOpen(false);
        }}
        onYes={() => applySync()}
      />
    </>
  );
}
