import React from "react";
import TransactionLineTable from "./TransactionLineTable";

const RefundTransaction = ({ transaction }) => {
  return (
    <div>
      <div className="TransactionDetail__GroupContainer">
      <div className="TransactionDetail__ParameterContainer">
        <span>Customer: </span>
        <div>{transaction?.["detail"]?.["RefundReceipt"]?.["CustomerRef"]?.["name"]}</div>
      </div>
      <div className="TransactionDetail__ParameterContainer">
        <span>Email: </span>
        <div>{transaction?.["detail"]?.["RefundReceipt"]?.["BillEmail"]?.["Address"]}</div>
      </div>
      <div className="TransactionDetail__ParameterContainer">
          <span>Billing address</span>
          <div className="TransactionDetail__ParameterContainer__MultiLine">
          <span>{transaction?.["detail"]?.["RefundReceipt"]?.["BillAddr"]?.["Line1"]}, </span>
          <span>{transaction?.["detail"]?.["RefundReceipt"]?.["BillAddr"]?.["Line2"]}, </span>
          <span>{transaction?.["detail"]?.["RefundReceipt"]?.["BillAddr"]?.["Line3"]}</span>
          </div>
        </div>
      <div className="TransactionDetail__ParameterContainer">
        <span>Payment method: </span>
        <div>{transaction?.["detail"]?.["RefundReceipt"]?.["PaymentMethodRef"]?.["name"]}</div>
      </div>
      <div className="TransactionDetail__ParameterContainer">
        <span>Refund from: </span>
        <div>{transaction?.["detail"]?.["RefundReceipt"]?.["DepositToAccountRef"]?.["name"]}</div>
      </div>
      <div className="TransactionDetail__ParameterContainer">
        <span>Refund Receipt date: </span>
        <div>{transaction?.["detail"]?.["RefundReceipt"]?.["TxnDate"]}</div>
      </div>
      </div>
      <TransactionLineTable 
      lines={transaction?.["detail"]?.["RefundReceipt"]?.["Line"]} 
      transactionID={transaction?.["detail"]?.["RefundReceipt"]?.["Id"]} 
      transaction_type="Credit Memo" />
      <div className="TransactionDetail__Subtotal">
        <span>Total Amount</span>
        <div>${transaction?.["detail"]?.["RefundReceipt"]?.["TotalAmt"]}</div>
      </div>
    </div>
  );
};

export default RefundTransaction;
