import { Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { styled } from "@mui/styles";



const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} arrow/>
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#1A3668",
      color: '#FFFFFF',
      fontSize: 12,
      width: "auto",
      padding: '8px 21px',
      opacity: "0.9 !important"
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "#1A3668",
    },
  }));

  export default CustomTooltip;