import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { PayrollContext } from "../../../../contexts/TicketingSystem/PayrollContext";
import MDIcons from "../../../Common/MDIcons";
import { createStyles, makeStyles } from "@mui/styles";
import GeneralHeader from "../../../Common/GeneralHeader";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import ReactTooltip from "react-tooltip";
import { MilestoneContext } from "../../../../contexts/TicketingSystem/MilestoneContext";
import { asanaTaskDateStringToDate, dateObjToAppString } from "../../../../utils/DateFunctions";
import { useNavigate } from "react-router-dom";

const PayrollListView = ({ setDisplayListFlag }) => {

  const navigate = useNavigate()

  const getPlatform = (milestone) => {
    return milestone?.custom_fields.find((x) => x.name === "Platform")?.display_value;
  };
  const getLocation = (milestone) => {
    return milestone?.custom_fields.find((x) => x.name === "Location")?.display_value;
  };

  const getType = (milestone) => {
    return milestone?.custom_fields.find((x) => x.name === "Type")?.display_value;
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const [tabIndex, setTabIndex] = useState<number>(0);
  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        boxShadow: "none",
        border: "none !important",
        height: "calc(100vh - 52rem)",
        "& .MuiTableCell-stickyHeader": {
          color: "var(--Neutral600) !important",
          fontWeight: "400 !important",
        },
        "& .MuiTableCell-root": {
          color: "#212134",
          fontSize: 14,
          height: "4.5rem",
          borderBottom: "1px solid #EAEAEF",
          justifyContent: "flex-start",
          "& span": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          },
          "& svg": {
            marginRight: "1rem",
            "& path": {
              fill: "var(--Success500)",
            },
          },
        },
      },
    })
  );

  const openPayroll = (milestone) => {
    setDisplayListFlag(false);
    setSelectedMilestone(milestone);
    // navigate(milestone.gid)

  };

  const classes = useStyles();
  const { setSelectedMilestone } = useContext(MilestoneContext);
  const { upcomingMilestones, completedMilestones } = useContext(PayrollContext);
  return (
    <div>
      <GeneralHeader title="Payroll" renderDivisions={false}></GeneralHeader>

      <Tabs className="tabs-bi" selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          <Tab
            className="tabs-bi__link settings_tab_link"
            disabledClassName="tabs-bi__link--disabled"
            selectedClassName="tabs-bi__link--selected"
            data-tip="Displays the upcoming 4 payrolls for this company."
          >
            UPCOMING PAYROLL
          </Tab>
          <Tab
            className="tabs-bi__link settings_tab_link"
            disabledClassName="tabs-bi__link--disabled"
            selectedClassName="tabs-bi__link--selected"
            data-tip="Displays the last 10 payrolls for this company."
          >
            PAYROLL HISTORY
          </Tab>
        </TabList>
        <TabPanel />
        <TabPanel />
      </Tabs>

      {tabIndex === 0 && (
        <TableContainer component={Paper} sx={{ border: "none" }} className={classes.root}>
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Platform</TableCell>
                <TableCell align="left">Location</TableCell>
                <TableCell align="left">Type</TableCell>
                <TableCell align="left">Due Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {upcomingMilestones?.slice(0, 4).map((row) => (
                <TableRow
                  key={row.gid}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                    ":hover": { background: "#F6F6F9" },
                  }}
                  onClick={() => openPayroll(row)}
                >
                  <TableCell align="left">{getPlatform(row)}</TableCell>
                  <TableCell align="left">{getLocation(row)}</TableCell>
                  <TableCell align="left">{getType(row)}</TableCell>
                  <TableCell align="left">{dateObjToAppString(asanaTaskDateStringToDate(row.due_on),"/")}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {tabIndex === 1 && (
        <TableContainer component={Paper} sx={{ border: "none" }} className={classes.root}>
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Platform</TableCell>
                <TableCell align="left">Location</TableCell>
                <TableCell align="left">Type</TableCell>
                <TableCell align="left">Due Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {completedMilestones?.slice(0, 10).map((row) => (
                <TableRow
                  key={row.gid}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="left">
                    <MDIcons title="checkbox_assignee" /> {getPlatform(row)}
                  </TableCell>
                  <TableCell align="left">{getLocation(row)}</TableCell>
                  <TableCell align="left">{getType(row)}</TableCell>
                  <TableCell align="left">{dateObjToAppString(asanaTaskDateStringToDate(row.due_on),"/")}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default PayrollListView;
