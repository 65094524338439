import { useState, useEffect, useRef } from 'react';
import './SearchComponent.css';
interface SearchComponentProps {
    searchText: string;
    setSearchText: (text: string) => void;
    searchPlaceHolder: string;
    extraClassName?: string;
}
export default function SearchComponent(props: SearchComponentProps) {

    const { searchText, setSearchText, searchPlaceHolder, extraClassName } = props;
    const [searchTerm, setSearchTerm] = useState(searchText)

    const isFirstRun = useRef(true);
    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        const delayDebounceFn = setTimeout(() => { setSearchText(searchTerm) }, 300)
        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm])

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value)
    }
    const clearSearch = () => {
        setSearchTerm("")
    }
    return (
        <div className={`searchBox settingsSearchBox ${extraClassName!}`}>
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.92188 8.40146L7.71354 6.16506C8.07292 5.53762 8.26042 4.83989 8.26042 4.13694C8.26042 1.85629 6.40625 0 4.13021 0C1.85417 0 0 1.85629 0 4.13694C0 6.4176 1.85417 8.27389 4.13021 8.27389C4.85677 8.27389 5.57552 8.07342 6.21615 7.69071L8.41406 9.91929C8.46354 9.96876 8.53385 10 8.60417 10C8.67448 10 8.74479 9.97136 8.79427 9.91929L9.92188 8.77896C10.026 8.67222 10.026 8.5056 9.92188 8.40146ZM4.12972 1.61133C5.52034 1.61133 6.65055 2.74385 6.65055 4.13671C6.65055 5.52958 5.52034 6.6621 4.12972 6.6621C2.7391 6.6621 1.60889 5.52958 1.60889 4.13671C1.60889 2.74385 2.7391 1.61133 4.12972 1.61133Z" fill="#32324D" />
            </svg>
            <input type="text"
                placeholder={searchPlaceHolder}
                onChange={onChange}
                value={searchTerm}
            />
            {
                searchTerm.length > 0 &&
                <svg className="modal__close" onClick={clearSearch} style={{ cursor: 'pointer' }} fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.55648 4.49936L8.77837 1.28451C8.91946 1.1434 8.99872 0.952011 8.99872 0.752449C8.99872 0.552887 8.91946 0.361499 8.77837 0.220387C8.63727 0.0792757 8.44591 0 8.24638 0C8.04685 0 7.85549 0.0792757 7.71439 0.220387L4.5 3.44273L1.28561 0.220387C1.14451 0.0792757 0.953154 -1.48685e-09 0.753621 0C0.554087 1.48685e-09 0.362726 0.0792757 0.221635 0.220387C0.0805431 0.361499 0.00127872 0.552887 0.00127871 0.752449C0.00127871 0.952011 0.0805431 1.1434 0.221635 1.28451L3.44352 4.49936L0.221635 7.71421C0.151406 7.78388 0.0956644 7.86676 0.0576247 7.95808C0.0195849 8.0494 0 8.14735 0 8.24627C0 8.3452 0.0195849 8.44315 0.0576247 8.53447C0.0956644 8.62579 0.151406 8.70867 0.221635 8.77833C0.291289 8.84857 0.37416 8.90432 0.465466 8.94237C0.556773 8.98041 0.654707 9 0.753621 9C0.852534 9 0.950468 8.98041 1.04177 8.94237C1.13308 8.90432 1.21595 8.84857 1.28561 8.77833L4.5 5.55599L7.71439 8.77833C7.78405 8.84857 7.86692 8.90432 7.95823 8.94237C8.04953 8.98041 8.14747 9 8.24638 9C8.34529 9 8.44323 8.98041 8.53453 8.94237C8.62584 8.90432 8.70871 8.84857 8.77837 8.77833C8.84859 8.70867 8.90434 8.62579 8.94238 8.53447C8.98042 8.44315 9 8.3452 9 8.24627C9 8.14735 8.98042 8.0494 8.94238 7.95808C8.90434 7.86676 8.84859 7.78388 8.77837 7.71421L5.55648 4.49936Z" fill="#62788C" fillOpacity="0.4" />
                </svg>
            }
        </div>
    )
}