import { useState } from "react";
import { uploadFile } from "../../../APIFunctions/file";
import { IFileResource } from "../../../types/API/fileResource";
import { ReactComponent as Example } from "../../../static/img/months_columns_example.svg";
import myExampleGif from "../../../static/img/PLExample.gif";
// import { showToast } from '../../../../utils/toastHelpers';

// import AlertDialog from '../../../Common/AlertDialog';

// import MDIcons from '../../../Common/MDIcons';
import { Company } from "../../../types/Company";
import { Onboarding } from "../../../types/Onboarding";
import { showToast } from "../../../utils/toastHelpers";
import AlertDialog from "../AlertDialog";
import MDIcons from "../MDIcons";
import "./UploadFileDialog.css";

interface IFileUploadDialogProps {
  open: boolean;
  onClose: () => void;
  meta: string;
  onSuccess?: (file: IFileResource) => void;
}

export default function FinancialReportUploadDialog({ open, onClose, meta, onSuccess }: IFileUploadDialogProps) {
  const [selectedFile, setSelectedFile] = useState<any>(null);

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const onAddClick = async () => {
    if (selectedFile) {
      if (selectedFile.size > 150 * 1024 * 1024) {
        // 150 MB
        console.error("File size is too big");
        return;
      }
      var result = await uploadFile(selectedFile, meta, selectedFile.name);
      if (result.detail) {
        showToast("success", "File uploaded successfully!");
        if (onSuccess) {
          onSuccess(result.file);
        }

        onClose();
      } else {
        onClose();
      }
    }
  };

  return (
    <AlertDialog
      dialogType="user-form"
      open={open}
      onClose={onClose}
      title={
        <span className="BulkAddEmployee__Title">
          {" "}
          <h3 style={{ fontWeight: 600 }}>{"Upload file"}</h3>{" "}
        </span>
      }
      dialogActions={
        <div className="action_btns_container">
          <button className={"btn--tertiary btn--M"} onClick={onClose} autoFocus>
            <span>Cancel</span>
          </button>
          <button className={!selectedFile ? "btn--primary--disabled btn--M" : "btn--primary btn--M"} onClick={() => onAddClick()} autoFocus>
            <span>Upload</span>
          </button>
        </div>
      }
    >
      <div className="UploadFile__Body__Container">
        <div className="upload_area">
          <MDIcons title={"File"} />
          <span className="Drag_Drop">
            <input id="nativeUploadFile" type="file" onChange={handleFileSelect} />
          </span>
        </div>
        <br></br>
        <div className="UploadFile__Body__Container__ExampleImage__Header">Follow this structure</div>
        <div className="UploadFile__Body__Container__ExampleImage">
          <img src={myExampleGif} alt="example" />
        </div>
      </div>
    </AlertDialog>
  );
}
