import React from "react";
import "./style.css";
import privacyPolicyImage from "../../../static/img/privacy-policy.png";
import mdLogoHeader from "../../../static/img/md-logo-card-header.png";
const PrivacyPolicy = () => {
  return (
    <div className="AppInformation__Container">
      <div className="AppInformation__Container__CenterCard">
        <img className="AppInformation__Container__MDLogoImage" src={mdLogoHeader} />
        <img className="AppInformation__Container__BannerImage" src={privacyPolicyImage} />
        <div className="AppInformation__Container__CenterCard__TextContent">
          <span style={{ color: "#8e8ea9", fontSize: "1.4rem", fontWeight: "700", marginTop: "3.9rem" }}>This Privacy Policy was last modified on June 28, 2022.</span>

          <div className="AppInformation__Container__CenterCard__TextContent__Paragraph">
            We at Mighty Digits LLC (“Mighty” “Mighty Digits,” “we,” “us,” or “our”) have created this privacy policy (the “Privacy Policy”) because we know that you care about how
            information you provide to us is used and shared. This Privacy Policy relates to the information collection and use practices of Mighty in connection with our Site
            located at <a href="www.mightydigits.com">www.mightydigits.com</a> (“Site”), Services that are made available to you through the Site and when you interact with us in
            any other way.
          </div>
          <div className="AppInformation__Container__CenterCard__TextContent__Paragraph">
            By visiting the Site, and/or using the Services, you are agreeing to the terms of this Privacy Policy and the accompanying Terms of Service. If you do not agree to all
            of the terms of this Privacy Policy, you may not access or use the Site and the Services.
          </div>
          <div className="AppInformation__Container__CenterCard__TextContent__Paragraph">
            Capitalized terms not defined in this Privacy Policy shall have the meanings set forth in our Terms of Service.
          </div>

          <ol>
            <li className="AppInformation__Container__CenterCard__TextContent__ParagraphHeader">INFORMATION WE COLLECT </li>
            <div className="AppInformation__Container__CenterCard__TextContent__Paragraph">
              In the course of operating the Site, providing the Services, and/or interacting with you, Mighty will collect (and/or receive) the following types of information.
            </div>
            <ol type="a">
              <li className="AppInformation__Container__CenterCard__TextContent__ParagraphHeader">Billing Information</li>
              <div className="AppInformation__Container__CenterCard__TextContent__Paragraph">
                When you sign up for a subscription, you will be required to provide certain information in addition to the Contact Information noted above. Such information may
                include a debit card number, credit card number, billing address, expiration date, security code and similar information (collectively, the “Billing Information”).
                Such Billing Information will be collected and processed by our third party payment processor pursuant to the terms and conditions of their privacy policies and
                terms of use. We do not collect, store or process any Billing Information.
              </div>

              <li className="AppInformation__Container__CenterCard__TextContent__ParagraphHeader">Customer Information </li>
              <div className="AppInformation__Container__CenterCard__TextContent__Paragraph">
                In order for us to provide the Services to you, you will provide us access to certain information based on the specific Services we are providing such as your bank
                statements, access to your Quickbooks OnlineTM account and other related financial documents.
              </div>

              <li className="AppInformation__Container__CenterCard__TextContent__ParagraphHeader">Other Information</li>
              <div className="AppInformation__Container__CenterCard__TextContent__Paragraph">
                In addition to the Contact Information, Billing Information and Customer Information, we may collect additional information regarding you and your use of our Site
                and Services, your interactions with us, as well as information regarding your computer or other devices used to access our Site and Services (collectively, the
                “Other Information”). Such Other Information may include:
              </div>
              <ul>
                <li className="AppInformation__Container__CenterCard__TextContent__Paragraph">
                  Information obtained from you. Additional information about yourself that you voluntarily provide to us.{" "}
                </li>
                <li className="AppInformation__Container__CenterCard__TextContent__Paragraph">
                  Information obtained from your online activity. Information that we automatically collect when you use the Site or Services, such as (i) information about the
                  device from which you access the Site or Services (i.e., the type of device, the advertising identifier (“IDFA” or “AdID”), the operating system and version (for
                  example iOS, Android or Windows), your carrier and network type (WiFi, 3G, 4G, LTE, etc.); (ii) your IP addresses (which may consist of a static or dynamic IP
                  address and will sometimes point to a specific identifiable computer or device); (iii) browser type and language; (iv) referring and exit pages and URLs; (v) date
                  and time; (vi) amount of time spent on particular pages; (vii) what features of the Site or Services you use or visit; and (viii) similar information concerning
                  your use of the Site or Services.
                </li>
                <li className="AppInformation__Container__CenterCard__TextContent__Paragraph">
                  Information we collect automatically through cookies, and similar technology. Like most online services, we automatically receive standard technical information
                  when you connect with the Site or Services. We collect this information through browser cookies, pixels, web server logs, web beacons, and similar technologies.
                  These features enable us to personalize your experience with the Site or Services, understand how you use them, maintain a persistent session, and improve and
                  further develop the Site or Services. Cookies are small packets of data that a Site stores on your computer’s hard drive so that your computer will “remember”
                  information about your visit. We use cookies to help us collect Other Information and to enhance your experience using the Site or Services. Specifically, we use
                  cookies to authenticate users, personalize your experience when using the Site or Services, analyze which features of the Site or Services you use most
                  frequently, and measure and optimize advertising and promotional effectiveness. To do this, we may use both session cookies, which expire once you close your web
                  browser, and persistent cookies, which stay on your computer until you delete them. If you do not want the Site or Services to place a cookie on your hard drive,
                  you may be able to turn that feature off on your computer or mobile device. Please consult your Internet browser’s documentation for information on how to do
                  this. However, if you decide not to accept cookies from us, the Site or Services may not function properly.{" "}
                </li>
                <li className="AppInformation__Container__CenterCard__TextContent__Paragraph">
                  Information obtained from other sources, including our social media pages. We may collect personal information from our users and visitors of other sites on which
                  you have used the Services. We will do so in accordance with the terms of use and privacy policies of those sites and applicable law. This includes our social
                  media pages on places like LinkedIn. For example, we may collect the information you shared on our social media pages through an application or form, which will
                  have a link to this Privacy Policy. Personal information may also be collected by the third-party social media sites that host our social media pages. These sites
                  may provide aggregate information and analysis to us about visitors' use of our social media pages. This allows us to better understand and analyze our user
                  growth, general demographic information about the users of these pages, and interaction with the content that we post. Overall, this information may be used to
                  help us understand the types of visitors and users of our social media pages and use of the content. This Privacy Policy does not cover personal information
                  collected by such third party social media sites. For more information on their privacy and security practices please review the privacy policies and terms of use
                  on their respective Sites.
                </li>
                <li className="AppInformation__Container__CenterCard__TextContent__Paragraph">
                  Information obtained from third-party analytics services. We use third-party analytics services (such as Google Analytics) to evaluate your use of the Site and
                  Services, compile reports on activity, collect demographic data, analyze performance metrics, and collect and evaluate other information relating to the Services
                  and mobile and internet usage. These third parties use cookies and other technologies to help analyze and provide us the data. By accessing and using the Site or
                  Services, you consent to the processing of data about you by these analytics providers in the manner and for the purposes set out in this Privacy Policy. For more
                  information on these third parties, including how to opt out from certain data collection, please visit the sites below. Please be advised that if you opt out of
                  any service, you may not be able to use the full functionality of the Site or the Services.
                </li>
                <br />
                <div className="AppInformation__Container__CenterCard__TextContent__Paragraph">
                  For Google Analytics, please visit <a href="https://www.google.com/analytics/">https://www.google.com/analytics/</a>.
                </div>
              </ul>
              <li className="AppInformation__Container__CenterCard__TextContent__ParagraphHeader">HOW WE USE AND SHARE YOUR INFORMATION</li>
              <div className="AppInformation__Container__CenterCard__TextContent__Paragraph">You authorize us to use the Contact Information, Volunteer Information, Survey Information and Other Information, (collectively, the "Information") to provide our Site and Services to you and to improve the Site and the Services. Also, we may use and share the Information as described below.</div>
              <ul>
                <li className="AppInformation__Container__CenterCard__TextContent__Paragraph"></li>
              </ul>
                    
            </ol>

            
          </ol>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
