import React, { useContext, useState } from "react";
import EmailContainerOtherActions from "../MilestoneEmail/EmailContainerHeader";
import SubTaskAcceptReject from "./SubTaskAcceptReject";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import MDIcons from "../../Common/MDIcons";
import MilestoneLinkButton from "./Links/MilestoneLinkButton";
import { SubTasksContext } from "../../../contexts/TicketingSystem/SubTasksContext";
import { MilestoneContext } from "../../../contexts/TicketingSystem/MilestoneContext";
import AlertDialog from "../../Common/AlertDialog";
import { countIncompleteSubtasks } from "../helpers";
import PermanentTicketingSystemLink from "./Links/PermanentTicketingSystemLink";

const MilestoneTaskContainerHeader = ({
  milestoneTask,
  ReviewFlag,
  completed,
  reviewTask,
  setReviewTask,
  displayComponentFlag,
  previousReviewTask,
  setPreviousReviewTask,
  type,
  onClose,
  onComplete,
  subTasksDict
}) => {
  const { payrollLoading } = useContext(MilestoneContext);
  const { displayMilestoneCardContent } = useContext(SubTasksContext);
  const [incompleteSubtasksCount, setIncompleteSubtasksCount] = useState<number | null>(null);
  const verifyCompleteness = () => {
    if (completed || (!completed && incompleteSubtasksCount === 0)) {
      onComplete()
    } else {
    setIncompleteSubtasksCount(countIncompleteSubtasks(subTasksDict?.[milestoneTask.gid]));
    }
  };

  const onCloseConfirmIncompleteSubtasksDialog = () => {
      setIncompleteSubtasksCount(null);
  };
  return (
    <>
      <div className="SubTask__Header">
        <button id="back-btn" data-test="back-btn" onClick={onClose} disabled={payrollLoading}>
          <ArrowBackIosNewIcon />
        </button>
        <span>{milestoneTask?.name}</span>
        {displayComponentFlag && type.current === "Email" && <EmailContainerOtherActions />}
        {type.current === "Draft Financial Package" && <PermanentTicketingSystemLink linkType={1} linkName={"Month End Financial Package"} areActionsAllowed={true} showAddButton={true}/>}

        {!ReviewFlag ? (
          <button
            id=""
            style={{ cursor: "pointer" }}
            className={completed ? "btn--secondary--completed btn--S flex-row--S success" : "btn--tertiary btn--S success"}
            onClick={verifyCompleteness}
          >
            <MDIcons title="check_button" />
            {completed ? "Marked completed" : "Mark complete"}
          </button>
        ) : (
          <>
            {displayMilestoneCardContent && (
              <SubTaskAcceptReject
                reviewTask={reviewTask}
                reviewTaskCompletion={reviewTask?.["completed"]}
                setReviewTask={setReviewTask}
                previousReviewTask={previousReviewTask}
                setPreviousReviewTask={setPreviousReviewTask}
                rejectTaskGid={milestoneTask?.gid}
                milestoneTaskName={milestoneTask?.name}
                milestoneTaskGID={milestoneTask?.gid}
                openMilestoneTask={null}
              />
            )}
          </>
        )}
      </div>
      <AlertDialog
        open={incompleteSubtasksCount !== null && incompleteSubtasksCount > 0}
        onClose={onCloseConfirmIncompleteSubtasksDialog}
        title={`This section has ${incompleteSubtasksCount} tasks incomplete. Are you sure you want to mark them all as completed?`}
        dialogType="yes_or_no"
        dialogActions={
          <>
            <button onClick={onCloseConfirmIncompleteSubtasksDialog} className="btn--tertiary btn--XS btn--80Width">
              Cancel
            </button>
            <button
              onClick={() => {
                onComplete();
                onCloseConfirmIncompleteSubtasksDialog();
              }}
              className="btn--secondary--completed btn--XS btn--80Width"
              autoFocus
              style={{ marginLeft: "0rem" }}
            >
              <MDIcons title="check_mark_for_approval"/>
              Complete
            </button>
          </>
        }
      />
    </>
  );
};

export default MilestoneTaskContainerHeader;
