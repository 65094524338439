import APIManger from "../APIManger";
import { showToast } from "./toastHelpers";

function processErrors(errorData: any) {
  if (typeof errorData === 'string') {
    return errorData;
  } else {
    let continue_searching = true;
    while (continue_searching) {
      const keys = Object.keys(errorData);
      if (typeof errorData[keys[0]] === 'string') {
        continue_searching = false;
        return errorData[keys[0]];
      } else if (typeof errorData[keys[0]] === 'object') {
        errorData = errorData[keys[0]];
      }
    }
    return 'Unknown Error Occured.';
  }
}

export function errorHanlder(error: any) {
  if (error && error.response) {
    const errorData = processErrors(error.response.data);
    switch (error.response.status) {
      case 400:
        showToast('error', errorData)
        break;
      case 401:
      case 403:
        APIManger.logout();
        break;
      case 500:
        showToast('error', 'Server error occured. Please contact the website administrator.');
        break;
      default:
        break;
    }
  }
}

export function asanaErrorHanlder(error: any) {
  if (error && error.response) {
    const errorData = processErrors(error.response.data);
    switch (error.response.status) {
      case 400:
        break;
      case 401:
        break;
      case 403:
        break;
      case 500:
        showToast('error', 'Asana Server error occured. Please contact the website administrator.');
        break;
      default:
        break;
    }
  }
}