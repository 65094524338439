import React, { useContext, useEffect, useState } from "react";
import CustomDatePicker from "../../Common/CustomDatePicker";
import { dateFromString } from "../../../utils/helpers";
import { createDateWithOffset, dateObjToAsanaString, endOfMonth } from "../../../utils/DateFunctions";
import { AuthContext } from "../../../contexts/AuthContext";
import CompanyQBOConnect from "../../Common/QBOConnect";
import { checkRollingForwardStatus, runRollingForward } from "../../../APIFunctions/data_modeling";
import { useQuery } from "@tanstack/react-query";
// import CustomDatePicker from '../Common/CustomDatePicker'

const RollForward = () => {
    const { selectedCompany } = useContext(AuthContext);
  const [lastDateOfActuals, setLastDateOfActuals] = useState<string>(dateObjToAsanaString(endOfMonth(createDateWithOffset(0,-1,0))));
  const [jobID, setJobId] = useState<number|null>(null)
  const [checkStatusInterval, setCheckStatusInterval] = useState<number>(1000 * 60 * 60 * 24);
  const [statusMessage, setStatusMessage] = useState<any>(null)

  

  const currentSyncInformation: any = useQuery({
    queryKey: ["roll-forward-job-status",jobID],
    queryFn: () => checkStatus(),
    refetchInterval: checkStatusInterval,
    enabled: !!(selectedCompany && jobID),
    staleTime:60*60*1000
  });

  const checkStatus = async() => {
    if (jobID) {
        const response = await checkRollingForwardStatus(jobID)
        return response
    }
    return null
  }


  const run = async() => {
    if (selectedCompany) {
        const response = await runRollingForward(selectedCompany, {
            "last_date_of_actuals":lastDateOfActuals,
            "copy_original_file":true
        })
        if ('job_id' in response) {
            setJobId(response['job_id'])
        }
        setCheckStatusInterval(1000);
    }
    
  }

  useEffect(() => {

    if (currentSyncInformation.data) {
        setStatusMessage(currentSyncInformation.data)
    }
    
  },[currentSyncInformation.data])

  useEffect(() => {
    if (currentSyncInformation.data?.run_status === 2 && checkStatusInterval !== 1000) {
      setCheckStatusInterval(1000);
    }

    if (currentSyncInformation.data?.run_status === 3 || currentSyncInformation.data?.run_status === 4) {
      setCheckStatusInterval(1000 * 60 * 60 * 24);
    }
  }, [currentSyncInformation.fetchStatus]);

  console.log("currentSyncInformation",currentSyncInformation)

  return (
    <div className="CompanySettings__Container">
    <div className="RollForward__Date">
        <div>Roll Forward Date</div>
      <CustomDatePicker
        selected={dateFromString(lastDateOfActuals)}
        onChange={(date: Date) => setLastDateOfActuals(dateObjToAsanaString(endOfMonth(date)))}
        dateFormat="MM-dd-yyyy"
        showYearDropdown
        showMonthYearPicker={true}
      />
      </div>

    {selectedCompany?.is_connected === true ? 
      
      <button className="btn--primary btn--M " onClick={run}>
        Run Roll Forward
      </button>
      :
      <CompanyQBOConnect />}
      <div>progress: {statusMessage?.progress ?  `${statusMessage?.progress}% completed.` : "Not started."}.
      </div>
      <div>{statusMessage?.run_status === 4 ? 'Rolling Forward was completed successfully.' : statusMessage?.run_status === 3 ? "Terminated early with errors." : ""}</div>
      {statusMessage?.error && <div>{`Errors: ${statusMessage?.error}`}</div>}
      
    </div>
  );
};

export default RollForward;
