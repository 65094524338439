import { FilterItem } from "../../../../types/CompanyFilters";

export const initialDates = new Date();

interface InitialState {
    id: number;
    email: string;
    locations: FilterItem[];
    Locations_select_all: boolean;
    departments: FilterItem[];
    Departments_select_all: boolean;
    divisions: FilterItem[];
    Divisions_select_all:boolean;
    status: boolean;
    role: number;
    headcount_access: boolean;
    embeded_views_access: boolean;

}


export const initialState: InitialState = {
    email: "",
    role: 0,
    departments: [],
    Departments_select_all: false,
    divisions: [],
    Divisions_select_all: false,
    locations: [],
    Locations_select_all: false,
    embeded_views_access: true,
    headcount_access: false,
    id: -1,
    status: false,
};

export const initialErrors = {
    email: false,
    role: false,
    departments: false,
    divisions: false,
    locations: false,
    embeded_views_access: false,
    headcount_access: false,
    status: false,
};