import { createContext, useContext, useEffect, useState } from "react";
import { retrieveAsanaSubTask, updateAsanaTask } from "../../APIFunctions/Asana";
import { asanaTaskDateStringToDate, dateObjToAsanaString } from "../../utils/DateFunctions";
import { AuthContext } from "../AuthContext";
import { TaskServicesContext } from "../Task/TaskServicesContext";

export interface MilestoneContextProps {
  upcomingMilestones: any[];
  setUpcomingMilestones: (upcomingMilestones: any[]) => void;
  currentMilestoneIndex: number;
  completedMilestones: any[];
  setCurrentMilestone: (currentMilestoneIndex: number) => void;
  selectedMilestone: any;
  setSelectedMilestone: (selectedMilestone: any) => void;
  subTasksAcceptanceArray: any[];
  setSubTasksAcceptanceArray: (subTasksAcceptanceArray: any[]) => void;
  milestoneTasksCompletionArray: any[];
  setMilestoneTasksCompletionArray: (milestoneTasksCompletionArray: any[]) => void;
  payrollLoading: boolean;
  setPayrollLoading: (payrollLoading: boolean) => void;
  isUserAssignedCurrentStage: boolean;
  setIsUserAssignedCurrentStage: (isUserAssignedCurrentStage: boolean) => void;
  currentStage: any;
  setCurrentStage: (currentStage: any) => void;
  currentStageIndex: number;
  setCurrentStageIndex: (currentStageIndex: number) => void;
  prevStage: any;
  setPrevStage: (prevStage: any) => void;
  nextStage: any;
  setNextStage: (nextStage: any) => void;
  subTasks: any[];
  setSubTasks: (subTasks: any[]) => void;
  userView: string;
  setUserView: (userView: string) => void;
  onComplete: () => void;
  getCurrentStage: () => void;
  getLowestDueDate: (incompleteMilestones: any[]) => Date;
  milestoneType: number|undefined;
  setMilestoneType: (milestoneType: number) => void;
  fullLoadedFlag: boolean;
  setFullLoadedFlag: (fullLoadedFlag: boolean) => void;
  onSubmitRequestRevision: () => void;
  allowEmailing: boolean;
  setAllowEmailing: (allowEmailing: boolean) => void;
  updateMilestoneTasksCompletionArray: (complete: boolean, taskGID: string) => void;
  refreshMilestonesFlag: boolean;
  setRefreshMilestonesFlag: (refreshMilestonesFlag: boolean) => void;
  fullWidthMode: boolean;
  setFullWidthMode: (fullWidthMode: boolean) => void;
  preparationStageTasks: any[];
  setPreparationStageTasks: (PreparationStageTasks:any[]) => void;
  staffAssignee:any;
  setStaffAssignee: (staffAssignee:any) => void;
}

export const MilestoneContext = createContext<MilestoneContextProps>(null as any);

export const MilestoneContextProvider = (props) => {
  const { userProfile } = useContext(AuthContext);
  const [selectedMilestone, setSelectedMilestone] = useState<any>();
  const [upcomingMilestones, setUpcomingMilestones] = useState<any>([]);
  const [completedMilestones, setCompletedMilestones] = useState<any[]>([]);
  const [currentMilestoneIndex, setCurrentMilestone] = useState<number>(0);
  const [subTasksAcceptanceArray, setSubTasksAcceptanceArray] = useState<any[]>([]);
  const [milestoneTasksCompletionArray, setMilestoneTasksCompletionArray] = useState<any[]>([]);
  const [payrollLoading, setPayrollLoading] = useState<boolean>(false);
  const [isUserAssignedCurrentStage, setIsUserAssignedCurrentStage] = useState<boolean>(false);
  const [currentStage, setCurrentStage] = useState<any>();
  const [currentStageIndex, setCurrentStageIndex] = useState<number>(0);
  const [prevStage, setPrevStage] = useState<any>();
  const [nextStage, setNextStage] = useState<any>();
  const [subTasks, setSubTasks] = useState<any[]>([]);
  const [userView, setUserView] = useState<string>("");
  const [milestoneType, setMilestoneType] = useState<number|undefined>(undefined);
  const [fullLoadedFlag, setFullLoadedFlag] = useState<boolean>(false);
  const [allowEmailing, setAllowEmailing] = useState<boolean>(false);
  const [refreshMilestonesFlag, setRefreshMilestonesFlag] = useState<boolean>(false);
  const [fullWidthMode, setFullWidthMode] = useState<boolean>(false);
  const [preparationStageTasks, setPreparationStageTasks] = useState<any[]>([]);
  const [staffAssignee, setStaffAssignee] = useState<any>();

  const updateMilestoneTasksCompletionArray = (completed, taskGID) => {
    const targetObj = milestoneTasksCompletionArray.find((x) => x.gid === taskGID);
    if (targetObj) {
      const updatedObj = { ...targetObj, completed };
      const updatedArray = milestoneTasksCompletionArray.map((x) => (x.gid === taskGID ? updatedObj : x));
      setMilestoneTasksCompletionArray(updatedArray);
    } else {
      // add a new obj to the array
      const updatedArray = [...milestoneTasksCompletionArray];
      updatedArray.push({ gid: taskGID, completed: completed });
      setMilestoneTasksCompletionArray(updatedArray);
    }
  };

  const getPreparationTasks = async() => {
    const preparationTask = subTasks.find((x) => x.name === "Staff Preparation");

    if (preparationTask) {
      setStaffAssignee(preparationTask["assignee"]);
      const response = await retrieveAsanaSubTask(preparationTask?.gid);
      if (response.data) {
        setPreparationStageTasks(response.data);
      }
    }
  };

  const onSubmitRequestRevision = async () => {
    const prevStage = { ...subTasks[currentStageIndex - 1] };
    if (currentStageIndex === 1) {
      setPrevStage(null);
    } else {
      setPrevStage(prevStage);
    }
    const nextStage = { ...subTasks[currentStageIndex] };
    setNextStage(nextStage);

    if (currentStageIndex > 0) {
      prevStage.completed = false;
      setCurrentStage(prevStage);
      setCurrentStageIndex(currentStageIndex - 1);
    }
    const today = dateObjToAsanaString(new Date());
    await updateAsanaTask({ gid: prevStage.gid, completed: false, due_date: today });
    setSubTasksAcceptanceArray([]);
    getCurrentStage();
  };

  const getLowestDueDate = (incompleteMilestones: any[]): Date => {
    let lowest_due_date: Date = new Date();
    let flag = false;
    for (let i = 0; i < incompleteMilestones.length; i++) {
      const due_date: Date | null = asanaTaskDateStringToDate(incompleteMilestones[i].due_on);
      if (due_date) {
        if (flag === false) {
          // to initiate
          lowest_due_date = due_date;
          flag = true;
        } else {
          if (lowest_due_date > due_date) {
            lowest_due_date = due_date;
          }
        }
      }
    }
    return lowest_due_date;
  };

  const onComplete = async () => {
    if (currentStage?.gid) {
      const newItem = { ...currentStage };
      newItem.completed = !currentStage.completed;
      currentStage.completed = newItem.completed;
      setPayrollLoading(true);
      await updateAsanaTask({
        gid: currentStage.gid,
        completed: newItem.completed,
      });
      setPayrollLoading(false);
    }

    getCurrentStage();
  };

  const userStage = (subtasks, currentStage) => {
    const thisUserGID = userProfile?.asana_user_id;
    if (thisUserGID) {
      for (let i = 0; i < subtasks.length; i++) {
        const subtask = subtasks[i];
        if (subtask.assignee?.gid === thisUserGID) {
          setUserView(subtask.name);

          if (currentStage?.gid === subtask.gid) break;
        }
      }
    }
  };

  const getCurrentStage = async () => {
    setPayrollLoading(true);

    if (selectedMilestone) {
      const response = await retrieveAsanaSubTask(selectedMilestone?.gid);
      if (response) {
        if (response.data) {
          let nextIncompleteSubtask: any = null;
          let nextIncompleteIndex: any = null;
          for (let i = 0; i < response.data.length; i++) {
            const subtask = response.data[i];
            if (subtask.completed === false && nextIncompleteSubtask === null && nextIncompleteIndex == null) {
              nextIncompleteSubtask = subtask;
              nextIncompleteIndex = i;
            }
          }
          if (nextIncompleteSubtask === null) {
            // set milestone as completed
            const newObj = { gid: selectedMilestone.gid, completed: true };
            const response = await updateAsanaTask(newObj);
            if (response?.["data"]?.["completed"]) {
              // after we confirm that the milestone has been set to completed
              setRefreshMilestonesFlag(true);
            }
          }
          setCurrentStageIndex(nextIncompleteIndex);
          setCurrentStage(nextIncompleteSubtask);
          userStage(response.data, nextIncompleteSubtask);
          setSubTasks(response.data);

          if (nextIncompleteIndex > 0) {
            const prevStage = { ...response.data[nextIncompleteIndex - 1] };
            setPrevStage(prevStage);
          }
          if (nextIncompleteIndex < response.data.length - 1) {
            const nextStage = { ...response.data[nextIncompleteIndex + 1] };
            setNextStage(nextStage);
          }
        }
      }
    }
    setPayrollLoading(false);
  };

  useEffect(() => {
    getPreparationTasks()
    
  }, [subTasks ]);

  useEffect(() => {
    getCurrentStage();
  },[selectedMilestone])

  return (
    <MilestoneContext.Provider
      value={{
        upcomingMilestones,
        setUpcomingMilestones,
        currentMilestoneIndex,
        completedMilestones,
        setCurrentMilestone,
        selectedMilestone,
        setSelectedMilestone,
        subTasksAcceptanceArray,
        setSubTasksAcceptanceArray,
        milestoneTasksCompletionArray,
        setMilestoneTasksCompletionArray,
        updateMilestoneTasksCompletionArray,
        payrollLoading,
        setPayrollLoading,
        isUserAssignedCurrentStage,
        setIsUserAssignedCurrentStage,
        currentStage,
        setCurrentStage,
        currentStageIndex,
        setCurrentStageIndex,
        prevStage,
        setPrevStage,
        nextStage,
        setNextStage,
        subTasks,
        setSubTasks,
        userView,
        setUserView,
        onComplete,
        getCurrentStage,
        getLowestDueDate,
        milestoneType,
        setMilestoneType,
        fullLoadedFlag,
        setFullLoadedFlag,
        onSubmitRequestRevision,
        allowEmailing,
        setAllowEmailing,
        refreshMilestonesFlag,
        setRefreshMilestonesFlag,
        fullWidthMode,
        setFullWidthMode,setPreparationStageTasks,preparationStageTasks, staffAssignee, setStaffAssignee
      }}
    >
      {props.children}
    </MilestoneContext.Provider>
  );
};
