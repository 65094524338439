import React, { useContext, useEffect, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import "../Components/MonthEndCloseViewsComponents.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import NotesQuestions from "../../Components/NotesAndQuestions/NotesQuestions";
import Comments from "./Comments";
import { Box, LinearProgress } from "@mui/material";
import { MilestoneContext } from "../../../../contexts/TicketingSystem/MilestoneContext";
import { createAsanaSubTask, retrieveAsanaTask, updateAsanaTask } from "../../../../APIFunctions/Asana";
import NoPayrollsView from "../../Components/NoMilestonesView";
import GeneralHeader from "../../../Common/GeneralHeader";
import AsanaLikeButton from "../../../AsanaComponents/AsanaLikeButton";
import MilestoneStagesHeader from "../../Components/MilestoneStagesHeader";
import MilestoneCard from "../Components/PlayrollCard/MilestoneCard";
import { SubTasksContextProvider } from "../../../../contexts/TicketingSystem/SubTasksContext";
import { dateObjToAsanaString } from "../../../../utils/DateFunctions";
import StageApproveReject from "../../Components/StageApproveReject";
import MilestoneHeader from "../../Components/MilestoneHeader";
import MainLoadingView from "../../Components/MainLoadingView";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useSelectedMonthEndCloseMilestone } from "../../hooks/useSelectedMilestone";
import { TaskServicesContext } from "../../../../contexts/Task/TaskServicesContext";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      boxShadow: "none",
      border: "none !important",
      height: "calc(100vh - 52rem)",
      "& .MuiTableCell-stickyHeader": {
        color: "var(--Neutral600) !important",
        fontWeight: "400 !important",
      },
      "& .MuiTableCell-root": {
        color: "#212134",
        fontSize: 14,
        height: "4.5rem",
        borderBottom: "1px solid #EAEAEF",
        justifyContent: "flex-start",
        "& span": {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
        "& svg": {
          marginRight: "1rem",
          "& path": {
            fill: "var(--Success500)",
          },
        },
      },
    },
  })
);

export type AdminViewOutletContextType = { setIsMilestoneTaskOpen: (milestoneTaskOpen: boolean) => void };

const AdminViewComponent = () => {
  const queryClient = useQueryClient();
  const { milestonegid } = useParams();
  const selectedMilestone = useSelectedMonthEndCloseMilestone();
  const { setSelectedMilestone, payrollLoading, setPayrollLoading, onSubmitRequestRevision, fullLoadedFlag, fullWidthMode } = useContext(MilestoneContext);

  const { team } = useContext(TaskServicesContext);
  const [milestoneFetchingTimeOut, setMilestoneFetchingTimeOut] = useState<boolean>(false);
  const [isMilestoneTaskOpen, setIsMilestoneTaskOpen] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const progressBarTheme = createTheme({
    components: {
      // Name of the component
      MuiLinearProgress: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            backgroundColor: "#E5E5E5",
            span: {
              backgroundColor: "#85b440 !important",
            },
          },
        },
      },
    },
  });

  useEffect(() => {
    // setPayrollLoading(true);
    setTimeout(() => {
      setMilestoneFetchingTimeOut(true);
      // setPayrollLoading(false)
    }, 7000);
  }, []);

  useEffect(() => {
    queryClient.invalidateQueries(["month-end", "selected-milestone", "first-incomplete"]);
  }, [milestonegid]);

  
  
  
  
  
  

  return (
    <>
      {!selectedMilestone.data && milestoneFetchingTimeOut && <NoPayrollsView milestoneType={"Month End Close"} />}

      {!fullLoadedFlag && <MainLoadingView milestoneType={"Month End Close"} />}

      {selectedMilestone.data && (
        <MilestoneHeader title="Month End Close" milestone={selectedMilestone.data?.data}>
          {selectedMilestone.data && milestoneFetchingTimeOut && (
            <>
              <AsanaLikeButton key={selectedMilestone.data?.data?.gid} task={selectedMilestone.data?.data} setTask={setSelectedMilestone} />
              <StageApproveReject onSubmitRequestRevision={onSubmitRequestRevision} />
            </>
          )}
        </MilestoneHeader>
      )}
      {selectedMilestone.data && milestoneFetchingTimeOut && <MilestoneStagesHeader />}

      {payrollLoading || selectedMilestone.isLoading ? (
        <Box sx={{ width: "100%" }}>
          <ThemeProvider theme={progressBarTheme}>
            <LinearProgress />
          </ThemeProvider>
        </Box>
      ) : (
        <Box sx={{ width: "100%", backgroundColor: "#f6f6f9", height: "4px" }}></Box>
      )}
      {selectedMilestone.data && (
        <div className="Payroll__ClientView__TopContainer">
          <div className={fullWidthMode ? "Payroll__ClientView__TopContainer__CenterContainer full-width" : "Payroll__ClientView__TopContainer__CenterContainer"}>
            <SubTasksContextProvider>
              {/* <MilestoneCard key={selectedMilestone?.gid} setIsMilestoneTaskOpen={setIsMilestoneTaskOpen} isMilestoneTaskOpen={isMilestoneTaskOpen} /> */}
              <Outlet context={{ setIsMilestoneTaskOpen, isMilestoneTaskOpen }} />
            </SubTasksContextProvider>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminViewComponent;
