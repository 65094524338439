import React from "react";
import TransactionLineTable from "./TransactionLineTable";

const InvoiceTransaction = ({ transaction }) => {
  return (
    <div>
      <div className="TransactionDetail__GroupContainer">
        <div className="TransactionDetail__ParameterContainer">
          <span>Customer</span>
          <div>{transaction?.["detail"]?.["Invoice"]?.["CustomerRef"]?.["name"]}</div>
        </div>
        <div className="TransactionDetail__ParameterContainer">
          <span>Customer Email</span>
          <div>{transaction?.["detail"]?.["Invoice"]?.["BillEmail"]?.["Address"] || "no email provided"}</div>
        </div>
        <div className="TransactionDetail__ParameterContainer">
          <span>Billing address</span>
          <div className="TransactionDetail__ParameterContainer__MultiLine">
            <span>{transaction?.["detail"]?.["Invoice"]?.["BillAddr"]?.["Line1"]}, </span>
            <span>{transaction?.["detail"]?.["Invoice"]?.["BillAddr"]?.["Line2"]}, </span>
            <span>{transaction?.["detail"]?.["Invoice"]?.["BillAddr"]?.["Line3"]}</span>
          </div>
        </div>
        <div className="TransactionDetail__ParameterContainer">
          <span>Invoice date</span>
          <div>{transaction?.["detail"]?.["Invoice"]?.["TxnDate"]}</div>
        </div>
        <div className="TransactionDetail__ParameterContainer">
          <span>Due Date</span>
          <div>{transaction?.["detail"]?.["Invoice"]?.["DueDate"]}</div>
        </div>
        <div className="TransactionDetail__ParameterContainer">
          <span>Document #</span>
          <div>{transaction?.["detail"]?.["Invoice"]?.["DocNumber"]}</div>
        </div>
      </div>
      <TransactionLineTable lines={transaction?.["detail"]?.["Invoice"]?.["Line"]} transactionID={transaction?.["detail"]?.["Invoice"]?.["Id"]} transaction_type="Invoice" />

      <div className="TransactionDetail__Subtotal">
        <span>Subtotal</span>
        <div>${transaction?.["detail"]?.["Invoice"]?.["Line"].find((x) => x["DetailType"] === "SubTotalLineDetail")?.["Amount"]}</div>
      </div>
      <div className="TransactionDetail__ParameterContainer">
        <span>Message</span>
        <div>{transaction?.["detail"]?.["Invoice"]?.["CustomerMemo"]?.["value"]}</div>
      </div>
    </div>
  );
};

export default InvoiceTransaction;
