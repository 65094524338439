import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MyGoogleLogin from "../GoogleAuthentication/GoogleLogin";
import CircularLoader from "../../Common/CircularLoader";

import { loginUser } from "../../../APIFunctions/auth";
import { UserProfile } from "../../../types/Auth";
import { AuthContext } from "../../../contexts/AuthContext";

import './Login.css';

const initialState = {
    email: '',
    password: '',
}

const initialErrors = {
    email: false,
    password: false,
    google_account_exists:false
}

export default function Login() {
    const { setUserProfile, setLocalToken, setUserSelectedCompany, targetURL, setTargetURL } = useContext(AuthContext)
    const [state, setState] = useState(initialState);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(initialErrors);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>, item: string) => {
        setErrors(initialErrors);
        setState((prev) => ({ ...prev, [item]: e.target.value }))
    }
    const login = async (email: string, password: string): Promise<boolean> => {
        setIsLoading(true);
        const result = await loginUser(email, password)
        if (result.token && result.profile) {
            setIsLoading(false);
            setLocalToken(result.token)
            const user = new UserProfile(result.profile);
            localStorage.setItem("UserProfile", JSON.stringify(user))
            localStorage.setItem("SelectedCompany", JSON.stringify(user.getFirstCompany()))
            setUserProfile(user)
            setUserSelectedCompany(user.getFirstCompany())
            
            if (!user.completed_profile)
                navigate('/complete')
            if (targetURL) {
                navigate(targetURL)
                setTargetURL(null)
            }

        } else {
            setIsLoading(false);
            // setRedirecrURL(null)
            setErrors({ email: true, password: true, google_account_exists: false });
            setState(initialState);
            return false
        }
        return false;
    }

    const GoogleLogin = async (token): Promise<boolean> => {
        setIsLoading(true);
        if (token.token && token.profile) {
            setIsLoading(false);
            setLocalToken(token.token)
            const user = new UserProfile(token.profile);
            localStorage.setItem("UserProfile", JSON.stringify(user))
            localStorage.setItem("SelectedCompany", JSON.stringify(user.getFirstCompany()))
            setUserProfile(user)
            setUserSelectedCompany(user.getFirstCompany())
            if (!user.completed_profile){
                navigate('/complete')
            }
                
            if (targetURL) {
                navigate(targetURL)
                setTargetURL(null)
            }
        } else {
            setIsLoading(false);
            setErrors({ email: false, password: false, google_account_exists: true });
            setState(initialState);
            return false
        }
        return false;
    }

    const validateForm = async () => {
        if (!state.password || state.password.length <= 0) {
            setErrors((prev) => ({ ...prev, password: true }));
        }
        if (state.email.length <= 0) {
            setErrors((prev) => ({ ...prev, email: true }))
        }
        if (!state.email.match(/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/)) {
            setErrors((prev) => ({ ...prev, email: true }))
        }
        if (!Object.values(errors).includes(true)) {
            await login(state.email, state.password)
        }
    }
    return (
        <div className="Login__Container">
            <h1>Login</h1>
            <div className="Auth__GoogleBtn">
                        <MyGoogleLogin GoogleLogin={GoogleLogin}/>
                    </div>
            <div className="Login__Container__Divider"></div>
            <div>
                <div className="Login__Container__EntryContainer">
                    <label>Email address</label>
                    <input type="email" name="email" placeholder="Email" maxLength={100} id="id_email"
                        onChange={(e) => onChange(e, 'email')}
                        value={state.email}
                        disabled={isLoading}
                        required
                        autoComplete="off"
                    />
                </div>
                <div className="Login__Container__EntryContainer">
                    <label>Password</label>
                    {/* <Link to="/forgetpassword">Forget Password</Link> */}
                    <input type="password" name="password" placeholder="Password" maxLength={100} id="id_password"
                        onChange={(e) => onChange(e, 'password')}
                        value={state.password}
                        disabled={isLoading}
                        required
                    />
                </div>
                {(errors.email || errors.password) && <span className="errorFont">Please enter a valid email and Password</span>}
                {(errors.google_account_exists) && <span className="errorFont">Your Gmail is not registered with us!</span>}
                <div className="Auth__BottomSection">
                    {isLoading ?
                        <CircularLoader color={"#007bff"} size={40} customClassName="custom-loader" />
                        :
                        <button id="login-btn" disabled={isLoading} className="btn--primary btn--L btn--fullWidth" onClick={validateForm}> Login </button>
                    }
                    <div className="m-4">
                        <label>Forgot your password ? <Link to="/forgot-password">Reset Password</Link></label>
                    </div>
                    {/* <div className="m-4">
                        <label>Don’t have an account ? <Link to="/register">Register</Link></label>
                    </div> */}
                    
                </div>
            </div>
        </div>
    )
}