import React from 'react'
import { Box, LinearProgress } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const LoadingBar = ({loading}) => {
    const progressBarTheme = createTheme({
        components: {
          // Name of the component
          MuiLinearProgress: {
            styleOverrides: {
              // Name of the slot
              root: {
                // Some CSS
                backgroundColor: "#E5E5E5",
                span: {
                  backgroundColor: "#85b440 !important",
                },
              },
            },
          },
        },
      });
  return (
    <>
    {loading ? (
        <Box sx={{ width: "100%" }}>
          <ThemeProvider theme={progressBarTheme}>
            <LinearProgress />
          </ThemeProvider>
        </Box>
      ) : (
        <Box sx={{ width: "100%", backgroundColor: "#E5E5E5", height: "4px" }}></Box>
      )}
    </>
  )
}

export default LoadingBar