import React from "react";
import MDIcons from "../../../../../Common/MDIcons";

const CommentEditFooter = ({ onEditCommentDescription, setCommentEditID }) => {
  return (
    <div className="CommentEditFooter">
    <button id="cancel-edit" onClick={() => setCommentEditID(null)}>
        Cancel
    </button>
      <button id="edit-comment" onClick={onEditCommentDescription}>
        <MDIcons title="submit_arrow" />
      </button>
    </div>
  )
};

export default CommentEditFooter;
