import APIManger from "../APIManger";
import { checked } from "../components/ExportQBO/MonthEndQBOContainer";
import { FinancialReportData__ResponseAPI } from "../types/API/FinancialReport";
import { Company } from "../types/Company";
import { CHECK_ROLLFORWARD_JOB_STATUS, DOWNLOAD_QBO_REPORT_IN_EXCEL, EXPORT_REPORT_TO_EXCEL, GET_LAST_EXPORT_TIME, GET_TRANSACTIONS_BY_DATE_ACCOUNT, LOAD_REPORT_DATA, RUN_ROLLFORWARD, SYNC_QB_DATA } from "./apiConstants";

export const syncQbData = async (company: Company): Promise<boolean> => {
  let url = SYNC_QB_DATA.replace("%COMPANY_ID%", company?.id?.toString());
  const apiResult: any = await APIManger.post(url);
  if (apiResult.detail === "Success") return true;
  else return false;
};
export const loadReportData = async (company: Company): Promise<FinancialReportData__ResponseAPI> => {
  let url = LOAD_REPORT_DATA.replace("%COMPANY_ID%", company?.id?.toString());
  const apiResult: FinancialReportData__ResponseAPI = await APIManger.post(url);
  return apiResult;
};

export const exportReportToExcel = async (company: Company,outputFormat: string, qboClasses:any[],fileName:string, sharepoint_folder_id:string, start_date_PL: any, end_date_PL: any, 
  start_date_BS: any, end_date_BS: any,start_date_PLDetail: any, 
  end_date_PLDetail: any,start_date_BSDetail: any, end_date_BSDetail: any, cashFLowsStartDate, cashFlowsEndDate, download:boolean, checked:checked, exportClasses: boolean): Promise<any> => {
  let url = EXPORT_REPORT_TO_EXCEL.replace("%COMPANY_ID%", company?.id?.toString())
  const data = {
    output_format:outputFormat,
    qbo_classes:qboClasses,
    file_name:fileName,
    sharepoint_folder_id:sharepoint_folder_id,
    start_date_pl:start_date_PL,
    end_date_pl:end_date_PL,
    start_date_pl_detail:start_date_PLDetail,
    end_date_pl_detail:end_date_PLDetail,
    start_date_bs:start_date_BS,
    end_date_bs:end_date_BS,
    start_date_bs_detail:start_date_BSDetail,
    end_date_bs_detail:end_date_BSDetail,
    start_date_cashflows:cashFLowsStartDate,
    end_date_cashflows: cashFlowsEndDate,
    checked:checked,
    exportClasses:exportClasses
  }

    let apiResult: any = await APIManger.post(url,data);
    return apiResult;
  }

export const downloadQBOExcelFile = async(export_run_id:string): Promise<any> => {
  let url = DOWNLOAD_QBO_REPORT_IN_EXCEL.replace("%EXPORT_RUN_ID%", export_run_id)
  let apiResult: any = await APIManger.downloadFinancials(url)
}

export const getTransactionsByDateAccount = async (company: Company, reportType: string, start_date: any, end_date: any, accountID: string): Promise<any> => {
  let url = GET_TRANSACTIONS_BY_DATE_ACCOUNT.replace("%COMPANY_ID%", company?.id?.toString()).replace("%REPORT_TYPE%", reportType)
  .replace("%START_DATE%", start_date.toString()).replace("%END_DATE%", end_date.toString()).replace("%ACCOUNT_ID%", accountID);
  const apiResult: any = await APIManger.get(url);
  return apiResult;
};


export const getLastQBOExportTime = async (company: Company): Promise<any> => {
  let url = GET_LAST_EXPORT_TIME.replace("%COMPANY_ID%", company?.id?.toString());
  const apiResult: any = await APIManger.get(url);
  return apiResult;
};


export const runRollingForward = async (company: Company, rollForwardRequestBody): Promise<any> => {
  let url = RUN_ROLLFORWARD.replace("%COMPANY_ID%", company?.id?.toString());
  const apiResult: any = await APIManger.post(url, rollForwardRequestBody);
  return apiResult;
};
export const checkRollingForwardStatus = async (job_id:number): Promise<any> => {
  let url = CHECK_ROLLFORWARD_JOB_STATUS.replace("%JOB_ID%", job_id.toString());
  const apiResult: any = await APIManger.get(url);
  return apiResult;
};


