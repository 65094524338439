import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React from 'react'
import { deleteAsanaTask, updateAsanaTask } from '../../../APIFunctions/Asana';

export const useEditAsanaTask = () => {
    const queryClient = useQueryClient();
    
    const editAsanaTaskMutation = useMutation({
        mutationFn: (updateObj:any) => updateAsanaTask(updateObj),
        onSuccess: (data) => {
          queryClient.invalidateQueries(["asana-task",data['data']['gid']]);
        },
      });
  return editAsanaTaskMutation
}

export const useDeleteAsanaTask = () => {
  const queryClient = useQueryClient();
  
  const deleteAsanaTaskMutation = useMutation({
      mutationFn: (gid:string) => deleteAsanaTask(gid),
      onSuccess: (data) => {
        queryClient.removeQueries(["asana-task",data['data']['gid']]);
      },
    });
return deleteAsanaTaskMutation
}

