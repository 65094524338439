import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import { DistributiveOmit } from '@mui/types';

import './AlertDialog.css';


export interface AlertDialogProps {
  onClose: (value: boolean) => void;
  title: React.ReactNode;
  dialogType: string;
  dialogActions?: React.ReactNode;
}

export default function AlertDialog({ onClose, title, dialogActions, dialogType, children, ...others }: AlertDialogProps & DistributiveOmit<DialogProps, keyof AlertDialogProps>) {
  return (
    <Dialog
      className={`alert-dialog-${dialogType}`}
      onClose={() => onClose(false)}
      aria-labelledby={`alert-dialog-${title}`}
      aria-describedby={`alert-dialog-description${title}`}
      PaperProps={{
        elevation: 0,
        sx: {
          width: 'auto',
          top: '0rem',
          height: dialogType === "task-form" ? '90% !important' : 'auto'
        },
      }}
      sx={{
        overflow: 'hidden',
        boxShadow: '4px 6px 10px rgba(0, 0, 0, 0.05)',
        borderRadius: 4,
      }}
      BackdropProps={{
        sx: {
          opacity: 0.5,
        }
      }}
      {...others}
    >
      <DialogTitle
        id={`alert-dialog-${title}`} sx={{display:"flex", lineHeight:"2.3rem"}}>
        {
          dialogType === "alert" && <ErrorIcon sx={{ color: 'rgba(208, 43, 32, 1)', fontSize: 25, marginRight: '10px' }} />
        }
        
        {dialogType === "yes_or_no" ? 
        <div style={{paddingRight: "35px", width: dialogType === "yes_or_no" ? "20rem" : "auto", fontWeight: dialogType === "yes_or_no" ? "400" : "600"}}>
        {title}
        </div>
        :
        <>
        {title}
        </>
      }
        
        
        {onClose ? (
          <IconButton
            className="btn--tertiary btn--XS--centered"
            aria-label="close"
            onClick={() => onClose(false)}
            sx={dialogType === 'task-form' ? {
              position: 'relative',
              color: 'rgba(33, 33, 52, 1)',
              borderRadius: '4px',
              marginLeft: '10px',
              padding: '3px',
            }
            :
            {
              position: 'absolute',
              color: 'rgba(33, 33, 52, 1)',
              borderRadius: '4px',
              marginLeft: 'auto',
              right:'1.6rem',
              top:'1.6rem',
              padding: '3px'
            }
          }
          >
            <CloseIcon sx={dialogType === "alert" ? { fontSize: 13 } : { fontSize: 16 }} />
          </IconButton>
        ) : null}

      </DialogTitle>
      {children &&
        <DialogContent>
          {children}
        </DialogContent>
      }
      {dialogActions &&
        <>
          <Divider />
          <DialogActions sx={{display:"flex", flexDirection: dialogType === "yes_or_no" ? "column" : "row", alignItems: "center", gap: "1rem"}}>
            {dialogActions}
          </DialogActions>
        </>
      }
    </Dialog>
  );
};