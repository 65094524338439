import { CURRENT_BASE_SERVER_URL, CURRENT_WS_BASE_SERVER_URL } from "../constants/server"


export const CURRENT_BASE_URL = CURRENT_BASE_SERVER_URL
export const CURRENT_WS_BASE_URL = CURRENT_WS_BASE_SERVER_URL
export const ASANA_BASE_URL = "https://app.asana.com/api/1.0/"
export const ASANA_WORKSPACE = "1128222288569674"

/**
 * Graph API APIs
 */
export const SYNC_EXCEL_FILE = "graph_api/sync-excel-file/%COMPANY_ID%/"
export const LIST_SHAREPOINT_CHILDREN = "graph_api/sharepoint/folder/children"
export const ADD_SHAREPOINT_FOLDER = "graph_api/sharepoint/folder/add"
export const RENAME_SHAREPOINT_ITEM = "graph_api/sharepoint/resource/rename"
export const LIST_SHAREPOINT_FILE_PERMISSIONS = "graph_api/sharepoint/resource/permissions"
export const ADD_SHAREPOINT_FILE_PERMISSION = "graph_api/sharepoint/resource/permissions/add"
export const DELETE_SHAREPOINT_FILE_PERMISSION = "graph_api/delete-sharepoint-file-permissions/%COMPANY_ID%/%FILE_ID%/%EMAIL%"





// account

export const LOGIN = "accounts/login/"
export const LOGOUT = "accounts/logout/"
export const SIGN_UP = "accounts/signup/"
export const GET_USER_PROFILE = "accounts/profile/"


// Forget Password Flow
export const SEND_TEMP_PASSWORD_CODE = "accounts/send-temp-password-code/"
export const VERIFY_PASSWORD_CODE = "accounts/verify-password-code/"
export const RESET_PASSWORD = "accounts/reset-password/"

/**
 * Account Settings APIs
 */
export const COMPANY_SETTINGS_CHANGE = "accounts/edit-company/%COMPANY_ID%/"
export const COMPANY_USER_CREATE_LIST = "accounts/company/%COMPANY_ID%/company-user/"
export const COMPANY_USER_RETRIVE_UPDATE_DELETE = "accounts/company/%COMPANY_ID%/company-user/%COMPANY_USER_ID%/"

export const COMPANY_USER_DELETE_LIST = "accounts/company/%COMPANY_ID%/company-user-list/"

export const COMPLETE_PROFILE = "accounts/complete-profile/"
export const ACCEPT_INVITATION = "accounts/accept-company-invitation/"
export const ACCEPT_ONBOARDING_INVITATION = "onboarding/accept-onboarding-invitation/"
export const INVITATION_COMPLETE_PROFILE = "accounts/complete-profile/%INVITATION_CODE%/"
export const GOOGLE_INVITATION_COMPLETE_PROFILE = "accounts/google-signup/%INVITATION_CODE%/"

export const INVITATION_ONBOARDING_COMPLETE_PROFILE = "onboarding/onboarding-complete-profile/%INVITATION_CODE%/"
export const COMPANY_PROP_CREATE = "accounts/company-prop-create/%COMPANY_ID%/"

export const GET_HEAD_COUNT_EMPLOYEE_LIST = "headcount/info-list/%COMPANY_ID%/"
export const EXPORT_HEAD_COUNT_EMPLOYEE_LIST = "headcount/info-csv-export/%COMPANY_ID%/"
export const EXPORT_PAYMENT_PLAN = "accounts/payment-plan-export/%COMPANY_ID%/"
export const DOWNLOAD_CSV_SAMPLE = "headcount/sample-csv/"
export const GET_HEAD_COUNT_LIST_FILTERS = "headcount/info-filters/%COMPANY_ID%/"

export const UPLOAD_HEAD_COUNT_LIST = "headcount/upload-info-list/%COMPANY_ID%/"

export const UPLOAD_FILES = "files/file-upload/"
export const DELETE_EMPLOYEE_LIST = "headcount/info-list-delete/%COMPANY_ID%/"
export const UPDATE_EMPLOYEE_LIST = "headcount/update-info-list/%COMPANY_ID%/"
export const DUPLICATE_EMPLOYEE_LIST = "headcount/duplicate-info-list/%COMPANY_ID%/"
export const ADD_EMPLOYEE = "headcount/info-create/%COMPANY_ID%/"
export const EDIT_DELETE_EMPLOYEE = "headcount/info-item/%COMPANY_ID%/%ID%/"

export const PRINT_EMBEDED_PDF = "headcount/print-embeded-pdf/%COMPANY_ID%/%DIVISION_ID%/"
export const Get_PDF_DOWNLOAD_LINK = "headcount/pdf-download-link/%COMPANY_ID%/"

export const LOAD_DASHBOARD = "miscellaneous/dashboard/%COMPANY_ID%/"
export const ONBOARDING_RETRIEVE_UPDATE = "onboarding/onboarding-info/%ONBOARDING_ID%/"

export const ONBOARDING_CONNECT_QBO = "quickbox/connect/onboarding/%ONBOARDING_ID%/"
export const ONBOARDING_DISCONNECT_QBO = "quickbox/disconnect/onboarding/%ONBOARDING_ID%/"
export const COMPANY_CONNECT_QBO = "quickbox/connect/company/%COMPANY_ID%/"
export const COMPANY_DISCONNECT_QBO = "quickbox/disconnect/company/%COMPANY_ID%/"


/**
 * Asana APIs
 */
export const ASANA_SEARCH_BY_SECTION_INCOMPLETE_MILESTONES = `workspaces/${ASANA_WORKSPACE}/tasks/search?resource_subtype=milestone&sections.any=%SECTION_GID%&completed=false&sort_by=due_date&sort_ascending=true&opt_fields=name,completed,followers,assignee.name,due_on,due_at,likes.user.name,assignee.photo,liked,memberships.section.name,memberships.project.name,modified_at,notes,html_notes,num_likes,num_subtasks,tags,tags.name,custom_fields&opt_expand=likes`
export const ASANA_TASK_RETRIEVE_UPDATE = "tasks/%TASK_GID%/"
export const ASANA_TASK_RETRIEVE = "tasks/%TASK_GID%/?opt_fields=name,completed,followers,assignee.name,due_on,due_at,likes.user.name,assignee.photo,liked,memberships.section.name,memberships.project.name,modified_at,notes,html_notes,num_likes,num_subtasks,tags,tags.name,custom_fields,created_by.name,created_by.photo,created_at,tags,tags.name&opt_expand=likes"
export const ASANA_TASK_DELETE = "tasks/%TASK_GID%/"
export const ASANA_TASKS_RETRIEVE = "projects/%PROJECT_GID%/tasks?opt_fields=name,completed,followers,assignee.name,due_on,due_at,likes.user.name,assignee.photo,liked,memberships.section.name,memberships.project.name,modified_at,notes,html_notes,num_likes,num_subtasks,tags,tags.name,custom_fields&opt_expand=likes&limit=100"
// search?resource_subtype=milestone&sort_by=modified_at&sort_ascending=fals
export const ASANA_TASKS_IN_SECTION_RETRIEVE = "sections/%SECTION_GID%/tasks?opt_fields=name,completed,followers,assignee.name,due_on,due_at,likes.user.name,assignee.photo,liked,memberships.section.name,memberships.project.name,modified_at,notes,html_notes,num_likes,num_subtasks,tags,tags.name,custom_fields&opt_expand=likes"
export const ASANA_PROJECT_CREATE = "projects/%PROJECT_GID%/duplicate"
export const ASANA_PROJECT_EVENT_RETRIEVE = "events?resource=%PROJECT_GID%&sync=%SYNC_GID%"
export const ASANA_CUSTOM_FIELDS_RETRIEVE = "projects/%PROJECT_GID%/custom_field_settings"
export const ASANA_TEAM_RETRIEVE = "projects/%PROJECT_GID%/project_memberships?opt_fields=user.name,user.gid,user.photo,user.email"
export const ASANA_TASK_CREATE = "tasks/"
export const ASANA_TASK_ADD_TO_SECTION = "sections/%SECTION_GID%/addTask"
export const ASANA_SUBTASK_CREATE = "tasks/%TASK_GID%/subtasks?opt_fields=name,completed,followers,assignee.name,assignee.photo,due_on,likes,liked,memberships.section.name,memberships.project.name,modified_at,notes,html_notes,num_likes,num_subtasks,custom_fields,tags,tags.name"
export const ASANA_TASK_COMMENT_CREATE = "tasks/%TASK_GID%/stories"
export const ASANA_TASK_COMMENT_DELETE = "stories/%STORY_GID%"
export const ASANA_TASK_ADD_PROJECT = "tasks/%TASK_GID%/addProject"
export const ASANA_SECTIONS_RETRIEVE = "projects/%PROJECT_GID%/sections"
export const ASANA_TASK_STORIES_RETRIEVE = "tasks/%TASK_GID%/stories?opt_fields=likes.user.name,liked,created_at,html_text,resource_subtype,type,text,created_by.name,created_by.photo&opt_expand=likes"
export const ASANA_TASK_STORY_RETRIEVE = "stories/%STORY_GID%"
export const ASANA_TASK_SUBTASKS_RETRIEVE = "tasks/%TASK_GID%/subtasks?opt_fields=name,completed,followers,assignee.name,assignee.photo,due_on,likes,liked,memberships.section.name,memberships.project.name,modified_at,notes,html_notes,num_likes,num_subtasks,custom_fields,tags,tags.name"
export const ASANA_TASK_ATTACHMENT_UPLOAD = "tasks/%TASK_GID%/attachments"
export const ASANA_TASK_ATTACHMENT_DELETE = "attachments/%ATTACHMENT_GID%"
export const ASANA_TASK_ATTACHMENTS_RETRIEVE = "attachments?parent=%TASK_GID%&opt_fields=name,download_url,permanent_url,view_url"
export const ASANA_TASK_ATTACHMENT_RETRIEVE = "attachments/%ATTACHMENT_GID%"


// asana authentication
export const ASANA_CONNECT = "asanaOauth/auth/Connect/"
export const ASANA_REFRESH = "asanaOauth/auth/authTokenRefresh/"
export const ASANA_DISCONNECT = "asanaOauth/auth/Disconnect/"
export const ASANA_RETRIEVE_ACCESS_TOKEN = "asanaOauth/accessToken/"
export const PROJECT_SUBTASKS_RETRIEVE = "asanaOauth/project-subtasks/%PROJECT_GID%/"

// asana automations
export const PROJECT_MILESTONES_CREATE = "asanaOauth/create_project-milestones/%PROJECT_NAME%/"


/**
 * DataModeling APIs
 */
// export const SYNC_QB_DATA = "data_modeling/sync_qb/%COMPANY_ID%/"
export const LOAD_REPORT_DATA = "data_modeling/load_report_data/%COMPANY_ID%/"
export const EXPORT_REPORT_TO_EXCEL = "data_modeling/export_report_to_excel/%COMPANY_ID%"
export const DOWNLOAD_QBO_REPORT_IN_EXCEL = "data_modeling/download_excel_file/%EXPORT_RUN_ID%"
export const GET_TRANSACTIONS_BY_DATE_ACCOUNT = "data_modeling/get_transactions_by_date_account/%COMPANY_ID%/%REPORT_TYPE%/%START_DATE%/%END_DATE%/%ACCOUNT_ID%"
export const GET_LAST_EXPORT_TIME = "data_modeling/get_last_qbo_to_excel/%COMPANY_ID%"



/**
 * Financial Statements APIs
 */
export const SYNC_QB_DATA = "financial_statement/sync_qb/%COMPANY_ID%/%START_DATE%/%END_DATE%/%ASANA_MILESTONE_GID%"
export const GET_TRANSACTIONS = "financial_statement/get-transactions"
export const CHECK_SYNC_STATUS = "financial_statement/check_sync_status/%COMPANY_ID%/%ASANA_MILESTONE_GID%"

// Rolling Forward
export const RUN_ROLLFORWARD = "excel-financial-model/roll-forward/%COMPANY_ID%"
export const CHECK_ROLLFORWARD_JOB_STATUS = "excel-financial-model/roll-forward/job/%JOB_ID%"

/**
 * Ticketing System
 */
export const PAYROLL_TYPES_RETRIEVE = "ticketing-system/payroll-types/%COMPANY_ID%/"
export const PAYROLL_TYPES_CREATE = "ticketing-system/payroll-types-create/%COMPANY_ID%/"
export const PAYROLL_LIST_RETRIEVE = "ticketing-system/payroll-list/%CYCLE_DATE%/"
export const EMAIL_RETRIEVE = "ticketing-system/email/%ASANA_MILESTONE_GID%/"
export const PAYROLL_CREATE = "ticketing-system/create-payroll/%TYPE_ID%/%CYCLE_DATE%/"
export const PAYROLL_FILE_LINK_RETRIEVE = "ticketing-system/payroll-file-link/%PAYROLL_TYPE_ID%/"
export const PAYROLL_PLATFORM_LINK_RETRIEVE = "ticketing-system/payroll-platform-link/%PLATFORM_TYPE_ID%/"
export const PAYROLL_FILE_LINK_UPDATE = 'ticketing-system/update-payroll-file-link/%LINK_ID%/'
export const PAYROLL_QUESTION_CREATE = "ticketing-system/create-payroll-question/%EMAIL_ID%/"
export const PAYROLL_QUESTION_IN_HEADER_CREATE = "ticketing-system/create-payroll-question-in-header/%HEADER_ID%/"
export const EMAIL_QUESTIONS_HEADER_CREATE = "ticketing-system/create-email-questions-header/%EMAIL_ID%/"
export const EMAIL_NOTES_HEADER_CREATE = "ticketing-system/create-email-notes-header/%EMAIL_ID%/"
export const PAYROLL_NOTE_CREATE = "ticketing-system/create-payroll-note/%EMAIL_ID%/"
export const PAYROLL_NOTE_IN_HEADER_CREATE = "ticketing-system/create-payroll-note-in-header/%HEADER_ID%/"
export const PAYROLL_QUESTION_UPDATE = "ticketing-system/update-payroll-question/%QUESTION_ID%/"
export const PAYROLL_QUESTION_IN_HEADER_UPDATE = "ticketing-system/update-payroll-question-in-header/%QUESTION_ID%/"
export const PAYROLL_NOTE_UPDATE = "ticketing-system/update-payroll-note/%NOTE_ID%/"
export const PAYROLL_NOTE_IN_HEADER_UPDATE = "ticketing-system/update-payroll-note-in-header/%NOTE_ID%/"
export const PAYROLL_NOTES_HEADER_UPDATE = "ticketing-system/update-payroll-notes-header/%HEADER_ID%/"
export const PAYROLL_QUESTIONS_HEADER_UPDATE = "ticketing-system/update-payroll-questions-header/%HEADER_ID%/"

export const PAYROLL_QUESTIONS_LIST_RETRIEVE  = "ticketing-system/retrieve-payroll-questions-list/%EMAIL_ID%/"
export const PAYROLL_QUESTIONS_IN_HEADER_LIST_RETRIEVE  = "ticketing-system/retrieve-payroll-questions-in-header-list/%HEADER_ID%/"
export const PAYROLL_QUESTIONS_HEADERS_LIST_RETRIEVE  = "ticketing-system/retrieve-payroll-questions-headers-list/%EMAIL_ID%/"
export const PAYROLL_NOTES_LIST_RETRIEVE  = "ticketing-system/retrieve-payroll-notes-list/%EMAIL_ID%/"
export const PAYROLL_NOTES_IN_HEADER_LIST_RETRIEVE  = "ticketing-system/retrieve-payroll-notes-in-header-list/%HEADER_ID%/"
export const PAYROLL_NOTES_HEADERS_LIST_RETRIEVE  = "ticketing-system/retrieve-payroll-notes-headers-list/%EMAIL_ID%/"

// Month End Close Cell Comments
export const CELL_COMMENTS_LIST_RETRIEVE  = "ticketing-system/retrieve-month-end-comments/%MILESTONE_ID%"
export const CELL_COMMENTS_RETRIEVE_UPDATE_DELETE  = "ticketing-system/retrieve-update-cell-comments/%CELL_COMMENT_ID%/"
export const CELL_COMMENTS_CREATE = "ticketing-system/create-cell-comment/"
export const TRANSACTION_DETAIL = "quickbox/transaction_detail/%COMPANY_ID%/%TRANSACTION_TYPE%/%TRANSACTION_ID%"

// QBO Report
export const RUN_AGED_REPORTS_QBO = "quickbox/run_aged_reports/%COMPANY_ID%/%START_DATE%/%END_DATE%"
export const GET_AGED_REPORTS_QBO = "quickbox/aged_reports/%COMPANY_ID%/%RUN_ID%"
export const AGED_REPORTS_DETAIL_QBO = "quickbox/aged_report_detail/%COMPANY_ID%?%PARAMS%"

// QBO Connection status for a company
export const IS_CONNECTED = "quickbox/is_connected/%COMPANY_ID%"

// get email for asana milestone
export const EMAIL_RETRIEVE_UPDATE  = "ticketing-system/create-retrieve-update-email/%ASANA_MILESTONE_ID%/%COMPANY_ID%/"


export const PAYROLL_EMAIL_PARAMETERS_RETRIEVE_UPDATE  = "ticketing-system/retrieve-update-payroll-email-parameters/%COMPANY_ID%/%MILESTONE_TYPE%/"
export const EMAIL_SEND = "ticketing-system/send-email/%EMAIL_ID%/"
export const PAYROLL_EMAIL_ATTACHMENT_CREATE = "files/payroll-email-attachment-upload/"
export const PAYROLL_EMAIL_ATTACHMENT_LIST_RETRIEVE = "ticketing-system/get-payroll-email-attachments/%EMAIL_ID%/"
export const PAYROLL_EMAIL_ATTACHMENT_REMOVE = "ticketing-system/remove-payroll-email-attachment/%FILE_ID%/"

// Links
export const MILESTONE_LINKS_CREATE_RETRIEVE = "ticketing-system/create-retrieve-links-list/%COMPANY_ID%"
export const MILESTONE_LINKS_RETRIEVE = "ticketing-system/create-retrieve-links-list/%COMPANY_ID%?email_id=%EMAIL_ID%"
export const MONTH_END_LINKS_CREATE_RETRIEVE = "ticketing-system/create-retrieve-month_end-links-list/%COMPANY_ID%/"
export const MILESTONE_LINK_EDIT_DELETE = "ticketing-system/retrieve-update-milestone-link/%LINK_ID%"
export const MONTH_END_LINK_EDIT_DELETE = "ticketing-system/retrieve-update-month_end-link/%LINK_ID%"
export const SUBTASK_LINKS_CREATE_RETRIEVE = "ticketing-system/create-retrieve-subtask-links-list/%SUBTASK_GID%/"
export const SUBTASK_LINK_EDIT_DELETE = "ticketing-system/retrieve-update-subtask-link/%LINK_ID%/"
export const SHAREPOINTFILEINFO = "retrieve-edit-delete-sharepoint-file-info/%SHAREPOINTFILE_ID%"
export const MILESTONE_LINK_PINNING = "ticketing-system/pinning-ticketing-system-link/%COMPANY_ID%/%LINK_ID%"

// TICKETING SYSTEM COMMENTS
export const TICKETING_SYSTEM_CELL_COMMENTS_LIST_RETRIEVE  = "ticketing-system/retrieve-ticketing-system-comments/%MILESTONE_ID%"
export const TICKETING_SYSTEM_CELL_COMMENTS_RETRIEVE_UPDATE_DELETE  = "ticketing-system/retrieve-update-ticketing-system-cell-comments/%CELL_COMMENT_ID%/"
export const TICKETING_SYSTEM_CELL_COMMENTS_CREATE = "ticketing-system/create-ticketing-system-cell-comment"

// Company Images
export const RETRIEVE_ALL_COMPANY_ICONS = "ticketing-system/retrieve-all-company-icons/"


