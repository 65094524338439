import { useContext, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Autocomplete, Box, createTheme, Paper, TextField, ThemeProvider } from "@mui/material";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import CustomTooltip from "../Tooltip";
import { TaskCardContext } from "../../../contexts/Task/TaskCardContext";
import { TaskTableContext } from "../../../contexts/Task/TaskTableContext";
import { TaskServicesContext } from "../../../contexts/Task/TaskServicesContext";
import { getInitials } from "../../../utils/helpers";
import "./style.css";

export default function AssigneeMenu({ task, setTask, size, optionsSize }) {

  const fontColor = "#212134";

  const theme = createTheme({
    components: {
      // Name of the component
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            padding: "0rem",
            minWidth: "0rem",
            borderRadius: "50%",
            width: `${size}px`,
            height: `${size}px`,
            border: "1px solid var(--Neutral200)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: `${fontColor} !important`,
            alignItems: "center !important",
            gap: "1rem",
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            border: "none",
          },
        },
      },
    },
  });

  const { team } = useContext(TaskServicesContext);

  const onAssigneeChange = (newValue) => {
    // the new value arrives as label, photo, value

    const newItem = { ...task };
    if (newValue.photo) {
      newItem["assignee"] = { gid: newValue.value, name: newValue.label, photo: newValue.photo };
    } else {
      newItem["assignee"] = { gid: newValue.value, name: newValue.label };
    }

    setTask(newItem);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <Button
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        id="assignee-btn"
        className={`btn--tertiary btn--M--centered ${task?.assignee?.name ? "show success" : "hide success"} AssigneeContainer__${size}`}
      >
        {task?.assignee?.name ? (
          <CustomTooltip title={task?.assignee?.name}>
            {task?.assignee?.photo?.[`image_${size}x${size}`] ? (
              <img className="AsanaPhoto" src={task?.assignee?.photo?.[`image_${size}x${size}`]} alt="" />
            ) : (
              <div className="user__circle" style={{height:Number(size), width:Number(size)}}>
                <div className="user__circle_inner">{getInitials(task.assignee.name)}</div>
              </div>
            )}
          </CustomTooltip>
        ) : (
          // <PersonRoundedIcon />
          <PersonRoundedIcon />
        )}
      </Button>
      {/* <Paper> */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "assignee-menu",
        }}
      >
        <Autocomplete
          id="assignee-input-label"
          sx={{ width: 300 }}
          options={team}
          autoHighlight
          onSelect={(e) => {
            // handleClose();
          }}
          onChange={(event: any, newValue: string | null) => {
            handleClose();
            onAssigneeChange(newValue);
          }}
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {option.photo?.[`image_${optionsSize}x${optionsSize}`] ? (
                <img className="AsanaPhoto" loading="lazy" width={optionsSize} src={option.photo?.[`image_${optionsSize}x${optionsSize}`]} alt="" />
              ) : (
                <div className="user__circle" style={{height:optionsSize, width:optionsSize}}>
                  <div className="user__circle_inner">{getInitials(option?.label)}</div>
                </div>
              )}
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              value={task?.assignee?.name ? task?.assignee?.name : ""}
              {...params}
              label="Choose an assignee"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />
      </Menu>
      {/* </Paper> */}
    </ThemeProvider>
  );
}
