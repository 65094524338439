import { createContext, useContext, useEffect, useState } from "react";
import { getAccessToken, RefreshAsana } from "../../APIFunctions/Asana";
import AsanaAPIManger from "../../APIManger/Asana";
import { AccessToken } from "../../types/AsanaTask";
import { AuthContext } from "../AuthContext";

export interface TaskAuthContextProps {
  setLoading: (loading: boolean) => void;
  loading: boolean;
  onRefreshAsana: () => void;
  accessToken: AccessToken;
  fetchAccessToken: () => Promise<boolean>;
}

export const TaskAuthContext = createContext<TaskAuthContextProps>(null as any);

export const TaskAuthContextProvider = (props) => {
  const [accessToken, setAccessToken] = useState<AccessToken>(AccessToken.getLocalStorageObj());
  const [loading, setLoading] = useState<boolean>(false);

  if (accessToken.copyingErrorHappen) {
    accessToken.setLocalObject();
  }

  const fetchAccessToken = async () => {
    const result = await getAccessToken();
    if (result.asana_access_token) {
      const newAccessToken: AccessToken = new AccessToken(result);
      newAccessToken.setLocalObject();
      setAccessToken(newAccessToken);
      AsanaAPIManger.token = result.asana_access_token;
      return true;
    }
    // {asana_access_token:"",localStorageKey:"AsanaAccessToken",copyingErrorHappen:false}
    setAccessToken(new AccessToken())
    new AccessToken().setLocalObject();
    return false;
  };

  const onRefreshAsana = async () => {
    const result = await RefreshAsana();
    if ("asana_access_token" in result) {
      const newAccessToken: AccessToken = new AccessToken(result);
      newAccessToken.setLocalObject();
      setAccessToken(newAccessToken);
      AsanaAPIManger.token = result.asana_access_token;
    }
    
    if ("error" in result){
      const newAccessToken: AccessToken = new AccessToken();
      newAccessToken.setLocalObject();
      setAccessToken(newAccessToken);
      AsanaAPIManger.token = "";
    }
  };
  AsanaAPIManger.refreshToken = onRefreshAsana;

  useEffect(() => {
    fetchAccessToken();
  }, []);

  return (
    <TaskAuthContext.Provider
      value={{
        loading,
        setLoading,
        onRefreshAsana,
        accessToken,
        fetchAccessToken,
      }}
    >
      {props.children}
    </TaskAuthContext.Provider>
  );
};