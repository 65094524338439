import { useState, useContext } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './modal.css';
import { ReportFilterContext } from '../../../contexts/HeadCount/ReportFilterContext';
import { DataConext } from '../../../contexts/HeadCount/DataConext';
import { Collapse } from "@mui/material";
import { EmployeeListConext } from '../../../contexts/HeadCount/EmployeeListConext';
import SelectComponent from '../../Common/SelectComponent';
import { LoaderStatus } from '../../../types/Loaders';
import { AuthContext } from '../../../contexts/AuthContext';
import { CompanyFilters } from '../../../types/CompanyFilters';
import { uppercaseWords } from '../../../utils/helpers';


const HeadCountFilters = () => {
    const { companyFilter } = useContext(AuthContext);
    const { hc_LoaderStatus } = useContext(DataConext);
    const { isModalOpen, tabIndex } = useContext(EmployeeListConext);
    const { selectedFilters, setSelectedFilters } = useContext(ReportFilterContext);
    const [dateError, setDateError] = useState(false);

    function onDateChange(minDate: Date, maxDate: Date) {
        if (minDate < maxDate) {
            setSelectedFilters(selectedFilters.updateDate(minDate, maxDate))
        }
        else {
            setDateError(true)
        }
    }

    function onMultiSelectChange(selectedValues, action) {
        setSelectedFilters(selectedFilters.updateSelectedOptions(action.name, selectedValues))
    }


    if (!companyFilter) return null;
    if (tabIndex === 0) return null;
    return (
        <Collapse
            in={isModalOpen}
            className={isModalOpen ? 'collapse-open' : 'collapse-closed'}
            classes={{ wrapperInner: 'compare_wrapper_inner' }}
            sx={{
                '& .MuiCollapse-wrapper': {
                    margin: 0,
                    marginBottom: 0,
                    padding: 3,
                    paddingBottom: "20px",
                    bgcolor: '#FFFFFF',
                    height: "auto",
                    paddingLeft: "30px"
                }
            }}
        >
            {
                <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#FFFFFF', height: 'auto', flexWrap: 'wrap', alignItems: 'center' }}>
                    <div className="dateEntryContainer">
                        <label className="label">Start Date</label>
                        <DatePicker
                            selected={selectedFilters.startDate}
                            onChange={(date: Date) => {
                                if (date) {
                                    let minDate = new Date(date.getFullYear(), date.getMonth(), 1)
                                    let maxDate = new Date(selectedFilters.endDate.getFullYear(), selectedFilters.endDate.getMonth() + 1, 0)
                                    onDateChange(minDate, maxDate)

                                }
                            }}
                            maxDate={selectedFilters.endDate}
                            dateFormat="MMMM yyyy"
                            showMonthYearPicker
                            showFullMonthYearPicker

                        />
                        {dateError &&
                            <label style={{ color: 'red' }} >Start date should come before the end date </label>
                        }
                    </div>
                    <div className="dateEntryContainer">
                        <label className="label">End Date</label>
                        <DatePicker
                            selected={selectedFilters.endDate}
                            onChange={(date: Date) => {
                                if (date) {
                                    let minDate = new Date(selectedFilters.startDate.getFullYear(), selectedFilters.startDate.getMonth(), 1)
                                    let maxDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)
                                    onDateChange(minDate, maxDate)
                                }
                            }}
                            minDate={selectedFilters.startDate}
                            dateFormat="MMMM yyyy"
                            showMonthYearPicker
                            showFullMonthYearPicker
                        />
                    </div>
                    {CompanyFilters.allfilters.map(filterKey =>

                        <div key={filterKey} className="dateEntryContainer">
                            <label className="label">{uppercaseWords(filterKey)}</label>
                            <SelectComponent
                                name={filterKey}
                                options={companyFilter.lookUpOption(filterKey,false)}
                                onChange={onMultiSelectChange}
                                value={selectedFilters.filterSelectedOptions(filterKey)}
                                isMulti
                                allowSelectAll
                                isLoading={hc_LoaderStatus !== LoaderStatus.Rendered}
                            />
                        </div>
                    )}
                </div>
            }
        </Collapse>
    )
}

export default HeadCountFilters;