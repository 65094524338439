import React, { useContext, useEffect, useState } from "react";
import { asanaTaskDateStringToDate, dateObjToAppString } from "../../../../../utils/DateFunctions";
import { PayrollContext } from "../../../../../contexts/TicketingSystem/PayrollContext";
import { getPayrollPlatformLink } from "../../../../../APIFunctions/ticketing_system";
import { payrollPlatform } from "../../../../../types/TicketingSystem";

const PayrollTable = () => {
  const [payrollPlatformLink, setPayrollPlatformLink] = useState<string>("");

  const { platform,location, cycleEndDate, cycleStartDate } = useContext(PayrollContext)

  const getPlatformLink = async () => {
    if (platform) {
      const response = await getPayrollPlatformLink(Number(payrollPlatform[platform]));
      if (response) {
        setPayrollPlatformLink(response.platform_link);
      } else {
        setPayrollPlatformLink("");
      }
    }
  };

  useEffect(() => {
    // discuss with Mihail how to not run this function everytime.
    getPlatformLink()
  },[platform])

  return (
    <div className="ClientPayrollCard__Container__Table__Container">
      <table>
        <tbody>
          <tr>
            <td className="left">
              <div className="table__cell">
                <div className="table__cell__label">Platform</div>
                <div className="table__cell__value">{platform ? <a href={payrollPlatformLink} rel="noreferrer" target="_blank">{platform}</a> : "--"}</div>
              </div>
            </td>
            <td>
              <div className="table__cell">
                <div className="table__cell__label">Location</div>
                <div className="table__cell__value">{location ? location : "--"}</div>
              </div>
            </td>
          </tr>
          <tr>
            <td className="left">
              <div className="table__cell">
                <div className="table__cell__label">Start Date</div>
                <div className="table__cell__value">{cycleStartDate ? dateObjToAppString(asanaTaskDateStringToDate(cycleStartDate),"/") : "--"}</div>
              </div>
            </td>
            <td>
              <div className="table__cell">
                <div className="table__cell__label">End Date</div>
                <div className="table__cell__value">{cycleEndDate ? dateObjToAppString(asanaTaskDateStringToDate(cycleEndDate),"/") : "--"}</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PayrollTable;
