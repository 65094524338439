import React, { useEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-mention/dist/quill.mention.css";
import "quill-mention";

export const convertQuillMentionsToAsana = (quillHTMLText) => {
  const quillMentionStartText = '<span class=\"mention\"'
  const quillMentionEndText = '</a></span>﻿</span>'
  const quillMentionStart = quillHTMLText.indexOf(quillMentionStartText)
  if (quillMentionStart === -1) return quillHTMLText
  const quillMentionEnd = quillHTMLText.indexOf(quillMentionEndText) + quillMentionEndText.length
  const quillMentionText = quillHTMLText.substring(quillMentionStart,quillMentionEnd )
  const idStartText = 'data-id=\"'
  const idEndText = '\" data-link'
  const idStartTextStart = quillMentionText.indexOf(idStartText) + idStartText.length
  const idEndTextEnd = quillMentionText.indexOf(idEndText)
  const id = quillMentionText.substring(idStartTextStart,idEndTextEnd)
  const asanaHTMLText = quillHTMLText.replace(quillMentionText, `<a data-asana-gid=\"${id}\"/>`)
  if (asanaHTMLText.indexOf(quillMentionStartText) !== -1 ) {
    return convertQuillMentionsToAsana(asanaHTMLText)
  }
  return asanaHTMLText
}

export const convertQuillHTMLToAsana = (QuillHTML) => {
  return QuillHTML ? "<body>" + QuillHTML.replaceAll("<p>","").replaceAll("</p>","\n").replaceAll("<br>","").replaceAll("<br/>","") + "</body>" : "<body></body>"
}

//Add https to link if https is not present
const Link = Quill.import("formats/link");
Link.sanitize = function (url) {
  // quill by default creates relative links if scheme is missing.
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    return `http://${url}`;
  }
  return url;
};
Quill.register(Link, true);

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = ["arial", "comic-sans", "courier-new", "georgia", "helvetica", "lucida"];
Quill.register(Font, true);

let atValues = []

const mention = {
  allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
  mentionDenotationChars: ['@', '#'],
  source: function (searchTerm: any, renderList: any, mentionChar: any) {
    let values:any;

    if (mentionChar === '@') {
      values = atValues;
    } 

    if (searchTerm.length === 0) {
      renderList(values, searchTerm);
    } else {
      const matches:any = [];
      for (let i = 0; i < values.length; i++)
        if (
          ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
        )
          matches.push(values[i]);
      renderList(matches, searchTerm);
    }
  }
  // ,options: {onSelect: ((item, insertItem) => {
  //   insertItem(item.link);}
  // )}
  
}

const modules = {
  toolbar: [["bold", "underline"], [{ list: "ordered" }, { list: "bullet" }], ["link"]],
  mention,
};

const formats = ["bold", "underline", "list", "bullet", "indent", "link", "mention"];

const RichTextEditor = ({ htmlValue, setHTMLValue, onChange, people, placeholder }) => {
  const reactQuillRef = useRef<ReactQuill>(null);
  // const [] = useState<string>(initialText)

  const handleProcedureContentChange = (content, delta, source, editor) => {
    setHTMLValue(content);
    onChange(content);
  };

  // function showMenu() {
  //   reactQuillRef.current?.getModule("mention").openMenu("@");
  // }


  useEffect(() => {
    // if (props.value) {
    //   setEditorHtml(props.value);
    // } else {
    //   setEditorHtml("");
    // }
    if (people) {
      let peoples = people.map((x) => {
        return { ...x, value: x["label"], id:x["value"], link:`https://app.asana.com/0/${x["value"]}/>` };
      });
      atValues = peoples;
    }
  }, [htmlValue]);


  return <ReactQuill placeholder={placeholder} ref={reactQuillRef} theme="snow" modules={modules} formats={formats} value={htmlValue} onChange={handleProcedureContentChange} />;
};

export default RichTextEditor;
