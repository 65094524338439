import React, { useContext, useEffect, useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { createAsanaSubTask, retrieveAsanaSubTask, updateAsanaTask } from "../../../APIFunctions/Asana";
import { MilestoneContext } from "../../../contexts/TicketingSystem/MilestoneContext";
import SubTaskAcceptReject from "./SubTaskAcceptReject";
import { getMonthEndTaskType, MonthEndTaskType } from "../../Tasks/utils";
import { SubTasksContext } from "../../../contexts/TicketingSystem/SubTasksContext";
import MDIcons from "../../Common/MDIcons";
import CustomTooltip from "../../Common/Tooltip";
import { stringToSlugConverter } from "../../../utils/helpers";
import { countIncompleteSubtasks } from "../helpers";
import AlertDialog from "../../Common/AlertDialog";
import DeleteIcon from "@mui/icons-material/Delete";

const MilestoneTaskLine = ({ milestoneTask, openMilestoneTask, openMilestoneTaskComponent, type }) => {
  const { setPayrollLoading, isUserAssignedCurrentStage, currentStage, prevStage, updateMilestoneTasksCompletionArray, milestoneTasksCompletionArray } =
    useContext(MilestoneContext);
  const { subTasksDict, updatePreparationStageTasks } = useContext(SubTasksContext);
  // const [completed, setCompleted] = useState<boolean>(milestoneTask?.completed);
  const [reviewTask, setReviewTask] = useState<any>();
  const [previousReviewTask, setPreviousReviewTask] = useState<any>();
  const [incompleteSubtasksCount, setIncompleteSubtasksCount] = useState<number | null>(null);

  const getReviewSubTask = async () => {
    // get the review milestoneTask inside
    let reviewTask;
    // refactor: we should have a list of all the names of tasks that are 'review' type.
    if (getMonthEndTaskType(currentStage?.name) === MonthEndTaskType.MANAGER_REVIEW || getMonthEndTaskType(currentStage?.name) === MonthEndTaskType.FINAL_REVIEW) {
      setPayrollLoading(true);
      const response = await retrieveAsanaSubTask(milestoneTask?.gid);
      if (response?.data) {
        reviewTask = response.data.find((x) => x.name === currentStage?.name);
        if (!reviewTask) {
          // if the review task doesn't exist then create it.
          const newSubTaskObj = {
            data: { name: currentStage?.name },
          };
          reviewTask = await createAsanaSubTask(milestoneTask?.gid, newSubTaskObj);
        }
      }
      setPayrollLoading(false);
    }
    setReviewTask(reviewTask);
  };

  const getPreviousReviewSubTask = async () => {
    // get the review milestoneTask inside
    let reviewTask;
    // refactor: we should have a list of all the names of tasks that are 'review' type.
    if (getMonthEndTaskType(prevStage?.name) === MonthEndTaskType.MANAGER_REVIEW || getMonthEndTaskType(prevStage?.name) === MonthEndTaskType.FINAL_REVIEW) {
      setPayrollLoading(true);
      const response = await retrieveAsanaSubTask(milestoneTask?.gid);
      if (response?.data) {
        reviewTask = response.data.find((x) => x.name === prevStage?.name);
        if (!reviewTask) {
          // if the review task doesn't exist then create it.
          const newSubTaskObj = {
            data: { name: prevStage?.name },
          };
          reviewTask = await createAsanaSubTask(milestoneTask?.gid, newSubTaskObj);
        }
      }
      setPayrollLoading(false);
    }
    setPreviousReviewTask(reviewTask);
  };

  const verifyCompleteness = () => {
    if (completed || (!completed && incompleteSubtasksCount === 0)) {
      onComplete()
    } else {
    setIncompleteSubtasksCount(countIncompleteSubtasks(subTasksDict?.[milestoneTask.gid]));
    }
  };

  const onComplete = () => {
    const newItem = { ...milestoneTask };
    newItem.completed = !milestoneTask.completed;
    milestoneTask.completed = newItem.completed;
    updateAsanaTask({
      gid: milestoneTask.gid,
      completed: newItem.completed,
    });
    // setCompleted(newItem.completed);
    updatePreparationStageTasks(newItem);
    updateMilestoneTasksCompletionArray(newItem.completed, milestoneTask.gid);
  };

  const onOpen = () => {
    openMilestoneTask(milestoneTask["gid"]);
  };

  const onOpenComponent = () => {
    openMilestoneTaskComponent(milestoneTask);
  };

  const onCloseConfirmIncompleteSubtasksDialog = () => {
    setIncompleteSubtasksCount(null);
  };

  const completed = milestoneTasksCompletionArray.find((x) => x.gid === milestoneTask.gid)?.completed;

  useEffect(() => {
    getReviewSubTask();
    getPreviousReviewSubTask();
  }, [subTasksDict]);

  return subTasksDict?.[milestoneTask.gid] ? (
    <>
      <div className="MilestoneCard__SubTasks__SubTaskContainer">
        {!completed && type === "Preparation" ? (
          <div className="MilestoneCard__SubTasks__SubTaskContainer__subTaskName">
            <CheckCircleOutlineIcon style={{ color: "#A5A5BA", marginRight: "0.7rem" }} onClick={verifyCompleteness} />
            <div id={milestoneTask.name} onClick={onOpen}>
              {milestoneTask.name}
            </div>
          </div>
        ) : (
          completed &&
          type === "Preparation" && (
            <div className="MilestoneCard__SubTasks__SubTaskContainer__subTaskName">
              <CheckCircleRoundedIcon style={{ color: "#5CB176", marginRight: "0.7rem" }} onClick={verifyCompleteness} />
              <div id={milestoneTask.name} onClick={onOpen}>
                {milestoneTask.name}
              </div>
            </div>
          )
        )}
        {type === "Review" && (
          <>
            <div className="MilestoneCard__SubTasks__SubTaskContainer__subTaskName" onClick={onOpen}>
              {milestoneTask.name}
            </div>
            <SubTaskAcceptReject
              reviewTask={reviewTask}
              reviewTaskCompletion={reviewTask?.["completed"]}
              setReviewTask={setReviewTask}
              previousReviewTask={previousReviewTask}
              setPreviousReviewTask={setPreviousReviewTask}
              rejectTaskGid={milestoneTask?.gid}
              milestoneTaskName={milestoneTask?.name}
              milestoneTaskGID={milestoneTask?.gid}
              openMilestoneTask={openMilestoneTask}
            />
          </>
        )}
        <div
          onClick={onOpen}
          id={`${stringToSlugConverter(milestoneTask.name)}-subtasks-btn`}
          className="MilestoneTaskLine__SubTaskCount__Container no-borders-no-background success"
        >
          <span>{subTasksDict?.[milestoneTask.gid]?.length}</span>
          <MDIcons title="subtask__icon" />
        </div>
        <div id={`${stringToSlugConverter(milestoneTask.name)}-component-btn`} className="no-borders-no-background success" onClick={onOpenComponent}>
          <MDIcons title="open_component" />
        </div>
      </div>

      <AlertDialog
        open={incompleteSubtasksCount !== null && incompleteSubtasksCount > 0}
        onClose={onCloseConfirmIncompleteSubtasksDialog}
        title={`This section has ${incompleteSubtasksCount} tasks incomplete. Are you sure you want to mark them all as completed?`}
        dialogType="yes_or_no"
        dialogActions={
          <>
            <button onClick={onCloseConfirmIncompleteSubtasksDialog} className="btn--tertiary btn--XS btn--80Width">
              Cancel
            </button>
            <button
              onClick={() => {
                onComplete();
                onCloseConfirmIncompleteSubtasksDialog();
              }}
              className="btn--secondary--completed btn--XS btn--80Width"
              autoFocus
              style={{ marginLeft: "0rem" }}
            >
              <MDIcons title="check_mark_for_approval"/>
              Complete
            </button>
          </>
        }
      />
    </>
  ) : (
    <></>
  );
};

export default MilestoneTaskLine;
