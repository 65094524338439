import React, { useContext, useEffect, useState } from "react";
import { TaskAuthContextProvider } from "../../contexts/Task/TaskAuthContext";
import { connectToAsana } from "../../APIFunctions/Asana";
import { TaskAuthContext } from "../../contexts/Task/TaskAuthContext";
import TasksList from "./TasksTable";
import { ReactComponent as ConnectImage } from "../../static/img/Asana/connect-to-asana.svg";
import { ReactComponent as AsanaLogo } from "../../static/img/Asana/asana-logo.svg";
import { useInterval } from "../../hooks";
import { AuthContext } from "../../contexts/AuthContext";
import NoDataFound from "../Common/NoDataFound";
import { TaskTableContext, TaskTableContextProvider } from "../../contexts/Task/TaskTableContext";
import TaskServices from "./TasksServices";
import { TaskServicesContextProvider } from "../../contexts/Task/TaskServicesContext";
import { Company } from "../../types/Company";
import { useMatch, useNavigate, useParams } from "react-router-dom";

function TasksEntryPoint() {
  const { selectedCompany, setSelectedCompany, userProfile } = useContext(AuthContext);
  const { accessToken, fetchAccessToken } = useContext(TaskAuthContext);
  const [attemptGetAccessToken, setAttemptGetAccessToken] = useState(false);
  const [projectID, setProjectID] = useState<string | undefined>();
  const [taskCategory, setTaskCategory] = useState<string | undefined>();
  // let tasks_category:string|any = ""
  const navigate = useNavigate();

  

  let { projectType } = useParams();
  console.log("projectType",projectType)

  const projectMapping = {
    payroll: "1203644742427201",
    user: selectedCompany?.asana_project_id,
    "month-end-close": "1203644742427157",
    "accounts-payable": "1203824445866269",
  };

  useInterval(
    async () => {
      if (attemptGetAccessToken) {
        const tokenRetrieved = await fetchAccessToken();
        if (tokenRetrieved) setAttemptGetAccessToken(false);
      }
    },
    3500,
    attemptGetAccessToken
  );

  const connectAsana = async () => {
    const consentURL = await connectToAsana();
    window.open(consentURL, "", "width=800,height=800,top=100,left=500");
    setAttemptGetAccessToken(true);
  };

  useEffect(() => {
    if (projectType) {
      setProjectID(projectMapping[projectType]);
    } else {
      setProjectID(projectMapping.user);
    }
  }, [selectedCompany, projectType]);

  useEffect(() => {
    if (projectType !== "") {
      setTaskCategory(projectType);
    } else {
      setTaskCategory("user");
      setProjectID(projectMapping.user);
    }
  }, [projectType]);

  return (
    <>
      {projectID !== "" && projectID !== undefined ? (
        accessToken.asana_access_token === "" ? (
          <div className="AsanaConnectContainer">
            <div className="ImageContainer">
              <ConnectImage />
              <AsanaLogo className="asana-logo" />
            </div>

            <h2>Start by connecting to Asana</h2>
            <span>This will allow us to connect your asana account to our mighty digits app!</span>
            <button className="btn--primary btn--M" onClick={connectAsana}>
              Connect
            </button>
          </div>
        ) : (
          <TaskServicesContextProvider projectID={!projectID ? projectMapping["user"] : projectID} tasks_category={taskCategory}>
            <TaskServices />
          </TaskServicesContextProvider>
        )
      ) : (
        <NoDataFound />
      )}
    </>
  );
}

export default function TasksModule() {
  return (
    <TaskAuthContextProvider>
      <TasksEntryPoint />
    </TaskAuthContextProvider>
  );
}

export function TasksModulePayroll() {
  return (
    <TaskAuthContextProvider>
      <TasksEntryPoint />
    </TaskAuthContextProvider>
  );
}
