import { useContext, useEffect } from 'react';
import { AuthContext } from './contexts/AuthContext';
import AuthRouter from './Routers/AuthRouter';
import AppContainer from './AppContainer';
import { useLocation } from 'react-router-dom';

export default function EntryPoint() {
    
    const location = useLocation();
    const { isLoggedin, isProfileCompleted, setTargetURL } = useContext(AuthContext)

    useEffect(() => {
        setTargetURL(location.pathname)
    },[])

    return (
        <>
            {(isLoggedin() && isProfileCompleted()) ? <AppContainer /> : <AuthRouter />}
        </>
    )
}