import React from "react";
import Skeleton from "@mui/material/Skeleton";

export default function DashboardCardsLoading() {
  return (
    <div className="dashboard-cards-container" style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr" }}>
      <Skeleton variant="rectangular" width="95%" height={550} sx={{ margin: 1.25, borderRadius: 1.25 }} />
    </div>
  );
}
