import React, { useContext } from 'react'
import { OnboardingContext } from '../../contexts/Onboarding/OnboardingContext';
import FormPage1 from './FormPage1';
import FormPage2 from './FormPage2';
import FormPage3 from './FormPage3';
import FormPage4 from './FormPage4';
import FormPage5 from './FormPage5';



const FormRenderer = () => {

    const { selectedForm } = useContext(OnboardingContext);

  return (
    <div>
        {selectedForm === 0 && <FormPage1 />}
        {selectedForm === 1 && <FormPage2 />}
        {selectedForm === 2 && <FormPage3 />}
        {selectedForm === 3 && <FormPage4 />}
        {selectedForm === 4 && <FormPage5 />}

    </div>
  )
}

export default FormRenderer