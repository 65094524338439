import React, { useContext, useEffect, useMemo, useState } from "react";
import InputContainer from "./InputContainer";
import { AuthContext } from "../../contexts/AuthContext";
import {
  asanaTaskDateStringToDate,
  createDateEndOfLastMonth,
  createDateWithOffsetFromDate,
  dateObjToAsanaString,
  endOfMonth,
  getNumberOfDaysBetweenTwoDays,
  startOfMonth,
} from "../../utils/DateFunctions";
import "./style.css";
import SharepointExplorer from "../Common/SharepointExplorer/SharepointExplorer";
import { exportReportToExcel, getLastQBOExportTime } from "../../APIFunctions/data_modeling";
import QBOFooter from "./Footer";
import { checked } from "./MonthEndQBOContainer";
import { allChecked, indeterminate, reportTypeNameDict } from "./utils";
import { Checkbox, Divider, FormControlLabel } from "@mui/material";
import RadioButtonsGroup from "../Common/RadioGroupComponent";
import { OptionTypeBase } from "react-select";

interface InputContainerProps {
  title: string;
  startDate: string;
  setStartDate: (date: string) => void;
  endDate: string;
  setEndDate: (date: string) => void;
  checked: boolean;
}

export const FormatOptions = [
  { value: "Pivoted", label: "Pivoted" },
  { value: "Unpivoted", label: "Unpivoted" },
];

export const CreationOptions = [
  { value: "Upload To Sharepoint", label: "Upload To Sharepoint" },
  { value: "Download File", label: "Download File" },
];

const ExportQBOContainer = ({ setSaveFolder, saveFolder }) => {
  const { selectedCompany } = useContext(AuthContext);
  const [displaySharepointBrowser, setDisplaySharepointBrowser] = useState<boolean>(false);
  const [exportFormat, setExportFormat] = useState<string>(FormatOptions[0].value);
  const [creationMethod, setCreationMethod] = useState<string>(CreationOptions[0].value);
  const [exportClasses, setExportClasses] = useState<boolean>(false);

  const [lastExportTime, setLastExportTime] = useState<any>();
  const [download] = useState<boolean>(false);
  const startDateInitial = startOfMonth(new Date(new Date().getFullYear(), 0, 1));
  const endDateInitial = endOfMonth(createDateEndOfLastMonth());

  const initialDateRanges = {
    incomeStatement: { 
      startDate: dateObjToAsanaString(startDateInitial), 
      endDate: dateObjToAsanaString(createDateEndOfLastMonth())
    },
    incomeStatementDetail: { 
      startDate: dateObjToAsanaString(startDateInitial), 
      endDate: dateObjToAsanaString(createDateEndOfLastMonth())
    },
    balanceSheetDetail: { 
      startDate: dateObjToAsanaString(startDateInitial), 
      endDate: dateObjToAsanaString(createDateEndOfLastMonth())
    },
    balanceSheet: { 
      startDate: dateObjToAsanaString(startDateInitial), 
      endDate: dateObjToAsanaString(createDateEndOfLastMonth())
    },
    cashFlows: { 
      startDate: dateObjToAsanaString(startDateInitial), 
      endDate: dateObjToAsanaString(createDateEndOfLastMonth())
    }
  };

  const [dateRanges, setDateRanges] = useState(initialDateRanges);

  const setIncomeStatementStartDate = (date: string) => {
    setDateRanges(prevState => ({
      ...prevState,
      incomeStatement: {
        ...prevState.incomeStatement,
        startDate: date
      }
    }));
  };
  
  const setIncomeStatementEndDate = (date: string) => {
    setDateRanges(prevState => ({
      ...prevState,
      incomeStatement: {
        ...prevState.incomeStatement,
        endDate: date
      }
    }));
  };
  const setIncomeStatementDetailStartDate = (date: string) => {
    setDateRanges(prevState => ({
      ...prevState,
      incomeStatementDetail: {
        ...prevState.incomeStatementDetail,
        startDate: date
      }
    }));
  };
  
  const setIncomeStatementDetailEndDate = (date: string) => {
    setDateRanges(prevState => ({
      ...prevState,
      incomeStatementDetail: {
        ...prevState.incomeStatementDetail,
        endDate: date
      }
    }));
  };
  
  const setBalanceSheetStartDate = (date: string) => {
    setDateRanges(prevState => ({
      ...prevState,
      balanceSheet: {
        ...prevState.balanceSheet,
        startDate: date
      }
    }));
  };
  
  const setBalanceSheetEndDate = (date: string) => {
    setDateRanges(prevState => ({
      ...prevState,
      balanceSheet: {
        ...prevState.balanceSheet,
        endDate: date
      }
    }));
  };
  
  const setBalanceSheetDetailStartDate = (date: string) => {
    setDateRanges(prevState => ({
      ...prevState,
      balanceSheetDetail: {
        ...prevState.balanceSheetDetail,
        startDate: date
      }
    }));
  };
  
  const setBalanceSheetDetailEndDate = (date: string) => {
    setDateRanges(prevState => ({
      ...prevState,
      balanceSheetDetail: {
        ...prevState.balanceSheetDetail,
        endDate: date
      }
    }));
  };

  const setCashFlowsStartDate = (date: string) => {
    setDateRanges(prevState => ({
      ...prevState,
      cashFlows: {
        ...prevState.cashFlows,
        startDate: date
      }
    }));
  };
  
  const setCashFlowsEndDate = (date: string) => {
    setDateRanges(prevState => ({
      ...prevState,
      cashFlows: {
        ...prevState.cashFlows,
        endDate: date
      }
    }));
  };
  

  const onCloseBrowser = () => {
    setDisplaySharepointBrowser(false);
  };

  const initialCheckedState: checked = {
    PL: true,
    PLDetail: true,
    BS: true,
    BSDetail: true,
    CF: true,
  };

  const [checked, setChecked] = useState<checked>(initialCheckedState);

  const setAllChecked = (checked) => {
    setChecked({
      PL: checked,
      PLDetail: checked,
      BS: checked,
      BSDetail: checked,
      CF: checked,
    });
  };

  const inputContainers = useMemo(() => {
    return [
      {
        title: "Income Statement",
        startDate: dateRanges.incomeStatement.startDate,
        setStartDate: setIncomeStatementStartDate,
        endDate: dateRanges.incomeStatement.endDate,
        setEndDate: setIncomeStatementEndDate,
        checked: checked.PL,
      },
      {
        title: "Income Statement Detail",
        startDate: dateRanges.incomeStatementDetail.startDate,
        setStartDate: setIncomeStatementDetailStartDate,
        endDate: dateRanges.incomeStatementDetail.endDate,
        setEndDate: setIncomeStatementDetailEndDate,
        checked: checked.PLDetail,
      },
      {
        title: "Balance Sheet",
        startDate: dateRanges.balanceSheet.startDate,
        setStartDate: setBalanceSheetStartDate,
        endDate: dateRanges.balanceSheet.endDate,
        setEndDate: setBalanceSheetEndDate,
        checked: checked.BS,
      },
      {
        title: "Balance Sheet Detail",
        startDate: dateRanges.balanceSheetDetail.startDate,
        setStartDate: setBalanceSheetDetailStartDate,
        endDate: dateRanges.balanceSheetDetail.endDate,
        setEndDate: setBalanceSheetDetailEndDate,
        checked: checked.BSDetail,
      },
      {
        title: "Cash Flow",
        startDate: dateRanges.cashFlows.startDate,
        setStartDate: setCashFlowsStartDate,
        endDate: dateRanges.cashFlows.endDate,
        setEndDate: setCashFlowsEndDate,
        checked: checked.CF,
      },
    ];
  }, [dateRanges, checked]);

  const handleChangeALL = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllChecked(event.target.checked);
  };

  const handleChangeChecked = (event: React.ChangeEvent<HTMLInputElement>, checkedReportName) => {
    setChecked({ ...checked, [reportTypeNameDict[checkedReportName]]: event.target.checked });
  };

  const handleChangeSelectedFormat = (selectedValue) => {
    setExportFormat(selectedValue);
  };
  const handleChangeSelectedCreationLocation = (selectedValue) => {
    setCreationMethod(selectedValue);
  };

  const handleChangeExportClasses = () => {
    setExportClasses((prev) => !prev);
  };

  const exportToExcel = async (saveFolder) => {
    if (selectedCompany) {
      const response = await exportReportToExcel(
        selectedCompany,
        exportFormat,
        [],
        `${selectedCompany?.companyName}_QBO_Export.xlsx`,
        saveFolder?.id,
        dateRanges.incomeStatement.startDate,
        dateRanges.incomeStatement.endDate,
        dateRanges.balanceSheet.startDate,
        dateRanges.balanceSheet.endDate,
        dateRanges.incomeStatementDetail.startDate,
        dateRanges.incomeStatementDetail.endDate,
        dateRanges.balanceSheetDetail.startDate,
        dateRanges.balanceSheetDetail.endDate,
        dateRanges.cashFlows.startDate,
        dateRanges.cashFlows.endDate,
        download,
        checked,
        exportClasses
      );
      return response;
    }
  };

  const getLastExportTime = async () => {
    if (selectedCompany) {
      const response = await getLastQBOExportTime(selectedCompany);
      if (response["last_export_time"]) {
        setLastExportTime(response["last_export_time"]);
      }
    }
  };

  useEffect(() => {
    getLastExportTime();
  }, []);

 // Helper function to set start dates
const setStartDates = (date: Date, offsetMonths: number) => {
  const offsetDate = dateObjToAsanaString(startOfMonth(createDateWithOffsetFromDate(date, 0, offsetMonths, 0)));
  setBalanceSheetStartDate(offsetDate);
  setIncomeStatementStartDate(offsetDate);
  setBalanceSheetDetailStartDate(offsetDate);
  setIncomeStatementDetailStartDate(offsetDate);
};

// Helper function to set end dates
const setEndDates = (date: string) => {
  setBalanceSheetEndDate(date);
  setIncomeStatementEndDate(date);
  setCashFlowsEndDate(date);
  setBalanceSheetDetailEndDate(date);
  setIncomeStatementDetailEndDate(date);
};

useEffect(() => {
  if (getNumberOfDaysBetweenTwoDays(startDateInitial, endDateInitial) < 180) {
    if (selectedCompany?.last_date_of_actuals) {
      const last_date_of_actuals = selectedCompany.last_date_of_actuals.split("T")[0];
      const lastDateAsDateObj = asanaTaskDateStringToDate(last_date_of_actuals);
      
      setStartDates(lastDateAsDateObj, -7);
      setEndDates(last_date_of_actuals);
    } else {
      const endOfLastMonth = createDateEndOfLastMonth();
      
      setStartDates(endOfLastMonth, -7);
      setEndDates(dateObjToAsanaString(endOfLastMonth));
    }
  }
}, [selectedCompany]);


  const ReportOptions = () => (
    <>
      <div>
        <h2>Report Options</h2>
      </div>
      <Divider />
      <div>
        <span>Report Type</span>
        <RadioButtonsGroup Title="exportFormat" selectedValue={exportFormat} onValueChange={handleChangeSelectedFormat} valuesList={FormatOptions} />
      </div>
  
      <Divider />
      <div>
        <span>Save report</span>
        <RadioButtonsGroup Title="creationMethod" selectedValue={creationMethod} onValueChange={handleChangeSelectedCreationLocation} valuesList={CreationOptions} />
      </div>
      <Divider />
      <div>
        <Checkbox checked={exportClasses} onChange={handleChangeExportClasses} data-test="exportClasses"/>
        <span>Include Classes</span>
      </div>
    </>
  );

  const CheckboxControl = ({ checked, handleChange }) => {
    const checkboxStyles = {
      padding: 0,
      color: "#C0C0CF",
      "&.Mui-checked": {
        color: "#85B440",
      },
      "&.MuiCheckbox-indeterminate": {
        color: "#85B440",
      },
    };
  
    return (
      <FormControlLabel
        label=""
        control={
          <Checkbox
            checked={allChecked(checked)}
            indeterminate={indeterminate(checked)}
            onChange={handleChange} 
            
          />
        }
        sx={checkboxStyles}
      />
    );
  };
  
  const HeaderLabel = ({ label }) => (
    <div className="ExportQBOToExcel__Container__Header__Label">{label}</div>
  );
  
  const InputContainerList = ({ containers, handleChangeChecked }) =>
    containers.map((container) => (
      <InputContainer
        title={container.title}
        startDate={container.startDate}
        setStartDate={container.setStartDate}
        endDate={container.endDate}
        setEndDate={container.setEndDate}
        checked={container.checked}
        handleChangeChecked={handleChangeChecked}
      />
    ));
  
  const ExportQBOToExcelContainer = () => (
    <div className="ExportQBOToExcel__Container">
      <div className="ExportQBOToExcel__Container__Grid">
        <div className="ExportQBOToExcel__Container__Header">
          <div className="ExportQBOToExcel__Container__Header__CheckAll">
            <CheckboxControl checked={checked} handleChange={handleChangeALL} />
          </div>
          <HeaderLabel label="Report Name" />
          <HeaderLabel label="Start Date" />
          <HeaderLabel label="End Date" />
        </div>
        <div className="ExportQBOToExcel__Container__InputContainer__List">
          <InputContainerList containers={inputContainers} handleChangeChecked={handleChangeChecked} />
        </div>
      </div>
      <QBOFooter creationMethod={creationMethod} exportToExcel={exportToExcel} setDisplaySharepointBrowser={setDisplaySharepointBrowser} />
    </div>
  );
  

  const SharepointBrowser = () => {
    if (displaySharepointBrowser) {
      return (
        <SharepointExplorer
          onCloseBrowser={onCloseBrowser}
          displaySharepointBrowser={displaySharepointBrowser}
          setSaveFolder={setSaveFolder}
          setDisplaySharepointBrowser={setDisplaySharepointBrowser}
          saveFolder={saveFolder}
          exportToExcel={exportToExcel}
        />
      );
    } else {
      return null;
    }
  };
  
  return (
    <>
      <div className="ExportQBOToExcel__Container__Parent" data-state-exportFormat={exportFormat} data-state-creationMethod={creationMethod} data-state-exportClasses={exportClasses}>
        <div className="ExportQBOToExcel__Container__Options">
          <ReportOptions />
        </div>
        <ExportQBOToExcelContainer />
        <SharepointBrowser />
      </div>
    </>
  );
};

export default ExportQBOContainer;
