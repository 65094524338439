import React from "react";
import { dateObjToAsanaString, endOfMonth } from "../../utils/DateFunctions";
import { dateFromString } from "../../utils/helpers";
import CustomDatePicker from "../Common/CustomDatePicker";
import { Checkbox, FormControlLabel } from "@mui/material";

const InputContainer = ({ title, startDate, setStartDate, endDate, setEndDate, checked, handleChangeChecked}) => {
  return (
    <div className="ExportQBOToExcel__Container__InputContainer">
      <FormControlLabel label="" control={<Checkbox checked={checked} onChange={(event) => handleChangeChecked(event, title)} />} />
      <div className="ExportQBOToExcel__Container__InputContainer__Title">{title}</div>
      <div data-test="start-date-input" className={checked ? "ExportQBOToExcel__Container__InputContainer__DateContainer" : "ExportQBOToExcel__Container__InputContainer__DateContainer disabled"}>
        <CustomDatePicker
          selected={dateFromString(startDate)}
          onChange={(date: Date) => setStartDate(dateObjToAsanaString(date))}
          dateFormat="MM-dd-yyyy"
          showYearDropdown
          showMonthYearPicker={true}
          disabled={!checked}
          
        />
      </div>
      <div data-test="start-date-input" className={checked ? "ExportQBOToExcel__Container__InputContainer__DateContainer" : "ExportQBOToExcel__Container__InputContainer__DateContainer disabled"}>
        <CustomDatePicker
          selected={dateFromString(endDate)}
          onChange={(date: Date) => setEndDate(dateObjToAsanaString(endOfMonth(date)))}
          dateFormat="MM-dd-yyyy"
          showYearDropdown
          showMonthYearPicker={true}
          disabled={!checked}
        />
      </div>
    </div>
  );
};

export default InputContainer;
