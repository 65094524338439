import APIManger from "../APIManger";
import { AGED_REPORTS_DETAIL_QBO, GET_AGED_REPORTS_QBO, IS_CONNECTED, RUN_AGED_REPORTS_QBO } from "./apiConstants";

export const getIsConnected = async(company_id): Promise<any> => {
  let url = IS_CONNECTED.replace("%COMPANY_ID%",company_id)
  const apiResult: any = await APIManger.get(url);
  return apiResult;
}

export const runAgedReports = async (company_id:any, params ): Promise<any> => {
    if ("start_date" in params && "end_date" in params) {
        let url = RUN_AGED_REPORTS_QBO.replace("%COMPANY_ID%",company_id).replace("%START_DATE%",params['start_date']).replace("%END_DATE%",params['end_date'])
        const apiResult: any = await APIManger.get(url);
        return apiResult;
    }
  };
  
export const getAgedReports = async (company_id:any, run_id:any ): Promise<any> => {
        let url = GET_AGED_REPORTS_QBO.replace("%COMPANY_ID%",company_id).replace("%RUN_ID%",run_id )
        const apiResult: any = await APIManger.get(url);
        return apiResult;
  };
  
export const getAgedReportsDetail = async (company_id:any,params ): Promise<any> => {
        let url = AGED_REPORTS_DETAIL_QBO.replace("%COMPANY_ID%",company_id).replace("%PARAMS%",params)
        const apiResult: any = await APIManger.get(url);
        return apiResult;    
  };