import React from 'react'
import TransactionLineTable from './TransactionLineTable'

const UnSupportedTransaction = () => {
  return (
    <div>
      This Transaction Type is not supported!
    </div>
  )
}

export default UnSupportedTransaction