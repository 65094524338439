


export const initialDates = new Date();
export const initialEditBulkState = {
    first_name: '',
    last_name: '',
    job_title: '',
    location: '-1',
    group: '-1',
    department: '-1',
    type: '-1',
    salary: '',
    start_date: null,
    end_date: null,
    note: '',
    schedule_raise_amount: '',
    schedule_raise_date: null,
    schedule_bonus_amount: '',
    schedule_bonus_repeat_every: null,
    schedule_commission_amount: '',
    schedule_commission_repeat_every: null,
}
export const initialErrors = {
    job_title: false,
    location: false,
    group: false,
    department: false,
    type: false,
    salary: false,
    start_date: false,
    repeat_times: false,
    schedule_raise_amount: false,
    schedule_raise_date: false,
    schedule_bonus_amount: false,
    schedule_bonus_repeat_every: false,
    schedule_commission_amount: false,
    schedule_commission_repeat_every: false,
};

export const initialState = {
    first_name: '',
    last_name: '',
    job_title: '',
    location: null,
    group: 1,
    department: '-1',
    type: 0,
    salary: '0',
    start_date: initialDates,
    end_date: null,
    note: '',
    schedule_raise_amount: '0',
    schedule_raise_date: null,
    schedule_bonus_amount: '0',
    schedule_bonus_repeat_every: null,
    schedule_commission_amount: '0',
    schedule_commission_repeat_every: null,
    repeat_times: 1,
}