import React, { useContext, useState } from 'react';
import { DataGridPro, GridRowParams, GridColDef, MuiEvent, useGridApiContext, GRID_DATE_COL_DEF, useGridSelector, gridPageSelector, gridPageSizeSelector } from '@mui/x-data-grid-pro';
import { createStyles, makeStyles } from '@mui/styles';
import TablePagination from '@mui/material/TablePagination';
import columnsRenderFunctions from './RenderComponents';
import HideAllButton from './HideAllButton';
import { ReactComponent as FilterDropdownIcon } from '../../../static/img/filter_dropdown_icon.svg';
import './tableComponent.css';
import { employeeTableDisplay, employeeTableFilter, employeeTableFilterOptions } from '../../../config/headcountConfigs';
import NoTableData from '../../Common/NoTableData';
import CustomSwitch from '../../Common/CustomSwitch';
import { EmployeeListConext } from '../../../contexts/HeadCount/EmployeeListConext';
import { dateFromString, displayStrDateFormatter, uppercaseAllWordLetters } from '../../../utils/helpers';
import { DataConext } from '../../../contexts/HeadCount/DataConext';
import { HeadCountInformation } from '../../../types/HeadCount';


const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                '& .MuiDataGrid-root': {
                    backgroundColor: '#fafafa',
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: "bold",
                        fontSize: "11px",
                        lineHeight: "16px",
                        color: "#666687",
                    },
                    '& .MuiDataGrid-iconSeparator': {
                        height: "56px",
                        borderLeft: "1px solid #F6F6F9"
                    },
                    '& .MuiDataGrid-columnHeader--sortable': {
                        '& .MuiDataGrid-iconButtonContainer': {
                            visibility: 'visible',
                            width: "auto"
                        },
                    },
                    '& .MuiDataGrid-columnHeader': {
                        '& .MuiDataGrid-columnHeaderTitleContainer': {
                            flexDirection: 'row',
                        },
                        '& .MuiDataGrid-columnHeaderDraggableContainer': {
                            flexDirection: 'row',
                            '& .MuiDataGrid-menuIcon': {
                                visibility: 'visible',
                                marginRight: '2px',
                                width: 'auto !important'
                            }
                        }
                    }
                },
                '& .MuiDataGrid-row': {
                    '& :hover': {
                        cursor: 'pointer',
                    },
                    '&.Mui-selected': {
                        backgroundColor: '#85b4401A !important',
                        '& :hover': {
                            backgroundColor: '#85b4401A !important',
                        },
                    },
                },
                '& .MuiDataGrid-cell': {
                    color: '#666687',
                    fontSize: 14,
                    borderRight: "1px solid #F6F6F9",
                    justifyContent: 'flex-start'
                },
                '& .MuiCheckbox-root': {
                    color: '#C0C0CF',
                },
                '& .MuiCheckbox-root >svg': {
                    border: "0px solid #FFFFFF",
                    height: "20px",
                    width: "20px"
                },
                '& .MuiCheckbox-root.Mui-checked': {
                    color: '#85b440 !important',
                },
                '& .DataGrid-marked-row--true': {
                    backgroundColor: '#FDF4DC',
                },
            }
        }));

export default function DataGridComponent() {
    const classes = useStyles();
    const { selectedFlatRows, setSelectedFlatRows, openEditDialoge, onFilterModelChange, filterModel } = useContext(EmployeeListConext);
    const { headCountDisplay } = useContext(DataConext);
    console.log("headCountDisplay",headCountDisplay)
    const [rowsPerPage, setRowsPerPage] = useState(100);

    const headCells: GridColDef[] = employeeTableDisplay.map(key => {
        const cellRenerItem = columnsRenderFunctions[key];
        if (key.includes("date")) {
            return {
                ...GRID_DATE_COL_DEF,
                field: key, headerName: uppercaseAllWordLetters(key),
                valueGetter: (value) => dateFromString(value.value),
                renderCell: (params) => displayStrDateFormatter(params.value),
                width: 125,
            }
        }
        else {
            return {
                field: employeeTableFilter.includes(key) ? key + "Str" : key,
                headerName: uppercaseAllWordLetters(key),
                renderCell: cellRenerItem,
                valueOptions: employeeTableFilterOptions.includes(key) ? HeadCountInformation.company_filter.lookUpOptionNames(key, false) : [],
                type: key === 'salary' ? 'number' : employeeTableFilterOptions.includes(key) ? 'singleSelect' : 'string',
                flex: (key === 'job_title' || key === 'department') ? 2 : 1,
            }
        }
    });


    function CustomPagination() {
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

        return (
            <TablePagination
                rowsPerPageOptions={[10, 30, 70, 100]}
                component="div"
                colSpan={3}
                page={page}
                count={headCountDisplay.length}
                rowsPerPage={pageSize}
                labelRowsPerPage="Entries per page"
                onPageChange={(event, value) => {
                    apiRef.current.setPage(value);
                }}
                onRowsPerPageChange={(event) => {
                    apiRef.current.setPageSize(parseInt(event.target.value, 10));
                    setRowsPerPage(parseInt(event.target.value, 10));
                }}
                sx={{
                    '& .MuiTablePagination-toolbar': {
                        paddingRight: 3,
                    },
                    '& .MuiTablePagination-selectLabel': {
                        mb: 0,
                        fontSize: 12,
                        lineHeight: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        color: '#8E8EA9'
                    },
                    '& .MuiTablePagination-displayedRows': {
                        mb: 0,
                    },
                    '& .MuiTablePagination-select': {
                        paddingRight: '24px',
                        minWidth: "16px",
                        fontSize: "1.3rem",
                        lineHeight: "2.3rem",
                        alignSelf: 'center',
                        '&.MuiTablePagination-select': {
                            backgroundColor: "#FFFFFF",
                            border: "1px solid #EAEAEF",
                            borderRadius: " 4px",
                        }
                    },
                }}
                SelectProps={{
                    MenuProps: {
                        BackdropProps: {
                            invisible: true,
                            className: 'pagination-list-background',
                        },
                    }
                }}
            />
        );
    }

    const openTransaction = (params, event, detail) => {

    }

    return <div className="tableContainer">
        <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1, height: "100%" }} className={classes.root}>
                <DataGridPro
                    columns={headCells}
                    rows={headCountDisplay.items}
                    getRowClassName={(params) => {
                        return `DataGrid-marked-row--${params.row.new}`
                    }}
                    disableSelectionOnClick
                    disableColumnPinning
                    showColumnRightBorder
                    showCellRightBorder
                    localeText={{
                        columnMenuSortAsc: 'Sort A to Z ↑',
                        columnMenuSortDesc: 'Sort Z to A ↓',
                        columnsPanelHideAllButton: <HideAllButton />,
                    }}
                    onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
                    onSelectionModelChange={(newSelectionModel) => {
                        setSelectedFlatRows(newSelectionModel.map(item => item.toString()));
                    }}
                    selectionModel={selectedFlatRows.map(item => parseInt(item))}
                    onRowClick={(params: GridRowParams, event: MuiEvent<React.SyntheticEvent<Element, Event>>) => {
                        event.defaultMuiPrevented = true;
                        if ((event.target as HTMLInputElement).type === 'checkbox')
                            return
                        openEditDialoge(params.row.id);
                    }}
                    components={{
                        NoRowsOverlay: NoTableData,
                        BaseSwitch: CustomSwitch,
                        Pagination: CustomPagination,
                        ColumnMenuIcon: FilterDropdownIcon
                    }}
                    initialState={{
                        filter: { filterModel: filterModel },
                        // sorting: { sortModel: [{ field: 'start_date', sort: 'asc' }] },
                    }}
                    pageSize={rowsPerPage}
                    pagination={headCountDisplay.length > 0}
                    rowsPerPageOptions={[10, 30, 70, 100]}
                    checkboxSelection={headCountDisplay.length > 0}
                    onFilterModelChange={onFilterModelChange}
                    onCellClick={openTransaction}
                />
            </div>
        </div>
    </div>
}