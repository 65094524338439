import React, { useContext } from "react";
import MDIcons from "../../Common/MDIcons";
import CustomTooltip from "../../Common/Tooltip";
import EmailChipMenu from "../../Common/DropDownComponent/EmailChipMenu";
import { addSharePointFilePermissions } from "../../../APIFunctions/graph_api";
import { AuthContext } from "../../../contexts/AuthContext";
import { MilestoneEmailContext } from "../../../contexts/TicketingSystem/MilestoneEmailContext";
import { MilestoneContext } from "../../../contexts/TicketingSystem/MilestoneContext";
import { updatePayrollFileLink } from "../../../APIFunctions/ticketing_system";
import { showToast } from "../../../utils/toastHelpers";
import { useEditLink } from "../Components/Links/LinksTableComponent/Hooks";
import { TooltipMessage } from "./helpers";
import usePermissions from "./hooks/usePermissions";


interface EmailChipProps {
  removeTags: (index: number) => void;
  tag: string;
  index: number;
  allowedEmails: string[]|null;
  setAllowedEmails: ((emails: string[]) => void)|null;
  allowedEmailsApp: string[];
  allowedEmailsAsana: string[];
}

const EmailChip: React.FC<EmailChipProps> = ({ removeTags, tag, index, allowedEmails, setAllowedEmails, allowedEmailsApp, allowedEmailsAsana }) => {
  const { selectedCompany } = useContext(AuthContext);
  const { sharepointLinkInEmail, setSharepointLinkInEmail } = useContext(MilestoneEmailContext);
  const { milestoneType } = useContext(MilestoneContext);
  const linkEditMutation = useEditLink(sharepointLinkInEmail?.id);

  const { appPermissionsGranted, asanaPermissionsGranted, permissionGranted } = usePermissions(tag, allowedEmailsApp, allowedEmailsAsana, allowedEmails);

  const customErrorMessages = () => {
    if (allowedEmails?.map((x) => x.toLowerCase())?.includes("error")) {
      return allowedEmails[1];
    } else {
      return false;
    }
  };

  const addAccess = async (accessPlatform) => {
    if (!allowedEmails || !setAllowedEmails) {
      return
    }
    try {
      if (selectedCompany && sharepointLinkInEmail && accessPlatform === "sharepoint") {
        if (sharepointLinkInEmail?.sharepoint_file_info?.sharepoint_id) {
          const response = await addSharePointFilePermissions(selectedCompany, sharepointLinkInEmail?.sharepoint_file_info?.sharepoint_id, tag);
          if (response["status"] === "success") {
            setAllowedEmails([...allowedEmails, tag.toLowerCase()]);
          }
          if (response["link"] !== "") {
            if (milestoneType !== 0) {
                linkEditMutation.mutate({ ...sharepointLinkInEmail["sharepoint_file_info"], web_url: response["link"] });
              }
            else if (sharepointLinkInEmail?.id) {
              const updateResponse = await updatePayrollFileLink(sharepointLinkInEmail?.id, { link: response["link"] });
              if (updateResponse.error) {
                throw new Error(updateResponse.message);
              }
            }
            setSharepointLinkInEmail({ ...sharepointLinkInEmail, sharepoint_file_info: { ...sharepointLinkInEmail["sharepoint_file_info"], web_url: response["link"] } });
          }
        } else {
          showToast("error", "No sharepoint file id detected! SOLUTION: set the link then try again!");
        }
      }
    } catch (error) {
      showToast("error", `An error occurred: ${error?.["message"]}`);
    }
  };


  return (
    <EmailChipMenu addAccess={addAccess} appAccessFlag={true} sharepointAccessFlag={true}>
      <CustomTooltip
        title={
          <React.Fragment>
           <TooltipMessage
              customErrorMessages={customErrorMessages()}
              sharepointLinkInEmail={sharepointLinkInEmail}
              permissionGranted={permissionGranted}
              asanaPermissionsGranted={asanaPermissionsGranted}
              appPermissionsGranted={appPermissionsGranted}
            />
          </React.Fragment>
        }
      >
        <li className="tag" data-test="tag" id={!permissionGranted ? "no-permission" : !appPermissionsGranted || !asanaPermissionsGranted ? "no-app-asana-permission" : ""}>
          {(!permissionGranted || !asanaPermissionsGranted || !appPermissionsGranted) && (
            <span>
              <MDIcons title="ExclamationMarkCircle" />
            </span>
          )}
          <span className="tag-title" data-test="tag-title">
            {tag}
          </span>
          <span className="tag-close-icon" data-test="tag-close-icon" onClick={() => removeTags(index)}>
            <MDIcons title="Cross" />
          </span>
        </li>
      </CustomTooltip>
    </EmailChipMenu>
  );
};

export default EmailChip;
