import { LocalType } from "./LocalType";

export class AccessToken extends LocalType<AccessToken>{
asana_access_token:any=""

constructor(obj: AccessToken | null = null) {
    super("AsanaAccessToken")
    if (obj) {
        Object.keys(obj).forEach(key => {
            try {
                if (key in this)
                this[key] = obj[key]
            } catch (error) {
                this.copyingErrorHappen = true
            }

            
        })
    }
}

public static getLocalStorageObj(): AccessToken{
    var newObj = new AccessToken().getLocalObject()
    return  newObj ? newObj : new AccessToken()
}

}

export interface section {
    name:string;
    gid:string;
}

export interface membership {
    section:section
}


export class AsanaTask {
    gid:string = "";
    name: string = "";
    assignee:any = {
        gid:"",
        name:"",
        photo:{image_21x21:"",image_27x27:"",image_36x36:"",image_60x60:"",image_128x128:"",image_1024x1024:""}
    };
    custom_fields:any = [{enum_value:""}]
    assignee_gid:string = "";
    completed:boolean = false;
    start_on: string = "";
    due_on:string="";
    notes:string="";    
    projects_gid: string ="";
    workspace_gid = "";
    memberships:membership[] = []

    constructor(obj: AsanaTask | null = null) {
        if (obj) {
            Object.keys(obj).forEach(key => {
                if (key in this)
                    this[key] = obj[key]
            })
        }
    }
}

export class ManageTasks {
    [key: string]:any;
    id: number = 0;
    task: Partial<AsanaTask> = {};
    constructor(obj: ManageTasks | null = null) {
        if (obj) {
            Object.keys(obj).forEach(key => {
                if (key in this)
                    this[key] = obj[key]
            })
        }
    }

    public get task_name(): string {
        var task_name: string = (!this.task.name|| this.task.name === "") ? "":this.task.name
        return task_name
    }
}


// todo: create a constructor

// discuss: does this need to mimic the schea of the Asana project exactly?
export class AsanaProject {
    "data": {
        "name": "",
        "new_project": {
            "gid":""
        }
    }
}

export class AsanaSection {
    "data": [{
        "gid":"",
        "name":""
    }]
}


export interface GridSingleSelectFilterOption {
    value: string;
    label: string;
}
export class OptionsValueList {
    [key: string]: GridSingleSelectFilterOption[];
}