import { DataConextProvider } from "../../contexts/ManageUsers/DataConext";
import SettingsModuleBody from "./SettingsModuleBody";
import SettingsModuleHeader from "./SettingsModuleHeader";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { UserRole } from "../../types/ManageUsers";
import { UserListConextProvider } from "../../contexts/ManageUsers/UserListConext";

export default function SettingsModule() {
    const { userProfile, selectedCompany } = useContext(AuthContext);
    const navigate = useNavigate();
    if (!userProfile.is_superuser && selectedCompany?.role === UserRole.User) {
        navigate('/')
        return <></>
    }
    return (
        <DataConextProvider>
            <UserListConextProvider>
                <SettingsModuleHeader />
                <SettingsModuleBody />
            </UserListConextProvider>
        </DataConextProvider>
    )
}