import { createContext, useContext, useEffect, useState } from "react";
import { ISelectedFilters, QBOSelectedFilters } from "../../../../../types/QBOReports.ts/SelectedFilters";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { FirstDayCurrentYear, appDateStringToDate, convertMonthLongYearStringToDate, createDateWithOffsetFromDate, dateObjToAppString, endOfMonth, startOfMonth } from "../../../../../utils/DateFunctions";
import { useSearchParams } from "react-router-dom";


export interface QBOFilterContextProps {
    selectedFilters: QBOSelectedFilters;
    setSelectedFilters: (selectedFilters: QBOSelectedFilters) => void;
    isModalOpen:boolean;
    setIsModalOpen: (open: boolean) => void
    toggleModal: () => void
    startDate: any;
    setStartDate: (startDate: any) => void;
    endDate: any;
    setEndDate: (endDate: any) => void;
    
}

export const QBOFilterContext = createContext<QBOFilterContextProps>(null as any);

export const QBOFilterContextProvider = (props) => {
    const { selectedCompany } = useContext(AuthContext)
    let [searchParams, setSearchParams] = useSearchParams();
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [selectedFilters, setSelectedFilters] = useState<QBOSelectedFilters>(new QBOSelectedFilters(selectedCompany?.offset));
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

    const startDateURL = searchParams.get("start_date");
    const endDateURL = searchParams.get("end_date");

    const toggleModal = () => {
        setIsModalOpen(prev => !prev)
    }

    const setInitialDates = () => {
      if (startDateURL) {
        setStartDate(appDateStringToDate(startDateURL))
      }
      if (endDateURL) {
        setEndDate(appDateStringToDate(endDateURL))
      }
      if (props.CustomEndDate && !startDateURL) {
        const endDateInitial = endOfMonth(convertMonthLongYearStringToDate(props.CustomEndDate));
        const startDateInitial = startOfMonth(createDateWithOffsetFromDate(endDateInitial, 0, -5, 0));
  
        setStartDate(startDateInitial);
        setEndDate(endDateInitial);
  
        if (!startDateURL) {
          setSearchParams((prevParams) => {
            return new URLSearchParams({
              ...Object.fromEntries(prevParams.entries()),
              ...{ start_date: dateObjToAppString(startDateInitial, "-"), end_date: dateObjToAppString(endDateInitial, "-") },
            });
          });
        }
      }
    }

    // const removeAllFilters = () => {
    //     setSelectedFilters(selectedFilters.resetFilters())   
    // }
    // const updateFilters = (key: string, selectedOptions: ISelectedFilters[]) => {
    //     setSelectedFilters(selectedFilters.updateSelectedOptions(key, selectedOptions))
    // }

    // todo: filtering based on the params in the url
    // const filterByParams = () => {
    //     const selectedPeriod = searchParams.get("view_by") ? PeriodDisplayOptions.find((x) => x.value === searchParams.get("view_by")) : PeriodDisplayOptions[0];
    //     if (selectedPeriod) setSelectedPeriodName(selectedPeriod);
    //     const selectedStartDate = searchParams.get("start_date");
    
    //     if (selectedStartDate) {
    //       const newDate = new Date(selectedStartDate);
    //       setStartDate(newDate);
    //     }
    //     const selectedEndDate = searchParams.get("end_date");
    //     if (selectedEndDate) {
    //       const newDate = new Date(selectedEndDate);
    //       setEndDate(newDate);
    //     }
    //   };

    useEffect(() => {
      setInitialDates()
      }, [props.CustomEndDate]);


    useEffect(() => {
      
      }, [props.CustomEndDate]);
    return (
        <QBOFilterContext.Provider value={{
            selectedFilters,
            setSelectedFilters,
            isModalOpen, setIsModalOpen, toggleModal, startDate, endDate, setStartDate, setEndDate
        }}>
            {props.children}
        </QBOFilterContext.Provider>
    )
}