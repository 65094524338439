import { TextareaAutosize } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import debounce from "lodash.debounce";
import { removeAsanaAttachment, retrieveAsanaAttachment, retrieveAsanaAttachments } from "../../../APIFunctions/Asana";
import { MilestoneContext } from "../../../contexts/TicketingSystem/MilestoneContext";
import "./style.css";
import AssigneeMenu from "../../Common/DropDownComponent/Assignee";
import CloseIcon from "@mui/icons-material/Close";
import CustomDatePicker from "../../Common/CustomDatePicker";
import { dateFromString } from "../../../utils/helpers";
import { dateObjToAppString, dateObjToAsanaString } from "../../../utils/DateFunctions";
import SubTaskLinksList from "./SubTaskLinksList";
import RichTextEditor, { convertQuillHTMLToAsana, convertQuillMentionsToAsana } from "../../Common/RichTextEditor";
import { TaskServicesContext } from "../../../contexts/Task/TaskServicesContext";
import MDIcons from "../../Common/MDIcons";
import SelectComponent from "../../Common/SelectComponent";
import { attachmentOptions } from "../../Tasks/utils";
import AsanaAPIManager from "../../../APIManger/Asana";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEditAsanaTask } from "../hooks/useAsanaTask";

const SubTaskContainer = ({ subTask, subTaskName, setSubTask, changeSubTaskInList }) => {
  const queryClient = useQueryClient();
  const asanaTaskMutation = useEditAsanaTask();
  const { setPayrollLoading } = useContext(MilestoneContext);
  const { team } = useContext(TaskServicesContext);
  const [name, setName] = useState<string>(subTask?.name);
  const [assignee, setAssignee] = useState<any>(subTask?.assignee);
  const [dueDate, setDueDate] = useState<string | null>(subTask?.due_on);
  const [notes, setNotes] = useState<string>("");

  const attachmentsQuery = useQuery({
    queryKey: ["asana-task", "attachments", subTask?.gid],
    queryFn: () => retrieveAsanaAttachments(subTask?.gid),
    staleTime: 1 * 60 * 1000,
    enabled: !!subTask?.gid,
  });

  const removeAttachment = async (gid) => {
    setPayrollLoading(true);
    await removeAsanaAttachment(gid);
    queryClient.refetchQueries({ queryKey: ["asana-task", "attachments", subTask?.gid] });
    setPayrollLoading(false);
  };

  const downloadAttachment = async (gid) => {
    const downloadURL = (await retrieveAsanaAttachment(gid)).data.download_url;
    await AsanaAPIManager.download(downloadURL);
  };

  const updateSubTaskInAsana = async (updatedSubtask) => {
    setPayrollLoading(true);
    await asanaTaskMutation.mutate({ gid: updatedSubtask.gid, name: updatedSubtask.name, assignee: updatedSubtask.assignee?.gid, html_notes: updatedSubtask.html_notes });
    setPayrollLoading(false);
  };

  const onChangeAssignee = (subTask) => {
    setAssignee(subTask.assignee);
    setSubTask(subTask);
    changeSubTaskInList(subTask);

    updateSubTaskInAsana(subTask);
  };

  const onClearAssignee = () => {
    setAssignee(null);
    setSubTask({ ...subTask, assignee: { gid: null } });
    updateSubTaskInAsana({ ...subTask, assignee: { gid: null } });
    changeSubTaskInList({ ...subTask, assignee: { gid: null } });
  };

  const onChangeNotes = (e) => {
    setNotes(e);
    debouncedSubTaskSave(e, "notes");
  };

  const onChangeName = (e) => {
    setName(e.target.value);
    debouncedSubTaskSave(e, "name");
  };

  const saveSubTask = (e, type) => {
    if (type === "name") {
      const cleaned_notes = convertQuillHTMLToAsana(convertQuillMentionsToAsana(notes));
      const newSubTask = { ...subTask, name: e.target.value, html_notes: cleaned_notes };
      setSubTask(newSubTask);
      changeSubTaskInList(newSubTask);
      updateSubTaskInAsana(newSubTask);
    } else if (type === "notes") {
      const cleaned_notes = convertQuillHTMLToAsana(convertQuillMentionsToAsana(e));
      const newSubTask = { ...subTask, name: name, html_notes: cleaned_notes };
      setSubTask(newSubTask);
      changeSubTaskInList(newSubTask);
      updateSubTaskInAsana(newSubTask);
    }
  };

  const onDateChange = (date: Date, task, updateTaskState) => {
    const newItem = {};
    newItem["due_on"] = dateObjToAsanaString(date);
    newItem["gid"] = task.gid;
    const updatedTask = { ...task };
    updatedTask["due_on"] = dateObjToAsanaString(date);
    updateTaskState(updatedTask);
    setSubTask(updatedTask);
    asanaTaskMutation.mutate(newItem);
    setDueDate(dateObjToAsanaString(date));
  };

  const onClearDueDate = (task, updateTaskState) => {
    const newItem = { ...task };
    newItem["due_on"] = null;
    setSubTask(newItem);
    updateTaskState(newItem);
    asanaTaskMutation.mutate(newItem);
    setDueDate(null);
  };

  const debouncedSubTaskSave = useMemo(() => {
    return debounce(saveSubTask, 500);
  }, [notes, name]);

  useEffect(() => {
    const html_string = subTask?.html_notes.replace("</body>", "").replace("<body>", "").replaceAll("\n", "<br/>");
    setNotes(html_string);
    return () => {
      debouncedSubTaskSave.cancel();
    };
  }, []);

  return (
    <>
      <div className="SubTaskContainer__NameInput__Container">
        <TextareaAutosize
          aria-label="empty textarea"
          id="feedback-task-card-name-input"
          placeholder="Insert Task Name ..."
          style={{ width: "100%", padding: 0, marginTop: 0 }}
          minRows={1}
          className="TaskCard__Title"
          value={name}
          name="name"
          onChange={(e) => onChangeName(e)}
        />
      </div>

      <div className="ClientPayrollCard__Container__Table__Container">
        <table>
          <tbody>
            <tr>
              <td className="left">
                <div className="table__cell">
                  {/* <div className="table__cell__label">Assignee</div> */}
                  <div className="table__cell__value">
                    <AssigneeMenu task={subTask} setTask={onChangeAssignee} size={36} optionsSize={27} />
                    {subTask?.assignee?.name === "" || assignee === null || assignee?.name === undefined ? (
                      <span className="placeholder">Add Assignee</span>
                    ) : (
                      <span className="SelectedAssigneeName">
                        {assignee?.name}
                        <CloseIcon onClick={() => onClearAssignee()} />
                      </span>
                    )}
                  </div>
                </div>
              </td>
              <td>
                <div className="table__cell">
                  {/* <div className="table__cell__label">Due Date</div> */}
                  <div className="table__cell__value">
                    <CustomDatePicker
                      selected={dueDate ? dateFromString(dueDate) : null}
                      onChange={(date: Date) => onDateChange(date, subTask, changeSubTaskInList)}
                      dateFormat="yyyy-MM-dd"
                      showYearDropdown
                      showMonthYearPicker={false}
                    />
                    {dueDate && <CloseIcon onClick={() => onClearDueDate(subTask, changeSubTaskInList)} />}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        {/* <SubTaskLinksList subtaskGID={subTask?.gid} /> */}

        <div className="SubTaskContainer__NotesContainer">
          <RichTextEditor htmlValue={notes} setHTMLValue={setNotes} onChange={(value) => onChangeNotes(value)} people={team} placeholder={"Add notes here..."}/>
          {/* asana attachments */}
          <div className="TaskCard__Attachments__Area">
            {attachmentsQuery.data?.data?.length > 0 &&
              attachmentsQuery.data?.data?.map((attachment) => (
                <div className="TaskCard__Attachments__Area__Container" key={attachment.gid}>
                  <MDIcons title="Files-icon" />
                  <span className="TaskCard__Attachments__Area__Container__Name">{attachment?.name}</span>
                  <SelectComponent
                    name={"attachment-actions"}
                    options={attachmentOptions}
                    onChange={(value) => {
                      value.value === "Download Attachment" ? downloadAttachment(attachment.gid) : removeAttachment(attachment.gid);
                    }}
                    isLoading={false}
                    internalLabel={true}
                    isSearchable={false}
                    value={{ value: "", label: "" }}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubTaskContainer;
