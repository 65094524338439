import axios, { AxiosInstance } from "axios";
import { CURRENT_BASE_URL } from "../APIFunctions/apiConstants";
import { errorHanlder } from "../utils/errorHandler";

declare module 'axios' {
    export interface AxiosResponse<T = any> extends Promise<T> { }
}
class APIManger {
    axiosInstance: AxiosInstance;
    token: string = localStorage.getItem("Token") || "";

    constructor() {
        this.axiosInstance = axios.create({
            baseURL: CURRENT_BASE_URL,
            headers: {
                "Content-type": "application/json"
            }
        });
        this.axiosInstance.interceptors.response.use((response) => {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response.data;
        }, (error) => {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            errorHanlder(error);
            /**
             * TODO: Handle errors that require UI
             */
            return error;
        });
        this.axiosInstance.interceptors.request.use((request) => {
            if (this.token && this.token !== '')
                request.headers.common['Authorization'] = "Token " + this.token
            return request
        }, (error) => {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            errorHanlder(error);
            return Promise.reject(error);
        });
    }

    get = async (url: string) => this.axiosInstance.get(url)

    download = async (url: string, file_name: string = "export.csv") => this.axiosInstance.get(url).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response as any]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file_name); //or any other extension
        document.body.appendChild(link);
        link.click();
    });

    downloadPDF = async (url: string) => {
        // discuss with Mihail
        const element = document.createElement('a');
        element.setAttribute('href', CURRENT_BASE_URL + url);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    downloadFinancials = async (url: string, file_name: string = "qbo_export.xlsx") => this.axiosInstance.get(url,{responseType:'blob'}).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response as any]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file_name); //or any other extension
        document.body.appendChild(link);
        link.click();
    });

    getFinancialsFile = async (url) => this.axiosInstance.get(url,{responseType:'blob'})


    upload = async (url: string, file: any, meta: string = "", name: string = "",email_id="") => {
        const formData = new FormData();
        formData.append("resource", file);
        if (meta) formData.append("meta", meta)
        if (name) formData.append("name", name)
        formData.append("email_id", email_id)
        return this.axiosInstance.post(url, formData, { headers: { 'Authorization': "Token " + this.token, "Content-Type": "multipart/form-data" } })
    }

    delete = async (url: string, data?) => this.axiosInstance.delete(url, { data: data })

    post = async (url: string, data?, token?: string) => {
        if (token) {
            return this.axiosInstance.post(url, data, { headers: { 'Authorization': "Token " + token } })
        } else {
            return this.axiosInstance.post(url, data)
        }
    }

    patch = async (url: string, data?, token?: string) => {
        if (token) {
            return this.axiosInstance.patch(url, data, { headers: { 'Authorization': "Token " + token } })
        } else {
            return this.axiosInstance.patch(url, data)
        }
    }

    

    put = async (url: string, data) => this.axiosInstance.put(url, data)

    logout(): void {     // this function is implemeted at the AuthContext 
    };

}

export default new APIManger()