import { useContext } from "react";
import { BrowserRouter, Routes, Route, Link, Navigate } from "react-router-dom";

import AppModuleViewer from "../components/AppModuleViewer";
import LoggedUserInvitation from "../components/Auth/LoggedUserInvitation";
import FinancialReportsHome from "../components/FinancialReportSchema";
import InvitationWelcome from "../components/InvitationWelcome";
import Onboarding from "../components/Onboarding";
import SettingsModule from "../components/SettingsModule";
import { AuthContext } from "../contexts/AuthContext";
import TermsOfService from "../components/Auth/Information/TermsOfService";
import PayrollClientView from "../components/TicketingSystem/PayrollViews";
import TasksModule from "../components/Tasks";
import TransactionsListComponent from "../components/Transactions";
import MilestonesAutomations from "../components/AdminAutomations/MilestonesAutomations";
import TaskServices from "../components/Tasks/TasksServices";
import PayrollViews from "../components/TicketingSystem/PayrollViews";
import MonthEndCloseViews from "../components/TicketingSystem/MonthEndViews/MonthEndViewsRoot";
import TasksList from "../components/Tasks/TasksTable";
import MilestoneCard from "../components/TicketingSystem/MonthEndViews/Components/PlayrollCard/MilestoneCard";
import MilestoneTaskContainer from "../components/TicketingSystem/Components/MilestoneTaskContainer";
import MilestoneTaskComponent from "../components/TicketingSystem/Components/MilestoneTaskComponent";
import ExportQBOToExcel from "../components/ExportQBO/ExportQBOToExcel";
import LoggedUserOnboardingInvitation from "../components/Auth/LoggedUserOnboardingInvitation";
import TaskCard from "../components/Tasks/TaskCard";

export default function AppRouter() {
  const shouldRedirect = true;
  const { selectedCompany } = useContext(AuthContext);
  return selectedCompany?.is_Onboarding ? (
    <Routes>
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/invitations/:inviteCode" element={<LoggedUserInvitation />} />
      <Route path="/onboarding-invitations/:inviteCode" element={<LoggedUserOnboardingInvitation />} />
      <Route path="/invitation-accepted/" element={<InvitationWelcome />} />
      <Route path="/onboarding/" element={<Onboarding />} />
      <Route path="/:company_name/onboarding" element={<Onboarding />} />
      <Route path="/" element={shouldRedirect ? <Navigate replace to="/" /> : <Onboarding />} />
    </Routes>
  ) : (
    <Routes>
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/:company_name/invitations/:inviteCode" element={<LoggedUserInvitation />} />
      <Route path="/:company_name/onboarding-invitations/:inviteCode" element={<LoggedUserOnboardingInvitation />} />
      <Route path="/:company_name/invitation-accepted" element={<InvitationWelcome />} />
      <Route path="/:company_name/asana-automations" element={<MilestonesAutomations />} />
      <Route path="/:company_name/settings" element={<SettingsModule />} />

      <Route path="/:company_name/financialreport" element={<FinancialReportsHome />} />
      <Route path="/:company_name/tasks" element={<TasksModule />}>
        <Route path="payroll" element={<PayrollViews />}>
          <Route path=":milestone_gid/action/:action" element={<PayrollViews />} />
          {/* <Route path=":milestone_gid" element={<PayrollViews />}/> */}
          <Route path=":milestoneTaskParam?" element={<MilestoneTaskContainer />}>
            <Route path="component" element={<MilestoneTaskComponent />} />
          </Route>
        </Route>
        <Route path="month-end-close" element={<MonthEndCloseViews />}>
          <Route path=":milestonegid" element={<MilestoneCard />}>
            <Route path=":milestoneTaskParam" element={<MilestoneTaskContainer />}>
              <Route path="component" element={<MilestoneTaskComponent />}></Route>
            </Route>
          </Route>
        </Route>
        {/* <Route path="project/:projectType" element={<TasksList />} /> */}
        <Route path="project/:projectType" element={<TasksList />}>
          <Route path=":task_gid" element={<TaskCard />} />
        </Route>
      </Route>

      <Route path="/:company_name/:module_url" element={<AppModuleViewer />} />
      <Route path="/:company_name" element={<AppModuleViewer />} />
      <Route path="/" element={<AppModuleViewer />} />
      {/* <Route path="/" element={shouldRedirect ? <Navigate replace to="/" /> : <AppModuleViewer />} /> */}
    </Routes>
  );
}
