import { createContext, useContext, useEffect, useState } from "react";
import { uploadFile } from "../../APIFunctions/file";
import { connectQBO, disconnectQBO, retrieveOnboarding, updateOnboarding } from "../../APIFunctions/onboarding";
import { MonthsOfYearOptions } from "../../components/Onboarding/FormPage2";
import { IFileResource } from "../../types/API/fileResource";
import { defaultHeadcountDepartments, defaultHeadcountLocations, defaultPLGroupings, Onboarding } from "../../types/Onboarding";
import { createDateWithOffset, createDateWithOffsetFromDate, endOfMonth, FirstDayCurrentYear } from "../../utils/DateFunctions";
import { stringToDateConverter } from "../../utils/helpers";
import { AuthContext } from "../AuthContext";
import { SocketContext } from '../../contexts/SocketContext';

export interface OnboardingContextProps {
    onboardingInputs: Onboarding;
    setOnboardingInputs: (onboardingItem: Onboarding) => void;
    selectedForm: any;
    setSelectedForm: (stepNumber: any) => void;
    applySaveStep: (onboardingCompleted: boolean, stepDirection?: string) => void;
    image: string,
    imageFile: IFileResource | undefined,
    setImageFile: (imageFile: IFileResource | undefined) => void,
    setImage: (image: string) => void,
    originalImage: string,
    setOriginalImage: (image: string) => void,
    isCropDialogOpen: boolean,
    setIsCropDialogOpen: (openCropDialog: boolean) => void,
    onBoardingCompleted: boolean,
    setOnboardingCompleted: (onBoardingCompleted: boolean) => void,
    onDateChange: (date: Date, itemKey: string) => void,
    onOnboardingInputsChange: (e: React.ChangeEvent<HTMLInputElement>, itemKey: string) => void,
    onConnection: (connect: boolean) => void,
    progress: number,
    setProgress: (progress: number) => void,
    formsCount: number,
    onSubmit: () => void,
    onBack: () => void,
    done: boolean,
    summaryIsOpen: boolean,
    setSummaryIsOpen: (summaryIsOpen: boolean) => void,
    PLFile: IFileResource | undefined,
    setPLFile: (PLFile: IFileResource | undefined) => void,
    BSFile: IFileResource | undefined,
    setBSFile: (BSFile: IFileResource | undefined) => void,
}

export const OnboardingContext = createContext<OnboardingContextProps>(null as any);

export const OnboardingContextProvider = (props) => {
    const formsCount = 5
    const { setQB_ConnectingStatus } = useContext(SocketContext);
    
    const { selectedCompany } = useContext(AuthContext)
    const [onboardingInputs, setOnboardingInputs] = useState<Onboarding>(new Onboarding())
    const [selectedForm, setSelectedForm] = useState<number>(0);
    const [image, setImage] = useState<string>("");
    const [imageFile, setImageFile] = useState<IFileResource | undefined>();
    const [originalImage, setOriginalImage] = useState<string>("");
    const [isCropDialogOpen, setIsCropDialogOpen] = useState<boolean>(false);
    const [onBoardingCompleted, setOnboardingCompleted] = useState<boolean>(false);

    const [progress, setProgress] = useState((1 * 100) / formsCount);
    const [done, setDone] = useState<boolean>(formsCount - 1 === selectedForm);
    const [summaryIsOpen, setSummaryIsOpen] = useState<boolean>(false);
    const [PLFile, setPLFile] = useState<IFileResource | undefined>();
    const [BSFile, setBSFile] = useState<IFileResource | undefined>();

    const onDateChange = (date: Date, itemKey: string) => {
        const newItem = { ...onboardingInputs };

        newItem[itemKey] = endOfMonth(date)
        setOnboardingInputs(newItem as any);
    }

    const onOnboardingInputsChange = (e: React.ChangeEvent<HTMLInputElement>, itemKey: string) => {
        const newItem = { ...onboardingInputs };
        newItem[itemKey] = e.currentTarget.value;
        setOnboardingInputs(newItem as any);
    };


    const onConnection = async (connect) => {
        if (selectedCompany) {
            if (connect) {
                const consentURL = await connectQBO(selectedCompany.id)
                window.open(consentURL,"","width=800,height=800,top=100,left=500");
            } else if (connect === false) {
                const response = await disconnectQBO(selectedCompany.id)
            }

            getOnboardingData()
        }
    }
    const applySaveStep = async (onboardingCompleted: boolean, stepDirection?: string, onboardingObj?: Onboarding) => {
        if (selectedCompany?.is_Onboarding === false) return
        var newOnboardingInputs: any = {}
        const commaSeparatedLists = {
            "headcount_departments":
                Array.isArray(onboardingInputs["headcount_departments"]) ?
                    onboardingInputs["headcount_departments"].join(',')
                    : onboardingInputs["headcount_departments"],
            "headcount_locations": Array.isArray(onboardingInputs["headcount_locations"]) ?
                onboardingInputs["headcount_locations"].join(',')
                : onboardingInputs["headcount_locations"],
            "pl_groupings": Array.isArray(onboardingInputs["pl_groupings"]) ?
                onboardingInputs["pl_groupings"].join(',')
                : onboardingInputs["pl_groupings"]
        }
        const stringDates = {
            "start_date": stringToDateConverter(onboardingInputs["start_date"] as any) as any,
            "end_date": stringToDateConverter(onboardingInputs["end_date"] as any) as any,
            "last_actuals_date": stringToDateConverter(onboardingInputs["last_actuals_date"] as any) as any
        }


        if (onboardingCompleted) {
            newOnboardingInputs = {
                ...onboardingInputs,
                "onboarding_step": selectedForm,
                "onboarding_completed": true,
                "onboarding_completed_send_email": true,
                ...commaSeparatedLists,
                ...stringDates
            }
        } else {
            let onboarding_step = 0
            if (stepDirection === "next") {
                onboarding_step = selectedForm + 1
            } else if (stepDirection === "same") {
                onboarding_step = selectedForm 
            } else if (stepDirection === "back") {
                onboarding_step = selectedForm - 1
            }
            newOnboardingInputs = {
                ...onboardingInputs,
                "onboarding_step": onboarding_step,
                ...commaSeparatedLists,
                ...stringDates,

            }
        }


        // get file ids if there were files uploaded
        if (PLFile) newOnboardingInputs["PLFile"] = PLFile.id
        if (BSFile) newOnboardingInputs["BSFile"] = BSFile.id
        const result = await updateOnboarding(Onboarding.toAPIRepresentation(newOnboardingInputs))
    }

    useEffect(() => {
        setDone(formsCount - 1 === selectedForm)
    }, [selectedForm])

    useEffect(() => {
        if (selectedCompany?.is_Onboarding) {
            getOnboardingData()
        }
    }, [selectedCompany])

    const getOnboardingData = async () => {
        if (selectedCompany) {
            const response = await retrieveOnboarding({ id: selectedCompany?.id })
            
            const obj = { ...response }

            if (obj["headcount_departments"] === null) {
                obj["headcount_departments"] = defaultHeadcountDepartments
            }
            if (typeof obj["headcount_departments"] === 'string') {
                obj["headcount_departments"] = obj["headcount_departments"]?.split(',')
            }

            if (obj["headcount_locations"] === null || obj["headcount_departments"]?.length === 0) {
                obj["headcount_locations"] = defaultHeadcountLocations
            } else if (typeof obj["headcount_locations"] === 'string') {
                obj["headcount_locations"] = obj["headcount_locations"]?.split(',')
            }

            if (obj["pl_groupings"] === null) {
                obj["pl_groupings"] = defaultPLGroupings
            } else if (typeof obj["pl_groupings"] === 'string') {
                obj["pl_groupings"] = obj["pl_groupings"].split(',')
            }

            const monthIndex = MonthsOfYearOptions.findIndex((value) => value.value === obj["offset"].toString());
            obj["offset"] = { value: (obj["offset"]).toString(), label: MonthsOfYearOptions[monthIndex].label }


            if (obj["start_date"] === null) {
                obj["start_date"] = endOfMonth(FirstDayCurrentYear());
            }
            if (obj["last_actuals_date"] === null) {
                obj["last_actuals_date"] = endOfMonth(createDateWithOffset(0, -2, 0))
            }
            if (obj["end_date"] === null) {
                obj["end_date"] = endOfMonth(createDateWithOffsetFromDate(FirstDayCurrentYear(), 0, 11, 3))
            }

            if (obj.onboarding_completed) {
                setOnboardingCompleted(obj.onboarding_completed)
            } else {
                setSelectedForm(obj.onboarding_step)
                setProgress(((1 * 100) / formsCount) * (obj.onboarding_step + 1))
            }

            setQB_ConnectingStatus(obj["qb_status"])
            setOnboardingInputs(obj)

        }
    }

    useEffect(() => {
        if (imageFile) {
            const upload = async () => {
                var result = await uploadFile(imageFile, "avatar", imageFile.name)
                const newOnboardingInputs = { ...onboardingInputs, "company_avatar": result.file.id }
                setOnboardingInputs(newOnboardingInputs)
            }
            upload()
        }

    }, [image])

    const onSubmit = () => {
        if (formsCount - 1 === selectedForm) {
            applySaveStep(false, "same")
            setSummaryIsOpen(true)
        } else {

            setSelectedForm((form) => form + 1);
            // save to backend
            applySaveStep(false, "next")
            // change the progress
            setProgress((progress) => {
                return progress + (1 * 100) / formsCount;
            });
        }
    }

    const onBack = () => {
        // load prev form
        setSelectedForm((stepNumber) => stepNumber - 1);
        applySaveStep(false, "back")
        // change the progress
        setProgress((progress) => {
            return progress - (1 * 100) / formsCount;
        });
    }

    return (
        <OnboardingContext.Provider value={{
            onboardingInputs,
            setOnboardingInputs,
            applySaveStep,
            selectedForm, setSelectedForm,
            onDateChange,
            onConnection,
            onOnboardingInputsChange,
            progress,
            setProgress,
            formsCount,
            image,
            imageFile,
            setImageFile,
            setImage,
            originalImage,
            setOriginalImage,
            isCropDialogOpen, setIsCropDialogOpen,
            done,
            onSubmit,
            onBack,
            summaryIsOpen,
            setSummaryIsOpen,
            onBoardingCompleted,
            setOnboardingCompleted,
            PLFile,
            BSFile,
            setPLFile,
            setBSFile
        }}>
            {props.children}
        </OnboardingContext.Provider>
    )

}