import { createContext, useContext, useEffect, useState } from "react";
import { OptionTypeBase } from "react-select";
import { TreeDataProvider } from "../../components/HeadCountModule/ReportNestedTree/TreeDataProvider";
import { LoaderStatus } from "../../types/Loaders";
import { AuthContext } from "../AuthContext";
import { DataConext } from "./DataConext";
import { ReportFilterContext } from "./ReportFilterContext";


export const ProjectionDisplayOptions = [
    { value: "department", label: "Department" },
    { value: "location", label: "Location" },
    { value: "job_title", label: "Job Title" },
    { value: "employe_name", label: "Employe Name" },
    { value: "group", label: "Group" },
    { value: "all", label: "All" }
];
export const PeriodDisplayOptions = [
    { value: "Month", label: "Month" },
    { value: "Quarter", label: "Quarter" },
    { value: "Year", label: "Year" }
];
export interface ReportDataContextProps {
    reportDataModel: TreeDataProvider;
    selectedPeriodName: OptionTypeBase;
    selectedProjectionName: OptionTypeBase;

    setSelectedPeriodName: (option: OptionTypeBase) => void;
    setSelectedProjectionName: (option: OptionTypeBase) => void;
}


export const ReportDataContext = createContext<ReportDataContextProps>(null as any);

export const ReportDataContextProvider = (props) => {
    const { selectedCompany,companyFilter } = useContext(AuthContext)
    const { selectedFilters } = useContext(ReportFilterContext);

    const [selectedPeriodName, setSelectedPeriodName] = useState<OptionTypeBase>(PeriodDisplayOptions[0]);
    const [selectedProjectionName, setSelectedProjectionName] = useState<OptionTypeBase>(ProjectionDisplayOptions[0]);
    const { headCountData, hc_LoaderStatus, setLoaderStatus } = useContext(DataConext);
    const [reportDataModel, setReportDataModel] = useState<TreeDataProvider>(new TreeDataProvider())

    useEffect(() => {
        setTimeout(() => {
                setReportDataModel(new TreeDataProvider(headCountData, selectedCompany, selectedFilters))
                if (hc_LoaderStatus === LoaderStatus.GeneratingReports)setLoaderStatus(LoaderStatus.Rendered)
        });
    }, [headCountData, selectedFilters, selectedCompany, companyFilter, setLoaderStatus, hc_LoaderStatus]);

    return (
        <ReportDataContext.Provider value={{
            reportDataModel,
            selectedPeriodName, setSelectedPeriodName,
            selectedProjectionName, setSelectedProjectionName
        }}>
            {props.children}
        </ReportDataContext.Provider>
    )
}

