import React, { useContext, useEffect, useState } from "react";
import CreatedByImage from "../../../../Common/Asana/CreatorImage";
import dayjs from "dayjs";
import BasicMenu from "../../../../Common/DropDownComponent/TaskMoreActions";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { TextareaAutosize } from "@mui/material";
import { updateAsanaTaskStory } from "../../../../../APIFunctions/Asana";
import RichTextEditor, { convertQuillHTMLToAsana, convertQuillMentionsToAsana } from "../../../../Common/RichTextEditor";
import { TaskServicesContext } from "../../../../../contexts/Task/TaskServicesContext";

const CommentReply = ({ reply, replyDeleteMutation }) => {
    const { userProfile } = useContext(AuthContext);
    const { team } = useContext(TaskServicesContext);
    const [replyText, setReplyText] = useState<string>(reply?.text)
    const [editFlag, setEditFlag] = useState<boolean>(false)

    

    const onEditReply = async() => {
        const cleaned_reply = convertQuillHTMLToAsana(convertQuillMentionsToAsana(replyText));
        updateAsanaTaskStory({gid:reply.gid, html_text:cleaned_reply})
        setEditFlag(false)
    }

    useEffect(() => {
  
        setReplyText(reply?.html_text?.replace("</body>", "").replace("<body>", "").replaceAll("\n", "<br/>"))
    },[])


  return (
    <div className="MonthEndComments__Comment__Reply">
      <div className="MonthEndComments__Comment__Title">
        <CreatedByImage task={reply} size={27} />
        <div className="MonthEndComments__Comment__Title__AuthorName">{reply?.created_by?.name}</div>
        <div className="MonthEndComments__Comment__Title__Date">{dayjs(reply?.created_at).fromNow()}</div>
        {reply?.created_by?.gid === userProfile?.asana_user_id ? (
              <BasicMenu deleteItem={() => replyDeleteMutation.mutate(reply.gid)} editItem={() => setEditFlag(true)} type="comment" />
            ) : (
              <></>
            )}
      </div>
      {editFlag ? 
      <><RichTextEditor htmlValue={replyText} setHTMLValue={setReplyText} onChange={(value) => setReplyText(value)} people={team} placeholder={"Add notes here..."}/>
          <button onClick={onEditReply}>
            reply
          </button>
          </>
      :
      <div  dangerouslySetInnerHTML={{ __html: replyText }} className="MonthEndComments__Comment__Body" />}
    </div>
  );
};

export default CommentReply;
