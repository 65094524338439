import { Close } from "@mui/icons-material";
import { TextareaAutosize } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { MilestoneContext } from "../../../../contexts/TicketingSystem/MilestoneContext";
import { MilestoneEmailContext } from "../../../../contexts/TicketingSystem/MilestoneEmailContext";
import MDIcons from "../../../Common/MDIcons";
import { getPayrollTaskType, PayrollTaskType } from "../../../Tasks/utils";
import AlertDialog from "../../../Common/AlertDialog";

const StageApproveRejectPayroll = ({ allCompleted, allAccepted, allReviewed, revisionSelected, onSubmitRequestRevision }) => {
  const { currentStage, onComplete, prevStage } = useContext(MilestoneContext);
  const { setEmailReadyToSendFlag } = useContext(MilestoneEmailContext);

  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const onShowConfirmDialog = () => {
    setShowConfirmDialog(true);
  };

  const onCloseConfirmDialog = () => {
    setShowConfirmDialog(false);
  };

  const onApprove = () => {
    if (getPayrollTaskType(currentStage?.name) === PayrollTaskType.MANAGER_REVIEW) {
      onShowConfirmDialog();
    } else {
      onComplete();
    }
  };

  return (
    <div className={"completeButton"}>
      <div className="flex-row">
        <button
          id=""
          className="btn--tertiary btn--S flex-row--S success"
          onClick={onApprove}
          // disabled={
          //   currentStage?.completed === true || (getPayrollTaskType(currentStage?.name) === PayrollTaskType.MANAGER_REVIEW && (!allAccepted() || !allReviewed())) || !allCompleted()
          // }
        >
          <MDIcons title="check_button" />
          <span>
            {/* SCENARIO: USER IS ASSIGNED THE CURRENT STAGE */}
            {getPayrollTaskType(currentStage?.name) === PayrollTaskType.STAFF_PREPARATION
              ? "Submit for review"
              : getPayrollTaskType(currentStage?.name) === PayrollTaskType.MANAGER_REVIEW
              ? prevStage?.completed === true
                ? "Send to Client for Review"
                : "Waiting on Staff Preparation"
              : getPayrollTaskType(currentStage?.name) === PayrollTaskType.CLIENT_REVIEW
              ? prevStage?.completed === true
                ? "Mark Client Approval"
                : "Waiting on Manager Approval"
              : getPayrollTaskType(currentStage?.name) === PayrollTaskType.READY_TO_PROCESS
              ? prevStage?.completed === true
                ? "Complete Processing Payroll"
                : "Waiting on Client Approval"
              : "Mark Complete"}
          </span>
        </button>

        {/* REJECT */}
        {getPayrollTaskType(currentStage?.name) === PayrollTaskType.MANAGER_REVIEW && (
          <button id="danger" className="btn--tertiary btn--S flex-row--S" onClick={onSubmitRequestRevision} disabled={!revisionSelected()}>
            <Close />
            <span>Request Revision</span>
          </button>
        )}
      </div>

      <AlertDialog
        onClose={onCloseConfirmDialog}
        open={showConfirmDialog}
        title={"Do you want to send Payroll email as well?"}
        dialogType="alert"
        dialogActions={
          <div className="flex-row-space-between">
            <button
              className="btn--tertiary btn--M btn--fullWidth"
              onClick={() => {
                onCloseConfirmDialog();
                onComplete();
              }}
            >
              Just Mark Completed
            </button>
            <button
              style={{ marginLeft: "1rem" }}
              className="btn--primary btn--M btn--fullWidth"
              onClick={() => {
                setEmailReadyToSendFlag("move-stage");
                onCloseConfirmDialog();
              }}
            >
              <span>View Email Dialog</span>
            </button>
          </div>
        }
      ></AlertDialog>
    </div>
  );
};

export default StageApproveRejectPayroll;
