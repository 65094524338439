import React, { useContext } from "react";
import CreatedByImage from "../../../../../Common/Asana/CreatorImage";

import TicketingSystemIcons from "../../../TicketingSystemIcons";
import { AuthContext } from "../../../../../../contexts/AuthContext";
import BasicMenu from "../../../../../Common/DropDownComponent/TaskMoreActions";
import AssigneeSkeleton from "../../../../../Common/Skeletons/AssigneeSkeleton";
import AssigneeMenu from "../../../../../Common/DropDownComponent/Assignee";
import { TextareaAutosize } from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import MDIcons from "../../../../../Common/MDIcons";
import { MonthEndCloseContext } from "../../../../../../contexts/TicketingSystem/MonthEndCloseContext";
import RichTextEditor from "../../../../../Common/RichTextEditor";
import ReplySkeleton from "./ReplySkeleton";
import AlertDialog from "../../../../../Common/AlertDialog";
import CommentReply from "../../../MilestoneTaskComponent/Financials/CommentReply";
import { CommentContext } from "../../Context/CommentContext";
dayjs.extend(relativeTime);

const CommentNormalView = ({
  setCommentEditID,
  asanaTaskQuery,
  comment,
  updateCommentResolution,
  onDelete,
  setCommentDescriptionFlag,
  editCommentMutation,
  description,
  selectedCommentID,
  reply,
  setReply,
  replyMutation,
  onEnterReply,
  repliesQuery,
  replyDeleteMutation,
  team
}) => {
  const { userProfile } = useContext(AuthContext);
  const { transactionDetailFlag, setTransactionDetailFlag } = useContext(MonthEndCloseContext)
  const {goToCommentCell} = useContext(CommentContext)


const onGoToCommentCell = () => {
  
  goToCommentCell(comment)
}



  return (
    <>  
    <div className="MonthEndComments__Comment__NormalViewHeader">
        <div className="MonthEndComments__Comment__AssignedTo">
            {
                comment?.asana_task?.assignee ? `Assigned to ${comment?.asana_task?.assignee.name}` : "No one is assigned this comment"
            }
        </div>
        <div className="MonthEndComments__Comment___PinMap no-borders-no-background success" id="goToCell" onClick={onGoToCommentCell}><MDIcons title="PinMap" /></div>
        </div>
      <div className="MonthEndComments__Comment__Title">
        <CreatedByImage task={comment?.asana_task} size={27} />
        <div className="MonthEndComments__Comment__Title__AuthorName">{comment?.asana_task?.created_by?.name}</div>
        <div className="MonthEndComments__Comment__Title__Date">{dayjs(comment?.asana_task?.created_at).fromNow()}</div>
        <div className="MonthEndComments__Comment__Buttons">
          <div id="resolve-comment" className={`no-borders-no-background success ${comment?.asana_task?.completed === true ? "resolved" : ""}`} onClick={updateCommentResolution}>
            <TicketingSystemIcons title="resolve_comment" />
          </div>
          {comment?.asana_task?.created_by?.gid === userProfile?.asana_user_id ? (
            <BasicMenu deleteItem={onDelete} editItem={() => setCommentEditID(comment.id)} type="comment" />
          ) : (
            <></>
          )}
          {/* todo: add a skeleton for Assignee Menu while editCommentMutation.isLoading */}
        </div>
      </div>
      <div dangerouslySetInnerHTML={{ __html: description }} className="MonthEndComments__Comment__Body" />
      
      <div className="MonthEndComments__Comment__RepliesContainer">
        {repliesQuery.data?.map((x) => {
          return <CommentReply reply={x} replyDeleteMutation={replyDeleteMutation} />;
        })}
      </div>
      {(!replyMutation.isSuccess && replyMutation.isLoading) && <ReplySkeleton />}
      {selectedCommentID === comment?.id && (
        <div className="MonthEndComments__Comment__Reply">
          <RichTextEditor htmlValue={reply} setHTMLValue={setReply} onChange={(value) => setReply(value)} people={team} placeholder={"Add comment here..."}/>
          <button onClick={() => replyMutation.mutate()}>
            reply
          </button>
        </div>
      )}

{/* todo: add TransactionDetail component here */}
      
      
    </>
  );
};

export default CommentNormalView;
