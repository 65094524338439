import { GridCallbackDetails, GridFilterModel } from "@mui/x-data-grid-pro";
import { createContext, useContext, useEffect, useState } from "react";
import { addEmployee, deleteEmployee, deleteSelectedEmployeesList, editEmployee, duplicateEmployee, updateSelectedEmployeesList } from "../../APIFunctions/headcouts";
import { ModuleType } from "../../types/CompanyModules";
import { EmployeeDialogType, HeadCountInformation } from '../../types/HeadCount';
import { LoaderStatus } from "../../types/Loaders";
import { stringToDateConverter } from "../../utils/helpers";
import { AuthContext } from "../AuthContext";
import { ModuleContext } from "../ModuleContext";
import { DataConext } from "./DataConext";
export interface EmployeeListConextProps {
    selectedFlatRows: readonly string[];
    setSelectedFlatRows: (selectedFlatRows: readonly string[]) => void;

    applyBulkEdit: (updates) => void;
    onClickSelectedItem: HeadCountInformation | null;
    setOnClickSelectedItem: (item: HeadCountInformation | null) => void;

    deleteDialogeOpen: boolean;
    closeDeleteDialoge: (apply: boolean) => void;
    openDeleteDialoge: () => void;

    duplicateDialogeOpen: boolean;
    closeDuplicateDialoge: (duplicate_num: number) => void;
    openDuplicateDialoge: () => void;

    openEditDialoge: (id: string) => void;

    openEditBulkDialoge: () => void;

    showToastThenRefresh: (message: string, markedCount?: number) => void;
    employeeDialogType: EmployeeDialogType;
    employeeDialogIsOpenByType: { [x: number]: boolean; };
    openEmployeeDialog: (employeeDialogType: EmployeeDialogType) => void;
    closeEmployeeDialog: () => void;
    applyUpdateEmployeeItem: (updatedEmployeeItem: HeadCountInformation) => Promise<void>;
    applyAddEmployeeItem: (newEmployeeItem: HeadCountInformation) => Promise<void>;

    filterModel: GridFilterModel;
    onFilterModelChange?: (model: GridFilterModel, details: GridCallbackDetails) => void;


    tabIndex: number;
    setTabIndex: (tabIndex: number) => void;

    isModalOpen: boolean;
    toggleModal: () => void;
}

const initialEmployeeDialogIsOpenByType = {
    [EmployeeDialogType.DEFAULT]: false,
    [EmployeeDialogType.ADD]: false,
    [EmployeeDialogType.BULK_ADD]: false,
    [EmployeeDialogType.EDIT]: false,
    [EmployeeDialogType.BULK_EDIT]: false,
}

export const EmployeeListConext = createContext<EmployeeListConextProps>(null as any);

export const EmployeeListConextProvider = (props) => {
    const { selectedCompany } = useContext(AuthContext)
    const { headCountData, hc_LoaderStatus, showToastThenRefresh } = useContext(DataConext);

    const { appModuleType } = useContext(ModuleContext);
    const [selectedFlatRows, setSelectedFlatRows] = useState<readonly string[]>([]);

    const [duplicateDialogeOpen, setDuplicatDialogeOpen] = useState(false);
    const [deleteDialogeOpen, setDeleteDialogeOpen] = useState(false);

    const [employeeDialogType, setEmployeeDialogType] = useState<EmployeeDialogType>(EmployeeDialogType.DEFAULT);
    const [employeeDialogIsOpenByType, setEmployeeDialogIsOpenByType] = useState<{ [x: number]: boolean; }>(initialEmployeeDialogIsOpenByType);

    const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });


    const [onClickSelectedItem, setOnClickSelectedItem] = useState<HeadCountInformation | null>(null)
    const [tabIndex, setTabIndex] = useState(0);
    const [isModalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        setFilterModel({ items: [] })
    }, [selectedCompany]);

    useEffect(() => {
        if (tabIndex === 0) {
            setModalOpen(false);
        }
    }, [tabIndex]);

    const toggleModal = () => {
        setModalOpen(!isModalOpen)
    }

    const openEditDialoge = (id: string) => {
        const new_item = headCountData.find(headcount => id.toString() === headcount.id.toString())
        if (new_item) {
            setOnClickSelectedItem(new_item);
            setEmployeeDialogType(EmployeeDialogType.EDIT);
            setEmployeeDialogIsOpenByType({ ...initialEmployeeDialogIsOpenByType, [EmployeeDialogType.EDIT]: true })
        }
    }

    const closeDeleteDialoge = (apply: boolean) => {
        closeEmployeeDialog();
        setDeleteDialogeOpen(false)
        if (apply)
            applyDeleteEmployee()
    }
    const openDeleteDialoge = () => {
        setDeleteDialogeOpen(true)
    }
    const closeDuplicateDialoge = async (duplicate_num: number = 0) => {
        if (selectedCompany) {
            if (duplicate_num > 0 && selectedFlatRows.length > 0) {
                if (appModuleType === ModuleType.CUSTOM) {
                    // applyFlipChanges();
                    // setIsAutomaticLoading(true);
                }
                if (hc_LoaderStatus === LoaderStatus.Rendered) {
                    const result = await duplicateEmployee(selectedFlatRows, duplicate_num, selectedCompany);
                    if (result) {
                        setSelectedFlatRows([]);
                        const message = `${selectedFlatRows.length} Employee${selectedFlatRows.length > 1 ? 's' : ''} Duplicated Successfully.`;
                        showToastThenRefresh(message, duplicate_num * selectedFlatRows.length);
                    }
                    // else setIsLoading(false);
                }
            }
        }
        setDuplicatDialogeOpen(false);
    }
    const openDuplicateDialoge = () => {
        setDuplicatDialogeOpen(true)
    }
    const openEditBulkDialoge = () => {
        if (selectedFlatRows.length > 1) {
            setEmployeeDialogType(EmployeeDialogType.BULK_EDIT);
            setEmployeeDialogIsOpenByType({ ...initialEmployeeDialogIsOpenByType, [EmployeeDialogType.BULK_EDIT]: true });
        }
        else openEditDialoge(selectedFlatRows[0])
    }

    const openEmployeeDialog = (employeeDialogType: EmployeeDialogType) => {
        setEmployeeDialogType(employeeDialogType);
        setEmployeeDialogIsOpenByType({ ...initialEmployeeDialogIsOpenByType, [employeeDialogType]: true });

    }

    const closeEmployeeDialog = () => {
        setEmployeeDialogType(EmployeeDialogType.DEFAULT);
        setEmployeeDialogIsOpenByType(initialEmployeeDialogIsOpenByType);
        setOnClickSelectedItem(null);
    }

    const applyAddEmployeeItem = async (newEmployeeItem: HeadCountInformation) => {
        if (selectedCompany) {
            if (newEmployeeItem) {
                setSelectedFlatRows([]);
                // applyFlipChanges();
                // setIsAutomaticLoading(true)
                newEmployeeItem.schedule_raise_date = stringToDateConverter(newEmployeeItem.schedule_raise_date as any) as any
                newEmployeeItem.start_date = stringToDateConverter(newEmployeeItem.start_date as any) as any
                newEmployeeItem.end_date = newEmployeeItem.end_date ? stringToDateConverter(newEmployeeItem.end_date as any) : null as any;
                const result = await addEmployee(newEmployeeItem, selectedCompany)
                if (result.detail) {
                    newEmployeeItem = new HeadCountInformation(selectedCompany, newEmployeeItem)
                    const message = ` ${result.detail} ${newEmployeeItem.job_titleStr} Added Successfully.`
                    showToastThenRefresh(message, result.detail)
                } else {
                }
            }
        }
    }

    const applyDeleteEmployee = async () => {
        if (selectedCompany) {
            if (appModuleType === ModuleType.CUSTOM) {
                // applyFlipChanges()
                // setIsAutomaticLoading(true)
            }
            if (onClickSelectedItem) {
                const result = await deleteEmployee(onClickSelectedItem.id, selectedCompany)
                if (result) {
                    setOnClickSelectedItem(null);
                    const message = `${onClickSelectedItem.first_name ?? ""} Deleted Successfully.`
                    showToastThenRefresh(message)
                }
                //else setIsLoading(false);

            }
            else {
                if (selectedFlatRows.length > 0 && hc_LoaderStatus === LoaderStatus.Rendered) {
                    if (appModuleType === ModuleType.CUSTOM) {
                        // setUpdatingHeadcount(true)
                    }
                    const result = await deleteSelectedEmployeesList(selectedFlatRows, selectedCompany)
                    if (result) {
                        setSelectedFlatRows([]);
                        const message = `${selectedFlatRows.length} Employee${selectedFlatRows.length > 1 ? 's' : ''} Deleted Successfully.`
                        showToastThenRefresh(message)
                    }
                    // else setIsLoading(false);
                }
            }
            // setIsLoading(false);
        }

    };

    const applyUpdateEmployeeItem = async (updatedEmployeeItem: HeadCountInformation) => {
        if (selectedCompany) {
            if (updatedEmployeeItem) {
                closeEmployeeDialog();
                if (updatedEmployeeItem !== onClickSelectedItem) {
                    // applyFlipChanges();
                    // setIsAutomaticLoading(true);
                    // setIsLoading(true);
                    // setUpdatingHeadcount(true)
                    var result = await editEmployee(updatedEmployeeItem, selectedCompany);
                    if (result.id) {
                        result = new HeadCountInformation(selectedCompany, result)
                        const message = `${result.job_titleStr} Updated Successfully.`
                        showToastThenRefresh(message, 1);
                    } else {
                        // setIsLoading(false);
                    }
                }
            }
            setOnClickSelectedItem(null);
        }
    };

    const applyBulkEdit = async (updates) => {
        if (selectedCompany) {
            // setIsLoading(true);
            if (appModuleType === ModuleType.CUSTOM) {
                // applyFlipChanges()
                // setIsAutomaticLoading(true)
            }

            if (selectedFlatRows.length > 0 && hc_LoaderStatus === LoaderStatus.Rendered) {
                if (appModuleType === ModuleType.CUSTOM) {
                    // setUpdatingHeadcount(true)
                }
                const result = await updateSelectedEmployeesList(selectedFlatRows, selectedCompany, updates)
                if (result) {
                    showToastThenRefresh(result.detail, result.count)
                }
                // else setIsLoading(false);
            }
            // setIsLoading(false);
        }

    };

    const onFilterModelChange = (model: GridFilterModel, details: GridCallbackDetails) => {
        setFilterModel(model)
    };

    return (
        <EmployeeListConext.Provider value={{
            selectedFlatRows,
            setSelectedFlatRows,
            applyBulkEdit,
            onClickSelectedItem,
            setOnClickSelectedItem,

            duplicateDialogeOpen,
            closeDuplicateDialoge,
            openDuplicateDialoge,

            deleteDialogeOpen,
            closeDeleteDialoge,
            openDeleteDialoge,
            openEditDialoge,
            openEditBulkDialoge,
            showToastThenRefresh,
            employeeDialogType,
            employeeDialogIsOpenByType,
            openEmployeeDialog,
            closeEmployeeDialog,
            applyUpdateEmployeeItem,
            applyAddEmployeeItem,
            filterModel,
            onFilterModelChange,

            tabIndex, setTabIndex,
            isModalOpen, toggleModal,
        }}>
            {props.children}
        </EmployeeListConext.Provider>
    )
}

