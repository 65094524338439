import React, { useContext, useEffect, useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import { TextareaAutosize } from "@mui/material";
import CustomDatePicker from "../Common/CustomDatePicker";
import { dateFromString } from "../../utils/helpers";
import SelectComponent from "../Common/SelectComponent";
import { TaskAuthContext } from "../../contexts/Task/TaskAuthContext";
import ContextMenu from "../Common/DropDownComponent/ContextMenu";
import AssigneeMenu from "../Common/DropDownComponent/Assignee";
import { TaskTableContext } from "../../contexts/Task/TaskTableContext";
import { TaskCardContext } from "../../contexts/Task/TaskCardContext";

const SubTask = ({ subtask }) => {
  const { openSubTask, subTaskUpdateObj, setSubTaskUpdateObj, deleteSubTask, onDateChange, setActiveSubTask, activeSubTask } = useContext(TaskCardContext);
  const { setSubTasks, subTasks, setTimeAtLastTaskUpdate, taskDetail, subTasksObj, setSubTasksObj } = useContext(TaskTableContext);

  const updateSubTaskName = (e) => {
    const fieldName = e.target.name;
    const updatedSubTask = { ...subtask, [fieldName]: e.target.value };
    if (subTaskUpdateObj.hasOwnProperty("gid")) {
      setSubTaskUpdateObj({
        ...subTaskUpdateObj,
        gid: subtask.gid,
        [fieldName]: e.target.value,
      });
    } else {
      setSubTaskUpdateObj({
        gid: subtask.gid,
        [fieldName]: e.target.value,
      });
    }

    const updatedSubTasksArray = subTasks.slice().map((x) => (x.gid === updatedSubTask.gid ? updatedSubTask : x));
    setSubTasks(updatedSubTasksArray);
  };

  const onCompleteSubTask = () => {
    const newItem = { ...subtask };
    newItem.completed = !subtask.completed;
    if (subTaskUpdateObj.hasOwnProperty("gid")) {
      setSubTaskUpdateObj({
        ...subTaskUpdateObj,
        gid: subtask.gid,
        completed: newItem.completed,
      });
    } else {
      setSubTaskUpdateObj({
        gid: subtask.gid,
        completed: newItem.completed,
      });
    }

    const updatedSubTasksArray = subTasks.slice().map((subTask) => (subTask.gid === newItem.gid ? newItem : subTask));

    // update the subTaskObj

    const newSubTaskObj = { ...subTasksObj };
    if (newSubTaskObj[taskDetail?.gid]) {
      const subTaskArray = subTasksObj[taskDetail?.gid];
      let subTasks: any = [];
      if (subTaskArray.subTasks) {
        subTasks = subTaskArray.subTasks.map((x) => (x.gid === subtask.gid ? newItem : x));
      } else {
        subTasks = subTaskArray.map((x) => (x.gid === subtask.gid ? newItem : x));
      }

      newSubTaskObj[taskDetail?.gid] = { subTasks };
      setSubTasksObj(newSubTaskObj);
    }

    setSubTasks(updatedSubTasksArray);
  };

  const onAssigneeChange = (subtask) => {

    if (subTaskUpdateObj.hasOwnProperty("gid")) {
      setSubTaskUpdateObj({
        ...subTaskUpdateObj,
        gid: subtask.gid,
        assignee: subtask.assignee.gid,
      });
    } else {
      setSubTaskUpdateObj({
        gid: subtask.gid,
        assignee: subtask.assignee.gid,
      });
    }

    const updatedSubTasksArray = subTasks.slice().map((x) => (x.gid === subtask.gid ? subtask : x));

    // update the subTaskObj

    const newSubTaskObj = { ...subTasksObj };
    if (newSubTaskObj[taskDetail?.gid]) {
      const subTaskArray = subTasksObj[taskDetail?.gid];
      let subTasks: any = [];
      if (subTaskArray.subTasks) {
        subTasks = subTaskArray.subTasks.map((x) => (x.gid === subtask.gid ? subtask : x));
      } else {
        subTasks = subTaskArray.map((x) => (x.gid === subtask.gid ? subtask : x));
      }

      newSubTaskObj[taskDetail?.gid] = { subTasks };
      setSubTasksObj(newSubTaskObj);
    }

    setSubTasks(updatedSubTasksArray);
  };

  useEffect(() => {
    setTimeAtLastTaskUpdate(new Date().getTime());
  }, [subtask]);

  return (
    <div
      className={activeSubTask === subtask.gid ? "TaskCard__SubTasksContainer__SubTask active" : "TaskCard__SubTasksContainer__SubTask"}
      key={subtask?.gid}
      onClick={() => setActiveSubTask(subtask.gid)}
    >
      {activeSubTask === subtask.gid ? (
        <>
          <div className={subtask?.completed ? "completed" : "not-completed"}>
            {!subtask?.completed ? <CheckCircleOutlineIcon onClick={onCompleteSubTask} /> : <CheckCircleRoundedIcon onClick={onCompleteSubTask} />}
          </div>
          <TextareaAutosize
            name="name"
            value={subtask.name}
            onChange={updateSubTaskName}
            aria-label="empty textarea"
            placeholder="Insert subtask description ..."
            style={{ width: "100%", padding: 10 }}
            className="TaskCard__SubTasksContainer__SubTask__TextInput"
          />

          <div className="TaskCard__SubTasksContainer__SubTask__ButtonsContainer">
            <CustomDatePicker
              selected={subtask.due_on ? dateFromString(subtask.due_on) : null}
              onChange={(date: Date) => onDateChange(date, "due_on", subtask)}
              dateFormat="MM-dd-yyyy"
              showYearDropdown
              showMonthYearPicker={false}
            />
            <AssigneeMenu  task={subtask} setTask={onAssigneeChange} size={27} optionsSize={27}/>
            {/* <button className='btn--secondary-danger btn--XXXS--centered' onClick={() => deleteSubTask(subtask.gid)}><CloseIcon /></button> */}
            <button className="btn--XXS--centered btn--secondary-danger reduced-opacity" onClick={() => deleteSubTask(subtask.gid)}>
              <DeleteIcon />
            </button>
            <button className="btn--tertiary btn--XXS--centered reduced-opacity" onClick={() => openSubTask(subtask.gid)}>
              <ArrowForwardIcon />
            </button>
          </div>
        </>
      ) : (
        <>
          <div className={subtask?.completed ? "completed" : "not-completed"}>
            {!subtask?.completed ? <CheckCircleOutlineIcon onClick={onCompleteSubTask} /> : <CheckCircleRoundedIcon onClick={onCompleteSubTask} />}
          </div>
          <div className="TaskCard__SubTasksContainer__SubTask__TextInput ">{subtask.name}</div>
          <div className="TaskCard__SubTasksContainer__SubTask__ButtonsContainer single">
            <CustomDatePicker
              selected={subtask.due_on ? dateFromString(subtask.due_on) : null}
              onChange={(date: Date) => onDateChange(date, "due_on", subtask)}
              dateFormat="MM-dd-yyyy"
              showYearDropdown
              showMonthYearPicker={false}
            />
            <AssigneeMenu task={subtask} setTask={onAssigneeChange} size={27} optionsSize={27}  />
            <button className="btn--XXS--centered btn--secondary-danger reduced-opacity" onClick={() => deleteSubTask(subtask.gid)}>
              <DeleteIcon />
            </button>
            <button className="btn--tertiary btn--XXS--centered reduced-opacity" onClick={() => openSubTask(subtask.gid)}>
              <ArrowForwardIcon />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default SubTask;
