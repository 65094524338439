import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
export interface StyleProps {
    zoomLevel: string;
  }
export const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    root: {
      height: "calc(68vh - 250px)",
      width: "40rem",
      "& .AccountsNames": {
        justifyContent: "flex-start !important",
      },
      "& .ERROR": {
        background: "#F5C0B8",
      },
      "& .FIRST_PROJECTION_COL": {
        borderRight: "1px var(--Neutral500) dashed !important",
      },
      "& .HIGHLIGHT_SELECTED": {
        background: "#FFF500 !important",
      },
      "& .HIGHLIGHT": {
        background: "rgb(255,253,204) !important",
      },
      "& .period__header": {
        flexDirection: "column",
      },

      "& .MuiDataGrid-root": {
        backgroundColor: "#fff",
        border: "none",
        height: "100%",

        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: "bold",
          fontSize: ({ zoomLevel }) =>
            zoomLevel === "1"
              ? 6
              : zoomLevel === "2"
              ? 7
              : zoomLevel === "3"
              ? 8
              : zoomLevel === "4"
              ? 9
              : zoomLevel === "5"
              ? 10
              : zoomLevel === "6"
              ? 11
              : zoomLevel === "7"
              ? 12
              : zoomLevel === "8"
              ? 13
              : zoomLevel === "9"
              ? 14
              : 15,
          lineHeight: "16px",
          color: "#32324D",
        },
        "& .MuiDataGrid-virtualScroller": {
          marginTop: ({ zoomLevel }) =>
            (zoomLevel === "1"
              ? 35
              : zoomLevel === "2"
              ? 40
              : zoomLevel === "3"
              ? 45
              : zoomLevel === "4"
              ? 47
              : zoomLevel === "5"
              ? 60
              : zoomLevel === "6"
              ? 60
              : zoomLevel === "7"
              ? 60
              : zoomLevel === "8"
              ? 52
              : zoomLevel === "9"
              ? 56
              : 60) + "px !important",
        },
        "& .MuiDataGrid-iconSeparator": {
          height: "25px",
          borderLeft: "none",
        },
        "& .MuiDataGrid-columnHeader--sortable": {
          "& .MuiDataGrid-iconButtonContainer": {
            visibility: "visible",
            width: "auto",
            display: "none",
          },
        },
        "& .MuiDataGrid-columnHeader": {
          flexDirection: "column",
          paddingLeft:"0rem",
          // justifyContent: "center",
          // alignItems:"center",
          paddingTop: ({ zoomLevel }) =>
          (zoomLevel === "1"
              ? 0
              : zoomLevel === "2"
              ? 8
              : zoomLevel === "3"
              ? 10
              : zoomLevel === "4"
              ? 10
              : zoomLevel === "5"
              ? 12
              : zoomLevel === "6"
              ? 13
              : zoomLevel === "7"
              ? 14
              : zoomLevel === "8"
              ? 15
              : zoomLevel === "9"
              ? 16
              : 17) + "px !important",
          // paddingRight: "0.1rem",
          // paddingLeft: "2rem",
          height: ({ zoomLevel }) =>
          (zoomLevel === "1"
              ? 15
              : zoomLevel === "2"
              ? 30
              : zoomLevel === "3"
              ? 35
              : zoomLevel === "4"
              ? 40
              : zoomLevel === "5"
              ? 40
              : zoomLevel === "6"
              ? 45
              : zoomLevel === "7"
              ? 50
              : zoomLevel === "8"
              ? 55
              : zoomLevel === "9"
              ? 60
              : 65) + "px !important",
        },
        "& .Header__Actuals::before, .Header__Projections::before": {
          display: "block",
          height:"auto",
          width: "100%",
          textAlign:"center",
          lineHeight:0,
          color: "var(--Neutral500)",
            fontSize: ({ zoomLevel }) =>
            zoomLevel === "1"
              ? 4
              : zoomLevel === "2"
              ? 5
              : zoomLevel === "3"
              ? 6
              : zoomLevel === "4"
              ? 7
              : zoomLevel === "5"
              ? 8
              : zoomLevel === "6"
              ? 9
              : zoomLevel === "7"
              ? 10
              : zoomLevel === "8"
              ? 11
              : zoomLevel === "9"
              ? 12
              : 13,
        },
        "& .Header__Projections::before": {
          display: "block",
          height:"auto",
          width: "100%",
          textAlign:"center",
          lineHeight:0,
          color: "var(--Neutral500)",
            fontSize: ({ zoomLevel }) =>
            zoomLevel === "1"
              ? 4
              : zoomLevel === "2"
              ? 5
              : zoomLevel === "3"
              ? 6
              : zoomLevel === "4"
              ? 7
              : zoomLevel === "5"
              ? 8
              : zoomLevel === "6"
              ? 9
              : zoomLevel === "7"
              ? 10
              : zoomLevel === "8"
              ? 11
              : zoomLevel === "9"
              ? 12
              : 13,
        },
        "& .MuiDataGrid-columnHeaders": {
          flexDirection: "column",
          justifyContent: "center",
          alignItems:"flex-start",
          borderTop: "1px solid #32324D",
          borderBottom: "1px solid #32324D",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          display:"flex",

          height: ({ zoomLevel }) =>
          (zoomLevel === "1"
              ? 31
              : zoomLevel === "2"
              ? 33
              : zoomLevel === "3"
              ? 38
              : zoomLevel === "4"
              ? 40
              : zoomLevel === "5"
              ? 45
              : zoomLevel === "6"
              ? 45
              : zoomLevel === "7"
              ? 50
              : zoomLevel === "8"
              ? 50
              : zoomLevel === "9"
              ? 55
              : 55) + "px !important",
          minHeight: "15px !important",
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            height: ({ zoomLevel }) =>
          (zoomLevel === "1"
              ? 20
              : zoomLevel === "2"
              ? 22
              : zoomLevel === "3"
              ? 27
              : zoomLevel === "4"
              ? 32
              : zoomLevel === "5"
              ? 37
              : zoomLevel === "6"
              ? 42
              : zoomLevel === "7"
              ? 45
              : zoomLevel === "8"
              ? 48
              : zoomLevel === "9"
              ? 52
              : 56) + "px !important",
            flexDirection: "row",
            justifyContent: "center",
          },
          "& .MuiDataGrid-columnHeaderDraggableContainer": {
            flexDirection: "row",
          },
        },
        "& .MuiDataGrid-pinnedColumnHeaders": {
          height: "24px",
          boxShadow: "none",
        },
        "& .MuiDataGrid-pinnedColumns": {
          boxShadow: "none",
        },
      },
      "& .MuiDataGrid-footerContainer": {
        display: "none",
      },
      "& .MuiDataGrid-row": {
        "& :hover": {
          cursor: "pointer",
          background: "#EAEAEF",
        },
        "&.Mui-selected": {
          backgroundColor: "#FFF!important",
          "& :hover": {
            backgroundColor: "#FFF !important",
          },
        },
      },
      "& .MuiDataGrid-cell": {
        display: "flex",
        color: "#666687",
        fontSize: ({ zoomLevel }) =>
          zoomLevel === "1"
            ? 7
            : zoomLevel === "2"
            ? 8
            : zoomLevel === "3"
            ? 9
            : zoomLevel === "4"
            ? 10
            : zoomLevel === "5"
            ? 11
            : zoomLevel === "6"
            ? 12
            : zoomLevel === "7"
            ? 13
            : zoomLevel === "8"
            ? 14
            : zoomLevel === "9"
            ? 15
            : 16,
        borderLeft: "none",
        borderBottom: "none",
        justifyContent: "flex-end",
        // alignItems: "flex-start",
        "& span": {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          fontSize: ({ zoomLevel }) =>
            zoomLevel === "1"
              ? 7
              : zoomLevel === "2"
              ? 8
              : zoomLevel === "3"
              ? 9
              : zoomLevel === "4"
              ? 10
              : zoomLevel === "5"
              ? 11
              : zoomLevel === "6"
              ? 13
              : zoomLevel === "7"
              ? 14
              : zoomLevel === "8"
              ? 16
              : zoomLevel === "9"
              ? 18
              : 20,
        },
      },
      "& .MuiCheckbox-root": {
        color: "#C0C0CF",
      },
      "& .MuiCheckbox-root >svg": {
        border: "0px solid #FFFFFF",
        height: "20px",
        width: "20px",
      },
      "& .MuiCheckbox-root.Mui-checked": {
        color: "#85b440 !important",
      },
    },
  })
);