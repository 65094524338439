import { Link, Preview } from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import { createAsanaSubTask, createAsanaTaskComment, retrieveAsanaSubTask, updateAsanaTask } from "../../../../APIFunctions/Asana";
import { useCountdown } from "../../../../hooks/useCountdown";
import { addHours, asanaTimeToHoursMinutes, dateObjToAsanaString } from "../../../../utils/DateFunctions";
import MDIcons from "../../../Common/MDIcons";
import green_check_gif from "../../../../static/gif/check-green.gif";
import paper_rocket_gif from "../../../../static/gif/paper-rocket.gif";
import AlertDialog from "../../../Common/AlertDialog";
import { LinearProgress, TextareaAutosize } from "@mui/material";
import { getPayrollFileLink } from "../../../../APIFunctions/ticketing_system";
import { payrollLocation, payrollPlatform } from "../../../../types/TicketingSystem";
import { AuthContext } from "../../../../contexts/AuthContext";
import { PayrollContext } from "../../../../contexts/TicketingSystem/PayrollContext";
import { MilestoneContext } from "../../../../contexts/TicketingSystem/MilestoneContext";
import { getPayrollTaskType, PayrollTaskType } from "../../../Tasks/utils";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { MilestoneEmailContext } from "../../../../contexts/TicketingSystem/MilestoneEmailContext";
import { convertLegacyLinkToMilestoneLink } from "../../Components/Links/Util";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      boxShadow: "none",
      border: "none !important",
      height: "calc(100vh - 52rem)",
      "& .MuiTableCell-stickyHeader": {
        color: "var(--Neutral600) !important",
        fontWeight: "400 !important",
      },
      "& .MuiTableCell-root": {
        color: "#212134",
        fontSize: 14,
        height: "4.5rem",
        borderBottom: "1px solid #EAEAEF",
        justifyContent: "flex-start",
        "& span": {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
        "& svg": {
          marginRight: "1rem",
          "& path": {
            fill: "var(--Success500)",
          },
        },
      },
    },
  })
);

const ClientPayrollCard = ({ milestone, setClientReviewMilestoneGID, setShowQA, immediateAction }) => {

  const { selectedCompany } = useContext(AuthContext);
  const { setPayrollLoading } = useContext(MilestoneContext);
  const { setSharepointLinkInEmail,email } = useContext(MilestoneEmailContext)
  const { payrollTypeID } = useContext(PayrollContext);
  const [currentStage, setCurrentStage] = useState<any>({});
  const [subTasks, setSubTasks] = useState<any[]>([]);
  const [currentStageIndex, setCurrentStageIndex] = useState<number>(0);
  const [payrollFileLink, setPayrollFileLink] = useState<string>("");
  const [showCompleteSuccess, setShowCompleteSuccess] = useState<boolean>(false);
  const [showRevisionRequestSuccess, setShowRevisionRequestSuccess] = useState<boolean>(false);
  const [showRevisionInput, setShowRevisionInput] = useState<boolean>(false);
  const [revisionText, setRevisionText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const Platform = milestone?.custom_fields.find((x) => x.name === "Platform")?.display_value;
  const Type = milestone?.custom_fields.find((x) => x.name === "Type")?.display_value;
  const Location = milestone?.custom_fields.find((x) => x.name === "Location")?.display_value;
  const DueDate = milestone?.due_on;
  const DueTime = milestone?.custom_fields.find((x) => x.name === "Due Time")?.display_value;
  const DueDateWithTime = addHours(new Date(DueDate), asanaTimeToHoursMinutes(DueTime));
  const Start_Date = milestone?.custom_fields.find((x) => x.name === "Payroll Cycle Start Date")?.date_value?.date;

  const progressBarTheme = createTheme({
    components: {
      // Name of the component
      MuiLinearProgress: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            backgroundColor: "#E5E5E5",
            span: {
              backgroundColor: "#85b440 !important",
            },
          },
        },
      },
    },
  });


  const End_Date = milestone?.custom_fields.find((x) => x.name === "Payroll Cycle End Date")?.date_value?.date;

  const [days, hours, minutes, seconds] = useCountdown(DueDateWithTime);

  const getFileLink = async () => {
    if (selectedCompany && payrollTypeID) {
      setPayrollLoading(true);
      const response = await getPayrollFileLink(payrollTypeID);
      
      if (response) {
        // todo: update SharepointLinkInEmail should follow new 'Milestone Link' structure
        setPayrollFileLink(response.link);
        
        const link = convertLegacyLinkToMilestoneLink(response.id, email?.id,response.file_name,response.link,response.sharepoint_id, "","","Excel Backup Payroll File",2)
        setSharepointLinkInEmail(link)
      }
      setPayrollLoading(false);

    }
  };

  const getCurrentStage = async () => {
    const response = await retrieveAsanaSubTask(milestone.gid);
    if (response) {
      if (response.data) {
        let nextIncompleteSubtask: any = null;
        let clientReviewSubtask: any = null;
        let nextIncompleteIndex: any = null;
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].completed === false && nextIncompleteSubtask === null && nextIncompleteIndex == null) {
            nextIncompleteSubtask = response.data[i];
            nextIncompleteIndex = i;
          }
          if (getPayrollTaskType(response.data[i].name) === PayrollTaskType.CLIENT_REVIEW) {
            clientReviewSubtask = response.data[i];
            setClientReviewMilestoneGID((prev) => {
              return { ...prev, [milestone.gid]: clientReviewSubtask.gid };
            });
          }
        }
        setCurrentStageIndex(nextIncompleteIndex);
        setCurrentStage(nextIncompleteSubtask);
        setSubTasks(response.data);
        const currentStage = nextIncompleteSubtask;
        if (currentStage?.name === "Client Review") {
          if (immediateAction === "Reject") {
            // discuss with Mihail: if i don't remove 'reject' i end up with the action happening twice
            const current_url = window.location.href;
            if (current_url.includes("Reject")) {
              const newURL = current_url.slice(0, -7);
              window.history.pushState("object or string", "Payroll", newURL);
              onRejectionClick();
            }
          } else if (immediateAction === "Approve") {
            const current_url = window.location.href;
            if (current_url.includes("Approve")) {
              const newURL = current_url.slice(0, -8);
              window.history.pushState("object or string", "Payroll", newURL);
              const newItem = { ...currentStage };
              newItem.completed = !currentStage.completed;
              currentStage.completed = newItem.completed;
              updateAsanaTask({
                gid: currentStage.gid,
                completed: newItem.completed,
              });
              setShowCompleteSuccess(true);
              setTimeout(function () {
                setShowCompleteSuccess(false);
              }, 5000);
              // set current stage to the next subtask in the list
              setCurrentStage(subTasks[currentStageIndex + 1]);
            }
          }
        }
      }
    }
  };

  const onSubmitRequestRevision = async () => {
    setLoading(true);

    const prevStage = { ...subTasks[currentStageIndex - 1] };
    let prevStageAssignee: any = null;
    if (prevStage.assignee) {
      prevStageAssignee = {
        name: prevStage.assignee.name,
        gid: prevStage.assignee.gid,
      };
    }
    const today = dateObjToAsanaString(new Date())
    const newObj = {
      data: {
        name: "Revision Requested",
        notes: revisionText,
        assignee: prevStageAssignee,
        due_on: today,
      },
    };

    const response = await createAsanaSubTask(currentStage.gid, newObj);

    if (response.data) {
      setRevisionText(" ");
      // todo:  setTaskCardLoading(false);
    } else if (response.response?.status === 401) {
      setTimeout(() => {
        onSubmitRequestRevision();
      }, 1000);
    }
    
    if (currentStageIndex > 0) {
      prevStage.completed = false;
      setCurrentStage(prevStage);
      updateAsanaTask({
        gid: prevStage.gid,
        completed: prevStage.completed,
        due_on:today
      });
    }
    setLoading(false);
    setShowRevisionInput(false);
    setShowRevisionRequestSuccess(true);
    setTimeout(function () {
      setShowRevisionRequestSuccess(false);
    }, 5000);
  };

  const onComplete = () => {
    if (currentStage?.gid) {
      const newItem = { ...currentStage };
      newItem.completed = !currentStage.completed;
      currentStage.completed = newItem.completed;
      updateAsanaTask({
        gid: currentStage.gid,
        completed: newItem.completed,
      });
    }
    setShowCompleteSuccess(true);
    setTimeout(function () {
      setShowCompleteSuccess(false);
    }, 5000);
    // set current stage to the next subtask in the list
    setCurrentStage(subTasks[currentStageIndex + 1]);
  };

  const onRejectionClick = () => {
    setShowRevisionInput(true);
    const prevStage = { ...subTasks[currentStageIndex - 1] };
  };

  useEffect(() => {
    getCurrentStage();
    getFileLink();
  }, []);

  return (
    <>
      {!showCompleteSuccess && !showRevisionRequestSuccess ? (
        <div className="ClientPayrollCard__Container">
          <div className="ClientPayrollCard__Container__Header--CenteredTitle">
            {getPayrollTaskType(currentStage?.name) === PayrollTaskType.CLIENT_REVIEW && (
              <div className="ClientPayrollCard__Container__Header__Title--CenteredTitle">Waiting for your Approval</div>
            )}

            <div className="ClientPayrollCard__Container__Header__FileLink">
              <a href={payrollFileLink} rel="noreferrer" target="_blank">
                <Link />
                <div>Payroll Backup File</div>
              </a>
            </div>
          </div>

          <div className="countDown__container">
            <div className="countDown__container__unit">
              <span className="countDown__container__unit__value">{days}</span>
              <span className="countDown__container__unit__label">Days</span>
            </div>
            <div className="countDown__container__unit">
              <span>:</span>
            </div>
            <div className="countDown__container__unit">
              <span className="countDown__container__unit__value">{hours}</span>
              <span className="countDown__container__unit__label">Hours</span>
            </div>
            <div className="countDown__container__unit">
              <span>:</span>
            </div>
            <div className="countDown__container__unit">
              <span className="countDown__container__unit__value">{minutes}</span>
              <span className="countDown__container__unit__label">Minutes</span>
            </div>
            <div className="countDown__container__unit">
              <span>:</span>
            </div>
            <div className="countDown__container__unit">
              <span className="countDown__container__unit__value">{seconds}</span>
              <span className="countDown__container__unit__label">Seconds</span>
            </div>
          </div>

          <div className="ClientPayrollCard__Stage">
            <div className={getPayrollTaskType(currentStage?.name) === PayrollTaskType.CLIENT_REVIEW ? "dot__blue" : "dot"}></div>
            <div className="ClientPayrollCard__Stage__Name">{currentStage?.name}</div>
            {getPayrollTaskType(currentStage?.name) === PayrollTaskType.CLIENT_REVIEW ? (
              <img className="AsanaPhoto" src={currentStage?.assignee?.photo?.["image_27x27"]} alt="" />
            ) : (
              <div className="AsanaPhoto border">
                <MDIcons title="MD_Logo_21x21" />
              </div>
            )}
          </div>

          <div className="ClientPayrollCard__Container__Table__Container">
            <table>
              <tbody>
                <tr>
                  <td className="left">
                    <div className="table__cell">
                      <div className="table__cell__label">Platform</div>
                      <div className="table__cell__value">{Platform ? Platform : "--"}</div>
                    </div>
                  </td>
                  <td>
                    <div className="table__cell">
                      <div className="table__cell__label">Location</div>
                      <div className="table__cell__value">{Location ? Location : "--"}</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="left">
                    <div className="table__cell">
                      <div className="table__cell__label">Type</div>
                      <div className="table__cell__value">{Type ? Type : "--"}</div>
                    </div>
                  </td>
                  <td>
                    <div className="table__cell">
                      <div className="table__cell__label">Due Date</div>
                      <div className="table__cell__value">
                        {DueDate + " "} {DueTime ? DueTime : ""}{" "}
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="left">
                    <div className="table__cell">
                      <div className="table__cell__label">Start Date</div>
                      <div className="table__cell__value">{Start_Date ? Start_Date : "--"}</div>
                    </div>
                  </td>
                  <td>
                    <div className="table__cell">
                      <div className="table__cell__label">End Date</div>
                      <div className="table__cell__value">{End_Date ? End_Date : "--"}</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* card footer */}
          <div className="ClientPayrollCard__Container__Footer">
            <button className="btn--tertiary Button__QA btn--S flex-row--S" onClick={() => setShowQA((prev) => !prev)}>
              Q&A
            </button>
            {currentStage?.completed && currentStage?.name === "Client Review" ? (
              <button
                className="btn--secondary--completed btn--tertiary btn--S flex-row--S"
                disabled={getPayrollTaskType(currentStage?.name) !== PayrollTaskType.CLIENT_REVIEW}
                onClick={onComplete}
              >
                Approved
              </button>
            ) : (
              <button
                id=""
                className="btn--tertiary btn--S flex-row--S success "
                disabled={getPayrollTaskType(currentStage?.name) !== PayrollTaskType.CLIENT_REVIEW}
                onClick={onComplete}
              >
                Approve
              </button>
            )}

            <button
              id="danger"
              className="btn--tertiary btn--S flex-row--S"
              disabled={getPayrollTaskType(currentStage?.name) !== PayrollTaskType.CLIENT_REVIEW}
              onClick={onRejectionClick}
            >
              Request Revision
            </button>
          </div>
        </div>
      ) : showCompleteSuccess ? (
        <div className="ClientPayrollCard__Container" style={{ height: "100%" }}>
          <img src={green_check_gif} alt="success" style={{ height: "100%", transform: "translateY(0rem)" }} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
              marginTop: "22rem",
            }}
          >
            <span>Approved!</span>
            <span>Good Job</span>
          </div>
        </div>
      ) : (
        <div className="ClientPayrollCard__Container">
          <img src={paper_rocket_gif} alt="success" />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
              marginTop: "22rem",
            }}
          >
            <span>Revision Requested!</span>
            <span>Thanks for your feedback</span>
          </div>
        </div>
      )}

      <AlertDialog
        open={showRevisionInput}
        onClose={() => setShowRevisionInput(false)}
        title="Request Revision"
        dialogType="user-form"
        dialogActions={
          <div className="flex-row-space-between">
            <button className="btn--tertiary btn--S flex-row--S">Cancel</button>
            <button className="btn--primary btn--S flex-row--S" onClick={onSubmitRequestRevision}>
              <span>Send</span>
            </button>
          </div>
        }
      >
        {loading && (
          <ThemeProvider theme={progressBarTheme}>
            <LinearProgress />
          </ThemeProvider>
        )}
        <div className="RequestRevision__Content__Container">
          <h2>Please provide more details</h2>
          <TextareaAutosize
            aria-label="empty textarea"
            placeholder="Insert a new comment ..."
            style={{
              width: "100%",
              padding: 10,
              minHeight: "14.8rem",
              border: "1px solid var(--Neutral200)",
              borderRadius: "4px",
            }}
            className="TaskCard__Description"
            value={revisionText}
            name="notes"
            onChange={(e) => setRevisionText(e.target.value)}
          />
        </div>
      </AlertDialog>
    </>
  );
};

export default ClientPayrollCard;
