import { Company } from "./Company";
import { LocalType } from "./LocalType";

export class UserProfile extends LocalType<UserProfile>{

    copyingErrorHappen:boolean = false

    id: number = 0;
    email: string = "";
    username: string = "";
    first_name: string = "";
    last_name: string = "";
    is_superuser: boolean = false;
    completed_profile: boolean = false;
    complete_companies: Partial <Company>[] = [];
    onboarding_companies: Partial <Company>[] = [];
    allCompanies: Company[] = [];
    asana_user_id:string="";


    // companies: Company[] = [];
    

    constructor(obj: UserProfile | null = null) {
        super("UserProfile")
        if (obj) {
            Object.keys(obj).forEach(key => {
                try {
                    if (key in this)
                    this[key] = obj[key]
                } catch (error) {
                    this.copyingErrorHappen = true
                }

                
            })
        }
    }

    public static getLocalStorageObj(): UserProfile{
        var newObj = new UserProfile().getLocalObject()
        return  newObj ? newObj : new UserProfile()
    }
    
    public get companies(){
        if(this.allCompanies.length === 0) {
            this.allCompanies = []
            this.complete_companies.forEach(element => {
                var item = new Company(element)
                item.is_Onboarding = false
                    this.allCompanies.push(item)
            });

            this.onboarding_companies.forEach(element => {
                var item = new Company(element)
                item.is_Onboarding = true
                this.allCompanies.push(item)
            });
        
            this.allCompanies.sort((a,b) => {
            let fa = a.companyName?.toLowerCase(),
            fb = b.companyName?.toLowerCase();

            if (fa <fb) {
                return -1;
            } else if (fa > fb) {
                return 1;
            }
            return 0;
        })
    }
        return this.allCompanies
    }

    getInitials = () => {
        if (this.first_name?.length > 0 && this.last_name?.length > 0) {
            return this.first_name[0] + " " + this.last_name[0] + " "
        }
        else {
            return this.email[0] + " " + this.email[1] + " "
        }
    }
    getFirstCompany(): Company | null {
        if (this.companies.length > 0) {
            return new Company(this.companies[0])
        }
        else {
            return null
        }
    }

}