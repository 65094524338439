import { CodeResponse, GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import APIManger from "../../../APIManger";
import { GoogleInvitationCompleteProfileAPI } from "../../../types/API/authData";
import MDIcons from "../../Common/MDIcons";

export default function MyGoogleRegister({ GoogleRegister }) {
  // const validateTokenAndObtainSession = async (data, idToken) => {
  //   const token = await APIManger.post("accounts/google-signup/", { data, idToken });
  //   return token;
  // };

  const signup = useGoogleLogin({
    onSuccess: async (response) => {
      // discuss with Mihail: chain this request to another request to the backend so we can find the ...
      // user based on the email, issue a token, and return the token and set it in the local storage
      try {
        let data: any = null;
        const res = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: {
            Authorization: `Bearer ${response.access_token}`,
          },
        });
        data = res.data;
        if (data) {
          const completeProfileData: GoogleInvitationCompleteProfileAPI = {
            first_name: data.given_name,
            last_name: data.family_name,
            email:data.email
          };
          GoogleRegister(completeProfileData, response.access_token);
        }
      } catch (err) {
        console.log(err);
      }
    },
  });

  return (
    <>
      <button onClick={() => signup()}>
      <MDIcons title="google_button_icon" />
        Register with Google
      </button>
    </>
  );
}
