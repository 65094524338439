import React, { useContext, useEffect, useState } from "react";
import { TaskTableContext } from "../../../../contexts/Task/TaskTableContext";
import { asanaTaskDateStringToDate } from "../../../../utils/DateFunctions";
import ClientPayrollCard from "./ClientPayrollCard";
import Carousel from "react-material-ui-carousel";
import { Paper, Button, TableContainer, Table, TableHead, TableCell, TableBody, TableRow } from "@mui/material";
import MDIcons from "../../../Common/MDIcons";
import { createStyles, makeStyles } from "@mui/styles";
import ClientReviewQA from "./ClientReviewQA";
import "./ClientView.css";
import { PayrollContext } from "../../../../contexts/TicketingSystem/PayrollContext";
import { MilestoneContext } from "../../../../contexts/TicketingSystem/MilestoneContext";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      boxShadow: "none",
      border: "none !important",
      height: "calc(100vh - 52rem)",
      "& .MuiTableCell-stickyHeader": {
        color: "var(--Neutral600) !important",
        fontWeight: "400 !important",
      },
      "& .MuiTableCell-root": {
        color: "#212134",
        fontSize: 14,
        height: "4.5rem",
        borderBottom: "1px solid #EAEAEF",
        justifyContent: "flex-start",
        "& span": {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
        "& svg": {
          marginRight: "1rem",
          "& path": {
            fill: "var(--Success500)",
          },
        },
      },
    },
  })
);

const ClientViewComponent = ({ milestone_gid, immediateAction, setDisplayListFlag }) => {
  const classes = useStyles();
  const { setCurrentMilestone,currentMilestoneIndex } = useContext(MilestoneContext)
  const { completedMilestones} = useContext(PayrollContext)
  const { upcomingMilestones } = useContext(PayrollContext);
  const [showQA, setShowQA] = useState<boolean>(false);
  const [updatedUpcomingMilestones, setUpdatedUpcomingMilestones] = useState<any[]>([]);

  

  const [clientReviewMilestoneGID, setClientReviewMilestoneGID] = useState<any>({});

  const getPlatform = (milestone) => {
    return milestone?.custom_fields.find((x) => x.name === "Platform")?.display_value;
  };
  const getLocation = (milestone) => {
    return milestone?.custom_fields.find((x) => x.name === "Location")?.display_value;
  };

  useEffect(() => {
    setDisplayListFlag(false)
    if (milestone_gid && upcomingMilestones?.length > 0) {
      const newMilestonesArray = upcomingMilestones.sort((a, b) => (a.gid === milestone_gid ? -1 : 1));
      setUpdatedUpcomingMilestones(newMilestonesArray);
    } else {
      setUpdatedUpcomingMilestones(upcomingMilestones);
    }
  }, [milestone_gid, upcomingMilestones]);

  return (
    <div className="Payroll__ClientView__TopContainer">
      <div className="Payroll__ClientView__TopContainer__CenterContainer">
        <div className="Payroll__ClientView__TopContainer__CenterContainer__Title">Your Next Payroll</div>
        <Carousel
          className="Payroll__ClientView__Carousel"
          prev={(prev, active) => setCurrentMilestone(prev || 0)}
          next={(next, active) => setCurrentMilestone(next || 0)}
          NextIcon={<MDIcons title="next" />}
          PrevIcon={<MDIcons title="back" />}
          autoPlay={false}
          cycleNavigation
          animation="fade"
        >
          {updatedUpcomingMilestones?.map((milestone) => {
            return (
              <>
                <ClientPayrollCard key={milestone.gid} milestone={milestone} setClientReviewMilestoneGID={setClientReviewMilestoneGID} setShowQA={setShowQA} immediateAction={immediateAction} />
              </>
            );
          })}
        </Carousel>
        {currentMilestoneIndex !== undefined && showQA && updatedUpcomingMilestones?.length > 0 && updatedUpcomingMilestones?.[currentMilestoneIndex] && (
          <ClientReviewQA task_gid={clientReviewMilestoneGID[updatedUpcomingMilestones[currentMilestoneIndex]?.gid]} />
        )}

        {!showQA && completedMilestones?.length > 0  && (
          <>
            <div className="Payroll__ClientView__TopContainer__CenterContainer__Title">Your Payroll History</div>
            <div>
              <TableContainer component={Paper} sx={{ border: "none" }} className={classes.root}>
                <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Platform</TableCell>
                      <TableCell align="left">Location</TableCell>
                      <TableCell align="left">Due Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {completedMilestones.map((row) => (
                      <TableRow
                        key={row.gid}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">
                          <MDIcons title="checkbox_assignee" /> {getPlatform(row)}
                        </TableCell>
                        <TableCell align="left">{getLocation(row)}</TableCell>
                        <TableCell align="left">{row.due_on}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ClientViewComponent;
