import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getBSTransactionsData, getPLTransactionsData } from "../Components/MilestoneTaskComponent/Financials/helpers/transactions";
import { useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";

export const usePLTransactionsReport = (startDate, endDate) => {
  const { selectedCompany } = useContext(AuthContext);

  const queryClient = useQueryClient()
  const currentSyncInformation:any =queryClient.getQueryData(["current-sync-info"]) 
  
  return useQuery({
    queryKey: ["PLTransactionsReportData",startDate.toString(),endDate.toString()],
    queryFn: () => getPLTransactionsData(selectedCompany, startDate, endDate),
    staleTime: 60 * 60 * 1000,
    enabled: !!(startDate && endDate && currentSyncInformation?.status === "Idle" && currentSyncInformation !== undefined)
  });
};

export const useBSTransactionsReport = (startDate, endDate) => {
  const queryClient = useQueryClient()
  const currentSyncInformation:any =queryClient.getQueryData(["current-sync-info"]) 
  const { selectedCompany } = useContext(AuthContext);
  return useQuery({
    queryKey: ["BSTransactionsReportData",startDate.toString(),endDate.toString()],
    queryFn: () => getBSTransactionsData(selectedCompany, startDate, endDate),
    staleTime: 60 * 60 * 1000,
    enabled: !!(startDate && endDate && currentSyncInformation?.status === "Idle" && currentSyncInformation !== undefined)
  });
};

export const useDrilledTransactions = (reqBody) => {
  const { selectedCompany } = useContext(AuthContext);
  // return useQuery(['PLTransactionsData'] , () => ge(reqBody))
};
