import React, { useContext, useEffect, useState } from "react";
import AlertDialog from "../AlertDialog";
import { addSharePointFolder, listSharePointChildren, renameSharePointItem } from "../../../APIFunctions/graph_api";
import LoadingBar from "../LoadingBar";
import MDIcons from "../MDIcons";
import ItemsList from "./ItemsList";
import { AuthContext } from "../../../contexts/AuthContext";
import { showToast } from "../../../utils/toastHelpers";
import FolderPath from "./FolderPath";

const SharepointExplorer = ({
  onCloseBrowser,
  displaySharepointBrowser,
  setDisplaySharepointBrowser,
  setSaveFolder,
  saveFolder,
  exportToExcel,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [currentSharepointFolderID, setCurrentSharepointFolderID] = useState<string>("root");
  const [folderChildren, setFolderChildren] = useState<any>([]);
  const [prevLevelID, setPrevLevelID] = useState<string>("root");
  const [selected, setSelected] = useState<any>(saveFolder);
  const [displayedFolderPath, setDisplayedFoldePath] = useState<string[]>([]);
  const [renameValue, setRenameValue] = useState<string>("");
  const { selectedCompany } = useContext(AuthContext)
  const [newFileName, setNewFileName] = useState<string>(`${selectedCompany?.companyName}_QBO_Report.xlsx`)

  const getFolderChildren = async () => {
    setLoading(true);
    if (selectedCompany) {
      const response = await listSharePointChildren(selectedCompany, currentSharepointFolderID);
      setFolderChildren(response["folder_children"]["value"]);
      if (response["parent_folder"]) {
        setPrevLevelID(response["parent_folder"]["parentReference"]["id"]);

        const fullPath = response["parent_folder"]["parentReference"]["path"] + "/" + response["parent_folder"]["name"];
        const pathArray = fullPath.split("/");
        // remove everything before the company name and the company name
        const companyNamePosition = pathArray.indexOf(selectedCompany.companyName);
        if (companyNamePosition !== -1) {
          const trimmedPath = pathArray.slice(companyNamePosition + 1);
          setDisplayedFoldePath(trimmedPath);
        }
        setSelected(response["parent_folder"]);
      }
    }
    setLoading(false);
  };

  const addNewFolder = async () => {
    setLoading(true);
    if (selectedCompany) {
      const response = await addSharePointFolder(selectedCompany, currentSharepointFolderID, "New Folder");
      setFolderChildren([...folderChildren, { ...response["new_folder"], new: true }]);
      setSelected(response["new_folder"]);
      setRenameValue(response["name"]);
    }
    setLoading(false);
  };

  const renameItem = async (selectedItemID: any, e: any) => {
    setLoading(true);
    if (selectedCompany) {
      const value = e.target.value.replace("."," ").replace("/"," ").replace(":"," ")
      const response = await renameSharePointItem(selectedCompany, selectedItemID, value);
      if (response["renamed_item"]) {
        const newChildrenArray = folderChildren.map((x) => (x.id === response["renamed_item"]["id"] ? response["renamed_item"] : x));
        setFolderChildren(newChildrenArray);
        setSelected(response["renamed_item"]);
        setRenameValue("");
      }
    }

    setLoading(false);
  };

  const renameItemOnEnter = (selectedItemID: any, e: any) => {
    if (e.keyCode === 13) {
      renameItem(selectedItemID, e);
    }
  };

  const renameItemOnBlur = (selectedItemID: any, e: any) => {
    renameItem(selectedItemID, e);
  };

  const openFile = (item) => {
    window.open(item.webUrl, "_blank", "noreferrer");
  };

  const drillDown = (item) => {
    if (item["folder"]) {
      if (currentSharepointFolderID === "root") {
        setPrevLevelID("root");
      } else {
        if (!(prevLevelID === "root")) {
          setPrevLevelID(item["parentReference"]["id"]);
        }
      }

      setCurrentSharepointFolderID(item.id);
    }
  };

  const goBack = () => {
    setCurrentSharepointFolderID(prevLevelID);
  };

  const onSaveToFolder = async () => {
    setSaveFolder(selected);
    setDisplaySharepointBrowser(false);
    const newFile = await exportToExcel(selected,newFileName);
    if ('error' in newFile) {
      showToast("Error", "We couldn't generate the file for you.");
    }
  };

  useEffect(() => {
    getFolderChildren();
  }, [currentSharepointFolderID, selectedCompany]);

  return (
    <AlertDialog
      onClose={onCloseBrowser}
      open={displaySharepointBrowser}
      title="Sharepoint Browser"
      dialogType="user-form"
      dialogActions={
        <div className="action_btns_container">
          <button className={"btn--tertiary btn--M"} onClick={onCloseBrowser} autoFocus>
            <span>Cancel</span>
          </button>
          {selected && (
            <div className="action_btns_container--right">
              <button disabled={!selected} className="btn--tertiary btn--M" onClick={() => setRenameValue(selected?.["name"])} autoFocus>
                <span>Rename</span>
              </button>
              <button disabled={!("folder" in selected)} className="btn--tertiary btn--M" onClick={addNewFolder} autoFocus>
                <span>New Folder</span>
              </button>
              <button disabled={!("folder" in selected)} className="btn--primary btn--M" onClick={onSaveToFolder} autoFocus>
                <span>Save to folder</span>
              </button>
            </div>
          )}
        </div>
      }
    >
      <LoadingBar loading={loading} />
      <div className="SharepointExplorer__Header">
        {displayedFolderPath?.length > 0 && (
          <button onClick={goBack} className="btn--tertiary btn--S no-borders-no-background Black-Button">
            <MDIcons title="Header__Back__Button" />
          </button>
        )}
        <FolderPath folderPathArray={displayedFolderPath} />
      </div>

      <div className="SharepointExplorer__Container">
        <ItemsList
          list={folderChildren}
          drillDown={drillDown}
          selected={selected}
          setSelected={setSelected}
          openFile={openFile}
          renameValue={renameValue}
          setRenameValue={setRenameValue}
          renameItemOnEnter={renameItemOnEnter}
          renameItemOnBlur={renameItemOnBlur}
        />
      </div>
      <div className="SharepointExplorer__FileUpLoadNaming__Container">
        <span>File Name</span>
        <input placeholder="insert the name of the new financial report file you're about to create." value={newFileName} onChange={(e) => setNewFileName(e.target.value)}/>
        </div>
    </AlertDialog>
  );
};

export default SharepointExplorer;
