import { ReactNode, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { AsanaTask } from "../../../types/AsanaTask";
import { asanaTaskDateStringToDate, dateObjToAppString, getMonthShort } from "../../../utils/DateFunctions";
import MDIcons from "../../Common/MDIcons";
import SectionTitleIcons from "../../Common/SectionTitleIcons";
import defaultCompany from "../../../static/img/default_company.png"
import { MilestoneContext } from "../../../contexts/TicketingSystem/MilestoneContext";


export interface MilestoneTitleProps {
  title: string;
  milestone?: AsanaTask | null;
}
export default function MilestoneTitle({ title, milestone = null }: MilestoneTitleProps) {

  const { selectedCompany } = useContext(AuthContext);
  const { milestoneType } = useContext(MilestoneContext)

  const milestoneStartDate = milestone?.custom_fields?.find(x => x.name === "Payroll Cycle Start Date")?.date_value?.date
  const milestoneEndDate = milestone?.custom_fields?.find(x => x.name === "Payroll Cycle End Date")?.date_value?.date
  const location = milestone?.custom_fields?.find(x => x.name === "Location")?.enum_value?.name
  const platform = milestone?.custom_fields?.find(x => x.name === "Platform")?.enum_value?.name
  const type = milestone?.custom_fields?.find(x => x.name === "Type")?.enum_value?.name  
  const period = milestone?.custom_fields?.find(x => x.name === "Period")?.date_value?.date
  const periodDate = asanaTaskDateStringToDate(period)

  return (
    <div className="title">
      <SectionTitleIcons title={title} />
      <span className="title__text--mid">{title}</span>
      {milestone && (
        <div className="title__Milestone__Container">
          <MDIcons title="filter_dropdown_icon_right" />
          <img className="company-icon-header" src={selectedCompany?.companyAvatarURL ? selectedCompany.companyAvatarURL : defaultCompany} alt="" />
          <span>{milestone.name.split(":")[0]}:</span>
          <>
          {milestoneType === 0 ? 
            <span>{platform} {location} {type} Payroll - {dateObjToAppString(asanaTaskDateStringToDate(milestoneStartDate),"/")} - {dateObjToAppString(asanaTaskDateStringToDate(milestoneEndDate),"/")}</span>
            :
            <span>{period ? getMonthShort(periodDate) : milestone?.name.split(": ")[1]}</span>}
            </>
          
          
        </div>
      )}
    </div>
  );
}