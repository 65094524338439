

import { ReactComponent as resolve_comment } from '../img/resolve_comment.svg';
import { ReactComponent as more_horizontal } from '../img/more-horizontal.svg';


const TicketingSystemIconsList = {
    "resolve_comment":resolve_comment,
    "more_horizontal":more_horizontal
}

interface TicketingSystemIconsProps {
    title: string;
    onClick?: () => void;
}
export default function TicketingSystemIcons({ title }: TicketingSystemIconsProps) {
    const ModuleIcon = TicketingSystemIconsList[title];
    if (ModuleIcon)
        return <ModuleIcon />
    else return <></>
}