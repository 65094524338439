import { Button } from '@mui/material';
import AlertDialog from '../../../Common/AlertDialog';

interface CloseConfirmDialogProps {
    open: boolean;
    onCancel: () => void;
    onYes: () => void;
}
export default function CloseConfirmDialog({ open, onCancel, onYes }: CloseConfirmDialogProps) {

    return (
        <AlertDialog
        dialogType="yes_or_no"
            open={open}
            onClose={onCancel}
            title="Are you sure you want to cancel?"
            dialogActions = {
                <>
                    <Button onClick={() => onCancel()} className='btn--tertiary btn--XS btn--80Width'>
                        No
                    </Button>
                    <Button onClick={() => onYes()} className='btn--secondary-danger btn--XS btn--80Width' autoFocus style={{marginLeft:"0rem"}}>
                        Yes
                    </Button>
                </>
            }
        />
    )
}