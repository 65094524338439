import React, { useContext } from "react";
import QBOGeneralHeader from "../../QBOReport/Components/GeneralHeader";
import { DataContext } from "../../QBOReport/Context/DataContext";
import { QBOFilterContext } from "../../QBOReport/Context/FilterContext";
import QBOReportFilters from "../../QBOReport/Components/Filter";
import QBOReportsTabs from "../../QBOReport/Components/Tabs";
import QBOReportHeader from "../../QBOReport/Components/ReportHeader";
import { DataGridContext } from "../../QBOReport/Context/DataGridContext";
import QBODataGrid from "../../QBOReport/Components/DataGrid";
import CommentsList from "../../QBOReport/Components/CellComments/CommentsList";
import '../../QBOReport/css/containers.css'

const Presentation = () => {
  const { reportTabIndex } = useContext(DataContext);
  const { isModalOpen, toggleModal } = useContext(QBOFilterContext);
  const { setDrillFlag, setDrilledCellParams } = useContext(DataGridContext);

  return (
    <div className="QBOReport_Container">
      <div className="QBOReport_Container__ReportContainer">
      <QBOGeneralHeader reportTabIndex={reportTabIndex} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <QBOReportFilters />
      <QBOReportsTabs />
      <div className={"FinancialReport__Container"} id="FinancialReport__Container">
        <QBOReportHeader setDrillFlag={setDrillFlag} setDrilledCellParams={setDrilledCellParams} />
      </div>
      <QBODataGrid zoomLevel={"5"}/>
      </div>
      <div>
      <CommentsList />
      </div>
    </div>
  );
};

export default Presentation;
