import React, { useContext, useEffect, useState } from "react";
import GeneralHeader from "../../../Common/GeneralHeader";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import "../PayrollClientView/ClientView.css";
import AsanaLikeButton from "../../../AsanaComponents/AsanaLikeButton";
import { createAsanaSubTask, updateAsanaTask } from "../../../../APIFunctions/Asana";
import PayrollCard from "../Components/PlayrollCard.tsx/PayrollCard";
import "../Components/PayrollViewsComponents.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Comments from "./Comments";
import { Box, LinearProgress } from "@mui/material";
import MilestoneDescription from "../Components/MilestoneDescription";
import { MilestoneContext } from "../../../../contexts/TicketingSystem/MilestoneContext";

import MilestoneStagesHeader from "../../Components/MilestoneStagesHeader";
import NotesQuestions from "../../Components/NotesAndQuestions/NotesQuestions";
import NoPayrollsView from "../../Components/NoMilestonesView";
import MilestoneHeader from "../../Components/MilestoneHeader";
import StageApproveReject from "../../Components/StageApproveReject";
import MainLoadingView from "../../Components/MainLoadingView";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      boxShadow: "none",
      border: "none !important",
      height: "calc(100vh - 52rem)",
      "& .MuiTableCell-stickyHeader": {
        color: "var(--Neutral600) !important",
        fontWeight: "400 !important",
      },
      "& .MuiTableCell-root": {
        color: "#212134",
        fontSize: 14,
        height: "4.5rem",
        borderBottom: "1px solid #EAEAEF",
        justifyContent: "flex-start",
        "& span": {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
        "& svg": {
          marginRight: "1rem",
          "& path": {
            fill: "var(--Success500)",
          },
        },
      },
    },
  })
);

const AdminViewComponent = ({setDisplayListFlag}) => {
  const {
    selectedMilestone,
    setSelectedMilestone,
    payrollLoading,
    setPayrollLoading,
    currentStage,fullLoadedFlag,setFullLoadedFlag, onSubmitRequestRevision
  } = useContext(MilestoneContext);


  const [showMore, setShowMore] = useState<boolean>(false);
  const [componentFlag, setComponentFlag] = useState<boolean>(false);
  const [rejectionSelected, setRejectionSelected] = useState<boolean>(false);

  const [isSubTaskOpen, setIsSubTaskOpen] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const progressBarTheme = createTheme({
    components: {
      // Name of the component
      MuiLinearProgress: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            backgroundColor: "#E5E5E5",
            span: {
              backgroundColor: "#85b440 !important",
            },
          },
        },
      },
    },
  });

  const onBackToList = () => {
    setDisplayListFlag(true)
  }

  useEffect(() => {
    // setFullLoadedFlag(false)
    setTimeout(() => {
      setComponentFlag(true);
      // setPayrollLoading(false)
    }, 6000);
  }, [payrollLoading]);

  return (
    <>
      {!selectedMilestone && componentFlag && <NoPayrollsView milestoneType="Payroll" />}
      {!fullLoadedFlag &&  <MainLoadingView milestoneType={"Payroll"} />}
      {selectedMilestone && componentFlag && (
        <MilestoneHeader title="Payroll"  milestone={selectedMilestone} backToList={onBackToList}>
          {selectedMilestone && componentFlag && selectedMilestone && (
            <>
              <AsanaLikeButton key={selectedMilestone.gid} task={selectedMilestone} setTask={setSelectedMilestone} />
              <StageApproveReject onSubmitRequestRevision={onSubmitRequestRevision} />
            </>
          )}
        </MilestoneHeader>
      )}
      {selectedMilestone && componentFlag && <MilestoneStagesHeader />}

      {payrollLoading || !selectedMilestone || !fullLoadedFlag ? (
        <Box sx={{ width: "100%" }}>
          <ThemeProvider theme={progressBarTheme}>
            <LinearProgress />
          </ThemeProvider>
        </Box>
      ) : (
        !componentFlag && <Box sx={{ width: "100%", backgroundColor: "#E5E5E5", height: "4px" }}></Box>
      )}
      {selectedMilestone && componentFlag && (
        <div className="Payroll__ClientView__TopContainer">
          <div className="Payroll__ClientView__TopContainer__CenterContainer">
            <PayrollCard
              key={selectedMilestone?.gid}
              setIsMilestoneTaskOpen={setIsSubTaskOpen}
              isMilestoneTaskOpen={isSubTaskOpen}
            />

            {showMore && selectedMilestone && componentFlag && <MilestoneDescription milestone={selectedMilestone} setSelectedMilestone={setSelectedMilestone} />}

            {/* {!isSubTaskOpen && ( */}
              {/* // <div className="Question__Container"> */}
                {/* <Tabs className="tabs-bi" selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}> */}
                  {/* <TabList> */}
                    {/* <Tab className="Question__Container__tabs">INTERNAL DISCUSSION</Tab> */}
                    {/* <Tab className="Question__Container__tabs">QUESTIONS TO CLIENT</Tab>
                    <Tab className="Question__Container__tabs">NOTES TO CLIENT</Tab> */}
                  {/* </TabList> */}
                  {/* <TabPanel /> */}
                  {/* <TabPanel />
                  <TabPanel /> */}
                {/* </Tabs> */}
                {/* <div className="Question__Container__Body"> */}
                  {/* {tabIndex === 0 && <Comments task_gid={selectedMilestone?.gid} />} */}
                  {/* {tabIndex === 1 && <NotesQuestions context={""} type={"Question"} />}
                  {tabIndex === 2 && <NotesQuestions context={""} type={"Note"} />} */}
                {/* </div> */}
              {/* </div> */}
            {/* )} */}
          </div>
        </div>
      )}
    </>
  );
};

export default AdminViewComponent;
