import { LinearProgress } from '@mui/material';
import { useContext } from 'react'
import { OnboardingContext } from '../../contexts/Onboarding/OnboardingContext';
import SummaryDialog from './Summary';
import GeneralHeader from '../Common/GeneralHeader';
import MDIcons from '../Common/MDIcons';
import FormRenderer from './FormRenderer';
import './Onboarding.css';

const Onboarding = () => {

    const { onBoardingCompleted, progress, selectedForm, formsCount, summaryIsOpen, setSummaryIsOpen } = useContext(OnboardingContext);

    return (
        <div className="onboarding__Container">
            <GeneralHeader title={"Onboarding new company"} renderDivisions={false} />
            <div className='onboarding__Container__BodyContainer'>
                {
                    !onBoardingCompleted &&
                    <div className="progressbar__container">
                        <LinearProgress
                            variant='determinate'
                            value={Math.floor(progress)}
                        />
                        <div className="progressbar__container__barLabelContainer">
                            <div>Set up your Account</div>
                            <span> {selectedForm + 1} from {formsCount}</span>
                        </div>
                    </div>
                }

                {onBoardingCompleted ?
                    <div className='onboarding__Container__BodyContainer__Completed'>
                        <div className='onboarding__Container__BodyContainer__Completed_body'>
                            <MDIcons title={"OnboardingCompleted"} />
                            <h2>
                                Done! You have successfully completed the onboarding process.
                            </h2>
                            <p>
                                Our team of professionals is working on creating your financial model. 
                                It should be ready in 48 hours. If you have any questions, please contact <span>support@mightydigits.com</span>.
                            </p>
                            <button className='btn--secondary btn--M--centered' onClick={() => {setSummaryIsOpen(true)}}>
                                View my answers
                            </button>
                        </div>


                    </div>
                    :
                    <div className='onboardingPage__Container'>
                        <FormRenderer />
                    </div>
                }

            </div>
            <SummaryDialog summaryIsOpen={summaryIsOpen} closeSummaryDialog={() => setSummaryIsOpen(false)} />
        </div>

    )
}

export default Onboarding