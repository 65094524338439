import React from 'react'
import MDIcons from '../MDIcons';

const FolderPath = ({folderPathArray}) => {
  return (
    <div className='FolderPathContainer'>
    {folderPathArray?.map((x, i) => {
        return (
          <div className='FolderPath'>
            <div style={i < folderPathArray.length - 1 ? { opacity: "0.5" } : {}}>{x}</div>
            {i !== folderPathArray.length - 1 && (
              <span style={i < folderPathArray.length - 1 ? { opacity: "0.5" } : {}}>
                <MDIcons title="right_arrow_filled" />
              </span>
            )}
          </div>
        );
      })}
      </div>
  )
}

export default FolderPath