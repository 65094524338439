import { DataContextProvider } from "../../contexts/FinancialReport/DataContext";
import { ReportFilterContextProvider } from "../../contexts/FinancialReport/FilterContext";
import TransactionsListComponent from "../Transactions";
import FinancialReport from "./Report";
import { BrowserRouter as Router, Route } from "react-router-dom";

const FinancialReportsHome = () => {
  return (
    <>
      <div>
        {/* <ReportFilterContextProvider>
          <DataContextProvider>
            <Router>
            <Switch> */}
              {/* <Route path="/:company_name/financialreport/transactions" element={<TransactionsListComponent zoomLevel={"5"} />} /> */}
              {/* <Route path="/:company_name/financialreport/" element={<FinancialReport />} /> */}
            {/* </Switch>
          </Router>
          </DataContextProvider>
        </ReportFilterContextProvider> */}
      </div>
    </>
  );
};

export default FinancialReportsHome;
