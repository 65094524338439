import { createContext, useState, useContext } from "react";
import { FinancialStatementSelectedFilters, ISelectedFilters } from "../../types/FinancialStatements.ts/SelectedFilters";
import { AuthContext } from "../AuthContext";

export interface FinancialStatementFilterContextProps {
    selectedFilters: FinancialStatementSelectedFilters;
    setSelectedFilters: (selectedFilters: FinancialStatementSelectedFilters) => void;
    updateFilters: (key: string, selectedFilters: ISelectedFilters[]) => void;
    removeAllFilters: () => void;
    isModalOpen:boolean;
    setIsModalOpen: (open: boolean) => void
    toggleModal: () => void
    
}

export const FinancialStatementFilterContext = createContext<FinancialStatementFilterContextProps>(null as any);

export const ReportFilterContextProvider = (props) => {
    const { selectedCompany } = useContext(AuthContext)
    const [selectedFilters, setSelectedFilters] = useState<FinancialStatementSelectedFilters>(new FinancialStatementSelectedFilters(selectedCompany?.offset));
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

    const toggleModal = () => {
        setIsModalOpen(prev => !prev)
    }

    const removeAllFilters = () => {
        setSelectedFilters(selectedFilters.resetFilters())   
    }
    const updateFilters = (key: string, selectedOptions: ISelectedFilters[]) => {
        setSelectedFilters(selectedFilters.updateSelectedOptions(key, selectedOptions))
    }
    return (
        <FinancialStatementFilterContext.Provider value={{
            selectedFilters,
            setSelectedFilters,
            updateFilters,
            removeAllFilters,
            isModalOpen, setIsModalOpen, toggleModal
        }}>
            {props.children}
        </FinancialStatementFilterContext.Provider>
    )
}