import { useContext, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchComponent from '../../Common/SearchComponent';
import { ManageUsersDialogType } from '../../../types/ManageUsers';
import UsersDialog from './UsersDialog';
import { UserListConext } from '../../../contexts/ManageUsers/UserListConext';
import { DataConext } from '../../../contexts/ManageUsers/DataConext';
import { usersTableSearchOptions } from '../../../config/accountSettingsConfigs';
import { uppercaseWords } from '../../../utils/helpers';
import DeleteEmployeeDialog from '../../HeadCountModule/TableControls/DeleteEmployeeDialog';


export default function TableControls() {
    const { selectedFlatRows, openUserDialog, openDeleteDialoge,deleteDialogeOpen, closeDeleteDialoge,userDialogIsOpenByType, manageUsersDialogType } = useContext(UserListConext);
    const { searchText, setSearchText } = useContext(DataConext)

    useEffect(() => {
        ReactTooltip.rebuild();
    }, []);

    return (
        <div className='table-manageUsers-controlsContainer'>
        {selectedFlatRows.length === 1 &&
            <>
                {/* <button className="btn--S btn--secondary" onClick={() => ({})}>
                    <EditIcon />
                    <span>Edit</span>
                </button> */}
                <button className="btn--S btn--secondary-danger" onClick={openDeleteDialoge} >
                    <DeleteIcon />
                    <span>Delete</span>
                </button>
                <span className='manage-users-selected'>{`${selectedFlatRows.length} user${selectedFlatRows.length > 1 ? 's' : ''} selected`}</span>
            </>
        }
        {selectedFlatRows.length > 1 &&
            <>
                <button className="btn--S btn--secondary-danger" onClick={openDeleteDialoge} >
                    <DeleteIcon />
                    <span>Delete</span>
                </button>
                <span className='manage-users-selected'>{`${selectedFlatRows.length} user${selectedFlatRows.length > 1 ? 's' : ''} selected`}</span>
            </>
        }
            <SearchComponent
                searchText={searchText}
                setSearchText={setSearchText}
                searchPlaceHolder={"Search..."}
            />
            <button className="btn--S btn--secondary" onClick={() => { openUserDialog(ManageUsersDialogType.INVITE) }}>
                <AddIcon />
                <span>Invite new user</span>
            </button>
            {userDialogIsOpenByType[manageUsersDialogType] && manageUsersDialogType !== ManageUsersDialogType.DEFAULT && <UsersDialog />}

            <DeleteEmployeeDialog open={deleteDialogeOpen} onCancel={() => { closeDeleteDialoge(false) }} onYes={() => closeDeleteDialoge(true)} />
        </div >
    )
}