import { ReactComponent as Dashboard } from '../../static/img/header-images/dashboard.svg';
import { ReactComponent as FinancialStatement } from '../../static/img/header-images/financial-statements.svg';
import { ReactComponent as HeadCount } from '../../static/img/header-images/headcount.svg';
import { ReactComponent as PitchDeck } from '../../static/img/header-images/pitch-deck.svg';
import { ReactComponent as BudgetToActuals } from '../../static/img/header-images/budget-to-actuals.svg';
import { ReactComponent as Summary } from '../../static/img/header-images/summary.svg';
import { ReactComponent as Settings } from '../../static/img/header-images/settings.svg';
import { ReactComponent as Onboarding } from '../../static/img/header-images/onboarding_icon.svg';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { ReactComponent as Payroll } from '../../static/img/sidebar-images/payroll.svg';
import { ReactComponent as Export_To_Excel } from '../../static/img/sidebar-images/export-to-excel.svg';


const titleIcons = {
    'Dashboard': Dashboard,
    'Financial Statements': FinancialStatement,
    'Income Statement': FinancialStatement,
    'Balance Sheet': FinancialStatement,
    'Cash Flows': FinancialStatement,
    'Headcount': HeadCount,
    'Pitch Deck': PitchDeck,
    'Budget To Actuals': BudgetToActuals,
    'Summary': Summary,
    'Revenue': MonetizationOnIcon,
    'Settings': Settings,
    "Onboarding new company": Onboarding,
    "Tasks":FormatListBulletedIcon,
    "Payroll": Payroll,
    "Export Financials to Excel":Export_To_Excel
}

interface SectionTitleIconsProps {
    title: string;
}
export default function SectionTitleIcons({ title }: SectionTitleIconsProps) {
    const ModuleIcon = titleIcons[title];
    if (ModuleIcon)
        return <ModuleIcon sx={{ color: '#85B440', fontSize:16 }}/>
    else return <></>
}