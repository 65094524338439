import comingsoon from '../../static/img/comming_soon.svg';
import './ComingSoon.css';

export default function NoUserCompany() {
    return (
        <>
            <div className="ComingSoon__Container">
                <img src={comingsoon} alt="Coming Soon" />
                <span className="large-bold">
                    No Company detected in your profile.
                </span>
                <span className="med-light">
                    Please contact&nbsp;
                    <span><a className="help-link" href="mailto:support@mightydigits.com">support@mightydigits.com</a></span>.
                </span>
            </div>
        </>
    )
}