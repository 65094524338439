import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../Context/DataContext";
import { QBOFilterContext } from "../../Context/FilterContext";
import { AuthContext } from "../../../../../../contexts/AuthContext";
import { CommentContext } from "../../Context/CommentContext";
import { DataGridContext } from "../../Context/DataGridContext";
import { useCellComments } from "../../Hooks/useComments";
import {
  DataGridPro,
  DataGridProProps,
  GRID_TREE_DATA_GROUPING_FIELD,
  GridCallbackDetails,
  GridCellParams,
  GridColDef,
  MuiEvent,
  gridSortedRowIdsSelector,
  gridVisibleColumnDefinitionsSelector,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useStyles } from "./styling";
import { commentHighlightFlag, decodedID, formatNumber, formatValue, getColWidth, getTreeDataStructure, getWideColWidth } from "./utils";
import { CustomGridTreeDataGroupingCell } from "./TreeDataGroupingCell";
import { uppercaseAllWordLetters } from "../../../../../../utils/helpers";
import { Box } from "@mui/material";
import ContextMenuMUIDataGrid from "../../../../../Common/ContextMenuMUIDataGrid";
import NoTableData from "../../../../../Common/NoTableData";
import Presentation from "./Presentation";

const Container = ({ zoomLevel }) => {
  const navigate = useNavigate();
  const {
    headersDisplay,
    rowsDisplay,
    reportType,
    detailHeadersDisplay,
    detailRowsDisplay,
    reportDetailLevel,
    runDetailQuery,
    setSelectedTransaction,
    selectedTransaction,
    dataStatus,
    loadingMessage,
  } = useContext(DataContext);

  const { drillFlag, setDrillFlag, setDrilledCellParams } = useContext(DataGridContext);
  const { CommentsListQuery, selectedCell, setSelectedCell, commentsTabIndex, commentCellCoordinates, showCommentsPan, createCommentMutation, onSelectComment } =
    useContext(CommentContext);
  const { isModalOpen } = useContext(QBOFilterContext);
  const { selectedCompany } = useContext(AuthContext);
  //   const {
  //      transactionsCoordinates, transactionDetailFlag } =
  //     useContext(MonthEndCloseContext);

  const gridRows = rowsDisplay ? getTreeDataStructure(headersDisplay, rowsDisplay, CommentsListQuery.data) : undefined;
  const detailGridRows = detailRowsDisplay ?  getTreeDataStructure(detailHeadersDisplay, detailRowsDisplay, CommentsListQuery.data) : undefined;

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const selectCommentBySelectedCell = () => {
    if (selectedCell) {
      const target_row_id = selectedCell?.row?.id;
      const target_col_id = selectedCell?.field;
      let filteredComments: any[] = [];
      let targetComment;
      filteredComments = CommentsListQuery.data?.filter((x) => x.row_id === target_row_id);
      if (filteredComments?.length > 0) {
        targetComment = filteredComments.find((x) => x.column_id === target_col_id);
      }
      if (targetComment) {
        onSelectComment(targetComment);
      }
    }
  };

  const selectCellForComment = async (params: GridCellParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
    setSelectedCell(params);
  };

  const onSetDrilledCellParams = async (params: GridCellParams) => {
    if (reportDetailLevel === "Summary") {
      setDrillFlag(true);
      // setDrilledCellParams(params);
      runDetailQuery(params);
    } else if (reportDetailLevel === "Detail") {
      const params_id = params["id"] as string;
      setSelectedTransaction({ transaction_type: params["row"]["Transaction Type"], transaction_id: params_id.split("-")[0] });
    }
  };

  const styleProps = {
    zoomLevel: zoomLevel,
  };

  const classes = useStyles(styleProps);

  const getTreeDataPath: DataGridProProps["getTreeDataPath"] = (row) => row.hierarchy;
  const groupingColDef: DataGridProProps["groupingColDef"] = {
    headerName: "  ",
    width: getWideColWidth(zoomLevel),
    cellClassName: (params: GridCellParams<any>) => "AccountsNames",
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
  };

  const headCells: GridColDef[] = useMemo(
    () =>
      headersDisplay
        .filter((x) => x !== "Date" && x !== "Group Column")
        .map((key) => {
          return {
            field: key,
            headerName: uppercaseAllWordLetters(key),
            width: getColWidth(zoomLevel),
            cellClassName: (params: GridCellParams<any>) => {
              const highlightFlag = showCommentsPan ? commentHighlightFlag(params?.row?.id, key, commentsTabIndex, CommentsListQuery.data) : "";
              return highlightFlag === "HIGHLIGHT" ? "HIGHLIGHT" : "NOERROR";
            },

            renderCell: (cellValues) => {
              return (
                // todo: review and make it generic
                <>
                  {cellValues.row["display_values"] === false ? (
                    <Box></Box>
                  ) : cellValues.row["bold"] === "bold" ? (
                    <Box sx={{ marginLeft: 1, marginTop: 0.4, fontWeight: 700 }}>{formatValue(cellValues.row[key],true)}</Box>
                  ) : (
                    <Box sx={{ marginLeft: 1, marginTop: 0.4 }}>{formatValue(cellValues.row[key],false)}</Box>
                  )}
                </>
              );
            },
          };
        }),
    [headersDisplay, CommentsListQuery.data, commentsTabIndex]
  );

  const detailHeadCells: GridColDef[] = useMemo(
    () =>
      detailHeadersDisplay
        ?.filter((x) => x !== "Date" && x !== "Group Column")
        .map((key) => {
          return {
            field: key,
            headerName: uppercaseAllWordLetters(key),
            width: getColWidth(zoomLevel),
            cellClassName: (params: GridCellParams<any>) => {
              const highlightFlag = showCommentsPan ? commentHighlightFlag(params?.row?.id, key, commentsTabIndex, CommentsListQuery.data) : "";
              return highlightFlag === "HIGHLIGHT" ? "HIGHLIGHT" : "NOERROR";
            },

            renderCell: (cellValues) => {
              return (
                // todo: review and make it generic
                <>
                  {/* <Box sx={{ marginLeft: 1, marginTop: 0.4 }}>{formatNumber(cellValues.row[key], true)}</Box> */}
                  {cellValues.row["display_values"] === false ? (
                    <Box></Box>
                  ) : cellValues.row["bold"] === "bold" ? (
                    <Box sx={{ marginLeft: 1, marginTop: 0.4, fontWeight: 700 }}>{formatValue(cellValues.row[key],true)}</Box>
                  ) : (
                    <Box sx={{ marginLeft: 1, marginTop: 0.4 }}>{formatValue(cellValues.row[key],false)}</Box>
                  )}
                </>
              );
            },
          };
        }),
    [detailHeadersDisplay, CommentsListQuery.data, commentsTabIndex]
  );

  const handleContextMenu = (event: React.MouseEvent) => {
    const target = event.target as HTMLElement;

    event.preventDefault();
    // setSelectedCell(Number(event.currentTarget.getAttribute('data-id')));
    setContextMenu(contextMenu === null && selectedCell ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null);
  };

  const apiRef = useGridApiRef();

  const rowIndexLocator = (rowId) => {

    try {
      if (rowId) {
        return gridSortedRowIdsSelector(apiRef).findIndex((id) => {
          return decodedID(id.toString()) === decodedID(rowId)});
      } else {
        return -1;
      }
    } catch {
      return -1;
    }
  };

  const colIndexLocator = (colId) => {
    try {
      if (colId) {
        return gridVisibleColumnDefinitionsSelector(apiRef).findIndex((column) => column.field === colId);
      } else {
        return -1;
      }
    } catch {
      return -1;
    }
  };

  useEffect(() => {
    selectCommentBySelectedCell();
  }, [selectedCell]);

  useEffect(() => {
    const { rowIndex, colIndex } = commentCellCoordinates;
    if (rowIndex && colIndex) {
      const rowIndexFound = rowIndexLocator(rowIndex);
      const colIndexFound = colIndexLocator(colIndex);
      if (rowIndexFound !== -1 && colIndexFound !== -1) {
        apiRef.current.scrollToIndexes({ rowIndex: rowIndexFound, colIndex: colIndexFound });
        setTimeout(() => {
            apiRef.current.setCellFocus(drillFlag ? detailGridRows?.[rowIndexFound]['id'] : gridRows?.[rowIndexFound]['id'], colIndex);
          
        }, 500);
      }
    }
  }, [apiRef, commentCellCoordinates]);

  return (
    <Presentation
      isModalOpen={isModalOpen}
      selectedTransaction={selectedTransaction}
      classes={classes}
      getTreeDataPath={getTreeDataPath}
      groupingColDef={groupingColDef}
      headCells={drillFlag ? detailHeadCells : headCells}
      dataRows={drillFlag ? detailGridRows : gridRows}
      zoomLevel={zoomLevel}
      selectCellForComment={selectCellForComment}
      apiRef={apiRef}
      onSetDrilledCellParams={onSetDrilledCellParams}
      handleContextMenu={handleContextMenu}
      contextMenu={contextMenu}
      setContextMenu={setContextMenu}
      createCommentMutation={createCommentMutation}
      dataStatus={dataStatus}
      loadingMessage={loadingMessage}
    />
  );
};

export default Container;
